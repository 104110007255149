import React from 'react';


interface CreateUpdateOrderProviderProps {
    projectId: string;
}

export type ProviderChildren = { projectId: string; children: React.ReactNode };

const OrderManagementContext = React.createContext({} as CreateUpdateOrderProviderProps);

function OrderManagementProvider({ projectId, children }: ProviderChildren) {
    const value: CreateUpdateOrderProviderProps = React.useMemo(
        () => ({
            projectId
        }),
        [projectId]
    );

    return <OrderManagementContext.Provider value={value}>{children}</OrderManagementContext.Provider>;
}

export { OrderManagementContext, OrderManagementProvider };

