import { Trans, t } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client';
import { ConcretingDeliveryNoteDocumentationFilter } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ColumnsType } from 'antd/lib/table';
import { NavigateFunction } from 'react-router-dom';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import { IMarketUnits } from 'src/app-react/constants/marketsData';
import { ConstructorNamesInputItem } from '../../ConcretingDocumentationFilters/InputItems/ConstructorNamesInputItem';
import { DeliveryNoteNumbersInputItem } from '../../ConcretingDocumentationFilters/InputItems/DeliveryNoteNumbersInputItem';
import { ExternalReferencesInputItem } from '../../ConcretingDocumentationFilters/InputItems/ExternalReferencesInputItem';
import { SupplierNamesInputItem } from '../../ConcretingDocumentationFilters/InputItems/SupplierNamesInputItem';
import { SupplierOrderIdsInputItem } from '../../ConcretingDocumentationFilters/InputItems/SupplierOrderIdsInputItem';
import { ConcretingDocumentationParsedAUSData } from './util';

type ConcretingRecord = ConcretingDocumentationParsedAUSData;

interface ConcretingDocumentationTableColumnsAUSProps {
    onDisplayCoseModal: (structuralElementId: string) => void;
    onDisplayConcreteTypeModal: (supplierProducedConcrete: SupplierProducedConcrete) => void;
    onDisplayPostTreatmentModal: (record: ConcretingRecord) => void;
    onEditPostTreatment: (record: ConcretingRecord) => void;
    onEditStructuralElement: (record: ConcretingRecord) => void;
    units: IMarketUnits;
    navigate: NavigateFunction;
    filter: ConcretingDeliveryNoteDocumentationFilter;
    setFilter: React.Dispatch<React.SetStateAction<ConcretingDeliveryNoteDocumentationFilter>>;
}

export const concretingDocumentationTableColumnsAUS: (
    props: ConcretingDocumentationTableColumnsAUSProps
) => ColumnsType<ConcretingRecord> = (props) => {
    return [
        {
            title: <Trans>Supplier name</Trans>,
            key: 'supplierName',
            dataIndex: 'supplierName',
            width: 130,
            sorter: true,
            filteredValue: props.filter.supplierName,
            filterDropdown: <SupplierNamesInputItem />
        },
        {
            title: <Trans>PO number</Trans>,
            key: 'externalReference',
            dataIndex: 'externalReference',
            width: 130,
            sorter: true,
            filteredValue: props.filter.externalReference,
            filterDropdown: <ExternalReferencesInputItem />
        },
        {
            title: <Trans>Supplier Order / Job Number</Trans>,
            key: 'supplierOrderId',
            dataIndex: 'supplierOrderId',
            width: 130,
            sorter: true,
            filteredValue: props.filter.supplierOrderId,
            filterDropdown: <SupplierOrderIdsInputItem />
        },
        {
            title: <Trans>Customer Name</Trans>,
            key: 'constructorName',
            dataIndex: 'constructorName',
            width: 130,
            sorter: true,
            filterSearch: true,
            filteredValue: props.filter.constructorName,
            filterDropdown: <ConstructorNamesInputItem />
        },
        {
            title: <Trans>Delivery docket No.</Trans>,
            key: 'deliveryNoteNumber',
            dataIndex: 'deliveryNoteNumber',
            width: 130,
            sorter: true,
            filterSearch: true,
            filteredValue: props.filter.deliveryNoteNumber,
            filterDropdown: <DeliveryNoteNumbersInputItem />,
            render: (deliveryNoteNumber: string, record: ConcretingRecord) => (
                <RawLinkButton
                    onClick={() =>
                        props.navigate(
                            `/projects/${record.proyectId}/orders/${record.orderId}/delivery-notes-details/${record.concreteLoadId}/details`
                        )
                    }>
                    {deliveryNoteNumber}
                </RawLinkButton>
            )
        },
        {
            title: <Trans>Delivery Date</Trans>,
            key: 'deliveryTime',
            dataIndex: 'deliveryTime',
            width: 130,
            sorter: true,
            render: (deliveryTime: Date) => <DateFormatter date={deliveryTime} />
        },
        {
            title: <Trans>Truck Load</Trans>,
            key: 'loadQuantity',
            dataIndex: 'loadQuantity',
            width: 130,
            sorter: false,
            render: (loadQuantity: number) => <NumberFormatter value={loadQuantity} unit={props.units.volume} />
        },
        {
            title: <Trans>Delivered Quantity</Trans>,
            key: 'deliveredQuantity',
            dataIndex: 'deliveredQuantity',
            width: 130,
            sorter: false,
            render: (deliveredQuantity: number) => (
                <NumberFormatter value={deliveredQuantity} unit={props.units.volume} />
            )
        },
        {
            title: <Trans>Arrival on Site</Trans>,
            key: 'timeArrived',
            dataIndex: 'timeArrived',
            width: 130,
            sorter: false,
            render: (timeArrived: Date) => <TimeFormatter date={timeArrived} />
        },
        {
            title: <Trans>Total time on site</Trans>,
            key: 'totalTimeOnSite',
            dataIndex: 'totalTimeOnSite',
            width: 130,
            sorter: false,
            render: (totalTimeOnSite: number) => <NumberFormatter value={totalTimeOnSite} unit={t`minutes`} />
        },
        {
            title: <Trans>Unloading End</Trans>,
            key: 'timeFinish',
            dataIndex: 'timeFinish',
            width: 130,
            sorter: false,
            render: (timeFinish: Date) => <TimeFormatter date={timeFinish} />
        },
        {
            title: <Trans>Concrete Type</Trans>,
            key: 'number',
            dataIndex: 'number',
            width: 130,
            sorter: true
        },
        {
            title: <Trans>Compressive Strength</Trans>,
            key: 'compressiveStrength',
            dataIndex: 'compressiveStrength',
            width: 130,
            sorter: false,
            render: (compressiveStrength: number) => (
                <NumberFormatter value={compressiveStrength} unit={props.units.compressiveStrength} />
            )
        }
    ];
};
