/* eslint-disable react/require-default-props */

import { Col } from 'antd';

export interface BaseColProps {
    span?: number;
    children: unknown;
    title: string;
}

function BaseCol({ title, children, span }: BaseColProps) {
    return (
        <Col span={span} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
            {title}
            {children}
        </Col>
    );
}

BaseCol.defaultProps = {
    span: 4
};

export default BaseCol;
