import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import { createInitialValuesDE, createTemperatureTestDE, getTemperatureFormValuesDE } from '../markets/DE/util';
import { createInitialValuesUS, createTemperatureTestUS, getTemperatureFormValuesUS } from '../markets/US/util';
import { FormInstance } from 'antd';
import { TestSamplingWithType } from '../../CommonTestForm/models';
import { TemperatureTestFormValues } from './models';
import {
    FreshConcreteTest,
    newFreshConcreteTest
} from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import { TemperatureTestFormValuesDE } from './DE/models';
import { TemperatureTestFormValuesUS } from './US/models';
import { createTemperatureTestAUS } from './AUS/util';
import { TemperatureTestFormValuesAUS } from './AUS/models';
import { TemperatureTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValues(values: any, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return createInitialValuesDE(values);
        default:
            return createInitialValuesUS(values);
    }
}

export function getTemperatureFormValues(values: FormInstance<any>, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return getTemperatureFormValuesDE(values);
        default:
            return getTemperatureFormValuesUS(values);
    }
}

export function createTemperatureTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    commonTest: TestSamplingWithType,
    temperatureTestFormValues: TemperatureTestFormValues,
    marketId: string
): TemperatureTest {
    const freshConcreteTest: FreshConcreteTest = newFreshConcreteTest(
        companyId,
        projectId,
        orderId,
        concreteLoadId,
        commonTest
    );

    switch (marketId) {
        case MARKETS.DE:
            return createTemperatureTestDE(freshConcreteTest, temperatureTestFormValues as TemperatureTestFormValuesDE);
        case MARKETS.AUS:
            return createTemperatureTestAUS(
                freshConcreteTest,
                temperatureTestFormValues as TemperatureTestFormValuesAUS
            );
        default:
            return createTemperatureTestUS(freshConcreteTest, temperatureTestFormValues as TemperatureTestFormValuesUS);
    }
}
