import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { FormInstance } from 'antd';
import moment from 'moment';

export function cleanForm(form: FormInstance) {
    form.setFieldsValue({
        name: '',
        number: '',
        description: undefined,
        startDate: null,
        endDate: null,
        billingAddress: null,
        typeOfConstruction: null,
        constructionName: '',
        constructionStreet: '',
        constructionHouseNo: '',
        constructionZip: '',
        constructionCity: '',
        constructionCountry: 'GE',
        marketId: null,
        timeZone: null,
        status: []
    });
}

export function projectToFormData(data: Project) {
    return {
        name: data?.name,
        number: data?.number,
        description: data?.description,
        startDate: moment(data?.startDate),
        endDate: moment(data?.endDate),
        billingAddress: data?.billingAddress,
        typeOfConstruction: data?.typeOfConstruction,
        constructionName: data?.constructionName,
        constructionStreet: data?.constructionStreet,
        constructionHouseNo: data?.constructionHouseNo,
        constructionZip: data?.constructionZip,
        constructionCity: data?.constructionCity,
        constructionCountry: data?.constructionCountry,
        marketId: data?.marketId,
        status: ['ProjectCreated'],
        timeZone: data?.timeZone
    };
}
