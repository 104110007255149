import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import { InputNumber } from 'src/app-react/components/Form';
import { MARKETS } from '../../../../../../../../../constants/markets';
import useProjectState from '../../../../../../../../../hooks/useProjectState';

export function ConcreteQuantityFormItem() {
    const { marketId } = useProjectState();
    const addonAfter = marketId === MARKETS.DE ? t`m3` : t`yd3`;

    return (
        <Form.Item
            label={<Trans>Concrete quantity</Trans>}
            name="concreteQuantity"
            rules={[
                {
                    pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                    required: true,
                    message: (
                        <span data-testid="errorInputConcreteQuantity">
                            <Trans>This field is required</Trans>
                        </span>
                    )
                }
            ]}>
            <InputNumber
                placeholder={t`Enter concrete quantity`}
                data-testid="concreteQuantity"
                addonAfter={addonAfter}
            />
        </Form.Item>
    );
}
