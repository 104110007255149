import React from 'react';
import { useLocation } from 'react-router-dom';
import { notifyPageChange } from './utils/userpilot';

export function UserPilotReloader(): null {
    const location = useLocation();
    React.useEffect(() => {
        notifyPageChange();
    }, [location]);
    return null;
}
