import { createContext } from 'react';

interface DeliveryNoteDetailsContextProps {
    orderId: string;
    projectId: string;
    concreteLoadId?: string;
}

const DeliveryNoteDetailsContext = createContext({} as DeliveryNoteDetailsContextProps);
export default DeliveryNoteDetailsContext;
