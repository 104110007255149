import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';

function fromDeliveryTimeToFormFields(
    deliveryTime: moment.Moment,
    formFieldsValue: ConcreteLoadDataLiteFormFields,
    marketId?: string
) {
    return {
        loadingStartDate: formFieldsValue.loadingStartDate ?? deliveryTime,
        arrivalAtConstructionSiteDate: formFieldsValue.arrivalAtConstructionSiteDate ?? deliveryTime,
        arrivalAtConstructionSite:
            marketId && marketId === 'AUS'
                ? formFieldsValue.arrivalAtConstructionSite ?? deliveryTime
                : formFieldsValue.arrivalAtConstructionSite,
        unloadingStartDate: formFieldsValue.unloadingStartDate ?? deliveryTime,
        unloadingEndDate: formFieldsValue.unloadingEndDate ?? deliveryTime,
        signedOnDate: formFieldsValue.signedOnDate ?? deliveryTime
    };
}

export default fromDeliveryTimeToFormFields;
