import { Trans } from '@lingui/macro';
import { ConcretedStructuralElementStartEndDate } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { PostTreatmentSummaryForm } from './components/PostTreatmentSummaryForm';
import { PostTreatmentTable } from './components/PostTreatmentTable';
import { PostTreatmentContext } from './contexts/PostTreatmentContext';

export function PostTreatment() {
    const [loadSummary, setLoadSummary] = useState(true);
    const [loadTable, setLoadTable] = useState<boolean>(true);
    const [concretingEndDate, setConcretingEndDate] = useState<Date>(null);
    const [concretingStartDate, setConcretingStartDate] = useState<Date>(null);

    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    useEffect(() => {
        if (loadTable) {
            setLoadTable(false);
        }

        if (loadSummary) {
            setLoadSummary(false);
        }
    }, [loadTable, loadSummary, projectId, companyId]);

    function triggerReload() {
        setLoadTable(true);
        setLoadSummary(true);
    }

    function getConcretedStartEndDateByCoSEId() {
        api.concretingDocumentationClient
            .getConcretedStartEndDateByCoSEId(companyId, projectId, structuralElementId)
            .then((response) => {
                if (response.isSuccess()) {
                    const result: ConcretedStructuralElementStartEndDate = response.getEntity();
                    setConcretingEndDate(result.concretingEndDate);
                    setConcretingStartDate(result.concretingStartDate);
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        getConcretedStartEndDateByCoSEId();
    }, []);

    return (
        <PostTreatmentContext.Provider value={{ concretingEndDate, concretingStartDate }}>
            <Row gutter={[8, 20]}>
                <Col span={24}>
                    <Row>
                        <Col span={9} style={{ marginBottom: '1rem' }}>
                            <b>
                                <Trans>Post-treatments</Trans>
                            </b>
                        </Col>
                        <Col span={24}>
                            <PostTreatmentSummaryForm loadSummary={loadSummary} triggerReload={triggerReload} />
                        </Col>
                        <Col span={24}>
                            <PostTreatmentTable loadTable={loadTable} triggerReload={triggerReload} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </PostTreatmentContext.Provider>
    );
}
