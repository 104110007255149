/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { Input } from 'antd';
import {
    ORDER_LOAD_LITE_TYPES,
    OrderLoadLiteTypesValues
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import InputFormItem from 'src/app-react/components/Form/FormItem/InputFormItem';
import { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';

interface SupplierNameFormItemProps extends SelectFormItemProps {
    type: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
}

function SupplierNameFormItem({ type, origin, ...props }: SupplierNameFormItemProps) {
    const isDisabled = type === ORDER_LOAD_LITE_TYPES.QR || origin === ORDER_LOAD_LITE_TYPES.QR.toLowerCase();

    return (
        <InputFormItem data-testid="consistencyType" label={t`Supplier name`} name="supplierName" {...props}>
            <Input
                data-testid="consistencyType"
                disabled={isDisabled}
                readOnly={true}
                placeholder={t`Enter supplier name`}
            />
        </InputFormItem>
    );
}

export default SupplierNameFormItem;
