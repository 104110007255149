import { t, Trans } from '@lingui/macro';
import { Button, Col, Modal, Row } from 'antd';
import Fraction from 'fraction.js';
import { useEffect, useState } from 'react';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { formatNumberValueToLanguage, numberFormat } from 'src/app-react/utils/formatters';
import { formatSlump, mapStrengthDevelopmentClass } from '../../../business-page/catalogue-management/utils';
import ExposureClassesFormatter from '../../Formatters/ExposureClassesFormatter';
import styles from './ConcreteTypeModal.module.css';
import { transformConcreteType } from './utils/formatters';
import {
    ConcreteTypeBP,
    ConcreteTypeExtendedBP,
    ConcreteTypeIngredientBP,
    IngredientBP
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { OrderOverviewOrderedConcreteType } from '@nexploretechnology/concreting-core-client';

const empty = '-';

export type ConcreteType = ConcreteTypeExtendedBP | OrderOverviewOrderedConcreteType | ConcreteTypeBP;

type Ingredient = ConcreteTypeIngredientBP & IngredientBP;

function formatDecimalAsFraction(decimal: number): string {
    const excludeWhole: boolean = true; // e.g. "1 1/5" will be visible instead of "6/5"
    return new Fraction(Number(decimal)).toFraction(excludeWhole);
}

interface ConcreteTypeModalProps {
    concreteDetails: ConcreteType;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    displayNextLabel?: boolean;
    onClose?: () => void;
}
const ConcreteTypeModal = (props: ConcreteTypeModalProps) => {
    const { concreteDetails, onClose, open, setOpen, displayNextLabel } = props;

    const { marketId } = useProjectState();

    const [concreteTypenDetailsData, setConcreteTypeData] = useState<
        ConcreteTypeExtendedBP | OrderOverviewOrderedConcreteType
    >({} as ConcreteTypeExtendedBP | OrderOverviewOrderedConcreteType);

    const marketDE: string = 'DE';

    useEffect(() => {
        if (open) {
            setConcreteTypeData(transformConcreteType(concreteDetails));
        }
    }, [open]);

    const firstBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Concrete Type</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-concreteTypeNumber" span={24}>
                    {concreteTypenDetailsData.concreteTypeNumber || empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Description</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-concreteTypeDescription" span={24}>
                    {concreteTypenDetailsData.concreteTypeDescription || empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} span={24}>
                    {getStrengthClass(concreteTypenDetailsData) || empty}
                </Col>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Exposure Class</Trans>
                    </label>
                </Col>

                <Col className={styles.colWithValue} data-testid="concreteTypeModal-exposureClass" span={24}>
                    <ExposureClassesFormatter
                        x0={concreteTypenDetailsData.x0}
                        xa={concreteTypenDetailsData.xa}
                        xc={concreteTypenDetailsData.xc}
                        xd={concreteTypenDetailsData.xd}
                        xfFirst={concreteTypenDetailsData.xfFirst}
                        xfSecond={concreteTypenDetailsData.xfSecond}
                        xm={concreteTypenDetailsData.xm}
                        xs={concreteTypenDetailsData.xs}
                    />
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Moisture Class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-moistureClass" span={24}>
                    {concreteTypenDetailsData.moistureClass || empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Consistency Class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-consistencyClass" span={24}>
                    {`${concreteTypenDetailsData.consistencyType || ''}${
                        concreteTypenDetailsData.consistencyClass || ''
                    }`}
                    {!(concreteTypenDetailsData.consistencyClass && concreteTypenDetailsData.consistencyType)
                        ? empty
                        : ''}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Maximum Aggregate size</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-maximumAggregateSize" span={24}>
                    {concreteTypenDetailsData.maximumAggregateSize
                        ? numberFormat(concreteTypenDetailsData.maximumAggregateSize)
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Strength development class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-strengthDevelopmentClass" span={24}>
                    {mapStrengthDevelopmentClass(concreteTypenDetailsData.strengthDevelopmentClass)}
                </Col>
            </>
        );
    };

    const firstBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Mix design number</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-concreteTypeNumber" span={24}>
                    {concreteTypenDetailsData.concreteTypeNumber || empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Description</Trans>
                    </label>
                </Col>
                <Col
                    className={styles.colWithValue}
                    data-testid="concreteTypeModalUS-concreteTypeDescription"
                    span={24}>
                    {concreteTypenDetailsData.concreteTypeDescription || empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-compressiveStrength" span={24}>
                    {concreteTypenDetailsData.compressiveStrength && concreteTypenDetailsData.compressiveStrengthUnit
                        ? `${concreteTypenDetailsData.compressiveStrength} ${concreteTypenDetailsData.compressiveStrengthUnit}`
                        : empty}
                </Col>

                <Col span={24}>
                    <label data-testid="slump" className={styles.modalLabel}>
                        <Trans>Slump</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-slump" span={24}>
                    {formatSlump(
                        concreteTypenDetailsData.slump,
                        concreteTypenDetailsData.slumpVariance,
                        concreteTypenDetailsData.slumpUnit,
                        empty
                    )}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Maximum Aggregate size</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-maximumAggregateSize" span={24}>
                    {concreteTypenDetailsData.maximumAggregateSize
                        ? formatDecimalAsFraction(Number(concreteTypenDetailsData.maximumAggregateSize))
                        : empty}
                </Col>
            </>
        );
    };

    const secondBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Density Class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-densityClass" span={24}>
                    {concreteTypenDetailsData.densityClass || empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Method of concrete use / Chloride content</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-MethodOfConcrete" span={24}>
                    {getMethodOfConcrete(concreteTypenDetailsData) || empty}
                </Col>
            </>
        );
    };

    const secondBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Method of concrete use</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-MethodOfConcrete" span={24}>
                    {getMethodOfConcrete(concreteTypenDetailsData) || empty}
                </Col>
            </>
        );
    };

    const thirdBlockRenderMarketIdDE = (): JSX.Element => {
        return <></>;
    };

    const thirdBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Air content</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-airContentInPercent" span={24}>
                    {concreteTypenDetailsData.airContentInPercent
                        ? `${numberFormat(concreteTypenDetailsData.airContentInPercent)} %`
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Submittal number</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-submittalNumber" span={24}>
                    {concreteTypenDetailsData.submittalNumber
                        ? formatNumberValueToLanguage(concreteTypenDetailsData.submittalNumber)
                        : empty}
                </Col>
            </>
        );
    };

    function getStrengthClass(concreteTypeDetails: ConcreteType): string {
        let strengthClass: string = '';

        if (concreteTypeDetails.typeOfConcrete) {
            strengthClass += `${concreteTypeDetails.typeOfConcrete}`;
        }

        if (concreteTypeDetails.cylindricCompressiveStrength) {
            strengthClass += ` ${concreteTypeDetails.cylindricCompressiveStrength}`;
        }

        if (concreteTypeDetails.cubicCompressiveStrength) {
            strengthClass += `/${concreteTypeDetails.cubicCompressiveStrength}`;
        }

        return strengthClass;
    }

    function getMethodOfConcrete(
        concreteTypeDetails: ConcreteTypeExtendedBP | OrderOverviewOrderedConcreteType
    ): string {
        let methodOfConcrete: string = '';

        if (concreteTypeDetails.methodOfConcreteUse) {
            methodOfConcrete += `${concreteTypeDetails.methodOfConcreteUse}`;
        }

        if (concreteTypeDetails.chlorideContentClass) {
            methodOfConcrete += ` / ${concreteTypeDetails.chlorideContentClass}`;
        }

        return optionMethodConcrete(methodOfConcrete);
    }

    function optionMethodConcrete(option: string): string {
        switch (option) {
            case 'prestressedConcrete / Cl 0,20':
                return t`Prestressed concrete / Cl 0,20`;
            case 'reinforcedConcrete / Cl 0,40':
                return t`Reinforced concrete  / Cl 0,40`;
            case 'nonReinforcedConcrete / Cl 1,00':
                return t`Non-reinforced concrete / Cl 1,00`;
            case 'prestressedConcrete':
                return t`Prestressed concrete`;
            case 'reinforcedConcrete':
                return t`Reinforced concrete`;
            case 'nonReinforcedConcrete':
                return t`Non-reinforced concrete`;
        }
        return '';
    }

    function renderAdditionalAttributes(additionalAttributes: string[] | undefined): JSX.Element {
        if (additionalAttributes && additionalAttributes.length > 0) {
            return (
                <>
                    {additionalAttributes.map((at: string, index: number) => (
                        <p data-testid="CatalogueCatalogConcreteTypeModal-additionalAttributes" key={index}>
                            {at}
                        </p>
                    ))}
                </>
            );
        } else {
            return <>{empty}</>;
        }
    }

    function getIngredients(ingredients: Ingredient[], ingredientName: string): Ingredient[] {
        const filteredIngredients: Ingredient[] = ingredients
            .filter((ingredient: Ingredient) => ingredient.ingredientType === ingredientName)
            .map((ingredient: Ingredient) => ({
                ...ingredient,
                quantity: ingredient.quantity ?? ingredient.density,
                quantityUnit: ingredient.quantityUnit ?? ingredient.densityUnit,
                maximumAggregateSize: ingredient.maximumAggregateSize ?? ingredient.maxAggregateSize,
                minimumAggregateSize: ingredient.minimumAggregateSize ?? ingredient.minAggregateSize
            }));

        return filteredIngredients;
    }

    function renderTextCementsAdditives(ingredients: Ingredient[], ingredientName: string): JSX.Element {
        const filteredIngredients: Ingredient[] = getIngredients(ingredients, ingredientName);

        if (filteredIngredients && filteredIngredients.length > 0) {
            return (
                <>
                    {filteredIngredients.map((ingredient: Ingredient, index: number) => {
                        const ingredientOrigin: string = ingredient.ingredientOrigin
                            ? ` ${ingredient.ingredientOrigin}`
                            : '';
                        const quantity: string = ingredient.quantity
                            ? ` (${numberFormat(ingredient.quantity)} ${ingredient.quantityUnit})`
                            : '';
                        return (
                            <p
                                data-testid={`CatalogueConcreteTypeModal-ingredients-${ingredient.ingredientName}`}
                                key={index}>{`${ingredient.ingredientName}${ingredientOrigin}${quantity}`}</p>
                        );
                    })}
                </>
            );
        } else {
            return <>{empty}</>;
        }
    }

    function renderTextAggregates(ingredients: Ingredient[], ingredientName: string): JSX.Element {
        const filteredIngredients: Ingredient[] = getIngredients(ingredients, ingredientName);

        if (filteredIngredients && filteredIngredients.length > 0) {
            return (
                <>
                    {filteredIngredients.map((ingredient: Ingredient, index: number) => {
                        const quantity: string = ingredient.quantity
                            ? ` (${numberFormat(ingredient.quantity)} ${ingredient.quantityUnit})`
                            : '';
                        return (
                            <p data-testid="concreteTypeModal-aggregates" key={index}>{`${ingredient.ingredientName} ${
                                ingredient.ingredientOrigin || ''
                            } ${quantity}`}</p>
                        );
                    })}
                </>
            );
        } else {
            return <>{empty}</>;
        }
    }

    function renderTextAdmixture(ingredients: ConcreteTypeIngredientBP[], ingredientName: string): JSX.Element {
        const filteredIngredients: ConcreteTypeIngredientBP[] = getIngredients(ingredients, ingredientName);

        if (filteredIngredients && filteredIngredients.length > 0) {
            return (
                <>
                    {filteredIngredients.map((ingredient: ConcreteTypeIngredientBP, index: number) => {
                        const ingredientOrigin: string = ingredient.ingredientOrigin
                            ? ` ${ingredient.ingredientOrigin}`
                            : '';
                        return (
                            <p
                                data-testid="concreteTypeModal-admixture"
                                key={index}>{`${ingredient.ingredientName}${ingredientOrigin}`}</p>
                        );
                    })}
                </>
            );
        } else {
            return <>{empty}</>;
        }
    }

    const onCloseButton = (): void => {
        setOpen(false);
    };

    const onNextButton = (): void => {
        setOpen(false);
        onClose?.();
    };

    return (
        <Modal
            maskClosable={false}
            open={open}
            forceRender
            onCancel={onCloseButton}
            title={marketId === marketDE ? t`Concrete Details` : t`Mix design details`}
            width={750}
            data-testid="concreteTypeModal"
            footer={[
                <Button data-testid="closeButton" key="send" type="primary" onClick={onNextButton}>
                    {displayNextLabel ? t`Next` : t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 4]}>
                {marketId === marketDE ? firstBlockRenderMarketIdDE() : firstBlockRenderMarketIdUS()}

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Cements</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} span={24}>
                    {concreteTypenDetailsData.ingredients
                        ? renderTextCementsAdditives(concreteTypenDetailsData.ingredients, 'cement')
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Supplementary cementious materials</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} span={24}>
                    {concreteTypenDetailsData.ingredients
                        ? renderTextCementsAdditives(
                              concreteTypenDetailsData.ingredients,
                              'supplementaryCementiousMaterials'
                          )
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Aggregates</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} span={24}>
                    {concreteTypenDetailsData.ingredients
                        ? renderTextAggregates(concreteTypenDetailsData.ingredients, 'aggregate') || empty
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>AdMixtures</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} span={24}>
                    {concreteTypenDetailsData.ingredients
                        ? renderTextAdmixture(concreteTypenDetailsData.ingredients, 'admixture')
                        : empty}
                </Col>

                {marketId === marketDE ? secondBlockRenderMarketIdDE() : secondBlockRenderMarketIdUS()}

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Testing Age (Days)</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-testingAge" span={24}>
                    {concreteTypenDetailsData.testingAge ? numberFormat(concreteTypenDetailsData.testingAge) : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Water/Cement Ratio</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="concreteTypeModal-waterCementRatio" span={24}>
                    {concreteTypenDetailsData.waterCementRatio
                        ? numberFormat(concreteTypenDetailsData.waterCementRatio)
                        : empty}
                </Col>

                {marketId === marketDE ? thirdBlockRenderMarketIdDE() : thirdBlockRenderMarketIdUS()}

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Additional attributes</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} span={24}>
                    {renderAdditionalAttributes(concreteTypenDetailsData.additionalAttributes)}
                </Col>
            </Row>
        </Modal>
    );
};

export default ConcreteTypeModal;
