import { Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { SUPPLIER_VALUES } from 'src/app-react/constants/suppliers';
import { SUPPLIERS_DATA } from 'src/app-react/constants/suppliersData';

export function SupplierNameFormItem() {
    const form = Form.useFormInstance();
    const selectedSoftware = Form.useWatch('supplierSoftware', form);
    const supplierNameOptions = SUPPLIERS_DATA[selectedSoftware as SUPPLIER_VALUES]?.name;

    return (
        <FormItem
            name="supplierName"
            label={<Trans>Supplier name</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Select
                style={{ width: '100%' }}
                data-testid="supplierName"
                placeholder={<Trans>Select a supplier name</Trans>}>
                {supplierNameOptions?.map((supplierNameOption) => (
                    <Select.Option data-testid={supplierNameOption} value={supplierNameOption}>
                        <span>{supplierNameOption}</span>
                    </Select.Option>
                ))}
            </Select>
        </FormItem>
    );
}
