import { t, Trans } from '@lingui/macro';
import { ConcretedStructuralElementForConcretingDocumentationBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col, Divider, Modal, Row, Space } from 'antd';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { MeasurementDetailsTable } from './MeasurementDetailsTable';
import { PostTreatmentDetailsTable } from './PostTreatmentDetailsTable';

interface PostTreatmentDetailsModalProps {
    concretedStructuralElement: ConcretedStructuralElementForConcretingDocumentationBP;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function PostTreatmentDetailsModal(props: PostTreatmentDetailsModalProps) {
    const { open, setOpen, concretedStructuralElement } = props;
    const { projectId } = useProjectState();
    const navigate: NavigateFunction = useNavigate();

    function onNext() {
        navigate(`/projects/${projectId}/concreting-documentation/${concretedStructuralElement.structuralElementId}`);
    }

    function onClose() {
        setOpen(false);
    }

    return (
        <Modal
            maskClosable={false}
            open={open}
            data-testid="postTreatmentDetailsModal"
            title={<Trans>Post-Treatment details</Trans>}
            okText={<Trans>Edit Post-Treatment</Trans>}
            cancelText={<Trans>Close</Trans>}
            onOk={onNext}
            onCancel={onClose}
            width={1000}>
            <Row gutter={[120, 132]}>
                <Col>
                    <Row>
                        <Col span={7} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <b>
                                <Trans>Minimal duration</Trans>
                            </b>
                            {concretedStructuralElement.targetNumberOfDays
                                ? `${concretedStructuralElement.targetNumberOfDays} ${t`day(s)`}`
                                : `-`}
                        </Col>
                        <Col span={7} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <b>
                                <Trans>Equipping / Stripping</Trans>
                            </b>
                            {concretedStructuralElement.equippingOrStripping ? (
                                <DateFormatter date={concretedStructuralElement.equippingOrStripping} />
                            ) : (
                                '-'
                            )}
                        </Col>
                        <Col style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                            <b>
                                <Trans>Actual duration</Trans>
                            </b>
                            {concretedStructuralElement.actualNumberOfDays
                                ? `${concretedStructuralElement.actualNumberOfDays} ${t`day(s)`}`
                                : `-`}
                        </Col>
                    </Row>
                    <Divider />
                    <Space direction="vertical" size={10}>
                        <Row>
                            <Col span={9}>
                                <b>
                                    <Trans>Post-treatments</Trans>
                                </b>
                            </Col>
                            <Col span={24}>
                                <PostTreatmentDetailsTable
                                    structuralElementId={concretedStructuralElement.structuralElementId}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={9}>
                                <b>
                                    <Trans>Temperature measurements</Trans>
                                </b>
                            </Col>
                            <Col span={24}>
                                <MeasurementDetailsTable
                                    structuralElementId={concretedStructuralElement.structuralElementId}
                                    measurementType="temperature"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={9}>
                                <b>
                                    <Trans>Compressive strength measurements</Trans>
                                </b>
                            </Col>
                            <Col span={24}>
                                <MeasurementDetailsTable
                                    structuralElementId={concretedStructuralElement.structuralElementId}
                                    measurementType="compressiveStrength"
                                />
                            </Col>
                        </Row>
                    </Space>
                </Col>
            </Row>
        </Modal>
    );
}
