import { CommentOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { CommentConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Divider, List } from 'antd';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { commentActionMapper } from 'src/app-react/utils/formatters';
import styles from './comment-concrete-load-list.module.css';

export interface ICommentConcreteLoad {
    commentsConceteLoad: CommentConcreteLoad[];
}

function CommentConcreteLoadList(props: ICommentConcreteLoad) {
    const { commentsConceteLoad } = props;
    return (
        <div>
            {commentsConceteLoad.length <= 0 ? (
                <p data-testid="hiddenButton" />
            ) : (
                <>
                    <List
                        data-testid="commentList"
                        header={
                            <div className={styles.listHeader}>
                                <CommentOutlined /> <Trans>Comments</Trans>
                            </div>
                        }
                        className={styles.listBody}
                        bordered
                        dataSource={commentsConceteLoad}
                        renderItem={(item: CommentConcreteLoad) => (
                            <List.Item className="listItem">
                                <List.Item.Meta
                                    title={
                                        <div className={styles.listItem}>
                                            {commentActionMapper[item.actionConcreteLoad]}
                                        </div>
                                    }
                                    description={
                                        <>
                                            <div className={styles.listComment}>{item.comment}</div>
                                            <div className={styles.listElemUser}>
                                                <DateTimeFormatter date={item.createdOn} />
                                                <Trans>by</Trans> {item.nameUser}
                                            </div>
                                        </>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                    <Divider />
                </>
            )}
        </div>
    );
}

export default CommentConcreteLoadList;
