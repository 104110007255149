const MOISTURE = [
    {
        value: 'W0',
        label: 'W0'
    },
    {
        value: 'WF',
        label: 'WF'
    },
    {
        value: 'WA',
        label: 'WA'
    },
    {
        value: 'WS',
        label: 'WS'
    }
];

export default MOISTURE;
