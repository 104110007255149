/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { TimePicker as AntdTimePicker, TimePickerProps } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function TimePicker(props: TimePickerProps) {
    const { formats } = useProjectConstantsState();

    return (
        <AntdTimePicker style={{ width: '100%' }} placeholder={t`Enter Time`} format={formats.timeForm} {...props} />
    );
}

export default TimePicker;
