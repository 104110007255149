import { Form, FormProps } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { CompressiveMeasurememntEditlModalFormDE } from './DE';
import { CompressiveMeasurememntEditlModalFormUS } from './US';

export function CompressiveMeasurementEditModalForm(props: Partial<FormProps>) {
    const { marketId } = useProjectState();

    let children: any;

    switch (marketId) {
        case MARKETS.DE:
            children = <CompressiveMeasurememntEditlModalFormDE />;
            break;
        case MARKETS.US:
        case MARKETS.AUS:
            children = <CompressiveMeasurememntEditlModalFormUS />;
            break;
        default:
            children = null;
            break;
    }

    return (
        <Form
            {...props}
            name="compressiveMeasurementModalForm"
            data-testid="compressiveMeasurementModalForm"
            layout="vertical">
            {children}
        </Form>
    );
}
