import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { WaterCementRatioFormItem } from '../../../FormItems/WaterCementRatioFormItem';

export function WaterCementRatioFormItemDE(): JSX.Element {
    const rexExpresion =
        i18n.locale === 'de-DE' ? /^(0(\,\d{1,2})?)$|^(1(\,0{1,2})?)$/ : /^(0(\.\d{1,2})?)$|^(1(\.0{1,2})?)$/;
    return (
        <WaterCementRatioFormItem
            rules={[
                {
                    pattern: rexExpresion,
                    message: t`Please enter the water/cement ratio. Must be >= 0 and <= 1`
                }
            ]}
        />
    );
}
