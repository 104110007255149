import { t, Trans } from '@lingui/macro';
import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext } from 'react';
import { EditModalFormContext } from '../../../../../../contexts/EditModalFormContext';

export function TemperatureTypeFormItem() {
    
    const {formInitialValues} = useContext(EditModalFormContext);

    function evalDisableItem() {
        return (formInitialValues && formInitialValues.types !== undefined);
    }
    
    return (
        <FormItem
            name="type"
            label={<b><Trans>Temperature type</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                required: true,
                message: <Trans>Please select a temperature type</Trans>
            }]}>

            <Select data-testid="types" 
                disabled={evalDisableItem()}
                placeholder={t`Select temperature type`} 
                style={{width:'100%'}}>
                <Select.Option key="surface" value="surface">
                    {t`Surface temperature`}
                </Select.Option>
                <Select.Option key="air" value="air">
                    {t`Air temperature`}
                </Select.Option>
                <Select.Option key="freshConcrete" value="freshConcrete">
                    {t`Fresh concrete temperature`}
                </Select.Option>
                <Select.Option key="differenceFreshConcreteAndSurface" value="differenceFreshConcreteAndSurface">
                    {t`Difference between fresh concrete and surface temperature`}
                </Select.Option>
                <Select.Option key="differenceSurfaceAndAir" value="differenceSurfaceAndAir">
                    {t`Difference between surface and air temperature`}
                </Select.Option>
                <Select.Option key="differenceInternalConcreteAndSurface" value="differenceInternalConcreteAndSurface">
                    {t`Difference between internal concrete and surface concrete`}
                </Select.Option>
            </Select>
        </FormItem>
    );
}
