import { EditOutlined, EllipsisOutlined, ImportOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { UserCompany } from '@nexploretechnology/concreting-core-client/user.manager-client/user.manager.dto';
import { Button, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import getProjectPermissions from '../../utils/getProjectPermissions';

export interface ProjectsTableColumnsProps {
    userCompany: UserCompany;
    onEditProject: (projectId: string) => void;
    onEnterProject: (projectId: string, marketId?: string) => void;
    onEditSupplier: (projectId: string) => void;
    onManageUsers: (projectId: string) => void;
}

function getActions(userCompany: UserCompany, projectId: string): MenuProps['items'] {
    const { isProjectAdmin, isProjectManager } = getProjectPermissions(userCompany, projectId);
    const items: MenuProps['items'] = [];

    if (isProjectManager || isProjectAdmin) {
        items.push({
            label: <Trans>Invite user</Trans>,
            key: `${projectId} UserManagement`,
            icon: <UserOutlined />
        });
    }

    if (isProjectManager || isProjectAdmin) {
        items.push({
            label: <Trans>Edit</Trans>,
            key: `${projectId} EditProject`,
            icon: <EditOutlined />
        });
    }

    if (isProjectAdmin) {
        items.push({
            label: <Trans>Edit supplier config</Trans>,
            key: `${projectId} EditSupplierConfig`,
            icon: <SettingOutlined />
        });
    }

    return items;
}

export const projectsTableColumns: (props: ProjectsTableColumnsProps) => ColumnsType<Project> = ({
    onEditProject,
    onEnterProject,
    onEditSupplier,
    onManageUsers,
    userCompany
}: ProjectsTableColumnsProps) => {
    const handleMenuClick: MenuProps['onClick'] = (event) => {
        const [projectId, key] = event.key.split(' ');

        switch (key) {
            case 'UserManagement':
                onManageUsers(projectId);
                break;
            case 'EditProject':
                onEditProject(projectId);
                break;
            case 'EditSupplierConfig':
                onEditSupplier(projectId);
                break;
            default:
        }
    };

    return [
        {
            title: <Trans>Project Number</Trans>,
            dataIndex: 'number',
            key: 'number',
            sorter: true
        },
        {
            title: <Trans>Project Name</Trans>,
            dataIndex: 'name',
            key: 'name',
            sorter: true
        },
        {
            title: <Trans>Construction start</Trans>,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            render: (startDate: Date) => <DateFormatter date={startDate} />
        },
        {
            title: <Trans>Construction end</Trans>,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
            render: (endDate: Date) => <DateFormatter date={endDate} />
        },
        {
            title: null,
            dataIndex: 'enterProject',
            render: (_: string, record: Project) => {
                const { isProjectAdmin, isProjectManager, isForeman, isConcreteEngineer } = getProjectPermissions(
                    userCompany,
                    record.id
                );

                if (!(!isProjectAdmin || (isProjectAdmin && (isForeman || isConcreteEngineer || isProjectManager)))) {
                    return null;
                }

                return (
                    <Button
                        className="projects-table__button"
                        data-testid={`enter-project-button-${record.id}`}
                        type="link"
                        size="small"
                        onClick={() => onEnterProject(record.id, record.marketId)}>
                        <ImportOutlined /> <Trans>Enter project</Trans>
                    </Button>
                );
            }
        },
        {
            title: null,
            dataIndex: 'actions',
            render: (_: string, record: Project) => {
                const actions = getActions(userCompany, record.id);

                if (!actions.length) {
                    return null;
                }

                const items = actions.map((item) => {
                    return {
                        ...item,
                        onClick: handleMenuClick
                    };
                });

                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className="projects-table__3dotIcon" />
                    </Dropdown>
                );
            }
        }
    ];
};
