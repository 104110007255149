/* eslint-disable react/function-component-definition */
import { t } from '@lingui/macro';
import { ProjectMarketIdEditable } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { Project } from '@nexploretechnology/concreting-core-client/project.management-client/project.management.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Form, Modal, Spin } from 'antd';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import { ProjectForm } from './Form';
import { cleanForm, projectToFormData } from './utils';

export interface ProjectFormValues extends Omit<Project, 'startDate' | 'endDate'> {
    startDate: Moment;
    endDate: Moment;
}

export interface ProjectModalFormProps {
    projectId?: string;
    companyId: string;
    isEditMode: boolean;
    hideModal: () => void;
    showModal: boolean;
    onComplete: () => void;
}

const ProjectModalForm: React.FunctionComponent<ProjectModalFormProps> = ({
    projectId,
    companyId,
    isEditMode,
    hideModal,
    showModal,
    onComplete
}) => {
    const [form] = Form.useForm();
    const [viewModal, setViewModal] = useState(false);
    const [project, setProject] = useState<Project>({} as Project);
    const [loading, setLoading] = useState(!!isEditMode); // don't show the spinner if the create modal is opened
    const [allowToUpdateMarketId, setAllowToUpdateMarketId] = useState<boolean>(true);
    const api = useApi();

    useEffect(() => {
        form.setFieldsValue({ constructionCountry: 'GE' });
    }, []);

    const onCancelModal = () => {
        hideModal();
        setViewModal(false);
    };

    const getProject = async () => {
        if (projectId) {
            api.projectManagementClient
                .getProject(companyId, projectId)
                .then((response) => {
                    if (response.isSuccess()) {
                        const data = response.getEntity();
                        setProject(response.getEntity());
                        form.setFieldsValue(projectToFormData(data));
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info: any) => {
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        }

        setLoading(false);
    };

    useEffect(() => {
        if (showModal && projectId) {
            setLoading(true);
            getProject();
        }
    }, [projectId, showModal]);

    useEffect(() => {
        if (isEditMode && projectId && projectId.length > 0) {
            api.projectManagementClient
                .getProjectHasOrdersOrCatalogue(companyId, projectId)
                .then((response: SimpleClientResponse<ProjectMarketIdEditable>) => {
                    if (response.isSuccess()) {
                        const projectMarketIdEditable: ProjectMarketIdEditable = response.getEntity();
                        setAllowToUpdateMarketId(!projectMarketIdEditable.hasOrdersOrCatalogue);
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                });
            // exception handling won't work like this in the pipeline. somehow ErrorNotification breaks it. with just console.log it works
            // .catch((e) => ErrorNotification({ message: e.code, description: e.message }));
        }
    }, [projectId]);

    const onFinish = (fieldsValue: ProjectFormValues) => {
        form.validateFields()
            .then(() => {
                const values = {
                    ...fieldsValue,
                    startDate: fieldsValue.startDate.utc(true).toString(),
                    endDate: fieldsValue.endDate.utc(true).toString()
                };

                if (project?.id) {
                    api.projectManagementClient
                        .updateProject(values, companyId, project.id)
                        .then((response) => {
                            if (response.isSuccess()) {
                                onComplete();
                                cleanForm(form);
                            } else {
                                ErrorNotification({ message: response.getError(), description: '' });
                            }
                        })
                        .catch((e) => ErrorNotification({ message: e.code, description: e.message }));
                } else {
                    api.projectManagementClient
                        .createProject(values, companyId)
                        .then((response) => {
                            if (response.isSuccess()) {
                                onComplete();
                                cleanForm(form);
                            } else {
                                ErrorNotification({ message: response.getError(), description: '' });
                            }
                        })
                        .catch((e) => ErrorNotification({ message: e.code, description: e.message }));
                }
            })
            .catch((e) => {
                ErrorNotification({ message: e.code, description: e.message });
            });
    };

    return (
        <Modal
            maskClosable={false}
            data-testid={isEditMode ? 'edit-project-modal' : 'create-project-modal'}
            title={!isEditMode ? t`Create a new project` : t`Edit project`}
            open={viewModal || showModal}
            onCancel={onCancelModal}
            okText={!isEditMode ? t`Create` : t`Update`}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={form.submit}
            width={1000}
            destroyOnClose>
            {loading ? (
                <div style={{ margin: '20px', padding: '30px 50px', textAlign: 'center' }}>
                    <Spin />
                </div>
            ) : (
                <ProjectForm
                    form={form}
                    project={project}
                    onFinish={onFinish}
                    allowToUpdateMarketId={allowToUpdateMarketId}
                />
            )}
        </Modal>
    );
};

export default ProjectModalForm;
