import { Trans, t } from "@lingui/macro";
import { Select } from "antd";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";

export function PurposeOfTestingFormItem() {
    return <SelectFormItem
        name="purposeOfTesting"
        label={<Trans>Purpose Of Testing</Trans>}
        required={true}
    >
        <Select data-testid="purposeOfTesting" placeholder={t`Select the purpose of testing`}>
            <Select.Option key="fieldTest" value="fieldTest">
                {t`Field test`}
            </Select.Option>
            <Select.Option key="labTest" value="labTest">
                {t`Lab test`}
            </Select.Option>
            <Select.Option key="strippingTest" value="strippingTest">
                {t`Stripping test`}
            </Select.Option>
            <Select.Option key="other" value="other">
                {t`Other`}
            </Select.Option>
        </Select>
    </SelectFormItem>
}