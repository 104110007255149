import { Trans } from '@lingui/macro';
import {
    CataloguedStructuralElementExtendedBP,
    ConcreteTypeMinimalBP,
    DataList,
    IsActiveBP,
    OffsetPagination,
    SimpleClientResponse,
    StructuralElementsWithPaginationParamsBP
} from '@nexploretechnology/concreting-core-client';
import { Button, Modal, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { paginationAdapterReactAntdToBCQD } from 'src/app-react/utils/pagination';
import { StructuralElementModal } from '../../../../components/Modals/StructuralElementModal/StructuralElementModal';
import AssignConcreteType from '../AssignConcreteType/assign-concrete-type';
import ConcreteTypesTable from '../ConcreteTypesTable/ConcreteTypesTable';
import StructuralElementModalForm, {
    IStructuralElementDE,
    IStructuralElementUS
} from '../StructuralElementModalForm/StructuralElementModalForm';
import { StructuralElementsTableColumnsDE } from './StructuralElementsTable.columns.de';
import { StructuralElementsTableColumnsUS } from './StructuralElementsTable.columns.us';
const DE = 'DE';
const US = 'US';
const AUS = 'AUS';

function StructuralElementsTable(props: {
    reLoad: boolean;
    setReLoad: (value: boolean) => void;
    setCount: React.Dispatch<React.SetStateAction<number>>;
}) {
    const { reLoad, setReLoad, setCount } = props;

    const { projectId, companyId, marketId } = useProjectState();

    const [tableDataDE, setTableDataDE] = useState<CataloguedStructuralElementExtendedBP[]>();
    const [tableDataUS, setTableDataUS] = useState<CataloguedStructuralElementExtendedBP[]>();

    const [structuralElementModalVisible, setStructuralElementModalVisible] = useState(false);

    const [assignUnassignModalVisible, setAssignUnassignModalVisible] = useState(false);
    const [assignUnassignModalAction, setAssignUnassignModalAction] = useState<'Assign' | 'Unassign'>();

    const [triggerReloadConcreteTypeTable, setTriggerReloadConcreteTypeTable] = useState<boolean>(false);

    const [action, setAction] = useState('create');
    const [initialValues, setInitialValues] = useState({} as IStructuralElementDE | IStructuralElementUS);
    const [initialValuesAssign, setInitialValuesAssign] = useState<string[]>([]);
    const [caSeIdAssign, setCaSeIdAssign] = useState<string>('');

    const [isPopupConfirmActiveModalVisible, setIsPopupConfirmActiveModalVisible] = useState(false);

    const api = useApi();

    interface PopupConfirmActiveModalData {
        structuralElement: CataloguedStructuralElementExtendedBP;
        active: boolean;
    }
    const [popupConfirmActiveModalData, setPopupConfirmActiveModalData] = useState<PopupConfirmActiveModalData>();

    const [catalogueStructuralElementModalVisible, setCatalogueStructuralElementModalVisible] = useState(false);
    const [catalogueStructuralElementModalInitialValues, setCatalogueStructuralElementModalInitialValues] = useState(
        {} as CataloguedStructuralElementExtendedBP
    );

    const tableRef = useRef({ pageSize: 10, pageIndex: 1 });
    const [pagination, setPagination] = useState({
        position: ['bottomRight'],
        pageSize: tableRef.current.pageSize,
        total: 0,
        showSizeChanger: true
    } as TablePaginationConfig);

    const offsetPagination = useRef<OffsetPagination<CataloguedStructuralElementExtendedBP>>({
        skip: (pagination.pageSize! - 1) * pagination.total!,
        limit: tableRef.current.pageSize,
        sorting: []
    });

    const loadTableData = async (): Promise<void> => {
        await getStructuralElements(offsetPagination.current);
        setReLoad(false);
    };

    const getStructuralElements = async (pagination: OffsetPagination<CataloguedStructuralElementExtendedBP>) => {
        const requestParameters: StructuralElementsWithPaginationParamsBP = {
            pagination: { ...pagination, sorting: [] }
        };
        await api.catalogueManagementClient
            .getExtendedCataloguedStructuralElementsForProjectAsDataList(companyId, projectId, requestParameters)
            .then((response) => {
                if (response.isSuccess()) {
                    const { data, count }: DataList<CataloguedStructuralElementExtendedBP> = response.getEntity();

                    if (marketId === 'DE') {
                        setTableDataDE(data);
                    } else {
                        setTableDataUS(data);
                    }
                    setCount(count);
                    setPagination({
                        position: ['bottomRight'],
                        pageSize: tableRef.current.pageSize,
                        total: count,
                        showSizeChanger: true
                    });
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    useEffect(() => {
        loadTableData();
        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            loadTableData();
        }, 20001);

        return () => clearInterval(interval);
    }, [reLoad, projectId, companyId]);

    const completed = async (): Promise<void> => {
        try {
            setInitialValues({} as IStructuralElementDE | IStructuralElementUS);
            await loadTableData();
            setTriggerReloadConcreteTypeTable(!triggerReloadConcreteTypeTable);
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    const AssignConcreteType_onComplete = async (): Promise<void> => {
        try {
            setInitialValues({} as IStructuralElementDE | IStructuralElementUS);
            setReLoad(true);
            setTriggerReloadConcreteTypeTable(!triggerReloadConcreteTypeTable);
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    function onEdit(structuralElement: CataloguedStructuralElementExtendedBP): void {
        getPrefillEdit(structuralElement);

        setAction('edit');

        setStructuralElementModalVisible(true);
    }

    function onDuplicate(structuralElement: CataloguedStructuralElementExtendedBP): void {
        getPrefillDuplicate(structuralElement);

        setAction('duplicate');
        setStructuralElementModalVisible(true);
    }

    function onActiveInactive(structuralElement: CataloguedStructuralElementExtendedBP): void {
        setPopupConfirmActiveModalData({
            structuralElement,
            active: !structuralElement.isActive
        });
        setIsPopupConfirmActiveModalVisible(true);
    }

    function onAssign(structuralElement: CataloguedStructuralElementExtendedBP): void {
        if (structuralElement?.id) {
            setInitialValuesAssign([]);
            setCaSeIdAssign(structuralElement.id);
            setAssignUnassignModalAction('Assign');
            setAssignUnassignModalVisible(true);
        }
    }

    function onUnassign(structuralElement: CataloguedStructuralElementExtendedBP): void {
        if (structuralElement?.id) {
            const concreteTypesId: string[] = [];
            if (structuralElement.assignedConcreteTypes) {
                structuralElement.assignedConcreteTypes.forEach((element) => {
                    if (element.id) {
                        concreteTypesId.push(element.id);
                    }
                });
            }

            setInitialValuesAssign(concreteTypesId);
            setCaSeIdAssign(structuralElement.id);
            setAssignUnassignModalAction('Unassign');
            setAssignUnassignModalVisible(true);
        }
    }

    const generateRequiredXf = (requiredXfFirst: number | undefined, requiredXfSecond: number | undefined): string => {
        if (requiredXfFirst && requiredXfSecond) {
            return '2/3';
        }
        if (requiredXfFirst) {
            return `${requiredXfFirst}`;
        }
        return '';
    };

    const baseDE = (structuralElement: CataloguedStructuralElementExtendedBP): IStructuralElementDE => {
        if (structuralElement) {
            return {
                name: structuralElement.structuralElementName,
                elementDescription: structuralElement.structuralElementDescription,
                planNumber: structuralElement.planNumber ? structuralElement.planNumber : undefined,

                typeOfConcrete: structuralElement.minimalRequiredTypeOfConcreteType,
                cylindricCompressiveStrength: structuralElement.minimalRequiredCylindricCompressiveStrength,
                cubicCompressiveStrength: structuralElement.minimalRequiredCubicCompressiveStrength,

                boqPositions: structuralElement.boqPositions,
                moistureClass: structuralElement.minimalRequiredMoistureClass,
                requiredX0: structuralElement.requiredX0,
                requiredXc: structuralElement.minimalRequiredXc,
                requiredXd: structuralElement.minimalRequiredXd,
                requiredXf: generateRequiredXf(
                    structuralElement.minimalRequiredXfFirst,
                    structuralElement.minimalRequiredXfSecond
                ),
                requiredXa: structuralElement.minimalRequiredXa,
                requiredXm: structuralElement.minimalRequiredXm,
                requiredXs: structuralElement.minimalRequiredXs,
                strengthDevelopment: structuralElement.minimalRequiredStrengthDevelopment
                    ? structuralElement.minimalRequiredStrengthDevelopment
                    : undefined,
                methodOfConcrete: structuralElement.requiredMethodOfConcreteUse,
                chlorideContent: structuralElement.requiredChlorideContentClass,
                externalInspection: structuralElement.externalInspection,
                densityClass: structuralElement.requiredDensityClass,
                concreteTypeIds: getConcreteTypeIds(structuralElement.assignedConcreteTypes)
            };
        }
        return {} as IStructuralElementDE;
    };

    const baseUS = (structuralElement: CataloguedStructuralElementExtendedBP) => {
        if (structuralElement) {
            return {
                name: structuralElement.structuralElementName,
                elementDescription: structuralElement.structuralElementDescription,
                methodOfConcrete: structuralElement.requiredMethodOfConcreteUse
                    ? structuralElement.requiredMethodOfConcreteUse
                    : '',
                planNumber: structuralElement.planNumber,

                slump: String(structuralElement.requiredSlump ? structuralElement.requiredSlump : ''),
                slumpVariance: String(
                    structuralElement.maximalAllowedSlumpVariance ? structuralElement.maximalAllowedSlumpVariance : ''
                ),
                massConcrete: structuralElement.massConcreteRequired,
                compressiveStrength: structuralElement.minimalRequiredCompressiveStrength || 0,
                costCode: structuralElement.costCode,
                concreteTypeIds: getConcreteTypeIds(structuralElement.assignedConcreteTypes)
            };
        }
        return {} as IStructuralElementUS;
    };

    const getConcreteTypeIds = (concreteTypes: ConcreteTypeMinimalBP[] | undefined): string[] => {
        if (concreteTypes) {
            const ids: string[] = [];
            concreteTypes.forEach((element: ConcreteTypeMinimalBP) => {
                if (element.id) {
                    ids.push(element.id);
                }
            });
            return ids;
        }
        return [];
    };

    const getPrefillEdit = async (structuralElement: CataloguedStructuralElementExtendedBP): Promise<void> => {
        if (structuralElement) {
            if (marketId === DE) {
                setInitialValues({
                    id: structuralElement.id,
                    ...baseDE(structuralElement)
                });
            } else if (marketId === US || marketId === AUS) {
                setInitialValues({
                    id: structuralElement.id,
                    ...baseUS(structuralElement)
                });
            }
        }
    };

    const getPrefillDuplicate = async (structuralElement: CataloguedStructuralElementExtendedBP): Promise<void> => {
        if (structuralElement) {
            if (marketId === DE) {
                setInitialValues(baseDE(structuralElement));
            } else if (marketId === US) {
                setInitialValues({
                    ...baseUS(structuralElement)
                });
            }
        }
    };

    async function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<CataloguedStructuralElementExtendedBP>
            | SorterResult<CataloguedStructuralElementExtendedBP>[],
        extra: TableCurrentDataSource<CataloguedStructuralElementExtendedBP>
    ): Promise<void> {
        offsetPagination.current = paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra);

        loadTableData();
    }

    async function onChangeDE(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<CataloguedStructuralElementExtendedBP>
            | SorterResult<CataloguedStructuralElementExtendedBP>[],
        extra: TableCurrentDataSource<CataloguedStructuralElementExtendedBP>
    ): Promise<void> {
        tableRef.current = { pageSize: pagination.pageSize, pageIndex: pagination.current };
        sorter = sorter as SorterResult<CataloguedStructuralElementExtendedBP>;

        onChange(pagination, filters, sorter, extra);
    }

    async function onChangeUS(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<CataloguedStructuralElementExtendedBP>
            | SorterResult<CataloguedStructuralElementExtendedBP>[],
        extra: TableCurrentDataSource<CataloguedStructuralElementExtendedBP>
    ): Promise<void> {
        tableRef.current = { pageSize: pagination.pageSize, pageIndex: pagination.current };
        sorter = sorter as SorterResult<CataloguedStructuralElementExtendedBP>;

        onChange(pagination, filters, sorter, extra);
    }

    const isExpandible = (record: CataloguedStructuralElementExtendedBP): boolean => {
        if (!record.assignedConcreteTypes) {
            return false;
        }

        return record.assignedConcreteTypes.length > 0;
    };

    function onStructuralElementModal(structuralElement: CataloguedStructuralElementExtendedBP): void {
        setCatalogueStructuralElementModalInitialValues(structuralElement);
        setCatalogueStructuralElementModalVisible(true);
    }

    const popupConfirmActiveModal_onOK = async (): Promise<void> => {
        setIsPopupConfirmActiveModalVisible(false);

        const structuralElementIsActive: IsActiveBP = {
            isActive: !popupConfirmActiveModalData!.structuralElement.isActive
        };

        await api.catalogueManagementClient
            .updateIsActiveForCataloguedStructuralElement(
                companyId,
                projectId,
                popupConfirmActiveModalData!.structuralElement.id,
                structuralElementIsActive
            )
            .then((response: SimpleClientResponse<void>) => {
                if (response.isSuccess()) {
                    loadTableData();
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
    };

    const popupConfirmActiveModal_onCancel = (): void => {
        setIsPopupConfirmActiveModalVisible(false);
    };

    return (
        <>
            {marketId === DE ? (
                <Table
                    data-testid="StructuralElementsTable"
                    rowClassName="StructuralElementsTable-testid"
                    pagination={pagination}
                    onChange={onChangeDE}
                    showSorterTooltip={false}
                    scroll={{ x: 1 }}
                    columns={StructuralElementsTableColumnsDE({
                        onEdit,
                        onDuplicate,
                        onActiveInactive,
                        onAssign,
                        onUnassign,
                        onStructuralElementModal
                    })}
                    rowKey="id"
                    dataSource={tableDataDE}
                    expandable={{
                        expandedRowRender: (record) => (
                            <ConcreteTypesTable
                                structuralElementId={record.id}
                                triggerReloadConcreteTypeTable={triggerReloadConcreteTypeTable}
                                setTriggerReloadConcreteTypeTable={setTriggerReloadConcreteTypeTable}
                            />
                        ),
                        rowExpandable: (record) => isExpandible(record)
                    }}
                />
            ) : (
                <Table
                    data-testid="StructuralElementsTable"
                    rowClassName="StructuralElementsTable-testid"
                    pagination={pagination}
                    onChange={onChangeUS}
                    showSorterTooltip={false}
                    scroll={{ x: 1 }}
                    columns={StructuralElementsTableColumnsUS({
                        onEdit,
                        onDuplicate,
                        onActiveInactive,
                        onAssign,
                        onUnassign,
                        onStructuralElementModal
                    })}
                    rowKey="id"
                    dataSource={tableDataUS}
                    expandable={{
                        expandedRowRender: (record) => (
                            <ConcreteTypesTable
                                structuralElementId={record.id}
                                triggerReloadConcreteTypeTable={triggerReloadConcreteTypeTable}
                                setTriggerReloadConcreteTypeTable={setTriggerReloadConcreteTypeTable}
                            />
                        ),
                        rowExpandable: (record) => isExpandible(record)
                    }}
                />
            )}
            <StructuralElementModalForm
                data-testid="structural-element-modal-form-edit-duplicate"
                open={structuralElementModalVisible}
                setOpen={setStructuralElementModalVisible}
                onComplete={() => completed()}
                initialValues={initialValues}
                action={action}
            />
            <AssignConcreteType
                data-testid="concrete-type-assign-modal"
                open={assignUnassignModalVisible}
                initialValues={initialValuesAssign}
                action={assignUnassignModalAction}
                setOpen={setAssignUnassignModalVisible}
                onComplete={() => AssignConcreteType_onComplete()}
                structuralElementId={caSeIdAssign}
            />

            <StructuralElementModal
                structuralElementDetails={catalogueStructuralElementModalInitialValues}
                open={catalogueStructuralElementModalVisible}
                setOpen={setCatalogueStructuralElementModalVisible}
            />
            <Modal
                maskClosable={false}
                title={
                    popupConfirmActiveModalData?.active ? (
                        <Trans>Activating structural element</Trans>
                    ) : (
                        <Trans>Deactivating structural element</Trans>
                    )
                }
                open={isPopupConfirmActiveModalVisible}
                width={600}
                onOk={popupConfirmActiveModal_onOK}
                onCancel={popupConfirmActiveModal_onCancel}
                footer={[
                    <Button
                        data-testid="PopupConfirmActiveModal.cancelButton"
                        key="cancelButton"
                        onClick={popupConfirmActiveModal_onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>,
                    <Button
                        data-testid="PopupConfirmActiveModal.okButton"
                        key="okButton"
                        onClick={popupConfirmActiveModal_onOK}
                        type="primary">
                        <Trans>Save</Trans>
                    </Button>
                ]}>
                <Trans
                    id={
                        popupConfirmActiveModalData?.active
                            ? 'You are about to activate this structural element. This means it will now be available for ordering!'
                            : 'You are about to deactivate this structural element. This means it will not be available for ordering anymore!'
                    }
                />
            </Modal>
        </>
    );
}

export default StructuralElementsTable;
