import { notification } from 'antd';

export interface IErrorNotification {
    message: string;
    description: string;
}

const ErrorNotification = (props: IErrorNotification) => {
    const { message, description } = props;

    return notification.error({
        message,
        description
    });
};

export default ErrorNotification;
