import { ExclamationCircleOutlined } from '@ant-design/icons';
import { t } from '@lingui/macro';
import { ConcretingDocumentationClient } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.client';
import { TypeMeasurement } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Modal } from 'antd';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';

interface MeasurementDeleteModalProps {
    type: TypeMeasurement;
    companyId: string;
    projectId: string;
    structuralElementId: string;
    measurementTestId: string;
    concretingDocumentationClient: ConcretingDocumentationClient;
    triggerTableReload: () => void;
}

export function MeasurementDeleteModal(props: MeasurementDeleteModalProps) {
    const {
        companyId,
        projectId,
        structuralElementId,
        measurementTestId,
        triggerTableReload,
        type,
        concretingDocumentationClient
    } = props;

    function deleteMeasurement() {
        concretingDocumentationClient
            .deleteCoseTest(companyId, projectId, structuralElementId, measurementTestId)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message:
                            type === 'temperature'
                                ? t`Temperature measurement record has been deleted successfully`
                                : t`Compressive strength measurement record has been deleted successfully`,
                        description: ''
                    });
                    triggerTableReload();
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    return Modal.warning({
        title: <b>{t`Do you want to delete this record?`}</b>,
        width: 600,
        icon: <ExclamationCircleOutlined />,
        content: t`You are about to delete this measurement. It will no longer be available.`,
        okText: t`Confirm`,
        okType: 'primary',
        okButtonProps: { className: 'measurementCancelModal' },
        okCancel: true,
        cancelText: t`Close`,
        onOk() {
            deleteMeasurement();
        }
    });
}
