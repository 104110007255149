import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function TypeOtherFormItem() {
    return (
        <FormItem
            name="typeOther"
            label={<b><Trans>Other Type</Trans></b>}
            rules={[{
                whitespace: true,
                required: true,
                message: <Trans>Please add an other type</Trans>
            }]}
            style={{gap: '.2rem'}}>
             <Input
                data-testid="typeOther"
                placeholder={t`Enter other type`}
            />
        </FormItem>
    );
}
