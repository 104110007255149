/* eslint-disable react/jsx-props-no-spreading */
import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

function DeliveryNoteNumberFormItem(props: FormItemInputProps) {
    return (
        <Form.Item
            name="deliveryNoteNumber"
            label={<Trans>Delivery note number</Trans>}
            rules={[requiredRule]}
            {...props}>
            <Input placeholder={t`Enter delivery note number`} data-testid="deliveryNoteNumberFormItem" />
        </Form.Item>
    );
}

export default DeliveryNoteNumberFormItem;
