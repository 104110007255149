import { FormInstance } from 'antd';
import { TestSamplingWithType } from '../CommonTestForm/models';
import { RelativeHumidityTestFormValues } from './models';
import { newFreshConcreteTest } from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import moment from 'moment';
import { RelativeHumidityTest } from '@nexploretechnology/concreting-core-client';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValues(values: any) {
    return {
        testingDate: values?.testingDate !== undefined ? moment(values.testingDate) : undefined,
        testingTime: values?.testingTime !== undefined ? moment(values.testingTime) : undefined,
        relativeHumidity: values?.relativeHumidity
    };
}

export function getRelativeHumidityFormValues(values: FormInstance) {
    return {
        testingDate: (values.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (values.getFieldValue('testingTime') as moment.Moment).toDate(),
        relativeHumidity: Number(values.getFieldValue('relativeHumidity') as string)
    };
}

export function createRelativeHumidityTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    commonTest: TestSamplingWithType,
    relativeHumidityTestFormValues: RelativeHumidityTestFormValues
): RelativeHumidityTest {
    const freshConcreteTest: FreshConcreteTest = newFreshConcreteTest(
        companyId,
        projectId,
        orderId,
        concreteLoadId,
        commonTest
    );

    const testingTime: Date = mergeDateTime(
        moment(relativeHumidityTestFormValues.testingDate),
        moment(relativeHumidityTestFormValues.testingTime)
    ).toDate();

    const relativeHumidityTest: RelativeHumidityTest = {
        ...freshConcreteTest,
        relativeHumidity: relativeHumidityTestFormValues.relativeHumidity,
        testingTime
    };

    return relativeHumidityTest;
}
