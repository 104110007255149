import { Trans, t } from "@lingui/macro"
import { Form, Input } from "antd"


export function CostCodeItem(){
    return (
        <Form.Item label={<Trans>Cost Code</Trans>} name="costCode" rules={[]}>
            <Input type="text" placeholder={t`Enter cost code`} data-testid="costCode" />
        </Form.Item>
    )
}