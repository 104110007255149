import { t } from '@lingui/macro';
import { CommentConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';

export const getRejectComment = (comments: CommentConcreteLoad[]): string => {
    const comment: CommentConcreteLoad[] = comments.filter((c) => c.actionConcreteLoad === 'rejectConcreteLoad');
    if (comment.length > 0) {
        return comment[0].comment;
    }
    return '';
};

export function mapReasonCode(reason: string): string {
    switch (reason) {
        case 'truckTooLate':
            return t`Truck too late`;
        case 'truckTooEarly':
            return t`Truck too early`;
        case 'wrongDelivery':
            return t`Wrong delivery`;
        case 'qualityInadequate':
            return t`Quality inadequate`;
        case 'recalledBySupplier':
            return t`Recalled by supplier`;
        case 'truckMalfunction':
            return t`Truck malfunction`;
        case 'weather':
            return t`Weather`;
        default:
            return t`Other`;
    }
}
