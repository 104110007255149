import { t } from '@lingui/macro';
import { FormInstance, Rule } from 'antd/lib/form';
import { NamePath } from 'antd/lib/form/interface';
import moment from 'moment';
import { mergeDateTime } from 'src/app-react/utils/lib';

interface DateTimeWithDependenciesRuleParams {
    name: string;
    disable?: boolean;
    message?: string;
    before?: NamePath[];
    sameOrBefore?: NamePath[];
    sameOrAfter?: NamePath[];
    requiredWhen?: NamePath[];
    after?: NamePath[];
}

function getDate({ getFieldValue }: FormInstance, fieldName: NamePath): moment.Moment | undefined {
    const time = getFieldValue(fieldName);
    const date = getFieldValue(`${fieldName}Date`);

    if (date && time) {
        return mergeDateTime(date, time);
    }

    return time;
}

function dateTimeWithDependenciesRule({
    disable,
    message,
    requiredWhen,
    before,
    sameOrBefore,
    sameOrAfter,
    after,
    name
}: DateTimeWithDependenciesRuleParams): Rule {
    return (form) => ({
        async validator() {
            if (disable) {
                return;
            }

            const date = getDate(<FormInstance>form, name);

            if (date) {
                const errorBefore = before?.some((dependencyName) => {
                    const date2 = getDate(<FormInstance>form, dependencyName);
                    return date2 && date.isBefore(date2, 'minutes');
                });

                const errorSameOrBefore = sameOrBefore?.some((dependencyName) => {
                    const date2 = getDate(<FormInstance>form, dependencyName);
                    return date2 && date.isSameOrBefore(date2, 'minutes');
                });

                const errorAfter = after?.some((dependencyName) => {
                    const date2 = getDate(<FormInstance>form, dependencyName);
                    return date2 && date.isAfter(date2, 'minutes');
                });

                const errorSameOrAfter = sameOrAfter?.some((dependencyName) => {
                    const date2 = getDate(<FormInstance>form, dependencyName);
                    return date2 && date.isSameOrAfter(date2, 'minutes');
                });

                if (errorBefore || errorSameOrBefore || errorSameOrAfter || errorAfter) {
                    return Promise.reject(new Error(message || t`invalid time`));
                }
            } else {
                const errorRequired = requiredWhen?.some((dependencyName) => {
                    const date2 = getDate(<FormInstance>form, dependencyName);
                    return date2;
                });

                if (errorRequired) {
                    return Promise.reject(new Error(message || t`invalid time`));
                }
            }

            return Promise.resolve();
        }
    });
}

export default dateTimeWithDependenciesRule;
