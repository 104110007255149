import { EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { ConcretedLoadForConcretingDocumentationBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link, NavigateFunction } from 'react-router-dom';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import { MutableGlobalContextValue } from 'src/app-react/providers/GlobalContext';
import { weatherMapper } from 'src/app-react/utils/formatters';
import { getTestAsArray, joinValuesAndUnit, joinValuesAndUnits } from './dataTableConcretedLoad.columns.de';

interface ConcretingDocModal {
    (param: string): void;
}

export const concretedLoadTableColumnsUS: (
    navigate: NavigateFunction,
    setGlobalState: (state: Partial<MutableGlobalContextValue>) => void,
    projectId: string,
    displaySpecimenModal: ConcretingDocModal,
    displayConcreteTypeModal: (record: ConcretedLoadForConcretingDocumentationBP) => Promise<void>,
    onDeliveryNoteNumberClick: (record: ConcretedLoadForConcretingDocumentationBP) => void,
    enableSorting: boolean
) => ColumnsType<ConcretedLoadForConcretingDocumentationBP> = (
    navigate,
    setGlobalState,
    projectId,
    displaySpecimenModal,
    displayConcreteTypeModal,
    onDeliveryNoteNumberClick,
    enableSorting
) => [
    {
        title: <Trans>Delivery note</Trans>,
        children: [
            {
                title: <Trans>Concreting Start</Trans>,
                key: 'concretingStartDate',
                dataIndex: 'concretingStartDate',
                width: 120,
                sorter: enableSorting,
                render: (concretingStartDate: Date) => <DateTimeFormatter date={concretingStartDate} />
            },

            {
                title: <Trans>Concreting end</Trans>,
                key: 'concretingEndDate',
                dataIndex: 'concretingEndDate',
                width: 120,
                sorter: enableSorting,
                render: (concretingEndDate: Date) => <DateTimeFormatter date={concretingEndDate} />
            },

            {
                title: <Trans>Delivery note number</Trans>,
                key: 'deliveryNoteNumber',
                dataIndex: 'deliveryNoteNumber',
                width: 120,
                sorter: enableSorting,
                render: (deliveryNoteNumber: string, record: ConcretedLoadForConcretingDocumentationBP) => (
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            onDeliveryNoteNumberClick(record);
                        }}
                        to="">
                        {deliveryNoteNumber}
                    </Link>
                )
            }
        ]
    },
    {
        title: <Trans>Delivery details</Trans>,
        children: [
            {
                title: <Trans>Concrete type</Trans>,
                key: 'number',
                dataIndex: 'number',
                width: 120,
                sorter: enableSorting,
                render: (number: string, record: ConcretedLoadForConcretingDocumentationBP) => (
                    <Link
                        onClick={(e) => {
                            e.preventDefault();
                            displayConcreteTypeModal(record);
                        }}
                        to="">{`${number}`}</Link>
                )
            },
            {
                title: <Trans>Mixing plant</Trans>,
                key: 'mixingPlant',
                width: 120,
                dataIndex: 'mixingPlant',
                sorter: enableSorting
            },

            {
                title: <Trans>Concreted quantity</Trans>,
                key: 'volume',
                dataIndex: 'volume',
                width: 120,
                sorter: enableSorting,
                render: (volume: number, record: ConcretedLoadForConcretingDocumentationBP) => (
                    <NumberFormatter value={volume} unit={record.volumeUnit} />
                )
            },

            {
                title: <Trans>Compressive strength</Trans>,
                key: 'compressiveStrength',
                dataIndex: 'compressiveStrength',
                sorter: true,
                width: 130,
                render: (compressiveStrength: number, record: ConcretedLoadForConcretingDocumentationBP) =>
                    compressiveStrength && record.compressiveStrengthUnit
                        ? `${compressiveStrength} ${record.compressiveStrengthUnit}`
                        : ''
            },

            {
                title: <Trans>Weather</Trans>,
                key: 'weather',
                dataIndex: 'weather',
                width: 130,
                sorter: enableSorting,
                render: (weather: string) => <p>{weatherMapper[weather]}</p>
            }
        ]
    },

    {
        title: <Trans>Concrete tests</Trans>,
        children: [
            {
                title: <Trans>Test specimen number</Trans>,
                key: 'testSpecimenIdentifier',
                dataIndex: 'testSpecimenIdentifier',
                width: 150,
                sorter: enableSorting,
                render: (testSpecimenIdentifier: string) =>
                    !testSpecimenIdentifier ? (
                        ''
                    ) : (
                        <>
                            {(() => {
                                const list = getTestAsArray(testSpecimenIdentifier);
                                return list.map((testSpeciment: string, index: number) => (
                                    <p>
                                        <RawLinkButton key={index} onClick={() => displaySpecimenModal(testSpeciment)}>
                                            <p>
                                                {testSpeciment}
                                                {index + 1 < list.length ? ',' : ''}
                                            </p>
                                        </RawLinkButton>
                                    </p>
                                ));
                            })()}
                        </>
                    )
            },

            {
                title: <Trans>Consistency test</Trans>,
                key: 'diameterMeasure',
                dataIndex: 'diameterMeasure',
                width: 130,
                sorter: enableSorting,
                render: (diameterMeasure: number, record: ConcretedLoadForConcretingDocumentationBP) =>
                    !diameterMeasure ? (
                        ''
                    ) : (
                        <>
                            {(() => {
                                const list = joinValuesAndUnits(record.diameterMeasure, record.unitDiameterMeasure);
                                return list.map((diameterMeasure: string, index: number) => (
                                    <p key={index}>
                                        {diameterMeasure}
                                        {index + 1 < list.length ? ',' : ''}
                                    </p>
                                ));
                            })()}
                        </>
                    )
            },

            {
                title: <Trans>Temperature test</Trans>,
                key: 'freshConcreteTemperature',
                dataIndex: 'freshConcreteTemperature',
                width: 130,
                sorter: enableSorting,
                render: (freshConcreteTemperature: number, record: ConcretedLoadForConcretingDocumentationBP) =>
                    !freshConcreteTemperature ? (
                        ''
                    ) : (
                        <>
                            {(() => {
                                const list = joinValuesAndUnits(
                                    record.freshConcreteTemperature,
                                    record.unitFreshConcreteTemperature
                                );
                                return list.map((freshConcreteTemperature: string, index: number) => (
                                    <p key={index}>
                                        {freshConcreteTemperature}
                                        {index + 1 < list.length ? ',' : ''}
                                    </p>
                                ));
                            })()}
                        </>
                    )
            },

            {
                title: <Trans>Air content</Trans>,
                key: 'airContent',
                dataIndex: 'airContent',
                width: 130,
                sorter: enableSorting,
                render: (airContent: string) =>
                    !airContent ? (
                        ''
                    ) : (
                        <>
                            {(() => {
                                const list = joinValuesAndUnit(airContent, 'Vol.%');
                                return list.map((airContent: string, index: number) => (
                                    <p key={index}>
                                        {airContent}
                                        {index + 1 < list.length ? ',' : ''}
                                    </p>
                                ));
                            })()}
                        </>
                    )
            }

            // {
            //   title: <Trans>Bulk density</Trans>,
            //   key: 'bulkDensity',
            //   dataIndex: 'bulkDensity',
            //   width: 130,
            //   sorter: enableSorting,
            //   render: (bulkDensity: string[]) =>
            //     !bulkDensity || bulkDensity.length === 0
            //       ? ''
            //       : bulkDensity.map((test: string, index: number) => <p key={index}>{test}</p>)
            // },
        ]
    },
    {
        title: <Trans>Actions</Trans>,
        key: 'editButton',
        dataIndex: 'editButton',
        width: 120,
        render: (_, record: ConcretedLoadForConcretingDocumentationBP) => {
            const items: MenuProps['items'] = [
                {
                    key: `${record.concretedLoadId} View delivery details`,
                    label: <Trans>View delivery details</Trans>,
                    icon: <FormOutlined />,
                    onClick: () => {
                        setGlobalState({ coseFocused: record.concretedStructuralElementId });
                        navigate(
                            `/projects/${projectId}/orders/${record.orderId}/delivery-notes-details/${record.concreteLoadId}/details`
                        );
                    }
                }
            ];
            return (
                <Dropdown key="actionEdit" data-testid="actions" menu={{ items }} trigger={['click']}>
                    <EllipsisOutlined />
                </Dropdown>
            );
        }
    }
];

function editRow(): void {}
