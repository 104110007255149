import { PlusOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Market } from '@nexploretechnology/concreting-core-client';
import { UserManagement } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { Button, Col, PageHeader, Row, Select, Table } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import useUserManagementState from 'src/app-react/hooks/useUserManagermentState';
import ManageUsersForm from '../project/components/ManageUsersForm/ManageUsersForm';
import { DeleteUserModal } from './components/DeleteUserModal/DeleteUserModal';
import EditUserForm from './components/EditUsersForm/EditUserForm';
import { userManagementTableColumns } from './user.management.columns';

export type TProjectRoles = ['ProjectManager', 'Foreman', 'ConcreteEngineer', 'Subcontractor', 'Batcher'];

function UserManagementPage() {
    const { api } = useGlobalState();
    const [marketId, setMarketId] = useState<string>();
    const { userManagement, reloadUserManagement, filters } = useUserManagementState();
    const [selectedCompanyId, setSelectedCompanyId] = useLocalStorage('preferredCompany');
    const [selectedProjectId, setSelectedProjectId] = useLocalStorage('preferredProject');
    const [manageUsersFormVisible, setManageUsersFormVisible] = useState<boolean>(false);
    const [editUserVisible, setEditUserVisible] = useState<boolean>(false);
    const [prefilledUser, setPrefilledUser] = useState<UserManagement>();

    useEffect(() => {
        if (selectedCompanyId && selectedProjectId) {
            api.projectManagementClient.getProject(selectedCompanyId, selectedProjectId).then((res) => {
                if (res.isSuccess()) {
                    setMarketId(res.getEntity()?.marketId);
                }
            });
        }
    }, [api.projectManagementClient, selectedCompanyId, selectedProjectId]);

    const showEditUserModal = (record: UserManagement) => {
        setPrefilledUser(record);
        setEditUserVisible(true);
    };

    const hideEditUserModal = () => {
        setPrefilledUser(undefined);
        setEditUserVisible(false);
    };

    const showDeleteUserModal = (record: UserManagement) => {
        DeleteUserModal(api, record, () => {
            reloadUserManagement();
        });
    };

    return (
        <>
            <PageHeader title={t`User management`} backIcon={false} />
            <Row gutter={16}>
                <Col className="gutter-row" span={6}>
                    <Row style={{ fontWeight: 'bold' }}>
                        <Trans>Company</Trans>
                    </Row>
                    <Row>
                        <Select
                            data-testid="company-select"
                            value={userManagement?.find((x) => x.companyId === selectedCompanyId)?.companyId}
                            onChange={setSelectedCompanyId}
                            style={{ minWidth: '90%' }}>
                            {_.uniqWith(
                                userManagement?.map((x) => ({ companyId: x.companyId, companyName: x.companyName })),
                                _.isEqual
                            )?.map(({ companyId, companyName }) => {
                                return (
                                    <Select.Option key={companyId} value={companyId}>
                                        {companyName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Row>
                </Col>
                <Col className="gutter-row" span={6}>
                    <Row style={{ fontWeight: 'bold' }}>
                        <Trans>Project</Trans>
                    </Row>
                    <Row>
                        <Select
                            data-testid="project-select"
                            value={userManagement?.find((x) => x.projectId === selectedProjectId)?.projectId}
                            onChange={setSelectedProjectId}
                            style={{ minWidth: '90%' }}>
                            {_.uniqWith(
                                userManagement
                                    ?.filter((x) => x.companyId === selectedCompanyId)
                                    ?.map((x) => ({ projectId: x.projectId, projectName: x.projectName })),
                                _.isEqual
                            )?.map(({ projectId, projectName }) => {
                                return (
                                    <Select.Option key={projectId} value={projectId}>
                                        {projectName}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Row>
                </Col>
                <Col className="gutter-row" span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Row>
                        {selectedCompanyId && selectedProjectId && (
                            <Button
                                type="primary"
                                size="large"
                                data-testid="invite-user-btn"
                                onClick={() => setManageUsersFormVisible(true)}>
                                <PlusOutlined /> <Trans>Invite user</Trans>
                            </Button>
                        )}
                    </Row>
                </Col>
            </Row>
            <br />
            <Table
                data-testid="userManagement-table"
                columns={userManagementTableColumns(marketId, showEditUserModal, showDeleteUserModal, filters)}
                dataSource={userManagement?.filter((x) => {
                    let result = x.companyId === selectedCompanyId && x.projectId === selectedProjectId;
                    if (filters?.userName) {
                        result = result && x?.userName?.toLowerCase()?.includes(filters.userName.toLowerCase());
                    }
                    if (filters?.userEmail) {
                        result = result && x?.userEmail?.includes(filters.userEmail.toLowerCase());
                    }
                    return result;
                })}
                pagination={{ defaultPageSize: 10, showSizeChanger: true }}
            />
            <ManageUsersForm
                companyId={selectedCompanyId}
                projectId={selectedProjectId}
                showModal={manageUsersFormVisible}
                hideModal={() => setManageUsersFormVisible(false)}
                marketId={marketId as Market}
                onComplete={reloadUserManagement}
            />

            <EditUserForm
                prefilledUserManagement={prefilledUser}
                marketId={marketId as Market}
                hideModal={hideEditUserModal}
                showModal={editUserVisible}
                onComplete={reloadUserManagement}
            />
        </>
    );
}

export default UserManagementPage;
