import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function SupplierProjectIdFormItem() {
    return (
        <FormItem
            name="supplierProjectId"
            label={<Trans>Supplier software project number</Trans>}
            rules={[{
                required: true,
                message: (<Trans>Please input supplier software project number</Trans>)
            }]}>
            <Input
                type="text"
                data-testid="supplier-projectId"
                placeholder={t`Enter supplier software project number`}
            />
        </FormItem>
    );
}
