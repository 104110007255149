import { SupplierProducedConcreteAccumulativeVolume } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import {
  DeliveryNoteDetailsBP,
  DeliveryNoteFullDetails
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { SupplierConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

export interface DeliveryNoteAllDetails extends DeliveryNoteFullDetails {
    deliveryDetails: DeliveryNoteDetailsBP;
    supplierConcreteLoad?: SupplierConcreteLoad;
}

interface Props {
    orderId: string;
    concreteLoadId: string;
}

function useSupplierConcreteAccumulativeVolume({ orderId, concreteLoadId }: Props) {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [loading, setLoading] = useState<boolean>(false);

    const [volumeData, setVolumeData] = useState<SupplierProducedConcreteAccumulativeVolume>();

    const loadData = useCallback(async () => {
        setLoading(true);

        api.concretingDocumentationClient
            .getSupplierProducedConcreteAccumulativeVolume(companyId, projectId, orderId, concreteLoadId, '')
            .then((response) => {
                setLoading(false);
                setVolumeData(response.getEntity());
            })
            // eslint-disable-next-line no-shadow
            .catch((error: any) => {
                setLoading(false);
                setError({ message: error.code, description: error.message });
            });
    }, [api.concretingDocumentationClient, projectId, orderId, concreteLoadId, companyId]);

    return {
        volumeData,
        loadData,
        error,
        loading
    };
}

export default useSupplierConcreteAccumulativeVolume;
