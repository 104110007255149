import { t } from '@lingui/macro';
import { strengthDevelopmentFormatter } from 'src/app-react/utils/formatters';

import { numberFormat } from 'src/app-react/utils/formatters';

import { methodConcreteSelected } from './mapper.fields';
import { ConcreteTypeModalFormValuesDE, ConcreteTypeModalFormValuesUS } from './components/CatalogueConcreteTypeModalForm/types';
import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';


export function formatExposureClass(
    x0?: boolean,
    xc?: number,
    xd?: number,
    xfFirst?: number,
    xfSecond?: number,
    xa?: number,
    xm?: number,
    xs?: number
): string {
    return [
        x0 && `X0`,
        xc && `XC ${xc}`,
        xd && `XD ${xd}`,
        xfFirst && !xfSecond && `XF ${xfFirst}`,
        xfFirst && xfSecond && `XF ${xfFirst}/${xfSecond}`,
        xa && `XA ${xa}`,
        xm && `XM ${xm}`,
        xs && `XS ${xs}`
    ]
        .filter(Boolean)
        .join(', ');
}

export function formatCompressiveStrengthDE(
    requiredTypeOfConcreteType?: string,
    cylindricCompressiveStrength?: number,
    cubicCompressiveStrength?: number
) {

    let strengthClass: string = '';

    if (requiredTypeOfConcreteType) {
        strengthClass += `${requiredTypeOfConcreteType}`;
    }

    if (cylindricCompressiveStrength) {
        strengthClass += ` ${cylindricCompressiveStrength}`;
    }

    if (cubicCompressiveStrength) {
        strengthClass += `/${cubicCompressiveStrength}`;
    }

    return strengthClass;
}

export function formatCompressiveStrengthUS(compressiveStrength?: number, compressiveStrengthUnit?: string) {
    if (compressiveStrength && compressiveStrengthUnit) {
        return `${compressiveStrength} ${compressiveStrengthUnit}`;
    }
    return '';
}

export function formatConsistency(consistencyType?: string, consistencyClass?: number) {
    if (consistencyType) {
        return `${consistencyType}${consistencyClass}`;
    }
    return '';
}

export function formatStrengthDevelopment(strengthDevelopment?: number) {
    return strengthDevelopmentFormatter(strengthDevelopment);
}

export function formatSlump(slump?: number, slumpVariance?: number, slumpUnit?: string, empty?: string) {
    let fSlump = slump ? numberFormat(slump) : '';
    let fVariance = slumpVariance ? `± ${numberFormat(slumpVariance)}` : '';
    let fUnit = slumpUnit || '';

    return fSlump ? (fSlump + ' ' + fVariance + ' ' + fUnit) : empty || '';
}

export function formatPercentageByLocale(percentage: number | undefined, localeString: string): string {
    if (percentage) {
        if (localeString === 'de-DE') {
            return `${percentage} %`;
        }
        return `${percentage}%`;
    }
    return '';
}

export function formatDescription(description?: string): string {
    if (description) {
        return description;
    }
    return '';
}
export function formatStrengthDevelopmentClass(strengthDevelopmentClass?: string): string {
    if (strengthDevelopmentClass) {
        return strengthDevelopmentClass;
    }
    return '';
}
export function formatMaximumAggregateSize(maximumAggregateSize?: number): string {
    if (maximumAggregateSize) {
        return `${maximumAggregateSize}`;
    }
    return '';
}

export function concreteTypeExtendedBPToIConcreteTypeMapper(
    ct: ConcreteTypeExtendedBP
): ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS {
    return {
        id: ct.id,
        typeNumber: ct.concreteTypeNumber,
        description: ct.concreteTypeDescription,
        price: ct.price,
        canBeOrdered: ct.canBeOrdered,
        testingAge: ct.testingAge,
        waterCementRatio: ct.waterCementRatio?.toString() || ct.waterCementRatio,
        additionalAttributes: ct.additionalAttributes,
        cements: ct.ingredients
            ?.filter((ingredient) => ingredient.ingredientType === 'cement')
            ?.map((ingredient) => ({
                type: ingredient.ingredientName,
                origin: ingredient.ingredientOrigin,
                quantity: ingredient.quantity
            })),
        supplementaryCementiousMaterials: ct.ingredients
            ?.filter((ingredient) => ingredient.ingredientType === 'supplementaryCementiousMaterials')
            ?.map((ingredient) => ({
                type: ingredient.ingredientName,
                origin: ingredient.ingredientOrigin,
                quantity: ingredient.quantity
            })),
        admixtures: ct.ingredients
            ?.filter((x) => x.ingredientType === 'admixture')
            ?.map((ingredient) => ({
                type: ingredient.ingredientName,
                origin: ingredient.ingredientOrigin
            })),
        assignToStructuralElements: ct.assignedCataloguedStructuralElements?.map(
            (cataloguedStructuralElement) => cataloguedStructuralElement.id
        ),
        compressiveStrengthClass: {
            requiredTypeOfConcreteType: ct.typeOfConcrete,
            cylindricCompressiveStrength: ct.cylindricCompressiveStrength,
            cubicCompressiveStrength: ct.cubicCompressiveStrength
        },
        consistencyClass: {
            consistencyType: ct.consistencyType,
            consistencyClass: ct.consistencyClass
        },
        moistureClass: ct.moistureClass,
        exposureClasses: {
            requiredX0: ct.x0 ? ct.x0 : false,
            requiredXc: ct.xc,
            requiredXd: ct.xd,
            requiredXf: generateRequiredXf(ct.xfFirst, ct.xfSecond),
            requiredXa: ct.xa,
            requiredXm: ct.xm,
            requiredXs: ct.xs
        },
        strengthDevelopmentClass: ct.strengthDevelopmentClass,
        densityClass: ct.densityClass,
        methodOfConcrete: ct.methodOfConcreteUse,
        ChlorideContent: ct.chlorideContentClass,
        externalInspection: ct.externalInspection,
        maxAggregateSize: ct.maximumAggregateSize,
        aggregates: ct.ingredients
            ?.filter((ingredient) => ingredient.ingredientType === 'aggregate')
            ?.map((ingredient) => ({
                type: ingredient.ingredientName,
                origin: ingredient.ingredientOrigin,
                minAggregateSize: ingredient.minimumAggregateSize,
                maxAggregateSize: ingredient.maximumAggregateSize,
                quantity: ingredient.quantity
            })),
        compressiveStrength: ct.compressiveStrength,
        slump: ct.slump,
        slumpVariance: ct.slumpVariance,
        airContentInPercent: ct.airContentInPercent,
        submittalNumber: ct.submittalNumber,
        methodOfConcreteChlorideContent: methodConcreteSelected(ct.methodOfConcreteUse, ct.chlorideContentClass)
    } as unknown as ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS;
}
const generateRequiredXf = (requiredXfFirst: number | undefined, requiredXfSecond: number | undefined): string => {
    if (requiredXfFirst && requiredXfSecond) {
        return '2/3';
    } else if (requiredXfFirst) {
        return `${requiredXfFirst}`;
    } else {
        return '';
    }
};

export function mapStrengthDevelopmentClass(storedValue: string): string {
    switch (storedValue) {
        case 'fast':
            return t`Fast`;
        case 'intermediate':
            return t`Intermediate`;
        case 'slow':
            return t`Slow`;
        case 'very slow':
            return t`Very slow`;
        default:
            return '';
    }
}
