import { Trans } from '@lingui/macro';
import { BasicConcreteLoadData } from '@nexploretechnology/concreting-core-client';
import { Col, Row } from 'antd';
import { mapReasonCode } from 'src/app-react/business-page/delivery-details/components/delivery-details-table/util';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';

export function DeliveryDashboardRejectInfo({ record }: { record: BasicConcreteLoadData }) {
    return (
        <>
            <div style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '15px' }}>
                <Trans>Rejection details</Trans>
            </div>
            <Row gutter={24}>
                <Col span={4} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        <Trans>Rejection date</Trans>
                    </span>
                    <span>
                        <DateFormatter date={record.rejectedOn} />
                    </span>
                </Col>

                <Col span={4} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        <Trans>Reject reason</Trans>
                    </span>
                    <span>{mapReasonCode(record.rejectionReasonCode)}</span>
                </Col>

                <Col span={4} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        <Trans>Rejected by</Trans>
                    </span>
                    <span>{record.rejectedByName}</span>
                </Col>

                <Col span={4} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        <Trans>Concrete used</Trans>
                    </span>
                    {record.rejectedVolume !== undefined && (
                        <span>
                            {record.rejectedVolume} {record.volumeUnit}
                        </span>
                    )}
                </Col>

                <Col span={4} style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                        <Trans>Comment</Trans>
                    </span>
                    <span>{record.rejectedComment}</span>
                </Col>
            </Row>
        </>
    );
}
