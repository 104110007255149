import { Trans, t } from '@lingui/macro';
import { DatePicker, Form, FormInstance, Radio, RadioChangeEvent, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';

export function FormAUS({ form }: { form: FormInstance<any> }) {
    const api = useApi();
    const { companyId, projectId } = useProjectState();
    const [supplierOrderIds, setSupplierOrderIds] = useState<string[]>([]);
    const [fileTypeOption, setFileTypeOption] = useState<string>();

    const { formats } = useProjectConstantsState();

    useEffect(() => {
        api.deliveryNoteLiteClient
            .getSupplierOrderIdsByProject(companyId, projectId, undefined, undefined)
            .then((res) => {
                const data = res.getEntity();
                setSupplierOrderIds(data);
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }, []);

    function onChange(e: RadioChangeEvent) {
        setFileTypeOption(e.target.value);
        form.setFieldsValue({
            supplierOrderId: undefined,
            range: undefined
        });
    }

    return (
        <>
            <Form.Item
                name="fileType"
                required
                label={
                    <span style={{ fontWeight: 'bold', color: '#1F3855' }}>
                        <Trans>File type</Trans>
                    </span>
                }>
                <Radio.Group onChange={onChange}>
                    <Space direction="vertical">
                        <Radio value="export-all">
                            <label>
                                <Trans>Export all</Trans>
                            </label>
                        </Radio>
                        <Radio value="export-by-order">
                            <label>
                                <Trans>Export by Supplier Order / Job Number</Trans>
                            </label>
                        </Radio>
                        <Radio value="export-by-date">
                            <label>
                                <Trans>Export by Date</Trans>
                            </label>
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                name="supplierOrderId"
                required={fileTypeOption === 'export-by-order'}
                hidden={fileTypeOption !== 'export-by-order'}
                label={
                    <span style={{ fontWeight: 'bold', color: '#1F3855' }}>
                        <Trans>Supplier Order / Job Number</Trans>
                    </span>
                }>
                <Select showSearch allowClear placeholder={t`Select Supplier Order / Job Number`}>
                    {supplierOrderIds?.map((supplierOrderid) => (
                        <Select.Option value={supplierOrderid}>
                            <span>{supplierOrderid}</span>
                        </Select.Option>
                    ))}

                    {!supplierOrderIds?.length && (
                        <Select.Option data-testid="select-option-none" value="none" disabled>
                            <span>
                                <Trans>No results available</Trans>
                            </span>
                        </Select.Option>
                    )}
                </Select>
            </Form.Item>

            <Form.Item
                name="range"
                required={fileTypeOption === 'export-by-date'}
                hidden={fileTypeOption !== 'export-by-date'}
                label={
                    <span style={{ fontWeight: 'bold', color: '#1F3855' }}>
                        <Trans>Date range</Trans>
                    </span>
                }>
                <DatePicker.RangePicker format={formats.dateForm} />
            </Form.Item>
        </>
    );
}
