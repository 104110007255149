import { ExtendedManufacturingFlowBP } from '@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.dto';
import moment from 'moment';
import { MARKETS } from 'src/app-react/constants/markets';
import { OrderManagementActions, OrderManagementActionsValues } from '../../models/Actions';
import { OrderDependencies } from '../../models/OrderDependencies';
import { OrderFields, OrderFieldsCommon, OrderFieldsDE, OrderFieldsUS } from '../../models/OrderFields';

function getDeliveryTime({
    action,
    extendedOrder
}: {
    action: OrderManagementActionsValues;
    extendedOrder?: ExtendedManufacturingFlowBP;
}): moment.Moment | null {
    if (action === OrderManagementActions.Update) {
        const deliveryTime = extendedOrder?.order?.deliveryTime;
        return deliveryTime && moment(deliveryTime);
    }

    return null;
}

export function fromOrderToOrderFields({
    marketId,
    action,
    dependencies: { concreteType, extendedOrder, structuralElement }
}: {
    marketId: string;
    action: OrderManagementActionsValues;
    dependencies: OrderDependencies;
}): OrderFields {
    const deliveryTime = getDeliveryTime({ action, extendedOrder });

    const structuralElementName =
        structuralElement?.structuralElementName || extendedOrder?.concretedStructuralElement?.elementName || '';

    const commonFields: OrderFieldsCommon = {
        deliveryTime,
        dateOfConcreting: deliveryTime,
        structuralElement: structuralElementName,
        additionalQuantity: extendedOrder?.order?.additionalQuantityRequired,
        concreteQuantity: extendedOrder?.order?.volume,
        unloadingLocation: extendedOrder?.order?.unloadingLocationId,
        additionalRequirement: extendedOrder?.order?.additionalRequirements
    };

    switch (marketId) {
        case MARKETS.DE:
            return {
                ...commonFields,
                outPerHour: extendedOrder?.order?.performance
            } as OrderFieldsDE;
        case MARKETS.US:
            return {
                ...commonFields,
                spacingInMinutes: extendedOrder?.order?.spacingInMinutes,
                slump: extendedOrder?.order?.slump ?? concreteType?.slump,
                loadSize: extendedOrder?.order?.loadSize
            } as OrderFieldsUS;
        default:
            throw new Error(`fromOrderToOrderFields: invalid marketId: ${marketId}`);
    }
}
