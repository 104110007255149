/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { Form, Radio } from 'antd';
import { t } from '@lingui/macro';
import RadioGroupFormItem, { RadioGroupFormItemProps } from 'src/app-react/components/Form/FormItem/RadioGroupFormItem';
import styles from './styles.module.css';

import fogIcon from '../../../../../assets/icons/weather/cloud-fog.svg';
import heavyRainIcon from '../../../../../assets/icons/weather/heavy-rain.svg';
import rainIcon from '../../../../../assets/icons/weather/rainy.svg';
import sonwIcon from '../../../../../assets/icons/weather/snow.svg';
import cloudyIcon from '../../../../../assets/icons/weather/sun-cloud.svg';
import sunIcon from '../../../../../assets/icons/weather/sun.svg';
import windIcon from '../../../../../assets/icons/weather/wind.svg';

export default function WeatherFormItem(props: RadioGroupFormItemProps) {
    const form = Form.useFormInstance();
    const name = props.name || 'weather';

    const onclick = (event: any) => {
        window.sessionStorage.setItem('weather', event.target.value);
    };

    return (
        <RadioGroupFormItem name={name} {...props}>
            <Radio.Group data-testid="weather" optionType="button" className={styles.radioGroup}>
                <Radio.Button value="sunny" data-testid="sunny" onClick={onclick}>
                    <img alt="sun" src={sunIcon} />
                    <p>{t`Sunny`}</p>
                </Radio.Button>
                <Radio.Button value="cloudy" data-testid="cloudy" onClick={onclick}>
                    <img alt="cloudy" src={cloudyIcon} />
                    <p>{t`Cloudy`}</p>
                </Radio.Button>
                <Radio.Button value="rainy" data-testid="rainy" onClick={onclick}>
                    <img alt="rain" src={rainIcon} />
                    <p>{t`Rainy`}</p>
                </Radio.Button>
                <Radio.Button value="heavyRain" data-testid="heavyRain" onClick={onclick}>
                    <img alt="heavyRain" src={heavyRainIcon} />
                    <p>{t`Heavy rain`}</p>
                </Radio.Button>
                <Radio.Button value="windy" data-testid="windy" onClick={onclick}>
                    <img alt="wind" src={windIcon} />
                    <p>{t`Windy`}</p>
                </Radio.Button>
                <Radio.Button value="snow" data-testid="snow" onClick={onclick}>
                    <img alt="snow" src={sonwIcon} />
                    <p>{t`Snow`}</p>
                </Radio.Button>
                <Radio.Button value="foggy" data-testid="foggy" onClick={onclick}>
                    <img alt="fog" src={fogIcon} />
                    <p>{t`Foggy`}</p>
                </Radio.Button>
            </Radio.Group>
        </RadioGroupFormItem>
    );
}
