import { i18n } from '@lingui/core';

export function fractionToNumberOrJustNumber(value: string | undefined): number | undefined {
    if (value === undefined) {
        return undefined;
    }

    if (value.indexOf('/') > 0) {
        const split: string[] = value.split('/');
        if (split.length === 2) {
            if (split[0].split(' ').length === 2) {
                const whole: number = parseInt(split[0].split(' ')[0], 10);
                const numerator: number = parseInt(split[0].split(' ')[1], 10);
                const denominator: number = parseInt(split[1], 10);

                const result: number = whole + numerator / denominator;
                return result;
            }
            const numerator: number = parseInt(split[0], 10);
            const denominator: number = parseInt(split[1], 10);

            return numerator / denominator;
        }
        return undefined;
    }

    if (i18n.locale === 'de-DE') {
        value = value.replace(',', '.');
    }
    const parsedValue: number = parseFloat(value);
    if (isNaN(parsedValue)) {
        return undefined;
    }
    return parsedValue;
}