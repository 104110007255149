import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col } from 'antd';
import styles from '../ConcreteTypeModal.module.css';
import { empty } from '../utils/utils';

export function ConcreteTypeNumber({ concreteDetails }: { concreteDetails: SupplierProducedConcrete }) {
    return (
        <>
            <Col span={24}>
                <label className={styles.modalLabel}>
                    <Trans>Concrete Type</Trans>
                </label>
            </Col>
            <Col className={styles.colWithValue} data-testid="concreteTypeModal-concreteTypeNumber" span={24}>
                {concreteDetails.number || empty}
            </Col>
        </>
    );
}
