import { DeliveryStatusFilter, PaginationWithoutSort } from '@nexploretechnology/concreting-core-client';
import { useCallback, useState } from 'react';
import { ValuesDropDown, mapStringToDropdown } from 'src/app-react/constants/marketsData';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGetSupplierOrderIdsByProject() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [supplierNumberIds, setSupplierNumberIds] = useState<ValuesDropDown[]>(undefined);

    const loadData = useCallback(
        async (pagination: PaginationWithoutSort | undefined, filter: DeliveryStatusFilter | undefined) => {
            return (
                api.deliveryNoteLiteClient
                    .getSupplierOrderIdsByProject(companyId, projectId, filter, pagination)
                    .then((response) => setSupplierNumberIds(mapStringToDropdown(response.getEntity())))
                    // eslint-disable-next-line no-shadow
                    .catch((error: any) => {
                        setError({ message: error.code, description: error.message });
                    })
            );
        },
        [api, companyId, projectId]
    );

    return {
        loadData,
        supplierNumberIds,
        error
    };
}

export default useGetSupplierOrderIdsByProject;
