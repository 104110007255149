/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form, Input, InputProps } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';

function MixingPlantFormItem(props: FormItemInputProps) {
    return (
        <Form.Item name="mixingPlant" label={t`Mixing plant`} {...props}>
            <Input placeholder={t`Enter mixing plant`} data-testid="mixingPlantFormItem" />
        </Form.Item>
    );
}

export default MixingPlantFormItem;
