import { MARKETS, MARKET_VALUES } from "src/app-react/constants/markets";
import { createContextDataDE } from "./markets/DE/util";
import { createContextDataUS } from "./markets/US/util";
import { TestSpecimenBP, TestSpecimenBPDE, TestSpecimenBPUS } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto";

export function createContextData(testSpecimen: TestSpecimenBP, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return createContextDataDE(testSpecimen as TestSpecimenBPDE);
        default:
            return createContextDataUS(testSpecimen as TestSpecimenBPUS);
    }
}