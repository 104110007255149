import { SupplierConcreteLoadDataLite } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useSaveOrderLoadLite() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState<boolean>();
    const [isLoading, setIsLoading] = useState(false);

    const saveOrderLoadLite = useCallback(
        async (supplierConcreteLoadDataLite: SupplierConcreteLoadDataLite) => {
            try {
                setIsLoading(true);
                await api.deliveryNoteLiteClient.createConcreteLoadLite(
                    companyId,
                    projectId,
                    supplierConcreteLoadDataLite
                );

                setError(null);
                setSuccess(true);
            } catch (err) {
                setError(err);
            } finally {
                setIsLoading(false);
            }
        },
        [api, companyId, projectId, setError, setIsLoading]
    );

    const editOrderLoadLite = useCallback(
        async (supplierConcreteLoadDataLite: SupplierConcreteLoadDataLite, concreteLoadId) => {
            try {
                await api.deliveryNoteLiteClient.editConcreteLoadLite(
                    companyId,
                    projectId,
                    concreteLoadId,
                    supplierConcreteLoadDataLite
                );
                setError(null);
                setSuccess(true);
            } catch (err) {
                setError(err);
            }
        },
        [api, setError, setSuccess, companyId, projectId]
    );

    return {
        error,
        success,
        saveOrderLoadLite,
        editOrderLoadLite,
        isLoading
    };
}

export default useSaveOrderLoadLite;
