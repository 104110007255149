import { Col, Row } from 'antd';
import LocationDescriptionFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationDescriptionFormItem';
import LocationNameFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationNameFormItem';
import LocationTypeFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationTypeFormItem';
import { ConcreteLoadLiteModalAction } from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import ConcreteTypeNumberFormItem from '../../Form/FormItems/ConcreteTypeNumberFormItem';
import DeliveryNoteNumberFormItem from '../../Form/FormItems/DeliveryNoteNumberFormItem';
import DeliveryTimeFormItem from '../../Form/FormItems/DeliveryTimeFormItem';
import MixingPlantFormItem from '../../Form/FormItems/MixingPlantFormItem';
import OrderVolumeFormItem from '../../Form/FormItems/OrderVolumeFormItem';
import SupplierOrderIdFormItem from '../../Form/FormItems/SupplierOrderIdFormItem';
import TruckNumberFormItem from '../../Form/FormItems/TruckNumberFormItem';
import VolumeFormItem from '../../Form/FormItems/VolumeFormItem';

interface OrderLiteModalForm1DELayoutProps {
    action?: ConcreteLoadLiteModalAction;
}

export function OrderLiteModalForm1DELayout({ action }: OrderLiteModalForm1DELayoutProps) {
    return (
        <>
            <Row gutter={54}>
                <Col span="12">
                    <DeliveryNoteNumberFormItem data-testid="DeliveryNoteNumberFormItemDE" />
                </Col>
                <Col span={12}>
                    <ConcreteTypeNumberFormItem data-testid="ConcreteTypeNumberFormItemDE" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <VolumeFormItem data-testid="VolumeFormItemDE" />
                </Col>
                <Col span="12">
                    <TruckNumberFormItem data-testid="TruckNumberFormItemDE" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <MixingPlantFormItem data-testid="MixingPlantFormItemDE" />
                </Col>
                <Col span={12}>
                    <SupplierOrderIdFormItem data-testid="SupplierOrderIdFormItemDE" action={action} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <OrderVolumeFormItem data-testid="OrderVolumeFormItemDE" action={action} />
                </Col>
                <Col span={12}>
                    <DeliveryTimeFormItem data-testid="DeliveryTimeFormItemDE" action={action} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <LocationNameFormItem data-testid="LocationNameFormItemDE" required={false} />
                </Col>
                <Col span={12}>
                    <LocationDescriptionFormItem data-testid="LocationDescriptionFormItemDE" required={false} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <LocationTypeFormItem data-testid="LocationTypeFormItemDE" required={false} />
                </Col>
            </Row>
        </>
    );
}
