import { Trans } from '@lingui/macro';
import { Button, Col, Form, FormProps, Row } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { OrderManagementFormDE } from './FormDE';
import { OrderManagementFormUS } from './FormUS';
import styles from '../../OrderManagementFormPage.module.css';

type OrderManagementFormProps = Partial<FormProps> & {
    onBack: () => void;
    onSubmit: () => void;
};

export default function OrderManagementForm({ onBack, onSubmit, ...props }: OrderManagementFormProps) {
    const { marketId } = useProjectState();

    let children;

    switch (marketId) {
        case MARKETS.DE:
        children = <OrderManagementFormDE />; 
            break;
        case MARKETS.US:
        children = <OrderManagementFormUS />; 
            break;
        default:
        children = null;
            break;
    }

    return (
        <Form
            {...props}
            layout="vertical"
            data-testid="CreateUpdateOrderForm"
            className={styles.formColor}
        >
            {children}

            <Row gutter={24}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Form.Item>
                        <Button
                            className={styles.formButton}
                            data-testid="back-btn"
                            onClick={onBack}
                            type="primary"
                            key={`back-btn`}>
                            <Trans>Back</Trans>
                        </Button>
                        <Button
                            className={styles.formButton}
                            data-testid="next-btn"
                            onClick={onSubmit}
                            type="primary"
                            key={`next-btn`}>
                            <Trans>Next</Trans>
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}
