import { PlusOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { DeliveryNoteDetailsBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button } from 'antd';
import { useContext, useState } from 'react';
import EndUnloadingModal from 'src/app-react/business-page/delivery-note-details/components/EndUnloadingModal/EndUnloadingModal';
import ReportArrivalModal from 'src/app-react/business-page/delivery-note-details/components/ReportArrivalModal/ReportArrivalModal';
import StartUnloadingModal from 'src/app-react/business-page/delivery-note-details/components/StartUnloadingModal/StartUnloadingModal';
import AcceptAndSignModal from 'src/app-react/business-page/delivery-note-details/components/accept-and-sign-delivery/AcceptAndSignModal';
import { DeliveryNoteManagementContext } from 'src/app-react/business-page/delivery-note-details/providers/delivery.note.provider';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { RejectConcreteLoadButton } from './RejectConcreteLoadButton';

interface Props {
    deliveryDetails: DeliveryNoteDetailsBP;
    loadDetails: () => void;
    concreteLoadId: string;
}

export function StatusButton({ deliveryDetails, loadDetails, concreteLoadId }: Props) {
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { projectId } = useProjectState();

    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [unloadingModalVisible, setUnloadingModalVisible] = useState(false);
    const [unloadedModalVisible, setUnloadedModalVisible] = useState(false);
    const [acceptAndSignModalVisible, setAcceptAndSignModalVisible] = useState(false);
    const { isViewer } = useUserPermissions();

    switch (deliveryDetails.deliveryNoteData.status) {
        case 'OnRoute':
        case 'DeliveryNoteReceived':
        case 'Loading':
            return (
                <>
                    <RejectConcreteLoadButton deliveryDetails={deliveryDetails} requestDetails={loadDetails}>
                        <Button
                            type="primary"
                            onClick={() => setReportModalVisible(true)}
                            hidden={isViewer}
                            data-testid="arrival-btn">
                            <PlusOutlined /> <Trans>Report arrival</Trans>
                        </Button>
                    </RejectConcreteLoadButton>

                    {reportModalVisible && (
                        <ReportArrivalModal
                            concreteLoadId={concreteLoadId}
                            deliveryNoteNo={deliveryDetails.deliveryNoteData.deliveryNoteNumber}
                            licensePlate={deliveryDetails.deliveryNoteData.truckLicensePlate}
                            open={reportModalVisible}
                            setOpen={setReportModalVisible}
                            onComplete={() => loadDetails()}
                        />
                    )}
                </>
            );
        case 'Arrived':
            return (
                <>
                    <RejectConcreteLoadButton deliveryDetails={deliveryDetails} requestDetails={loadDetails}>
                        <Button
                            type="primary"
                            hidden={isViewer}
                            onClick={() => setUnloadingModalVisible(true)}
                            data-testid="unloading-btn">
                            <PlusOutlined /> <Trans>Start unloading</Trans>
                        </Button>
                    </RejectConcreteLoadButton>

                    {unloadingModalVisible && (
                        <StartUnloadingModal
                            concreteLoadId={concreteLoadId}
                            deliveryNoteNo={deliveryDetails.deliveryNoteData.deliveryNoteNumber}
                            licensePlate={deliveryDetails.deliveryNoteData.truckLicensePlate}
                            processableUntil={deliveryDetails.deliveryNoteData.processableUntil}
                            arrivalDateTime={deliveryDetails.deliveryNoteData.arrivalAtConstructionSite}
                            open={unloadingModalVisible}
                            setOpen={setUnloadingModalVisible}
                            onComplete={() => loadDetails()}
                        />
                    )}
                </>
            );
        case 'Unloading':
            return (
                <>
                    <RejectConcreteLoadButton deliveryDetails={deliveryDetails} requestDetails={loadDetails}>
                        <Button
                            type="primary"
                            onClick={() => setUnloadedModalVisible(true)}
                            hidden={isViewer}
                            data-testid="unloaded-btn">
                            <PlusOutlined /> <Trans>End unloading</Trans>
                        </Button>
                    </RejectConcreteLoadButton>

                    {unloadedModalVisible && (
                        <EndUnloadingModal
                            concreteLoadId={concreteLoadId}
                            deliveryNoteNo={deliveryDetails.deliveryNoteData.deliveryNoteNumber}
                            licensePlate={deliveryDetails.deliveryNoteData.truckLicensePlate}
                            startUnloadingDateTime={deliveryDetails.deliveryNoteData.unloadingStart}
                            open={unloadedModalVisible}
                            setOpen={setUnloadedModalVisible}
                            setAcceptAndSignModalVisible={setAcceptAndSignModalVisible}
                            onComplete={() => loadDetails()}
                        />
                    )}
                </>
            );

        case 'Unloaded':
            return (
                <>
                    <RejectConcreteLoadButton deliveryDetails={deliveryDetails} requestDetails={loadDetails}>
                        {!deliveryDetails.rejectedBy && (
                            <Button
                                type="primary"
                                onClick={() => setAcceptAndSignModalVisible(true)}
                                hidden={isViewer}
                                data-testid="sign-btn">
                                <PlusOutlined /> <Trans>Accept and sign</Trans>
                            </Button>
                        )}
                    </RejectConcreteLoadButton>

                    {acceptAndSignModalVisible && (
                        <AcceptAndSignModal
                            projectId={projectId}
                            orderId={orderId}
                            concreteLoadId={concreteLoadId}
                            open={acceptAndSignModalVisible}
                            setOpen={setAcceptAndSignModalVisible}
                            onComplete={() => loadDetails()}
                            fromDeliveryDetailsPage={false}
                        />
                    )}
                </>
            );
        default:
            return <></>;
    }
}
