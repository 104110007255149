import { BellOutlined, GlobalOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Button, Dropdown, MenuProps } from 'antd';
import { useState } from 'react';
import { getAuthService } from 'src/app-react/services/authService';
import LanguageModal from '../../Modals/LanguageModal/LanguageModal';
import styles from '../AppMenu.module.css';

function TopMenu() {
    const [languageModalVisible, setLanguageModalVisible] = useState(false);

    const showLanguageModal = (): void => {
        setLanguageModalVisible(true);
    };

    const logout = (): void => {
        getAuthService().logout();
    };

    const renderTopMenu = (): MenuProps['items'] => {
        return [
            {
                key: 'language',
                label: (
                    <Button data-testid="languageMenuBtn" type="text" onClick={() => showLanguageModal()}>
                        {' '}
                        <GlobalOutlined /> <Trans>Language</Trans>{' '}
                    </Button>
                )
            },

            {
                key: 'logout',
                label: (
                    <Button data-testid="logoutMenuBtn" type="text" onClick={() => logout()}>
                        {' '}
                        <LogoutOutlined /> <Trans>Logout</Trans>{' '}
                    </Button>
                )
            }
        ];
    };

    return (
        <>
            <Dropdown trigger={['click']} menu={{ items: [] }} placement="bottom" className={styles.menuRightIcons}>
                <Button
                    hidden
                    icon={
                        <div className={styles.menuDropdown}>
                            <BellOutlined style={{ fontSize: '1.4rem' }} />
                            <p>
                                <Trans>Updates</Trans>
                            </p>
                        </div>
                    }
                />
            </Dropdown>

            <Dropdown
                trigger={['click']}
                menu={{ items: renderTopMenu() }}
                placement="bottom"
                className={styles.menuRightIcons}>
                <Button
                    icon={
                        <div className={styles.menuDropdown}>
                            <UserOutlined style={{ fontSize: '1.4rem' }} />
                            <p>
                                <Trans>Profile</Trans>
                            </p>
                        </div>
                    }
                />
            </Dropdown>
            <LanguageModal open={languageModalVisible} setOpen={setLanguageModalVisible} />
        </>
    );
}

export default TopMenu;
