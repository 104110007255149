/* eslint-disable react/jsx-props-no-spreading */

import { t, Trans } from '@lingui/macro';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import { Input } from 'antd';

function CustomerNumberFormItem(props: InputFormItemProps) {
    return (
        <InputFormItem
            data-testid="constructorNumberFormItem"
            label={<Trans>Customer number</Trans>}
            name="constructorNumber"
            {...props}>
            <Input data-testid="constructorNumber" placeholder={t`Enter customer number`} />
        </InputFormItem>
    );
}

export default CustomerNumberFormItem;
