import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { Form, Input } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

export function SlumpFormItem({ allowDecimals }: { allowDecimals: boolean }) {
    const { units } = useProjectConstantsState();
    const regExpDE = allowDecimals ? new RegExp(/^\d+(?:,\d{1,2})?$/) : new RegExp(/^\d+$/);
    const regExpUS = allowDecimals ? new RegExp(/^\d+(?:\.\d{1,2})?$/) : new RegExp(/^\d+$/);

    return (
        <Form.Item
            name="flowTableTest"
            label={<Trans>Slump</Trans>}
            rules={[
                {
                    required: true,
                    pattern: i18n.locale === 'de-DE' ? regExpDE : regExpUS,
                    message: t`Please introduce a valid input`
                }
            ]}>
            <Input
                type="text"
                data-testid="flowTableTest"
                addonAfter={units.slump}
                placeholder={t`Enter the test results`}
            />
        </Form.Item>
    );
}
