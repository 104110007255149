/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form, TimePickerProps } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import {
    CONCRETE_LOAD_LITE_MODAL_ACTION,
    ConcreteLoadLiteModalAction
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import DateTimePicker from 'src/app-react/components/Form/DateTimePicker';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

interface DeliveryTimeFormItemProps extends FormItemInputProps {
    action?: ConcreteLoadLiteModalAction;
}
function DeliveryTimeFormItem({ action, ...props }: DeliveryTimeFormItemProps) {
    const isReadOnly = action === CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT;

    return (
        <Form.Item name="deliveryTime" label={t`Enter delivery date`} rules={[requiredRule]} {...props}>
            <DateTimePicker disabled={isReadOnly} data-testid="deliveryTime" placeholder={t`Enter delivery date`} />
        </Form.Item>
    );
}

export default DeliveryTimeFormItem;
