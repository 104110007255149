import { i18n } from '@lingui/core';
import moment from 'moment';
import { TemperatureTestFormValuesUS } from '../US/models';
import { parseDecimalByLocale } from 'src/app-react/utils/formatters';
import { FormInstance } from 'antd';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { MARKETS } from 'src/app-react/constants/markets';
import { FreshConcreteTest, TemperatureTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValuesUS(values: any) {
    const initialFormValuesUS: TemperatureTestFormValuesUS = values;

    return {
        testingDate:
            initialFormValuesUS?.testingDate !== undefined ? moment(initialFormValuesUS.testingDate) : undefined,
        testingTime:
            initialFormValuesUS?.testingTime !== undefined ? moment(initialFormValuesUS.testingTime) : undefined,
        airTemperature: initialFormValuesUS?.airTemperature?.toString(),
        freshConcreteTemperature: initialFormValuesUS?.freshConcreteTemperature?.toString()
    };
}

export function getTemperatureFormValuesUS(temperatureTestForm: FormInstance<any>): TemperatureTestFormValuesUS {
    return {
        testingDate: (temperatureTestForm.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (temperatureTestForm.getFieldValue('testingTime') as moment.Moment).toDate(),
        airTemperature: parseDecimalByLocale(
            temperatureTestForm.getFieldValue('airTemperature') as string,
            i18n.locale
        ),
        freshConcreteTemperature: parseDecimalByLocale(
            temperatureTestForm.getFieldValue('freshConcreteTemperature') as string,
            i18n.locale
        )
    };
}

export function createTemperatureTestUS(
    freshConcreteTest: FreshConcreteTest,
    temperatureTestFormValues: TemperatureTestFormValuesUS
) {
    const { units } = getProjectConstants({ marketId: MARKETS.US });
    const testingTime: Date = mergeDateTime(
        moment(temperatureTestFormValues.testingDate),
        moment(temperatureTestFormValues.testingTime)
    ).toDate();

    // Remove ª character Cª => C
    const unit = units.temperature.replace('°', '') as 'C' | 'F';

    const temperatureTest: TemperatureTest = {
        ...freshConcreteTest,
        airTemprature: {
            quantity: temperatureTestFormValues.airTemperature,
            unit
        },
        freshConcreteTemprature: {
            quantity: temperatureTestFormValues.freshConcreteTemperature,
            unit
        },
        testingTime,
        externalInspection: false
    };

    return temperatureTest;
}
