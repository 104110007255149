import { Trans } from '@lingui/macro';
import { Badge, Tabs } from 'antd';
import { useContext, useState } from 'react';
import useProjectState from 'src/app-react/hooks/useProjectState';
import FreshConcreteTestsTable from '../FreshConcreteTestsTable/FreshConcreteTestsTable';
import MultipleConcreteTestModal from '../MultipleConcreteTestModal/MultipleConcreteTestModal';
import TestSpecimenTable from '../TestSpecimenTable/TestSpecimenTable';
import { MARKETS } from 'src/app-react/constants/markets';
import { FreshConcreteTest, TestSpecimen } from '@nexploretechnology/concreting-core-client';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';

export interface IConcreteTestsTabsProps {
    orderId: string;
    concreteLoadId: string;
    deliveryNoteNumber?: string;
    isConcreteTestModalVisible: boolean;
    setIsConcreteTestModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    initialFormValuesConcreteTestModal: any;
    resetInitialFormValuesConcreteTestModal: () => void;
    testRecords?: {
        freshConcreteTests?: FreshConcreteTest[];
        testSpecimens?: TestSpecimen[];
    };
}

function ConcreteTestsTabs(props: IConcreteTestsTabsProps) {
    const {
        orderId,
        concreteLoadId,
        isConcreteTestModalVisible,
        setIsConcreteTestModalVisible,
        initialFormValuesConcreteTestModal,
        resetInitialFormValuesConcreteTestModal,
        testRecords
    } = props;

    const { marketId } = useProjectState();
    const [refreshFreshConcreteTestsTable, setRefreshFreshConcreteTestsTable] = useState<boolean>(false);
    const [refreshTestSpecimenTable, setRefreshTestSpecimenTable] = useState<boolean>(false);
    const [freshConcreteTestsCount, setFreshConcreteTestsCount] = useState<number>(1); // 1 = trigger data load the first time
    const [testSpecimenCount, setTestSpecimenCount] = useState<number>(0);
    const { reloadDeliveryNotePage } = useContext(DeliveryNoteManagementContext)

    const displayTestSpecimenTitle = (): JSX.Element => {
        return marketId === MARKETS.DE ? <Trans>Test specimen</Trans> : <Trans>Test cylinder</Trans>;
    };

    const refreshFreshConcreteTestsTableCallback = () => {
        setFreshConcreteTestsCount(1); // first forcing making it visible that refresh will work
        setRefreshFreshConcreteTestsTable(true);
        reloadDeliveryNotePage()
    };

    const items = [
        {
            label: (
                <>
                    <Trans>Fresh concrete tests</Trans> <Badge count={freshConcreteTestsCount} />
                </>
            ),
            key: 'FreshConcreteTestsTabPane',
            children: (
                <FreshConcreteTestsTable
                    orderId={orderId}
                    concreteLoadId={concreteLoadId}
                    refreshTable={refreshFreshConcreteTestsTable}
                    setRefreshTable={setRefreshFreshConcreteTestsTable}
                    setFreshConcreteTestsCount={setFreshConcreteTestsCount}
                    records={testRecords.freshConcreteTests}
                />
            ),
            forceRender: true
        },
        {
            label: (
                <>
                    {displayTestSpecimenTitle()} <Badge count={testSpecimenCount} />
                </>
            ),
            key: 'TestSpecimenTabPane',
            children: (
                <TestSpecimenTable
                    orderId={orderId}
                    concreteLoadId={concreteLoadId}
                    refreshTable={refreshTestSpecimenTable}
                    setRefreshTable={setRefreshTestSpecimenTable}
                    setTestSpecimenCount={setTestSpecimenCount}
                    records={testRecords.testSpecimens}
                />
            ),
            forceRender: true
        }
    ];

    return (
        <>
            {freshConcreteTestsCount > 0 || testSpecimenCount > 0 ? (
                <Tabs defaultActiveKey="FreshConcreteTests" items={items} />
            ) : (
                <></>
            )}

            {isConcreteTestModalVisible && (
                <MultipleConcreteTestModal
                    concreteLoadId={concreteLoadId}
                    open={isConcreteTestModalVisible}
                    setOpen={setIsConcreteTestModalVisible}
                    initialFormValues={initialFormValuesConcreteTestModal}
                    resetInitialFormValuesConcreteTestModal={resetInitialFormValuesConcreteTestModal}
                    onOkFreshConcreteTests={refreshFreshConcreteTestsTableCallback}
                    onOkTestSpecimen={() => {
                        setTestSpecimenCount(1); // first forcing making it visible that refresh will work
                        setRefreshTestSpecimenTable(true);
                    }}
                    deliveryNoteNumber={props.deliveryNoteNumber}
                />
            )}
        </>
    );
}

export default ConcreteTestsTabs;
