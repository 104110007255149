import { Col, Row } from "antd";
import { MeasurementDateFormItem } from "../../FormItems/MeasurementDateFormItem";
import { MeasurementTimeFormItem } from "../../FormItems/MeasurementTimeFormItem";
import { TemperatureFormItem } from "../../FormItems/TemperatureFormItem";
import { TemperatureTypeFormItem } from "../FormItems/TemperatureTypeFormItem";

export function TemperatureMeasurememntEditlModalFormLayoutUS() {

    return (
        <>
            <Row>
                <Col span={24}>
                    <TemperatureTypeFormItem />
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <MeasurementDateFormItem />
                </Col>
                <Col span={12}>
                    <MeasurementTimeFormItem />
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <TemperatureFormItem />
                </Col>
            </Row>
        </>
    )
}