import { Trans, t } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function TokenFormItem() {
    return (
        <FormItem
            label={<Trans>Token</Trans>}
            name="bcqdToSupplierToken"
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="password" data-testid="bcqdToSupplierToken" placeholder={t`Enter service token`} />
        </FormItem>
    );
}
