import { Trans, t } from "@lingui/macro";
import { Form, Input } from "antd";


export function PlanNumberItem() {
    return <Form.Item
        label={<Trans>Drawing set</Trans>} 
        name="planNumber"
        rules={[]}>
        <Input
            type="text"
            placeholder={t`Enter drawing set`}
            data-testid="planNumber"
        />
    </Form.Item>
}