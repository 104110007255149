import { PlusOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { Divider, Form, Space, Typography } from 'antd';
import { useCallback, useState } from 'react';
import UnloadingLocationModal from 'src/app-react/business-page/order-management/components/UnloadingLocationModal/UnloadingLocationModal';
import useOrderDependenciesState from 'src/app-react/business-page/order-management/hooks/useOrderDependenciesState';
import { LocationSelect } from 'src/app-react/business-page/shared/FormItems/Location/LocationSelect';

export function UnloadingLocationFormItem() {
    const form = Form.useFormInstance();
    const [visibleModal, setVisibleModal] = useState(false);

    const {
        dependencies: { locations = [] },
        loadLocations
    } = useOrderDependenciesState();

    const onCompleteModal = useCallback(
        (unloadingLocation: string) => {
            form.setFieldsValue({
                unloadingLocation: unloadingLocation
            });

            loadLocations();
        },
        [loadLocations, form]
    );

    return (
        <>
            <Form.Item
                label={<Trans>Unloading location</Trans>}
                name="unloadingLocation"
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-testid="errorInputLoadingLocation">
                                <Trans>This field is required</Trans>
                            </span>
                        )
                    }
                ]}>
                <LocationSelect
                    locations={locations}
                    placeholder={t`Please select unloading location`}
                    data-testid="unloadingLocation"
                    dropdownRender={(menu) => (
                        <>
                            {menu}
                            <Divider style={{ margin: '8px 0' }} />
                            <Space align="center" style={{ padding: '0 8px 4px' }}>
                                <Typography.Link
                                    data-testid="createUnloadingLink"
                                    onClick={() => setVisibleModal(true)}
                                    style={{ whiteSpace: 'nowrap' }}>
                                    <PlusOutlined /> {t`Create unloading location`}
                                </Typography.Link>
                            </Space>
                        </>
                    )}
                />
            </Form.Item>
            {visibleModal && (
                <UnloadingLocationModal
                    open={visibleModal}
                    setOpen={setVisibleModal}
                    onComplete={onCompleteModal}
                />
            )}
        </>
    );
}
