import { i18n } from '@lingui/core';
import moment from "moment";
import { formatDecimalByLocale, parseDecimalByLocale } from "src/app-react/utils/formatters";
import { ConsistencyTestFormValuesDE } from "./models";
import { FormInstance } from 'antd';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { MARKETS } from 'src/app-react/constants/markets';
import { ConsistencyTest, FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValues(values: any) {
    const initialFormValuesDE: ConsistencyTestFormValuesDE = values;

    return {
        testingDate:
            initialFormValuesDE?.testingDate !== undefined
                ? moment(initialFormValuesDE.testingDate)
                : undefined,
        testingTime:
            initialFormValuesDE?.testingTime !== undefined
                ? moment(initialFormValuesDE.testingTime)
                : undefined,
        flowTableTest: formatDecimalByLocale(initialFormValuesDE?.flowTableTest?.toString(), i18n.locale),
        externalInspection:
            initialFormValuesDE?.externalInspection !== undefined
                ? initialFormValuesDE?.externalInspection
                    ? 'true'
                    : 'false'
                : undefined
    };
}

export function getConsistencyFormValuesDE(consistencyTestForm: FormInstance<any>): ConsistencyTestFormValuesDE {
    return {
        testingDate: (consistencyTestForm.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (consistencyTestForm.getFieldValue('testingTime') as moment.Moment).toDate(),
        flowTableTest: parseDecimalByLocale(
            consistencyTestForm.getFieldValue('flowTableTest') as string,
            i18n.locale
        ),
        externalInspection: (consistencyTestForm.getFieldValue('externalInspection') as string) === 'true'
    };
}

export function createConsistencyTestDE(
    freshConcreteTest: FreshConcreteTest,
    consistencyTestFormValues: ConsistencyTestFormValuesDE
) {
    const { units } = getProjectConstants({ marketId: MARKETS.DE });
    const testingTime: Date = mergeDateTime(
        moment(consistencyTestFormValues.testingDate),
        moment(consistencyTestFormValues.testingTime)
    ).toDate();

    const consistencyTest: ConsistencyTest = {
        ...freshConcreteTest,
        diameter: {
            length: consistencyTestFormValues.flowTableTest,
            unit: units.slump as ConsistencyTest['diameter']['unit']
        },
        externalInspection: consistencyTestFormValues.externalInspection,
        testingTime
    };

    return consistencyTest;
}