import { Trans } from '@lingui/macro';

const METHOD_CONCRETE = [
    {
        value: 'prestressedConcrete',
        label: <Trans>Prestressed concrete</Trans>
    },
    {
        value: 'reinforcedConcrete',
        label: <Trans>Reinforced concrete</Trans>
    },
    {
        value: 'nonReinforcedConcrete',
        label: <Trans>Non-reinforced concrete</Trans>
    }
];

export default METHOD_CONCRETE;
