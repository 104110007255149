import { i18n } from '@lingui/core';
import moment from "moment";
import { AirContentTestFormValuesUS } from "./models";
import { formatDecimalByLocale, parseDecimalByLocale } from "src/app-react/utils/formatters";
import { FormInstance } from 'antd';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { AirContentTest, FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValuesUS(values: any) {
    const initialFormValuesDE: AirContentTestFormValuesUS = values;

    return {
        testingDate:
            initialFormValuesDE?.testingDate !== undefined
                ? moment(initialFormValuesDE.testingDate)
                : undefined,
        testingTime:
            initialFormValuesDE?.testingTime !== undefined
                ? moment(initialFormValuesDE.testingTime)
                : undefined,
        airContent: formatDecimalByLocale(initialFormValuesDE?.airContent?.toString(), i18n.locale),
        airMeasurementMethod: values?.airMeasurementMethod
    };
}

export function getAirContentFormValuesUS(airContentTestForm: FormInstance<any>): AirContentTestFormValuesUS {
    return {
        testingDate: (airContentTestForm.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (airContentTestForm.getFieldValue('testingTime') as moment.Moment).toDate(),
        airContent: parseDecimalByLocale(
            airContentTestForm.getFieldValue('airContent') as string,
            i18n.locale
        ),
        airMeasurementMethod: airContentTestForm.getFieldValue('airMeasurementMethod')
    };
}

export function createAirContentTestUS(
    freshConcreteTest: FreshConcreteTest,
    airContentTestFormValues: AirContentTestFormValuesUS
) {
    const testingTime: Date = mergeDateTime(
        moment(airContentTestFormValues.testingDate),
        moment(airContentTestFormValues.testingTime)
    ).toDate();


    const airContentTest: AirContentTest = {
        ...freshConcreteTest,
        airContent: airContentTestFormValues.airContent,
        airMeasurementMethod: airContentTestFormValues.airMeasurementMethod,
        testingTime,
        externalInspection: false
    };

    return airContentTest;
}