import { i18n } from '@lingui/core';
import {
    ICommonFieldStructuralElementForm,
    IFieldsStructuralElementFormDE,
    IFieldsStructuralElementFormUS,
    IStructuralElementDE,
    IStructuralElementUS
} from './StructuralElementModalForm';
import {
    getRequiredXfFirst,
    getRequiredXfSecond,
    getRequiredXx
} from 'src/app-react/business-page/shared/Forms/StructuralElement/utils';
import { MARKETS } from 'src/app-react/constants/markets';
import { formatDecimalByLocale, getSwitchValue, parseDecimalByLocale } from 'src/app-react/utils/formatters';
import { methodConcreteSelected } from '../../mapper.fields';
import {
    CataloguedStructuralElementBP,
    CataloguedStructuralElementBPDE,
    CataloguedStructuralElementBPUS,
    ChangeCommentBP
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

const getFieldString = (selectedValue: string | undefined): string | undefined => selectedValue || undefined;

export const generateCaSE = (
    projectId: string,
    companyId: string,
    marketId: string,
    values: IStructuralElementDE | IStructuralElementUS
): CataloguedStructuralElementBP => {
    if (marketId === MARKETS.DE) {
        return generateCaSEDE(projectId, companyId, values as IStructuralElementDE);
    }
    return generateCaSEUS(projectId, companyId, values as IStructuralElementUS);
};

export const generateCaSEDE = (
    projectId: string,
    companyId: string,
    values: IStructuralElementDE
): CataloguedStructuralElementBPDE => {
    let payloadDE: CataloguedStructuralElementBPDE = {} as CataloguedStructuralElementBPDE;
    const {
        id,
        name,
        elementDescription,
        planNumber,
        typeOfConcrete,
        cylindricCompressiveStrength,
        cubicCompressiveStrength,
        moistureClass,
        requiredX0,
        requiredXc,
        requiredXd,
        requiredXf,
        requiredXa,
        requiredXm,
        requiredXs,
        methodConcreteUseChloride,
        strengthDevelopment,
        densityClass,
        externalInspection,
        concreteTypeIds,
        boqPositions
    }: IFieldsStructuralElementFormDE & ICommonFieldStructuralElementForm = values;
    const separator = ' / ';
    const methodConcreteUseChlorideAsArray: string[] = methodConcreteUseChloride
        ? methodConcreteUseChloride.split(separator)
        : [];

    payloadDE = {
        id,
        projectId,
        companyId,
        planNumber: getFieldString(planNumber),
        elementName: name,
        elementDescription: getFieldString(elementDescription),
        boqPositions,
        methodOfConcrete:
            methodConcreteUseChlorideAsArray && methodConcreteUseChlorideAsArray.length > 1
                ? methodConcreteUseChlorideAsArray[0]
                : '',
        chlorideContent:
            methodConcreteUseChlorideAsArray && methodConcreteUseChlorideAsArray.length > 1
                ? methodConcreteUseChlorideAsArray[1]
                : '',
        requiredTypeOfConcreteType: typeOfConcrete,
        cylindricCompressiveStrength: Number(cylindricCompressiveStrength),
        cubicCompressiveStrength: Number(cubicCompressiveStrength),
        moistureClass,
        requiredX0: requiredX0 === true || requiredX0 === false ? requiredX0 : false,
        requiredXc: getRequiredXx(requiredXc?.toString()),
        requiredXd: getRequiredXx(requiredXd?.toString()),
        requiredXfFirst: getRequiredXfFirst(requiredXf),
        requiredXfSecond: getRequiredXfSecond(requiredXf),
        requiredXa: getRequiredXx(requiredXa?.toString()),
        requiredXm: getRequiredXx(requiredXm?.toString()),
        requiredXs: getRequiredXx(requiredXs?.toString()),
        externalInspection: externalInspection ? Boolean(externalInspection) : false,
        strengthDevelopment: strengthDevelopment ? Number(strengthDevelopment) : undefined,
        densityClass: getFieldString(densityClass),
        concreteTypeIds: concreteTypeIds?.length > 0 ? concreteTypeIds : []
    };
    payloadDE = cleanPayLoad(payloadDE);

    return payloadDE;
};

export const generateCaSEUS = (
    projectId: string,
    companyId: string,
    values: IStructuralElementUS
): CataloguedStructuralElementBPUS => {
    let payloadUS: CataloguedStructuralElementBPUS = {} as CataloguedStructuralElementBPUS;

    const {
        id,
        name,
        elementDescription,
        planNumber,
        methodOfConcrete,
        slump,
        slumpVariance,
        massConcrete,
        compressiveStrength,
        costCode,
        concreteTypeIds
    }: IFieldsStructuralElementFormUS & ICommonFieldStructuralElementForm = values;

    payloadUS = {
        id,
        projectId,
        companyId,
        planNumber: getFieldString(planNumber),
        elementName: name,
        elementDescription: getFieldString(elementDescription),
        methodOfConcrete: getFieldString(methodOfConcrete),
        slump: slump ? parseDecimalByLocale(slump, i18n.locale) : undefined,
        slumpUnit: slump ? 'in' : undefined,
        slumpVariance: slumpVariance ? parseDecimalByLocale(slumpVariance, i18n.locale) : undefined,
        massConcrete: massConcrete ? Boolean(massConcrete) : false,
        compressiveStrength: Number(compressiveStrength),
        compressiveStrengthUnit: 'psi',
        costCode: getFieldString(costCode),
        concreteTypeIds: concreteTypeIds?.length > 0 ? concreteTypeIds : []
    };

    payloadUS = cleanPayLoad(payloadUS);

    return payloadUS;
};

const cleanPayLoad = (payloadDE: CataloguedStructuralElementBPDE): CataloguedStructuralElementBPDE => {
    for (const key in payloadDE) {
        const value: string | number | boolean | string[] | ChangeCommentBP[] | undefined =
            payloadDE[key as keyof CataloguedStructuralElementBPDE];
        if (!value && value !== false) {
            delete payloadDE[key as keyof CataloguedStructuralElementBPDE];
        }
    }

    return payloadDE;
};

export const getInitialFormValues = (marketId: string, values: IStructuralElementDE | IStructuralElementUS) => {
    return {
        id: values.id,
        name: values.name,
        elementDescription: values.elementDescription,
        planNumber: values.planNumber,
        ...(marketId === MARKETS.DE
            ? getInitialFormValuesDE(values as IStructuralElementDE)
            : getInitialFormValuesUS(values as IStructuralElementUS))
    };
};

const getInitialFormValuesDE = (values: IStructuralElementDE): IFieldsStructuralElementFormDE => {
    return {
        typeOfConcrete: values.typeOfConcrete,
        cylindricCompressiveStrength: values.cylindricCompressiveStrength,
        cubicCompressiveStrength: values.cubicCompressiveStrength,

        moistureClass: values.moistureClass,
        requiredX0: values.requiredX0,
        requiredXc: values.requiredXc,
        requiredXd: values.requiredXd,
        requiredXf: values.requiredXf,
        requiredXa: values.requiredXa,
        requiredXm: values.requiredXm,
        requiredXs: values.requiredXs,
        methodConcreteUseChloride: methodConcreteSelected(values.methodOfConcrete, values.chlorideContent),
        densityClass: values.densityClass,
        strengthDevelopment: values.strengthDevelopment,
        externalInspection: getSwitchValue(values.externalInspection),
        concreteTypeIds: values.concreteTypeIds
    };
};

const getInitialFormValuesUS = (values: IStructuralElementUS): IFieldsStructuralElementFormUS => {
    const slump: string = formatDecimalByLocale(values.slump, i18n.locale) || '';
    const slumpVariance: string = formatDecimalByLocale(values.slumpVariance, i18n.locale) || '';

    return {
        planNumber: values.planNumber,
        costCode: values.costCode,
        methodOfConcrete: methodConcreteSelected(values.methodOfConcrete, undefined),
        compressiveStrength: values.compressiveStrength,
        slump,
        slumpVariance,
        massConcrete: getSwitchValue(values.massConcrete),
        concreteTypeIds: values.concreteTypeIds
    };
};
