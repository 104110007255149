import { Trans } from "@lingui/macro";
import { MARKETS } from "src/app-react/constants/markets";
import useProjectState from "src/app-react/hooks/useProjectState";

export const FormTitle = ({ action }: { action: string }): JSX.Element => {
    const { marketId } = useProjectState();

    switch (action) {
        case 'create':
            if (marketId === MARKETS.DE) {
                return (
                    <label data-testid="title-form-label">
                        <Trans>Add concrete type</Trans>
                    </label>
                );
            }
            return (
                <label data-testid="title-form-label">
                    <Trans>Add mix design</Trans>
                </label>
            );

        case 'duplicate':
            if (marketId === MARKETS.DE) {
                return (
                    <label data-testid="title-form-label">
                        <Trans>Duplicate concrete type</Trans>
                    </label>
                );
            }
            return (
                <label data-testid="title-form-label">
                    <Trans>Duplicate mix design</Trans>
                </label>
            );

        case 'edit':
            if (marketId === MARKETS.DE) {
                return (
                    <label data-testid="title-form-label">
                        <Trans>Edit concrete type</Trans>
                    </label>
                );
            }
            return (
                <label data-testid="title-form-label">
                    <Trans>Edit mix design</Trans>
                </label>
            );
    }
    return <></>;
};