import { DefaultOptionType } from 'antd/lib/select';

export function buildDefaultOptionType(values: DefaultOptionType[]) {
    return values.map(({ value, label }) => {
        return {
            value: value,
            label: (
                <div data-testid={label}>
                    <span> {label} </span>
                </div>
            )
        };
    });
}
