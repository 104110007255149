import { t } from '@lingui/macro';
import { Direction } from '@nexploretechnology/bcqd-client';
import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ProjectModalForm from 'src/app-react/business-page/project/components/ProjectModalForm/ProjectModalForm';
import NotFound from 'src/app-react/components/NotFound/NotFound';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { BUSINESS_PAGES } from 'src/app-react/constants/businessPages';
import useApi from 'src/app-react/hooks/useApi';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import getProjectBusinessPages from 'src/app-react/services/projectService/getProjectBusinessPages';
import getProjectPermissions from '../../utils/getProjectPermissions';
import ManageUsersForm from '../ManageUsersForm/ManageUsersForm';
import SupplierConfigForm from '../SupplierConfigForm/SupplierConfigForm';
import { projectsTableColumns } from './ProjectsTable.columns';

interface ProjectsTableProps {
    reload: boolean;
    companyId: string;
}

function ProjectsTable({ reload, companyId }: ProjectsTableProps) {
    const [projects, updateProjects] = useState([] as Project[]);
    const [projectId, setProjectId] = useState<string>('');
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showSupplierModal, setShowSupplierModal] = useState<boolean>(false);
    const [showManageModal, setShowManageModal] = useState<boolean>(false);
    const [totalProjects, setTotalProjects] = useState<number>(0);
    const navigate: NavigateFunction = useNavigate();
    const pagination = useRef<OffsetPagination<Project>>(undefined);
    const { userProfile } = useGlobalState();

    const [loading, setLoading] = useState(true);

    const userCompany = userProfile?.companies?.find((c) => c.companyId === companyId);
    const api = useApi();

    useEffect(() => {
        setTotalProjects(0);

        const queryParams: OffsetPagination<Project> = {
            skip: paginationConfig.current! === 0 ? 0 : (paginationConfig.current! - 1) * paginationConfig.pageSize!,
            limit: paginationConfig?.pageSize!,
            sorting: sorting ? [sorting] : []
        };

        getProjects(queryParams);
    }, [companyId]);

    const paginationConfig = {
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: totalProjects,
        showSizeChanger: true
    } as TablePaginationConfig;

    let sorting: Partial<Record<keyof Project, Direction>>;

    async function onChange(
        paginationOnChange: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Project> | SorterResult<Project>[]
    ) {
        sorter = sorter as SorterResult<Project>;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'asc', descend: 'desc' }[sorter.order];
            const sortingValue = `{"${sorter.columnKey}": "${sortDirection || 'DESC'}"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OffsetPagination<Project> = {
            skip:
                paginationOnChange.current! === 0
                    ? 0
                    : (paginationOnChange.current! - 1) * paginationOnChange.pageSize!,
            limit: paginationOnChange?.pageSize!,
            sorting: sorting ? [sorting] : []
        };

        getProjects(queryParams);
    }
    const hideModal = () => {
        setShowEditModal(false);
        setShowSupplierModal(false);
        setShowManageModal(false);
    };

    const getProjects = useCallback(
        async (queryParams?: OffsetPagination<Project>) => {
            if (!companyId) {
                setLoading(false);
                return;
            }

            const paginationParam: OffsetPagination<Project> = {
                skip: queryParams ? queryParams.skip : 0,
                limit: queryParams ? queryParams.limit : paginationConfig.defaultPageSize,
                sorting: queryParams && queryParams.sorting ? queryParams.sorting : []
            };
            pagination.current = paginationParam;

            setLoading(true);

            api.projectManagementClient
                .loadProjects(companyId, paginationParam)
                .then((response) => {
                    setLoading(false);
                    if (response.isSuccess()) {
                        updateProjects(response.getEntity());
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info: any) => {
                    setLoading(false);

                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        },
        [companyId, api.projectManagementClient]
    );

    const getTotalProjects = useCallback(async () => {
        if (!companyId) {
            return;
        }

        api.projectManagementClient
            .getProjectCount(companyId)
            .then((response) => {
                if (response.isSuccess()) {
                    setTotalProjects(response.getEntity().count);
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }, [companyId, api.projectManagementClient]);

    useEffect(() => {
        getProjects(pagination.current);
        getTotalProjects();
        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            getProjects(pagination.current);
            getTotalProjects();
        }, 20000);

        return () => clearInterval(interval);
    }, [reload, companyId, getProjects, getTotalProjects]);

    const onEnterProject = (_projectId: string, marketId?: string): void => {
        const businessPages = getProjectBusinessPages({ marketId });
        const { isForeman, isProjectManager, isConcreteEngineer } = getProjectPermissions(userCompany, _projectId);

        if (
            (isProjectManager && !isForeman && !isConcreteEngineer) ||
            (isConcreteEngineer && !isProjectManager && !isForeman)
        ) {
            navigate(`/projects/${_projectId}/catalogue-management`);
        } else if (businessPages.includes(BUSINESS_PAGES.ORDERS)) {
            navigate(`/projects/${_projectId}/orders`);
        } else if (businessPages.includes(BUSINESS_PAGES.DELIVERIES)) {
            navigate(`/projects/${_projectId}/deliveries`);
        }
    };

    const onEditProject = (_projectId: string): void => {
        setProjectId(_projectId);
        setShowEditModal(true);
    };

    const onEditSupplier = (_projectId: string): void => {
        setProjectId(_projectId);
        setShowSupplierModal(true);
    };

    const onManageUsers = (_projectId: string): void => {
        setProjectId(_projectId);
        setShowManageModal(true);
    };
    const onFinishCreateEditProjectModal = (): void => {
        getProjects(pagination.current);
        getTotalProjects();
        hideModal();
    };

    if ((!companyId || !projects.length) && !loading) {
        return (
            <NotFound
                message={t`You are currently not assigned to any projects with the email address ${userProfile.userEmail}.
        Please contact the project administrator to request permission.`}
            />
        );
    }

    return (
        <>
            <Table
                data-testid="projects-table"
                rowKey="id"
                columns={projectsTableColumns({
                    userCompany,
                    onEditProject,
                    onEnterProject,
                    onEditSupplier,
                    onManageUsers
                })}
                dataSource={projects}
                showSorterTooltip={false}
                onChange={onChange}
                pagination={paginationConfig}
            />
            <ManageUsersForm
                companyId={companyId}
                projectId={projectId}
                showModal={showManageModal}
                hideModal={hideModal}
                marketId={projects?.find((x) => x.id === projectId)?.marketId}
            />
            <ProjectModalForm
                companyId={companyId}
                isEditMode
                projectId={projectId}
                hideModal={hideModal}
                showModal={showEditModal}
                onComplete={onFinishCreateEditProjectModal}
            />
            <SupplierConfigForm
                companyId={companyId}
                projectId={projectId}
                showModal={showSupplierModal}
                hideModal={hideModal}
            />
        </>
    );
}

export default ProjectsTable;
