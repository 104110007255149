import { t, Trans } from '@lingui/macro';
import {
    Language,
    TimeZone
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Button, Form, Modal } from 'antd';
import { Moment } from 'moment';
import { useContext } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { LanguageContext } from 'src/app-react/providers/LanguageProvider';
import { FormAUS } from './Forms/FormAUS';
import { FormDE } from './Forms/FormDE';
import { FormUS } from './Forms/FormUS';
import { generateAndDownloadXlsx } from './util';

interface IGenerateDocumentModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}

type DocumentFileType = 'concreting-documentation' | 'extended-concreting-documentation';
interface GenerateDocumentConfiguration {
    fileType?: DocumentFileType;
    range?: Moment[];
    supplierOrderId?: string;
}

function GenerateDocumentModal({ open, setOpen, onComplete }: IGenerateDocumentModalProps) {
    const api = useApi();
    const { companyId, projectId, marketId } = useProjectState();
    const { getLanguage } = useContext(LanguageContext);
    const [form] = Form.useForm();

    const onOk = async (): Promise<void> => {
        const fields: GenerateDocumentConfiguration = form.getFieldsValue();
        if (!fields.fileType) {
            return ErrorNotification({
                message: t`Select File Type`,
                description: ''
            });
        }

        let lang = getLanguage();
        if (lang === 'en-UK') {
            lang = 'en-GB';
        }

        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

        if (!timeZone) {
            ErrorNotification({
                message: t`Your browser is not providing anysn timeZone`,
                description: ''
            });
        }
        let message: string = t`Extended Concreting Documentation will be exported shortly.`;

        if (fields.fileType === 'concreting-documentation') {
            message = t`Concreting documentation will be exported shortly.`;
            api.concretingDocumentationClient
                .getConcretingDocumentationPerCLReport(companyId, projectId, {
                    lang: lang as Language,
                    timeZone: timeZone as TimeZone
                })
                .then((res) => {
                    const response = res.getWrappedResponse();
                    generateAndDownloadXlsx(response);
                });
        } else if (fields.fileType === 'extended-concreting-documentation' && marketId === MARKETS.DE) {
            api.concretingDocumentationClient
                .getConcretingDocumentationOrdersExportDE(companyId, projectId, {
                    lang: lang as Language,
                    timeZone: timeZone as TimeZone
                })
                .then((res) => {
                    const response = res.getWrappedResponse();
                    generateAndDownloadXlsx(response);
                });
        } else if (fields.fileType === 'extended-concreting-documentation' && marketId === MARKETS.US) {
            api.concretingDocumentationClient
                .getConcretingDocumentationOrdersExportUS(companyId, projectId, {
                    lang: lang as Language,
                    timeZone: timeZone as TimeZone
                })
                .then((res) => {
                    const response = res.getWrappedResponse();
                    generateAndDownloadXlsx(response);
                });
        } else if (marketId === MARKETS.AUS) {
            const startDate: Date = fields?.range?.[0]?.set({ hour: 0, minute: 0, second: 0 }).toDate();
            const endDate: Date = fields?.range?.[1]?.set({ hour: 23, minute: 59, second: 59 }).toDate();

            api.concretingDocumentationClient
                .getConcretingDocumentationOrdersExportAUS(companyId, projectId, {
                    lang: lang as Language,
                    timeZone: timeZone as TimeZone,
                    startDate: startDate,
                    endDate: endDate,
                    supplierOrderId: fields.supplierOrderId
                })
                .then((res) => {
                    const response = res.getWrappedResponse();
                    generateAndDownloadXlsx(response);
                });
        }

        SuccessNotification({
            message,
            description: ''
        });

        setOpen(false);
    };

    const onCancel = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="modal"
            open={open}
            title={t`Generate documents`}
            width={600}
            onOk={onOk}
            onCancel={onCancel}
            footer={[
                <Button data-testid="cancel-btn" key="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button data-testid="ok-btn" key="okButton" onClick={onOk} type="primary">
                    <Trans>Generate Document</Trans>
                </Button>
            ]}>
            <p data-testid="modal-message">
                <Trans>Choose the export configuration that fits your requirements.</Trans>
            </p>
            <Form form={form} layout="vertical" data-testid="generate-document-form">
                {marketId === MARKETS.DE && <FormDE />}
                {marketId === MARKETS.US && <FormUS />}
                {marketId === MARKETS.AUS && <FormAUS form={form} />}
            </Form>
        </Modal>
    );
}

export default GenerateDocumentModal;
