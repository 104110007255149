import { t } from '@lingui/macro';
import { TestSpecimenBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { FormInstance, notification } from 'antd';
import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import { MODAL_TITLE_AUS } from './AUS/util';
import { MODAL_TITLE_DE } from './DE/util';
import { MODAL_TITLE_US } from './US/util';

export function getModalTitle(marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.AUS:
            return MODAL_TITLE_AUS;
        case MARKETS.US:
            return MODAL_TITLE_US;
        default:
            return MODAL_TITLE_DE;
    }
}

const RESPONSE_ERRORS = {
    TEST_SPECIMEN_ALREADY_EXISTS: 'TEST_SPECIMEN_ALREADY_EXISTS'
};

export function displayError(form: FormInstance, response: SimpleClientResponse<TestSpecimenBP>) {
    const error = response.getWrappedResponse().data as any;
    const errorCode: string | undefined = error?.error?.code;

    let errorMessage: string;

    if (errorCode === RESPONSE_ERRORS.TEST_SPECIMEN_ALREADY_EXISTS) {
        errorMessage = t`This specimen identifier already exists, please insert a unique identifier`;

        form.setFields([
            {
                name: 'specimenIdentification',
                errors: [errorMessage]
            }
        ]);
    } else {
        errorMessage = t`Specimen test could not be created`;
    }

    notification.error({
        message: errorMessage,
        description: ''
    });
}
