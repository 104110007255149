import { SpecimenTestFormValuesAUS } from '../../components/TestSpecimenTestForm/markets/AUS/models';

export function createInitialFormValuesTestSpecimenTestForm() {
    return {
        specimenIdentification: '',
        typeOfTesting: 'compressiveStrength',
        purposeOfTesting: 'fieldTest',
        mould: 'cube',
        compactor: 'internalVibrator'
    } as SpecimenTestFormValuesAUS;
}
