import { Trans, t } from "@lingui/macro";
import { CataloguedStructuralElementExtendedBP } from "@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto";
import { Select } from "antd";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";
import { strengthDevelopmentFormatter } from "src/app-react/utils/formatters";

const { Option } = Select;

function compressiveStrengthClass(structuralElement: CataloguedStructuralElementExtendedBP): string {
    return `${structuralElement.minimalRequiredTypeOfConcreteType} ${structuralElement.minimalRequiredCylindricCompressiveStrength}/${structuralElement.minimalRequiredCubicCompressiveStrength}`;
}
export function AssignToStructuralElementsFormItem(
    { assignableStructuralElements }: { assignableStructuralElements: CataloguedStructuralElementExtendedBP[] }
): JSX.Element {
    return (
        <SelectFormItem
            required={false}
            name="assignToStructuralElements"
            label={t`Assign to structural element(s)`}
        >
            <Select
                mode="multiple"
                key="assignToStructuralElements"
                data-testid="assignToStructuralElements"
                className="concrete-type-modal__input"
                placeholder={t`Please select`}
                optionLabelProp="label"
                optionFilterProp="label">
                {assignableStructuralElements.length > 0 ? (
                    assignableStructuralElements.map((structuralElement: CataloguedStructuralElementExtendedBP) =>
                        <Option
                            key={structuralElement.id}
                            value={structuralElement.id}
                            label={structuralElement.structuralElementName}>
                            <span>
                                <b>{structuralElement.structuralElementName}</b>
                                {' ('}
                                {[
                                    compressiveStrengthClass(structuralElement),
                                    strengthDevelopmentFormatter(
                                        structuralElement.minimalRequiredStrengthDevelopment
                                    )
                                ]
                                    .filter(Boolean)
                                    .join(', ')}
                                )
                            </span>
                        </Option>
                    )
                ) : (
                    <Option value="No results available" disabled>
                        <Trans>No results available</Trans>
                    </Option>
                )}
            </Select>
        </SelectFormItem>
    );
}
