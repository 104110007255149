const SLUMPS_DATA = [
    { value: 0.5, label: '0.5 in' },
    { value: 1, label: '1 in' },
    { value: 1.5, label: '1.5 in' },
    { value: 2, label: '2 in' },
    { value: 2.5, label: '2.5 in' },
    { value: 3, label: '3 in' },
    { value: 3.5, label: '3.5 in' },
    { value: 4, label: '4 in' },
    { value: 4.5, label: '4.5 in' },
    { value: 5, label: '5 in' },
    { value: 5.5, label: '5.5 in' },
    { value: 6, label: '6 in' },
    { value: 6.5, label: '6.5 in' },
    { value: 7, label: '7 in' },
    { value: 7.5, label: '7.5 in' },
    { value: 8, label: '8 in' },
    { value: 8.5, label: '8.5 in' },
    { value: 9, label: '9 in' },
    { value: 9.5, label: '9.5 in' },
    { value: 10, label: '10 in' },
    { value: 10.5, label: '10.5 in' },
    { value: 11, label: '11 in' },
    { value: 11.5, label: '11.5 in' },
    { value: 12, label: '12 in' },
    { value: 12.5, label: '12.5 in' },
    { value: 13, label: '13 in' },
    { value: 13.5, label: '13.5 in' },
    { value: 14, label: '14 in' },
    { value: 14.5, label: '14.5 in' },
    { value: 15, label: '15 in' }
];

export default SLUMPS_DATA;
