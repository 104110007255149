import { AxiosInstance } from 'axios';

export type Role =
    | 'CompanyAdmin'
    | 'ProjectAdmin'
    | 'ProjectManager'
    | 'Foreman'
    | 'Subcontractor'
    | 'Viewer'
    | 'ConcreteEngineer'
    | 'Batcher'
    | 'ActivatedDevice';

export type RoleDefinition = Record<string, Array<string>>;
export type CompanyRoles = Partial<Record<Role, RoleDefinition>>;
export type ProjectRoles = Partial<Record<Role, RoleDefinition>>;

export interface Project {
    marketId: string;
    supplierSoftware: string;
    projectId: string;
    projectRoles: ProjectRoles;
}
export interface Company {
    companyId: string;
    companyName: string;
    companyRoles: CompanyRoles;
    projects: Project[];
}

export interface User {
    userId: string;
    userEmail: string;
    name: string;
    companies: Company[];
}

export interface UserProfile {
    userId: string;
    userEmail: string;
    userName: string;
    companies: Company[];
}

export interface IAuthService {
    readonly user: UserProfile;
    logout(): void;
    getToken(): string;
    getAxiosInstance(): AxiosInstance;
    getAxiosCoreInstance(): AxiosInstance;
    getServerPath(): string;
}

let authService: IAuthService | null = null;

export function getAuthService(): IAuthService {
    if (!authService) {
        throw new Error('Not auth service defined yet');
    }

    return authService;
}

export function setAuthService(auth: IAuthService): void {
    authService = auth;
}
