import moment from 'moment';
import { TemperatureTestFormValuesAUS } from './models';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { MARKETS } from 'src/app-react/constants/markets';
import { FreshConcreteTest, TemperatureTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createTemperatureTestAUS(
    freshConcreteTest: FreshConcreteTest,
    temperatureTestFormValues: TemperatureTestFormValuesAUS
) {
    const { units } = getProjectConstants({ marketId: MARKETS.AUS });
    const testingTime: Date = mergeDateTime(
        moment(temperatureTestFormValues.testingDate),
        moment(temperatureTestFormValues.testingTime)
    ).toDate();

    // Remove ª character Cª => C
    const unit = units.temperature.replace('°', '') as 'C' | 'F';

    const temperatureTest: TemperatureTest = {
        ...freshConcreteTest,
        airTemprature: {
            quantity: temperatureTestFormValues.airTemperature,
            unit
        },
        freshConcreteTemprature: {
            quantity: temperatureTestFormValues.freshConcreteTemperature,
            unit
        },
        testingTime,
        externalInspection: false
    };

    return temperatureTest;
}
