import { t } from '@lingui/macro';
import { Select } from 'antd';
import { useEffect } from 'react';
import useGetDeliveryProductDescriptionByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetDeliveryProductDescriptionByProject';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';

function ProductDescriptionFormItem(props: SelectFormItemProps) {
    const { loadData, productDescriptions, error } = useGetDeliveryProductDescriptionByProject();

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectFormItem label={t`Product description`} name="description" {...props}>
            <Select
                placeholder={t`Please select a product description`}
                allowClear
                options={productDescriptions}
                data-testid="productDescriptionFormItem"
            />
        </SelectFormItem>
    );
}

export default ProductDescriptionFormItem;
