import { OrderFlowState } from '@nexploretechnology/concreting-core-client';

export const ORDER_STATUS: Record<string, OrderFlowState> = {
    INIT: 'init',
    INQUIRY_DRAFT: 'inquiryDraft',
    INQUIRY_REJECTED: 'inquiryRejected',
    INQUIRY_REVIEW: 'inquiryReview',
    CONFIRMED: 'confirmed',
    CANCELLED: 'cancelled',
    CHANGE_REQUEST: 'changeRequest',
    CHANGE_REQUEST_REJECTED: 'changeRequestRejected',
    CANCELLATION_REQUESTED: 'cancellationRequested',
    COMPLETED: 'completed',
    EXPIRED: 'expired',
    REJECTED_FINAL: 'rejectedFinal'
} as const;

export type ORDER_STATUS_VALUES = OrderFlowState;
