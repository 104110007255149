import { Form } from "antd";
import { Trans, t } from "@lingui/macro";
import EditableTagGroup from "src/app-react/business-page/catalogue-management/components/EditableTagGroup/EditableTagGroup";


interface MethodOfConcreteItemProps {
    style?: React.CSSProperties;
    boqPositions: string[]
}

export function BoQPositionsItem({ style, boqPositions }: MethodOfConcreteItemProps) {
    return (
        <Form.Item
            label={<Trans>BoQ positions</Trans>}
            style={style}
            name="boqPositions">
            <EditableTagGroup tags={boqPositions} newTagLabel={t`New`} />
        </Form.Item>
    )
}