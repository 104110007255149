import { PlusOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Button, Card, Col, PageHeader, Row, Select } from 'antd';
import { useMemo, useState } from 'react';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import useLocalStorage from 'src/app-react/hooks/useLocalStorage';
import isRoleSelected from 'src/app-react/utils/isRoleSelected';
import ProjectModalForm from './ProjectModalForm/ProjectModalForm';
import ProjectsTable from './ProjectsTable/ProjectsTable';

function ProjectsPage() {
    const [reload, setReload] = useState(false);
    const [isModalVisible, setModalVisibility] = useState(false);
    const { userProfile } = useGlobalState();
    const [localStorageCompanyId, setLocalStorageCompanyId] = useLocalStorage('preferredCompany');

    const showModal: () => void = () => setModalVisibility(true);
    const hideModal: () => void = () => setModalVisibility(false);

    const companyId = useMemo(() => {
        return userProfile?.companies?.find((c) => c.companyId === localStorageCompanyId)
            ? localStorageCompanyId
            : userProfile?.companies?.[0]?.companyId;
    }, [userProfile, localStorageCompanyId]);

    const userHasProjectAdminRole = isRoleSelected({
        role: 'ProjectAdmin',
        companyId,
        userProfile
    });

    return (
        <>
            <PageHeader title={t`Project list`} backIcon={false} />
            <Row wrap={false}>
                <Col flex="none">
                    <Trans>Selected company</Trans>
                </Col>
                <Col flex="auto">
                    <Select
                        data-testid="company-select"
                        defaultValue={companyId}
                        onChange={setLocalStorageCompanyId}
                        style={{ minWidth: '30%', marginLeft: 10 }}>
                        {userProfile.companies.map((company) => {
                            return (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Col>
                <Col flex="none">
                    {userHasProjectAdminRole && (
                        <Button data-testid="open-project-modal-button" type="primary" onClick={showModal}>
                            <PlusOutlined /> <Trans>Create Project</Trans>
                        </Button>
                    )}
                </Col>
            </Row>
            <br />
            <div>
                {userProfile && <ProjectsTable reload={reload} companyId={companyId} />}
                {userHasProjectAdminRole && (
                    <Card style={{ textAlign: 'center' }}>
                        <Button data-testid="open-project-modal-button" type="primary" onClick={showModal}>
                            <PlusOutlined /> <Trans>Create Project</Trans>
                        </Button>
                    </Card>
                )}
            </div>
            <ProjectModalForm
                companyId={companyId}
                isEditMode={false}
                hideModal={hideModal}
                showModal={isModalVisible}
                onComplete={() => {
                    hideModal();
                    setReload(true);
                }}
            />
        </>
    );
}

export default ProjectsPage;
