import { useState } from 'react';
import styles from '../MultipleConcreteTestModal.module.css';
import { RightSquareOutlined } from '@ant-design/icons';

interface TestReferencesProps {
    imageURL: string;
    visibleLabel: string;
    hiddenLabel: string;
}

export function TestReferences({ imageURL, visibleLabel, hiddenLabel }: TestReferencesProps) {
    const [visible, setVisible] = useState(false);

    return (
        <div
            className={`${styles.refenceContainer} ${visible ? styles.refenceContainerActive : ''}`}
            onClick={() => setVisible((prev) => !prev)}>
            <div className={styles.refenceTitle}>
                <RightSquareOutlined />
                {visible ? visibleLabel : hiddenLabel}
            </div>
            {visible && <img onClick={() => window.open(imageURL)} src={imageURL} />}
        </div>
    );
}
