import { t, Trans } from '@lingui/macro';
import {
  ConcretedStructuralElementTest,
  TypeofTemperature
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { temperatureMeasurementMapper } from 'src/app-react/utils/formatters';

export const temperatureDetailsTableColumns: (marketId: string) => ColumnsType<ConcretedStructuralElementTest> = (
    marketId: string
) => {
    const columns: (ColumnType<ConcretedStructuralElementTest> & { hidden?: boolean })[] = [
        {
            title: <Trans>Temperature type</Trans>,
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (type: TypeofTemperature, record: ConcretedStructuralElementTest) => (
                <p>{temperatureMeasurementMapper[type]}</p>
            )
        },

        {
            title: <Trans>Measurement time</Trans>,
            dataIndex: 'timeMeasurement',
            key: 'timeMeasurement',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.timeMeasurement).unix() - moment(b.timeMeasurement).unix(),
            render: (timeMeasurement: Date) => <DateTimeFormatter date={timeMeasurement} />
        },

        {
            title: <Trans>Temperature</Trans>,
            dataIndex: 'result',
            key: 'result',
            sorter: (a, b) => a.result - b.result,
            render: (_, record: ConcretedStructuralElementTest) => (
                <p>
                    <NumberFormatter value={record.result} unit={record.result != null ? record.resultUnit : ''} />
                </p>
            )
        },

        {
            title: <Trans>Use for Post-treatment</Trans>,
            dataIndex: 'useForPt',
            key: 'useForPt',
            hidden: marketId !== 'DE',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (useForPt: boolean) => <p>{useForPt ? t`Yes` : t`No`}</p>
        }
    ];

    return columns.filter((item) => !item.hidden);
};
