import { Trans, t } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function SupplierUrlFormItem() {
    return (
        <FormItem
            name="url"
            label={<Trans>Supplier software url</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>Please input supplier software url</Trans>
                }
            ]}>
            <Input type="text" data-testid="supplierUrl" placeholder={t`Enter URL`} />
        </FormItem>
    );
}
