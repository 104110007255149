import { Trans, t } from "@lingui/macro";
import { Form, Input } from "antd";


export function DescriptionItem() {
    return <Form.Item
        label={<Trans>Structural element description</Trans>}
        name="elementDescription"
        rules={[]}>
        <Input
            type="text"
            placeholder={t`Enter structural element description`}
            data-testid="elementDescription"
        />
    </Form.Item>
}