import { Trans } from '@lingui/macro';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { ColumnsType } from 'antd/lib/table';

export default function customerInformationTableColumns() {
    const columns: ColumnsType<DeliveryNoteFullDetails> = [
        {
            title: <Trans>Customer name</Trans>,
            key: 'constructorName',
            dataIndex: 'constructorName',
            render: (_val: string, record) => record?.concreteOrder?.constructorName
        },
        {
            title: <Trans>Customer number</Trans>,
            key: 'constructorName',
            dataIndex: 'constructorName',
            render: (_val: string, record) => record?.concreteOrder?.constructorNumber
        },
        {
            title: <Trans>Delivery address</Trans>,
            key: 'constructorName',
            dataIndex: 'constructorName',
            render: (_val: string, record) => record?.unloadingLocation?.locationDescription
        },
        {
            title: <Trans>Unloading location</Trans>,
            key: 'constructorName',
            dataIndex: 'constructorName',
            render: (_val: string, record) => record?.unloadingLocation?.locationName
        },
        {
            title: <Trans>Distance to plant</Trans>,
            key: 'constructorName',
            dataIndex: 'constructorName',
            render: (_val: string, record) => record?.unloadingLocation?.distanceToPlant
        }
    ];

    return columns;
}
