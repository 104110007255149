import { Col } from "antd";
import { ActualDurationFormItem } from "../../FormItems/ActualDurationFormItem";
import { EquippingFormItem } from "../../FormItems/EquippingFormItem";
import { MinimalDurationFormItem } from "../../FormItems/MinimalDurationFormItem";

export function PostTreatmentSummaryFormLayoutDE() {
    return (
        <>
            <Col span={7}>
                <MinimalDurationFormItem />
            </Col>
            <Col span={7}>
                <EquippingFormItem />
            </Col>
            <Col span={7}>
                <ActualDurationFormItem />
            </Col>
        </>
    );
}
