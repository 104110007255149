import { FormInstance } from 'antd';
import { ConcreteTestingTypes } from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import { CommonTestFormValues } from './models';
import moment from 'moment';
import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import { createInitialCommonValuesAUS } from './markets/AUS/util';
import { createInitialCommonValuesDE } from './markets/DE/util';
import { SamplingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialCommonValues(values: any, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.AUS:
            return createInitialCommonValuesAUS(values);
        default:
            return createInitialCommonValuesDE(values);
    }
}

export function getFormCommonValues(commonForm: FormInstance<any>): CommonTestFormValues {
    return {
        samplingLocation: commonForm.getFieldValue('samplingLocation') as SamplingLocationBP,
        testType: commonForm.getFieldValue('testType') as ConcreteTestingTypes[],
        samplingDate: (commonForm.getFieldValue('samplingDate') as moment.Moment).toDate() || new Date(),
        samplingTime: (commonForm.getFieldValue('samplingTime') as moment.Moment).toDate() || new Date()
    };
}
