import { Select } from 'antd';
import { t } from '@lingui/macro';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';

function MethodConcreteFormItem(props: SelectFormItemProps) {
    const { methodConcrete = [] } = useProjectConstantsState();

    return (
        <SelectFormItem
            label={t`Method of concrete use`}
            name="methodOfConcrete"
            {...props}>
            <Select
                allowClear={props.required === false}
                placeholder={t`Please select method of concrete use`}
                options={methodConcrete}
                data-testid="methodOfConcrete"
            />
        </SelectFormItem>
    );
}

export default MethodConcreteFormItem;
