import { Trans } from '@lingui/macro';
import Modal, { ModalProps } from 'antd/lib/modal/Modal';
import { useContext } from 'react';
import { SupplierConfigFormContext } from '../../contexts/SupplierConfigFormContext';

export function SupplierConfigModal(props: Partial<ModalProps>) {
    const { open, onCancel, onOk, children } = props;
    const { supplierConfig } = useContext(SupplierConfigFormContext);

    return (
        <Modal
            maskClosable={false}
            open={open}
            onCancel={onCancel}
            destroyOnClose
            onOk={onOk}
            data-testid="supplier-config-modal"
            title={
                supplierConfig.id ? (
                    <Trans>Edit supplier configuration</Trans>
                ) : (
                    <Trans>Add supplier configuration</Trans>
                )
            }
            okText={<Trans>Save</Trans>}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}>
            {children}
        </Modal>
    );
}
