import { t, Trans } from '@lingui/macro';
import FormItem from 'antd/lib/form/FormItem';
import { useDiscardChanges } from 'src/app-react/components/DiscardChangesModal/hooks/useDiscardChanges';
import { InputNumber } from 'src/app-react/components/Form';

export function MinimalDurationFormItem() {

    const {unsavedChanges} = useDiscardChanges();

    function onChange() {
        unsavedChanges.current = true;
    }

    return (
        <FormItem
            name="targetNumberOfDays"
            label={<b><Trans>Minimal duration</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                required: false,
            }]}>
             <InputNumber
                onChange={onChange}
                style={{width:'100%'}}
                placeholder={t`Enter minimal duration`}
                data-testid="targetNumberOfDays"
                addonAfter={t`day(s)`}
                min={1}
                onKeyDown={e => ['.', ','].includes(e.key) && e.preventDefault()}
            />
        </FormItem>
    );
}

