/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { Select } from 'antd';
import { t } from '@lingui/macro';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';

function MethodConcreteFormItem(props: SelectFormItemProps) {
    const { methodConcrete = [] } = useProjectConstantsState();

    return (
        <SelectFormItem
            label={t`Method of concrete use`}
            name="methodOfConcrete"
            dependencies={['chlorideContentClass']}
            rules={[
                (form) => ({
                    validator(_, value) {
                        const chlorideContentClass = form.getFieldValue('chlorideContentClass');

                        if (!value || !chlorideContentClass) {
                            return Promise.resolve();
                        }

                        switch (value) {
                            case 'prestressedConcrete':
                                if (chlorideContentClass !== 'Cl 0,20') {
                                    return Promise.reject(new Error(`${t`Chloride content should be`} "Cl 0,20"`));
                                }
                                break;
                            case 'reinforcedConcrete':
                                if (chlorideContentClass !== 'Cl 0,40') {
                                    return Promise.reject(new Error(`${t`Chloride content should be`} "Cl 0,40"`));
                                }
                                break;
                            case 'nonReinforcedConcrete':
                                if (chlorideContentClass !== 'Cl 1,00') {
                                    return Promise.reject(new Error(`${t`Chloride content should be`} "Cl 1,00"`));
                                }
                                break;
                            default:
                                return Promise.reject();
                        }

                        return Promise.resolve();
                    }
                })
            ]}
            {...props}>
            <Select
                allowClear={props.required === false}
                placeholder={t`Please select method of concrete use`}
                options={methodConcrete}
                data-testid="methodOfConcreteChlorideContent"
            />
        </SelectFormItem>
    );
}

export default MethodConcreteFormItem;
