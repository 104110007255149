/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement, cloneElement } from 'react';
import { t } from '@lingui/macro';
import { TimePickerProps, Form, FormItemProps } from 'antd';
import parseRules from '../utils/parseRules';

export interface TimePickerFormItemProps extends FormItemProps {
    timePickerProps?: TimePickerProps;
}

function TimePickerFormItem({ required, timePickerProps, ...props }: TimePickerFormItemProps) {
    return (
        <Form.Item {...props} rules={parseRules(required, props)}>
            {isValidElement(props.children) &&
                cloneElement(props.children, { placeholder: t`Enter Time`, ...timePickerProps })}
        </Form.Item>
    );
}

export default TimePickerFormItem;
