import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { EditModalFormContext } from "../../../../../../contexts/EditModalFormContext";
import { CommentFormItem } from "../../FormItems/CommentFormItem";
import { EndingDateFormItem } from "../../FormItems/EndingDateFormItem";
import { PostTreatmentTypeFormItem } from "../../FormItems/PostTreatmentTypeFormItem";
import { StartingDateFormItem } from "../../FormItems/StartingDateFormItem";
import { TypeOtherFormItem } from "../../FormItems/TypeOtherFormItem";

export function PostTreatmentEditlModalFormLayoutUS() {

    const [typeOtherVisible, setTypeOtherVisible] = useState(false);
    const {formInitialValues} = useContext(EditModalFormContext);
    
     useEffect(() => {
        setTypeOtherVisible(formInitialValues && formInitialValues.typeOther !== undefined);
    },[]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <PostTreatmentTypeFormItem 
                    setTypeOtherVisible={setTypeOtherVisible}
                    />
                </Col>
            </Row>

            {typeOtherVisible && <Row>
                <Col span={24}>
                    <TypeOtherFormItem />
                </Col>
            </Row>}

            <Row gutter={24}>
                <Col span={12}>
                    <StartingDateFormItem />
                </Col>
                <Col span={12}>
                    <EndingDateFormItem />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CommentFormItem />
                </Col>
            </Row>
        </>
    )
}