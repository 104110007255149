/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement, cloneElement } from 'react';
import { Form, FormItemProps, InputProps } from 'antd';
import parseRules from '../utils/parseRules';

export interface InputFormItemProps extends FormItemProps {
    inputProps?: InputProps;
    forbiddenStrings?: string[];
}

function InputFormItem({ required, inputProps, forbiddenStrings, ...props }: InputFormItemProps) {
    const normalizeValue = (value: string) => {
        let result = value;
        if (forbiddenStrings?.length > 0) {
            forbiddenStrings.forEach((str) => {
                result = result.replace(new RegExp(str, 'g'), '');
            });
        }
        return result;
    };

    return (
        <Form.Item normalize={normalizeValue} {...props} rules={parseRules(required, props)}>
            {isValidElement(props.children) && cloneElement(props.children, inputProps)}
        </Form.Item>
    );
}

export default InputFormItem;
