import { Trans } from '@lingui/macro';
import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ColumnsType } from 'antd/lib/table';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { IMarketUnits } from 'src/app-react/constants/marketsData';

interface Props {
    concretedStructuralElement: ConcretedStructuralElementBP;
    units: IMarketUnits;
}

export const concreteDetailsTableColumns = ({ units, concretedStructuralElement }: Props) => {
    return [
        {
            title: <Trans>Concreted Structural Element</Trans>,
            key: 'concretedStructuralElementName',
            dataIndex: 'concretedStructuralElementName',
            render: () => concretedStructuralElement?.elementName
        },
        {
            title: <Trans>Concreting Start</Trans>,
            key: 'concretingStart',
            dataIndex: 'concretingStart',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <DateTimeFormatter date={record?.concretedLoad?.concretingStartDate} />
            )
        },
        {
            title: <Trans>Concreting End</Trans>,
            key: 'concretingEnd',
            dataIndex: 'concretingEnd',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <DateTimeFormatter date={record?.concretedLoad?.concretingEndDate} />
            )
        },
        {
            title: <Trans>Concreted Quantity</Trans>,
            key: 'quantity',
            dataIndex: 'quantity',
            render: (_: string, record: DeliveryNoteAllDetails) => (
                <NumberFormatter value={record?.concretedLoad?.volume} unit={units.volume} />
            )
        },
        {
            title: <Trans>Weather during concreting</Trans>,
            key: 'weather',
            dataIndex: 'weather',
            render: (_: string, record: DeliveryNoteAllDetails) => record?.concretedLoad?.weather
        }
    ] as ColumnsType<DeliveryNoteAllDetails>;
};

export default concreteDetailsTableColumns;
