import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import { OrderManagementProvider } from './providers/order.management.provider';
import OrderManagementFormPage from './components/OrderManagementFormPage/OrderManagementFormPage';

const UpdateOrderHandler = () => {
    const { projectId, orderId, structElementId, concreteTypeId } = useParams();

    return (
        <OrderManagementProvider projectId={projectId}>
            <OrderManagementFormPage 
                title={t`Update order`}
                urlBack={`/projects/${projectId}/order-type/${orderId}/edit`}
                orderId={orderId} 
                structuralElementId={structElementId} 
                concreteTypeId={concreteTypeId} 
                action='update' />
        </OrderManagementProvider>
    );
};

export default UpdateOrderHandler;
