import { Trans } from '@lingui/macro';
import { Radio, RadioChangeEvent } from 'antd';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import { useSearchParamsState } from 'src/app-react/hooks/useSearchParamsState';

interface Props {
    setDisplayCustomRange: React.Dispatch<React.SetStateAction<boolean>>;
}

export function DeliveryTimeInputItem({ setDisplayCustomRange }: Props) {
    const { setFilter } = useContext(ConcretingDocumentationContext);
    const [period, setPeriod] = useSearchParamsState('deliveryTime', '');

    useEffect(() => {
        setFilter((prev: any) => {
            const newFilter = { ...prev };
            if (period !== 'custom') {
                setDisplayCustomRange(false);
            }
            switch (period) {
                case 'last2Days':
                    newFilter.deliveryTime = {
                        startDate: moment().add(-2, 'days').toDate(),
                        endDate: moment().toDate()
                    };
                    break;
                case 'lastWeek':
                    newFilter.deliveryTime = {
                        startDate: moment().add(-7, 'days').toDate(),
                        endDate: moment().toDate()
                    };
                    break;
                case 'custom':
                    setDisplayCustomRange(true);
                    break;
                case 'today':
                    newFilter.deliveryTime = {
                        startDate: moment().startOf('day').toDate(),
                        endDate: moment().endOf('day').toDate()
                    };
                    break;
                case '':
                default:
                    newFilter.deliveryTime = null;
                    break;
            }

            return newFilter;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]);

    function onChange(e: RadioChangeEvent) {
        setPeriod(e.target.value);
    }

    return (
        <>
            <b>
                <Trans>Period of concreting</Trans>
            </b>
            <Radio.Group
                value={period}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}>
                <Radio.Button value="today">
                    <Trans>Today</Trans>
                </Radio.Button>
                <Radio.Button value="last2Days">
                    <Trans>Last 2 days</Trans>
                </Radio.Button>
                <Radio.Button value="lastWeek">
                    <Trans>Last week</Trans>
                </Radio.Button>
                <Radio.Button value="custom">
                    <Trans>Custom</Trans>
                </Radio.Button>
                <Radio.Button value="">
                    <Trans>All time</Trans>
                </Radio.Button>
            </Radio.Group>
        </>
    );
}
