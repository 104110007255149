import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import {
    AssignedConcreteTypesBP,
    ConcreteTypeExtendedBP,
    ConcreteTypesWithPaginationParamsBP,
    DataList,
    SimpleClientResponse
} from '@nexploretechnology/concreting-core-client';
import { Button, Form, Modal, Select, SelectProps, Tag } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import {
    formatCompressiveStrengthDE,
    formatCompressiveStrengthUS,
    formatConsistency,
    formatDescription,
    formatMaximumAggregateSize,
    formatPercentageByLocale,
    formatSlump,
    formatStrengthDevelopmentClass
} from '../../utils';

const { Option } = Select;

const marketIdDE = 'DE';
export type TSearchResultsState = SelectProps<object>['options'];
export interface IOptions {
    value: string;
    label: ReactElement;
}
interface IProps {
    structuralElementId: string;
    open: boolean;
    initialValues?: string[];
    action: 'Assign' | 'Unassign';
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}

const noPaginationParameters: ConcreteTypesWithPaginationParamsBP = {
    pagination: {
        skip: 0,
        limit: 1000,
        sorting: []
    }
};

function AssignConcreteType({ structuralElementId, open, initialValues = [], action, setOpen, onComplete }: IProps) {
    const { projectId, companyId, marketId } = useProjectState();
    const [concreteTypesByCaSEForm] = Form.useForm();
    const [showErrorNoConcreteTypeRemoved, setShowErrorNoConcreteTypeRemoved] = useState(false);
    const [showErrorNoConcreteTypeAdded, setShowErrorNoConcreteTypeAdded] = useState(false);
    const api = useApi();

    useEffect(() => {
        updateValue();
    }, [initialValues]);

    const [getAssignableConcreteTypes, setAssignableConcreteTypes] = useState<ConcreteTypeExtendedBP[]>([]);

    const getAssignableConcreteTypesOptions = async (): Promise<ConcreteTypeExtendedBP[]> => {
        return structuralElementId
            ? api.catalogueManagementClient
                  .getAssignedExtendedConcreteTypesToCaSeAsDataList(
                      companyId,
                      projectId,
                      structuralElementId,
                      noPaginationParameters
                  )

                  .then((response: SimpleClientResponse<DataList<ConcreteTypeExtendedBP>>) => {
                      if (response.isSuccess()) {
                          const assignedConcreteTypes: ConcreteTypeExtendedBP[] = response.getEntity().data;

                          if (action === 'Unassign') {
                              return assignedConcreteTypes;
                          }

                          return api.catalogueManagementClient
                              .getExtendedConcreteTypesForProjectAsDataList(
                                  companyId,
                                  projectId,
                                  noPaginationParameters
                              )
                              .then((response: SimpleClientResponse<DataList<ConcreteTypeExtendedBP>>) => {
                                  if (response.isSuccess()) {
                                      const assignableForProjectConcreteTypes: ConcreteTypeExtendedBP[] =
                                          response.getEntity().data;

                                      const assignableConcreteTypes: ConcreteTypeExtendedBP[] =
                                          assignableForProjectConcreteTypes.filter(
                                              (ct: ConcreteTypeExtendedBP) =>
                                                  !assignedConcreteTypes.find(
                                                      (assignedCT: ConcreteTypeExtendedBP) => ct.id === assignedCT.id
                                                  )
                                          );
                                      return assignableConcreteTypes;
                                  }
                                  ErrorNotification({
                                      message: response.getError(),
                                      description: ''
                                  });

                                  return [];
                              });
                      }
                      ErrorNotification({
                          message: response.getError(),
                          description: ''
                      });

                      return [];
                  })
            : [];
    };

    const loadAssignableConcreteTypes = async (): Promise<void> => {
        setAssignableConcreteTypes(await getAssignableConcreteTypesOptions());
    };

    useEffect(() => {
        loadAssignableConcreteTypes();
    }, [open, projectId, companyId]);

    const updateValue = (): void => {
        if (initialValues) {
            concreteTypesByCaSEForm.setFieldsValue({
                concreteTypeIds: initialValues
            });
        }
    };

    const onCancel = (): void => {
        concreteTypesByCaSEForm.resetFields();
        setOpen(false);
        setShowErrorNoConcreteTypeAdded(false);
        setShowErrorNoConcreteTypeRemoved(false);
    };

    const checkSaveModificationsAllowed = (
        storedStrucElemIds: string[],
        currentInputStructElementIds: string[]
    ): boolean => {
        if (storedStrucElemIds.length !== currentInputStructElementIds.length) return true;
        for (const currentId of currentInputStructElementIds) {
            if (!storedStrucElemIds.includes(currentId)) return true;
        }

        return false;
    };

    const onSubmit = async (): Promise<void> => {
        await concreteTypesByCaSEForm.validateFields().then(async (values: { concreteTypeIds: string[] }) => {
            let selectedConcreteTypeIds: string[] = values.concreteTypeIds?.length > 0 ? values.concreteTypeIds : [];

            if (action === 'Assign') {
                if (selectedConcreteTypeIds.length === 0) {
                    setShowErrorNoConcreteTypeAdded(true);
                } else {
                    api.catalogueManagementClient
                        .getAssignedExtendedConcreteTypesToCaSeAsDataList(
                            companyId,
                            projectId,
                            structuralElementId,
                            noPaginationParameters
                        )
                        .then((response: SimpleClientResponse<DataList<ConcreteTypeExtendedBP>>) => {
                            if (response.isSuccess()) {
                                const assignedConcreteTypes: ConcreteTypeExtendedBP[] = response.getEntity().data;
                                selectedConcreteTypeIds = selectedConcreteTypeIds.concat(
                                    assignedConcreteTypes.map((ct: ConcreteTypeExtendedBP) => ct.id)
                                );
                                setShowErrorNoConcreteTypeAdded(false);
                                saveAssignedConcreteTypesToCataloguedStructuralElement();
                            }
                        });
                }
            } else {
                // unassign
                api.catalogueManagementClient
                    .getAssignedExtendedConcreteTypesToCaSeAsDataList(
                        companyId,
                        projectId,
                        structuralElementId,
                        noPaginationParameters
                    )
                    .then((response: SimpleClientResponse<DataList<ConcreteTypeExtendedBP>>) => {
                        if (response.isSuccess()) {
                            const assignedConcreteTypes: ConcreteTypeExtendedBP[] = response.getEntity().data;
                            const assignedConcreteTypeInDbIds = assignedConcreteTypes.map((element) => element.id);

                            if (checkSaveModificationsAllowed(assignedConcreteTypeInDbIds, selectedConcreteTypeIds)) {
                                setShowErrorNoConcreteTypeRemoved(false);
                                saveAssignedConcreteTypesToCataloguedStructuralElement();
                            } else {
                                setShowErrorNoConcreteTypeRemoved(true);
                            }
                        }
                    });
            }

            function saveAssignedConcreteTypesToCataloguedStructuralElement(): void {
                const payload: AssignedConcreteTypesBP = {
                    concreteTypeIds: selectedConcreteTypeIds
                };

                api.catalogueManagementClient
                    .assignConcreteTypesToCataloguedStructuralElement(
                        companyId,
                        projectId,
                        structuralElementId,
                        payload
                    )
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message:
                                    action === 'Assign'
                                        ? t`Concrete type(s) assigned successfully`
                                        : t`Concrete type(s) unassigned successfully`,
                                description: ''
                            });

                            concreteTypesByCaSEForm.resetFields();

                            onComplete?.();
                            setOpen(false);
                        } else {
                            ErrorNotification({
                                message: response.getError(),
                                description: ''
                            });
                        }
                    })
                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
            }
        });
    };

    const displayTitleForm = (): JSX.Element => {
        return (
            <label data-testid="title-form-label">
                {action === 'Assign' ? (
                    <Trans>Assign concrete type(s) to catalogued structural element </Trans>
                ) : (
                    <Trans>Unassign concrete type(s) to catalogued structural element </Trans>
                )}
            </label>
        );
    };
    const commonFieldsAssignConcreteTypeRenderDE = (): JSX.Element => {
        function tagRender(propsAssiged: any) {
            const { label, closable, onClose } = propsAssiged;
            return (
                <Tag
                    color="blue"
                    closable={closable}
                    onClose={onClose}
                    style={{ marginRight: 3 }}
                    data-testid="valueConcreteTypeIds">
                    {label}
                </Tag>
            );
        }

        return (
            <>
                <Form.Item
                    label={
                        action === 'Assign' ? (
                            <Trans>Assign concrete type(s) to structural element</Trans>
                        ) : (
                            <Trans>Unassign concrete type(s) from structural element</Trans>
                        )
                    }
                    name="concreteTypeIds"
                    className="structural-element-modal__inline100">
                    <Select
                        optionLabelProp="label"
                        optionFilterProp="label"
                        mode="multiple"
                        showArrow
                        tagRender={tagRender}
                        style={{ width: '100%' }}
                        data-testid="concreteTypeIds">
                        {getAssignableConcreteTypes.length > 0 ? (
                            getAssignableConcreteTypes.map((concreteType: ConcreteTypeExtendedBP) => {
                                return marketId === marketIdDE ? (
                                    <Option
                                        key={concreteType.id}
                                        value={concreteType.id}
                                        label={concreteType.concreteTypeNumber}>
                                        <div data-testid="field">
                                            <span>
                                                <b>{concreteType.concreteTypeNumber}</b>
                                                {' ('}
                                                {[
                                                    formatCompressiveStrengthDE(
                                                        concreteType.typeOfConcrete,
                                                        concreteType.cylindricCompressiveStrength,
                                                        concreteType.cubicCompressiveStrength
                                                    ),
                                                    formatConsistency(
                                                        concreteType.consistencyType,
                                                        concreteType.consistencyClass
                                                    ),
                                                    formatMaximumAggregateSize(concreteType.maximumAggregateSize),
                                                    formatStrengthDevelopmentClass(
                                                        concreteType.strengthDevelopmentClass
                                                    ),
                                                    formatDescription(concreteType.concreteTypeDescription)
                                                ]
                                                    .filter((x) => x !== '')
                                                    .join(', ')}
                                                )
                                            </span>
                                        </div>
                                    </Option>
                                ) : (
                                    <Option
                                        key={concreteType.id}
                                        value={concreteType.id}
                                        label={concreteType.concreteTypeNumber}>
                                        <div data-testid="field">
                                            <span>
                                                <b>{concreteType.concreteTypeNumber}</b>
                                                {' ('}
                                                {[
                                                    formatCompressiveStrengthUS(
                                                        concreteType.compressiveStrength,
                                                        concreteType.compressiveStrengthUnit
                                                    ),
                                                    formatSlump(
                                                        concreteType.slump,
                                                        concreteType.slumpVariance,
                                                        concreteType.slumpUnit
                                                    ),
                                                    formatPercentageByLocale(
                                                        concreteType.airContentInPercent,
                                                        i18n.locale
                                                    ),
                                                    formatDescription(concreteType.concreteTypeDescription)
                                                ]
                                                    .filter((x) => x !== '')
                                                    .join(', ')}
                                                )
                                            </span>
                                        </div>
                                    </Option>
                                );
                            })
                        ) : (
                            <Option value="No results available" disabled>
                                <span>
                                    <Trans>No results available</Trans>
                                </span>
                            </Option>
                        )}
                    </Select>
                </Form.Item>
                <div>
                    <p style={{ color: 'red' }}>
                        {showErrorNoConcreteTypeRemoved ? (
                            <Trans>Cannot save: no concrete type has been removed</Trans>
                        ) : (
                            ''
                        )}
                    </p>
                </div>
                <div>
                    <p style={{ color: 'red' }}>
                        {showErrorNoConcreteTypeAdded ? (
                            <Trans>Cannot save: no concrete type has been added</Trans>
                        ) : (
                            ''
                        )}
                    </p>
                </div>
            </>
        );
    };

    const displayTextOkButton = (): JSX.Element => {
        return (
            <label data-testid="okButton-label">
                <Trans>Save</Trans>
            </label>
        );
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="assign-concrete-types-modal"
            forceRender
            getContainer={false}
            title={displayTitleForm()}
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button data-testid="cancelButton" key="cancelButton" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    onClick={onSubmit}
                    data-testid="concrete-types-submit"
                    type="primary"
                    key="concrete-types-submit-btn">
                    {displayTextOkButton()}
                </Button>
            ]}>
            <Form
                form={concreteTypesByCaSEForm}
                data-testid="assignConcreteTypesFormModal"
                key={`structural-modal-form-${projectId}`}
                initialValues={{ initialValues }}>
                <label data-testid="labelSelected">
                    {action === 'Assign' ? (
                        <Trans>
                            Please select the concrete type(s) you want to assign to the catalogued structural element
                        </Trans>
                    ) : (
                        <Trans>
                            Please deselect the concrete type(s) you want to unassign to the catalogued structural
                            element
                        </Trans>
                    )}
                </label>
                <p />
                {commonFieldsAssignConcreteTypeRenderDE()}
            </Form>
        </Modal>
    );
}

export default AssignConcreteType;
