/* eslint-disable react/require-default-props */

import { numberFormatter } from 'src/app-react/utils/formatters';

interface NumberFormatterProps {
    value: number | string | undefined | null;
    unit?: string;
    defaultValue?: string;
    // maximumFractionDigits?: number;
    format?: Intl.NumberFormatOptions;
}

function NumberFormatter({ value, unit, defaultValue, format }: NumberFormatterProps) {
    return <>{numberFormatter(value, unit, defaultValue, format)}</>;
}

export default NumberFormatter;
