import { Outlet } from 'react-router-dom';
import AppMenu from '../AppMenu/AppMenu';

function CompanyLayout() {
    return (
        <AppMenu>
            <Outlet />
        </AppMenu>
    );
}

export default CompanyLayout;
