import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { ORDER_STATUS } from '../../constants/orderStatus';
import { SUPPLIER_VALUES } from '../../constants/suppliers';
import { SUPPLIERS_DATA } from '../../constants/suppliersData';

const DEFAULT_EDIT_DISALLOWED_STATUS = [ORDER_STATUS.CANCELLATION_REQUESTED, ORDER_STATUS.CANCELLED];
const DEFAULT_CANCEL_DISALLOWED_STATUS = [ORDER_STATUS.CANCELLATION_REQUESTED, ORDER_STATUS.CANCELLED];
const DEFAULT_ADDITIONAL_QUANTITY_DISALLOWED_STATUS = [ORDER_STATUS.CANCELLATION_REQUESTED, ORDER_STATUS.CANCELLED];

function isValidSupplierAndOrderToEdit(supplierId: SUPPLIER_VALUES, record: OrderOverviewBP): boolean {
    const suppliersData = SUPPLIERS_DATA[supplierId];

    if (record.concreteLoadCount > 0) {
        return false;
    }

    if (record.orderStatus === 'inquiryDraft' && supplierId === 'cemex.cemexgo') {
        return !record.inquiryOrderAlreadyUpdated;
    }

    if (!suppliersData?.permissions?.allowOrderEdit) {
        return false;
    }

    if (suppliersData.custom?.orderEditStatus?.allow) {
        return suppliersData.custom.orderEditStatus.allow.includes(record.orderStatus);
    }

    if (suppliersData.custom?.orderEditStatus?.disallow) {
        return !suppliersData.custom.orderEditStatus.disallow.includes(record.orderStatus);
    }

    return !DEFAULT_EDIT_DISALLOWED_STATUS.includes(record.orderStatus);
}

function isValidSupplierAndOrderToCancel(supplierId: SUPPLIER_VALUES, record: OrderOverviewBP): boolean {
    const suppliersData = SUPPLIERS_DATA[supplierId];

    if ((record.concreteLoadCount || 0) > 1) {
        return false;
    }

    if (!suppliersData?.permissions?.allowOrderCancel) {
        return false;
    }

    if (suppliersData.custom?.orderCancelStatus?.allow) {
        return suppliersData.custom.orderCancelStatus.allow.includes(record.orderStatus);
    }

    if (suppliersData.custom?.orderCancelStatus?.disallow) {
        return !suppliersData.custom.orderCancelStatus.disallow.includes(record.orderStatus);
    }

    return !DEFAULT_CANCEL_DISALLOWED_STATUS.includes(record.orderStatus);
}

function isValidSupplierAndOrderToAdditionalQuantity(supplierId: SUPPLIER_VALUES, record: OrderOverviewBP): boolean {
    const suppliersData = SUPPLIERS_DATA[supplierId];

    if (!suppliersData?.permissions?.allowOrderAdditionalQuantity) {
        return false;
    }

    if (suppliersData.custom?.orderAdditionalQuantityStatus?.allow) {
        return suppliersData.custom.orderAdditionalQuantityStatus.allow.includes(record.orderStatus);
    }

    if (suppliersData.custom?.orderAdditionalQuantityStatus?.disallow) {
        return !suppliersData.custom.orderAdditionalQuantityStatus.disallow.includes(record.orderStatus);
    }

    return !DEFAULT_ADDITIONAL_QUANTITY_DISALLOWED_STATUS.includes(record.orderStatus);
}

function isDeliveryTimeOver(supplierId: SUPPLIER_VALUES, order: OrderOverviewBP): boolean {
    const suppliersData = SUPPLIERS_DATA[supplierId];

    if (suppliersData?.custom?.checkDeliveryTimeFunc) {
        return suppliersData.custom.checkDeliveryTimeFunc(order.deliveryTime);
    }

    return new Date() > new Date(order.deliveryTime);
}

export default function getOrderPermissions({ supplierId, record }: { supplierId: string; record: OrderOverviewBP }) {
    const validDeliveryTime = !isDeliveryTimeOver(supplierId as SUPPLIER_VALUES, record);
    const validOrderType = record.orderType == null;

    const allowEdit =
        validOrderType && validDeliveryTime && isValidSupplierAndOrderToEdit(supplierId as SUPPLIER_VALUES, record);

    const allowCancel =
        validOrderType && validDeliveryTime && isValidSupplierAndOrderToCancel(supplierId as SUPPLIER_VALUES, record);

    const allowAdditionalQuantity =
        validOrderType && isValidSupplierAndOrderToAdditionalQuantity(supplierId as SUPPLIER_VALUES, record);

    return {
        allowEdit,
        allowCancel,
        allowDuplicate: validOrderType,
        allowAdditionalQuantity
    };
}
