/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form, Input, InputProps } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import {
    CONCRETE_LOAD_LITE_MODAL_ACTION,
    ConcreteLoadLiteModalAction
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';

interface SupplierOrderIdFormItemProps extends FormItemInputProps {
    action?: ConcreteLoadLiteModalAction;
}

function SupplierOrderIdFormItem({ action, ...props }: SupplierOrderIdFormItemProps) {
    let isReadOnly = false;
    if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT) {
        isReadOnly = true;
    }

    return (
        <Form.Item name="supplierOrderId" label={t`Supplier order ID`} {...props}>
            <Input
                readOnly={isReadOnly}
                placeholder={t`Enter supplier order ID`}
                data-testid="supplierOrderIdFormItem"
            />
        </Form.Item>
    );
}

export default SupplierOrderIdFormItem;
