import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import {
    DeliveryNoteDetailsBP,
    DeliveryNoteFullDetails
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { SupplierConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

export interface DeliveryNoteAllDetails extends DeliveryNoteFullDetails {
    deliveryDetails: DeliveryNoteDetailsBP;
    supplierConcreteLoad?: SupplierConcreteLoad;
}

interface Props {
    concreteLoadId: string;
}

function useConcretedStructuralElement({ concreteLoadId }: Props) {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [loading, setLoading] = useState<boolean>(false);

    const [concretedStructuralElement, setConcretedStructuralElement] = useState<ConcretedStructuralElementBP>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadData = useCallback(async () => {
        setLoading(true);

        api.concretingDocumentationClient
            .getConcretedStructuralElementByConcreteLoadId(companyId, projectId, concreteLoadId)
            .then((response) => {
                setLoading(false);
                setConcretedStructuralElement(response.getEntity());
            })
            .catch((error: any) => {
                setLoading(false);
                setError({ message: error.code, description: error.message });
            });
    }, [api.concretingDocumentationClient, projectId, companyId, concreteLoadId]);

    return {
        error,
        loading,
        loadData,
        concretedStructuralElement
    };
}

export default useConcretedStructuralElement;
