import { t, Trans } from '@lingui/macro';
import { OrderFlowState } from '@nexploretechnology/concreting-core-client/order.services-client/order.services.dto';

export function mapStrengthDevelopmentClass(storedValue: string): string {
    switch (storedValue) {
        case 'fast':
            return t`Fast`;
        case 'intermediate':
            return t`Intermediate`;
        case 'slow':
            return t`Slow`;
        case 'very slow':
            return t`Very slow`;
        default:
            return '';
    }
}

export function tagStatusColor(status: OrderFlowState, concreteLoadCount: number, endTimeDelivery: boolean): string {
    let color: string;
    switch (status) {
        case 'confirmed':
        case 'changeRequestRejected':
            if (concreteLoadCount > 0 && endTimeDelivery === true) {
                color = 'orange';
            } else {
                color = 'green';
            }
            break;
        case 'completed':
            color = 'green';
            break;
        case 'cancellationRequested':
            color = 'orange';
            break;
        case 'cancelled':
        case 'cancelledFinal':
        case 'inquiryRejected':
        case 'rejectedFinal':
            color = 'red';
            break;
        default:
            color = 'blue'; // grey is not supported by ant design
    }
    return color;
}

export function statusText(status: OrderFlowState, concreteLoadCount: number, endTimeDelivery: boolean): JSX.Element {
    switch (status) {
        case 'confirmed':
        case 'changeRequestRejected':
            if (concreteLoadCount > 0) {
                if (endTimeDelivery === true) {
                    return <Trans>Awaiting signature</Trans>;
                }
                return <Trans>In delivery</Trans>;
            }
            return <Trans>Order confirmed</Trans>;

        case 'cancelled':
        case 'cancelledFinal':
            return <Trans>Order cancelled</Trans>;
        case 'cancellationRequested':
            return <Trans>Cancellation requested</Trans>;
        case 'changeRequest':
            return <Trans>Update requested</Trans>;
        case 'inquiryRejected':
            return <Trans>Order rejected</Trans>;
        case 'inquiryDraft':
        case 'inquiryReview':
            return <Trans>Order inquired</Trans>;
        case 'rejectedFinal':
            return <Trans>Order rejected</Trans>;
        case 'completed':
            return <Trans>Completed</Trans>;
        case 'expired':
            return <Trans>Expired</Trans>;
    }
    return <></>;
}
