import { MARKET_VALUES } from 'src/app-react/constants/markets';
import { MARKETS_DATA } from 'src/app-react/constants/marketsData';
import { SUPPLIER_VALUES } from '../../constants/suppliers';
import { SUPPLIERS_DATA } from '../../constants/suppliersData';

export default function getOrderDependencies({ supplierId, marketId }: { supplierId?: string; marketId?: string }) {
    return {
        ...MARKETS_DATA[marketId as MARKET_VALUES]?.custom?.orderDependencies,
        ...SUPPLIERS_DATA[supplierId as SUPPLIER_VALUES]?.custom?.orderDependencies
    };
}
