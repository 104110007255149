import { Trans } from '@lingui/macro';
import emptySvg from '../../../assets/images/emptystate_illu.svg';

function NotFound({ message }: { message?: string }) {
    return (
        <div style={{ textAlign: 'center', marginTop: '10%' }}>
            <p style={{ fontWeight: 'bold', color: '#1F3855' }}>{message || <Trans>Page not found</Trans>}</p>
            <img alt="not-found" src={emptySvg} />
        </div>
    );
}

export default NotFound;
