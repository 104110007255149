import { InputMultipleConcreteTest } from '@nexploretechnology/concreting-core-client';
import { useCallback } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

interface BasicParams {
    orderId: string;
    concreteLoadId: string;
}

function useMultipleTest() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();

    return useCallback(
        async (inputTests: InputMultipleConcreteTest, basicParams: BasicParams) => {
            return api.concreteMultipleTestClient
                .createOrUpdateTests(companyId, projectId, basicParams.orderId, basicParams.concreteLoadId, inputTests)
                .then((response) => {
                    const data = response.httpResponse.data;
                    return { success: response.isSuccess(), error: undefined, data };
                })
                .catch((err) => ({
                    data: null,
                    success: false,
                    error: err.message
                }));
        },
        [api, projectId, companyId]
    );
}

export default useMultipleTest;
