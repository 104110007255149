import { MARKETS, MARKET_VALUES } from "src/app-react/constants/markets";

import { createInitialFormValuesTestSpecimenTestForm as createInitialFormValuesTestSpecimenTestFormDE } from './markets/DE/util';
import { createInitialFormValuesTestSpecimenTestForm as createInitialFormValuesTestSpecimenTestFormUS } from './markets/US/util';
import { createInitialFormValuesTestSpecimenTestForm as createInitialFormValuesTestSpecimenTestFormAUS } from './markets/AUS/util';
import { TestingAgeForConcreteLoad } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto";

export function createInitialFormValuesTestSpecimenTestForm(testingAgeForConcreteLoad: TestingAgeForConcreteLoad, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return createInitialFormValuesTestSpecimenTestFormDE(testingAgeForConcreteLoad);
        case MARKETS.AUS:
            return createInitialFormValuesTestSpecimenTestFormAUS();
        default:
            return createInitialFormValuesTestSpecimenTestFormUS();
    }
}