import { Trans } from "@lingui/macro";

export const ConcreteTypeAction = ({ action }: { action: string }): JSX.Element => {
    switch (action) {
        case 'create':
        case 'duplicate':
            return (
                <label data-testid="okButton-label">
                    <Trans>Add concrete type</Trans>
                </label>
            );
        case 'edit':
            return (
                <label data-testid="okButton-label">
                    <Trans>Update concrete type</Trans>
                </label>
            );
        default:
            return <></>;
    }
};