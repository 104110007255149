import { t, Trans } from '@lingui/macro';
import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Form, Modal } from 'antd';
import { useEffect } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { createNewCose, getInitialFormValues } from '../../../utils';
import { ConcretedStructuralElementEditModalForm } from './Forms';

interface ConcretedStructuralElementModalEditProps {
    cose: ConcretedStructuralElementBP;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    triggerReload?: () => void;
}

export function ConcretedStruturalElementModalEdit(props: ConcretedStructuralElementModalEditProps) {
    const [form] = Form.useForm();
    const { cose, open, setOpen, triggerReload } = props;
    const { projectId, companyId, marketId } = useProjectState();
    const api = useApi();

    function setInitialValues() {
        form.setFieldsValue(getInitialFormValues(cose));
    }

    async function onSubmit() {
        await form
            .validateFields()
            .then((data) => {
                const newCose = createNewCose(cose, data, marketId, projectId, companyId);

                api.concretingDocumentationClient
                    .updateConcretedStructuralElement(companyId, projectId, cose.id, newCose)
                    .then((response) => {
                        if (response.isSuccess()) {
                            SuccessNotification({
                                message: t`Structural element has been updated successfully`,
                                description: ''
                            });
                            triggerReload();
                            setOpen(false);
                        } else {
                            const msg = JSON.parse(response.getError());
                            ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                        }
                    })
                    .catch((info) => {
                        ErrorNotification({
                            message: info.errorFields[0].errors[0],
                            description: ''
                        });
                    });
            })
            .catch((info) => {
                ErrorNotification({ message: info.errorFields[0].errors[0], description: '' });
            });
    }

    function onClose() {
        setOpen(false);
    }

    useEffect(() => {
        if (cose) {
            setInitialValues();
        }
    }, [cose, projectId]);

    return (
        <Modal
            maskClosable={false}
            open={open}
            data-testid="edit-cose-modal"
            title={<Trans>Edit concreted structural element</Trans>}
            okText={<Trans>Save</Trans>}
            cancelText={<Trans>Cancel</Trans>}
            onOk={onSubmit}
            onCancel={onClose}
            destroyOnClose
            width={800}>
            <ConcretedStructuralElementEditModalForm form={form} />
        </Modal>
    );
}
