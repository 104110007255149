import { ISupplierData } from '.';
import { MARKETS } from '../markets';

export const CIMIC_DATA: ISupplierData = {
    market: MARKETS.AUS,
    label: 'Cimic',
    name: ['Cimic'],
    manualIntegration: true,
    permissions: {
        allowOrderEdit: false,
        allowOrderCancel: false,
        allowOrderAdditionalQuantity: false,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: true
    }
};
