import { t, Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import useOrderDependenciesState from 'src/app-react/business-page/order-management/hooks/useOrderDependenciesState';
import { buildDefaultOptionType } from '../../../../../../../../shared/Forms/utils/buildDefaultOptionType';

export function SpacingInMinutesFormItem() {
    const {
        dependencies: { spacingInMinutes = [] }
    } = useOrderDependenciesState();

    const options = buildDefaultOptionType(spacingInMinutes);

    return (
        <Form.Item
            name="spacingInMinutes"
            label={<Trans>Spacing between trucks</Trans>}
            rules={[
                {
                    required: true,
                    message: (
                        <span data-testid="errorInputSpacingInMinutes">
                            <Trans>This field is required</Trans>
                        </span>
                    )
                }
            ]}>
            <Select placeholder={t`Select spacing between trucks`} options={options} data-testid="spacingInMinutes" />
        </Form.Item>
    );
}
