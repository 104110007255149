import { MARKETS, MARKET_VALUES } from "src/app-react/constants/markets";
import { createAirContentTestDE, createInitialValuesDE, getAirContentFormValuesDE } from './markets/DE/util';
import { createAirContentTestUS, createInitialValuesUS, getAirContentFormValuesUS } from './markets/US/util';
import { FormInstance } from "antd";
import { TestSamplingWithType } from "../CommonTestForm/models";
import { AirContentTestFormValues } from "./models";
import { newFreshConcreteTest } from "src/app-react/business-page/delivery-note-details/utils/concrete-tests-service";
import { AirContentTestFormValuesDE } from "./markets/DE/models";
import { AirContentTestFormValuesUS } from "./markets/US/models";
import { AirContentTest, FreshConcreteTest } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto";

export function createInitialValues(values: any, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return createInitialValuesDE(values);
        default:
            return createInitialValuesUS(values);
    }
}

export function getAirContentFormValues(values: FormInstance<any>, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return getAirContentFormValuesDE(values);
        default:
            return getAirContentFormValuesUS(values);
    }
}

export function createAirContentTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    commonTest: TestSamplingWithType,
    airContentTestFormValues: AirContentTestFormValues,
    marketId: string
): AirContentTest {
    const freshConcreteTest: FreshConcreteTest = newFreshConcreteTest(
        companyId,
        projectId,
        orderId,
        concreteLoadId,
        commonTest
    );

    switch (marketId) {
        case MARKETS.DE:
            return createAirContentTestDE(freshConcreteTest, airContentTestFormValues as AirContentTestFormValuesDE);
        default:
            return createAirContentTestUS(freshConcreteTest, airContentTestFormValues as AirContentTestFormValuesUS);
    }
}