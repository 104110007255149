import { Rule } from 'antd/lib/form';
import { t } from '@lingui/macro';
import { NamePath } from 'antd/lib/form/interface';

interface RequieredWithDependenciesRuleParams {
    disable?: boolean;
    message?: string;
    dependencies: NamePath[];
}

function requieredWithDependenciesRule({ disable, dependencies, message }: RequieredWithDependenciesRuleParams): Rule {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (disable) {
                return Promise.resolve();
            }

            const hasSomeDependencyWithValue = dependencies.some((fieldName) => {
                const dependencyValue = getFieldValue(fieldName);
                return dependencyValue != null && dependencyValue !== '';
            });

            if ((value == null || value === '') && hasSomeDependencyWithValue) {
                return Promise.reject(new Error(message || t`Required`));
            }

            return Promise.resolve();
        }
    });
}

export default requieredWithDependenciesRule;
