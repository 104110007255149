import { t } from "@lingui/macro";
import { Input } from "antd";
import InputFormItem, { InputFormItemProps } from "src/app-react/components/Form/FormItem/InputFormItem";

export function WaterCementRatioFormItem(props: InputFormItemProps): JSX.Element {
    return (
        <InputFormItem required={false} name="waterCementRatio" label={t`Water/cement ratio`} {...props}>
            <Input data-testid="waterCementRatio" placeholder={t`Enter the water/cement ratio`} />
        </InputFormItem>
    );
}
