import { EditOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Button } from 'antd';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';

interface Props {
    onEditTimestamp: () => void;
    canEdit: boolean;
}

export const deliveryDetailsTableTimestampsColumnsAUS = ({ onEditTimestamp, canEdit }: Props) => {
    return [
        {
            title: <Trans>Delivery date and time</Trans>,
            key: 'dateTime',
            dataIndex: 'dateTime',
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <DateTimeFormatter date={record.concreteOrder?.deliveryTime} />
            )
        },

        {
            title: <Trans>Loading time</Trans>,
            key: 'loadingTime',
            dataIndex: 'loadingTime',
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <TimeFormatter date={record.supplierConcreteLoadData?.loadingStart} />
            )
        },

        {
            title: <Trans>Arrival at site</Trans>,
            key: 'arrivalTime',
            dataIndex: 'arrivalTime',
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <TimeFormatter date={record.concreteLoad?.arrivalAtConstructionSite} />
            )
        },

        {
            title: <Trans>Unloading start</Trans>,
            key: 'unloadingStart',
            dataIndex: 'unloadingStart',
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <TimeFormatter date={record.concreteLoad?.unloadingStart} />
            )
        },

        {
            title: <Trans>Unloading end</Trans>,
            key: 'unloadingEnd',
            dataIndex: 'unloadingEnd',
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <TimeFormatter date={record.concreteLoad?.unloadingEnd} />
            )
        },

        {
            title: <Trans>Accepted</Trans>,
            key: 'accepted',
            dataIndex: 'accepted',
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <TimeFormatter date={record.concreteLoad?.signedOn} />
            )
        },

        {
            title: <Trans>Actions</Trans>,
            key: 'actions',
            dataIndex: 'actions',
            hidden: !canEdit,
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <Button
                    disabled={record.concreteLoad?.arrivalAtConstructionSite === undefined}
                    data-testid="editButton"
                    type="link"
                    size="small"
                    onClick={() => onEditTimestamp()}>
                    <EditOutlined /> <Trans>Edit</Trans>
                </Button>
            )
        }
    ].filter((item) => !item.hidden);
};
