import Fraction from 'fraction.js';

/* tslint:disable */
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import {
  PostTreatmentTypes,
  TypeOfMeasurement,
  TypeOfMeasurementUnit
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import React from 'react';
import { MARKETS } from '../constants/markets';

// TODO NB-3733: remove any when migrate from angular to react: https://nexplore.atlassian.net/browse/NB-3733
const dateTimeFormat: any = { dateStyle: 'medium', timeStyle: 'medium' };
const timeFormat: any = { timeStyle: 'short' };
const dateFormat: any = { dateStyle: 'medium' };

type NumberFormatterValue = number | string | undefined | null;

export function dateTimeFormatter(date: Date | string | null | undefined, format?: any): string | null {
    const useFormat = format || dateTimeFormat;
    return date ? i18n.date(date, useFormat) : null;
}

export function dateFormatter(date: Date | string | null | undefined, format?: any): string | null {
    const useFormat = format || dateFormat;
    return date ? i18n.date(date, useFormat) : null;
}
export function timeFormatter(date: Date | string | null | undefined, format?: any): string | null {
    const useFormat = format || timeFormat;
    return date ? i18n.date(date, useFormat) : null;
}

export function removeIdentifierFromFormFieldName(elementKey: string): string {
    return elementKey.slice(0, -2);
}

export const weatherMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    sunny: <Trans>Sunny</Trans>,
    cloudy: <Trans>Cloudy</Trans>,
    rainy: <Trans>Rainy</Trans>,
    heavyRain: <Trans>Heavy Rain</Trans>,
    windy: <Trans>Windy</Trans>,
    snow: <Trans>Snow</Trans>,
    foggy: <Trans>Foggy</Trans>
};

export const postTreatmentTypeMapper: { [key in PostTreatmentTypes]: React.ReactElement<any, any> | undefined } = {
    keptInMoulds: <Trans>Mould</Trans>,
    applicationOfImperviousMaterial: <Trans>PVC</Trans>,
    applicationOfWettedBurlap: <Trans>Burlap</Trans>,
    waterCuring: <Trans>Water</Trans>,
    applicationOfCuringCompound: <Trans>Resin</Trans>,
    heatInsulationPads: <Trans>Heated pads</Trans>,
    other: <Trans>other</Trans>
};

export const daysTranslationSelector = (days: number): React.ReactElement<any, any> => {
    if (days) {
        return (
            <p>
                {days} {days > 1 ? <Trans>days</Trans> : <Trans>day</Trans>}
            </p>
        );
    }

    return null;
};

export const strucutralElementName = () => <Trans />;

export function unitFormatter(unit: string): string {
    return mapUnit(unit);
}

export function numberFormatter(
    originalValue: NumberFormatterValue,
    unit?: string,
    defaultValue?: string,
    format?: Intl.NumberFormatOptions
): string | null | undefined {
    const value = typeof originalValue === 'string' ? Number(originalValue) : originalValue;

    if (typeof value !== 'number' || isNaN(value)) {
        return (originalValue as string) || defaultValue;
    }

    return unit ? `${i18n.number(value, format)} ${mapUnit(unit)}` : i18n.number(value, format);
}

export function strengthDevelopmentFormatter(value: NumberFormatterValue) {
    if (value == null) {
        return '-';
    }

    return `r ≤ ${numberFormatter(value)}`;
}

export const concreteInformationCategoryMapper: { [key: string]: React.ReactElement<any, any> } = {
    concreteTypeNumber: <Trans>Concrete Type Number</Trans>,
    mixDesignNumber: <Trans>Mix design number</Trans>,
    comprehensiveStrengthClass: <Trans>Compressive Strength Class</Trans>,
    strengthDevelopment: <Trans>Strength Development</Trans>,
    exposureClass: <Trans>Exposure Class(es)</Trans>,
    methodOfConcrete: <Trans>Method of concrete / Chloride content</Trans>,
    consistencyClass: <Trans>Consistency Class</Trans>,
    waterCementRatio: <Trans>Water/cement ratio (eq)</Trans>,
    cementTypes: <Trans>Cement type and origin</Trans>,
    additivesFluid: <Trans>Admixtures</Trans>,
    additivesSolid: <Trans>Supplementary Cementitious Materials</Trans>,
    AggregateSize: <Trans>Maximum Aggregate size/grain size</Trans>,
    aggregateSize: <Trans>Maximum Aggregate size/grain size</Trans>,
    densityClass: <Trans>Density Class</Trans>,
    moistureClass: <Trans>Moisture Class</Trans>,
    FiverType: <Trans>Fiber type and quantity</Trans>,
    fiverType: <Trans>Fiber type and quantity</Trans>,
    SpecialProperties: <Trans>Special properties</Trans>,
    specialProperties: <Trans>Special properties</Trans>,
    slump: <Trans>Slump</Trans>,
    compressiveStrength: <Trans>Compressive strength</Trans>,
    maxWaterToAdd: <Trans>Max water to add</Trans>
};

export const commentActionMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    reportArrival: <Trans>Arrival</Trans>,
    startUnloading: <Trans>Unloading start</Trans>,
    endUnloading: <Trans>Unloading end</Trans>,
    acceptAndSign: <Trans>Accepted and signed</Trans>,
    rejectConcreteLoad: <Trans>Concrete load rejection</Trans>
};

export const cancelReasonCodeMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    siteNotReady: <Trans>Site not ready</Trans>,
    permitsNotAvailable: <Trans>Permits not available</Trans>,
    weather: <Trans>Weather conditions</Trans>,
    changeInSchedule: <Trans>Change in schedule</Trans>,
    incorrectOrder: <Trans>Incorrect order</Trans>,
    cancelledBySupplier: <Trans>Cancelled by supplier</Trans>,
    other: <Trans>Other</Trans>
};

export const postTreatmentMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    keptInMoulds: <Trans>Kept in moulds (Mould)</Trans>,
    applicationOfImperviousMaterial: <Trans>Application of impervious material (PVC)/plastic sheeting</Trans>,
    applicationOfWettedBurlap: <Trans>Application of wetted burlap (Burlap)</Trans>,
    waterCuring: <Trans>Water curing (Water)</Trans>,
    applicationOfCuringCompound: <Trans>Application of curing compound (Resin)</Trans>,
    heatInsulationPads: <Trans>Heat insulation pads</Trans>,
    other: <Trans>Other</Trans>
};

export const temperatureMeasurementMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    surface: <Trans>Surface temperature</Trans>,
    air: <Trans>Air temperature</Trans>,
    freshConcrete: <Trans>Fresh concrete temperature</Trans>,
    intermediateFreshConcreteAndSurface: <Trans>Intermediate between fresh concrete and surface temperature</Trans>,
    intermediateSurfaceAndAir: <Trans>Intermediate between surface and air temperature</Trans>,
    differenceFreshConcreteAndSurface: <Trans>Difference between fresh concrete and surface temperature</Trans>,
    differenceSurfaceAndAir: <Trans>Difference between surface and air temperature</Trans>,
    differenceInternalConcreteAndSurface: <Trans>Difference between internal concrete and surface concrete</Trans>
};

export const compressiveMeasurementMapper: { [key: string]: React.ReactElement<any, any> | undefined } = {
    testSpecimen: <Trans>Test Specimen</Trans>,
    maturity: <Trans>Maturity</Trans>,
    reboundHammer: <Trans>Rebound hammer</Trans>,
    pendulumHammer: <Trans>Pendulum hammer</Trans>,
    drillingCore: <Trans>Drilling core</Trans>,
    other: <Trans>Other</Trans>
};

export function mapCompressiveMeasurementValueUnit(
    compressiveType: TypeOfMeasurement,
    marketId: string
): TypeOfMeasurementUnit {
    switch (compressiveType) {
        case 'maturity':
            return marketId === MARKETS.DE || marketId === MARKETS.AUS ? '°C h' : '°F h';
        case 'reboundHammer':
        case 'pendulumHammer':
            return 'Div';
        case 'drillingCore':
        default:
            return 'N';
    }
}

export function mapUnit(unit: string): string {
    switch (unit) {
        case 'C':
            return '°C';
        case 'inches':
            return 'in';
        case 'm3':
            return 'm³';
        case 'YD3':
        case 'yd3':
            return 'yd³';
        case 'mh3':
        case 'm3h':
            return 'm³/h';
        case 'kgm2hr':
            return 'kg/m²/hr';
        default:
            return unit;
    }
}

export function numberFormat(number: number | undefined): string | null {
    return number || number === 0 ? i18n.number(number) : null;
}

export function formatNumberValueToLanguage(value: string | number): string {
    return !isNaN(Number(value)) ? `${numberFormat(Number(value))}` : `${value}`;
}

export function parseDecimalByLocale(decimalString: string, localeString: string): number {
    if (localeString === 'de-DE') {
        return Number(decimalString.replace(',', '.'));
    }
    return Number(decimalString);
}

export function formatDecimalByLocale(value: string | number | undefined, localeString: string): string | undefined {
    if (typeof value === 'number') {
        return value.toLocaleString(localeString || 'en-GB');
    }

    if (typeof value === 'string') {
        if (localeString === 'de-DE') {
            return value.replace('.', ',');
        }
        return value;
    }

    return undefined;
}

export function getSwitchValue(switchValue: boolean | undefined): boolean | undefined {
    if (switchValue !== undefined) {
        if (switchValue) {
            return true;
        }
        return false;
    }
    return undefined;
}

export function exposureClassesFormatter({
    x0,
    xc,
    xd,
    xfFirst,
    xfSecond,
    xa,
    xm,
    xs
}: {
    x0: boolean;
    xc: number;
    xd: number;
    xfFirst: number;
    xfSecond: number;
    xa: number;
    xm: number;
    xs: number;
}): string {
    return [
        x0 && `X0`,
        xc && `XC ${xc}`,
        xd && `XD ${xd}`,
        xfFirst && !xfSecond && `XF ${xfFirst}`,
        xfFirst && xfSecond && `XF ${xfFirst}/${xfSecond}`,
        !xfFirst && xfSecond && `XF ${xfSecond}`,
        xa && `XA ${xa}`,
        xm && `XM ${xm}`,
        xs && `XS ${xs}`
    ]
        .filter(Boolean)
        .join(', ');
}

export function formatDecimalAsFraction(decimal: number): string {
    const excludeWhole = true; // e.g. "1 1/5" will be visible instead of "6/5"
    return new Fraction(Number(decimal)).toFraction(excludeWhole);
}
