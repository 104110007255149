import { WarningTwoTone } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { DeliveryConcreteInformation } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Table } from 'antd';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import { formatConcreteInformation } from 'src/app-react/business-page/delivery-details/util';
import { MARKET_VALUES } from 'src/app-react/constants/markets';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import styles from '../../../shared/shared.module.css';
import { concreteInformationDataTableColumns } from './columns';

export interface Props {
    deliveryNoteAllDetails: DeliveryNoteAllDetails;
}

function ConcreteInformationTableDE({ deliveryNoteAllDetails }: Props) {
    const { units } = useProjectConstantsState();
    const { marketId } = useProjectState();

    const concreteInformation = formatConcreteInformation(
        deliveryNoteAllDetails.deliveryDetails,
        marketId as MARKET_VALUES
    );

    function keyMapFromTableData(tableData: DeliveryConcreteInformation[]): DeliveryConcreteInformation[] {
        return tableData.map((value: DeliveryConcreteInformation, key: number) => {
            if (value.category === 'AggregateSize') {
                return {
                    category: 'AggregateSize',
                    orderValue:
                        value.orderValue && value.orderValue !== 'undefined'
                            ? `${value.orderValue} ${units.maxAggregateSize}`
                            : '',
                    deliveryValue:
                        value.deliveryValue && value.deliveryValue !== 'undefined'
                            ? `${value.deliveryValue} ${units.maxAggregateSize}`
                            : '',
                    hasDeviation: false
                };
            }

            if (value.category === 'maxWaterToAdd') {
                return {
                    category: 'maxWaterToAdd',
                    orderValue:
                        value.orderValue && value.orderValue !== 'undefined'
                            ? `${value.orderValue} ${units.water}`
                            : '',
                    deliveryValue:
                        value.deliveryValue && value.deliveryValue !== 'undefined'
                            ? `${value.deliveryValue} ${units.water}`
                            : '',
                    hasDeviation: false
                };
            }
            return { ...value, key };
        });
    }

    function getNumberDeviations(): number {
        if (!concreteInformation) {
            return 0;
        }
        const deliveryConcreteInformations: DeliveryConcreteInformation[] = concreteInformation.filter(
            (dci: DeliveryConcreteInformation) => dci.hasDeviation
        );
        return deliveryConcreteInformations.length;
    }

    function getRowClass(nameCategory: string): string {
        const deliveryConcreteInformation: DeliveryConcreteInformation | undefined = concreteInformation.find(
            (d: DeliveryConcreteInformation) => d.category === nameCategory
        );

        if (deliveryConcreteInformation) {
            const rowClass: string = deliveryConcreteInformation.hasDeviation ? styles.tableRowDanger : styles.tableRow;

            return rowClass;
        }

        return styles.tableRow;
    }

    return (
        <div className="antd-table">
            {getNumberDeviations() > 0 ? (
                <p data-testid="numberDeviations">
                    <span className={styles.tableTitle}>
                        <Trans>Concrete information</Trans>
                    </span>
                    <WarningTwoTone twoToneColor="#eb2f96" /> <Trans>Attention! Deviations in </Trans>{' '}
                    <b style={{ color: '#dc3545' }}>{getNumberDeviations()}</b> <Trans>categories</Trans>
                </p>
            ) : (
                <p data-testid="withoutDeviations">
                    <span className={styles.tableTitle}>
                        <Trans>Concrete information</Trans>
                    </span>
                </p>
            )}
            <Table
                data-testid="concrete-load-table"
                scroll={{ x: 10 }}
                columns={concreteInformationDataTableColumns()}
                dataSource={keyMapFromTableData(concreteInformation)}
                pagination={false}
                rowClassName={(record) => {
                    return getRowClass(record.category);
                }}
            />
        </div>
    );
}

export default ConcreteInformationTableDE;
