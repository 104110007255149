import { t, Trans } from '@lingui/macro';
import { Button, Modal, Table } from 'antd';
import { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKET_VALUES, MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getProjectConstants } from 'src/app-react/services/projectService';
import SpecimenTestModal from '../SpecimenTestModal/SpecimenTestModal';
import { testSpecimenTableColumns } from './TestSpecimenTable.columns';
import { createContextData } from './util';
import {
    TestSpecimenBP,
    TestSpecimenBPDE
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto';

export interface TestSpecimenTableRecord {
    testSpecimenIdentifier: string;
    samplingTime: Date;
    testingAge: number;
    testType: string;
    testPurpose: string;
    externalInspection: boolean;
    testSpecimen: TestSpecimenBP;
}

export interface ITestSpecimenTableProps {
    orderId: string;
    concreteLoadId: string;
    refreshTable?: boolean;
    setRefreshTable?: (value: boolean) => void;
    setTestSpecimenCount?: (count: number) => void;
    records?: TestSpecimenBP[];
}

function TestSpecimenTable(props: ITestSpecimenTableProps) {
    const { orderId, concreteLoadId, refreshTable, setRefreshTable, setTestSpecimenCount, records } = props;

    const { projectId, marketId, companyId, supplierId } = useProjectState();
    const { testSpecimenTestType, testSpecimenTestPurpose } = getProjectConstants({ marketId, supplierId });
    const [tableData, setTableData] = useState<TestSpecimenTableRecord[]>();
    const [isSpecimenTestModalVisible, setIsSpecimenTestModalVisible] = useState(false);
    const [isPopupConfirmModalVisible, setIsPopupConfirmModalVisible] = useState(false);

    const api = useApi();

    const [contextData, setContextData] = useState<any>();
    const [popupConfirmModalData, setPopupConfirmModalData] = useState<any>();

    async function reloadTableData(records?: TestSpecimenBP[]): Promise<void> {
        function mapTestSpecimens(testSpecimens: TestSpecimenBP[]): TestSpecimenTableRecord[] {
            function testType(testSpecimen: TestSpecimenBP) {
                return (
                    (testSpecimenTestType.find((t) => t.value === (testSpecimen as TestSpecimenBPDE).testType)
                        ?.label as string) ?? ''
                );
            }

            function testPurpose(testSpecimen: TestSpecimenBP): string {
                return (
                    (testSpecimenTestPurpose.find((t) => t.value === (testSpecimen as TestSpecimenBPDE).testPurpose)
                        ?.label as string) ?? ''
                );
            }

            function testExternalInspection(testSpecimen: TestSpecimenBP): boolean {
                if (marketId === MARKETS.DE) {
                    return (testSpecimen as TestSpecimenBPDE).externalInspection;
                }
                return false;
            }

            return testSpecimens.map((testSpecimen) => ({
                id: testSpecimen.id,
                testSpecimenIdentifier: testSpecimen.testSpecimenIdentifier,
                samplingTime: testSpecimen.samplingTime,
                testingAge: testSpecimen.testingAge,
                testType: testType(testSpecimen),
                testPurpose: testPurpose(testSpecimen),
                externalInspection: testExternalInspection(testSpecimen),
                testSpecimen
            }));
        }

        if (records?.length) {
            setTableData(mapTestSpecimens(records));
            setTestSpecimenCount?.(records.length);
            return;
        }

        api.testSpecimenClient.loadTestSpecimenList(projectId, orderId, concreteLoadId, companyId).then((response) => {
            if (response.isSuccess()) {
                const records: TestSpecimenTableRecord[] = mapTestSpecimens(response.getEntity());
                setTableData(records);
                setTestSpecimenCount?.(records.length);
            } else {
                ErrorNotification({
                    message: response.getError(),
                    description: ''
                });
            }
        });
    }

    useEffect(() => {
        // first load
        reloadTableData(records);

        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            if (!isPopupConfirmModalVisible && !isSpecimenTestModalVisible) {
                reloadTableData();
            }
        }, 20000);

        return () => clearInterval(interval);
    }, [isPopupConfirmModalVisible, isSpecimenTestModalVisible]);

    useEffect(() => {
        if (refreshTable) {
            reloadTableData();
            setRefreshTable?.(false);
        }
    }, [refreshTable]);

    useEffect(() => {
        if (contextData) {
            setIsSpecimenTestModalVisible(true);
        }
    }, [contextData]);

    const onEdit = (record: TestSpecimenTableRecord): void => {
        setContextData(createContextData(record.testSpecimen as TestSpecimenBP, marketId as MARKET_VALUES));
    };

    const onDelete = (record: TestSpecimenTableRecord): void => {
        setPopupConfirmModalData(record);
        setIsPopupConfirmModalVisible(true);
    };

    const onDetails = (record: TestSpecimenTableRecord): void => {
        // to be done in the future
    };

    const popupConfirmModal_onOK = async (): Promise<void> => {
        setIsPopupConfirmModalVisible(false);
        api.testSpecimenClient
            .deleteTestSpecimen(projectId, orderId, concreteLoadId, popupConfirmModalData.id, companyId)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Specimen test deleted successfully`,
                        description: ''
                    });
                    reloadTableData();
                } else {
                    ErrorNotification({
                        message: t`Specimen test could not be deleted`,
                        description: ''
                    });
                }
            })

            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    const popupConfirmModal_onCancel = (): void => {
        setIsPopupConfirmModalVisible(false);
    };

    return (
        <>
            <Table
                data-testid="TestSpecimenTable"
                showSorterTooltip={false}
                scroll={{ x: 1000 }}
                columns={testSpecimenTableColumns({ onEdit, onDelete, onDetails, marketId })}
                rowKey="id"
                dataSource={tableData}
                rowClassName="TestSpecimenTable-testid"
            />

            {isSpecimenTestModalVisible && (
                <SpecimenTestModal
                    specimenTestId={contextData.id}
                    orderId={contextData.orderId}
                    concreteLoadId={contextData.concreteLoadId}
                    reloadTestSpecimens={reloadTableData}
                    open={isSpecimenTestModalVisible}
                    setOpen={setIsSpecimenTestModalVisible}
                    initialFormValues={{
                        specimenIdentification: contextData?.testSpecimenIdentifier,
                        typeOfTesting: contextData?.testType,
                        purposeOfTesting: contextData?.testPurpose,
                        mould: contextData?.mould,
                        testingAge: contextData?.testingAge,
                        compactor: contextData?.compactor,
                        compactionTime: contextData?.compactionTime?.quantity,
                        externalInspection: contextData?.externalInspection,
                        samplingDate: contextData?.samplingTime, // use samplingTime for testingDate
                        samplingTime: contextData?.samplingTime,
                        samplingLocation: contextData?.samplingLocation
                    }}
                />
            )}

            <Modal
                maskClosable={false}
                title={t`Delete test`}
                open={isPopupConfirmModalVisible}
                width={400}
                onOk={popupConfirmModal_onOK}
                onCancel={popupConfirmModal_onCancel}
                footer={[
                    <Button
                        data-testid="PopupConfirmModal.cancelButton"
                        key="cancelButton"
                        onClick={popupConfirmModal_onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>,
                    <Button
                        data-testid="PopupConfirmModal.okButton"
                        key="okButton"
                        onClick={popupConfirmModal_onOK}
                        type="primary">
                        <Trans>OK</Trans>
                    </Button>
                ]}>
                <Trans>Are you sure with deleting this test?</Trans>
            </Modal>
        </>
    );
}

export default TestSpecimenTable;
