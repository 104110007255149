import { t, Trans } from '@lingui/macro';
import { Badge, Breadcrumb, Tabs } from 'antd';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ProjectBreadcrumb from 'src/app-react/components/Breadcrumb/ProjectBreadcrumb';
import useProjectState from 'src/app-react/hooks/useProjectState';
import OrderConcreteTypesTable from '../OrderConcreteTypesTable/OrderConcreteTypesTable';
import OrderStructuralElementsTable from '../OrderStructuralElementsTable/OrderStructuralElementsTable';

const OrderCreationTableTabs = (props: { orderId?: string }) => {
    const { orderId } = props;
    const [structuralElementsCount, setStructuralElementsCount] = useState(0);
    const [concreteTypeCount, setConcreteTypeCount] = useState(0);
    const navigate: NavigateFunction = useNavigate();
    const { projectId } = useProjectState();

    const items = [
        {
            label: (
                <>
                    <Trans>Structural elements</Trans> <Badge count={structuralElementsCount} />
                </>
            ),
            key: 'structuralElements',
            children: (
                <OrderStructuralElementsTable
                    expandable={true}
                    count={structuralElementsCount}
                    setCount={setStructuralElementsCount}
                    orderId={orderId}
                />
            ),
            forceRender: true
        },
        {
            label: (
                <>
                    <Trans>Concrete type</Trans> <Badge count={concreteTypeCount} />
                </>
            ),
            key: 'concreteTypes',
            children: (
                <OrderConcreteTypesTable
                    expandable={true}
                    count={concreteTypeCount}
                    setCount={setConcreteTypeCount}
                    orderId={orderId}
                />
            ),
            forceRender: true
        }
    ];

    return (
        <>
            <AppHeader
                title={orderId ? t`Update order` : t`New order`}
                onBack={() => navigate(`/projects/${projectId}/orders`)}
                breadcrumb={
                    <ProjectBreadcrumb>
                        <Breadcrumb.Item>
                            <Trans>Order monitoring</Trans>
                        </Breadcrumb.Item>
                    </ProjectBreadcrumb>
                }
            />

            <Tabs defaultActiveKey="structuralElements" items={items} />
        </>
    );
};

export default OrderCreationTableTabs;
