import { t, Trans } from '@lingui/macro';
import { Button, Form, Modal } from 'antd';
import { useEffect } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcreteTestingTypes } from '../../utils/concrete-tests-service';
import { MARKET_VALUES } from 'src/app-react/constants/markets';
import useConsistencyTest from '../MultipleConcreteTestModal/components/ConsistencyTestForm/hooks/useConsistencyTest';
import {
    createConsistencyTest,
    createInitialValues,
    getConsistencyFormValues
} from '../MultipleConcreteTestModal/components/ConsistencyTestForm/util';
import {
    createInitialCommonValues,
    getFormCommonValues
} from '../MultipleConcreteTestModal/components/CommonTestForm/util';
import ConsistencyTestForm from '../MultipleConcreteTestModal/components/ConsistencyTestForm/ConsistencyTestForm';
import CommonTestForm from '../MultipleConcreteTestModal/components/CommonTestForm/CommonTestForm';
import { ConsistencyTestFormValues } from '../MultipleConcreteTestModal/components/ConsistencyTestForm/models';
import { CommonTestFormValues } from '../MultipleConcreteTestModal/components/CommonTestForm/models';

export interface IConsistencyTestModalFormProps {
    orderId?: string;
    concreteLoadId?: string;
    consistencyTestId?: string;
    open: boolean;
    setOpen: (isVisible: boolean) => void;
    initialFormValues?: Partial<ConsistencyTestFormValues & CommonTestFormValues>;
    reloadFreshConcreteTests?: () => Promise<void>;
    onOk?: () => void;
    onCancel?: () => void;
}

function ConsistencyTestModal(props: IConsistencyTestModalFormProps) {
    const {
        orderId,
        concreteLoadId,
        consistencyTestId,
        open,
        setOpen,
        initialFormValues,
        reloadFreshConcreteTests,
        onOk,
        onCancel
    } = props;

    const [form] = Form.useForm();
    const { companyId, projectId, marketId } = useProjectState();

    const consistencyTestMutate = useConsistencyTest();

    let enabledButton = true;

    async function onSubmit(): Promise<void> {
        if (!enabledButton) return;
        try {
            enabledButton = false;

            if (!(await form.validateFields())) return;

            const consistencyTestFormValues = getConsistencyFormValues(form, marketId as MARKET_VALUES);
            const commonTestFormValues = { ...getFormCommonValues(form), testType: ConcreteTestingTypes.Consistency };

            const consistencyTest = createConsistencyTest(
                companyId,
                projectId,
                orderId,
                concreteLoadId,
                commonTestFormValues,
                consistencyTestFormValues,
                marketId
            );

            if (!consistencyTestId) {
                const { success, error } = await consistencyTestMutate(consistencyTest);

                if (error || !success) {
                    ErrorNotification({
                        message: 'Consistency test could not be created',
                        description: error ?? t`Unknown error`
                    });
                } else {
                    SuccessNotification({
                        message: t`Consistency test created successfully`,
                        description: ''
                    });
                    reloadFreshConcreteTests?.();
                    setOpen(false);
                    onOk?.();
                }
            } else {
                const { success, error } = await consistencyTestMutate(consistencyTest, consistencyTestId);

                if (error || !success) {
                    ErrorNotification({
                        message: 'Consistency test could not be updated',
                        description: error ?? t`Unknown error`
                    });
                } else {
                    SuccessNotification({
                        message: t`Consistency test updated successfully`,
                        description: ''
                    });
                    reloadFreshConcreteTests?.();
                    setOpen(false);
                    onOk?.();
                }
            }
            enabledButton = true;
        } catch (err) {}
    }

    function formOnCancel(): void {
        setOpen(false);
        onCancel?.();
    }

    useEffect(() => {
        form.setFieldsValue(createInitialCommonValues(initialFormValues, marketId as MARKET_VALUES));
        form.setFieldsValue(createInitialValues(initialFormValues, marketId as MARKET_VALUES)); // we need to manually set it initialValues does not work even with destroyOnClose and preserve on each reopen - see https://github.com/ant-design/ant-design/issues/22171
    }, [initialFormValues, form, marketId]);

    return (
        <Modal
            maskClosable={false}
            title={t`Edit consistency test`}
            open={open}
            width={500}
            onCancel={formOnCancel}
            footer={[
                <Button data-testid="ConsistencyTestModal.cancelButton" key="cancelButton" onClick={formOnCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    data-testid="ConsistencyTestModal.okButton"
                    key="okButton"
                    onClick={onSubmit}
                    disabled={!enabledButton}
                    type="primary">
                    <Trans>Save</Trans>
                </Button>
            ]}>
            <Form form={form} layout="vertical">
                <CommonTestForm requestTestType={false} />
                <ConsistencyTestForm showTitle={false} />
            </Form>
        </Modal>
    );
}

export default ConsistencyTestModal;
