/* eslint-disable react/require-default-props */

import { Divider, Table } from 'antd';
import supplierInformationTableColumns from './supplierInformationTableColumns';
import { Trans } from '@lingui/macro';
import styles from '../shared/shared.module.css';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';

export interface SupplierInformationTableProps {
    deliveryDetails?: DeliveryNoteFullDetails;
}

function SupplierInformationTable({ deliveryDetails }: SupplierInformationTableProps) {
    return (
        deliveryDetails && (
            <>
                <p className={styles.tableTitle}>
                    <Trans>Supplier information</Trans>
                </p>
                <Table
                    bordered={false}
                    data-testid="supplier-information-table"
                    scroll={{ x: 1 }}
                    columns={supplierInformationTableColumns()}
                    dataSource={[deliveryDetails]}
                    pagination={false}
                />
                <Divider />
            </>
        )
    );
}

export default SupplierInformationTable;
