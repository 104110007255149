import { PlusSquareOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { PostTreatment } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Button, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { PostTreatmentDeleteModal } from '../PostTreatmentDeleteModal';
import { PostTreatmentEditModal } from '../PostTreatmentEditModal';
import { postTreatmentTableColumns } from './columnsTable';

interface PostTreatmentTableProps {
    loadTable: boolean;
    triggerReload: () => void;
}

export function PostTreatmentTable(props: PostTreatmentTableProps) {
    const [postTreatments, setPostTreatments] = useState([] as PostTreatment[]);
    const [postTreatmentForUpdate, setPostTreatmentForUpdate] = useState({} as PostTreatment);
    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId } = useProjectState();
    const { loadTable, triggerReload } = props;
    const api = useApi();

    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);

    async function loadPostTreatment() {
        api.concretingDocumentationClient
            .loadStructuralElementPostTreatments(companyId, projectId, structuralElementId)
            .then((response) => {
                if (response.isSuccess()) {
                    const result: PostTreatment[] = response.getEntity();
                    setPostTreatments(result);
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function onCreate() {
        setPostTreatmentForUpdate(null);
        setEditModalVisible(true);
    }

    function onEdit(postTreatment: PostTreatment) {
        setPostTreatmentForUpdate(postTreatment);
        setEditModalVisible(true);
    }

    function onDelete(postTreatmentId: string) {
        PostTreatmentDeleteModal({
            companyId,
            projectId,
            structuralElementId,
            postTreatmentId,
            triggerReload,
            concretingDocumentationClient: api.concretingDocumentationClient
        });
    }

    useEffect(() => {
        if (loadTable) {
            loadPostTreatment();
        }
    }, [loadTable, companyId, projectId]);

    return (
        <>
            <Table
                style={{ marginTop: '1.5rem' }}
                data-testid="postTreatmentTable"
                rowClassName="postTreatmentRow"
                rowKey="id"
                columns={postTreatmentTableColumns(onEdit, onDelete)}
                dataSource={postTreatments}
                loading={loadTable}
                pagination={false}
            />

            <Button
                style={{ width: '100%', height: '3.5rem', color: '#024A94', marginTop: '.5rem' }}
                onClick={onCreate}>
                <PlusSquareOutlined /> <Trans>Record post-treatment</Trans>
            </Button>

            <PostTreatmentEditModal
                postTreatment={postTreatmentForUpdate}
                open={editModalVisible}
                setOpen={setEditModalVisible}
                triggerReload={triggerReload}
            />
        </>
    );
}
