import { ExclamationCircleOutlined } from '@ant-design/icons';
import { t } from '@lingui/macro';
import { Modal } from 'antd';
import React, { useContext, useRef } from 'react';

interface DiscardChangesContextValues {
    displayDiscardChangeModal: (props: DiscardChangesModalProps) => void;
    unsavedChanges: React.MutableRefObject<boolean>;
}

export interface DiscardChangesModalProps {
    onConfirm: () => void;
    onCancel?: () => void;
}

const DiscardChangesContext = React.createContext<DiscardChangesContextValues>({} as DiscardChangesContextValues);

function useDiscardChanges() {
    return useContext(DiscardChangesContext);
};

const DiscardChangeProvider = ({ children }: {children : React.ReactNode}) => { 

    const unsavedChanges = useRef(false);

    function displayDiscardChangeModal(props: DiscardChangesModalProps) {
        if(unsavedChanges.current) {
            showModal({
                onConfirm: () => {
                    unsavedChanges.current = false;
                    props.onConfirm();
                },
                onCancel: props.onCancel
            });
        } 
        else {
            unsavedChanges.current = false;
            props.onConfirm();
        }
    }

    function showModal(props: DiscardChangesModalProps) {
        const { onConfirm, onCancel } = props;
    
        return (
            Modal.warning({
                title: t`Do you want to discard your changes?`,
                width: 600,
                icon: <ExclamationCircleOutlined />,
                content: t`Your are about to leave without saving the last changes you made.`,
                okText: t`Discard changes`,
                okType: 'primary',
                okButtonProps: {className: 'DiscardChangesModal'},
                okCancel: true,
                cancelText: t`Close`,
                onOk() {
                    onConfirm();
                },
                onCancel() {
                    onCancel?.();
                }
            })
        )
    }

    return <DiscardChangesContext.Provider value={{displayDiscardChangeModal: displayDiscardChangeModal, unsavedChanges: unsavedChanges}}>
        {children}
        </DiscardChangesContext.Provider>;
}

export { DiscardChangeProvider, useDiscardChanges };
