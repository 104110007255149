import { t, Trans } from '@lingui/macro';
import { DatePicker, Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function dateValidator(rule: RuleObject, date: moment.Moment) {
    if (date && date.isSameOrAfter(moment(), 'days')) {
        return Promise.resolve();
    }

    return Promise.reject(rule.message as string);
}

function disabledDateHandler(current: moment.Moment | null): boolean {
    if (!current) {
        return false;
    }

    return current.isBefore(moment(), 'day');
}

export function DateOfConcretingFormItem() {
    const { formats } = useProjectConstantsState();

    return (
        <Form.Item
            name="dateOfConcreting"
            label={<Trans>Date of concreting</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                },
                {
                    message: <Trans>Day of date or concreting should be bigger than now</Trans>,
                    validator: dateValidator
                }
            ]}>
            <DatePicker
                data-testid="dateOfConcreting"
                value={moment()}
                placeholder={t`Enter date of concrete`}
                disabledDate={disabledDateHandler}
                format={formats.dateForm}
            />
        </Form.Item>
    );
}
