import { FormItemProps } from 'antd';
import requiredRule from '../Validations/requiredRule';

function parseRules(required: boolean, props: FormItemProps) {
    const customRequiredRule = (props.rules || []).find((rule) => 'required' in rule);

    if (required == null || customRequiredRule) {
        return props.rules;
    }

    return [{ ...requiredRule, required }, ...(props.rules || [])];
}

export default parseRules;
