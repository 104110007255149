import { t } from '@lingui/macro';
import { Rule } from 'antd/lib/form';
import DateTimePickerFormItem from 'src/app-react/components/Form/FormItem/DateTimePickerFormItem';
import dateTimeWithDependenciesRule from 'src/app-react/components/Form/Validations/dateTimeWithDependenciesRule';
import TIME_STAMP_DEPENDENCIES from '../../../../../constants/timeStampDependencies';

const RULES: Rule[] = [
    dateTimeWithDependenciesRule({
        name: 'loadingStart',
        sameOrAfter: ['unloadingStart', 'unloadingEnd', 'signedOn']
    })
];

function LoadingStartFormItem() {
    return (
        <DateTimePickerFormItem
            datePickerFormItemProps={{
                name: 'loadingStartDate',
                label: t`Load date`
            }}
            timePickerFormItemProps={{
                name: 'loadingStart',
                dependencies: TIME_STAMP_DEPENDENCIES,
                rules: RULES
            }}
        />
    );
}

export default LoadingStartFormItem;
