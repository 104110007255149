import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function CommentFormItem() {
    return (
        <FormItem
            name="comment"
            label={<b><Trans>Comment</Trans></b>}
            style={{gap: '.2rem'}}>
             <Input.TextArea
                data-testid="comment"
                placeholder={t`Enter comment`}
                autoSize={{ minRows: 3, maxRows: 5 }}
            />
        </FormItem>
    );
}
