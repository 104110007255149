import { SupplierConfig } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';

export default function getProjectPermissions({ supplierConfig }: { supplierConfig?: SupplierConfig }) {
    return {
        allowCreateOrderLoadLite:
            !!supplierConfig?.isManualType || !!supplierConfig?.isQrType || !!supplierConfig?.isOcrType,
        allowCreateOrderLoadLiteQR: !!supplierConfig?.isQrType,
        allowCreateOrderLoadLiteOCR: !!supplierConfig?.isOcrType
    };
}
