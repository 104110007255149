/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { Select } from 'antd';
import { t } from '@lingui/macro';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';

function StrengthDevelopmentClassFormItem(props: SelectFormItemProps) {
    const { strengthDevelopmentClass = [] } = useProjectConstantsState();

    return (
        <SelectFormItem required name="strengthDevelopmentClass" label={t`Strength development class`} {...props}>
            <Select
                allowClear={props.required === false}
                placeholder={t`Please select strength development class`}
                options={strengthDevelopmentClass}
                data-testid="strengthDevelopmentClass"
            />
        </SelectFormItem>
    );
}

export default StrengthDevelopmentClassFormItem;
