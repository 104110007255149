import { t, Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import useOrderDependenciesState from 'src/app-react/business-page/order-management/hooks/useOrderDependenciesState';
import { buildDefaultOptionType } from '../../../../../../../../shared/Forms/utils/buildDefaultOptionType';

export function SlumpFormItem() {
    const {
        dependencies: { slumps = [] }
    } = useOrderDependenciesState();

    const options = buildDefaultOptionType(slumps);

    return (
        <Form.Item
            name="slump"
            label={<Trans>Slump</Trans>}
            rules={[
                {
                    pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                    required: true,
                    message: (
                        <span data-testid="errorInputSlump">
                            <Trans>This field is required</Trans>
                        </span>
                    )
                }
            ]}>
            <Select placeholder={t`Please select slump`} options={options} data-testid="slumpInput"></Select>
        </Form.Item>
    );
}
