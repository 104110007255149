import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import TimePicker from 'src/app-react/components/Form/TimePicker';

export function DeliveryTimeFormItem() {
    return (
        <Form.Item
            label={<Trans>Delivery Time</Trans>}
            name="deliveryTime"
            rules={[{
                required: true,
                message: <Trans>This field is required</Trans>
            }]}>
            <TimePicker
                data-testid="deliveryTime"
                placeholder={t`Enter delivery time`}
            />
        </Form.Item>
    );
}
