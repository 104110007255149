import { t, Trans } from '@lingui/macro';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Form, FormInstance, Input, Row, Select } from 'antd';
import { useEffect, useState } from 'react';
import InputFormItem from 'src/app-react/components/Form/FormItem/InputFormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import { MARKET_VALUES, MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ExternalInspectionFormItem } from '../../FormItems/DE/ExternalInspectionFormItem';
import styles from '../../MultipleConcreteTestModal.module.css';
import { MouldFormItem } from './FormItems/MouldFormItem';
import { PurposeOfTestingFormItem } from './FormItems/PurposeOfTestingFormItem';
import { TypeOfTestingFormItem } from './FormItems/TypeOfTestingFormItem';
import { CompactionTimeFormItem } from './markets/DE/FormItems/CompactionTimeFormItem';
import { getSpecimenIdentificationTitle } from './markets/util';

export interface Props {
    showTitle?: boolean;
    form?: FormInstance<any>;
    showErrorTestSpecimAlreadyExists?: boolean;
    setShowErrorTestSpecimAlreadyExists?: React.Dispatch<React.SetStateAction<boolean>>;
}

function TestSpecimenTestForm(props: Props) {
    const { form, showErrorTestSpecimAlreadyExists, setShowErrorTestSpecimAlreadyExists } = props;

    const { marketId, projectId, companyId } = useProjectState();
    const api = useApi();

    const [previousSpecimenTestIdentifier, setPreviousSpecimenTestIdentifier] = useState<string>();

    const { showTitle = true } = props;

    const fetchPreviousSpecimenTest = async () => {
        api.testSpecimenClient
            .getLastTestSpecimenIdentifierForProject(companyId, projectId)
            .then((res: SimpleClientResponse<string | undefined>) => {
                const err = res.getError();
                if (!err) {
                    setPreviousSpecimenTestIdentifier(res.getEntity());
                }
            });
    };

    useEffect(() => {
        fetchPreviousSpecimenTest();
    }, [projectId, companyId]);

    return (
        <>
            {showTitle && (
                <>
                    {' '}
                    <Row>
                        <label className={styles.title}>
                            <Trans>Test specimen</Trans>
                        </label>
                    </Row>
                    <br />
                </>
            )}

            <InputFormItem
                forbiddenStrings={['%', '_']}
                name="specimenIdentification"
                label={
                    <div>
                        {getSpecimenIdentificationTitle(marketId as MARKET_VALUES)}
                        <br />
                        {previousSpecimenTestIdentifier && (
                            <label
                                style={{
                                    color: 'gray',
                                    fontSize: '10pt'
                                }}>
                                <Trans>Last specimen identifier included in the project: </Trans>{' '}
                                {previousSpecimenTestIdentifier}
                            </label>
                        )}
                    </div>
                }
                rules={[
                    requiredRule,
                    () => ({
                        validator(_, value) {
                            if (showErrorTestSpecimAlreadyExists) {
                                form && form.getFieldInstance('specimenIdentification').focus();
                                setShowErrorTestSpecimAlreadyExists(false);
                                return Promise.reject(
                                    t`This specimen identifier already exists, please insert a unique identifier`
                                );
                            }
                            return Promise.resolve();
                        }
                    })
                ]}>
                <Input data-testid="specimenIdentification" placeholder={t`P10926:1`} />
            </InputFormItem>

            <TypeOfTestingFormItem />

            <PurposeOfTestingFormItem />

            <Form.Item
                name="testingAge"
                label={<Trans>Testing Age</Trans>}
                rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^[0-9]\d*$/),
                        message: t`This field is required`
                    }
                ]}>
                <Input
                    type="number"
                    data-testid="testingAge"
                    addonAfter={t`days`}
                    placeholder={t`Enter the testing age`}
                />
            </Form.Item>

            <MouldFormItem />

            <Form.Item
                name="compactor"
                label={<Trans>Compactor</Trans>}
                initialValue={marketId === MARKETS.DE ? undefined : 'tampingRod'}
                rules={[requiredRule]}>
                <Select data-testid="compactor" placeholder={t`Select the compactor`}>
                    <Select.Option key="vibratingTable" value="vibratingTable">
                        {t`Vibrating table`}
                    </Select.Option>
                    <Select.Option key="internalVibrator" value="internalVibrator">
                        {t`Internal vibrator`}
                    </Select.Option>
                    <Select.Option key="tamper" value="tamper">
                        {t`Tamper`}
                    </Select.Option>
                    <Select.Option key="tampingRod" value="tampingRod">
                        {t`Tamping Rod`}
                    </Select.Option>
                </Select>
            </Form.Item>

            {marketId === MARKETS.DE && (
                <>
                    <CompactionTimeFormItem />
                    <ExternalInspectionFormItem />
                </>
            )}
        </>
    );
}

export default TestSpecimenTestForm;
