import { t, Trans } from '@lingui/macro';
import { PostTreatment } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Form, Modal } from 'antd';
import moment from 'moment';
import { useContext } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { EditModalFormContext } from '../../../contexts/EditModalFormContext';
import { PostTreatmentContext } from '../../contexts/PostTreatmentContext';
import { PostTreatmentEditModalForm } from './Forms';

interface PostTreatmentEditModalProps {
    postTreatment?: PostTreatment;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    triggerReload: () => void;
}

export function PostTreatmentEditModal(props: PostTreatmentEditModalProps) {
    const [form] = Form.useForm();
    const { postTreatment, open, setOpen, triggerReload } = props;
    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId } = useProjectState();
    const { concretingEndDate } = useContext(PostTreatmentContext);
    const api = useApi();

    function updatePostTreatment(postTreatment: PostTreatment) {
        api.concretingDocumentationClient
            .updatePostTreatment(companyId, projectId, structuralElementId, postTreatment.id!, postTreatment)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Post-treatment has been updated successfully`,
                        description: ''
                    });
                    triggerReload();
                    onClose();
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function createPostTreatment(postTreatment: PostTreatment) {
        api.concretingDocumentationClient
            .createPostTreatment(companyId, projectId, structuralElementId, postTreatment)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`New post-treatment has been recorded successfully`,
                        description: ''
                    });
                    triggerReload();
                    onClose();
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function onFinish(fieldsValue: PostTreatment) {
        form.validateFields()
            .then(() => {
                if (postTreatment) {
                    fieldsValue = { ...postTreatment, ...fieldsValue };
                    updatePostTreatment(fieldsValue);
                } else {
                    fieldsValue.concretedStructuralElementId = structuralElementId;
                    createPostTreatment(fieldsValue);
                }
            })
            .catch((e) => {
                ErrorNotification({ message: e.code, description: e.message });
            });
    }

    function setInitialValues() {
        if (postTreatment) {
            const initialValues = {
                ...postTreatment,
                postTreatmentStart: moment(postTreatment.postTreatmentStart),
                postTreatmentEnd: moment(postTreatment.postTreatmentEnd)
            };

            form.setFieldsValue(initialValues);
            return initialValues;
        }

        form.setFieldsValue({
            types: undefined,
            comment: undefined,
            typeOther: undefined,
            postTreatmentStart: concretingEndDate ? moment(concretingEndDate) : undefined,
            postTreatmentEnd: undefined
        });

        return undefined;
    }

    function onClose() {
        setOpen(false);
    }

    return (
        <Modal
            maskClosable={false}
            open={open}
            data-testid="supplier-config-modal"
            title={postTreatment ? <Trans>Edit post-treament</Trans> : <Trans>Record post-treatment</Trans>}
            okText={<Trans>Save</Trans>}
            cancelText={<Trans>Cancel</Trans>}
            onOk={() => form.submit()}
            onCancel={onClose}
            destroyOnClose
            width={600}>
            <EditModalFormContext.Provider value={{ formInitialValues: setInitialValues() }}>
                <PostTreatmentEditModalForm form={form} initialValues={setInitialValues()} onFinish={onFinish} />
            </EditModalFormContext.Provider>
        </Modal>
    );
}
