import moment from 'moment';
import { CommonTestFormValues } from '../../models';

export function createInitialCommonValuesDE(values: any) {
    const initialFormValues: CommonTestFormValues = values;

    return {
        samplingDate:
            initialFormValues?.samplingDate !== undefined ? moment(initialFormValues.samplingDate) : undefined,
        samplingTime:
            initialFormValues?.samplingTime !== undefined ? moment(initialFormValues.samplingTime) : undefined,
        samplingLocation: initialFormValues?.samplingLocation?.toString(),
        testType: initialFormValues?.testType?.toString()
    };
}
