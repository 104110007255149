import { ConcreteTypeExtendedBP } from "@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto";
import { Col, Row } from "antd";
import MethodOfConcreteFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/MethodOfConcreteFormItem";
import CompressiveStrengthFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/US/CompressiveStrengthFormItem";
import { DescriptionItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItems/DescriptionItem";
import { NameItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItems/NameItem";
import { AssignMixDesignToItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/AssignMixDesignItem";
import { CostCodeItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/CostCodeItem";
import { MassConcreteItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/MassConcreteItem";
import { PlanNumberItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/PlanNumberItem";
import { SlumpItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/SlumpItem";

interface FormUSProps {
    concreteTypes: ConcreteTypeExtendedBP[];
}

export function FormUS({ concreteTypes }: FormUSProps) {
    return <>
        <Row gutter={8}>
            <Col span="12">
                <NameItem />
            </Col>
            <Col span="12">
                <DescriptionItem />
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span="12">
                <PlanNumberItem  />
            </Col>
            <Col span="12">
                <CostCodeItem />
            </Col>
        </Row>
        <Row gutter={[16, 0]}>
            <Col span={12}>
                <CompressiveStrengthFormItem />
            </Col>
            <Col span={12}>
                <SlumpItem />
            </Col>
        </Row>
        <Row gutter={[16, 0]}>
            <Col span={12}>
                <MethodOfConcreteFormItem />
            </Col>
            <Col span={12}>
                <MassConcreteItem />
            </Col>
        </Row>
        <Row>
            <Col style={{ width: '100%' }}>
                <AssignMixDesignToItem concreteTypes={concreteTypes} />
            </Col>
        </Row>
    </>
}