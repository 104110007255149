import { FunctionComponent} from 'react';
import useProjectState from 'src/app-react/hooks/useProjectState';
import NumberFormatter from './NumberFormatter';
import { t } from '@lingui/macro';

interface VolumenFormatterProps {
    value: number | string | undefined | null;
    defaultValue?: string;
}

const VOLUMEN_MAPPER : Record<string, string> = {
    'DE': t`m3`,
    'US': t`yd3`,
}

const DEFAULT_VOLUMEN = t`m3`;

const VolumenFormatter: FunctionComponent<VolumenFormatterProps> = (props) => {
    const { marketId } = useProjectState();
    const unit = VOLUMEN_MAPPER[marketId] || DEFAULT_VOLUMEN;

    return <NumberFormatter {...props} unit={unit} />;
}

export default VolumenFormatter;
