import { Trans } from '@lingui/macro';
import {
  ConcretedStructuralElementTest,
  TypeMeasurement
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { compressiveDetailsTableColumns } from './compressiveColumnsTable';
import { temperatureDetailsTableColumns } from './temperatureColumnsTable';

export function MeasurementDetailsTable(props: { structuralElementId: string; measurementType: TypeMeasurement }) {
    const [measurement, setMeasurements] = useState([] as ConcretedStructuralElementTest[]);
    const { projectId, companyId, marketId } = useProjectState();
    const [loadTable, setLoadTable] = useState(true);
    const { structuralElementId, measurementType } = props;
    const api = useApi();

    async function loadMeasurements() {
        api.concretingDocumentationClient
            .loadCoseTestByTypeMeasurement(companyId, projectId, structuralElementId, measurementType)
            .then((response) => {
                if (response.isSuccess()) {
                    const result: ConcretedStructuralElementTest[] = response.getEntity();
                    setMeasurements(result);
                    setLoadTable(false);
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        loadMeasurements();
    }, [projectId, companyId]);

    return (
        <>
            {!loadTable && measurement.length === 0 ? (
                measurementType === 'temperature' ? (
                    <Trans>No temperature measurements have been recorded.</Trans>
                ) : (
                    <Trans>No compressive strength measurements have been recorded.</Trans>
                )
            ) : (
                <Table
                    style={{ marginTop: '1.5rem' }}
                    data-testid={
                        measurementType === 'temperature' ? 'temperatureDetailsTable' : 'compressiveDetailsTable'
                    }
                    rowClassName={measurementType === 'temperature' ? 'temperatureDetailsRow' : 'compressiveDetailsRow'}
                    rowKey="id"
                    columns={
                        measurementType === 'temperature'
                            ? temperatureDetailsTableColumns(marketId)
                            : compressiveDetailsTableColumns(marketId)
                    }
                    dataSource={measurement}
                    loading={loadTable}
                    pagination={false}
                />
            )}
        </>
    );
}
