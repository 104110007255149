import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import OrderLiteModalForm2Alert from './components/Alert';
import OrderLiteModalForm2DefaultDELayout from './components/FormDE/Layouts/DefaultLayout';
import OrderLiteModalForm2DefaultUSLayout from './components/FormUS/Layouts/DefaultLayout';
import OrderLiteModalForm2DefaultAUSLayout from './components/FormAUS/Layouts/DefaultLayout';
import { OrderLoadLiteTypesValues } from '../../../models/Types';

interface OrderLiteModalForm2Props {
    type?: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
}
function OrderLiteModalForm2({ type, origin }: OrderLiteModalForm2Props) {
    const { marketId } = useProjectState();

    let marketForm;

    switch (marketId) {
        case MARKETS.DE:
            marketForm = <OrderLiteModalForm2DefaultDELayout />;
            break;
        case MARKETS.US:
            marketForm = <OrderLiteModalForm2DefaultUSLayout />;
            break;
        case MARKETS.AUS:
            marketForm = <OrderLiteModalForm2DefaultAUSLayout type={type} origin={origin} />;
            break;
        default:
            throw new Error('invalid marketId');
    }

    return (
        <>
            <OrderLiteModalForm2Alert />
            {marketForm}
        </>
    );
}

export default OrderLiteModalForm2;
