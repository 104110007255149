import { MARKETS } from 'src/app-react/constants/markets';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { OrderManagementActions, OrderManagementActionsValues } from '../../models/Actions';
import { OrderDependencies } from '../../models/OrderDependencies';

import { InputOrderBP } from '@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.dto';
import { OrderFields, OrderFieldsDE, OrderFieldsUS } from '../../models/OrderFields';

function getCataloguedStructuralElementIds(
    action: OrderManagementActionsValues,
    { structuralElement, extendedOrder }: OrderDependencies
): string[] {
    const cataloguedStructuralElementIds: string[] = [];

    if (structuralElement?.id) {
        cataloguedStructuralElementIds.push(structuralElement?.id);
    } else if (extendedOrder?.concretedStructuralElement) {
        const orderStructuralElement = extendedOrder.concretedStructuralElement;

        if (action === OrderManagementActions.Update) {
            cataloguedStructuralElementIds.push(orderStructuralElement.id);
        } else if (action === OrderManagementActions.Duplicate) {
            cataloguedStructuralElementIds.push(orderStructuralElement.cataloguedStructuralElementId);
        }
    }

    return cataloguedStructuralElementIds;
}

export function fromOrderFieldsToInputOrderBP({
    action,
    marketId,
    orderFields,
    dependencies
}: {
    marketId: string;
    orderFields: OrderFields;
    action: OrderManagementActionsValues;
    dependencies: OrderDependencies;
}): InputOrderBP {
    const { concreteType } = dependencies;
    const date = mergeDateTime(orderFields.dateOfConcreting, orderFields.deliveryTime).toDate();

    const inputOrderBP: InputOrderBP = {
        deliveryTime: date,
        orderedCataloguedConcreteTypeId: concreteType?.id ?? '',
        orderedConcreteTypeNumber: concreteType?.concreteTypeNumber ?? '',
        cataloguedStructuralElementIds: getCataloguedStructuralElementIds(action, dependencies),
        volume: Number(orderFields.concreteQuantity),
        additionalQuantityRequired: orderFields.additionalQuantity,
        additionalRequirements: orderFields.additionalRequirement || undefined,
        unloadingLocationId: orderFields.unloadingLocation,
        componentAllocation: orderFields.structuralElement
    };

    switch (marketId) {
        case MARKETS.DE:
            const orderFieldsDE = orderFields as OrderFieldsDE;

            return {
                ...inputOrderBP,
                performanceUnit: 'm3h',
                volumeUnit: 'm3',
                performance: Number(orderFieldsDE.outPerHour)
            };
        case MARKETS.US:
            const orderFieldsUS = orderFields as OrderFieldsUS;

            return {
                ...inputOrderBP,
                volumeUnit: 'yd3',
                slump: orderFieldsUS.slump,
                loadSize: orderFieldsUS.loadSize,
                spacingInMinutes: orderFieldsUS.spacingInMinutes
            };
        default:
            throw new Error(`fromOrderFieldsToOrder: invalid marketId: ${marketId}`);
    }
}
