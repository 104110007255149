import { DeliveryNoteAllDetails } from '../../hooks/useDeliveryNoteDetails';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { MARKETS } from 'src/app-react/constants/markets';
import ConcreteDetailsTableAUS from './markets/AUS/ConcreteDetailsTable';
import ConcreteDetailsTableDE from './markets/DE/ConcreteDetailsTable';
import useConcretedStructuralElement from '../../hooks/useConcretedStructuralElement';
import { useEffect } from 'react';

export interface Props {
    deliveryNoteAllDetails: DeliveryNoteAllDetails;
}

function ConcreteDetailsTable({ deliveryNoteAllDetails }: Props) {
    const { marketId } = useProjectState();

    const { concretedStructuralElement, loadData } = useConcretedStructuralElement({
        concreteLoadId: deliveryNoteAllDetails?.concreteLoad?.id
    });

    useEffect(() => {
        if (!deliveryNoteAllDetails?.concretedLoad) return;
        loadData();
    }, [deliveryNoteAllDetails, loadData]);

    if (!deliveryNoteAllDetails?.concretedLoad) return null;

    switch (marketId) {
        case MARKETS.AUS:
            return <ConcreteDetailsTableAUS deliveryNoteAllDetails={deliveryNoteAllDetails} />;
        default:
            return (
                <ConcreteDetailsTableDE
                    concretedStructuralElement={concretedStructuralElement}
                    deliveryNoteAllDetails={deliveryNoteAllDetails}
                />
            );
    }
}

export default ConcreteDetailsTable;
