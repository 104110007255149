import { useCallback, useState } from 'react';
import { OrderLoadLiteTypesValues, ORDER_LOAD_LITE_TYPES } from '../models/Types';

const MAX_PAGE = 3;

function useStepsPagination(type: OrderLoadLiteTypesValues) {
    const minPage = type !== ORDER_LOAD_LITE_TYPES.MANUAL ? 0 : 1;
    const [page, setPage] = useState(minPage);

    const nextPage = useCallback(() => {
        setPage((oldPage) => {
            const newPage = oldPage + 1;
            return newPage >= MAX_PAGE ? MAX_PAGE : newPage;
        });
    }, [setPage]);

    const prevPage = useCallback(() => {
        setPage((oldPage) => {
            const newPage = oldPage - 1;
            return newPage >= minPage ? newPage : minPage;
        });
    }, [setPage, minPage]);

    return {
        page,
        maxPage: MAX_PAGE,
        minPage,
        nextPage,
        prevPage
    };
}

export default useStepsPagination;
