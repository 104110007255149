/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement, cloneElement } from 'react';
import { Form, FormItemProps, SelectProps } from 'antd';
import parseRules from '../utils/parseRules';

export interface SelectFormItemProps extends FormItemProps {
    selectProps?: SelectProps;
}

function SelectFormItem({ required, selectProps, ...props }: SelectFormItemProps) {
    return (
        <Form.Item {...props} rules={parseRules(required, props)}>
            {isValidElement(props.children) && cloneElement(props.children, selectProps)}
        </Form.Item>
    );
}

export default SelectFormItem;
