import { t, Trans } from '@lingui/macro';
import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext } from 'react';
import { EditModalFormContext } from '../../../../../contexts/EditModalFormContext';

interface PostTreatmentTypeFormItemProps {
    setTypeOtherVisible: React.Dispatch<React.SetStateAction<boolean>>;
}
export function PostTreatmentTypeFormItem(props: PostTreatmentTypeFormItemProps) {
    
    const {setTypeOtherVisible} = props;
    const {formInitialValues} = useContext(EditModalFormContext);

    function onChange(option: string)  {
        setTypeOtherVisible(option === 'other');
    };
    
    function evalDisableItem() {
        return (formInitialValues && formInitialValues.types !== undefined);
    }
    
    return (
        <FormItem
            name="types"
            label={<b><Trans>Post-treatment type</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                required: true,
                message: <Trans>Please select a post-treatment type</Trans>
            }]}>

            <Select data-testid="types" 
                onChange={onChange}
                disabled={evalDisableItem()}
                placeholder={t`Select post-treatment type`} 
                style={{width:'100%'}}>
                <Select.Option key="keptInMoulds" value="keptInMoulds">
                    {t`Kept in moulds (Mould)`}
                </Select.Option>
                <Select.Option key="applicationOfImperviousMaterial" value="applicationOfImperviousMaterial">
                    {t`Application of impervious material (PVC)/plastic sheeting`}
                </Select.Option>
                <Select.Option key="applicationOfWettedBurlap" value="applicationOfWettedBurlap">
                    {t`Application of wetted burlap (Burlap)`}
                </Select.Option>
                <Select.Option key="waterCuring" value="waterCuring">
                    {t`Water curing (Water)`}
                </Select.Option>
                <Select.Option key="applicationOfCuringCompound" value="applicationOfCuringCompound">
                    {t`Application of curing compound (Resin)`}
                </Select.Option>
                <Select.Option key="heatInsulationPads" value="heatInsulationPads">
                    {t`Heat insulation pads`}
                </Select.Option>
                <Select.Option key="other" value="other">
                    {t`Other`}
                </Select.Option>
            </Select>
        </FormItem>
    );
}
