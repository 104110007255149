import { ExclamationCircleOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { ConcretingDocumentationClient } from "@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.client";
import { Modal } from "antd";
import ErrorNotification from "src/app-react/components/Notification/ErrorNotification";
import SuccessNotification from "src/app-react/components/Notification/SuccessNotification";

interface PostTreatmentDeleteModalProps {
    companyId: string;
    projectId: string;
    structuralElementId: string;
    postTreatmentId: string;
    concretingDocumentationClient: ConcretingDocumentationClient;
    triggerReload: () => void;
}

export function PostTreatmentDeleteModal(props: PostTreatmentDeleteModalProps) {
    const { companyId, projectId, structuralElementId, postTreatmentId, triggerReload, concretingDocumentationClient } = props;


    function deletePostTreatment() {
        concretingDocumentationClient.deletePostTreatment(companyId, projectId, structuralElementId, postTreatmentId)
        .then((response) => {
            if (response.isSuccess()) {
                SuccessNotification({
                    message: t`Post-treatment record has been deleted successfully`,
                    description: ''
                });
                triggerReload();
            } else {
                ErrorNotification({ message: response.getError(), description: '' });
            }
        })
        .catch((info) => {
            ErrorNotification({
                message: info.errorFields[0].errors[0],
                description: ''
            });
        });
    }

    return (
        Modal.warning({
            title: <b>{t`Do you want to delete this record?`}</b>,
            width: 600,
            icon: <ExclamationCircleOutlined />,
            content: t`You are about to delete this post-treatment. It will no longer be available.`,
            okText: t`Confirm`,
            okType: 'primary',
            okButtonProps: {className: 'postTreatmentCancelModal'},
            okCancel: true,
            cancelText: t`Close`,
            onOk() {
                deletePostTreatment();
            }

        })
    )
}