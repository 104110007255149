import { ISupplierData } from '.';
import { MARKETS } from '../markets';

export const SAUTER_DSA_DATA: ISupplierData = {
    market: MARKETS.DE,
    label: 'Dsa',
    name: ['Spenner-Herkules'],
    softwareName: 'dsa',
    apiIntegration: true,
    permissions: {
        allowOrderEdit: true,
        allowOrderCancel: true,
        allowOrderAdditionalQuantity: true,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: true
    }
};
