import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext } from 'react';
import { EditModalFormContext } from '../../../../../contexts/EditModalFormContext';

export function TypeOtherFormItem() {

    const {formInitialValues} = useContext(EditModalFormContext);

    function evalDisableItem() {
        return (formInitialValues && formInitialValues.type !== undefined);
    }
    
    return (
        <FormItem
            name="typeOther"
            rules={[{
                whitespace: true,
                required: true,
                message: <Trans>This field is required</Trans>
            }]}>
             <Input
                disabled={evalDisableItem()}
                data-testid="typeOther"
                placeholder={t`Provide details about type of measurement`}
            />
        </FormItem>
    );
}
