/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import InputNumber from 'src/app-react/components/Form/InputNumber';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import useProjecsConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function SlumpFormItem(props: InputFormItemProps) {
    const { units } = useProjecsConstantsState();
    const required = props.required ?? true;

    return (
        <InputFormItem
            required={required}
            label={t`Slump`}
            name="slump"
            rules={[numberRule({ min: 0, required })]}
            {...props}>
            <InputNumber style={{ width: '100%' }} addonAfter={units.slump} min={0} />
        </InputFormItem>
    );
}

export default SlumpFormItem;
