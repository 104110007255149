import moment from 'moment';
import { ISupplierData } from '..';
import { MARKETS } from '../../markets';
import { ORDER_STATUS } from '../../orderStatus';

export const CEMEX_DATA: ISupplierData = {
    market: MARKETS.US,
    label: 'CemexGo',
    name: ['CEMEX'],
    softwareName: 'cemex.cemexgo',
    apiIntegration: true,
    permissions: {
        allowOrderEdit: false,
        allowOrderCancel: true,
        allowOrderAdditionalQuantity: false,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: false
    },
    custom: {
        orderCancelStatus: {
            allow: [ORDER_STATUS.INQUIRY_DRAFT, ORDER_STATUS.CONFIRMED]
        },
        orderEditStatus: {
            allow: [ORDER_STATUS.INQUIRY_DRAFT, ORDER_STATUS.CONFIRMED]
        },
        checkDeliveryTimeFunc(deliveryTime?: Date) {
            const timeOver = moment(deliveryTime).subtract(1, 'days').set({ h: 12, m: 0, s: 0 });

            return new Date() > timeOver.toDate();
        }
    }
};
