import moment from 'moment';
import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';
import {
  SupplierConcreteLoadDataLite,
    SupplierConcreteLoadDataLiteAUS,
    SupplierConcreteLoadDataLiteDE,
    SupplierConcreteLoadDataLiteUS
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

function parseDate(date?: Date) {
    if (date) {
        return moment(date);
    }

    return undefined;
}

function fromSupplierConcreteLoadDataLiteFormFieldsDE({ producedConcrete }: SupplierConcreteLoadDataLiteDE) {
    let requiredXf = producedConcrete?.xfFirst?.toString();

    if (requiredXf && producedConcrete?.xfSecond) {
        requiredXf = `${requiredXf}/${producedConcrete.xfSecond}`;
    }

    const formFields: Partial<ConcreteLoadDataLiteFormFields> = {
        requiredX0: producedConcrete?.x0,
        requiredXc: producedConcrete?.xc,
        requiredXd: producedConcrete?.xd,
        requiredXf,
        requiredXa: producedConcrete?.xa,
        requiredXm: producedConcrete?.xm,
        requiredXs: producedConcrete?.xs,
        moistureClass: producedConcrete?.moistureClass,
        strengthDevelopmentClass: producedConcrete?.strengthDevelopmentClass,
        maxAggregateSize: producedConcrete?.maxAggregationSize,
        densityClass: producedConcrete?.densityClass,
        methodOfConcrete: producedConcrete?.methodOfConcrete,
        chlorideContentClass: producedConcrete?.chlorideContent,
        consistencyClass: {
            consistencyType: producedConcrete?.consistencyType,
            consistencyClass: producedConcrete?.consistencyClass
        },
        compressiveStrengthClass: {
            requiredTypeOfConcreteType: producedConcrete?.typeOfConcrete,
            cylindricCompressiveStrength: producedConcrete?.cylindricCompressiveStrength,
            cubicCompressiveStrength: producedConcrete?.cubicCompressiveStrength
        }
    };

    return formFields;
}

function fromSupplierConcreteLoadDataLiteFormFieldsUS(data: SupplierConcreteLoadDataLiteUS) {
    const formFields: Partial<ConcreteLoadDataLiteFormFields> = {
        slump: data?.producedConcrete?.slump,
        maxWaterToAdd: data?.producedConcrete?.maxWaterToAdd,
        compressiveStrength: data?.producedConcrete?.compressiveStrength
    };

    return formFields;
}

function fromSupplierConcreteLoadDataLiteFormFieldsAUS({ producedConcrete }: SupplierConcreteLoadDataLiteAUS) {
    const formFields: Partial<ConcreteLoadDataLiteFormFields> = {
        compressiveStrength: producedConcrete?.compressiveStrength,
        slump: producedConcrete?.slump,
        maxWaterToAdd: producedConcrete?.maxWaterToAdd,
        maxAggregateSize: producedConcrete?.maxAggregationSize,
        description: producedConcrete?.description
    };

    return formFields;
}

function fromRawDataResponseToFormFields(
    data: SupplierConcreteLoadDataLite,
    formFields: ConcreteLoadDataLiteFormFields
) {
    const newFormFields: ConcreteLoadDataLiteFormFields = {
        number: data.number,
        deliveryNoteNumber: data.deliveryNoteNumber,
        volume: data.volume,
        orderVolume: data.orderVolume,
        concretedVolume: data.concretedVolume,
        deliveryTime: moment(data.deliveryTime),
        truckNumber: data.truckNumber,
        mixingPlant: data.mixingPlant,
        arrivalAtConstructionSite: parseDate(data.arrivalAtConstructionSite),
        loadingStart: parseDate(data.loadingStart), //  loadingEnd: parseDate(data.loadingEnd) unloadingStart: parseDate(data.loadingEnd),
        unloadingStart: parseDate(data.unloadingStart),
        unloadingEnd: parseDate(data.loadingEnd),
        signedOn: parseDate(data.loadingEnd),
        weather: data.weather,
        locationDistanceToPlant: data?.locationDistanceToPlant,
        constructorName: data?.constructorName,
        constructorNumber: data?.constructorNumber,
        contact: data?.contact,
        externalReference: data?.externalReference,
        supplierBusinessNumber: data?.supplierBusinessNumber,
        supplierName: data?.supplierName,
        additionalQuantityRequired: data?.additionalQuantityRequired,

        // qr fields widthout priority
        supplierOrderId: formFields.supplierOrderId ?? data.supplierOrderId,
        locationType: formFields.locationType ?? data.locationType,
        locationName: formFields.locationName ?? data.locationName,
        locationDescription: formFields.locationDescription ?? data.locationDescription,

        ...fromSupplierConcreteLoadDataLiteFormFieldsDE(<SupplierConcreteLoadDataLiteDE>data),
        ...fromSupplierConcreteLoadDataLiteFormFieldsUS(<SupplierConcreteLoadDataLiteUS>data),
        ...fromSupplierConcreteLoadDataLiteFormFieldsAUS(<SupplierConcreteLoadDataLiteAUS>data)
    };

    newFormFields.loadingStartDate = newFormFields.loadingStart;
    newFormFields.arrivalAtConstructionSiteDate = newFormFields.arrivalAtConstructionSite;
    newFormFields.unloadingStartDate = newFormFields.unloadingStart;
    newFormFields.unloadingEndDate = newFormFields.unloadingEnd;
    newFormFields.signedOnDate = newFormFields.signedOn;

    return newFormFields;
}

export default fromRawDataResponseToFormFields;
