const DENSITY_CLASS = [
    { value: 'D1.0', label: 'D1.0' },
    { value: 'D1.2', label: 'D1.2' },
    { value: 'D1.4', label: 'D1.4' },
    { value: 'D1.6', label: 'D1.6' },
    { value: 'D1.8', label: 'D1.8' },
    { value: 'D2.0', label: 'D2.0' }
];

export default DENSITY_CLASS;
