import { t, Trans } from '@lingui/macro';
import { Market } from '@nexploretechnology/concreting-core-client';
import { UserManagement } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Switch } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';

export type TProjectRoles = ['ProjectManager', 'Foreman', 'ConcreteEngineer', 'Subcontractor', 'Batcher'];

export interface IEditUserFormProps {
    prefilledUserManagement: UserManagement;
    marketId: Market;
    hideModal: () => void;
    showModal: boolean;
    onComplete?: () => void;
}

function EditUserForm(props: IEditUserFormProps) {
    const { prefilledUserManagement, marketId, hideModal, showModal, onComplete } = props;
    const [form] = Form.useForm();
    const api = useApi();
    const [disableViewer, setDisableViewer] = useState<boolean>();
    const [disableNonViewer, setDisableNonViewer] = useState<boolean>();

    useEffect(() => {
        form.setFieldsValue({
            userName: prefilledUserManagement?.userName,
            userEmail: prefilledUserManagement?.userEmail,
            isUserIntern: prefilledUserManagement?.isUserIntern,
            roles: prefilledUserManagement?.roles
        });
        setDisableNonViewer(prefilledUserManagement?.roles?.includes('Viewer'));
        setDisableViewer(prefilledUserManagement?.roles?.length && !prefilledUserManagement?.roles?.includes('Viewer'));
    }, [form, marketId, prefilledUserManagement]);

    const cleanForm = () => {
        form.setFieldsValue({
            userName: '',
            userEmail: '',
            isUserIntern: marketId === 'AUS',
            roles: []
        });
    };

    const onSubmit = () => {
        form.validateFields().then(() => {
            const formFields = form.getFieldsValue();

            api.userManagementClient
                .editUserRoles(
                    prefilledUserManagement.companyId,
                    prefilledUserManagement.projectId,
                    prefilledUserManagement.userId,
                    formFields.roles
                )
                .then((response) => {
                    if (response.isSuccess()) {
                        hideModal();
                        cleanForm();
                        onComplete?.();
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info: any) => {
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        });
    };

    const onChangeRole = (checkedValues: Array<CheckboxValueType>) => {
        setDisableNonViewer(checkedValues.includes('Viewer'));
        setDisableViewer(checkedValues.length && !checkedValues.includes('Viewer'));
    };

    return (
        <Modal
            maskClosable={false}
            title={t`Edit user roles`}
            open={!!showModal}
            onCancel={() => {
                hideModal();
                cleanForm();
            }}
            onOk={onSubmit}
            data-testid="edit-roles-form"
            forceRender
            footer={[
                <Button
                    onClick={() => {
                        hideModal();
                        cleanForm();
                    }}
                    key="hide">
                    <Trans>Cancel</Trans>
                </Button>,
                <Button type="primary" onClick={onSubmit} key="show">
                    <Trans>SAVE</Trans>
                </Button>
            ]}>
            <Col span={24}>
                <Form form={form} data-testid="edit-user-form" id="manageUsersForm" layout="vertical">
                    <Form.Item
                        hidden={marketId !== 'AUS'}
                        name="isUserIntern"
                        valuePropName="checked"
                        label={t`Intern User`}>
                        <Switch disabled data-testid="isUserIntern" />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Trans>User name</Trans>
                            </span>
                        }
                        name="userName"
                        rules={[{ required: true, message: t`This field is required` }]}>
                        <Input disabled type="text" placeholder={t`Enter user name`} data-testid="userName" />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Trans>Email address</Trans>
                            </span>
                        }
                        name="userEmail"
                        rules={[
                            { required: true, message: t`This field is required` },
                            {
                                type: 'email',
                                message: t`Please input a correct email address`
                            }
                        ]}>
                        <Input disabled type="email" placeholder={t`Enter email address`} data-testid="email" />
                    </Form.Item>

                    <Form.Item
                        label={
                            <span>
                                <Trans>Select Roles</Trans>
                            </span>
                        }
                        name="roles"
                        rules={[{ required: true, message: t`This field is required` }]}>
                        <Checkbox.Group onChange={onChangeRole}>
                            <Row>
                                <Checkbox
                                    disabled={disableNonViewer}
                                    value="ProjectManager"
                                    data-testid="project_manager">
                                    <Trans>Project Manager</Trans>
                                </Checkbox>
                            </Row>
                            <Row>
                                <Checkbox disabled={disableNonViewer} value="Foreman" data-testid="foreman">
                                    <Trans>Foreman</Trans>
                                </Checkbox>
                            </Row>
                            <Row hidden={marketId === 'AUS'}>
                                <Checkbox
                                    disabled={disableNonViewer}
                                    value="ConcreteEngineer"
                                    data-testid="concrete_engineer">
                                    <Trans>Concrete Engineer</Trans>
                                </Checkbox>
                            </Row>
                            <Row hidden={marketId !== 'AUS'}>
                                <Checkbox disabled={disableNonViewer} value="Subcontractor" data-testid="subcontractor">
                                    <Trans>Subcontractor</Trans>
                                </Checkbox>
                            </Row>
                            <Row hidden={marketId === 'AUS'}>
                                <Checkbox disabled={disableNonViewer} value="Batcher" data-testid="batcher">
                                    <Trans>Batcher</Trans>
                                </Checkbox>
                            </Row>
                            <Row>
                                <Checkbox disabled={disableViewer} value="Viewer" data-testid="viewer">
                                    <Trans>Viewer</Trans>
                                </Checkbox>
                            </Row>
                        </Checkbox.Group>
                    </Form.Item>
                </Form>
            </Col>
        </Modal>
    );
}

export default EditUserForm;
