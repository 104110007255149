/* eslint-disable no-underscore-dangle */
import { Direction } from '@nexploretechnology/bcqd-client';
import { OffsetPagination } from '@nexploretechnology/concreting-core-client';
import {
    ColumnType,
    FilterValue,
    SorterResult,
    TableCurrentDataSource,
    TablePaginationConfig
} from 'antd/lib/table/interface';

export const pageSize = 10;

export const noPagination: OffsetPagination<any> = {
    limit: 1000,
    skip: 0,
    sorting: []
};

export const defaultPagination: OffsetPagination<any> = {
    limit: pageSize,
    skip: 0,
    sorting: []
};

export function paginationAdapterReactAntdToBCQD<T>(
    pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    _extra: TableCurrentDataSource<T>
): OffsetPagination<T> {
    const _sorter = sorter as SorterResult<T>;
    const _pageSize = pagination?.pageSize || 0;
    return {
        limit: _pageSize,
        skip: ((pagination?.current || 1) - 1) * _pageSize,
        sorting:
            _sorter?.field && _sorter?.order
                ? [
                      {
                          [_sorter.field as string]: {
                              ascend: 'asc',
                              descend: 'desc'
                          }[_sorter?.order] as Direction
                      } as Record<keyof T, Direction>
                  ]
                : undefined
    };
}

export type ExtendedColumnType<T> = ColumnType<T> & {
    children?: ExtendedColumnType<T>[];
    hidden?: boolean;
};
