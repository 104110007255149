import { CataloguedStructuralElementExtendedBP, ConcreteTypeExtendedBP, OffsetPagination } from '@nexploretechnology/concreting-core-client';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ConcreteTypeModal from 'src/app-react/components/Modals/ConcreteTypeModal/ConcreteTypeModal';
import { StructuralElementModal } from 'src/app-react/components/Modals/StructuralElementModal/StructuralElementModal';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import {
    defaultPagination,
    noPagination,
    pageSize,
    paginationAdapterReactAntdToBCQD
} from 'src/app-react/utils/pagination';
import { ChildOrderConcreteTypesTable } from '../OrderConcreteTypesTable/OrderConcreteTypesTable';
import { OrderStructuralElementsTableColumnsDE } from './OrderStructuralElementsTable.columns.de';
import { OrderStructuralElementsTableColumnsUS } from './OrderStructuralElementsTable.columns.us';

interface IProps {
    parentConcreteType?: ConcreteTypeExtendedBP;
    expandable?: boolean;
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    orderId?: string;
    isChild?: boolean;
}
export default function OrderStructuralElementsTable({
    parentConcreteType,
    expandable = false,
    count,
    setCount,
    orderId,
    isChild
}: IProps) {
    const { projectId, companyId, marketId } = useProjectState();
    const [dataSource, setDataSource] = useState<CataloguedStructuralElementExtendedBP[]>([]);
    const [structuralElementModalVisible, setStructuralElementModalVisible] = useState(false);
    const [structuralElementModalInitialValues, setStructuralElementModalInitialValues] =
        useState<CataloguedStructuralElementExtendedBP>();
    const [concreteTypeModalVisible, setConcreteTypeModalVisible] = useState(false);
    const [selectedStructuralElementId, setSelectedStructuralElementId] = useState('');
    const [pagination, setPagination] = useState<OffsetPagination<CataloguedStructuralElementExtendedBP>>(
        isChild ? noPagination : defaultPagination
    );
    const api = useApi();

    const navigate: NavigateFunction = useNavigate();

    function onModalClose() {
        if (!orderId) {
            navigate(
                `/projects/${projectId}/order-type/structuralel/${selectedStructuralElementId}/concretetype/${parentConcreteType.id}/new-order`
            );
        } else {
            navigate(
                `/projects/${projectId}/order-type/structuralel/${selectedStructuralElementId}/concretetype/${parentConcreteType.id}/order/${orderId}/edit`
            );
        }
    }

    const loadTableData = async (): Promise<void> => {
        if (parentConcreteType) {
            api.catalogueManagementClient
                .getAssignedExtendedCaSesToConcreteTypeAsDataList(companyId, projectId, parentConcreteType.id, {
                    pagination,
                    isActive: true
                })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        } else {
            api.catalogueManagementClient
                .getExtendedCataloguedStructuralElementsForProjectAsDataList(companyId, projectId, {
                    pagination,
                    isActive: true
                })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        }
    };

    useEffect(() => {
        loadTableData();
        const interval = setInterval(loadTableData, 20001);
        return () => clearInterval(interval);
    }, [parentConcreteType, pagination, projectId, companyId]);

    async function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<CataloguedStructuralElementExtendedBP>
            | SorterResult<CataloguedStructuralElementExtendedBP>[],
        extra: TableCurrentDataSource<CataloguedStructuralElementExtendedBP>
    ): Promise<void> {
        setPagination(paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra));
    }

    function onCatalogueStructuralElementModal(structuralElement: CataloguedStructuralElementExtendedBP): void {
        setStructuralElementModalInitialValues(structuralElement);
        setStructuralElementModalVisible(true);
    }

    function onCatalogueConcreteTypeModal(structuralElementId: string): void {
        setSelectedStructuralElementId(structuralElementId);
        setConcreteTypeModalVisible(true);
    }

    return (
        <>
            <Table
                data-testid="StructuralElementsTable"
                rowClassName="StructuralElementsTable-testid"
                pagination={isChild ? false : { position: ['bottomRight'], defaultPageSize: pageSize, total: count }}
                onChange={onChange}
                showSorterTooltip={false}
                scroll={{ x: 1 }}
                columns={{
                    DE: OrderStructuralElementsTableColumnsDE,
                    US: OrderStructuralElementsTableColumnsUS
                }[marketId as 'DE' | 'US']({
                    expandable,
                    onCatalogueStructuralElementModal,
                    onCatalogueConcreteTypeModal
                })}
                rowKey="id"
                dataSource={dataSource}
                expandable={
                    isChild
                        ? {}
                        : {
                            expandedRowRender: (record) => (
                                <ChildOrderConcreteTypesTable
                                    expandable={false}
                                    parentStructuralElement={record}
                                    orderId={orderId}
                                />
                            ),
                            rowExpandable: (record) => Boolean(expandable && record.assignedConcreteTypes?.length)
                        }
                }
            />
            {structuralElementModalInitialValues && (
                <StructuralElementModal
                    structuralElementDetails={structuralElementModalInitialValues}
                    open={structuralElementModalVisible}
                    setOpen={setStructuralElementModalVisible}
                />
            )}

            {parentConcreteType && (
                <ConcreteTypeModal
                    concreteDetails={parentConcreteType}
                    open={concreteTypeModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                    displayNextLabel
                    onClose={onModalClose}
                />
            )}
        </>
    );
}

export function ChildOrderStructuralElementsTable(props: Omit<IProps, 'count' | 'setCount'>) {
    const [count, setCount] = useState(0);
    return <OrderStructuralElementsTable {...{ isChild: true, ...props, count, setCount }} />;
}
