import { ConcreteTypeExtendedBP } from "@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto";
import { Col, Row } from "antd";
import CompressiveStrengthClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/CompressiveStrengthClassFormItem";
import DensityClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/DensityClassFormItem";
import ExposureClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/ExposureClassesFormItem";
import { ExternalInspectionFormItem } from "src/app-react/business-page/shared/FormItems/Catalogue/DE/ExternalInspectionFormItem";
import MethodConcreteUseChlorideFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/MethodOfConcreteUseChlorideFormItem";
import MoistureClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/MoistureClassFormItem";
import { DescriptionItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItems/DescriptionItem";
import { NameItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItems/NameItem";
import { AssignMixDesignToItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsDE/AssignMixDesignItem";
import { BoQPositionsItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsDE/BoQPositionsItem";
import { PlanNumberItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsDE/PlanNumberItem";
import { StrengthDevelopmentItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsDE/StrengthDevelopmentItem";


interface FormDEProps {
    boqPositions: string[];
    concreteTypes: ConcreteTypeExtendedBP[];
}

export function FormDE({ boqPositions, concreteTypes }: FormDEProps) {
    return <>
        <Row gutter={8}>
            <Col span="12">
                <NameItem />
            </Col>
            <Col span="12">
                <DescriptionItem />
            </Col>
        </Row>
        <Row gutter={8}>
            <Col span="12">
                <BoQPositionsItem boqPositions={boqPositions} />
            </Col>
            <Col span="12">
                <PlanNumberItem />
            </Col>
        </Row>
        <Row gutter={[16, 0]}>
            <Col span={12}>
                <CompressiveStrengthClassFormItem
                    formItem1={{ name: 'typeOfConcrete' }}
                    formItem2={{ name: 'cylindricCompressiveStrength' }}
                    formItem3={{ name: 'cubicCompressiveStrength' }}
                    required={true}
                />
            </Col>
            <Col span={12}>
                <MoistureClassFormItem />
            </Col>
        </Row>
        <Row>
            <Col style={{ width: '100%' }}>
                <ExposureClassFormItem />
            </Col>
        </Row>
        <Row gutter={[16, 0]}>
            <Col span={12}>
                <MethodConcreteUseChlorideFormItem name="methodConcreteUseChloride" />
            </Col>
            <Col span={12}>
                <StrengthDevelopmentItem />
            </Col>
        </Row>
        <Row gutter={[16, 0]}>
            <Col span={12}>
                <DensityClassFormItem />
            </Col>
            <Col span={12}>
                <ExternalInspectionFormItem required={true} />
            </Col>
        </Row>
        <Row>
            <Col style={{ width: '100%' }}>
                <AssignMixDesignToItem concreteTypes={concreteTypes} />
            </Col>
        </Row>
    </>
}