import { ISupplierData } from '.';
import { MARKETS } from '../markets';

export const TRAPOBET_DATA: ISupplierData = {
    market: MARKETS.DE,
    label: 'Trapobet',
    name: ['Trapobet'],
    manualIntegration: true,
    permissions: {
        allowOrderEdit: true,
        allowOrderCancel: true,
        allowOrderAdditionalQuantity: false,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: true
    }
};
