import { DeliveryNoteAllDetails } from '../../hooks/useDeliveryNoteDetails';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { MARKETS } from 'src/app-react/constants/markets';
import ConcreteInformationTableAUS from './markets/AUS/ConcreteInformationTable';
import ConcreteInformationTableDE from './markets/DE/ConcreteInformationTable';
export interface IConcreteInformationDataTable {
    deliveryNoteAllDetails: DeliveryNoteAllDetails;
}

function ConcreteInformationDataTable({ deliveryNoteAllDetails }: IConcreteInformationDataTable) {
    const { marketId } = useProjectState();

    switch (marketId) {
        case MARKETS.AUS:
            return <ConcreteInformationTableAUS deliveryNoteAllDetails={deliveryNoteAllDetails} />;
        default:
            return <ConcreteInformationTableDE deliveryNoteAllDetails={deliveryNoteAllDetails} />;
    }
}

export default ConcreteInformationDataTable;
