import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Col, Form, Input, Row } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import styles from '../../MultipleConcreteTestModal.module.css';
import { MARKETS } from 'src/app-react/constants/markets';
import { ExternalInspectionFormItem } from '../../FormItems/DE/ExternalInspectionFormItem';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import useProjectState from 'src/app-react/hooks/useProjectState';

export interface Props {
    showTitle?: boolean;
}

function TemperatureTestForm({ showTitle = true }: Props) {
    const { units } = useProjectConstantsState();
    const { marketId } = useProjectState();

    return (
        <>
            {showTitle && (
                <>
                    <Row>
                        <label className={styles.title}>
                            <Trans>Temperature Test</Trans>
                        </label>
                    </Row>
                    <br />
                </>
            )}

            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="testingDate"
                        label={<Trans>Testing Date</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <DatePicker data-testid="testingDate" placeholder={t`Enter Date`} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="testingTime"
                        label={<Trans>Testing Time</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <TimePicker data-testid="testingTime" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="airTemperature"
                label={<Trans>Air Temperature</Trans>}
                rules={[
                    {
                        required: true,
                        pattern:
                            i18n.locale === 'de-DE'
                                ? new RegExp(/^-?\d+(?:,\d{1})?$/)
                                : new RegExp(/^-?\d+(?:\.\d{1})?$/),
                        message: t`This field is required`
                    }
                ]}>
                <Input
                    type="text"
                    data-testid="airTemperature"
                    addonAfter={units.temperature}
                    placeholder={t`Enter the air temperature`}
                />
            </Form.Item>

            <Form.Item
                name="freshConcreteTemperature"
                label={<Trans>Fresh Concrete Temperature</Trans>}
                rules={[
                    {
                        required: true,
                        pattern:
                            i18n.locale === 'de-DE'
                                ? new RegExp(/^-?\d+(?:,\d{1})?$/)
                                : new RegExp(/^-?\d+(?:\.\d{1})?$/),
                        message: t`This field is required`
                    }
                ]}>
                <Input
                    type="text"
                    data-testid="freshConcreteTemperature"
                    addonAfter={units.temperature}
                    placeholder={t`Enter the fresh concrete temperature`}
                />
            </Form.Item>

            {marketId === MARKETS.DE && <ExternalInspectionFormItem />}
        </>
    );
}

export default TemperatureTestForm;
