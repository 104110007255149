import { PlusSquareOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { ColumnsType } from 'antd/lib/table';
import ExposureClassesFormatter from 'src/app-react/components/Formatters/ExposureClassesFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import { mapStrengthDevelopmentClass } from '../../utils';
import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

export const OrderConcreteTypesTableColumnsDE = (props: {
    expandable: boolean;
    showCatalogueConcreteTypeModal: (concreteType: ConcreteTypeExtendedBP, isOrderCreation: boolean) => void;
}): ColumnsType<ConcreteTypeExtendedBP> => {
    const { expandable, showCatalogueConcreteTypeModal } = props;

    const columns = [
        {
            title: <Trans>Concrete type number</Trans>,
            dataIndex: 'concreteTypeNumber',
            sorter: true,
            render: (concreteTypeNumber: string, record: ConcreteTypeExtendedBP) => (
                <>
                    <RawLinkButton onClick={() => showCatalogueConcreteTypeModal(record, false)}>
                        {concreteTypeNumber}
                    </RawLinkButton>
                </>
            )
        },
        {
            title: <Trans>Concrete type description</Trans>,
            dataIndex: 'concreteTypeDescription',
            sorter: true,
            render: (concreteTypeDescription: string) => <>{concreteTypeDescription}</>
        },
        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'typeOfConcrete',
            sorter: true,
            render: (typeOfConcrete: string, record: ConcreteTypeExtendedBP) =>
                renderCompressiveStrength(typeOfConcrete, record)
        },
        {
            title: <Trans>Exposure classes</Trans>,
            dataIndex: 'x0',
            render: (x0: boolean, record: ConcreteTypeExtendedBP) =>
                <ExposureClassesFormatter x0={record.x0} xa={record.xa} xc={record.xc} xd={record.xd} xfFirst={record.xfFirst} xfSecond={record.xfSecond} 
                    xm={record.xm} xs={record.xs}/>
        },
        {
            title: <Trans>Max aggregation size</Trans>,
            dataIndex: 'maximumAggregateSize',
            sorter: true,
            render: (maximumAggregateSize: number, record: ConcreteTypeExtendedBP) => <>{record.maximumAggregateSize}</>
        },
        {
            title: <Trans>Consistency class</Trans>,
            dataIndex: 'consistencyType',
            sorter: true,
            render: (consistencyType: string, record: ConcreteTypeExtendedBP) => (
                <>{`${consistencyType} ${record.consistencyClass}`}</>
            )
        },
        {
            title: <Trans>Strength development</Trans>,
            dataIndex: 'strengthDevelopmentClass',
            sorter: true,
            render: (strengthDevelopmentClass: string) => <>{mapStrengthDevelopmentClass(strengthDevelopmentClass)}</>
        }
    ];

    if (!expandable) {
        columns.push(displayOrderCreationColumn());
    }

    return columns;

    function displayOrderCreationColumn() {
        return {
            title: <Trans>Order</Trans>,
            dataIndex: 'order',
            sorter: false,
            fixed: 'right',

            render: (_: string, record: ConcreteTypeExtendedBP) => (
                <PlusSquareOutlined
                    data-testid="createOrdertypeBtn"
                    onClick={() => showCatalogueConcreteTypeModal(record, true)}
                    style={{ fontSize: '150%' }}></PlusSquareOutlined>
            )
        };
    }

    function renderCompressiveStrength(typeOfConcrete: string, record: ConcreteTypeExtendedBP): JSX.Element {
        return <>{`${typeOfConcrete} ${record.cylindricCompressiveStrength}/${record.cubicCompressiveStrength}`}</>;
    }
};
