import { Trans, t } from "@lingui/macro";
import { Select } from "antd";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";

export function MouldFormItem() {
    return <SelectFormItem
        name="mould"
        label={<Trans>Test Specimen Mould</Trans>}
        required={true}
        initialValue="cylinder"
    >
        <Select data-testid="mould" placeholder={t`Select the test specimen mould`}>
            <Select.Option key="cylinder" value="cylinder">
                {t`Cylinder`}
            </Select.Option>
        </Select>
    </SelectFormItem>
}