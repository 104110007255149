import { t, Trans } from '@lingui/macro';
import { Button, Form, Modal } from 'antd';
import { useEffect, useRef } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcreteTestingTypes } from '../../utils/concrete-tests-service';
import { MARKET_VALUES } from 'src/app-react/constants/markets';
import { CommonTestFormValues } from '../MultipleConcreteTestModal/components/CommonTestForm/models';
import {
    createInitialCommonValues,
    getFormCommonValues
} from '../MultipleConcreteTestModal/components/CommonTestForm/util';
import CommonTestForm from '../MultipleConcreteTestModal/components/CommonTestForm/CommonTestForm';
import { createInitialValues } from '../MultipleConcreteTestModal/components/WindSpeedTestForm/util';
import { WindSpeedTestFormValues } from '../MultipleConcreteTestModal/components/WindSpeedTestForm/models';
import useWindSpeedTest from '../MultipleConcreteTestModal/components/WindSpeedTestForm/hooks/useWindSpeedTest';
import {
    createWindSpeedTest,
    getWindSpeedFormValues
} from '../MultipleConcreteTestModal/components/WindSpeedTestForm/util';
import { WindSpeedTest } from '@nexploretechnology/concreting-core-client';
import WindSpeedTestForm from '../MultipleConcreteTestModal/components/WindSpeedTestForm/WindSpeedTestForm';

export interface IWindSpeedTestModalProps {
    orderId?: string;
    concreteLoadId?: string;
    windSpeedTestId?: string;
    open: boolean;
    setOpen: (isVisible: boolean) => void;
    initialFormValues?: Partial<WindSpeedTestFormValues & CommonTestFormValues>;
    reloadFreshConcreteTests?: () => Promise<void>;
    onOk?: () => void;
    onCancel?: () => void;
}

function WindSpeedTestModal(props: IWindSpeedTestModalProps) {
    const {
        orderId,
        concreteLoadId,
        windSpeedTestId,
        open,
        setOpen,
        initialFormValues,
        reloadFreshConcreteTests,
        onOk,
        onCancel
    } = props;
    const [form] = Form.useForm();
    const { projectId, marketId, companyId } = useProjectState();

    const windSpeedTestMutate = useWindSpeedTest();

    let enabledButton = useRef(true).current;

    async function onSubmit(): Promise<void> {
        if (!enabledButton) return;
        enabledButton = false;

        try {
            if (!(await form.validateFields())) return;
        } catch (err) {
            return;
        }

        const windSpeedTestFormValues = getWindSpeedFormValues(form);
        const commonTestFormValues = { ...getFormCommonValues(form), testType: ConcreteTestingTypes.WindSpeed };

        const windSpeedTest: WindSpeedTest = createWindSpeedTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            commonTestFormValues,
            windSpeedTestFormValues
        );

        if (!windSpeedTestId) {
            const { success, error } = await windSpeedTestMutate(windSpeedTest);

            if (error || !success) {
                ErrorNotification({
                    message: 'Wind speed test could not be created',
                    description: error ?? t`Unknown error`
                });
            } else {
                SuccessNotification({
                    message: t`Wind speed test created successfully`,
                    description: ''
                });
                reloadFreshConcreteTests?.();
                setOpen(false);
                onOk?.();
            }
        } else {
            const { success, error } = await windSpeedTestMutate(windSpeedTest, windSpeedTestId);

            if (error || !success) {
                ErrorNotification({
                    message: 'Wind speed test could not be updated',
                    description: error ?? t`Unknown error`
                });
            } else {
                SuccessNotification({
                    message: t`Wind speed test updated successfully`,
                    description: ''
                });
                reloadFreshConcreteTests?.();
                setOpen(false);
                onOk?.();
            }
        }

        enabledButton = true;
    }

    function formOnCancel(): void {
        setOpen(false);
        onCancel?.();
    }

    useEffect(() => {
        form.setFieldsValue(createInitialCommonValues(initialFormValues, marketId as MARKET_VALUES));
        form.setFieldsValue(createInitialValues(initialFormValues));
    }, [initialFormValues, projectId, companyId, form, marketId]);

    return (
        <Modal
            maskClosable={false}
            title={t`Edit wind speed test`}
            open={open}
            width={500}
            onCancel={formOnCancel}
            footer={[
                <Button data-testid="WindSpeedTestModal.cancelButton" key="cancelButton" onClick={formOnCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button data-testid="WindSpeedTestModal.okButton" key="okButton" onClick={onSubmit} type="primary">
                    <Trans>Save</Trans>
                </Button>
            ]}>
            <Form form={form} layout="vertical">
                <CommonTestForm requestTestType={false} />
                <WindSpeedTestForm showTitle={false} />
            </Form>
        </Modal>
    );
}

export default WindSpeedTestModal;
