const TIME_STAMP_DEPENDENCIES: string[] = [
    'loadingStart',
    'loadingStartDate',
    'arrivalAtConstructionSite',
    'arrivalAtConstructionSiteDate',
    'unloadingStart',
    'unloadingStartDate',
    'unloadingEnd',
    'unloadingEndDate',
    'signedOn',
    'signedOnDate'
];

export default TIME_STAMP_DEPENDENCIES;
