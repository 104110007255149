import { t, Trans } from '@lingui/macro';
import { Radio } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

export function ExternalInspectionFormItem({ required = false }: { required?: boolean }) {
    return (
        <FormItem
            label={t`External inspection`}
            name="externalInspection"
            required={required}
            rules={required ? [requiredRule] : undefined}
            initialValue
        >
            <Radio.Group data-testid="externalInspection" style={{ justifyContent: 'flex-start' }}>
                <Radio data-testid="externalInspectionYes" value={true}>
                    <Trans>Yes</Trans>
                </Radio>
                <Radio data-testid="externalInspectionNo" value={false}>
                    <Trans>No</Trans>
                </Radio>
            </Radio.Group>
        </FormItem>
    );
}

ExternalInspectionFormItem.defaultProps = {
    required: false
};
