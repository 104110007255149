import { IMarketFormats } from '../..';

const FORMATS: IMarketFormats = {
    dateTimeForm: 'MM/DD/YYYY hh:mm a',
    dateForm: 'MM/DD/YYYY',
    timeForm: 'hh:mm a',
    dateTime: 'MM/DD/YYYY hh:mm',
    date: 'MM/DD/YYYY',
    time: 'hh:mm a'
};

export default FORMATS;
