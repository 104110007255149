import { Trans, t } from "@lingui/macro";
import { Form, Radio } from "antd";
import requiredRule from "src/app-react/components/Form/Validations/requiredRule";

export function TypeOfConstructionFormItem() {
    return (
        <Form.Item
            label={t`Type of construction`}
            name="typeOfConstruction"
            rules={[requiredRule]}>
            <Radio.Group>
                <Radio value="civilConstruction" data-testid="civilConstruction">
                    <Trans>Civil construction</Trans>
                </Radio>
                <Radio value="buildingConstruction" data-testid="buildingConstruction">
                    <Trans>Building construction</Trans>
                </Radio>
            </Radio.Group>
        </Form.Item>
    );
}