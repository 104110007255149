import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function CustomerIdFormItem() {
    return (
        <FormItem
            name="customerId"
            label={<Trans>Supplier software customer code</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>Please input customer code</Trans>
                }
            ]}>
            <Input type="text" data-testid="customerId" placeholder={t`Enter customer code`} />
        </FormItem>
    );
}
