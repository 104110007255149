import { Trans } from '@lingui/macro';
import { SpecimenTestFormValuesAUS } from './models';

export function createInitialValues(values: any, deliveryNoteNumber?: string) {
    const initialFormValues: SpecimenTestFormValuesAUS = values;
    const randomNumber = Math.floor(Math.random() * 900000) + 100000;

    return {
        specimenIdentification:
            initialFormValues?.specimenIdentification ||
            (deliveryNoteNumber ? `${deliveryNoteNumber}-${randomNumber}` : ''),
        typeOfTesting: initialFormValues?.typeOfTesting,
        purposeOfTesting: initialFormValues?.purposeOfTesting,
        testingAge: initialFormValues?.testingAge?.toString(),
        mould: initialFormValues?.mould,
        compactor: initialFormValues?.compactor
    };
}

export const specimenIdentificationTitleAUS = <Trans>Cube identification</Trans>;
