import { SetStateAction, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

interface SearchParamsStateOptions {
    history?: false
}

export function useSearchParamsState<T = string>(
    searchParamName: string,
    defaultValue: T,
    options?: SearchParamsStateOptions
): readonly [
    searchParamsState: T,
    setSearchParamsState: React.Dispatch<React.SetStateAction<T>>,
] {
    const [searchParams, setSearchParams] = useSearchParams();

    let searchParamsState = defaultValue;
    const acquiredSearchParam = searchParams.get(searchParamName);

    if (acquiredSearchParam) {
        searchParamsState = JSON.parse(decodeURIComponent(acquiredSearchParam)) as T;
    }

    const setSearchParamsState = useCallback((newValue: SetStateAction<T>) => {
        if (typeof newValue === "function") {
            newValue = (newValue as (prevState: T) => T)(searchParamsState);
        }
        let newState: { [key: string]: string } = {};
        searchParams.forEach((value: string, key: string) => {
            newState[key] = value;
        });
        try {
            newState[searchParamName] = encodeURIComponent(JSON.stringify(newValue));
        } catch (error) {
            console.error(error);
        }
        setSearchParams(newState, { replace: !options?.history });
    }, [searchParams, searchParamsState, setSearchParams, searchParamName, options?.history]);
    return [searchParamsState, setSearchParamsState];
}