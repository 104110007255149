import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGenerateConcreteLoadLite() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();

    const generateConcreteLoadLiteData = useCallback(
        async (qrRawData: string) => {
            return (
                api.deliveryNoteLiteClient
                    .generateConcreteLoadLiteData(companyId, projectId, 'qr', qrRawData)
                    .then((response) => response.getEntity())
                    // eslint-disable-next-line no-shadow
                    .catch((error: AxiosError) => {
                        setError({ message: error.response.data.code, description: error.response.data.message });
                    })
            );
        },
        [api, companyId, projectId]
    );

    return {
        generateConcreteLoadLiteData,
        error
    };
}

export default useGenerateConcreteLoadLite;
