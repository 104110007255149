import { ConsistencyTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';
import { useCallback } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useConsistencyTest() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();

    return useCallback(
        async (consistencyTest: ConsistencyTest, id?: string) => {
            return (
                id
                    ? api.freshConcreteClient.updateConsistencyTest(
                        projectId,
                        consistencyTest.orderId,
                        consistencyTest.concreteLoadId,
                        id,
                        consistencyTest,
                        companyId
                    )
                    : api.freshConcreteClient.createConsistencyTest(
                        projectId,
                        consistencyTest.orderId,
                        consistencyTest.concreteLoadId,
                        consistencyTest,
                        companyId
                    )
            )
                .then((response) => ({ success: response.isSuccess(), error: undefined }))
                .catch((err) => ({
                    success: false,
                    error:
                        err?.response?.data?.error?.errors?.[0]
                            ?.replace('"testingTime"', 'Testing time')
                            ?.replace('"ref:samplingTime"', 'sampling time') ?? err
                }));
        },
        [api, projectId, companyId]
    );
}

export default useConsistencyTest;
