import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { StructuralElement } from '../StructuralElementModal';

export function transformStructuralElement(
    structuralElement: StructuralElement
): CataloguedStructuralElementExtendedBP {
    if (structuralElement.hasOwnProperty('elementName')) {
        const concreteStructuralElement: ConcretedStructuralElementBP =
            structuralElement as ConcretedStructuralElementBP;
        return {
            ...concreteStructuralElement,
            structuralElementName: concreteStructuralElement.elementName,
            requiredChlorideContentClass: concreteStructuralElement.chlorideContent,
            requiredDensityClass: concreteStructuralElement.densityClass,
            requiredMethodOfConcreteUse: concreteStructuralElement.methodOfConcrete,
            requiredSlump: concreteStructuralElement.slump,
            minimalRequiredCompressiveStrength: concreteStructuralElement.compressiveStrength,
            massConcreteRequired: concreteStructuralElement.massConcrete,
            maximalAllowedSlumpVariance: concreteStructuralElement.slumpVariance,
            minimalRequiredXa: concreteStructuralElement.requiredXa,
            minimalRequiredXc: concreteStructuralElement.requiredXc,
            minimalRequiredXd: concreteStructuralElement.requiredXd,
            minimalRequiredXm: concreteStructuralElement.requiredXm,
            minimalRequiredXs: concreteStructuralElement.requiredXs,
            minimalRequiredXfFirst: concreteStructuralElement.requiredXfFirst,
            minimalRequiredXfSecond: concreteStructuralElement.requiredXfSecond,
            minimalRequiredCubicCompressiveStrength: concreteStructuralElement.cubicCompressiveStrength,
            minimalRequiredCylindricCompressiveStrength: concreteStructuralElement.cylindricCompressiveStrength,
            minimalRequiredMoistureClass: concreteStructuralElement.moistureClass,
            minimalRequiredStrengthDevelopment: concreteStructuralElement.strengthDevelopment,
            minimalRequiredTypeOfConcreteType: concreteStructuralElement.typeOfConcrete,
            isActive: undefined
        } as CataloguedStructuralElementExtendedBP;
    }
    return structuralElement as CataloguedStructuralElementExtendedBP;
}
