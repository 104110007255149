import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { Button, Space } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SortOrder } from 'antd/lib/table/interface';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { MARKETS } from 'src/app-react/constants/markets';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { TestSpecimenTableRecord } from './TestSpecimenTable';

export interface ITestSpecimenTableColumnsProps {
    onEdit: (testSpecimen: TestSpecimenTableRecord) => void;
    onDelete: (testSpecimen: TestSpecimenTableRecord) => void;
    onDetails: (testSpecimen: TestSpecimenTableRecord) => void;
    marketId: string;
}

type FixedType = boolean | 'right' | 'left';

export const testSpecimenTableColumns: (
    props: ITestSpecimenTableColumnsProps
) => ColumnsType<TestSpecimenTableRecord> = (props: ITestSpecimenTableColumnsProps) => {
    const { onEdit, onDelete, marketId } = props;
    const { isViewer } = useUserPermissions();

    return [
        {
            title: <Trans>Specimen identifier</Trans>,
            dataIndex: 'testSpecimenIdentifier',
            sorter: (a: TestSpecimenTableRecord, b: TestSpecimenTableRecord) => {
                return a.testSpecimenIdentifier.localeCompare(b.testSpecimenIdentifier);
            },
            render: (testSpecimenIdentifier: string) => <>{testSpecimenIdentifier}</>
        },
        {
            title: <Trans>Sampling time</Trans>,
            dataIndex: 'samplingTime',
            sorter: (a: TestSpecimenTableRecord, b: TestSpecimenTableRecord, sortOrder: SortOrder) => {
                const r = new Date(a.samplingTime).getTime() - new Date(b.samplingTime).getTime();
                if (r !== 0) return r;
                return sortOrder === 'ascend' ? a.testingAge - b.testingAge : b.testingAge - a.testingAge;
            },
            defaultSortOrder: 'ascend' as SortOrder,
            render: (samplingTime: Date) => (
                <Space direction="vertical">
                    <DateTimeFormatter date={samplingTime} />
                </Space>
            )
        },
        {
            title: <Trans>Testing age</Trans>,
            dataIndex: 'testingAge',
            sorter: (a: TestSpecimenTableRecord, b: TestSpecimenTableRecord) => {
                return a.testingAge - b.testingAge;
            },
            render: (testingAge: number) => (
                <>{testingAge === 1 ? `${testingAge} ${t`day`}` : `${testingAge} ${t`days`}`}</>
            )
        },
        {
            title: <Trans>Test type</Trans>,
            dataIndex: 'testType',
            sorter: (a: TestSpecimenTableRecord, b: TestSpecimenTableRecord) => {
                return a.testType.localeCompare(b.testType);
            },
            render: (testType: string) => <>{testType}</>
        },
        {
            title: <Trans>Test purpose</Trans>,
            dataIndex: 'testPurpose',
            sorter: (a: TestSpecimenTableRecord, b: TestSpecimenTableRecord) => {
                return a.testPurpose.localeCompare(b.testPurpose);
            },
            render: (testPurpose: string) => <>{testPurpose}</>
        },
        {
            title: <Trans>External inspection</Trans>,
            dataIndex: 'externalInspection',
            hidden: marketId !== MARKETS.DE,
            sorter: (a: TestSpecimenTableRecord, b: TestSpecimenTableRecord) => {
                return Number(a.externalInspection ? 1 : 0) - Number(b.externalInspection ? 1 : 0);
            },
            render: (externalInspection: boolean) => <>{externalInspection ? t`yes` : t`no`}</>
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            fixed: 'right' as FixedType,
            hidden: isViewer,
            width: 200,
            render: (id: string, record: TestSpecimenTableRecord, index: number) => (
                <Space direction="horizontal">
                    <Button data-testid="editButton" type="link" size="small" onClick={() => onEdit(record)}>
                        <EditOutlined /> <Trans>Edit</Trans>
                    </Button>

                    <Button data-testid="deleteButton" type="link" size="small" onClick={() => onDelete(record)}>
                        <DeleteOutlined /> <Trans>Delete</Trans>
                    </Button>
                </Space>
            )
        }
    ].filter((item) => !item.hidden);
};
