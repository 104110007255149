/* eslint-disable react/destructuring-assignment */ /* eslint-disable react/jsx-props-no-spreading */
import { Select } from 'antd';
import { t } from '@lingui/macro';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';

const typeOfConcreteTypeFieldName = ['compressiveStrengthClass', 'requiredTypeOfConcreteType', 'typeOfConcrete'];

function DensityClassFormItem(props: SelectFormItemProps) {
    const { densityClass = [] } = useProjectConstantsState();

    return (
        <SelectFormItem
            label={t`Density class`}
            name="densityClass"
            dependencies={[typeOfConcreteTypeFieldName]}
            rules={[
                (form) => ({
                    validator(_, value) {
                        if (!value && typeOfConcreteTypeFieldName.find((name) => form.getFieldValue(name) === 'LC')) {
                            return Promise.reject(new Error(t`Density class is required for LC`));
                        }

                        return Promise.resolve();
                    }
                })
            ]}
            {...props}>
            <Select
                allowClear={!props.required}
                placeholder={t`Please select densisty class`}
                options={densityClass}
                data-testid="densityClass"
            />
        </SelectFormItem>
    );
}

export default DensityClassFormItem;
