export const EXPOSURE_CLASS_XC = [
    { value: 1, label: 'XC1' },
    { value: 2, label: 'XC2' },
    { value: 3, label: 'XC3' },
    { value: 4, label: 'XC4' }
];

export const EXPOSURE_CLASS_XD = [
    { value: 1, label: 'XD1' },
    { value: 2, label: 'XD2' },
    { value: 3, label: 'XD3' }
];

export const EXPOSURE_CLASS_XF = [
    { value: '1', label: 'XF1' },
    { value: '2', label: 'XF2' },
    { value: '2/3', label: 'XF2/3' },
    { value: '3', label: 'XF3' },
    { value: '4', label: 'XF4' }
];

export const EXPOSURE_CLASS_XA = [
    { value: 1, label: 'XA1' },
    { value: 2, label: 'XA2' },
    { value: 3, label: 'XA3' }
];

export const EXPOSURE_CLASS_XM = [
    { value: 1, label: 'XM1' },
    { value: 2, label: 'XM2' },
    { value: 3, label: 'XM3' }
];

export const EXPOSURE_CLASS_XS = [
    { value: 1, label: 'XS1' },
    { value: 2, label: 'XS2' },
    { value: 3, label: 'XS3' }
];
