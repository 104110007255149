import { I18n, i18n, MessageDescriptor } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConfigProvider } from 'antd';
import antdCsCZ from 'antd/lib/locale/cs_CZ';
import antdDeDE from 'antd/lib/locale/de_DE';
import antdEnUK from 'antd/lib/locale/en_GB';
import antdEnUS from 'antd/lib/locale/en_US';
import antdEsES from 'antd/lib/locale/es_ES';
import antdPlPL from 'antd/lib/locale/pl_PL';

import { cs, de, en, es, hi, pl, zh } from 'make-plural/plurals';
import moment from 'moment';
import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/pl';
import React, { useEffect } from 'react';
import { messages as csTranslations } from '../locales/cs-CZ/translations';
import { messages as deTranslations } from '../locales/de-DE/translations';
import { messages as auTranslations } from '../locales/en-AU/translations';
import { messages as ukTranslations } from '../locales/en-UK/translations';
import { messages as enTranslations } from '../locales/en-US/translations';
import { messages as esTranslations } from '../locales/es-ES/translations';
import { messages as inTranslations } from '../locales/hi-IN/translations';
import { messages as plTranslations } from '../locales/pl-PL/translations';
import { messages as hkTranslations } from '../locales/zh-HK/translations';

import { detect, fromNavigator } from '@lingui/detect-locale';

export interface LanguageProps {
    changeLanguage: (language: Language) => void;
    getLanguage: () => string;
}

export const languages = ['en-UK', 'en-US', 'de-DE', 'cs-CZ', 'pl-PL', 'en-AU', 'zh-HK', 'hi-IN'] as const;

export type Language = (typeof languages)[number];

const DEFAULT_FALLBACK_LANG = 'en-UK';

const LanguageContext = React.createContext({} as LanguageProps);
type ProviderChildren = { children: React.ReactNode };

i18n.loadLocaleData({
    'en-UK': { plurals: en },
    'es-ES': { plurals: es },
    'de-DE': { plurals: de },
    'en-US': { plurals: en },
    'cs-CZ': { plurals: cs },
    'pl-PL': { plurals: pl },
    'en-AU': { plurals: en },
    'zh-HK': { plurals: zh },
    'hi-IN': { plurals: hi }
});

i18n.load({
    'en-UK': ukTranslations,
    'es-ES': esTranslations,
    'de-DE': deTranslations,
    'en-US': enTranslations,
    'cs-CZ': csTranslations,
    'pl-PL': plTranslations,
    'en-AU': auTranslations,
    'zh-HK': hkTranslations,
    'hi-IN': inTranslations
} as any);

function getAntdLocale(locale: string) {
    switch (locale) {
        case 'de-DE':
            return antdDeDE;
        case 'es-ES':
            return antdEsES;
        case 'en-US':
            return antdEnUS;
        case 'cs-CZ':
            return antdCsCZ;
        case 'pl-PL':
            return antdPlPL;
        default:
            return antdEnUK;
    }
}

function getMomentLocale(locale: string) {
    switch (locale) {
        case 'de-DE':
            return 'de';
        case 'es-ES':
            return 'es';
        case 'en-US':
            return 'en';
        case 'cs-CZ':
            return 'cs';
        case 'pl-PL':
            return 'pl';
        case 'en-AU':
            return 'en-au';
        case 'zh-HK':
            return 'hk';
        case 'hi-IN':
            return 'in';
        default:
            return 'en-gb';
    }
}

const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('phrase')) {
    initializePhraseAppEditor(
        {
            fullReparse: true,
            autoLowercase: false,
            prefix: '{{__',
            suffix: '__}}',
            projectId: 'dba12d9b8801c980d8a5d3a3232d67f1'
        },
        i18n
    );
}
async function changeLanguage(language: Language) {
    i18n.activate(language);
    localStorage.setItem('NexploreConcrete.preferredLanguage', language);
}

function getLanguage(): string {
    return localStorage.getItem('NexploreConcrete.preferredLanguage');
}

function LanguageProvider({ children }: ProviderChildren) {
    const storedLanguage: Language | null = getLanguage() as Language | null;

    let lang = storedLanguage || (detect(fromNavigator(), DEFAULT_FALLBACK_LANG) as Language);

    if (lang?.length === 2) {
        lang = languages.find((l) => l.substring(0, 2) === lang);
    }

    if (!languages.includes(lang)) lang = DEFAULT_FALLBACK_LANG;

    useEffect(() => {
        changeLanguage(lang);
    });

    return (
        <I18nProvider i18n={i18n} forceRenderOnLocaleChange>
            <AppConfigProvider>{children}</AppConfigProvider>
        </I18nProvider>
    );
}

function AppConfigProvider({ children }: ProviderChildren) {
    const value: LanguageProps = {
        changeLanguage,
        getLanguage
    };

    moment.locale(getMomentLocale(i18n.locale));
    return (
        <ConfigProvider locale={getAntdLocale(i18n.locale)}>
            <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>
        </ConfigProvider>
    );
}

export { LanguageContext, LanguageProvider };

function initializePhraseAppEditor(config: any, i18nInstance: I18n): void {
    i18nInstance._ = (id: string | MessageDescriptor): string => {
        let message: string;
        if (typeof id === 'string') {
            message = id;
        } else {
            message = id.id || 'translation id not found';
        }

        message = message.replace('<', '[[[[[[html_open]]]]]]').replace('>', '[[[[[[html_close]]]]]]');
        message = `${config.prefix}phrase_${message}${config.suffix}`;

        return message;
    };

    (window as any).PHRASEAPP_CONFIG = config;

    const phraseapp = document.createElement('script');
    phraseapp.type = 'text/javascript';
    phraseapp.async = true;
    phraseapp.src = `https://phraseapp.com/assets/in-context-editor/2.0/app.js?${new Date().getTime()}`;
    const s = document.getElementsByTagName('script')[0];
    if (s && s.parentNode) {
        s.parentNode.insertBefore(phraseapp, s);
    } else {
        document.insertBefore(phraseapp, null);
    }
}
