import { Form } from 'antd';
import { SUPPLIERS } from 'src/app-react/constants/suppliers';
import { SupplierConfigFormLayoutDefaultDE } from './components/Layouts/DefaultLayout';
import { SupplierConfigFormLayoutGodelDE } from './components/Layouts/GodelLayout';
import { SupplierConfigFormLayoutSimmaDE } from './components/Layouts/SimmaLayout';

export function SupplierConfigFormLayoutDE() {
    const form = Form.useFormInstance();
    const selectedSoftware = Form.useWatch('supplierSoftware', form);

    switch (selectedSoftware) {
    case SUPPLIERS.GODEL:
        return <SupplierConfigFormLayoutGodelDE />;
    case SUPPLIERS.SIMMA:
        return <SupplierConfigFormLayoutSimmaDE />;
    default:
        return <SupplierConfigFormLayoutDefaultDE />;
    }
}
