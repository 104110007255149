import { t, Trans } from '@lingui/macro';
import { Col, Form, Row } from 'antd';
import styles from '../../MultipleConcreteTestModal.module.css';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import { InputNumber } from 'src/app-react/components/Form';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export interface Props {
    showTitle?: boolean;
}

function WindSpeedTestForm({ showTitle = true }: Props) {
    return (
        <>
            {showTitle && (
                <>
                    <Row>
                        <label className={styles.title}>
                            <Trans>Wind Speed Test</Trans>
                        </label>
                    </Row>
                    <br />
                </>
            )}

            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="testingDate"
                        label={<Trans>Testing Date</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <DatePicker style={{ width: '100%' }} data-testid="testingDate" placeholder={t`Enter Date`} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="testingTime" label={<Trans>Testing Time</Trans>} rules={[requiredRule]}>
                        <TimePicker data-testid="testingTime" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="windSpeed"
                label={<Trans>Wind Speed</Trans>}
                rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^\d+$/),
                        message: t`This field is required`
                    }
                ]}>
                <InputNumber data-testid="windSpeed" addonAfter={t`kph`} placeholder={t`Enter the wind speed`} />
            </Form.Item>
        </>
    );
}

export default WindSpeedTestForm;
