import { t } from '@lingui/macro';
import { useContext, useEffect } from 'react';
import useConstructorNames from 'src/app-react/business-page/concreting-documentation/hooks/useConstructorNames';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import { mapFilters } from '../../util';
import { TableSearhFilter } from './TableSearchFilter';

export function ConstructorNamesInputItem() {
    const { loadConstructorNames, constructorNames, loading } = useConstructorNames();
    const { filter, setFilter } = useContext(ConcretingDocumentationContext);

    useEffect(() => {
        loadConstructorNames({ periodOfConcreting: filter?.deliveryTime });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.deliveryTime]);

    return (
        <TableSearhFilter
            placeholder={t`Search project name`}
            options={mapFilters(constructorNames)}
            onSearch={(query: string) => {
                loadConstructorNames({ query, periodOfConcreting: filter?.deliveryTime });
            }}
            onChange={(values: string[]) => {
                setFilter((prev: any) => ({
                    ...prev,
                    constructorName: values.length ? values : undefined
                }));
            }}
            loading={loading}
        />
    );
}
