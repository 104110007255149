import { Trans } from '@lingui/macro';
import { DeliveryNoteAdmixture } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Divider, Table } from 'antd';
import { useEffect } from 'react';
import { deliveryAdmixtureTableColumns } from './delivery-admixture-table.columns';

interface AdmixtureTable {
    concreteAdmixture: DeliveryNoteAdmixture[];
}

function DeliveryAdmixtureTable(props: AdmixtureTable) {
    const { concreteAdmixture } = props;

    useEffect(() => {}, [concreteAdmixture]);

    const keyMapFromTableData = (tableData: DeliveryNoteAdmixture[]) => {
        return tableData.map((value: DeliveryNoteAdmixture, key: number) => {
            return { ...value, key };
        });
    };

    return concreteAdmixture.length <= 0 ? null : (
        <>
            <h2>
                <Trans>Admixture on site</Trans>
            </h2>

            <Table
                data-testid="delivery-admixture-table"
                columns={deliveryAdmixtureTableColumns()}
                dataSource={keyMapFromTableData(concreteAdmixture)}
                pagination={false}
                rowClassName="delivery-admixture-row"
            />
            <Divider />
        </>
    );
}

export default DeliveryAdmixtureTable;
