import { DeleteOutlined, EditOutlined, EllipsisOutlined, FilterFilled } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { UserManagement } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { Dropdown, MenuProps, Tag } from 'antd';
import { IUserManagementFilters } from 'src/app-react/providers/UserManagementContext';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { UserEmailFilter } from './components/UserManagementFilters/UserEmailFilter';
import { UserNameFilter } from './components/UserManagementFilters/UserNameFilter';
import styles from './userManagement.module.css';

export const userManagementTableColumns = (
    marketId: string,
    editUser: (user: UserManagement) => void,
    deleteUser: (user: UserManagement) => void,
    filters: IUserManagementFilters
) => {
    const getActions = (record: UserManagement): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: <Trans>Edit user roles</Trans>,
            key: `edit-${record.userId}-${record.companyId}`,
            icon: <EditOutlined />,
            onClick: () => {
                editUser(record);
            }
        });

        items.push({
            label: <Trans>Remove user from project</Trans>,
            key: `remove-${record.userId}-${record.companyId}`,
            icon: <DeleteOutlined />,
            onClick: () => {
                deleteUser(record);
            }
        });

        return items;
    };

    const getRoleTag = (role: string) => {
        switch (role) {
            case 'ProjectManager':
                return (
                    <Tag color="blue">
                        <Trans>Project Manager</Trans>
                    </Tag>
                );
            case 'Foreman':
                return (
                    <Tag color="yellow">
                        <Trans>Foreman</Trans>
                    </Tag>
                );
            case 'ConcreteEngineer':
                return (
                    <Tag color="purple">
                        <Trans>Concrete Engineer</Trans>
                    </Tag>
                );
            case 'Subcontractor':
                return (
                    <Tag color="pink">
                        <Trans>Subcontractor</Trans>
                    </Tag>
                );
            case 'Batcher':
                return (
                    <Tag color="orange">
                        <Trans>Batcher</Trans>
                    </Tag>
                );
            case 'Viewer':
                return (
                    <Tag color="cyan">
                        <Trans>Viewer</Trans>
                    </Tag>
                );
            default:
                return undefined;
        }
    };

    const columns: ExtendedColumnType<UserManagement>[] = [
        {
            title: <Trans>User name</Trans>,
            key: 'userName',
            dataIndex: 'userName',
            sorter: (a, b) => (a.userName?.toLowerCase() < b.userName?.toLowerCase() ? -1 : a.userName?.toLowerCase() > b.userName?.toLowerCase() ? 1 : 0),
            render: (value) => value,
            filterDropdown: <UserNameFilter />,
            filterIcon: (
                <FilterFilled
                    style={{ color: filters?.userName != null && filters.userName !== '' ? '#1890ff' : undefined }}
                />
            )
        },
        {
            title: <Trans>Email address</Trans>,
            key: 'userEmail',
            dataIndex: 'userEmail',
            sorter: (a, b) => (a.userEmail?.toLowerCase() < b.userEmail?.toLowerCase() ? -1 : a.userEmail?.toLowerCase() > b.userEmail?.toLowerCase() ? 1 : 0),
            render: (value) => value,
            filterDropdown: <UserEmailFilter />,
            filterIcon: (
                <FilterFilled
                    style={{ color: filters?.userEmail != null && filters.userEmail !== '' ? '#1890ff' : undefined }}
                />
            )
        },
        {
            title: <Trans>Internal user</Trans>,
            hidden: marketId !== 'AUS',
            key: 'isUserIntern',
            dataIndex: 'isUserIntern',
            render: (value) => (value ? <Tag color="green">{t`Yes`}</Tag> : <Tag color="grey">{t`No`}</Tag>)
        },
        {
            title: <Trans>Project roles</Trans>,
            key: 'roles',
            dataIndex: 'roles',
            render: (value) => value?.map((role: string) => getRoleTag(role))
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            width: 50,
            render: (value, record) => (
                <div data-testid="dropdown-test">
                    <Dropdown trigger={['click']} menu={{ items: getActions(record) }}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                </div>
            )
        }
    ];

    return columns.filter((item) => !item.hidden);
};
