import { t, Trans } from '@lingui/macro';
import {
    CataloguedStructuralElementBPDE,
    CataloguedStructuralElementBPUS,
    ConcreteTypeExtendedBP,
    ConcreteTypesWithPaginationParamsBP,
    DataList,
    SimpleClientResponse
} from '@nexploretechnology/concreting-core-client';
import { Button, Form, Modal, SelectProps } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { FormDE } from './Forms/FormDE';
import { FormUS } from './Forms/FormUS';
import { generateCaSE, getInitialFormValues } from './utils';

export interface ICommonFieldStructuralElementForm {
    id?: string;
    name: string;
    elementDescription?: string;
}

export interface IFieldsStructuralElementFormDE {
    boqPositions?: string[];
    planNumber?: string;

    typeOfConcrete?: string;
    cylindricCompressiveStrength?: number;
    cubicCompressiveStrength?: number;

    moistureClass?: string;
    requiredX0?: boolean;
    requiredXc?: string | number;
    requiredXd?: string | number;
    requiredXf?: string;
    requiredXa?: string | number;
    requiredXm?: string | number;
    requiredXs?: string | number;
    methodConcreteUseChloride?: string;
    methodOfConcrete?: string;
    chlorideContent?: string;
    strengthDevelopment?: number;
    densityClass?: string;
    externalInspection?: boolean;
    concreteTypeIds?: string[];
}

export interface IFieldsStructuralElementFormUS {
    methodOfConcrete?: string;
    planNumber?: string;

    slump?: string;
    slumpVariance?: string;
    massConcrete?: boolean;
    compressiveStrength: number;
    costCode?: string;

    concreteTypeIds?: string[];
}

export interface IStructuralElementDE extends ICommonFieldStructuralElementForm, IFieldsStructuralElementFormDE {}
export interface IStructuralElementUS extends ICommonFieldStructuralElementForm, IFieldsStructuralElementFormUS {}

export type TSearchResultsState = SelectProps<object>['options'];

export interface IOptions {
    value: string;
    label: ReactElement;
}

function StructuralElementModalForm(props: {
    open: boolean;
    initialValues?: IStructuralElementDE | IStructuralElementUS;
    action: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}) {
    const { open, initialValues, action, setOpen, onComplete } = props;

    const { projectId, companyId, marketId } = useProjectState();

    const api = useApi();

    const [structuralElementForm] = Form.useForm();
    const [boqPositions, setBoqPositions] = useState<string[]>([]);
    const [getAssignableConcreteTypes, setAssignableConcreteTypes] = useState<ConcreteTypeExtendedBP[]>([]);

    const loadAssignableConcreteTypes = async (): Promise<void> => {
        setAssignableConcreteTypes(await getAssignableConcreteTypesOptions());
    };

    useEffect(() => {
        loadAssignableConcreteTypes();
    }, [open, projectId, companyId]);

    const getAssignableConcreteTypesOptions = async (): Promise<ConcreteTypeExtendedBP[]> => {
        const parameters: ConcreteTypesWithPaginationParamsBP = {
            pagination: {
                skip: 0,
                limit: 1000,
                sorting: []
            }
        };

        return api.catalogueManagementClient
            .getExtendedConcreteTypesForProjectAsDataList(companyId, projectId, parameters)
            .then((response: SimpleClientResponse<DataList<ConcreteTypeExtendedBP>>) => {
                if (response.isSuccess()) {
                    const assignableConcreteTypes: ConcreteTypeExtendedBP[] = response.getEntity().data;
                    return assignableConcreteTypes;
                }
                ErrorNotification({
                    message: response.getError(),
                    description: ''
                });

                return [];
            });
    };

    useEffect(() => {
        if (!initialValues) return;
        const initialValuesDE: IStructuralElementDE = initialValues as IStructuralElementDE;
        if (initialValuesDE.boqPositions) {
            setBoqPositions(initialValuesDE.boqPositions);
        } else {
            setBoqPositions([]);
        }

        const formValues = getInitialFormValues(marketId, initialValues);
        structuralElementForm.setFieldsValue(formValues);
    }, [initialValues]);

    const createOrDuplicateCaSE = async (
        payload: CataloguedStructuralElementBPDE | CataloguedStructuralElementBPUS
    ): Promise<void> => {
        await api.catalogueManagementClient
            .createCataloguedStructuralElement(companyId, projectId, payload)
            .then((response: SimpleClientResponse<{ id: string }>) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Catalogue structural element created successfully`,
                        description: ''
                    });

                    structuralElementForm.resetFields();

                    onComplete?.();
                    setOpen(false);
                } else if (
                    (response?.httpResponse?.data as any)?.error?.code ===
                    'DUPLICATED_CATALOGUED_STRUCTURAL_ELEMENT_ERROR'
                ) {
                    ErrorNotification({
                        message: t`This structural element already exists.`,
                        description: ''
                    });
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    const editCaSE = async (
        payload: CataloguedStructuralElementBPDE | CataloguedStructuralElementBPUS
    ): Promise<void> => {
        if (payload.id) {
            await api.catalogueManagementClient
                .updateCataloguedStructuralElement(companyId, projectId, payload.id, payload)
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`Catalogue structural element updated successfully`,
                            description: ''
                        });

                        structuralElementForm.resetFields();

                        onComplete?.();
                        setOpen(false);
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }
    };

    const executeAction = async (
        payload: CataloguedStructuralElementBPDE | CataloguedStructuralElementBPUS
    ): Promise<void> => {
        if (action === 'create' || action === 'duplicate') {
            await createOrDuplicateCaSE(payload);
        } else if (action === 'edit' && payload.id) {
            await editCaSE(payload);
        }
    };

    const onSubmit = async (): Promise<void> => {
        try {
            await structuralElementForm
                .validateFields()
                .then(async (values) => {
                    if (initialValues && initialValues.id) {
                        values.id = initialValues.id;
                    }

                    await executeAction(generateCaSE(projectId, companyId, marketId, values));
                })
                .catch((info) => {
                    ErrorNotification({ message: info.errorFields[0].errors[0], description: '' });
                });
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    const onCancel = (): void => {
        structuralElementForm.resetFields();
        setOpen(false);
    };

    const displayTitleForm = (): JSX.Element => {
        switch (action) {
            case 'create':
                return (
                    <label data-testid="title-form-label-create">
                        <Trans>Add catalogue structural element</Trans>
                    </label>
                );
            case 'duplicate':
                return (
                    <label data-testid="title-form-label-duplicate">
                        <Trans>Duplicate catalogue structural element</Trans>
                    </label>
                );
            case 'edit':
                return (
                    <label data-testid="title-form-label-edit">
                        <Trans>Edit catalogue structural element</Trans>
                    </label>
                );
            default:
                return <></>;
        }
    };
    const displayTextOkButton = (): JSX.Element => {
        switch (action) {
            case 'create':
            case 'duplicate':
                return (
                    <label data-testid="okButton-label">
                        <Trans>Add structural element</Trans>
                    </label>
                );
            case 'edit':
                return (
                    <label data-testid="okButton-label">
                        <Trans>Update structural element</Trans>
                    </label>
                );
            default:
                return <></>;
        }
    };

    return (
        <Modal
            maskClosable={false}
            data-testid={`structural-element-modal-form-${action}`}
            forceRender
            title={displayTitleForm()}
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button data-testid="cancelButton" key="cancelButton" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    onClick={onSubmit}
                    data-testid="structural-element-submit"
                    type="primary"
                    key="structural-element-submit-btn">
                    {displayTextOkButton()}
                </Button>
            ]}>
            <Form
                layout="vertical"
                colon={false}
                form={structuralElementForm}
                data-testid="structuralElementForm"
                key={`structural-modal-form-${projectId}`}
                initialValues={{ initialValues }}>
                {marketId === MARKETS.DE ? (
                    <FormDE boqPositions={boqPositions} concreteTypes={getAssignableConcreteTypes} />
                ) : (
                    <FormUS concreteTypes={getAssignableConcreteTypes} />
                )}
            </Form>
        </Modal>
    );
}

export default StructuralElementModalForm;
