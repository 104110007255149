import { ConcretingDeliveryNoteDocumentation } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import moment from 'moment';

export interface ConcretingDocumentationParsedAUSData {
    supplierName: string;
    supplierOrderId: string;
    constructorName: string;
    deliveryNoteNumber: string;
    deliveryTime: Date;
    loadQuantity: number;
    timeArrived: Date;
    totalTimeOnSite: number;
    timeFinish: Date;
    number: string;
    compressiveStrength: number;
    proyectId: string;
    orderId: string;
    concreteLoadId: string;
    deliveredQuantity: number;
    externalReference: string;
}

export const parseConcretingDocumentationTable = (data: ConcretingDeliveryNoteDocumentation['data']) => {
    return data.map((record) => {
        const parsedData: ConcretingDocumentationParsedAUSData = {
            supplierName: record.supplierConcreteLoadData?.supplierName,
            supplierOrderId: record.concreteOrder?.supplierOrderId,
            constructorName: record.concreteOrder?.constructorName,
            deliveryNoteNumber: record.supplierConcreteLoadData?.deliveryNoteNumber,
            deliveryTime:
                record?.supplierConcreteLoadData?.loadingStart ??
                record?.concreteLoad?.arrivalAtConstructionSite ??
                record.concreteOrder?.deliveryTime,
            loadQuantity: record.supplierProducedConcrete?.volume,
            timeArrived: record.concreteLoad.arrivalAtConstructionSite,
            totalTimeOnSite: record.concreteLoad.unloadingEnd
                ? Math.abs(
                      moment(record.concreteLoad.arrivalAtConstructionSite).diff(
                          moment(record.concreteLoad.unloadingEnd),
                          'minutes'
                      )
                  )
                : undefined,
            timeFinish: record.concreteLoad.unloadingEnd,
            number: record.supplierProducedConcrete?.number,
            compressiveStrength: record.supplierProducedConcrete?.compressiveStrength,
            proyectId: record.concreteLoad.projectId,
            orderId: record.concreteLoad.orderId,
            concreteLoadId: record.concreteLoad.id,
            deliveredQuantity: record.concretedLoad?.volume,
            externalReference: record.concreteOrder?.externalReference
        };

        return parsedData;
    });
};
