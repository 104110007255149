import { Trans, t } from "@lingui/macro";
import { Select } from "antd";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";

export function PurposeOfTestingFormItem() {
    return <SelectFormItem
        name="purposeOfTesting"
        label={<Trans>Purpose Of Testing</Trans>}
        required={true}
    >
        <Select data-testid="purposeOfTesting" placeholder={t`Select the purpose of testing`}>
            <Select.Option key="qualityTest" value="qualityTest">
                {t`Quality test`}
            </Select.Option>
            <Select.Option key="hardeningTest" value="hardeningTest">
                {t`Hardening test`}
            </Select.Option>
            <Select.Option key="initialTest" value="initialTest">
                {t`Initial test`}
            </Select.Option>
            <Select.Option key="other" value="other">
                {t`Other`}
            </Select.Option>
        </Select>
    </SelectFormItem>
}