import { TestSpecimenBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto';
import { useCallback } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useTestSpecimen() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();

    return useCallback(
        async (testSpecimen: TestSpecimenBP, id?: string) => {
            return (
                id
                    ? api.testSpecimenClient.updateTestSpecimen(
                        projectId,
                        testSpecimen.orderId,
                        testSpecimen.concreteLoadId,
                        id,
                        testSpecimen,
                        companyId
                    )
                    : api.testSpecimenClient.createTestSpecimen(
                        projectId,
                        testSpecimen.orderId,
                        testSpecimen.concreteLoadId,
                        testSpecimen,
                        companyId
                    )
            )
                .then((response) => ({ success: response.isSuccess(), error: undefined }))
                .catch((err) => {
                    const error = err?.response?.data?.error;
                    return {
                        success: false,
                        error:
                            error?.errors?.[0]
                                ?.replace('"testingTime"', 'Testing time')
                                ?.replace('"ref:samplingTime"', 'sampling time') ??
                            error?.code ??
                            error
                    };
                });
        },
        [api, projectId, companyId]
    );
}

export default useTestSpecimen;
