// Import of the ant styles should be load always at first place
import './styles/styles.less';
import './app-react/business-page/delivery-note-details/components/accept-and-sign-delivery/AcceptAndSignModal.css';
import './app-react/components/FeedbackButton/FeedbackButton.css';

import ReactDOM from 'react-dom';
import App from './app-react/app';

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
