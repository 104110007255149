import { SupplierNameWithBusinessNumber } from '@nexploretechnology/concreting-core-client';
import { useCallback, useState } from 'react';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGetSupplierNameAndBusinessNumber() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [dropDownData, setDropdownData] = useState<ValuesDropDown[]>([]);
    const [supplierBusinessData, setSupplierBusinessData] = useState<SupplierNameWithBusinessNumber[]>([]);

    function mapDropdown(data: void | SupplierNameWithBusinessNumber[]) {
        if (data) {
            const dropdownValues = data.map((x) => ({
                label: `${x.supplierBusinessNumber} (${x.supplierName})`,
                value: `${x.supplierBusinessNumber},${x.supplierName}`
            }));

            setDropdownData(dropdownValues);
            setSupplierBusinessData(data);
        }
    }
    const loadSupplierBusinessData = useCallback(async () => {
        return (
            api.concreteLoadClient
                .getSupplierNameAndBusinessNumber(companyId, projectId)
                .then((response) => mapDropdown(response.getEntity()))
                // eslint-disable-next-line no-shadow
                .catch((error: any) => {
                    setError({ message: error.code, description: error.message });
                })
        );
    }, [api, companyId, projectId]);

    return {
        loadSupplierBusinessData,
        dropDownData,
        supplierBusinessData,
        error
    };
}

export default useGetSupplierNameAndBusinessNumber;
