/* eslint-disable react/jsx-props-no-spreading */

import { useMemo } from 'react';
import { Button, ButtonProps } from 'antd';
import throttle from 'lodash/throttle';

interface ThrottleButtonProps extends ButtonProps {
    throttleNumber?: number;
}

function ThrottleButton({ onClick, throttleNumber, ...props }: ThrottleButtonProps) {
    const debouncedHandleClick = useMemo(() => throttle(onClick, throttleNumber), [onClick, throttleNumber]);

    return <Button onClick={debouncedHandleClick} {...props} />;
}

ThrottleButton.defaultProps = {
    throttleNumber: 3000
};

export default ThrottleButton;
