/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import { InputNumber } from 'src/app-react/components/Form';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function VolumeFormItem(props: FormItemInputProps) {
    const { units } = useProjectConstantsState();

    return (
        <Form.Item label={t`Quantity`} name="volume" rules={[requiredRule, numberRule({ minOrEqual: 0 })]} {...props}>
            <InputNumber placeholder={t`Enter quantity`} data-testid="volume" addonAfter={t`${units.volume}`} />
        </Form.Item>
    );
}

export default VolumeFormItem;
