import { Trans } from '@lingui/macro';

const CHLORIDE_CONTENT = [
    {
        value: 'Cl 0,20',
        label: <Trans>Cl 0,20</Trans>
    },
    {
        value: 'Cl 0,40',
        label: <Trans>Cl 0,40</Trans>
    },
    {
        value: 'Cl 1,00',
        label: <Trans>Cl 1,00</Trans>
    }
];

export default CHLORIDE_CONTENT;
