import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { useContext } from 'react';
import { PostTreatmentContext } from '../../../../contexts/PostTreatmentContext';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export function StartingDateFormItem() {
    const { concretingEndDate } = useContext(PostTreatmentContext);
    const form = Form.useFormInstance();

    function disabledDateHandler(current: moment.Moment | null): boolean {
        if (!current) {
            return false;
        }

        const concretingEndDateMoment: moment.Moment = moment(concretingEndDate);
        const postTreatmentType: string = form.getFieldValue('types');

        if (postTreatmentType && postTreatmentType === 'keptInMoulds') {
            return current.isBefore(concretingEndDateMoment, 'day') || current.isAfter(concretingEndDateMoment, 'day');
        }

        return current.isBefore(concretingEndDateMoment, 'day');
    }

    function postTreatmentStartingDateValidator(
        rule: RuleObject,
        date: moment.Moment,
        callback: (error?: string) => void
    ) {
        const postTreatmentType: string = form.getFieldValue('types');

        if (date === undefined || date === null) {
            return callback(rule.message as string);
        }
        if (postTreatmentType && postTreatmentType === 'keptInMoulds') {
            const concretingEndDateMoment: moment.Moment = moment(concretingEndDate);
            if (date.isBefore(concretingEndDateMoment, 'day') || date.isAfter(concretingEndDateMoment, 'day')) {
                return callback(rule.message as string);
            }
        }

        return callback();
    }

    function onChange(current: moment.Moment | null) {
        current?.startOf('day');
    }

    return (
        <FormItem
            name="postTreatmentStart"
            label={
                <b>
                    <Trans>Starting Date</Trans>
                </b>
            }
            style={{ gap: '.2rem' }}
            rules={[
                {
                    required: true,
                    message: <Trans>Please select a valid starting date</Trans>,
                    validator: postTreatmentStartingDateValidator
                }
            ]}>
            <DatePicker
                onChange={onChange}
                style={{ width: '100%' }}
                data-testid="postTreatmentStart"
                value={moment()}
                placeholder={t`Select starting date`}
                disabledDate={disabledDateHandler}
            />
        </FormItem>
    );
}
