/* eslint-disable react/require-default-props */

import { Trans } from '@lingui/macro';
import { Divider, Table } from 'antd';
import { useEffect } from 'react';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useSupplierConcreteAccumulativeVolume from '../../hooks/useSupplierConcreteAccumulativeVolume';
import useSupplierConcreteTotalVolume from '../../hooks/useSupplierConcreteTotalVolume';
import styles from '../shared/shared.module.css';
import orderFormInformationColumnsDE from './orderFormInformationColumnDE';
import orderFormInformationColumnsAUS from './orderFormInformationColumnsAUS';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';

interface Props {
    deliveryDetails?: DeliveryNoteFullDetails;
}

function OrderFormInformationTable({ deliveryDetails }: Props) {
    const { units } = useProjectConstantsState();
    const { marketId } = useProjectState();

    const { volumeData: totalVolumeData, loadData: totalLoadData } = useSupplierConcreteTotalVolume({
        orderId: deliveryDetails?.concreteOrder?.id
    });
    const { volumeData: accumulativeVolumeData, loadData: accumulativeLoadData } =
        useSupplierConcreteAccumulativeVolume({
            orderId: deliveryDetails?.concreteOrder?.id,
            concreteLoadId: deliveryDetails?.concreteLoad.id
        });

    useEffect(() => {
        if (!deliveryDetails) return;
        if (marketId === MARKETS.AUS) {
            accumulativeLoadData();
        } else {
            totalLoadData();
        }
    }, [deliveryDetails, marketId, accumulativeLoadData, totalLoadData]);

    if ((!deliveryDetails && !totalVolumeData) || (!deliveryDetails && !accumulativeVolumeData)) return <></>;

    return (
        <>
            <p className={styles.tableTitle}>
                {marketId === MARKETS.AUS ? (
                    <Trans>Supplier Order / Job Number information</Trans>
                ) : (
                    <Trans>Order Form Information</Trans>
                )}
            </p>
            <Table
                rowClassName="order-form-information-table-row"
                bordered={false}
                data-testid="order-form-information-table"
                scroll={{ x: 1 }}
                columns={
                    marketId === MARKETS.AUS
                        ? orderFormInformationColumnsAUS({ units, accumulativeVolumeData })
                        : orderFormInformationColumnsDE({ units, totalVolumeData })
                }
                dataSource={[deliveryDetails]}
                pagination={false}
            />
            <Divider />
        </>
    );
}

export default OrderFormInformationTable;
