import { useEffect } from 'react';
import ErrorNotification from '../../../../components/Notification/ErrorNotification';
import useOrderDependenciesState from '../../hooks/useOrderDependenciesState';

export default function OrderDependenciesError(): null {
    const { dependenciesError } = useOrderDependenciesState();

    useEffect(() => {
        if (!dependenciesError) {
            return;
        }

        const error = dependenciesError as unknown as any;
        const message = error?.error?.message || error?.message || 'server error';

        ErrorNotification({
            message: message,
            description: ''
        });
    }, [dependenciesError]);

    return null;
}
