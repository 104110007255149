import { Trans } from "@lingui/macro";
import { Form, Select } from "antd";
import { formatCompressiveStrengthUS, formatDescription, formatPercentageByLocale, formatSlump } from "src/app-react/business-page/catalogue-management/utils";
import { mixDesignItemTagRender } from "../utils";
import { i18n } from "@lingui/core";
import { useMemo } from "react";
import { ConcreteTypeExtendedBP } from "@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto";

const { Option } = Select;


interface AssignMixDesignItemUSProps {
    style?: React.CSSProperties;
    concreteTypes: ConcreteTypeExtendedBP[];
}

export function AssignMixDesignToItem({ concreteTypes }: AssignMixDesignItemUSProps) {
    const options = useMemo(() => {
        if (!concreteTypes.length) {
            return <Option value={'No results available'} disabled={true}>
                <span>
                    <Trans>No results available</Trans>
                </span>
            </Option>
        }

        return concreteTypes.map((concreteType: ConcreteTypeExtendedBP) => 
            <Option
                key={concreteType.id}
                value={concreteType.id}
                label={concreteType.concreteTypeNumber}>
                <div data-testid="field">
                    <span>
                        <b>{concreteType.concreteTypeNumber}</b>
                        {' ('}
                        {[
                            formatCompressiveStrengthUS(
                                concreteType.compressiveStrength,
                                concreteType.compressiveStrengthUnit
                            ),
                            formatSlump(
                                concreteType.slump,
                                concreteType.slumpVariance,
                                concreteType.slumpUnit
                            ),
                            formatPercentageByLocale(
                                concreteType.airContentInPercent,
                                i18n.locale
                            ),
                            formatDescription(concreteType.concreteTypeDescription)
                        ]
                            .filter((x) => x !== '')
                            .join(', ')}
                        {')'}
                    </span>
                </div>
            </Option>
        )


    }, [concreteTypes]);

    return (
        <>
            <Form.Item
                label={<Trans>Assign concrete type(s) to structural element</Trans>}
                name="concreteTypeIds"
            >
                <Select
                    mode="multiple"
                    optionFilterProp="label"
                    optionLabelProp="label"
                    showArrow
                    tagRender={mixDesignItemTagRender}
                    style={{ width: '100%' }}
                    data-testid="concreteTypeIds">
                    {options}
                </Select>
            </Form.Item>
        </>
    );
};