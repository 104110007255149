import { t } from '@lingui/macro';
import { Steps } from 'antd';
import { useEffect, useState } from 'react';
import { timeFormatter } from 'src/app-react/utils/formatters';
import { DeliveryDashboardExpandedInfoProps } from '../..';
import { DeliveryDashboardRejectInfo } from '../DeliveryDashboardRejectInfo';
const { Step } = Steps;

export function DeliveryDashboardStatusInfo(props: DeliveryDashboardExpandedInfoProps) {
    const { record } = props;
    const [stateNumber, setStateNumber] = useState<number>(0);

    function evaluateState() {
        if (record.signedOn) {
            setStateNumber(5);
        } else if (record.unloadingEnd) {
            setStateNumber(4);
        } else if (record.unloadingStart) {
            setStateNumber(3);
        } else if (record.arrivalAtConstructionSite) {
            setStateNumber(2);
        } else {
            setStateNumber(1);
        }
    }

    useEffect(() => {
        evaluateState();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Steps current={stateNumber}>
                <Step
                    title={t`Delivery scheduled`}
                    description={record.createdOn ? timeFormatter(record.createdOn, { timeStyle: 'short' }) : '-'}
                />
                <Step
                    title={t`Arrival at site`}
                    description={
                        record.arrivalAtConstructionSite
                            ? timeFormatter(record.arrivalAtConstructionSite, { timeStyle: 'short' })
                            : '-'
                    }
                />
                <Step
                    title={t`Unloading start`}
                    description={record.unloadingStart ? timeFormatter(record.unloadingStart, { timeStyle: 'short' }) : '-'}
                />
                <Step
                    title={t`Unloading end`}
                    description={record.unloadingEnd ? timeFormatter(record.unloadingEnd, { timeStyle: 'short' }) : '-'}
                />
                <Step
                    title={t`Delivery completed`}
                    description={record.signedOn ? timeFormatter(record.signedOn, { timeStyle: 'short' }) : '-'}
                />
            </Steps>
            <hr style={{ border: '1px solid #f0f0f0' }} />
            {record.rejectedOn && <DeliveryDashboardRejectInfo record={record} />}
        </>
    );
}
