import { CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { Direction } from '@nexploretechnology/bcqd-client';
import {
  Filtering,
  OffsetPagination,
  OrderFlowState,
  OrderOverviewBP,
  OrderOverviewOrderedConcreteType,
  OrderPage
} from '@nexploretechnology/concreting-core-client';

import { Button, Modal, Table, TablePaginationConfig, Tag } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useEffect, useRef, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ConcreteTypeModal from 'src/app-react/components/Modals/ConcreteTypeModal/ConcreteTypeModal';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { storedOrderNumber } from 'src/app-react/utils/lib';
import CancelOrderDetailsModal from '../CancelOrderDetailsModal/CancelOrderModalDetails';
import CancelOrderModal from '../CancelOrderModal/CancelOrderModal';
import OrderDetailModal from '../OrderDetailsModal/MonitoringOrderDetailModal';
import { OrderMonitoringColumns } from './OrderMonitoringTable.columns';
import styles from './OrderMonitoringTable.module.css';

const defaultPageSize = 10;
const pageIndex = 1;

export interface OrderQueryParamsForOrderMonitoring {
    index: number;
    size: number;
    sorting?: Partial<Record<keyof OrderOverviewBP, Direction>>[];
}

function OrderMonitoringTable(props: {
    tabStatus: string;
    refreshCounter?: (countElements: OrderPage) => void;
    reLoad?: boolean;
    setReLoad?: (value: boolean) => void;
}) {
    const { refreshCounter, tabStatus, reLoad, setReLoad } = props;
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    const navigate: NavigateFunction = useNavigate();
    const [orders, setOrders] = useState([] as OrderOverviewBP[]);

    const [detailsModalVisible, setDetailsModalVisible] = useState(false);
    const [cancelOrderModalVisible, setCancelOrderModalVisible] = useState(false);
    const [cancelOrderDetailsModalVisible, setCancelOrderDetailsModalVisible] = useState(false);
    const [statusToShowCancelOrderDetails, setStatusToShowCancelOrderDetails] = useState({} as OrderFlowState);

    const [concreteTypeModalVisible, setConcreteTypeModalVisible] = useState(false);
    const [orderToEDitToShow, setOrderToEDitToShow] = useState({} as OrderOverviewBP);
    const [concreteTypeToShow, setConcreteToShow] = useState({} as OrderOverviewOrderedConcreteType);

    const [filterOrderNumber, setFilterOrderNumber] = useState('');
    const [filterConcreteType, setFilterConcreteType] = useState('');
    const [filterStructuralElement, setFilterStructuralElement] = useState('');

    const filterOrderNumberValue = useRef('');
    const filterConcreteTypeValue = useRef('');
    const filterStructuralElementValue = useRef('');
    const { confirm } = Modal;

    const filterOrderNumberClicked = async () => {
        filterOrderNumberValue.current = filterOrderNumber;
        await loadOrders();
    };

    const filterConcreteTypeClicked = async () => {
        filterConcreteTypeValue.current = filterConcreteType;
        await loadOrders();
    };

    const filterStructuralElementClicked = async () => {
        filterStructuralElementValue.current = filterStructuralElement;
        await loadOrders();
    };

    const resetStructuralElementFilter = async () => {
        filterStructuralElementValue.current = '';
        setFilterStructuralElement('');
        await loadOrders();
    };

    const resetConcreteTypeFilter = async () => {
        filterConcreteTypeValue.current = '';
        setFilterConcreteType('');
        await loadOrders();
    };

    const resetOrderNumberFilter = async () => {
        filterOrderNumberValue.current = '';
        setFilterOrderNumber('');
        await loadOrders();
    };

    const displayFilterModal = () => {
        confirm({
            wrapClassName: 'filterModal',
            title: t`Clear all filters`,
            icon: <ExclamationCircleOutlined />,
            content: t`Do you want to clear all active filters?`,
            okText: t`Clear all filters`,
            okType: 'primary',
            okButtonProps: { danger: true, className: 'cleanFilterModalBtn' },
            cancelText: 'Cancel',
            onOk() {
                resetAllFilters();
            },
            onCancel() {}
        });
    };

    const resetAllFilters = async () => {
        filterStructuralElementValue.current = '';
        filterConcreteTypeValue.current = '';
        filterOrderNumberValue.current = '';
        setFilterOrderNumber('');
        setFilterStructuralElement('');
        setFilterConcreteType('');
        await loadOrders();
    };

    const [pagination, setPagination] = useState({
        position: ['bottomRight'],
        defaultPageSize,
        total: 0
    } as TablePaginationConfig);

    const orderQueryParams = useRef({
        index: (pageIndex - 1) * defaultPageSize,
        size: defaultPageSize,
        sorting: []
    } as OrderQueryParamsForOrderMonitoring);

    useEffect(() => {
        loadOrders();
        const interval = setInterval(() => {
            loadOrders();
        }, 20001);

        return () => clearInterval(interval);
    }, [projectId]);

    useEffect(() => {
        loadOrders();
    }, [reLoad]);

    const loadOrders = async (): Promise<void> => {
        const pagination: OffsetPagination<OrderOverviewBP> = {
            skip: orderQueryParams.current.index,
            limit: orderQueryParams.current.size,
            sorting: orderQueryParams.current.sorting
        };
        if (!pagination.sorting || pagination.sorting.length === 0) {
            switch (tabStatus) {
                case 'Active':
                case 'Open':
                    pagination.sorting.push({ deliveryTime: 'asc' });
                    break;
                case 'Completed':
                case 'Canceled':
                    pagination.sorting.push({ deliveryTime: 'desc' });
                    break;
                default:
                    break;
            }
        }

        const filters: Filtering[] = [
            { name: 'orderNumber', value: filterOrderNumberValue.current || '' },
            { name: 'orderedConcreteTypeNumber', value: filterConcreteTypeValue.current || '' },
            { name: 'structuralElementName', value: filterStructuralElementValue.current || '' }
        ];

        await api.orderMonitoringClient
            .loadOrdersOverview(projectId, tabStatus, companyId, 'trash-token-to-remove', pagination, filters)
            .then((response) => {
                if (response.isSuccess()) {
                    const result = response.getEntity();
                    setOrders(result.data);
                    checkLoadCountOrder(result.count);
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    const getTotalForTable = (countElements: OrderPage): number => {
        switch (tabStatus) {
            case 'Active':
                return countElements.activeTotal;
            case 'Open':
                return countElements.openTotal;
            case 'Completed':
                return countElements.completedTotal;
            case 'Canceled':
                return countElements.canceledTotal;
            default:
                return 0;
        }
    };

    const loadCountOrders = async (): Promise<void> => {
        await api.orderMonitoringClient
            .loadCountOrders(projectId, companyId)
            .then((response) => {
                if (response.isSuccess()) {
                    const countElements: OrderPage = response.getEntity();
                    refreshCounter?.(countElements);

                    setPagination({
                        showSizeChanger: true,
                        position: ['bottomRight'],
                        defaultPageSize,
                        total: getTotalForTable(countElements)
                    });
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    const checkLoadCountOrder = async (loadedOrdersCount: number): Promise<void> => {
        if (
            filterOrderNumberValue.current === '' &&
            filterConcreteTypeValue.current === '' &&
            filterStructuralElementValue.current === ''
        ) {
            await loadCountOrders();
        } else {
            setPagination({
                showSizeChanger: true,
                position: ['bottomRight'],
                defaultPageSize,
                total: loadedOrdersCount
            });
        }
    };

    function keyMapFromTableData(tableData: OrderOverviewBP[]): OrderOverviewBP[] {
        return tableData.map((value: OrderOverviewBP, key: number) => {
            return { ...value, key };
        });
    }

    function onEdit(orderId: string): void {
        navigate(`/projects/${projectId}/orders/${orderId}/edit`);
    }

    function onDuplicate(orderId: string): void {
        navigate(`/projects/${projectId}/orders/${orderId}/duplicate`);
    }

    function goToConcreteLoad(orderId: string): void {
        storedOrderNumber();
        navigate(`/projects/${projectId}/orders/${orderId}/delivery-notes-details`);
    }

    function showCancelModal(order: OrderOverviewBP): void {
        setOrderToEDitToShow(order);
        setCancelOrderModalVisible(true);
        setReLoad(false);
    }

    function showCancelOrderDetailsModal(order: OrderOverviewBP, status: OrderFlowState): void {
        setOrderToEDitToShow(order);
        setStatusToShowCancelOrderDetails(status);
        setCancelOrderDetailsModalVisible(true);
    }

    function showDetailsModal(order: OrderOverviewBP): void {
        setOrderToEDitToShow(order);
        setDetailsModalVisible(true);
    }

    function hideDetailsModal(): void {
        setDetailsModalVisible(false);
    }
    async function showConcreteType(orderedConcreteType: OrderOverviewOrderedConcreteType): Promise<void> {
        setConcreteToShow(orderedConcreteType);
        setConcreteTypeModalVisible(true);
    }

    function hideCancelOrderDetailsModal(): void {
        setCancelOrderDetailsModalVisible(false);
    }

    async function onChange(
        _pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<OrderOverviewBP> | SorterResult<OrderOverviewBP>[],
        extra: TableCurrentDataSource<OrderOverviewBP>
    ) {
        sorter = sorter as SorterResult<OrderOverviewBP>;

        let sorting: Partial<Record<keyof OrderOverviewBP, Direction>> | undefined;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
            const sortingValue = `{"${(sorter as SorterResult<OrderOverviewBP>).columnKey}": "${
                sortDirection || 'DESC'
            }"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OrderQueryParamsForOrderMonitoring = {
            index: _pagination.current! === 0 ? 0 : (_pagination.current! - 1) * _pagination.pageSize!,
            size: _pagination.pageSize!,
            sorting: sorting ? [sorting!] : []
        };

        orderQueryParams.current = queryParams;
        await loadOrders();
    }

    return (
        <>
            {filterOrderNumberValue.current ||
            filterConcreteTypeValue.current ||
            filterStructuralElementValue.current ? (
                <>
                    <b>
                        <Trans>Active table filters:</Trans>
                    </b>
                    <div className={styles.filterArea}>
                        <div className={styles.tagsArea}>
                            {filterOrderNumberValue.current ? (
                                <Tag
                                    data-testid="orderFilterTag"
                                    className={styles.filterTag}
                                    closable
                                    onClose={() => resetOrderNumberFilter()}>
                                    <Trans>Order number:</Trans>
                                    <b> {filterOrderNumberValue.current}</b>
                                </Tag>
                            ) : null}
                            {filterConcreteTypeValue.current ? (
                                <Tag
                                    data-testid="typeFilterTag"
                                    className={styles.filterTag}
                                    closable
                                    onClose={() => resetConcreteTypeFilter()}>
                                    <Trans>Concrete type:</Trans>
                                    <b> {filterConcreteTypeValue.current}</b>
                                </Tag>
                            ) : null}
                            {filterStructuralElementValue.current ? (
                                <Tag
                                    data-testid="structuralFilterTag"
                                    className={styles.filterTag}
                                    closable
                                    onClose={() => resetStructuralElementFilter()}>
                                    <Trans>Structural Element:</Trans> <b>{filterStructuralElementValue.current}</b>
                                </Tag>
                            ) : null}
                        </div>
                        <Button type="primary" danger data-testid="clearFilterBtn" onClick={() => displayFilterModal()}>
                            <CloseCircleOutlined /> <Trans>Clear all filters</Trans>
                        </Button>
                    </div>
                </>
            ) : null}

            <Table
                bordered
                dataz-testid="order-monitoring-table"
                rowClassName="order-monitoring-row"
                scroll={{ x: 10 }}
                pagination={pagination}
                columns={OrderMonitoringColumns({
                    onEdit,
                    onDuplicate,
                    showDetailsModal,
                    showCancelOrderModal: showCancelModal,
                    showCancelOrderDetailsModal,
                    showConcreteType,
                    goToConcreteLoad,
                    filterOrderNumber,
                    setFilterOrderNumber,
                    filterOrderNumberClicked,
                    filterConcreteType,
                    setFilterConcreteType,
                    filterConcreteTypeClicked,
                    filterStructuralElement,
                    setFilterStructuralElement,
                    filterStructuralElementClicked
                })}
                dataSource={keyMapFromTableData(orders)}
                onChange={onChange}
            />

            {detailsModalVisible && (
                <OrderDetailModal
                    projectId={projectId}
                    order={orderToEDitToShow}
                    hideModal={hideDetailsModal}
                    showModal={detailsModalVisible}
                />
            )}

            {concreteTypeModalVisible && (
                <ConcreteTypeModal
                    concreteDetails={concreteTypeToShow}
                    open={concreteTypeModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                />
            )}

            {cancelOrderModalVisible && (
                <CancelOrderModal
                    order={orderToEDitToShow}
                    open={cancelOrderModalVisible}
                    setOpen={setCancelOrderModalVisible}
                    setReLoad={setReLoad}
                />
            )}

            {cancelOrderDetailsModalVisible && (
                <CancelOrderDetailsModal
                    orderStatus={statusToShowCancelOrderDetails}
                    order={orderToEDitToShow}
                    hideModal={hideCancelOrderDetailsModal}
                    showModal={cancelOrderDetailsModalVisible}
                />
            )}
        </>
    );
}

export default OrderMonitoringTable;
