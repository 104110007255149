import { t, Trans } from '@lingui/macro';
import { Button, Form, Modal } from 'antd';
import { useEffect } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcreteTestingTypes } from '../../utils/concrete-tests-service';
import { MARKET_VALUES } from 'src/app-react/constants/markets';
import CommonTestForm from '../MultipleConcreteTestModal/components/CommonTestForm/CommonTestForm';
import useTemperatureTest from '../MultipleConcreteTestModal/components/TemperatureTestForm/hooks/useTemperatureTest';
import TemperatureTestForm from '../MultipleConcreteTestModal/components/TemperatureTestForm/TemperatureTestForm';
import {
    createInitialValues,
    createTemperatureTest,
    getTemperatureFormValues
} from '../MultipleConcreteTestModal/components/TemperatureTestForm/markets/util';
import {
    createInitialCommonValues,
    getFormCommonValues
} from '../MultipleConcreteTestModal/components/CommonTestForm/util';
import { TemperatureTestFormValues } from '../MultipleConcreteTestModal/components/TemperatureTestForm/markets/models';
import { CommonTestFormValues } from '../MultipleConcreteTestModal/components/CommonTestForm/models';

export interface ITemperatureTestModalProps {
    orderId?: string;
    concreteLoadId?: string;
    temperatureTestId?: string;
    open: boolean;
    setOpen: (isVisible: boolean) => void;
    initialFormValues?: Partial<TemperatureTestFormValues & CommonTestFormValues>;
    reloadFreshConcreteTests?: () => Promise<void>;
    onOk?: () => void;
    onCancel?: () => void;
}

function TemperatureTestModal(props: ITemperatureTestModalProps) {
    const {
        orderId,
        concreteLoadId,
        temperatureTestId,
        open,
        setOpen,
        initialFormValues,
        reloadFreshConcreteTests,
        onOk,
        onCancel
    } = props;

    const [form] = Form.useForm();
    const { projectId, marketId, companyId } = useProjectState();

    const temperatureTestMutate = useTemperatureTest();

    let enabledButton = true;

    async function onSubmit(): Promise<void> {
        if (!enabledButton) return;
        try {
            enabledButton = false;

            if (!(await form.validateFields())) return;
            if (!(await form.validateFields())) return;

            const temperatureTestFormValues = getTemperatureFormValues(form, marketId as MARKET_VALUES);
            const commonTestFormValues = {
                ...getFormCommonValues(form),
                testType: ConcreteTestingTypes.Temperature
            };

            const temperatureTest = createTemperatureTest(
                companyId,
                projectId,
                orderId,
                concreteLoadId,
                commonTestFormValues,
                temperatureTestFormValues,
                marketId
            );

            if (!temperatureTestId) {
                const { success, error } = await temperatureTestMutate(temperatureTest);

                if (error || !success) {
                    ErrorNotification({
                        message: 'Temperature test could not be created',
                        description: error ?? t`Unknown error`
                    });
                } else {
                    SuccessNotification({
                        message: t`Temperature test created successfully`,
                        description: ''
                    });
                    reloadFreshConcreteTests?.();
                    setOpen(false);
                    onOk?.();
                }
            } else {
                const { success, error } = await temperatureTestMutate(temperatureTest, temperatureTestId);

                if (error || !success) {
                    ErrorNotification({
                        message: 'Temperature test could not be updated',
                        description: error ?? t`Unknown error`
                    });
                } else {
                    SuccessNotification({
                        message: t`Temperature test updated successfully`,
                        description: ''
                    });
                    reloadFreshConcreteTests?.();
                    setOpen(false);
                    onOk?.();
                }
            }
            enabledButton = true;
        } catch (err) {
            ErrorNotification({
                message: 'Temperature test could not be updated',
                description: t`Unknown error`
            });
        }
    }

    function formOnCancel(): void {
        setOpen(false);
        onCancel?.();
    }

    useEffect(() => {
        form.setFieldsValue(createInitialCommonValues(initialFormValues, marketId as MARKET_VALUES));
        form.setFieldsValue(createInitialValues(initialFormValues, marketId as MARKET_VALUES));
    }, [initialFormValues, marketId, form]);

    return (
        <Modal
            maskClosable={false}
            title={t`Edit temperature test`}
            open={open}
            width={500}
            onCancel={formOnCancel}
            footer={[
                <Button data-testid="TemperatureTestModal.cancelButton" key="cancelButton" onClick={formOnCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    data-testid="TemperatureTestModal.okButton"
                    key="okButton"
                    onClick={onSubmit}
                    disabled={!enabledButton}
                    type="primary">
                    <Trans>Save</Trans>
                </Button>
            ]}>
            <Form form={form} layout="vertical">
                <CommonTestForm requestTestType={false} />
                <TemperatureTestForm showTitle={false} />
            </Form>
        </Modal>
    );
}

export default TemperatureTestModal;
