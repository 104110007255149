import { TestSpecimenBPUS } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto";

export function createContextDataUS(testSpecimen: TestSpecimenBPUS) {
    return {
        id: testSpecimen.id,
        projectId: testSpecimen.projectId,
        companyId: testSpecimen.companyId,
        orderId: testSpecimen.orderId,
        concreteLoadId: testSpecimen.concreteLoadId,
        testSpecimenIdentifier: testSpecimen.testSpecimenIdentifier,
        samplingLocation: testSpecimen.samplingLocation,
        samplingTime: testSpecimen.samplingTime,
        mould: testSpecimen.mould,
        compactor: testSpecimen.compactor,
        testingAge: testSpecimen.testingAge,
        createdOn: testSpecimen.createdOn,
        lastModifiedOn: testSpecimen.lastModifiedOn,

        testPurpose: testSpecimen.testPurpose,
        testType: testSpecimen.testType
    };
}
