import { Trans } from '@lingui/macro';
import { Form, Radio } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext, useEffect } from 'react';
import { SupplierConfigFormContext } from '../../../../../contexts/SupplierConfigFormContext';

export function IntegrationTypeFormItem() {
    const form = Form.useFormInstance();
    const { supplierConfig } = useContext(SupplierConfigFormContext);

    useEffect(() => {
        if (supplierConfig.isApiType) {
            form.setFieldsValue({ integrationType: 'isApiType' });
        } else if (supplierConfig.isManualType) {
            form.setFieldsValue({ integrationType: 'isManualType' });
        } else if (supplierConfig.isQrType) {
            form.setFieldsValue({ integrationType: 'isQrType' });
        }
    }, [form, supplierConfig.isApiType, supplierConfig.isManualType, supplierConfig.isQrType]);

    return (
        <FormItem
            name="integrationType"
            label={<Trans>Integration Type</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Radio.Group
                data-testid="isApiType"
                style={{ justifyContent: 'flex-start', gap: '1rem' }}
                onChange={() => {
                    form.resetFields(['supplierName']);
                    form.resetFields(['supplierSoftware']);
                }}>
                <Radio data-testid="isApi" value="isApiType">
                    <Trans>API</Trans>
                </Radio>
                <Radio data-testid="isQr" value="isQrType">
                    <Trans>QR</Trans>
                </Radio>
                <Radio data-testid="isManual" value="isManualType">
                    <Trans>Manual</Trans>
                </Radio>
            </Radio.Group>
        </FormItem>
    );
}
