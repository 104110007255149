import { t, Trans } from '@lingui/macro';
import { InputOrderBP } from '@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.dto';
import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getAuthService } from 'src/app-react/services/authService';
const { Option } = Select;

interface CancelModalProps {
    order: OrderOverviewBP;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
    setReLoad?: (value: boolean) => void;
}

function CancelOrderModal(props: CancelModalProps) {
    const { order, open, setOpen, setReLoad } = props;
    const [form] = Form.useForm();
    const [commentsRequired, setCommentsRequired] = useState<boolean>(false);
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    function onCancel() {
        form.resetFields();
        setOpen(false);
        setReLoad(true);
    }

    async function onSubmit() {
        try {
            const { cancelReason, comment } = await form.validateFields();

            const cancerOrderInput: InputOrderBP = {
                performance: order.performance,
                performanceUnit: order.performanceUnit,
                spacingInMinutes: order.spacingInMinutes,
                slump: order.slump,
                slumpUnit: order.slumpUnit,
                loadSize: order.truckLoadSize,
                cancelledBy: getAuthService().user.userId,
                cancelledReasonCode: cancelReason,
                cancelledReasonComment: comment
            };

            api.orderMonitoringClient
                .cancelOrder(companyId, projectId, order.id, cancerOrderInput)
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`Cancelation request has been succesfully sent`,
                            description: ''
                        });

                        onCancel();
                    } else {
                        const code = (response?.httpResponse?.data as any)?.error?.code;
                        if (code === 'INVALID_CANCEL_ORDER_REQUEST') {
                            ErrorNotification({
                                message: 'The order can´t be cancelled',
                                description: 'The delivery has been started. Please contact the supplier'
                            });
                        } else {
                            ErrorNotification({ message: response.getError(), description: '' });
                        }
                    }
                })
                .catch((info: any) => {
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        } catch (e: any) {
            ErrorNotification({
                message: t`Form not filled correctly`,
                description: t`Please, make sure that all the fields are filled correctly`
            });
        }
    }

    return (
        <Modal
            maskClosable={false}
            data-testid="cancel-order-modal"
            title={t`Cancel Order`}
            open={open}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button key="cancel-button" data-testid="cancel-button" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    // className={styles.modalButton}
                    // disabled={!enabledButton}
                    onClick={onSubmit}
                    data-testid="reject-concrete-load-submit"
                    type="primary">
                    <Trans>Confirm</Trans>
                </Button>
            ]}>
            <Form form={form} layout="vertical" data-testid="cancel-order-form">
                <Row gutter={[48, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="cancelReason"
                            label={<Trans>Please provide a reason for canceling an order.</Trans>}
                            rules={[
                                {
                                    required: true,
                                    message: t`This field is required`
                                }
                            ]}>
                            <Select
                                placeholder={t`Select cancel reason`}
                                onChange={(value: string) => {
                                    switch (value) {
                                        case 'siteNotReady':
                                        case 'weather':
                                        case 'other':
                                            setCommentsRequired(true);
                                            break;
                                        default:
                                            setCommentsRequired(false);
                                            break;
                                    }
                                }}>
                                <Option value="siteNotReady">
                                    <Trans>Site not ready</Trans>
                                </Option>
                                <Option value="permitsNotAvailable">
                                    <Trans>Permits not available</Trans>
                                </Option>
                                <Option value="weather">
                                    <Trans>Weather conditions</Trans>
                                </Option>
                                <Option value="changeInSchedule">
                                    <Trans>Change in schedule</Trans>
                                </Option>
                                <Option value="incorrectOrder">
                                    <Trans>Incorrect order</Trans>
                                </Option>
                                <Option value="cancelledBySupplier">
                                    <Trans>Cancelled by supplier</Trans>
                                </Option>
                                <Option value="other">
                                    <Trans>Other</Trans>
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span="24">
                        <Form.Item
                            name="comment"
                            label={<Trans>Comment</Trans>}
                            rules={[
                                {
                                    required: commentsRequired,
                                    message: t`This field is required`
                                }
                            ]}>
                            <Input.TextArea
                                data-testid="comment-input"
                                placeholder={t`Enter here any remark you might have`}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                maxLength={250}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default CancelOrderModal;
