import { i18n } from '@lingui/core';
import moment from 'moment';
import { TemperatureTestFormValuesDE } from './models';
import { formatDecimalByLocale, parseDecimalByLocale } from 'src/app-react/utils/formatters';
import { FormInstance } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { FreshConcreteTest, TemperatureTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValuesDE(values: any) {
    const initialFormValuesDE: TemperatureTestFormValuesDE = values;

    return {
        testingDate:
            initialFormValuesDE?.testingDate !== undefined ? moment(initialFormValuesDE.testingDate) : undefined,
        testingTime:
            initialFormValuesDE?.testingTime !== undefined ? moment(initialFormValuesDE.testingTime) : undefined,
        airTemperature: formatDecimalByLocale(initialFormValuesDE?.airTemperature?.toString(), i18n.locale),
        freshConcreteTemperature: formatDecimalByLocale(
            initialFormValuesDE?.freshConcreteTemperature?.toString(),
            i18n.locale
        ),
        externalInspection:
            initialFormValuesDE?.externalInspection !== undefined
                ? initialFormValuesDE?.externalInspection
                    ? 'true'
                    : 'false'
                : undefined
    };
}

export function getTemperatureFormValuesDE(temperatureTestForm: FormInstance<any>): TemperatureTestFormValuesDE {
    return {
        testingDate: (temperatureTestForm.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (temperatureTestForm.getFieldValue('testingTime') as moment.Moment).toDate(),
        airTemperature: parseDecimalByLocale(
            temperatureTestForm.getFieldValue('airTemperature') as string,
            i18n.locale
        ),
        freshConcreteTemperature: parseDecimalByLocale(
            temperatureTestForm.getFieldValue('freshConcreteTemperature') as string,
            i18n.locale
        ),
        externalInspection: (temperatureTestForm.getFieldValue('externalInspection') as string) === 'true'
    };
}

export function createTemperatureTestDE(
    freshConcreteTest: FreshConcreteTest,
    temperatureTestFormValues: TemperatureTestFormValuesDE
) {
    const { units } = getProjectConstants({ marketId: MARKETS.DE });
    const testingTime: Date = mergeDateTime(
        moment(temperatureTestFormValues.testingDate),
        moment(temperatureTestFormValues.testingTime)
    ).toDate();

    // Remove ª character Cª => C
    const unit = units.temperature.replace('°', '') as 'C' | 'F';

    const temperatureTest: TemperatureTest = {
        ...freshConcreteTest,
        airTemprature: {
            quantity: temperatureTestFormValues.airTemperature,
            unit
        },
        freshConcreteTemprature: {
            quantity: temperatureTestFormValues.freshConcreteTemperature,
            unit
        },
        externalInspection: temperatureTestFormValues.externalInspection
            ? temperatureTestFormValues.externalInspection
            : false,
        testingTime
    };

    return temperatureTest;
}
