import { t } from '@lingui/macro';
import { Space } from 'antd';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import { DeliveryDashboardAmount } from './components/DeliveryDashboardAmount';
import DeliveryDashboardBreadcrumb from './components/DeliveryDashboardBreadcrumb';
import { DeliveryDashboardFilters } from './components/DeliveryDashboardFilters';
import { DeliveryDashboardTableViews } from './components/DeliveryDashboardTableViews';

export function DeliveryDashboardPage() {
    const navigate: NavigateFunction = useNavigate();
    const [refreshData, setRefreshData] = useState<boolean>(false);

    return (
        <>
            <AppHeader
                title={t`Deliveries`}
                onBack={() => navigate('/projects')}
                breadcrumb={<DeliveryDashboardBreadcrumb />}
            />
            <Space size="large" direction="vertical" style={{ width: '100%' }}>
                <DeliveryDashboardFilters />
                <DeliveryDashboardAmount refreshData={refreshData} />
                <DeliveryDashboardTableViews refreshData={refreshData} setRefreshData={setRefreshData} />
            </Space>
        </>
    );
}
