/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import ExposureClassesFormatter from 'src/app-react/components/Formatters/ExposureClassesFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { mapStrengthDevelopmentClass } from '../../utils';
import styles from './ConcreteTypesTable.module.css';
import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

export const ConcreteTypesTableColumnsDE = (props: {
    onCanBeOrdered: (concreteType: ConcreteTypeExtendedBP) => void;
    onUnassign: (concreteType: ConcreteTypeExtendedBP) => void;
    onDuplicate: (concreteType: ConcreteTypeExtendedBP) => void;
    onEdit: (concreteType: ConcreteTypeExtendedBP) => void;
    showCatalogueConcreteTypeModal: (concreteType: ConcreteTypeExtendedBP) => void;
}): ColumnsType<ConcreteTypeExtendedBP> => {
    const { onCanBeOrdered, onUnassign, onDuplicate, onEdit, showCatalogueConcreteTypeModal } = props;
    const { isViewer } = useUserPermissions();

    const getActions = (record: ConcreteTypeExtendedBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: <Trans>Edit</Trans>,
            key: `${record.id} Edit`,
            icon: <EditOutlined />,
            onClick: () => {
                onEdit(record);
            }
        });

        items.push({
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <EditOutlined />,
            onClick: () => {
                onDuplicate(record);
            }
        });

        items.push({
            label: <Trans>Unassign</Trans>,
            key: `${record.id} AssignCT`,
            icon: <DeleteOutlined />,
            onClick: () => {
                onUnassign(record);
            }
        });

        return items;
    };

    const columns: ExtendedColumnType<ConcreteTypeExtendedBP>[] = [
        {
            title: <Trans>Concrete type number</Trans>,
            dataIndex: 'concreteTypeNumber',
            sorter: true,
            render: (concreteTypeNumber: string, record: ConcreteTypeExtendedBP) => (
                <RawLinkButton onClick={() => showCatalogueConcreteTypeModal(record)}>
                    {concreteTypeNumber}
                </RawLinkButton>
            )
        },
        {
            title: <Trans>Concrete type description</Trans>,
            dataIndex: 'concreteTypeDescription',
            sorter: true,
            render: (concreteTypeDescription: string) => <>{concreteTypeDescription}</>
        },
        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'typeOfConcrete',
            sorter: true,
            render: (typeOfConcrete: string, record: ConcreteTypeExtendedBP) =>
                renderCompressiveStrength(typeOfConcrete, record)
        },
        {
            title: <Trans>Exposure classes</Trans>,
            dataIndex: 'x0',
            render: (x0: boolean, record: ConcreteTypeExtendedBP) => renderExposureClass(x0, record)
        },
        {
            title: <Trans>Max aggregation size</Trans>,
            dataIndex: 'maximumAggregateSize',
            sorter: true,
            render: (maximumAggregateSize: number) => <>{maximumAggregateSize}</>
        },
        {
            title: <Trans>Consistency class</Trans>,
            dataIndex: 'consistencyType',
            sorter: true,
            render: (consistencyType: string, record: ConcreteTypeExtendedBP) =>
                renderConsistencyClass(consistencyType, record)
        },
        {
            title: <Trans>Strength development</Trans>,
            dataIndex: 'strengthDevelopmentClass',
            sorter: true,
            render: (strengthDevelopmentClass: string) => <>{mapStrengthDevelopmentClass(strengthDevelopmentClass)}</>
        },
        {
            title: <Trans>Can be ordered</Trans>,
            dataIndex: 'canBeOrdered',
            sorter: true,
            render: (canBeOrdered: boolean, record: ConcreteTypeExtendedBP) => renderCanBeOrdered(record)
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            hidden: isViewer,
            width: 50, // we set this width to have an alignment of this column with the other table having also 50 as width for the action column
            render: (id: string, record: ConcreteTypeExtendedBP) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                );
            }
        }
    ];

    return columns.filter((item) => !item.hidden);

    function renderCompressiveStrength(typeOfConcrete: string, record: ConcreteTypeExtendedBP): JSX.Element {
        return <>{`${typeOfConcrete} ${record.cylindricCompressiveStrength}/${record.cubicCompressiveStrength}`}</>;
    }

    function renderExposureClass(x0: boolean, record: ConcreteTypeExtendedBP): JSX.Element {
        return (
            <ExposureClassesFormatter
                x0={record.x0}
                xa={record.xa}
                xc={record.xc}
                xd={record.xd}
                xfFirst={record.xfFirst}
                xfSecond={record.xfSecond}
                xm={record.xm}
                xs={record.xs}
            />
        );
    }

    function renderConsistencyClass(consistencyType: string, record: ConcreteTypeExtendedBP): JSX.Element {
        return <>{`${consistencyType} ${record.consistencyClass}`}</>;
    }

    function renderCanBeOrdered(record: ConcreteTypeExtendedBP): JSX.Element {
        const canOrdered: boolean = record.canBeOrdered;
        function onChange(): void {
            onCanBeOrdered(record);
        }

        return <Checkbox onChange={onChange} checked={canOrdered} />;
    }
};
