import { Col } from 'antd';
import { ITestSpecimenModalRowProps } from './TestSpecimenDetailModal';
import styles from './TestSpecimenDetailModal.module.css';
const TestSpecimenModalRow = (props: ITestSpecimenModalRowProps) => {
    const { specimenLabel, specimenValue, specimenTestId } = props;

    return (
        <>
            <Col span={24}>
                <label className={styles.modalLabel}>{specimenLabel}</label>
            </Col>
            <Col style={{marginBottom: '15px'}} data-testid={specimenTestId} span={24}>
                {specimenValue || ''}
            </Col>
        </>
    );
};

export default TestSpecimenModalRow;
