import { Col, Row } from 'antd';
import ChlorideContentFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/ChlorideContentFomItem';
import CompressiveStrengthClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/CompressiveStrengthClassFormItem';
import ConsistencyClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/ConsistencyClassFormItem';
import DensityClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/DensityClassFormItem';
import ExposureClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/ExposureClassesFormItem';
import MaxAggregateSizeFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MaxAggregateSizeFormItem';
import MethodConcreteFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MethodOfConcreteFormItem';
import MoistureClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MoistureClassFormItem';
import StrengthDevelopmentClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/StrengthDevelopmentClassFormItem';

export default function OrderLiteModalForm2DefaultDELayout() {
    return (
        <>
            <Row gutter={54}>
                <Col span="12">
                    <CompressiveStrengthClassFormItem required={false} />
                </Col>
                <Col span="12">
                    <ConsistencyClassFormItem required={false} />
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <ExposureClassFormItem required={false} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <MoistureClassFormItem required={false} />
                </Col>
                <Col span="12">
                    <StrengthDevelopmentClassFormItem required={false} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <MaxAggregateSizeFormItem />
                </Col>
                <Col span="12">
                    <DensityClassFormItem required={false} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <MethodConcreteFormItem required={false} />
                </Col>
                <Col span="12">
                    <ChlorideContentFormItem required={false} />
                </Col>
            </Row>
        </>
    );
}
