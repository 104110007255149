import { Trans } from '@lingui/macro';
import { DeliveryNoteAdmixture } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Quantity } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { ColumnsType } from 'antd/lib/table';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';

export interface AdmixtureTableColumns {
    displayConcreteTestModal: (dnId: string) => void;
    displayReportArrivalModal: (dnId: string, dnNo: string, licensePlate: string) => void;
    displayStartUnloadingModal: (
        dnId: string,
        dnNo: string,
        licensePlate: string,
        arrivalDateTime: Date,
        processableUntil: Date
    ) => void;
    displayEndUnloadingModal: (dnId: string, dnNo: string, licensePlate: string, startUnloadingDateTime: Date) => void;

    openDeliveryDetailsPage: (dnId: string) => void;
}

export const deliveryAdmixtureTableColumns: () => ColumnsType<DeliveryNoteAdmixture> = () => {
    return [
        {
            title: <Trans>Name of Admixture</Trans>,
            key: 'type',
            dataIndex: 'type',
            sorter: false,
            render: (type: string) => <span data-testid="type-additive">{type}</span>
        },

        {
            title: <Trans>Admixture quantity</Trans>,
            key: 'quantity',
            dataIndex: 'quantity',
            sorter: false,
            render: (quantity: Quantity) => (
                <span>
                    {quantity.amount} {quantity.unit}
                </span>
            )
        },

        {
            title: <Trans>Remaining quantity</Trans>,
            key: 'leftOver',
            dataIndex: 'leftOver',
            sorter: false,
            render: (leftOver: Quantity) => (
                <span>
                    {leftOver.amount} {leftOver.unit}
                </span>
            )
        },

        {
            title: <Trans>Minimal mixing time</Trans>,
            key: 'recommendedTime',
            dataIndex: 'recommendedTime',
            sorter: false,
            render: (recommendedTime: Quantity) => (
                <span>
                    {recommendedTime.amount} {recommendedTime.unit}
                </span>
            )
        },

        {
            title: <Trans>Start of mixing</Trans>,
            key: 'start',
            dataIndex: 'start',
            sorter: false,
            render: (start: Date) => <DateTimeFormatter date={start} />
        }
    ];
};
