import { t, Trans } from '@lingui/macro';
import { Col, Form, Row } from 'antd';
import styles from '../../MultipleConcreteTestModal.module.css';
import { MARKETS } from 'src/app-react/constants/markets';
import { ExternalInspectionFormItem } from '../../FormItems/DE/ExternalInspectionFormItem';
import { AirMeasurementMethodFormItem } from '../../FormItems/US/AirMeasurementMethodFormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { InputNumber } from 'src/app-react/components/Form';
import airContentReferenceImage from '../../../../../../../assets/images/testReferenes/airContent.png';
import { TestReferences } from '../TestReferences';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export interface Props {
    showTitle?: boolean;
}

function AirContentTestForm({ showTitle = true }: Props) {
    const { marketId } = useProjectState();

    return (
        <>
            {showTitle && (
                <>
                    <Row>
                        <label className={styles.title}>
                            <Trans>Air Content Test</Trans>
                        </label>
                    </Row>
                    <br />
                </>
            )}

            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="testingDate"
                        label={<Trans>Testing Date</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <DatePicker style={{ width: '100%' }} data-testid="testingDate" placeholder={t`Enter Date`} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="testingTime" label={<Trans>Testing Time</Trans>} rules={[requiredRule]}>
                        <TimePicker data-testid="testingTime" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="airContent"
                label={<Trans>Air Content</Trans>}
                rules={[
                    {
                        required: true,
                        pattern: new RegExp(/^\d+(?:.\d{1,2})?$/),
                        message: t`This field is required`
                    }
                ]}>
                <InputNumber data-testid="airContent" addonAfter="Vol.%" placeholder={t`Enter the air content`} />
            </Form.Item>

            {marketId === MARKETS.US && <AirMeasurementMethodFormItem />}

            {marketId === MARKETS.DE && <ExternalInspectionFormItem />}

            {marketId === MARKETS.DE && (
                <TestReferences
                    imageURL={airContentReferenceImage}
                    visibleLabel={t`Hide air content reference`}
                    hiddenLabel={t`Show air content reference`}
                />
            )}
        </>
    );
}

export default AirContentTestForm;
