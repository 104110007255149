import { ConcretedStructuralElementForConcretingDocumentationBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';

export const keyMapFromTableData = (
    concretingDocumentationData: ConcretedStructuralElementForConcretingDocumentationBP[]
): ConcretedStructuralElementForConcretingDocumentationBP[] => {
    return concretingDocumentationData.map(
        (value: ConcretedStructuralElementForConcretingDocumentationBP, key: number) => {
            return { ...value, key: value.structuralElementId };
        }
    );
};
