import { PageHeader } from 'antd';
import styles from './AppHeader.module.css';

interface AppHeaderParams {
    title: string;
    onBack: () => void;
    onBreadcrumbCreation?: () => JSX.Element;
    breadcrumb?: JSX.Element;
    extra?: React.ReactNode;
}

function AppHeader(params: AppHeaderParams) {
    function createBreadcrumb(): JSX.Element {
        return params?.onBreadcrumbCreation?.();
    }

    return (
        <PageHeader
            className={styles.pageHeader}
            title={params.title}
            onBack={params.onBack}
            breadcrumb={params.breadcrumb || createBreadcrumb()}
            extra={params.extra}
        />
    );
}

export default AppHeader;
