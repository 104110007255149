/* eslint-disable react/no-array-index-key */
import { Trans } from '@lingui/macro';
import { DeliveryConcreteInformation } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { ColumnsType } from 'antd/lib/table';
import {
  optionMethodConcrete,
  optionStrengthDevelopmentClass
} from 'src/app-react/business-page/delivery-details/util';
import { concreteInformationCategoryMapper, numberFormatter } from 'src/app-react/utils/formatters';

const CATERGORIES_WITH_NUMBER_FORMMATTER = ['waterCementRatio'];

function translateValueRow(category: string, originalValue: string) {
    const options: { [key: string]: (value: string) => string } = {
        strengthDevelopment: (value) => optionStrengthDevelopmentClass(value),
        methodOfConcrete: (value) => optionMethodConcrete(value)
    };

    return options[category.trim()]?.(originalValue) ?? originalValue;
}

function createListOfElementsFormValues(values: string[]): JSX.Element[] {
    return values.map((value: string, index: number) => <p key={index}>{value}</p>);
}

function convertToString(originalValue: string | undefined, category: string): string {
    const value = typeof originalValue === 'string' ? originalValue.replace(/undefined/g, '').trim() : originalValue;

    if (value?.length === 1 && value.match(/(\/|\||,|\.)/)) {
        return '';
    }

    if (value && CATERGORIES_WITH_NUMBER_FORMMATTER.includes(category)) {
        return numberFormatter(value);
    }

    return translateValueRow(category, value);
}

function renderValueRow(value: string, record: DeliveryConcreteInformation): JSX.Element[] | string {
    return !Array.isArray(value) ? convertToString(value, record.category) : createListOfElementsFormValues(value);
}

export const concreteInformationDataTableColumns: () => ColumnsType<DeliveryConcreteInformation> = () => [
    {
        title: <Trans>Category</Trans>,
        key: 'category',
        dataIndex: 'category',
        render: (category: string) => <p key={category}>{concreteInformationCategoryMapper[category.trim()]} </p>
    },
    {
        title: <Trans>Order Value</Trans>,
        key: 'orderValue',
        dataIndex: 'orderValue',
        render: renderValueRow
    },
    {
        title: <Trans>Delivery Value</Trans>,
        key: 'deliveryValue',
        dataIndex: 'deliveryValue',
        render: renderValueRow
    }
];
