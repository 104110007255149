import { Trans } from '@lingui/macro';
import {
  CataloguedStructuralElementExtendedBP,
  IsActiveBP
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Button, Modal } from 'antd';
import React from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

interface IProps {
    structuralElement: CataloguedStructuralElementExtendedBP;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}
export default function ActivateStructuralElementModal({ structuralElement, open, setOpen, onComplete }: IProps) {
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    const onOk = async (): Promise<void> => {
        setOpen(false);

        const structuralElementIsActive: IsActiveBP = {
            isActive: !structuralElement.isActive
        };

        await api.catalogueManagementClient
            .updateIsActiveForCataloguedStructuralElement(
                companyId,
                projectId,
                structuralElement.id,
                structuralElementIsActive
            )
            .then((response: SimpleClientResponse<void>) => {
                if (response.isSuccess()) {
                    onComplete?.();
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
    };

    const onCancel = (): void => {
        setOpen(false);
    };

    function renderActiveModal() {
        return (
            <Modal
                maskClosable={false}
                data-testid="modal"
                title={<Trans>Deactivating structural element</Trans>}
                open={open}
                width={600}
                onOk={onOk}
                onCancel={onCancel}
                footer={[
                    <Button data-testid="cancel-btn" key="cancelButton" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>,
                    <Button data-testid="ok-btn" key="okButton" onClick={onOk} type="primary">
                        <Trans>Save</Trans>
                    </Button>
                ]}>
                <p data-testid="modal-message">
                    <Trans>
                        You are about to deactivate this structural element. This means it will not be available for
                        ordering anymore!
                    </Trans>
                </p>
            </Modal>
        );
    }

    function renderInactiveModal() {
        return (
            <Modal
                maskClosable={false}
                data-testid="modal"
                title={<Trans>Activating structural element</Trans>}
                open={open}
                width={600}
                onOk={onOk}
                onCancel={onCancel}
                footer={[
                    <Button data-testid="cancel-btn" key="cancelButton" onClick={onCancel}>
                        <Trans>Cancel</Trans>
                    </Button>,
                    <Button data-testid="ok-btn" key="okButton" onClick={onOk} type="primary">
                        <Trans>Save</Trans>
                    </Button>
                ]}>
                <p data-testid="modal-message">
                    <Trans>
                        You are about to activate this structural element. This means it will now be available for
                        ordering!
                    </Trans>
                </p>
            </Modal>
        );
    }

    return structuralElement?.isActive ? renderActiveModal() : renderInactiveModal();
}
