import { DeliveryConcreteInformation } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { optionMethodConcrete, optionStrengthDevelopmentClass } from '../../util';

export function formatConcreteInformationDE(concreteInformation: DeliveryConcreteInformation[]) {
    const information: DeliveryConcreteInformation[] = [];

    concreteInformation.forEach((element: DeliveryConcreteInformation) => {
        if (
            element.category !== 'slump' &&
            element.category !== 'compressiveStrength' &&
            element.category !== 'maxWaterToAdd'
        ) {
            const newElement = { ...element };
            if (element.category === 'methodOfConcrete') {
                newElement.orderValue = element.orderValue ? optionMethodConcrete(element.orderValue as string) : '';
            }

            if (element.category === 'strengthDevelopment') {
                newElement.orderValue = element.orderValue
                    ? optionStrengthDevelopmentClass(element.orderValue as string)
                    : '';
            }
            information.push(newElement);
        }
    });

    return information || [];
}
