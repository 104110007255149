import { DeleteOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { DeliveryNoteDetailsBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button } from 'antd';
import { useState } from 'react';
import RejectConcreteLoadModal from 'src/app-react/business-page/delivery-note-details/components/RejectConcreteLoadModal/RejectConcreteLoadModal';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';

interface Props {
    children?: JSX.Element;
    deliveryDetails: DeliveryNoteDetailsBP;
    requestDetails: () => void;
}

export function RejectConcreteLoadButton({ deliveryDetails, requestDetails, children }: Props) {
    const permissions = useUserPermissions();
    const [rejectConcreteLoadModalVisible, setRejectConcreteLoadModalVisible] = useState(false);

    return (
        <>
            {permissions.isAllowToRejectConcreteDeliveryLoads && (
                <Button
                    danger
                    type="primary"
                    onClick={() => setRejectConcreteLoadModalVisible(true)}
                    data-testid="reject-button"
                    style={{ marginRight: '8px' }}>
                    <DeleteOutlined /> <Trans>Reject concrete load</Trans>
                </Button>
            )}

            {children}

            {rejectConcreteLoadModalVisible && (
                <RejectConcreteLoadModal
                    deliveryNoteDetailsBP={deliveryDetails}
                    open={rejectConcreteLoadModalVisible}
                    setOpen={setRejectConcreteLoadModalVisible}
                    onComplete={() => {
                        setRejectConcreteLoadModalVisible(false);
                        requestDetails();
                    }}
                />
            )}
        </>
    );
}
