import { t, Trans } from '@lingui/macro';
import {
  CanBeOrderedBP,
  CataloguedStructuralElementMinimalBP,
  ConcreteTypeExtendedBP,
  ConcreteTypeIngredientBP,
  ConcreteTypesWithPaginationParamsBP,
  OffsetPagination,
  SimpleClientResponse
} from '@nexploretechnology/concreting-core-client';
import { Button, Modal, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect, useRef, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { noPagination, paginationAdapterReactAntdToBCQD } from 'src/app-react/utils/pagination';
import ConcreteTypeModal from '../../../../components/Modals/ConcreteTypeModal/ConcreteTypeModal';
import { methodConcreteSelected } from '../../mapper.fields';
import CatalogueConcreteTypeModalForm from '../CatalogueConcreteTypeModalForm/CatalogueConcreteTypeModalForm';
import {
  CommonFormValues,
  ConcreteTypeModalFormValuesDE,
  ConcreteTypeModalFormValuesUS,
  FormValuesDE,
  FormValuesUS
} from '../CatalogueConcreteTypeModalForm/types';
import { ConcreteTypesTableColumnsDE } from './ConcreteTypesTable.columns.de';
import { ConcreteTypesTableColumnsUS } from './ConcreteTypesTable.columns.us';

const ConcreteTypesTable = React.forwardRef(
    (props: {
        structuralElementId: string;
        triggerReloadConcreteTypeTable: boolean;
        setTriggerReloadConcreteTypeTable: (value: boolean) => void;
    }) => {
        const { structuralElementId, triggerReloadConcreteTypeTable, setTriggerReloadConcreteTypeTable } = props;
        const { projectId, companyId, marketId } = useProjectState();
        const [tableDataDE, setTableDataDE] = useState<ConcreteTypeExtendedBP[]>([]);
        const [tableDataUS, setTableDataUS] = useState<ConcreteTypeExtendedBP[]>([]);
        const [catalogueConcreteTypeModalVisible, setCatalogueConcreteTypeModalVisible] = useState(false);
        const [catalogueConcreteTypeModalInitialValues, setCatalogueConcreteTypeModalInitialValues] = useState(
            {} as ConcreteTypeExtendedBP
        );

        const api = useApi();

        const [concreteTypeModalVisible, setConcreteTypeModalVisible] = useState(false);
        const [concreteTypeModalInitialValues, setConcreteTypeModalInitialValues] = useState(
            {} as ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS
        );
        const [concreteTypeModalAction, setConcreteTypeModalAction] = useState('create');

        const [isPopupConfirmAssignUnassignModalVisible, setIsPopupConfirmAssignUnassignModalVisible] = useState(false);
        interface PopupConfirmAssignUnassignModalData {
            structuralElementId: string;
            concreteTypeId: string;
        }
        const [popupConfirmAssignUnassignModalData, setPopupConfirmAssignUnassignModalData] =
            useState<PopupConfirmAssignUnassignModalData>();

        const [isPopupConfirmCanBeOrderedModalVisible, setIsPopupConfirmCanBeOrderedModalVisible] = useState(false);
        interface PopupConfirmCanBeOrderedModalData {
            structuralElementId: string;
            concreteType: ConcreteTypeExtendedBP;
            canBeOrdered: boolean;
        }
        const [popupConfirmCanBeOrderedModalData, setPopupConfirmCanBeOrderedModalData] =
            useState<PopupConfirmCanBeOrderedModalData>();

        const offsetPagination = useRef<OffsetPagination<ConcreteTypeExtendedBP>>(
            noPagination as OffsetPagination<ConcreteTypeExtendedBP>
        );

        function onCanBeOrdered(concreteType: ConcreteTypeExtendedBP): void {
            setPopupConfirmCanBeOrderedModalData({
                structuralElementId,
                concreteType,
                canBeOrdered: !concreteType.canBeOrdered
            });
            setIsPopupConfirmCanBeOrderedModalVisible(true);
        }

        function onUnassign(concreteType: ConcreteTypeExtendedBP): void {
            onEditorUnassign(structuralElementId, concreteType.id);
        }

        function onDuplicate(concreteType: ConcreteTypeExtendedBP): void {
            onEditorDuplicate(concreteType, false);
        }

        function onEdit(concreteType: ConcreteTypeExtendedBP): void {
            onEditorDuplicate(concreteType, true);
        }

        const getIngredients = (
            ingredients: ConcreteTypeIngredientBP[],
            ingredientName: string
        ): ConcreteTypeIngredientBP[] => {
            const filteredIngredients: ConcreteTypeIngredientBP[] = [];
            if (ingredients) {
                const ingredientCement: ConcreteTypeIngredientBP[] | undefined = ingredients.filter(
                    (i) => i.ingredientType === ingredientName
                );
                if (ingredientCement) {
                    ingredientCement.forEach((ingredient: ConcreteTypeIngredientBP) => {
                        filteredIngredients.push(ingredient);
                    });
                }
            }
            return filteredIngredients;
        };

        const generateRequiredXf = (
            requiredXfFirst: number | undefined,
            requiredXfSecond: number | undefined
        ): string => {
            if (requiredXfFirst && requiredXfSecond) {
                return '2/3';
            }
            if (requiredXfFirst) {
                return `${requiredXfFirst}`;
            }
            return '';
        };

        const baseConcreteType = (
            concreteType: ConcreteTypeExtendedBP,
            edit: boolean
        ): ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS => {
            let cements: ConcreteTypeIngredientBP[] = [];
            let aggregates: ConcreteTypeIngredientBP[] = [];
            let supplementaryCementiousMaterials: ConcreteTypeIngredientBP[] = [];
            let admixtures: ConcreteTypeIngredientBP[] = [];

            if (concreteType) {
                if (concreteType.ingredients) {
                    cements = getIngredients(concreteType.ingredients, 'cement');
                    aggregates = getIngredients(concreteType.ingredients, 'aggregate');
                    admixtures = getIngredients(concreteType.ingredients, 'admixture');
                    supplementaryCementiousMaterials = getIngredients(
                        concreteType.ingredients,
                        'supplementaryCementiousMaterials'
                    );
                }

                let formValuesDE: FormValuesDE = {} as FormValuesDE;
                let formValuesUS: FormValuesUS = {} as FormValuesUS;

                if (marketId === 'DE') {
                    formValuesDE = {
                        maxAggregateSize: concreteType.maximumAggregateSize,

                        aggregates: aggregates
                            ? aggregates.map((element) => {
                                  return {
                                      type: element.ingredientName || '',
                                      origin: element.ingredientOrigin,
                                      quantity: element.quantity,
                                      minAggregateSize: element.minimumAggregateSize,
                                      maxAggregateSize: element.maximumAggregateSize
                                  };
                              })
                            : [],

                        compressiveStrengthClass: {
                            requiredTypeOfConcreteType: concreteType.typeOfConcrete,
                            cylindricCompressiveStrength: concreteType.cylindricCompressiveStrength,
                            cubicCompressiveStrength: concreteType.cubicCompressiveStrength
                        },

                        consistencyClass: {
                            consistencyType: concreteType.consistencyType,
                            consistencyClass: concreteType.consistencyClass
                        },

                        moistureClass: concreteType.moistureClass ? concreteType.moistureClass : '',
                        strengthDevelopmentClass: concreteType.strengthDevelopmentClass,
                        densityClass: concreteType.densityClass,
                        methodOfConcreteChlorideContent: methodConcreteSelected(
                            concreteType.methodOfConcreteUse,
                            concreteType.chlorideContentClass
                        ),
                        exposureClasses: {
                            requiredX0: concreteType.x0,
                            requiredXc: concreteType.xc,
                            requiredXd: concreteType.xd,
                            requiredXf: generateRequiredXf(concreteType.xfFirst, concreteType.xfSecond),
                            requiredXa: concreteType.xa,
                            requiredXm: concreteType.xm,
                            requiredXs: concreteType.xs
                        },
                        externalInspection: concreteType.externalInspection
                    };
                } else {
                    formValuesUS = {
                        compressiveStrength: concreteType.compressiveStrength!,

                        slump: String(concreteType.slump ? concreteType.slump : ''),

                        slumpVariance: String(concreteType.slumpVariance ? concreteType.slumpVariance : ''),
                        airContentInPercent: concreteType.airContentInPercent,
                        submittalNumber: concreteType.submittalNumber,

                        methodOfConcrete: String(
                            concreteType.methodOfConcreteUse ? concreteType.methodOfConcreteUse : ''
                        ),

                        ...(concreteType.maximumAggregateSize && {
                            maxAggregateSize: String(concreteType.maximumAggregateSize)
                        }),

                        aggregates: aggregates
                            ? aggregates.map((element) => {
                                  return {
                                      type: element.ingredientName || '',
                                      origin: element.ingredientOrigin,
                                      quantity: element.quantity,
                                      maxAggregateSize: element.maximumAggregateSize
                                          ? String(element.maximumAggregateSize)
                                          : undefined
                                  };
                              })
                            : []
                    };
                }

                const commonFormValues: CommonFormValues = {
                    typeNumber: edit === true ? concreteType.concreteTypeNumber : '',
                    testingAge: concreteType.testingAge,
                    waterCementRatio: concreteType.waterCementRatio ? String(concreteType.waterCementRatio) : '',
                    price: concreteType.price ? String(concreteType.price) : '',

                    cements: cements
                        ? cements.map((element) => {
                              return {
                                  type: element.ingredientName || '',
                                  origin: element.ingredientOrigin,
                                  quantity: element.quantity
                              };
                          })
                        : [],
                    admixtures: admixtures
                        ? admixtures.map((element) => {
                              return {
                                  type: element.ingredientName || '',
                                  origin: element.ingredientOrigin
                              };
                          })
                        : [],

                    supplementaryCementiousMaterials: supplementaryCementiousMaterials
                        ? supplementaryCementiousMaterials.map((element) => {
                              return {
                                  type: element.ingredientName || '',
                                  origin: element.ingredientOrigin,
                                  quantity: element.quantity
                              };
                          })
                        : [],

                    assignToStructuralElements: concreteType.assignedCataloguedStructuralElements
                        ? concreteType.assignedCataloguedStructuralElements.map(
                              (element: CataloguedStructuralElementMinimalBP) => {
                                  return element.id;
                              }
                          )
                        : [],

                    additionalAttributes: concreteType.additionalAttributes,

                    description: concreteType.concreteTypeDescription,

                    canBeOrdered: concreteType.canBeOrdered
                };

                if (marketId === 'DE') {
                    return { ...commonFormValues, ...formValuesDE };
                }
                return { ...commonFormValues, ...formValuesUS };
            }

            return {} as ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS;
        };

        function onEditorDuplicate(concreteType: ConcreteTypeExtendedBP, edit: boolean): void {
            if (!edit) {
                setConcreteTypeModalInitialValues({
                    ...baseConcreteType(concreteType, edit)
                });
                setConcreteTypeModalAction('duplicate');
            } else {
                setConcreteTypeModalInitialValues({
                    id: concreteType.id,
                    ...baseConcreteType(concreteType, edit)
                });
                setConcreteTypeModalAction('edit');
            }

            setConcreteTypeModalVisible(true);
        }

        function showCatalogueConcreteTypeModal(concreteType: ConcreteTypeExtendedBP): void {
            setCatalogueConcreteTypeModalInitialValues(concreteType);
            setCatalogueConcreteTypeModalVisible(true);
        }

        const completed = async (): Promise<void> => {
            try {
                setConcreteTypeModalInitialValues({} as ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS);
                loadTableData();
                setTriggerReloadConcreteTypeTable(!triggerReloadConcreteTypeTable);
            } catch (e: any) {
                ErrorNotification({ message: e.code, description: e.message });
            }
        };

        const loadTableData = async (): Promise<void> => {
            await getConcreteTypes();
        };

        const getConcreteTypes = async () => {
            const requestParameters: ConcreteTypesWithPaginationParamsBP = { pagination: offsetPagination.current };
            await api.catalogueManagementClient
                .getAssignedExtendedConcreteTypesToCaSeAsDataList(
                    companyId,
                    projectId,
                    structuralElementId,
                    requestParameters
                )
                .then((response) => {
                    if (response.isSuccess()) {
                        const concreteTypes: ConcreteTypeExtendedBP[] = response.getEntity().data;

                        if (marketId === 'DE') {
                            setTableDataDE(concreteTypes);
                        } else {
                            setTableDataUS(concreteTypes);
                        }
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        };

        function onEditorUnassign(structuralElementId: string, concreteTypeId: string): void {
            setPopupConfirmAssignUnassignModalData({
                structuralElementId,
                concreteTypeId
            });
            setIsPopupConfirmAssignUnassignModalVisible(true);
        }

        function onChange(
            pagination: TablePaginationConfig,
            filters: Record<string, FilterValue | null>,
            sorter: SorterResult<ConcreteTypeExtendedBP> | SorterResult<ConcreteTypeExtendedBP>[],
            extra: TableCurrentDataSource<ConcreteTypeExtendedBP>
        ): void {
            offsetPagination.current = paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra);
            loadTableData();
        }

        const popupConfirmAssignUnassignModal_onOK = async (): Promise<void> => {
            setIsPopupConfirmAssignUnassignModalVisible(false);

            await api.catalogueManagementClient
                .unassignCataloguedStructuralElementAndConcreteType(
                    companyId,
                    projectId,
                    popupConfirmAssignUnassignModalData!.structuralElementId,
                    popupConfirmAssignUnassignModalData!.concreteTypeId
                )
                .then((response: SimpleClientResponse<void>) => {
                    if (response.isSuccess()) {
                        loadTableData();
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                });
        };

        const popupConfirmAssignUnassignModal_onCancel = (): void => {
            setIsPopupConfirmAssignUnassignModalVisible(false);
        };

        const popupConfirmCanBeOrderedModal_onOK = async (): Promise<void> => {
            setIsPopupConfirmCanBeOrderedModalVisible(false);

            const concreteTypeCanBeOrdered: CanBeOrderedBP = {
                canBeOrdered: !popupConfirmCanBeOrderedModalData!.concreteType.canBeOrdered
            };

            await api.catalogueManagementClient
                .updateCanBeOrderedForConcreteType(
                    companyId,
                    projectId,
                    popupConfirmCanBeOrderedModalData!.concreteType.id,
                    concreteTypeCanBeOrdered
                )
                .then((response: SimpleClientResponse<void>) => {
                    if (response.isSuccess()) {
                        loadTableData();
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                });
        };

        const popupConfirmCanBeOrderedModal_onCancel = (): void => {
            setIsPopupConfirmCanBeOrderedModalVisible(false);
        };

        useEffect(() => {
            loadTableData();
        }, [structuralElementId, triggerReloadConcreteTypeTable, projectId, companyId]);

        return (
            <>
                {marketId === 'DE' ? (
                    <Table
                        data-testid="ConcreteTypesTable"
                        rowClassName="ConcreteTypesTable-testid"
                        showSorterTooltip={false}
                        scroll={{ x: 1000 }}
                        onChange={onChange}
                        columns={ConcreteTypesTableColumnsDE({
                            onCanBeOrdered,
                            onUnassign,
                            onDuplicate,
                            onEdit,
                            showCatalogueConcreteTypeModal
                        })}
                        rowKey="id"
                        dataSource={tableDataDE}
                        pagination={false}
                    />
                ) : (
                    <Table
                        data-testid="ConcreteTypesTable"
                        rowClassName="ConcreteTypesTable-testid"
                        showSorterTooltip={false}
                        onChange={onChange}
                        scroll={{ x: 1000 }}
                        columns={ConcreteTypesTableColumnsUS({
                            onCanBeOrdered,
                            onUnassign,
                            onDuplicate,
                            onEdit,
                            showCatalogueConcreteTypeModal
                        })}
                        rowKey="id"
                        dataSource={tableDataUS}
                        pagination={false}
                    />
                )}

                <ConcreteTypeModal
                    concreteDetails={catalogueConcreteTypeModalInitialValues}
                    open={catalogueConcreteTypeModalVisible}
                    setOpen={setCatalogueConcreteTypeModalVisible}
                />

                <CatalogueConcreteTypeModalForm
                    data-testid="concrete-type-modal-edit-duplicate"
                    open={concreteTypeModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                    initialValues={concreteTypeModalInitialValues}
                    onComplete={() => completed()}
                    action={concreteTypeModalAction}
                />

                <Modal
                    maskClosable={false}
                    title={t`Unassign concrete type`}
                    open={isPopupConfirmAssignUnassignModalVisible}
                    width={400}
                    onOk={popupConfirmAssignUnassignModal_onOK}
                    onCancel={popupConfirmAssignUnassignModal_onCancel}
                    footer={[
                        <Button
                            data-testid="PopupConfirmAssignUnassignModal.cancelButton"
                            key="cancelButton"
                            onClick={popupConfirmAssignUnassignModal_onCancel}>
                            <Trans>Cancel</Trans>
                        </Button>,
                        <Button
                            data-testid="PopupConfirmAssignUnassignModal.okButton"
                            key="okButton"
                            onClick={popupConfirmAssignUnassignModal_onOK}
                            type="primary">
                            <Trans>OK</Trans>
                        </Button>
                    ]}>
                    <Trans>You are about to unassign the concrete type from the selected structural element!</Trans>
                </Modal>

                <Modal
                    maskClosable={false}
                    title={
                        marketId === 'DE' ? (
                            popupConfirmCanBeOrderedModalData?.canBeOrdered ? (
                                <Trans>Concrete type available for ordering</Trans>
                            ) : (
                                <Trans>Concrete type unavailable for ordering</Trans>
                            )
                        ) : popupConfirmCanBeOrderedModalData?.canBeOrdered ? (
                            <Trans>Mix design available for ordering</Trans>
                        ) : (
                            <Trans>Mix design unavailable for ordering</Trans>
                        )
                    }
                    open={isPopupConfirmCanBeOrderedModalVisible}
                    width={600}
                    onOk={popupConfirmCanBeOrderedModal_onOK}
                    onCancel={popupConfirmCanBeOrderedModal_onCancel}
                    footer={[
                        <Button
                            data-testid="PopupConfirmCanBeOrderedModal.cancelButton"
                            key="cancelButton"
                            onClick={popupConfirmCanBeOrderedModal_onCancel}>
                            <Trans>Cancel</Trans>
                        </Button>,
                        <Button
                            data-testid="PopupConfirmCanBeOrderedModal.okButton"
                            key="okButton"
                            onClick={popupConfirmCanBeOrderedModal_onOK}
                            type="primary">
                            <Trans>Save</Trans>
                        </Button>
                    ]}>
                    <Trans
                        id={
                            marketId === 'DE'
                                ? popupConfirmCanBeOrderedModalData?.canBeOrdered
                                    ? 'You are about to make this concrete type available for ordering!'
                                    : 'You are about to make this concrete type unavailable for ordering!'
                                : popupConfirmCanBeOrderedModalData?.canBeOrdered
                                ? 'You are about to make this mix design available for ordering!'
                                : 'You are about to make this mix design unavailable for ordering!'
                        }
                    />
                </Modal>
            </>
        );
    }
);

export default ConcreteTypesTable;
