/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { AutoComplete, Form } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef } from 'react';
import { DEBOUNCE_TIME } from 'src/app-react/business-page/DeliveryDashboardPage/components/DeliveryDashboardTableViews';
import useGetCompressiveAndAggregationDataByConcreteNumber from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetCompressiveAndAggregationDataByConcreteNumber';
import useGetSupplierProducedConcreteNumbersByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetSupplierProducedConcreteNumbersByProject';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';

function ConcreteTypeNumberFormItem(props: InputFormItemProps) {
    const form = Form.useFormInstance();

    const {
        loadData: loadNumberdata,
        typeNumbers,
        error: loadNumberError
    } = useGetSupplierProducedConcreteNumbersByProject();

    const {
        loadcompressiveAndAggregationData,
        compressiveAndAggregationData,
        error: loadCompressiveDataError
    } = useGetCompressiveAndAggregationDataByConcreteNumber();

    useEffect(() => {
        loadNumberdata(undefined);
    }, [loadNumberdata]);

    useEffect(() => {
        if (loadNumberError) {
            ErrorNotification({
                message: loadNumberError.message || 'unexpected error',
                description: loadNumberError.description
            });
        } else if (loadCompressiveDataError) {
            ErrorNotification({
                message: loadCompressiveDataError.message || 'unexpected error',
                description: loadCompressiveDataError.description
            });
        }
    }, [loadNumberError, loadCompressiveDataError]);

    useEffect(() => {
        if (compressiveAndAggregationData) {
            form.setFieldsValue({ compressiveStrength: compressiveAndAggregationData.compressiveStrength });
            form.setFieldsValue({ maxAggregateSize: compressiveAndAggregationData.maxAggregationSize });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compressiveAndAggregationData]);

    const loadcompressiveAndAggregationDataWithDebouce = useRef(
        debounce((text) => {
            loadcompressiveAndAggregationData(text);
        }, DEBOUNCE_TIME)
    );

    const onChange = useCallback(
        (text: string) => {
            form?.setFields([
                {
                    name: 'number',
                    value: text
                }
            ]);

            if (text.length > 0) {
                loadcompressiveAndAggregationDataWithDebouce.current(text);
            } else {
                form.setFieldsValue({ compressiveStrength: null });
                form.setFieldsValue({ maxAggregateSize: null });
            }
        },
        [form, loadcompressiveAndAggregationDataWithDebouce]
    );

    const onSearch = useCallback((inputValue: string, option: ValuesDropDown) => {
        return (option?.value as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }, []);

    return (
        <Form.Item name="number" label={t`Concrete type number`} {...props} rules={[requiredRule]}>
            <AutoComplete
                data-testid="concreteTypeFormItemAUS"
                placeholder={t`Please select concrete type number`}
                onChange={onChange}
                options={typeNumbers}
                filterOption={onSearch}
            />
        </Form.Item>
    );
}
export default ConcreteTypeNumberFormItem;
