/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form } from 'antd';
import DateTimePicker from 'src/app-react/components/Form/DateTimePicker';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

function DeliveryTimeFormItem(props: InputFormItemProps) {
    return (
        <Form.Item name="deliveryTime" label={t`Enter delivery date`} rules={[requiredRule]} {...props}>
            <DateTimePicker data-testid="deliveryTime" placeholder={t`Enter delivery date`} />
        </Form.Item>
    );
}

export default DeliveryTimeFormItem;
