import { useState, useEffect } from "react";

const APP_KEY_PREFIX = 'NexploreConcrete';

function getIternalKeyValue(key: string) {
    return `${APP_KEY_PREFIX}-${key}`;
}

function getStorageValue(key: string, defaultValue?: unknown) {
    try {
        const value = localStorage.getItem(getIternalKeyValue(key));
        return JSON.parse(value) ?? defaultValue;
    } catch (_error) {
        return defaultValue;
    }
}

function useLocalStorage(key: string, defaultValue?: unknown) {
    const [value, setValue] = useState(() => {
        return getStorageValue(key, defaultValue);
    });

    useEffect(() => {
        localStorage.setItem(
            getIternalKeyValue(key),
            JSON.stringify(value),
        );
    }, [key, value]);

    return [value, setValue];
};

export default useLocalStorage;
