import { OffsetPagination } from '@nexploretechnology/concreting-core-client';
import { useCallback } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

const DEFAULT_PAGINATION: OffsetPagination<any> = {
    skip: 0,
    limit: 20,
    sorting: []
};

function useSearchConcreteType() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();

    return useCallback(
        async (concreteTypeNumber: string) => {
            return api.catalogueManagementClient
                .getExtendedConcreteTypesForProject(companyId, projectId, {
                    concreteTypeNumber,
                    pagination: DEFAULT_PAGINATION
                })
                .then((response) => {
                    return response.getEntity();
                })
                .catch(() => []);
        },
        [api, companyId, projectId]
    );
}

export default useSearchConcreteType;
