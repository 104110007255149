import { ISupplierData } from '.';
import { MARKETS } from '../markets';
import { ORDER_STATUS } from '../orderStatus';

export const GODEL_DATA: ISupplierData = {
    market: MARKETS.DE,
    label: 'Pahm',
    name: ['Godel'],
    softwareName: 'godel.pahm',
    apiIntegration: true,
    permissions: {
        allowOrderEdit: true,
        allowOrderCancel: true,
        allowOrderAdditionalQuantity: false,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: true
    },
    custom: {
        orderValidations: {
            locationNameRules: [
                {
                    required: true,
                    max: 25
                }
            ],
            locationDescriptionRules: [
                {
                    required: true,
                    max: 25
                }
            ],
            additionalRequirementRules: [
                {
                    max: 250
                }
            ]
        },
        orderEditStatus: {
            allow: [
                ORDER_STATUS.INQUIRY_DRAFT,
                ORDER_STATUS.INQUIRY_REJECTED,
                ORDER_STATUS.INQUIRY_REVIEW,
                ORDER_STATUS.CONFIRMED,
                ORDER_STATUS.CHANGE_REQUEST
            ]
        }
    }
};
