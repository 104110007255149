import { FunctionComponent } from 'react';
import { exposureClassesFormatter } from 'src/app-react/utils/formatters';

interface ExposureClassesFormatterProps {
    x0: boolean;
    xc?: number;
    xd?: number;
    xfFirst?: number;
    xfSecond?: number;
    xa?: number;
    xm?: number;
    xs?: number;
}

const ExposureClassesFormatter: FunctionComponent<ExposureClassesFormatterProps> = (props) => {
    return <>{exposureClassesFormatter({x0: props.x0, xc: props.xc, xd: props.xd, xfFirst: props.xfFirst, xfSecond: props.xfSecond, xa: props.xa, xm: props.xm, xs: props.xs})}</>;
}

export default ExposureClassesFormatter;
