import { Trans } from "@lingui/macro";
import { Radio } from "antd";
import FormItem from "antd/lib/form/FormItem";

export function ExternalInspectionFormItem() {
    return (
        <FormItem
            name="useForPt"
            initialValue={true}
            label={<b><Trans>Use results for post-treatment</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                required: true,
                message: <Trans>This field is required</Trans>,

            }]}>
             <Radio.Group data-testid="useForPt" style={{ justifyContent: 'flex-start', gap: '1rem' }}>
                <Radio data-testid="useForPtRadioYes" value={true}>
                    <Trans>Yes</Trans>
                </Radio>
                <Radio data-testid="useForPtRadioNo" value={false}>
                    <Trans>No</Trans>
                </Radio>
            </Radio.Group>
        </FormItem>
    );
}

