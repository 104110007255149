import { CloseCircleOutlined, EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { PostTreatment } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import { postTreatmentMapper } from 'src/app-react/utils/formatters';

const getActions = (
    record: PostTreatment,
    onEdit: (record: PostTreatment) => void,
    onDelete: (id: string) => void
): MenuProps['items'] => {
    const items: MenuProps['items'] = [];

    items.push({
        label: <Trans>Edit</Trans>,
        key: `${record.id} Edit`,
        icon: <FormOutlined />,
        onClick: () => {
            onEdit(record);
        }
    });

    items.push({
        label: <Trans>Delete</Trans>,
        key: `delete`,
        icon: <CloseCircleOutlined />,
        onClick: () => {
            onDelete(record.id);
        }
    });

    return items;
};

export const postTreatmentTableColumns: (
    onEdit: (postTreatment: PostTreatment) => void,
    onDelete: (id: string) => void
) => ColumnsType<PostTreatment> = (onEdit: (postTreatment: PostTreatment) => void, onDelete: (id: string) => void) => {
    return [
        {
            title: <Trans>Post-treatment type</Trans>,
            dataIndex: 'types',
            key: 'types',
            sorter: (a, b) => a.types.localeCompare(b.types),
            render: (types: string, record: PostTreatment) =>
                types === 'other' ? <p>{record.typeOther}</p> : <p>{postTreatmentMapper[types]}</p>
        },

        {
            title: <Trans>Starting Date</Trans>,
            dataIndex: 'postTreatmentStart',
            key: 'postTreatmentStart',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.postTreatmentStart).unix() - moment(b.postTreatmentStart).unix(),
            render: (postTreatmentStart: Date) => <DateFormatter date={postTreatmentStart} />
        },

        {
            title: <Trans>Ending Date</Trans>,
            dataIndex: 'postTreatmentEnd',
            key: 'postTreatmentEnd',
            sorter: (a, b) => moment(a.postTreatmentEnd).unix() - moment(b.postTreatmentEnd).unix(),
            render: (postTreatmentEnd: Date) => <DateFormatter date={postTreatmentEnd} />
        },

        {
            title: <Trans>Duration</Trans>,
            dataIndex: 'duration',
            key: 'duration',
            sorter: false,
            render: (_, record: PostTreatment) => (
                <p>
                    {getActualNumberOfDays(new Date(record.postTreatmentEnd), new Date(record.postTreatmentStart)) ||
                        ''}{' '}
                    {t`day(s)`}
                </p>
            )
        },

        {
            title: <Trans>Comments</Trans>,
            dataIndex: 'comment',
            key: 'comment',
            sorter: false,
            render: (comment: string) => <p>{comment}</p>
        },

        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record: PostTreatment) => (
                <Dropdown
                    key="actionButton"
                    data-testid="actionButton"
                    menu={{ items: getActions(record, onEdit, onDelete) }}
                    trigger={['click']}>
                    <EllipsisOutlined />
                </Dropdown>
            )
        }
    ];
};

function getActualNumberOfDays(concretingEndDate: Date, postTreatmentEndDate: Date): number | undefined {
    if (concretingEndDate && postTreatmentEndDate) {
        concretingEndDate.setHours(0, 0, 0, 0);
        postTreatmentEndDate.setHours(0, 0, 0, 0);
        const timeDifference: number = Math.abs(concretingEndDate.getTime() - postTreatmentEndDate.getTime());
        const daysDiff: number = Math.round(timeDifference / (1000 * 60 * 60 * 24)) + 1;
        return daysDiff;
    }
    return undefined;
}
