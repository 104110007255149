import { IMarketUnits } from '../..';

const UNITS: IMarketUnits = {
    volume: 'yd3',
    water: 'l',
    density: 'lb/yd3',
    temperature: 'F°',
    compressiveStrength: 'psi',
    slump: 'in'
};

export default UNITS;
