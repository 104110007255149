import { t } from '@lingui/macro';
import { useContext, useEffect } from 'react';
import useSupplierOrderIds from 'src/app-react/business-page/concreting-documentation/hooks/useSupplierOrderIds';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import { mapFilters } from '../../util';
import { TableSearhFilter } from './TableSearchFilter';

export function SupplierOrderIdsInputItem() {
    const { loadSupplierOrderIds, supplierOrderIds, loading } = useSupplierOrderIds();
    const { filter, setFilter } = useContext(ConcretingDocumentationContext);

    useEffect(() => {
        loadSupplierOrderIds({ periodOfConcreting: filter?.deliveryTime });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.deliveryTime]);

    return (
        <TableSearhFilter
            placeholder={t`Search supplier order id`}
            options={mapFilters(supplierOrderIds)}
            onSearch={(query: string) => {
                loadSupplierOrderIds({ query, periodOfConcreting: filter?.deliveryTime });
            }}
            onChange={(values: string[]) => {
                setFilter((prev: any) => ({
                    ...prev,
                    supplierOrderId: values.length ? values : undefined
                }));
            }}
            loading={loading}
        />
    );
}
