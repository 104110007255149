import { FormInstance } from "antd";
import { SpecimenTestFormValuesUS } from "../US/models";
import { Trans } from "@lingui/macro";
import { CompactorBP, TestPurposeBPUS, TestSpecimenBPUS, TestTypeBPUS } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto";
import { FreshConcreteTest } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto";

export function createInitialValues(values: any) {
    const initialFormValues: SpecimenTestFormValuesUS = values;

    return {
        specimenIdentification: initialFormValues?.specimenIdentification,
        typeOfTesting: initialFormValues?.typeOfTesting,
        purposeOfTesting: initialFormValues?.purposeOfTesting,
        testingAge: initialFormValues?.testingAge?.toString(),
        mould: initialFormValues?.mould,
        compactor: initialFormValues?.compactor
    };
}

export function getSpecimentTestFormValuesUS(form: FormInstance<any>) {
    return {
        specimenIdentification: form.getFieldValue('specimenIdentification'),
        typeOfTesting: form.getFieldValue('typeOfTesting') as TestTypeBPUS,
        purposeOfTesting: form.getFieldValue('purposeOfTesting') as TestPurposeBPUS,
        testingAge: parseFloat(form.getFieldValue('testingAge') as string),
        mould: form.getFieldValue('mould'),
        compactor: form.getFieldValue('compactor') as CompactorBP
    };
}

export function createSpecimenTestUS(
    freshConcreteTest: FreshConcreteTest,
    specimenTestFormValues: SpecimenTestFormValuesUS
) {
    const specimenTest: TestSpecimenBPUS = {
        ...freshConcreteTest,
        testType: specimenTestFormValues.typeOfTesting,
        testPurpose: specimenTestFormValues.purposeOfTesting,
        testSpecimenIdentifier: specimenTestFormValues.specimenIdentification,
        mould: specimenTestFormValues.mould,
        compactor: specimenTestFormValues.compactor,
        testingAge: specimenTestFormValues.testingAge
    };

    return specimenTest;
}

export const specimenIdentificationTitleUS = <Trans>Cylinder identification</Trans>;