import { t } from "@lingui/macro";
import { Form, Switch } from "antd";
import requiredRule from "src/app-react/components/Form/Validations/requiredRule";

export function CanBeOrderedFormItem(): JSX.Element {
    return (
        <Form.Item
            name="canBeOrdered"
            valuePropName="checked"
            label={t`Available for ordering`}
            rules={[requiredRule]}>
            <Switch data-testid="canBeOrdered" />
        </Form.Item>
    );
}