import { Trans } from '@lingui/macro';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import { useContext } from 'react';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

const { RangePicker } = DatePicker;

export function CustomDeliveryTimeInputItem() {
    const { filter, setFilter } = useContext(ConcretingDocumentationContext);
    const { formats } = useProjectConstantsState();

    function onChange(value: RangePickerProps['value']) {
        if (value && value[0] && value[1]) {
            const startDate = value[0].startOf('day').toDate();
            const endDate = value[1].endOf('day').toDate();
            setFilter({ ...filter, deliveryTime: { startDate, endDate } });
        }
    }

    const startDate = moment(filter.deliveryTime?.startDate ?? new Date());
    const endDate = moment(filter.deliveryTime?.endDate ?? new Date());
    return (
        <>
            <b>
                <Trans>Custom period of concreting</Trans>
            </b>

            <RangePicker
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={[startDate, endDate]}
                format={formats.dateForm}
            />
        </>
    );
}
