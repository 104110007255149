import { BrowserRouter } from 'react-router-dom';
import AppRouter from './AppRouter';
import { DiscardChangeProvider } from './components/DiscardChangesModal/hooks/useDiscardChanges';
import { GlobalContextProvider } from './providers/GlobalContext';
import { LanguageProvider } from './providers/LanguageProvider';
import { UserPilotReloader } from './UserPilotReloader';

function App() {
    return (
        <BrowserRouter>
            <GlobalContextProvider>
                <LanguageProvider>
                    <DiscardChangeProvider>
                        <UserPilotReloader />
                        <AppRouter />
                    </DiscardChangeProvider>
                </LanguageProvider>
            </GlobalContextProvider>
        </BrowserRouter>
    );
}
export default App;
