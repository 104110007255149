import { t } from "@lingui/macro";
import { Select } from "antd";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";
import useProjectConstantsState from "src/app-react/hooks/useProjecsConstantsState";

export function AirMeasurementMethodFormItem() {
    const constants = useProjectConstantsState();

    return <SelectFormItem 
        label={t`Air measurement method`} 
        name={"airMeasurementMethod"}
        required={true}
    >
        <Select
            allowClear={false}
            options={constants.airMeasurmenMethod}
            data-testid="airMeasurementMethod"
        />
    </SelectFormItem>
}