import { Col, Row } from 'antd';
import { SupplierUIFormItem } from '../Form/components/FormItems/SupplierUIFormItem';
import { ManualSupplierNameFormItem } from './FormItems/ManualSupplierNameFormItem';

export function SupplierConfigManualForm() {
    return (
        <Row>
            <Col span={24}>
                <ManualSupplierNameFormItem />
            </Col>
            <Col span={11}>
                <SupplierUIFormItem />
            </Col>
        </Row>
    );
}
