import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col } from 'antd';
import { formatCompressiveStrengthDE } from 'src/app-react/business-page/catalogue-management/utils';
import styles from '../../ConcreteTypeModal.module.css';
import { empty } from '../../utils/utils';

export function CompressiveStrength({ concreteDetails }: { concreteDetails: SupplierProducedConcrete }) {
    const strengthClass = formatCompressiveStrengthDE(
        concreteDetails.typeOfConcrete,
        concreteDetails.cylindricCompressiveStrength,
        concreteDetails.cubicCompressiveStrength
    );

    return (
        <>
            <Col span={24}>
                <label className={styles.modalLabel}>
                    <Trans>Compressive strength</Trans>
                </label>
            </Col>
            <Col className={styles.colWithValue} span={24}>
                {strengthClass || empty}
            </Col>
        </>
    );
}
