import { Col, Row } from 'antd';
import ArrivalAtConstructionSiteFormItem from '../FormItems/ArrivalAtConstructionSiteFormItem';
import ConcretedVolumeFormItem from '../FormItems/ConcretedVolumeFormItem';
import LoadingStartFormItem from '../FormItems/LoadingStartFormItem';
import SignedOnFormItem from '../FormItems/SignedOnFormItem';
import UnloadingEndFormItem from '../FormItems/UnloadingEndFormItem';
import UnloadingStartFormItem from '../FormItems/UnloadingStartFormItem';
import WeatherFormItem from '../FormItems/WeatherFormItem';

export function OrderLiteModalForm3DefaultLayout() {
    return (
        <>
            <Row gutter={54}>
                <Col span="12">
                    <LoadingStartFormItem />
                </Col>
                <Col span="12">
                    <ArrivalAtConstructionSiteFormItem />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <UnloadingStartFormItem />
                </Col>
                <Col span="12">
                    <UnloadingEndFormItem />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <SignedOnFormItem />
                </Col>
                <Col span="12">
                    <ConcretedVolumeFormItem />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="24">
                    <WeatherFormItem />
                </Col>
            </Row>
        </>
    );
}
