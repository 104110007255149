import { MARKET_VALUES } from 'src/app-react/constants/markets';
import { MARKETS_DATA } from 'src/app-react/constants/marketsData';
import { SUPPLIER_VALUES } from 'src/app-react/constants/suppliers';
import { SUPPLIERS_DATA } from 'src/app-react/constants/suppliersData';

export default function getProjectConstants({ marketId, supplierId }: { marketId: string; supplierId?: string }) {
    return {
        units: {},
        formats: {},
        ...MARKETS_DATA[marketId as MARKET_VALUES]?.constants,
        ...SUPPLIERS_DATA[supplierId as SUPPLIER_VALUES]?.constants
    };
}
