/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-bind */
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import styles from './StructuralElementsTable.module.css';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

const NA = '';

export const StructuralElementsTableColumnsUS = (props: {
    onEdit: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onDuplicate: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onActiveInactive: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onAssign: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onUnassign: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onStructuralElementModal: (structuralElement: CataloguedStructuralElementExtendedBP) => void;
}): ColumnsType<CataloguedStructuralElementExtendedBP> => {
    const { onEdit, onDuplicate, onActiveInactive, onAssign, onUnassign, onStructuralElementModal } = props;

    const getActions = (record: CataloguedStructuralElementExtendedBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: <Trans>Edit</Trans>,
            key: `${record.id} Edit`,
            icon: <EditOutlined />,
            onClick: () => {
                onEdit(record);
            }
        });

        items.push({
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <EditOutlined />,
            onClick: () => {
                onDuplicate(record);
            }
        });

        items.push({
            label: <Trans>Assign concrete types</Trans>,
            key: `${record.id} AssignCT`,
            icon: <PlusCircleOutlined />,
            onClick: () => {
                onAssign(record);
            }
        });

        items.push({
            label: <Trans>Unassign concrete types</Trans>,
            key: `${record.id} UnassignCT`,
            icon: <DeleteOutlined />,
            onClick: () => {
                onUnassign(record);
            }
        });

        return items;
    };
    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<CataloguedStructuralElementExtendedBP>[] = [
        {
            title: <Trans>Structural element</Trans>,
            dataIndex: 'structuralElementName',
            sorter: true,
            render: (elementName: string, record: CataloguedStructuralElementExtendedBP) => renderElementName(record)
        },
        {
            title: <Trans>Compressive strength + unit</Trans>,
            dataIndex: 'minimalRequiredCompressiveStrength',
            sorter: true,
            render: (compressiveStrength: string, record: CataloguedStructuralElementExtendedBP) =>
                renderCompressiveStrength(record)
        },
        {
            title: <Trans>Description</Trans>,
            dataIndex: 'elementDescription',
            render: (elementDescription: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record ? record.structuralElementDescription : NA}</>
            )
        },
        {
            title: <Trans>Cost Code</Trans>,
            dataIndex: 'costCode',
            sorter: true,
            render: (costCode: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record ? record.costCode : NA}</>
            )
        },
        {
            title: <Trans>Active</Trans>,
            dataIndex: 'isActive',
            sorter: true,
            render: (active: boolean, record: CataloguedStructuralElementExtendedBP) => renderActive(record)
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'actions',
            hidden: isViewer,
            width: 50, // we set this width to have an alignment of this column with the other table having also 50 as width for the action column
            render: (id: string, record: CataloguedStructuralElementExtendedBP, index: number) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown menu={{items}} trigger={['click']}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                );
            }
        }
    ];

    return columns.filter((item) => !item.hidden);

    function renderElementName(record: CataloguedStructuralElementExtendedBP): JSX.Element {
        return (
            <RawLinkButton data-testid="editButton" onClick={() => onStructuralElementModal(record)}>
                {record ? record.structuralElementName : NA}
            </RawLinkButton>
        );
    }

    function renderCompressiveStrength(record: CataloguedStructuralElementExtendedBP): JSX.Element {
        return <>{record ? `${record.minimalRequiredCompressiveStrength} ${record.compressiveStrengthUnit}` : NA}</>;
    }

    function renderActive(record: CataloguedStructuralElementExtendedBP): JSX.Element {
        function onChange(): void {
            if (record) {
                onActiveInactive(record);
            }
        }
        if (record) {
            return <Checkbox onChange={onChange} checked={record.isActive} />;
        }
        return <></>;
    }
};
