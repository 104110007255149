import { Trans, t } from '@lingui/macro';
import { DeliveryOverviewStatus } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Col, Row } from 'antd';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import styles from '../DeliveryOverviewStatusCard.module.css';

function BarCol(value: number, _unit: string, _percent: number, backgroundColor: string): JSX.Element {
    if (value > 0) {
        return (
            <Col className={styles.barCol} flex={_percent} style={{ backgroundColor }}>
                <NumberFormatter value={value} unit={_unit} />
            </Col>
        );
    }

    return null;
}

function LegendCol(text: string, backgroundColor: string): JSX.Element {
    return (
        <Col>
            <Row gutter={[4, 0]} className={styles.cardLegendCol} align="middle">
                <Col>
                    <div className={styles.cardCircle} style={{ backgroundColor }} />
                </Col>
                <Col>{text}</Col>
            </Row>
        </Col>
    );
}

interface Props {
    statusOverview: DeliveryOverviewStatus;
    percent: DeliveryOverviewStatus;
}

export function StatusOverview({ statusOverview, percent }: Props): JSX.Element {
    const { units } = useProjectConstantsState();

    if (!statusOverview || !percent) return null;

    return (
        <>
            <Row className={styles.overviewTitle}>
                <Trans>Delivery progress</Trans>
            </Row>

            <Row className={styles.overviewRowCard}>
                <Col span={24}>
                    <div className={styles.chartBar}>
                        <Row className={styles.overviewRowCol} align="middle">
                            {BarCol(
                                statusOverview.deliveredAndAccepted,
                                units.volume,
                                percent.deliveredAndAccepted,
                                '#52C41A'
                            )}
                            {BarCol(statusOverview.inProgress, units.volume, percent.inProgress, '#FA8C16')}
                            {BarCol(statusOverview.declined, units.volume, percent.declined, '#F5222D')}
                            {BarCol(statusOverview.queued, units.volume, percent.queued, '#13C2C2')}
                            {BarCol(
                                statusOverview.aditionalRequired,
                                t`${units.volume}`,
                                percent.aditionalRequired,
                                '#BFBFBF'
                            )}
                        </Row>
                    </div>

                    <br />
                    <hr className={styles.overviewBreak} />

                    <Row className={styles.overviewLegendCol} gutter={[16, 0]} align="middle" justify="end">
                        {LegendCol(t`Delivered`, '#52C41A')}
                        {LegendCol(t`Delivery in progress`, '#FA8C16')}
                        {LegendCol(t`Rejected`, '#F5222D')}
                        {LegendCol(t`Queued`, '#13C2C2')}
                        {LegendCol(t`Requested`, '#BFBFBF')}
                    </Row>
                </Col>
            </Row>
        </>
    );
}
