import { t } from '@lingui/macro';
import { Steps } from 'antd';
import { OrderLoadLiteTypesValues, ORDER_LOAD_LITE_TYPES } from '../models/Types';

interface OrderLoadLiteModalStepsProps {
    page: number;
    type: OrderLoadLiteTypesValues;
}

function OrderLoadLiteModalSteps({ page, type }: OrderLoadLiteModalStepsProps) {
    const current = type === ORDER_LOAD_LITE_TYPES.MANUAL ? page - 1 : page;
    const commonSteps = (
        <>
            <Steps.Step title={t`Basic information`} />
            <Steps.Step title={t`Concrete properties`} />
            <Steps.Step title={t`Timestamps`} />
        </>
    );

    let steps;

    switch (type) {
        case ORDER_LOAD_LITE_TYPES.QR:
            steps = (
                <>
                    <Steps.Step title={t`QR`} />
                    {commonSteps}
                </>
            );
            break;
        default:
            steps = commonSteps;
    }

    return <Steps current={current}>{steps}</Steps>;
}

export default OrderLoadLiteModalSteps;
