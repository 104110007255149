import { Trans } from '@lingui/macro';
import { Badge, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import ConcreteTypesTable from '../ConcreteTypesTable2/ConcreteTypesTable';
import StructuralElementsTable from '../StructuralElementsTable/StructuralElementsTable';

export interface CatalogueTabsProps {
    reLoad: boolean;
    setReLoad: (value: boolean) => void;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const CatalogueTabs = ({ reLoad, setReLoad, setActiveTab }: CatalogueTabsProps) => {
    const [structuralElementsCount, setStructuralElementsCount] = useState(0);
    const [concreteTypeCount, setConcreteTypeCount] = useState(0);

    useEffect(() => {
        setActiveTab('StructuralElementsTable');
    }, []);

    const items = [
        {
            label: (
                <>
                    <Trans>Structural elements</Trans>
                    <Badge count={structuralElementsCount} />
                </>
            ),
            key: 'StructuralElementsTable',
            children: (
                <StructuralElementsTable reLoad={reLoad} setReLoad={setReLoad} setCount={setStructuralElementsCount} />
            ),
            forceRender: true
        },
        {
            label: (
                <>
                    <Trans>Concrete Types</Trans>
                    <Badge count={concreteTypeCount} />
                </>
            ),
            key: 'ConcreteTypesTable',
            children: (
                <ConcreteTypesTable
                    reLoad={reLoad}
                    setReLoad={setReLoad}
                    expandable={true}
                    count={concreteTypeCount}
                    setCount={setConcreteTypeCount}
                />
            ),
            forceRender: true
        }
    ];

    return <Tabs items={items} defaultActiveKey="StructuralElementsTable" onChange={setActiveTab} />;
};

export default CatalogueTabs;
