import { t } from '@lingui/macro';
import { TableSearhFilter } from './TableSearchFilter';
import useUserManagementState from 'src/app-react/hooks/useUserManagermentState';

export function UserNameFilter() {
    const { loading, setFilter } = useUserManagementState();

    return (
        <TableSearhFilter
            placeholder={t`Search user name`}
            onSearch={(query: string) => {
                setFilter({ userName: query });
            }}
            loading={loading}
        />
    );
}
