import { t, Trans } from '@lingui/macro';
import { Select } from 'antd';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DEBOUNCE_TIME } from '../../DeliveryDashboardTableViews';
import { DeliveryDashboardContext } from '../../../context/DeliveryDahsboardPageContext';

export function DeliveryNoteNumberInputItem() {
    const { projectId, companyId } = useProjectState();
    const api = useApi();
    const [deliveryNumbers, setDeliveryNumbers] = useState<string[]>([]);
    const { filter, setFilter } = useContext(DeliveryDashboardContext);

    function onChange(value: string[]) {
        const newFilter = { ...filter, deliveryNumbers: value };
        setFilter(newFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadDeliveryNoteNumbers = useCallback(
        debounce(() => {
            api.deliveryNoteLiteClient
                .getDeliveryNumbersByProject(companyId, projectId, filter, undefined)
                .then((response) => {
                    if (response.isSuccess()) {
                        setDeliveryNumbers(response.getEntity());
                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }, DEBOUNCE_TIME),
        [filter]
    );

    useEffect(() => {
        loadDeliveryNoteNumbers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <>
            <b>
                <Trans>Delivery note number</Trans>
            </b>
            <Select
                data-testid="deliverynumber"
                mode="multiple"
                allowClear
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={filter?.deliveryNumbers}
                placeholder={t`Select delivery note number`}
                style={{ width: '100%' }}
                options={deliveryNumbers?.map((delivery) => ({ label: delivery, value: delivery }))}
            />
        </>
    );
}
