import { Trans } from '@lingui/macro';
import { Form, Radio, Space } from "antd";

export function FormUS() {

  return <>
    <Form.Item
      name="fileType"
      required
      label={
        <span style={{ fontWeight: 'bold', color: '#1F3855' }}>
          <Trans>File type</Trans>
        </span>
      }>

      <Radio.Group>
        <Space direction="vertical">
          <Radio
            value="extended-concreting-documentation"
            data-testid="extended-concreting-documentation">
            <label>
              <Trans>Extended concreting documentation</Trans>
            </label>{' '}
            <br />
            <label style={{ color: 'gray', fontSize: '10pt' }}>
              <Trans>
                Expert version, can be used e.g. for internal analysis of orders and
                deliveries
              </Trans>
            </label>
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  </>
}