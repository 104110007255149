const SPACING_IN_MINUTES_DATA = [
    { value: 0, label: 'Not required' },
    { value: 4, label: '4 min' },
    { value: 5, label: '5 min' },
    { value: 6, label: '6 min' },
    { value: 7, label: '7 min' },
    { value: 8, label: '8 min' },
    { value: 10, label: '10 min' },
    { value: 12, label: '12 min' },
    { value: 15, label: '15 min' },
    { value: 20, label: '20 min' },
    { value: 25, label: '25 min' },
    { value: 30, label: '30 min' },
    { value: 35, label: '35 min' },
    { value: 40, label: '40 min' },
    { value: 45, label: '45 min' },
    { value: 50, label: '50 min' },
    { value: 60, label: '1 hour' },
    { value: 80, label: '1 hour 20 min' },
    { value: 90, label: '1 hour 30 min' },
    { value: 100, label: '1 hour 40 min' },
    { value: 120, label: '2 hour' }
];

export default SPACING_IN_MINUTES_DATA;
