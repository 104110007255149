import { Trans } from '@lingui/macro';
import { Button, Col, Row, Space } from 'antd';
import ThrottleButton from 'src/app-react/components/Form/ThrottleButton';

interface OrderLiteModalFooterProps {
    disabled: boolean;
    page: number;
    maxPage: number;
    minPage: number;
    onSubmit: () => void;
    onNext: () => void;
    onPrev: () => void;
    onClose: () => void;
}

function OrderLiteModalFooter(props: OrderLiteModalFooterProps) {
    const { page, maxPage, minPage, disabled, onClose, onPrev, onNext, onSubmit } = props;

    return (
        <Row justify="space-between">
            <Col span={8}>
                <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={onClose} data-testid="order-lite-modal-footer-cancel">
                        <Trans>Cancel</Trans>
                    </Button>
                    {page > minPage && (
                        <Button onClick={onPrev} data-testid="order-lite-modal-footer-previous">
                            <Trans>Previous</Trans>
                        </Button>
                    )}
                </Space>
            </Col>
            <Col span={8}>
                <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {page < maxPage && (
                        <>
                            <ThrottleButton onClick={onNext} type="primary" data-testid="order-lite-modal-footer-next">
                                <Trans>Next</Trans>
                            </ThrottleButton>
                            {page > 1 && (
                                <ThrottleButton
                                    onClick={onSubmit}
                                    disabled={disabled}
                                    type="primary"
                                    data-testid="order-lite-modal-footer-submit">
                                    <Trans>Skip & save</Trans>
                                </ThrottleButton>
                            )}
                        </>
                    )}

                    {page >= maxPage && (
                        <ThrottleButton
                            onClick={onSubmit}
                            disabled={disabled}
                            type="primary"
                            data-testid="order-lite-modal-footer-submit">
                            <Trans>Save</Trans>
                        </ThrottleButton>
                    )}
                </Space>
            </Col>
        </Row>
    );
}

export default OrderLiteModalFooter;
