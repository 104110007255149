import { t, Trans } from "@lingui/macro";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import TimePicker from "src/app-react/components/Form/TimePicker";

export function MeasurementTimeFormItem() {
    return (
        <FormItem
            name="timeMeasurement"
            label={<b><Trans>Measurement Time</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                required: true,
                message: <Trans>Please select a valid measurement time</Trans>,

            }]}>
             <TimePicker
                data-testid="timeMeasurement"
                value={moment()}
                placeholder={t`Select testing time`}
                />
        </FormItem>
    );
}

