import { Trans } from "@lingui/macro";
import { Col, Row } from "antd";
import { MeasurementTable } from "./components/MeasurementTable";

export function Measurements() {

    return (
        <>
            <Row gutter={[8, 20]}>
                <Col span={24}>
                    <Row>
                        <Col span={9} style={{marginBottom: '1rem'}}>
                            <b>
                                <Trans>Temperature measurements</Trans>
                            </b>
                        </Col>
                        <Col span={24}>
                            <MeasurementTable  measurementType="temperature"/>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={9} style={{marginBottom: '1rem'}}>
                            <b>
                                <Trans>Compressive strength measurements</Trans>
                            </b>
                        </Col>
                        <Col span={24}>
                        <MeasurementTable  measurementType="compressiveStrength"/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}
