import { t, Trans } from '@lingui/macro';
import { ExtendedManufacturingFlowBP } from '@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.dto';
import { InputOrderBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { Button, Form, Input, Modal, Row, Space } from 'antd';
import { useContext, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { unitFormatter } from 'src/app-react/utils/formatters';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';

export interface AdditionalQuantityModalFormValues {
    currentQuantity: number;
    quantity: number;
}

function AdditionalQuantityModal(props: {
    projectId: string;
    orderId: string;

    open: boolean;
    setOpen: (isVisible: boolean) => void;

    initialFormValues: AdditionalQuantityModalFormValues;
    refreshData: () => void;
}) {
    const { open, setOpen, initialFormValues, refreshData } = props;
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { companyId, projectId, marketId } = useProjectState();
    const [page, showPage] = useState<number>(1);
    const api = useApi();

    const [form] = Form.useForm();

    const initialValues = {
        quantity: initialFormValues.quantity
    };

    function getFormValues(): AdditionalQuantityModalFormValues {
        return {
            currentQuantity: initialFormValues.currentQuantity,
            quantity: parseFloat(form.getFieldValue('quantity'))
        };
    }

    async function form_onOk(): Promise<void> {
        try {
            if (await form.validateFields()) {
                switch (page) {
                    case 1:
                        showPage(2);
                        break;
                    case 2:
                        const formValues: AdditionalQuantityModalFormValues = getFormValues();

                        setOpen(false);
                        onSubmit(formValues);

                        showPage(1);
                        break;
                }
            }
        } catch (errorInfo) {
            // Must be empty. This catch is necessary for the tests, without the tests are failing. In normal usage ant design displays the required field information.
        }
    }

    function form_onCancel(): void {
        setOpen(false);
        showPage(1);
    }

    return (
        <Modal
            maskClosable={false}
            title={page === 1 ? t`Request additional quantity` : t`Confirm additional quantity request`}
            open={open}
            destroyOnClose
            width={500}
            onOk={form_onOk}
            onCancel={form_onCancel}
            footer={[
                <Button
                    data-testid="AdditionalQuantityModal.cancelButton"
                    key="cancelButton"
                    onClick={() => {
                        page === 1 ? form_onCancel() : showPage(1);
                    }}>
                    {page === 1 ? <Trans>Cancel</Trans> : <Trans>Back</Trans>}
                </Button>,
                <Button
                    data-testid="AdditionalQuantityModal.okButton"
                    key="okButton"
                    onClick={form_onOk}
                    type="primary">
                    {page === 1 ? <Trans>Next</Trans> : <Trans>Send request</Trans>}
                </Button>
            ]}>
            <Form form={form} initialValues={initialValues} layout="vertical" preserve={false}>
                <Row>
                    {page === 1 ? (
                        <Trans>Please enter the amount of additional concrete quantity you want to request.</Trans>
                    ) : (
                        <>
                            <Trans>Do you want to request</Trans>
                            &nbsp;
                            <b>
                                {getFormValues().quantity} {unitFormatter(marketId === 'DE' ? 'm3' : 'yd3')}
                            </b>
                            &nbsp;
                            <Trans>of additional concrete?</Trans>
                        </>
                    )}
                </Row>

                <br />

                <Row>
                    {page === 1 ? (
                        <Space direction="vertical">
                            <Trans>Current quantity</Trans>
                            {`${initialFormValues.currentQuantity} ${unitFormatter(marketId === 'DE' ? 'm3' : 'yd3')}`}
                        </Space>
                    ) : (
                        <Space direction="vertical">
                            <Trans>New total quantity</Trans>
                            {`${initialFormValues.currentQuantity + getFormValues().quantity} ${unitFormatter('m3')}`}
                        </Space>
                    )}
                </Row>

                <br />

                <Form.Item
                    hidden={page === 2}
                    name="quantity"
                    label={<Trans>Additional concrete quantity</Trans>}
                    rules={[
                        {
                            required: true,
                            pattern: new RegExp(/^[1-9]\d*$/),
                            message: t`This field is required`
                        }
                    ]}>
                    <Input
                        type="number"
                        data-testid="quantity"
                        addonAfter={unitFormatter(marketId === 'DE' ? 'm3' : 'yd3')}
                        placeholder={t`Enter the quantity`}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );

    async function onSubmit(formValues: AdditionalQuantityModalFormValues) {
        const { quantity } = formValues;
        let order: ExtendedManufacturingFlowBP;
        let inputOrder: InputOrderBP;
        api.orderManagementClient
            .loadExtendedManufacturingFlow(projectId, companyId, orderId)
            .then((response) => {
                if (response.isSuccess()) {
                    order = response.getEntity();

                    inputOrder = {
                        deliveryTime: order.order.deliveryTime,
                        orderedCataloguedConcreteTypeId: order.order.orderedCataloguedConcreteTypeId,
                        orderedConcreteTypeNumber: order.concreteType.typeNumber,
                        cataloguedStructuralElementIds: [order.concretedStructuralElement.id],
                        volume: order.order.volume + quantity,
                        volumeUnit: order.order.volumeUnit,
                        performance: order.order.performance,
                        performanceUnit: order.order.performanceUnit,
                        additionalQuantityRequired: order.order.additionalQuantityRequired,
                        additionalRequirements: order.order.additionalRequirements,
                        unloadingLocationId: order.order.unloadingLocationId,
                        componentAllocation: order.concretedStructuralElement.elementName,
                        spacingInMinutes: order.order.spacingInMinutes,
                        slump: order.order.slump,
                        loadSize: order.order.loadSize,
                        addressStreet: order.order.addressStreet,
                        addressBuildingNumber: order.order.addressBuildingNumber,
                        addressPostCode: order.order.addressPostCode,
                        addressCity: order.order.addressCity,
                        addressCountry: order.order.addressCountry
                    };
                    if (inputOrder) {
                        api.orderManagementClient
                            .createChangeRequestInputOrder(projectId, orderId, companyId, inputOrder)
                            .then((responseCreate) => {
                                if (responseCreate.isSuccess()) {
                                    refreshData();
                                } else {
                                    ErrorNotification({
                                        message: responseCreate.getError(),
                                        description: ''
                                    });
                                }
                            })
                            .catch((info) => {
                                ErrorNotification({
                                    message: info.errorFields[0].errors[0],
                                    description: ''
                                });
                            });
                    }
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            })
            .catch((err) => {
                ErrorNotification({
                    message: err.errorFields[0].errors[0],
                    description: ''
                });
            });
    }
}

export default AdditionalQuantityModal;
