import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function AppCodeFormItem() {
    return (
        <FormItem
            name="appCode"
            label={<Trans>App Code</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="text" data-testid="appCode" placeholder={t`Enter app code`} />
        </FormItem>
    );
}
