import { PlusSquareOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import {
  ConcretedStructuralElementTest,
  TypeMeasurement
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Button, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { CompressiveMeasurementEditModal } from '../CompressiveMeasurementEditModal';
import { MeasurementDeleteModal } from '../MeasurementDeleteModal';
import { TemperatureMeasurementEditModal } from '../TemperatureMeasurementEditModal';
import { compressiveMeasurementsTableColumns } from './compressiveColumnsTable';
import { temperatureMeasurementsTableColumns } from './temperatureColumnsTable';

interface MeasurementTableProps {
    measurementType: TypeMeasurement;
}
export function MeasurementTable(props: MeasurementTableProps) {
    const { measurementType } = props;
    const [measurementForUpdate, setMeasurementForUpdate] = useState({} as ConcretedStructuralElementTest);
    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId, marketId } = useProjectState();
    const [measurements, setMeasurements] = useState([] as ConcretedStructuralElementTest[]);
    const [loadTable, setLoadTable] = useState(true);
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
    const api = useApi();

    async function loadMeasurements() {
        api.concretingDocumentationClient
            .loadCoseTestByTypeMeasurement(companyId, projectId, structuralElementId, measurementType)
            .then((response) => {
                if (response.isSuccess()) {
                    const result: ConcretedStructuralElementTest[] = response.getEntity();
                    setMeasurements(result);
                    setLoadTable(false);
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function onCreate() {
        setMeasurementForUpdate(null);
        setEditModalVisible(true);
    }

    function onEdit(measurement: ConcretedStructuralElementTest) {
        setMeasurementForUpdate(measurement);
        setEditModalVisible(true);
    }

    function onDelete(measurementTestId: string) {
        MeasurementDeleteModal({
            type: measurementType,
            companyId,
            projectId,
            structuralElementId,
            measurementTestId,
            triggerTableReload: reloadTable,
            concretingDocumentationClient: api.concretingDocumentationClient
        });
    }

    function reloadTable() {
        setLoadTable(true);
    }

    useEffect(() => {
        if (loadTable) {
            loadMeasurements();
        }
    }, [loadTable, projectId, companyId]);

    return (
        <>
            <Table
                style={{ marginTop: '1.5rem' }}
                data-testid="measurementTable"
                rowClassName={measurementType === 'temperature' ? 'temperatureRow' : 'compressiveRow'}
                rowKey="id"
                columns={
                    measurementType === 'temperature'
                        ? temperatureMeasurementsTableColumns(marketId, onEdit, onDelete)
                        : compressiveMeasurementsTableColumns(marketId, onEdit, onDelete)
                }
                dataSource={measurements}
                loading={loadTable}
                pagination={false}
            />

            <Button
                style={{ width: '100%', height: '3.5rem', color: '#024A94', marginTop: '.5rem' }}
                onClick={onCreate}>
                <PlusSquareOutlined />{' '}
                {measurementType === 'temperature' ? (
                    <Trans>Record temperature measurement</Trans>
                ) : (
                    <Trans>Record compressive strength measurement</Trans>
                )}
            </Button>

            {measurementType === 'temperature' && (
                <TemperatureMeasurementEditModal
                    measurementTest={measurementForUpdate}
                    open={editModalVisible}
                    setOpen={setEditModalVisible}
                    triggerTableReload={reloadTable}
                />
            )}

            {measurementType === 'compressiveStrength' && (
                <CompressiveMeasurementEditModal
                    measurementTest={measurementForUpdate}
                    open={editModalVisible}
                    setOpen={setEditModalVisible}
                    triggerTableReload={reloadTable}
                />
            )}
        </>
    );
}
