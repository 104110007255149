import { t, Trans } from '@lingui/macro';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export function MeasurementDateFormItem() {
    return (
        <FormItem
            name="dateMeasurement"
            label={
                <b>
                    <Trans>Measurement Date</Trans>
                </b>
            }
            style={{ gap: '.2rem' }}
            rules={[
                {
                    required: true,
                    message: <Trans>Please select a valid measurement date</Trans>
                    // validator: postTreatmentEndingDateValidator
                }
            ]}>
            <DatePicker
                style={{ width: '100%' }}
                data-testid="timeMeasurement"
                value={moment()}
                placeholder={t`Select testing date`}
                // disabledDate={disabledDateHandler}
            />
        </FormItem>
    );
}
