import { Trans } from '@lingui/macro';
import { Row, Col } from 'antd';
import { AirTemperatureItem } from '../../../../../../../shared/Forms/StructuralElement/FormItems/AirTemperatureItem';
import { NameItem } from '../../../../../../../shared/Forms/StructuralElement/FormItems/NameItem';
import Title from 'antd/lib/typography/Title';
import { SlumpItem } from 'src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/SlumpItem';
import { CostCodeItem } from 'src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/CostCodeItem';
import { MassConcreteItem } from 'src/app-react/business-page/shared/Forms/StructuralElement/FormItemsUS/MassConcreteItem';
import MethodOfConcreteFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MethodOfConcreteFormItem';
import CompressiveStrengthFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/CompressiveStrengthFormItem';

function FormUS() {
    return (
        <>
            <Row>
                <Col span={24}>
                    <NameItem />
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <CompressiveStrengthFormItem required={false} />
                </Col>
                <Col span={12}>
                    <SlumpItem />
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <MethodOfConcreteFormItem name="methodConcreteUseChloride" />
                </Col>
                <Col span={12}>
                    <MassConcreteItem />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <CostCodeItem />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Title level={5} data-testid="title-air-temperature">
                        <Trans>Air temperature during concreting</Trans>
                    </Title>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <AirTemperatureItem />
            </Row>
        </>
    );
}

export default FormUS;
