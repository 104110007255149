import { QuestionCircleOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { InputUpdateStatusConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect } from 'react';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getAuthService } from 'src/app-react/services/authService';
import { mergeDateTime } from '../../../../utils/lib';
import DeliveryNoteDetailsContext from '../../context/delivery-note-details-context';
import useContextOrProps from '../../hooks/useContextOrProps';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import styles from './StartUnloadingModal.module.css';

export interface IStartUnloadingForm {
    time: moment.Moment;
    date: moment.Moment;
    comment: string;
}
const marketIdDE = 'DE';
export interface IStartUnloadingModalProps {
    concreteLoadId?: string;
    deliveryNoteNo: string | undefined;
    licensePlate: string;
    arrivalDateTime: Date | undefined;
    processableUntil: Date;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}

function StartUnloadingModal(props: IStartUnloadingModalProps) {
    const { concreteLoadId } = useContextOrProps(['concreteLoadId'], props, DeliveryNoteDetailsContext);
    const { deliveryNoteNo, licensePlate, arrivalDateTime, processableUntil, open, setOpen, onComplete } = props;
    const [startUnloadingForm] = Form.useForm();
    const { orderId } = useContext(DeliveryNoteManagementContext);

    const api = useApi();

    const { projectId, companyId, marketId } = useProjectState();
    const arrivalMoment: moment.Moment = moment(arrivalDateTime);

    useEffect(() => {
        updateValue(new Date());
    }, [open, projectId, companyId]);

    const updateValue = (date: Date): void => {
        startUnloadingForm.setFieldsValue({ date: moment(date), time: moment(), comment: '' });
    };
    const dateValidator = (rule: RuleObject, date: moment.Moment, callback: (error?: string) => void) => {
        const time: moment.Moment = startUnloadingForm.getFieldValue('time');
        const dateTimeAction: moment.Moment = mergeDateTime(date, time);
        if (dateTimeAction.isSameOrAfter(arrivalMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const timeValidator = (rule: RuleObject, time: moment.Moment, callback: (error?: string) => void) => {
        const date: moment.Moment = startUnloadingForm.getFieldValue('date');
        const dateTimeAction: moment.Moment = mergeDateTime(date, time);
        if (dateTimeAction.isSameOrAfter(arrivalMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const disabledDateHandler = (current: moment.Moment | null): boolean => {
        if (!current) {
            return false;
        }
        return current.isAfter(moment(), 'day') || current.isBefore(arrivalMoment, 'day');
    };

    const commentHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        startUnloadingForm.setFieldsValue({ comment: e.target.value });
    };

    const onSubmit = async (): Promise<void> => {
        try {
            const { date, time, comment }: IStartUnloadingForm = await startUnloadingForm.validateFields();
            setOpen(false);
            const { userId } = getAuthService().user;
            const payload: InputUpdateStatusConcreteLoad = {
                status: 'Unloading',
                dateTimeAction: mergeDateTime(date, time).toDate(),
                inputCommentConcreteLoad: comment
                    ? {
                          concreteLoadId,
                          comment,
                          actionConcreteLoad: 'startUnloading',
                          createdBy: userId
                      }
                    : undefined
            };

            api.deliveryNoteClient
                .updateConcreteLoadById(companyId, projectId, orderId, concreteLoadId, payload)
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`Start unloading updated successfully`,
                            description: ''
                        });
                        startUnloadingForm.resetFields();
                        onComplete?.();
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                });
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    const onCancel = (): void => {
        startUnloadingForm.resetFields();
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="unloading-modal"
            forceRender
            getContainer={false}
            title={t`Start Unloading`}
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button
                    className={styles.modalButton}
                    onClick={onSubmit}
                    data-testid="submit"
                    type="primary"
                    key={`submit-btn-${concreteLoadId}`}>
                    <Trans>Start Unloading</Trans>
                </Button>
            ]}>
            <Form
                form={startUnloadingForm}
                data-testid="StartUnloadingForm"
                key={`modal-form-${concreteLoadId}`}
                initialValues={{ date: moment(), time: moment(), comment: '' }}>
                <Trans>Please enter the time when the truck starts unloading.</Trans>
                <Row gutter={8}>
                    <Col span="11">
                        <Row>
                            <label className={styles.modalLabel}>
                                {marketId === marketIdDE ? (
                                    <Trans>Delivery note number</Trans>
                                ) : (
                                    <Trans>Ticket number</Trans>
                                )}
                            </label>
                        </Row>
                        <Row>
                            <p>{deliveryNoteNo}</p>
                        </Row>
                    </Col>
                    <Col span="13">
                        <Row>
                            <label className={styles.modalLabel}>
                                {marketId === marketIdDE ? <Trans>License Plate</Trans> : <Trans>Truck number</Trans>}
                            </label>
                        </Row>
                        <Row>
                            <p>{licensePlate}</p>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span="11">
                        <Form.Item
                            label={<Trans>Start Date</Trans>}
                            className={styles.colInline}
                            name="date"
                            rules={[
                                { required: true, message: t`Please input date` },
                                {
                                    message: t`Start unloading date must be between arrival date and now`,
                                    validator: dateValidator
                                }
                            ]}>
                            <DatePicker
                                data-testid="date-input"
                                value={moment()}
                                placeholder={t`Enter Date`}
                                disabledDate={disabledDateHandler}
                            />
                        </Form.Item>
                    </Col>
                    <Col span="11">
                        <Form.Item
                            label={<Trans>Start Time</Trans>}
                            className={styles.colInline}
                            name="time"
                            rules={[
                                { required: true, message: t`Please input time` },
                                {
                                    message: t`Start unloading date must be between arrival date and now`,
                                    validator: timeValidator
                                }
                            ]}>
                            <TimePicker value={moment()} />
                        </Form.Item>
                    </Col>
                </Row>
                {marketId === marketIdDE && processableUntil ? (
                    <Row>
                        <Trans>Delivery should be unloaded until</Trans>
                        &nbsp;
                        <span> {moment(processableUntil).format('HH:mm a')}</span>
                    </Row>
                ) : (
                    <></>
                )}
                <Row>
                    <Col span="24">
                        <label className={styles.modalLabel}>
                            <Trans>Comment</Trans>
                            <span>
                                <QuestionCircleOutlined /> (optional)
                            </span>
                        </label>
                        <Form.Item name="comment">
                            <Input.TextArea
                                data-testid="comment-input"
                                placeholder={t`Write a comment e.g. about the concrete mix`}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                onChange={commentHandler}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default StartUnloadingModal;
