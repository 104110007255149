import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Col, Form, Input, Row } from 'antd';
import ConsistencyClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/ConsistencyClassFormItem';
import ExposureClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/ExposureClassesFormItem';
import MoistureClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MoistureClassFormItem';
import CompressiveStrengthClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/CompressiveStrengthClassFormItem';
import StrengthDevelopmentClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/StrengthDevelopmentClassFormItem';
import MaxAggregateSizeFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MaxAggregateSizeFormItem';
import DensityClassFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/DensityClassFormItem';
import MethodConcreteUseChlorideFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/MethodOfConcreteUseChlorideFormItem';
import { ExternalInspectionFormItem } from 'src/app-react/business-page/shared/FormItems/Catalogue/DE/ExternalInspectionFormItem';
import { CanBeOrderedFormItem } from '../../FormItems/CanBeOrderedFormItem';
import { TestingAgeFormItem } from '../../FormItems/TestingAgeFormItem';
import { AssignToStructuralElementsFormItem } from './FormItems/AssignToStructuralElementsFormItem';
import InputFormItem from 'src/app-react/components/Form/FormItem/InputFormItem';
import { AdditionalAttributesFormItem } from '../US/FormItems/AdditionalAttributesFormItem';
import { AggregatesFormItem } from './FormItems/AggregatesFormItem';
import { CementsFormItem } from '../../FormItems/CementsFormItem';
import { AdmixturesFormItem } from '../../FormItems/AdmixturesFormitem';
import { SupplementaryCementiousMaterialsFormItem } from '../../FormItems/SupplementaryCementiousMaterialsFormItem';
import { WaterCementRatioFormItemDE } from './FormItems/WaterCementRatioFormItemDE';
import { useWatch } from 'antd/lib/form/Form';
import useFormInstance from 'antd/lib/form/hooks/useFormInstance';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

interface Props {
    additionalAttributes: string[];
    assignableStructuralElements: CataloguedStructuralElementExtendedBP[];
}

export function ConcreteTypeModalFormDE({ additionalAttributes, assignableStructuralElements }: Props) {
    const form = useFormInstance();

    const { units } = useProjectConstantsState();

    const compressiveStrengthClass = useWatch(['compressiveStrengthClass', 'requiredTypeOfConcreteType'], form);

    return (
        <>
            <Row>
                <Trans>Please select or fill the required fields to create a concrete type.</Trans>
            </Row>

            <br />

            <Row gutter={12}>
                <Col span="12">
                    <InputFormItem required name="typeNumber" label={t`Concrete type number`}>
                        <Input type="text" placeholder={t`Enter concrete type number`} data-testid="typeNumber" />
                    </InputFormItem>
                </Col>
                <Col span="12">
                    <InputFormItem label={t`Concrete type description`} name="description">
                        <Input type="text" placeholder={t`Enter concrete type description`} data-testid="description" />
                    </InputFormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span="12">
                    <Form.Item
                        name="price"
                        label={t`Price`}
                        rules={[
                            {
                                pattern:
                                    i18n.locale === 'de-DE'
                                        ? new RegExp(/^\d+(?:,\d{1,2})?$/)
                                        : new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                message: t`Please input price`
                            }
                        ]}>
                        <Input placeholder={t`Enter price`} data-testid="price" addonAfter={t`€/${units.density}`} />
                    </Form.Item>
                </Col>
                <Col span="12">
                    <CanBeOrderedFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <br />

            <Row gutter={12}>
                <Col span="12">
                    <CompressiveStrengthClassFormItem required />
                </Col>
                <Col span="12">
                    <ConsistencyClassFormItem />
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span="12">
                    <MoistureClassFormItem />
                </Col>
                <Col span="12">
                    <StrengthDevelopmentClassFormItem />
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <ExposureClassFormItem parentName="exposureClasses" />
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span="12">
                    <MaxAggregateSizeFormItem required />
                </Col>
                <Col span="12">
                    <MethodConcreteUseChlorideFormItem required />
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span="12">
                    <TestingAgeFormItem />
                </Col>
                <Col span="12">
                    <WaterCementRatioFormItemDE />
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span="12">
                    <DensityClassFormItem required={compressiveStrengthClass === 'LC'} />
                </Col>
                <Col span="12">
                    <ExternalInspectionFormItem required />
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <AdditionalAttributesFormItem additionalAttributes={additionalAttributes} />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <AggregatesFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <CementsFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <SupplementaryCementiousMaterialsFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <AdmixturesFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <AssignToStructuralElementsFormItem assignableStructuralElements={assignableStructuralElements} />
                </Col>
            </Row>
        </>
    );
}

export default ConcreteTypeModalFormDE;
