/* eslint-disable no-use-before-define */
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { StrengthDevelopmentFormatter } from 'src/app-react/components/Formatters';
import ExposureClassesFormatter from 'src/app-react/components/Formatters/ExposureClassesFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import styles from './StructuralElementsTable.module.css';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

const NA = '';
export const StructuralElementsTableColumnsDE = (props: {
    onEdit: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onDuplicate: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onActiveInactive: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onAssign: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onUnassign: (structuralElementConcreteTypes: CataloguedStructuralElementExtendedBP) => void;
    onStructuralElementModal: (structuralElement: CataloguedStructuralElementExtendedBP) => void;
}): ColumnsType<CataloguedStructuralElementExtendedBP> => {
    const { onEdit, onDuplicate, onActiveInactive, onAssign, onUnassign, onStructuralElementModal } = props;

    const getActions = (record: CataloguedStructuralElementExtendedBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: <Trans>Edit</Trans>,
            key: `${record.id} Edit`,
            icon: <EditOutlined />,
            onClick: () => {
                onEdit(record);
            }
        });

        items.push({
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <EditOutlined />,
            onClick: () => {
                onDuplicate(record);
            }
        });

        items.push({
            label: <Trans>Assign concrete types</Trans>,
            key: `${record.id} AssignCT`,
            icon: <PlusCircleOutlined />,
            onClick: () => {
                onAssign(record);
            }
        });

        items.push({
            label: <Trans>Unassign concrete types</Trans>,
            key: `${record.id} UnassignCT`,
            icon: <DeleteOutlined />,
            onClick: () => {
                onUnassign(record);
            }
        });

        return items;
    };
    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<CataloguedStructuralElementExtendedBP>[] = [
        {
            title: <Trans>Structural element</Trans>,
            dataIndex: 'structuralElementName',
            sorter: true,
            render: (elementName: string, record: CataloguedStructuralElementExtendedBP) => renderElementName(record)
        },
        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'minimalRequiredCubicCompressiveStrength',
            sorter: true,
            render: (compressiveStrength: string, record: CataloguedStructuralElementExtendedBP) =>
                renderCompressiveStrength(record)
        },
        {
            title: <Trans>Exposure class</Trans>,
            dataIndex: 'id',
            render: (id: string, record: CataloguedStructuralElementExtendedBP) => (
                <ExposureClassesFormatter
                    x0={record.requiredX0}
                    xa={record.minimalRequiredXa}
                    xc={record.minimalRequiredXc}
                    xd={record.minimalRequiredXd}
                    xfFirst={record.minimalRequiredXfFirst}
                    xfSecond={record.minimalRequiredXfSecond}
                    xm={record.minimalRequiredXm}
                    xs={record.minimalRequiredXs}
                />
            )
        },
        {
            title: <Trans>Moisture class</Trans>,
            dataIndex: 'minimalRequiredMoistureClass',
            sorter: true,
            render: (moistureClass: string, record: CataloguedStructuralElementExtendedBP) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>{record ? record.minimalRequiredMoistureClass : NA}</>
            )
        },
        {
            title: <Trans>Strength development</Trans>,
            dataIndex: 'minimalRequiredStrengthDevelopment',
            sorter: true,
            render: (minimalRequiredStrengthDevelopment: number) => (
                <StrengthDevelopmentFormatter value={minimalRequiredStrengthDevelopment} />
            )
        },
        {
            title: <Trans>Active</Trans>,
            dataIndex: 'isActive',
            sorter: true,
            render: (active: boolean, record: CataloguedStructuralElementExtendedBP) => renderActive(record)
        },
        {
            title: <Trans>Actions</Trans>,
            hidden: isViewer,
            dataIndex: 'id',
            width: 50, // we set this width to have an alignment of this column with the other table having also 50 as width for the action column
            render: (id: string, record: CataloguedStructuralElementExtendedBP) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                );
            }
        }
    ];

    return columns.filter((item) => !item.hidden);

    function renderElementName(record: CataloguedStructuralElementExtendedBP): JSX.Element {
        return (
            <RawLinkButton data-testid="editButton" onClick={() => onStructuralElementModal(record)}>
                {record ? record.structuralElementName : NA}
            </RawLinkButton>
        );
    }

    function renderCompressiveStrength(record: CataloguedStructuralElementExtendedBP): JSX.Element {
        return (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
                {record
                    ? `${record.minimalRequiredTypeOfConcreteType} ${record.minimalRequiredCylindricCompressiveStrength}/${record.minimalRequiredCubicCompressiveStrength}`
                    : NA}
            </>
        );
    }

    function renderActive(record: CataloguedStructuralElementExtendedBP): JSX.Element {
        function onChange(): void {
            if (record) {
                onActiveInactive(record);
            }
        }
        if (record) {
            // eslint-disable-next-line react/jsx-no-bind
            return <Checkbox onChange={onChange} checked={record.isActive} />;
        }
        return <></>;
    }
};
