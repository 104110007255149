import { t } from '@lingui/macro';
import {
    DeliveryConcreteInformation,
    DeliveryNoteDetailsBP
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import { DeliveryNoteAllDetails } from './hooks/useDeliveryNoteDetails';
import { formatConcreteInformationAUS } from './markets/AUS/util';
import { formatConcreteInformationDE } from './markets/DE/util';
import { formatConcreteInformationUS } from './markets/US/util';

export function optionMethodConcrete(option: string): string {
    switch (option) {
        case 'prestressedConcrete / Cl 0,20':
            return t`Prestressed concrete / Cl 0,20`;
        case 'reinforcedConcrete / Cl 0,40':
            return t`Reinforced concrete  / Cl 0,40`;
        case 'nonReinforcedConcrete / Cl 1,00':
            return t`Non-reinforced concrete / Cl 1,00`;
        case 'prestressedConcrete':
            return t`Prestressed concrete`;
        case 'reinforcedConcrete':
            return t`Reinforced concrete`;
        case 'nonReinforcedConcrete':
            return t`Non-reinforced concrete`;
        default:
            return option;
    }
}

export function optionStrengthDevelopmentClass(option: string): string {
    switch (option) {
        case 'very slow':
            return t`very slow`;
        case 'slow':
            return t`slow`;
        case 'intermediate':
            return t`intermediate`;
        case 'fast':
            return t`fast`;
        default:
            return option;
    }
}

export function formatConcreteInformation(deliveryDetails: DeliveryNoteDetailsBP, marketId: MARKET_VALUES) {
    const concreteInformation: DeliveryConcreteInformation[] = deliveryDetails.deliveryConcreteInformation
        ? deliveryDetails.deliveryConcreteInformation
        : [];

    switch (marketId) {
        case MARKETS.DE:
            return formatConcreteInformationDE(concreteInformation);
        case MARKETS.AUS:
            return formatConcreteInformationAUS(concreteInformation);
        default:
            return formatConcreteInformationUS(concreteInformation);
    }
}

export function keyMapFromTableData(tableData: DeliveryNoteAllDetails[]): DeliveryNoteAllDetails[] {
    return tableData.map((value: DeliveryNoteAllDetails, key: number) => {
        return { ...value, key };
    });
}
