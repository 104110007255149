import { Col, Row } from 'antd';
import { SupplierUIFormItem } from '../../../../Form/components/FormItems/SupplierUIFormItem';
import { PasswordFormItem } from '../../../FormItems/PasswordFormItem';
import { SupplierUrlFormItem } from '../../../FormItems/SupplierUrlFormItem';
import { UsernameFormItem } from '../../../FormItems/UsernameFormItem';
import { AppCodeFormItem } from '../FormItems/AppCodeFormItem';
import { ContractIdFormItem } from '../FormItems/ContractIdFormItem';
import { CustomerIdFormItem } from '../FormItems/CustomerIdFormItem';
import { SubscriptionKeyFormItem } from '../FormItems/SubscriptionKeyFormItem';
import { SupplierProjectIdFormItem } from '../FormItems/SupplierProjectIdFormItem';

export function SupplierConfigFormLayoutCemexUS() {
    return (
        <>
            <Row gutter={8}>
                <Col span={11}>
                    <SupplierUrlFormItem />
                </Col>
                <Col span={13}>
                    <ContractIdFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <SupplierProjectIdFormItem />
                </Col>
                <Col span={13}>
                    <CustomerIdFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <UsernameFormItem />
                </Col>
                <Col span={13}>
                    <PasswordFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <SubscriptionKeyFormItem />
                </Col>
                <Col span={13}>
                    <AppCodeFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <SupplierUIFormItem />
            </Row>
        </>
    );
}
