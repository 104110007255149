import { ConcretingDeliveryNoteDocumentationFilter } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';

export const isAirTempAvailable = (airTemp: string): boolean =>
    !airTemp?.includes('undefined') || airTemp?.length === 0;

export const cleanConcretingDeliveryDocumentationFilter = (filters: ConcretingDeliveryNoteDocumentationFilter) => {
    const f = { ...filters };
    for (const key in f) {
        if (!f[key as keyof typeof filters]) {
            delete f[key as keyof typeof filters];
        }
    }
    return f as ConcretingDeliveryNoteDocumentationFilter;
};

export const clearFiltersForFilterList = (filters: ConcretingDeliveryNoteDocumentationFilter) => {
    if (filters.deliveryTime)
        return { deliveryTime: filters.deliveryTime } as ConcretingDeliveryNoteDocumentationFilter;
    return {};
};

export const defaultPagination = { skip: 0, limit: 10 };
