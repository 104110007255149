import { OrderDeliveryNotes } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import moment from 'moment';
import { MARKETS } from 'src/app-react/constants/markets';
import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';

const AUS_DEFAULT_LOCATION_TYPE = 'other';

function fromOrderDeliveryNotesToFormFieldsAUS(): Partial<ConcreteLoadDataLiteFormFields> {
    const arrivalAtConstructionSite = moment();

    return {
        arrivalAtConstructionSite,
        arrivalAtConstructionSiteDate: arrivalAtConstructionSite,
        locationType: AUS_DEFAULT_LOCATION_TYPE
    };
}

function fromOrderDeliveryNotesToFormFields({
    orderDeliveryNotes,
    marketId
}: {
    orderDeliveryNotes: OrderDeliveryNotes;
    marketId: string;
}): Partial<ConcreteLoadDataLiteFormFields> {
    const location = orderDeliveryNotes.unloadingLocationDetails;

    const commonFormFields = {
        orderVolume: orderDeliveryNotes.quantity,
        supplierOrderId: orderDeliveryNotes.supplierOrderId,
        number: orderDeliveryNotes.concreteType,
        deliveryTime: moment(orderDeliveryNotes.DeliveryDateAndTime),
        locationType: location?.locationType,
        locationName: location?.locationName,
        locationDescription: location?.locationDescription
    };

    switch (marketId) {
        case MARKETS.AUS:
            return {
                ...commonFormFields,
                ...fromOrderDeliveryNotesToFormFieldsAUS()
            };
        default:
            return commonFormFields;
    }
}

export default fromOrderDeliveryNotesToFormFields;
