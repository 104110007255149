import { t, Trans } from '@lingui/macro';
import { InputOrderBP } from '@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.dto';
import { Button, Col, Modal, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { NumberFormatter, VolumenFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { mapStrengthDevelopmentClass } from '../../utils';
import styles from './OrderPreviewModal.module.css';

// TODO user common OrderPreview
export interface OrderPreview {
    structuralElement: string;
    typeOfConcrete: string;
    compressiveStrengthClass: string;
    exposureClasses: string;
    maxAggregationSize: number;
    consistencyClass: string;
    consistencyType: string;
    strengthDevelopment: string;
    deliveryTime: Date;
    concreteQuantity: number;
    outputPerHour?: number;
    unloadingLocation: string;
    additionalRequirement: string;
    concreteTypeNumber?: string;
    additionalQuantity: boolean;
    spacingInMinutes?: string | number;
    slump?: string | number;
    loadSize?: string | number;
}

interface OrderPreviewProps {
    orderPreview: OrderPreview;
    order: InputOrderBP;
    orderId: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function OrderPreviewModal(props: OrderPreviewProps) {
    const { orderPreview, order, orderId, open, setOpen } = props;
    const { projectId, companyId, marketId } = useProjectState();
    const [orderPreviewData, setOrderPreviewData] = useState<OrderPreview>({} as OrderPreview);
    const marketDE = 'DE';
    const navigate: NavigateFunction = useNavigate();
    const api = useApi();

    const [loadingNewOrder, setLoadingNewOrder] = useState(false);
    const [enabledSendButton, setEnabledSendButton] = useState(true);

    useEffect(() => {
        if (open) {
            setOrderPreviewData(orderPreview);
        }
    }, [open, projectId, companyId]);

    const getAdditionalQuantity = (additionalQuantity: boolean): string => {
        if (additionalQuantity) {
            return t`yes`;
        }
        return t`no`;
    };

    const onCancel = (): void => {
        setOpen(false);
    };

    const resetModal = (): void => {
        setEnabledSendButton(true);
        setLoadingNewOrder(false);
        setOpen(false);
    };

    const sendOrder = async (): Promise<void> => {
        if (enabledSendButton === true) {
            setEnabledSendButton(false);
            setLoadingNewOrder(true);

            if (orderId) {
                api.orderManagementClient
                    .createChangeRequestInputOrder(projectId, orderId, companyId, order)
                    .then((response) => {
                        if (response.isSuccess()) {
                            setOpen(false);
                            SuccessNotification({
                                message: t`Order updated successfully`,
                                description: ''
                            });
                            setTimeout(() => {
                                navigate(`/projects/${projectId}/orders`);
                            }, 500);
                        } else {
                            resetModal();
                            ErrorNotification({ message: response.getError(), description: '' });
                        }
                    })
                    .catch(() => {
                        resetModal();
                    });
            } else {
                api.orderManagementClient
                    .createInputOrder(projectId, order, companyId)
                    .then((response) => {
                        if (response.isSuccess()) {
                            setOpen(false);
                            SuccessNotification({
                                message: t`Order created successfully`,
                                description: ''
                            });
                            setTimeout(() => {
                                navigate(`/projects/${projectId}/orders`);
                            }, 500);
                        } else {
                            resetModal();
                            const error = (response?.getWrappedResponse()?.data as any)?.error;

                            let message: string;
                            let description = '';

                            if (
                                /(inactive\scatalogued\sstructural\selement|not\sorderable\sconcrete\stype)/.test(
                                    error?.message
                                )
                            ) {
                                message = t`The order can´t be duplicated`;
                                description = t`The selected structural element or concrete type is not available in the catalogue. Please select “Change” to choose an available option.`;
                            } else if (error?.message === 'Unable to create a preorder on supplier') {
                                message = t`There was a problem when creating the order on the supplier's system. Please try again later or contact an administrator.`;
                            } else {
                                message = response.getError();
                            }

                            ErrorNotification({ message, description });
                        }
                    })
                    .catch(() => {
                        resetModal();
                    });
            }
        }
    };

    const firstBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Concrete type number</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderPreviewData.typeOfConcrete || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength class</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderPreviewData.compressiveStrengthClass || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Exposure Class</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderPreviewData.exposureClasses || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Maximum aggregate size</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <NumberFormatter value={orderPreviewData.maxAggregationSize} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Consistency class</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    {orderPreviewData.consistencyType && orderPreviewData.consistencyClass
                        ? `${orderPreviewData.consistencyType} ${orderPreviewData.consistencyClass}`
                        : ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Strength development class</Trans>
                    </label>
                </Col>
                <Col span={12}>{mapStrengthDevelopmentClass(orderPreviewData.strengthDevelopment)}</Col>
            </>
        );
    };

    const firstBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Mix design no.</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderPreviewData.typeOfConcrete || ''}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength</Trans>
                    </label>
                </Col>
                <Col span={12}>{`${orderPreviewData.compressiveStrengthClass || ''}`}</Col>
            </>
        );
    };

    const secondBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Output per hour</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <VolumenFormatter value={orderPreviewData.outputPerHour} />
                </Col>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>With additional quantity</Trans>
                    </label>
                </Col>
                <Col span={12}>{getAdditionalQuantity(orderPreviewData.additionalQuantity)}</Col>
            </>
        );
    };

    const secondBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Spacing between trucks</Trans>
                    </label>
                </Col>
                <Col span={12} data-testid="spacingInMinutes">
                    {orderPreviewData.spacingInMinutes}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Load size per truck</Trans>
                    </label>
                </Col>
                <Col span={12} data-testid="loadSize">
                    <VolumenFormatter value={orderPreviewData.loadSize} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Slump</Trans>
                    </label>
                </Col>
                <Col span={12} data-testid="slump">
                    <NumberFormatter value={orderPreviewData.slump} unit="in" />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>With clean up load</Trans>
                    </label>
                </Col>
                <Col span={12}>{getAdditionalQuantity(orderPreviewData.additionalQuantity)}</Col>
            </>
        );
    };

    return (
        <Modal
            maskClosable={false}
            open={open}
            onCancel={onCancel}
            title={loadingNewOrder ? t`Order creating` : t`Order review`}
            width={750}
            data-testid="orderPreviewModal"
            footer={[
                <Button
                    data-testid="sendButton"
                    key="send"
                    type="primary"
                    disabled={!enabledSendButton}
                    onClick={sendOrder}>
                    {t`Send`}
                </Button>
            ]}>
            {loadingNewOrder ? (
                <div style={{ margin: '20px', padding: '30px 50px', textAlign: 'center' }}>
                    <Spin />
                </div>
            ) : (
                <Row gutter={[48, 16]}>
                    <Col span={12}>
                        <label className={styles.modalLabel}>
                            <Trans>Structural element to be concreted</Trans>
                        </label>
                    </Col>

                    <Col span={12}>{orderPreviewData.structuralElement || ''}</Col>

                    {marketId === marketDE ? firstBlockRenderMarketIdDE() : firstBlockRenderMarketIdUS()}

                    <Col span={12}>
                        <label className={styles.modalLabel}>
                            <Trans>Delivery Time</Trans>
                        </label>
                    </Col>
                    <Col span={12}>
                        <DateTimeFormatter date={orderPreviewData.deliveryTime} />
                    </Col>

                    <Col span={12}>
                        <label className={styles.modalLabel}>
                            <Trans>Concrete quantity</Trans>
                        </label>
                    </Col>
                    <Col span={12}>
                        <VolumenFormatter value={orderPreviewData.concreteQuantity} />
                    </Col>
                    {marketId === marketDE ? secondBlockRenderMarketIdDE() : secondBlockRenderMarketIdUS()}

                    <Col span={12}>
                        <label className={styles.modalLabel}>
                            <Trans>Unloading location</Trans>
                        </label>
                    </Col>
                    <Col span={12}>{orderPreviewData.unloadingLocation || ''}</Col>

                    <Col span={12}>
                        <label className={styles.modalLabel}>
                            <Trans>Additional requirements</Trans>
                        </label>
                    </Col>
                    <Col span={12}>{orderPreviewData.additionalRequirement || ''}</Col>
                </Row>
            )}
        </Modal>
    );
}

export default OrderPreviewModal;
