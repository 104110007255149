import { t, Trans } from '@lingui/macro';
import {
  ConcretedStructuralElementTest,
  TypeOfMeasurement
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import moment from 'moment';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { compressiveMeasurementMapper } from 'src/app-react/utils/formatters';
import { nullableNumberTableSorter, nullableStringTableSorter } from 'src/app-react/utils/lib';

export const compressiveDetailsTableColumns: (marketId: string) => ColumnsType<ConcretedStructuralElementTest> = (
    marketId: string
) => {
    const columns: (ColumnType<ConcretedStructuralElementTest> & { hidden?: boolean })[] = [
        {
            title: <Trans>Type of measurement</Trans>,
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (type: TypeOfMeasurement, record: ConcretedStructuralElementTest) =>
                type === 'other' ? <p>{record.typeOther}</p> : <p>{compressiveMeasurementMapper[type]}</p>
        },

        {
            title: <Trans>Measurement time</Trans>,
            dataIndex: 'timeMeasurement',
            key: 'timeMeasurement',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.timeMeasurement).unix() - moment(b.timeMeasurement).unix(),
            render: (timeMeasurement: Date) => <DateTimeFormatter date={timeMeasurement} />
        },

        {
            title: <Trans>Measurement value</Trans>,
            dataIndex: 'measurement',
            key: 'measurement',
            sorter: false,
            render: (_, record: ConcretedStructuralElementTest) => (
                <p>
                    <NumberFormatter
                        value={record.measurement}
                        unit={record.measurement != null ? record.measurementUnit : ''}
                    />
                </p>
            )
        },

        {
            title: <Trans>Result</Trans>,
            dataIndex: 'result',
            key: 'result',
            sorter: (a, b, sortOrder) => nullableNumberTableSorter(a.result, b.result, sortOrder),
            render: (_, record: ConcretedStructuralElementTest) => (
                <p>
                    <NumberFormatter value={record.result} unit={record.result != null ? record.resultUnit : ''} />
                </p>
            )
        },

        {
            title: <Trans>Test specimen</Trans>,
            dataIndex: 'testSpecimenTestSpecimenIdentifier',
            key: 'testSpecimenTestSpecimenIdentifier',
            sorter: (a, b, sortOrder) =>
                nullableStringTableSorter(
                    a.testSpecimenTestSpecimenIdentifier,
                    b.testSpecimenTestSpecimenIdentifier,
                    sortOrder
                ),
            render: (_, record: ConcretedStructuralElementTest) => <p>{record.testSpecimenTestSpecimenIdentifier}</p>
        },

        {
            title: <Trans>Use for Post-treatment</Trans>,
            dataIndex: 'useForPt',
            key: 'useForPt',
            hidden: marketId !== 'DE',
            sorter: (a, b) => a.useForPt.toString().localeCompare(b.useForPt.toString()),
            render: (useForPt: boolean) => <p>{useForPt ? t`Yes` : t`No`}</p>
        }
    ];

    return columns.filter((item) => !item.hidden);
};
