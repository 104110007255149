import { t } from "@lingui/macro";
import { Trans } from "@lingui/macro";
import { Form, Radio } from "antd";
import requiredRule from "src/app-react/components/Form/Validations/requiredRule";

export function BillingAddressFormItem() {
    return (
        <Form.Item
            label={t`Billing address`}
            name="billingAddress"
            rules={[requiredRule]}>
            <Radio.Group>
                <Radio value="UseConstruction" data-testid="billingAddress">
                    <Trans>Use construction site address as billing address</Trans>
                </Radio>
                <Radio value="EnterDerivating" data-testid="enterDerivating">
                    <Trans>Enter derivating billing address</Trans>
                </Radio>
            </Radio.Group>
        </Form.Item>
    )
}