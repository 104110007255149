import { ISupplierData } from '.';
import { MARKETS } from '../markets';

export const BERGER_DATA: ISupplierData = {
    market: MARKETS.DE,
    label: 'Berger',
    name: ['Berger'],
    manualIntegration: true,
    permissions: {
        allowOrderEdit: true,
        allowOrderCancel: true,
        allowOrderAdditionalQuantity: false,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: true
    }
};
