import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';

export function StructuralElementFormItem() {
    return (
        <Form.Item
            label={<Trans>Structural element to be concreted</Trans>}
            name="structuralElement"
            rules={[{
                required: true,
                message: <Trans>This field is required</Trans>
            }]}>
            <Input
                type="text"
                placeholder={t`e.g Bodenplatte 4. OG`}
                data-testid="structuralElement"
                value="getStructuralElementDescription"
            />
        </Form.Item>
    );
}
