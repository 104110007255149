/* eslint-disable react/jsx-props-no-spreading */

import { Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';
import useGetSupplierNameAndBusinessNumber from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetSupplierNameAndBusinessNumber';
import { OrderLoadLiteTypesValues } from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import ABNNumberFormItem from './ABNNumberFormItem';
import SupplierNameFormItem from './SupplierNameFormItem';

export interface SupplierInformationGroupItemsProps extends SelectFormItemProps {
    type: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
}

function SupplierInformationGroupItems({ type, origin }: SupplierInformationGroupItemsProps) {
    const { loadSupplierBusinessData, dropDownData, supplierBusinessData, error } =
        useGetSupplierNameAndBusinessNumber();

    useEffect(() => {
        loadSupplierBusinessData();
    }, [loadSupplierBusinessData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    return (
        <Row gutter={54}>
            <Col span={12}>
                <ABNNumberFormItem
                    dropDownData={dropDownData}
                    supplierBusinessData={supplierBusinessData}
                    origin={origin}
                    type={type}
                    data-testid="ABNNumberFormItemAUS"
                />
                <Form.Item hidden name="supplierBusinessNumber">
                    <Input type="hidden" />
                </Form.Item>
            </Col>
            <Col span={12}>
                <SupplierNameFormItem origin={origin} type={type} data-testid="SupplierNameFormItemAUS" />
            </Col>
        </Row>
    );
}

export default SupplierInformationGroupItems;
