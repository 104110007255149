import { t, Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import useOrderDependenciesState from 'src/app-react/business-page/order-management/hooks/useOrderDependenciesState';
import { buildDefaultOptionType } from '../../../../../../../../shared/Forms/utils/buildDefaultOptionType';

export function LoadSizeFormItem() {
    const {
        dependencies: { loadSizes = [] }
    } = useOrderDependenciesState();

    const options = buildDefaultOptionType(loadSizes);

    return (
        <Form.Item
            label={<Trans>Load Size</Trans>}
            name="loadSize"
            rules={[
                {
                    pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                    required: false,
                    message: (
                        <span data-testid="errorInputLoadSize">
                            <Trans>Please input load size</Trans>
                        </span>
                    )
                }
            ]}>
            <Select placeholder={t`Please select load size`} options={options} data-testid="loadSize" />
        </Form.Item>
    );
}
