import ConcreteLoadDataLiteFormFields from '../../../models/ConcreteLoadDataLiteFormFields';
import { OrderLoadLiteTypesValues, ORDER_LOAD_LITE_TYPES } from '../../../models/Types';
import OrderLoadLiteModalForm0QRLayout from './components/Layouts/QRLayout';

interface OrderLoadLiteModalForm0Props {
    type: OrderLoadLiteTypesValues;
    initializeForm: (formFields: ConcreteLoadDataLiteFormFields, origin?: string) => void;
    onNext: () => void;
}

function OrderLoadLiteModalForm0({ type, initializeForm, onNext }: OrderLoadLiteModalForm0Props) {
    switch (type) {
        case ORDER_LOAD_LITE_TYPES.QR:
            return <OrderLoadLiteModalForm0QRLayout onNext={onNext} initializeForm={initializeForm} />;
        default:
            throw new Error('invalid type');
    }
}

export default OrderLoadLiteModalForm0;
