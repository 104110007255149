/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { SupplierNameWithBusinessNumber } from '@nexploretechnology/concreting-core-client';
import { Form, Select } from 'antd';
import { useCallback, useEffect } from 'react';
import useGetSupplierConstructorDataByBusinessNumber from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetSupplierConstructorDataByBusinessNumber';
import {
    ORDER_LOAD_LITE_TYPES,
    OrderLoadLiteTypesValues
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';

interface ABNNumberFormItemProps extends SelectFormItemProps {
    type: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
    supplierBusinessData: SupplierNameWithBusinessNumber[];
    dropDownData: ValuesDropDown[];
}

function ABNNumberFormItem(props: ABNNumberFormItemProps) {
    const { origin, type, dropDownData, supplierBusinessData } = props;
    const form = Form.useFormInstance();

    const { loadConstructorData, constructorData, error } = useGetSupplierConstructorDataByBusinessNumber();

    useEffect(() => {
        if (constructorData) {
            form?.setFields([
                {
                    name: 'constructorName',
                    value: constructorData.constructorName
                },
                {
                    name: 'constructorNumber',
                    value: constructorData.constructorNumber
                }
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [constructorData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    const onChangeSelector = useCallback(
        (option: string | undefined) => {
            const supplierInfo = option.split(',');

            if (option) {
                form?.setFields([
                    {
                        name: 'supplierName',
                        value: supplierInfo[1]
                    }
                ]);

                form?.setFields([
                    {
                        name: 'supplierBusinessNumber',
                        value: supplierInfo[0]
                    }
                ]);

                loadConstructorData(supplierInfo[0]);
            }
        },
        [form, loadConstructorData, supplierBusinessData]
    );

    const onClear = useCallback(() => {
        const isQr = type === ORDER_LOAD_LITE_TYPES.QR || origin === ORDER_LOAD_LITE_TYPES.QR.toLowerCase();
        const fields: any = [
            {
                name: 'constructorName',
                value: undefined
            },
            {
                name: 'constructorNumber',
                value: undefined
            }
        ];

        if (!isQr) {
            fields.push({
                name: 'supplierName',
                value: undefined
            });
        }

        form?.setFields(fields);
    }, [form, type, origin]);

    return (
        <SelectFormItem label={t`ABN number`} name="supplierBusinessNumberSelector" {...props}>
            <Select
                placeholder={t`Please select a ABN number`}
                allowClear
                options={dropDownData}
                data-testid="supplierBusinessNumberInputForm"
                onClear={onClear}
                onChange={onChangeSelector}
            />
        </SelectFormItem>
    );
}

export default ABNNumberFormItem;
