import { IMarketData } from '../';
import { BUSINESS_PAGES } from '../../businessPages';
import CHLORIDE_CONTENT from './dataValues/chlorideContent';
import CONSISTENCY_TYPE from './dataValues/consistencyType';
import DENSITY_CLASS from './dataValues/densityClass';
import {
    EXPOSURE_CLASS_XA,
    EXPOSURE_CLASS_XC,
    EXPOSURE_CLASS_XD,
    EXPOSURE_CLASS_XF,
    EXPOSURE_CLASS_XM,
    EXPOSURE_CLASS_XS
} from './dataValues/exposureClass';
import METHOD_CONCRETE from './dataValues/methodConcrete';
import METHOD_CONCRETE_USECHLORIDE from './dataValues/methodConcreteUseChloride';
import MOISTURE from './dataValues/moisture';
import STRENGTH_DEVELOPMENT_CLASS from './dataValues/strengthDevelopmentClass';
import { TEST_SPECIMEN_TEST_TYPE } from './dataValues/testSpecimenTestType';
import { TEST_SPECIMEN_TEST_PURPOSE } from './dataValues/testSpecimenTestPurpose';
import TYPE_OF_CONCRETE from './dataValues/typeOfConcrete';
import UNITS from './dataValues/units';
import FORMATS from './dataValues/formats';

const MARKET_DATA_DE: IMarketData = {
    businessPages: Object.values(BUSINESS_PAGES),
    constants: {
        units: UNITS,
        formats: FORMATS,
        methodConcrete: METHOD_CONCRETE,
        methodConcreteUseChloride: METHOD_CONCRETE_USECHLORIDE,
        moisture: MOISTURE,
        strengthDevelopmentClass: STRENGTH_DEVELOPMENT_CLASS,
        densityClass: DENSITY_CLASS,
        chlorideContent: CHLORIDE_CONTENT,
        typeOfConcrete: TYPE_OF_CONCRETE,
        consistencyType: CONSISTENCY_TYPE,
        exposureClassXc: EXPOSURE_CLASS_XC,
        exposureClassXd: EXPOSURE_CLASS_XD,
        exposureClassXf: EXPOSURE_CLASS_XF,
        exposureClassXa: EXPOSURE_CLASS_XA,
        exposureClassXm: EXPOSURE_CLASS_XM,
        exposureClassXs: EXPOSURE_CLASS_XS,
        testSpecimenTestType: TEST_SPECIMEN_TEST_TYPE,
        testSpecimenTestPurpose: TEST_SPECIMEN_TEST_PURPOSE
    }
};

export default MARKET_DATA_DE;
