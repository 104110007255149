import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { useMemo } from 'react';
import getOrderPermissions from '../services/orderService/getOrderPermissions';
import useProjectState from './useProjectState';
import useUserPermissions from './useUserPermissions';

function useOrderPermissions() {
    const permissions = useUserPermissions();
    const { supplierId } = useProjectState();

    return useMemo(() => {
        return ({ record }: { record: OrderOverviewBP }) => {
            const orderPermissions = getOrderPermissions({
                record,
                supplierId
            });

            return {
                ...orderPermissions,
                allowEdit: orderPermissions.allowEdit && permissions.isAllowToCreateConcreteOrders,
                allowCancel:
                    orderPermissions.allowCancel && permissions.isAllowToCreateConcreteOrderCancellationRequests
            };
        };
    }, [permissions, supplierId]);
}

export default useOrderPermissions;
