import { Trans } from '@lingui/macro';
import { ConcreteLoadIngredientOverviewResponse } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Table } from 'antd';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DeviationFormatter from './components/DeviationFormatter';

interface IConcreteLoadIngredientsOverviewTableSumaryProps {
    concreteLoadIngredients?: ConcreteLoadIngredientOverviewResponse;
}

function ConcreteLoadIngredientsOverviewTableSumary(props: IConcreteLoadIngredientsOverviewTableSumaryProps) {
    const {
        waterTargetQuantity,
        waterTargetQuantityUnit,
        waterActualQuantity,
        waterActualQuantityUnit,
        waterPercentageDeviation
    } = props?.concreteLoadIngredients || {};

    return (
        <Table.Summary fixed>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                    <b>
                        <Trans>Calculated total water amount</Trans>
                    </b>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>-</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>-</Table.Summary.Cell>
                <Table.Summary.Cell index={3}>
                    <NumberFormatter value={waterActualQuantity} unit={waterActualQuantityUnit} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4}>
                    <NumberFormatter value={waterTargetQuantity} unit={waterTargetQuantityUnit} />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5}>
                    <DeviationFormatter value={waterPercentageDeviation} />
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </Table.Summary>
    );
}

export default ConcreteLoadIngredientsOverviewTableSumary;
