import { Col, Row } from 'antd';
import LocationNameFormItem from 'src/app-react/business-page/shared/FormItems/Location/LocationNameFormItem';
import { ConcreteLoadLiteModalAction } from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import ConcreteTypeNumberFormItem from '../../Form/FormItems/ConcreteTypeNumberFormItem';
import DeliveryNoteNumberFormItem from '../../Form/FormItems/DeliveryNoteNumberFormItem';
import DeliveryTimeFormItem from '../../Form/FormItems/DeliveryTimeFormItem';
import MixingPlantFormItem from '../../Form/FormItems/MixingPlantFormItem';
import OrderVolumeFormItem from '../../Form/FormItems/OrderVolumeFormItem';
import SupplierOrderIdFormItem from '../../Form/FormItems/SupplierOrderIdFormItem';
import TruckNumberFormItem from '../../Form/FormItems/TruckNumberFormItem';
import VolumeFormItem from '../../Form/FormItems/VolumeFormItem';

interface OrderLiteModalForm1USLayout {
    action?: ConcreteLoadLiteModalAction;
}

export function OrderLiteModalForm1USLayout({ action }: OrderLiteModalForm1USLayout) {
    return (
        <>
            <Row gutter={54}>
                <Col span="12">
                    <DeliveryNoteNumberFormItem data-testid="DeliveryNoteNumberFormItemUS" />
                </Col>
                <Col span="12">
                    <ConcreteTypeNumberFormItem data-testid="ConcreteTypeNumberFormItemUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <VolumeFormItem data-testid="VolumeFormItemUS" />
                </Col>
                <Col span="12">
                    <TruckNumberFormItem data-testid="TruckNumberFormItemUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <MixingPlantFormItem data-testid="MixingPlantFormItemUS" />
                </Col>
                <Col span="12">
                    <SupplierOrderIdFormItem action={action} data-testid="SupplierOrderIdFormItemUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <OrderVolumeFormItem data-testid="OrderVolumeFormItemUS" />
                </Col>
                <Col span="12">
                    <DeliveryTimeFormItem data-testid="DeliveryTimeFormItemUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <LocationNameFormItem required={false} data-testid="LocationNameFormItemUS" />
                </Col>
            </Row>
        </>
    );
}
