import React, { useCallback, useMemo, useState } from 'react';
import Api from 'src/api';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getOrderDependencies, getOrderValidations } from 'src/app-react/services/orderService';
import OrderDependenciesError from '../components/Errors/OrderDependenciesError';
import { OrderDependencies, OrderValidations } from '../models/OrderDependencies';

interface LoadAllDependenciesParams {
    structuralElementId?: string;
    concreteTypeId?: string;
    orderId?: string;
}

interface CommonLoadElementParams {
    api: Api;
    id?: string;
    concreteTypeId?: string;
    structuralElementId?: string;
    projectId: string;
    companyId: string;
}

export interface OrderDependenciesContextValue {
    loadAllDependencies: (params: LoadAllDependenciesParams) => void;
    loadLocations: () => void;
    resetDependenciesError: (error: Error | null | undefined) => void;
    dependenciesError: Error | null | undefined;
    dependencies: OrderDependencies;
    validations: OrderValidations;
}

const OrderDependenciesContext = React.createContext<OrderDependenciesContextValue>(
    {} as OrderDependenciesContextValue
);

async function loadExtendedOrder({ api, id, ...params }: CommonLoadElementParams) {
    if (id) {
        const response = await api.orderManagementClient.loadExtendedManufacturingFlow(
            params.projectId,
            params.companyId,
            id
        );

        return response.getEntity();
    }

    return null;
}

async function loadStructuralElement({ api, id, ...params }: CommonLoadElementParams) {
    if (id) {
        const response = await api.catalogueManagementClient.getExtendedCataloguedStructuralElementById(
            params.companyId,
            params.projectId,
            id
        );

        return response.getEntity();
    }

    return null;
}

async function loadConcreteType({ api, id, ...params }: CommonLoadElementParams) {
    if (id) {
        const response = await api.catalogueManagementClient.getExtendedConcreteTypeById(
            params.companyId,
            params.projectId,
            id
        );

        return response.getEntity();
    }

    return null;
}

async function loadLocations({ api, ...params }: CommonLoadElementParams) {
    const response = await api.unloadingLocationClient.loadUnloadingLocations(params.companyId, params.projectId);

    return response.getEntity();
}

async function loadOrderDependencies({ api, ...params }: CommonLoadElementParams) {
    const response = await api.catalogueManagementClient.getOrderDependenciesContext(
        params.companyId,
        params.projectId,
        params.concreteTypeId,
        params.structuralElementId
    );

    return response.getEntity();
}

async function loadAllDependencies(
    params: CommonLoadElementParams & LoadAllDependenciesParams
): Promise<OrderDependencies> {
    const { orderId, ...commonParams } = params;
    let { structuralElementId } = params;
    const extendedOrder = await loadExtendedOrder({ ...commonParams, id: orderId });
    const concreteTypeId = params.concreteTypeId || extendedOrder?.order?.orderedCataloguedConcreteTypeId;

    if (!structuralElementId) structuralElementId = extendedOrder?.concretedStructuralElement?.id;

    const { locations, structuralElement, concreteType } = await loadOrderDependencies({
        ...commonParams,
        concreteTypeId,
        structuralElementId
    });
    return {
        extendedOrder,
        structuralElement,
        concreteType,
        locations
    };
}

function OrderDependenciesContextProvider(props: { children: React.ReactChild }) {
    const [dependencies, setDependencies] = useState({} as OrderDependenciesContextValue['dependencies']);
    const [dependenciesError, setDependenciesError] =
        useState<OrderDependenciesContextValue['dependenciesError']>(null);

    const api = useApi();
    const { marketId, supplierId, projectId, company } = useProjectState();

    const loadAllDependenciesCallback = useCallback<OrderDependenciesContextValue['loadAllDependencies']>(
        (params: LoadAllDependenciesParams) => {
            if (!marketId || !supplierId) {
                return;
            }

            const commonParams = { api, projectId, companyId: company?.companyId };

            loadAllDependencies({ ...params, ...commonParams })
                .then((newDependencies) => {
                    setDependencies((oldDependencies) => ({
                        ...oldDependencies,
                        ...getOrderDependencies({ supplierId, marketId }),
                        ...newDependencies
                    }));
                })
                .catch(setDependenciesError);
        },
        [api, company, marketId, supplierId, projectId]
    );

    const loadLocationsCallback = useCallback(() => {
        const commonParams = { api, projectId, companyId: company?.companyId };

        loadLocations(commonParams)
            .then((locations) => {
                setDependencies((oldDependencies) => ({
                    ...oldDependencies,
                    locations
                }));
            })
            .catch(setDependenciesError);
    }, [api, company, projectId]);

    const value = useMemo<OrderDependenciesContextValue>(
        () => ({
            loadAllDependencies: loadAllDependenciesCallback,
            loadLocations: loadLocationsCallback,
            resetDependenciesError: setDependenciesError,
            dependenciesError,
            dependencies,
            validations: getOrderValidations({ supplierId, marketId })
        }),
        [loadAllDependenciesCallback, dependencies, dependenciesError, supplierId, marketId]
    );

    return (
        <OrderDependenciesContext.Provider value={value}>
            <OrderDependenciesError />
            {props.children}
        </OrderDependenciesContext.Provider>
    );
}

export { OrderDependenciesContext, OrderDependenciesContextProvider };
