import { Breadcrumb } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import useProjectState from 'src/app-react/hooks/useProjectState';

export default function OrderManagementFormBreadcrumb({ title } : { title: string }) {
    const { project, company } = useProjectState();

    return (
        <Breadcrumb>
            <Breadcrumb.Item>{company.companyName}</Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/projects'}>{project.name}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={`/projects/${project.id}/orders`}>
                    <Trans>Order monitoring</Trans>
                </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
        </Breadcrumb>
    );
}
