import { t, Trans } from '@lingui/macro';
import { LoadTestSpecimensByCoseIdResult } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext, useEffect, useState } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { EditModalFormContext } from '../../../../../contexts/EditModalFormContext';

export function TestSpecimenFormItem() {
    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId } = useProjectState();
    const [testSpecimenes, setTestSpecimenes] = useState([] as LoadTestSpecimensByCoseIdResult[]);
    const { formInitialValues } = useContext(EditModalFormContext);
    const api = useApi();

    function evalDisableItem() {
        return formInitialValues && formInitialValues.testSpecimenTestSpecimenIdentifier !== undefined;
    }

    function loadTestSpecimensByCoseId() {
        api.concretingDocumentationClient
            .loadTestSpecimensByCoseId(companyId, projectId, structuralElementId)
            .then((response) => {
                if (response.isSuccess()) {
                    setTestSpecimenes(response.getEntity());
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        if (!formInitialValues) {
            loadTestSpecimensByCoseId();
        }
    }, [projectId, companyId]);

    return (
        <FormItem
            name="testSpecimenTestSpecimenIdentifier"
            label={
                <b>
                    <Trans>Test Specimen</Trans>
                </b>
            }
            style={{ gap: '.2rem' }}
            rules={[
                {
                    required: true,
                    message: <Trans>Please select a test specimen</Trans>
                }
            ]}>
            <Select
                data-testid="testSpecimenTestSpecimenIdentifier"
                disabled={evalDisableItem()}
                placeholder={t`Select test specimen`}
                style={{ width: '100%' }}>
                {testSpecimenes.length > 0 &&
                    testSpecimenes.map((test: LoadTestSpecimensByCoseIdResult) => {
                        return (
                            <Select.Option key={test.testSpecimenIdentifier} value={test.testSpecimenIdentifier}>
                                {test.testSpecimenIdentifier}
                            </Select.Option>
                        );
                    })}
            </Select>
        </FormItem>
    );
}
