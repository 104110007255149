import { t } from "@lingui/macro";

export const TEST_SPECIMEN_TEST_PURPOSE = [
    {
        value: 'qualityTest',
        label: t`Quality test`
    },
    {
        value: 'hardeningTest',
        label: t`Hardening test`
    },
    {
        value: 'initialTest',
        label: t`Initial test`
    },
    {
        value: 'other',
        label: t`Other`
    }
];