import { t } from "@lingui/macro";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface ShowMoreProps {
    maxHeight: number,
    label?: string
}

export function ShowMore({ maxHeight, label, children }: PropsWithChildren<ShowMoreProps>) {
    const [visible, setVisible] = useState(false);
    const [height, setHeight] = useState(0);
    const container = useRef<HTMLDivElement>();
    label = label ?? t`Show more`;

    useEffect(() => {
        if (!container.current) return () => {};
        const resizeObserver = new ResizeObserver(() => {
            if (!container.current) return;
            setHeight(container?.current?.getBoundingClientRect()?.height);
        });
        resizeObserver.observe(container.current);
        return () => resizeObserver.disconnect(); // clean up 
    }, []);

    const css = {
        maxHeight: visible ? 'none' : maxHeight,
        overflow: visible ? 'visible' : 'hidden'
    }

    return <div>
        <div style={css} ref={container}>{children}</div>
        {!visible && height === maxHeight && <>
            <Link to="" onClick={(e) => {
                e.preventDefault();
                setVisible(true)
            }}>{label}</Link>
        </>
        }
    </div>
}