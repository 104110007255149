import { t } from '@lingui/macro';
import { Space, Tag } from 'antd';
import { ShowMore } from 'src/app-react/components/ShowMore/ShowMore';
import BaseCol from '../BaseCol';
import { DeliveryOverviewData } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

function ConcreteTypesCol({ data }: { data: DeliveryOverviewData }) {
    const concreteTypes = data.concreteTypes.map((concreteType, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tag key={`${i}-${concreteType}`}>{concreteType}</Tag>
    ));

    return (
        <BaseCol span={8} title={t`Concrete types`}>
            <Space size={[0, 10]} wrap>
                <ShowMore maxHeight={80}>{concreteTypes}</ShowMore>
            </Space>
        </BaseCol>
    );
}

export default ConcreteTypesCol;
