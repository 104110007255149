import { TestingAgeForConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { SpecimenTestFormValuesDE } from '../../components/TestSpecimenTestForm/markets/DE/models';

export function createInitialFormValuesTestSpecimenTestForm(testingAgeForConcreteLoad: TestingAgeForConcreteLoad) {
    return {
        typeOfTesting: 'compressiveStrength',
        purposeOfTesting: 'qualityTest',
        testingAge:
            testingAgeForConcreteLoad?.supplierProducedConcreteTestingAge ||
            testingAgeForConcreteLoad?.orderedConcreteTypeTestingAge ||
            28,
        mould: 'cube',
        compactor: 'vibratingTable',
        compactionTime: 30,
        externalInspection: true
    } as SpecimenTestFormValuesDE;
}
