import { t } from '@lingui/macro';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { UserManagement } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import Api from 'src/api';

export function DeleteUserModal(api: Api, userManagement: UserManagement, onComplete?: () => void) {
    function onOk() {
        api.userManagementClient
            .deleteUserRoles(userManagement.companyId, userManagement.projectId, userManagement.userId)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({ message: t`User deleted succesful` });
                    onComplete?.();
                }
            })
            .catch((info: any) => {
                ErrorNotification({
                    message: info,
                    description: ''
                });
            });
    }
    return Modal.warning({
        title: <b>{t`Do you want to remove this user?`}</b>,
        width: 600,
        icon: <ExclamationCircleOutlined />,
        content: t`You are about to remove the user from the project. This means the user will no longer be available to access the project's information.`,
        okText: t`Confirm`,
        okType: 'primary',
        okButtonProps: { className: 'measurementCancelModal' },
        okCancel: true,
        cancelText: t`Cancel`,
        onOk
    });
}
