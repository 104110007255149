import { MARKETS } from "src/app-react/constants/markets";
import useProjectState from "src/app-react/hooks/useProjectState";
import { TypeOfTestingFormItem as TypeOfTestingFormItemDE } from '../markets/DE/FormItems/TypeOfTestingFormItem'
import { TypeOfTestingFormItem as TypeOfTestingFormItemUS } from '../markets/US/FormItems/TypeOfTestingFormItem';

export function TypeOfTestingFormItem() {
    const { marketId } = useProjectState();
    switch (marketId) {
        case MARKETS.DE:
            return <TypeOfTestingFormItemDE />
        default:
            return <TypeOfTestingFormItemUS />
    }
}
