import { createContext, useEffect, useState } from 'react';
import useGlobalState from '../hooks/useGlobalState';

export interface UserIsProjectManagerContextValue {
    userIsProjectManager?: boolean;
}

const UserIsProjectManagerContext = createContext<UserIsProjectManagerContextValue>({
    userIsProjectManager: undefined
});

function UserIsProjectManagerProvider({ children }: { children: unknown }) {
    const [userIsProjectManager, setUserIsProjectManager] = useState(false);

    const { api } = useGlobalState();

    useEffect(() => {
        api.userManagementClient.getUserManagementIsProjectManager().then((response) => {
            if (response.isSuccess()) {
                setUserIsProjectManager(response.getEntity());
            }
        });
        setUserIsProjectManager(false);
    }, [api]);

    return (
        <UserIsProjectManagerContext.Provider value={{ userIsProjectManager }}>
            {children}
        </UserIsProjectManagerContext.Provider>
    );
}

export { UserIsProjectManagerContext, UserIsProjectManagerProvider };
