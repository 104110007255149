import { Trans } from "@lingui/macro";
import { Form, Radio } from "antd";


export function MassConcreteItem() {
    return <Form.Item
        label={<Trans>Structural element requires mass concrete</Trans>}
        name="massConcrete">
        <Radio.Group data-testid="massConcrete" style={{ justifyContent: 'flex-start' }}>
            <Radio data-testid="massConcreteYes" value={true}>
                <Trans>Yes</Trans>
            </Radio>
            <Radio data-testid="massConcreteNo" value={false}>
                <Trans>No</Trans>
            </Radio>
        </Radio.Group>
    </Form.Item>
}