import { t } from '@lingui/macro';
import { Button, Checkbox, Input, Spin } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useEffect, useState } from 'react';

interface Props {
    options?: { text: string; value: string }[];
    onChange?: (value: string[]) => void;
    onSearch?: (value: string) => void;
    placeholder?: string;
    loading?: boolean;
}

export function TableSearhFilter({ options, onChange, onSearch, placeholder, loading }: Props) {
    const [selected, setSelected] = useState<string[]>([]);
    const [query, setQuery] = useState<string>();

    const onCheckboxChangeChange = (e: CheckboxChangeEvent) => {
        const { value } = e.target;
        setSelected((prev) => {
            let newSelected = [...prev];
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((item) => item !== value);
            } else {
                newSelected.push(value);
            }
            onChange?.(newSelected);
            return newSelected;
        });
    };

    useEffect(() => {
        onSearch?.(query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const onClear = () => {
        setQuery('');
        setSelected([]);
        onChange?.([]);
    };

    return (
        <div style={{ width: '300px' }}>
            <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Input
                    placeholder={placeholder ?? t`Search`}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                />
                <Button onClick={onClear} size="small" style={{ width: 90, height: 33 }}>
                    {t`Clear`}
                </Button>
            </div>
            {loading ? (
                <div style={{ margin: '20px', padding: '30px 50px', textAlign: 'center' }}>
                    <Spin />
                </div>
            ) : (
                <div style={{ padding: '0 10px', maxHeight: 250, overflowY: 'auto' }}>
                    {options?.map((option) => (
                        <Checkbox
                            checked={selected.includes(option.value)}
                            value={option.value}
                            style={{ width: '100%', marginLeft: '0' }}
                            key={option.value}
                            onChange={onCheckboxChangeChange}>
                            {option.text}
                        </Checkbox>
                    ))}
                </div>
            )}
        </div>
    );
}
