import { t, Trans } from '@lingui/macro';
import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import {
  CancelledOrder,
  OrderFlowState
} from '@nexploretechnology/concreting-core-client/order.services-client/order.services.dto';
import { Button, Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { cancelReasonCodeMapper } from 'src/app-react/utils/formatters';
import styles from './CancelOrderModalDetails.module.css';

interface CancelOrderDetailsModalProps {
    orderStatus: OrderFlowState;
    order: OrderOverviewBP;
    hideModal: () => void;
    showModal: boolean;
}

function CancelOrderDetailsModal(props: CancelOrderDetailsModalProps) {
    const { orderStatus, order, hideModal, showModal } = props;
    const { companyId, projectId } = useProjectState();
    const [orderCancellationDetails, setOrderCancellationDetails] = useState<CancelledOrder>({} as CancelledOrder);

    const api = useApi();
    useEffect(() => {
        if (showModal) {
            api.orderMonitoringClient.loadCancellationDetais(companyId, projectId, order.id).then((response) => {
                if (response.isSuccess()) {
                    const cancellationDetails: CancelledOrder = response.getEntity();
                    setOrderCancellationDetails(cancellationDetails);
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
        }
    }, [showModal, projectId, companyId]);

    function renderCancelledAndCancellationRequestedInformation(): JSX.Element {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>This order was cancelled on</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <DateFormatter date={orderCancellationDetails.cancelledOn} />
                    <Trans>at</Trans> <TimeFormatter date={orderCancellationDetails.cancelledOn} />
                </Col>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested date</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <DateFormatter date={orderCancellationDetails.cancelledOn} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested time</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <TimeFormatter date={orderCancellationDetails.createdOn} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Reason</Trans>
                    </label>
                </Col>
                <Col span={12}>{cancelReasonCodeMapper[orderCancellationDetails.reasonCode]}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Comment</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderCancellationDetails.reasonComment}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>User</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderCancellationDetails.userId}</Col>
            </>
        );
    }

    function renderCancellationRequestedInformation(): JSX.Element {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested date</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <DateFormatter date={orderCancellationDetails.createdOn} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Cancellation requested time</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <TimeFormatter date={orderCancellationDetails.cancelledOn} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Reason</Trans>
                    </label>
                </Col>
                <Col span={12}>{cancelReasonCodeMapper[orderCancellationDetails.reasonCode]}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Comment</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderCancellationDetails.reasonComment}</Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>User</Trans>
                    </label>
                </Col>
                <Col span={12}>{orderCancellationDetails.userId}</Col>
            </>
        );
    }

    function renderCancelledInformation(): JSX.Element {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>This order was cancelled on</Trans>
                    </label>
                </Col>
                <Col span={12}>
                    <DateFormatter date={orderCancellationDetails.cancelledOn} />
                    <Trans>at</Trans> <TimeFormatter date={orderCancellationDetails.cancelledOn} />
                </Col>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Reason</Trans>
                    </label>
                </Col>
                <Col span={12}>{cancelReasonCodeMapper[orderCancellationDetails.reasonCode]}</Col>
                {!orderCancellationDetails.userId &&
                orderCancellationDetails.reasonCode === 'cancelledBySupplier' ? null : (
                    <>
                        <Col span={12}>
                            <label className={styles.modalLabel}>
                                <Trans>User</Trans>
                            </label>
                        </Col>
                        <Col span={12}>{orderCancellationDetails.userId}</Col>
                    </>
                )}
            </>
        );
    }

    return !showModal ? null : (
        <Modal
            maskClosable={false}
            data-testid="cancel-order-details-modal"
            title={orderStatus === 'cancellationRequested' ? t`Cancellation Requested` : t`Order cancelled`}
            open={showModal}
            onCancel={hideModal}
            width={700}
            footer={[
                <Button data-testid="closeButton" key="close" type="primary" onClick={hideModal}>
                    {t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 16]}>
                {orderStatus === 'cancelled'
                    ? orderCancellationDetails.cancelledOn
                        ? renderCancelledAndCancellationRequestedInformation()
                        : renderCancelledInformation()
                    : renderCancellationRequestedInformation()}
            </Row>
        </Modal>
    );
}

export default CancelOrderDetailsModal;
