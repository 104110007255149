import { t, Trans } from '@lingui/macro';
import { UnloadingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/unloading.location-client/unloading.location.dto';
import { Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useContext, useEffect, useState } from 'react';
import { LocationSelect } from 'src/app-react/business-page/shared/FormItems/Location/LocationSelect';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryDashboardContext } from '../../../context/DeliveryDahsboardPageContext';

function TagRender(props: CustomTagProps) {
    const { label, closable, onClose } = props;

    return (
        <Tag closable={closable} onClose={onClose} style={{ maxWidth: 150, paddingRight: 20 }}>
            {label}
        </Tag>
    );
}

export function UnloadingLocationInputItem() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [unloadingLocations, setUnloadingLocations] = useState<UnloadingLocationBP[]>([]);
    const { filter, setFilter } = useContext(DeliveryDashboardContext);

    function onChange(value: string[]) {
        const newFilter = { ...filter, unloadingLocations: value };
        setFilter(newFilter);
    }

    function loadUnloadingLocations() {
        api.deliveryNoteLiteClient
            .loadUnloadingLocationsByProject(companyId, projectId, filter)
            .then((response) => {
                if (response.isSuccess()) {
                    setUnloadingLocations(response.getEntity());
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        loadUnloadingLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <>
            <b>
                <Trans>Unloading Locations</Trans>
            </b>
            <LocationSelect
                locations={unloadingLocations}
                data-testid="unloadingLocations"
                mode="multiple"
                allowClear
                tagRender={TagRender}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={filter?.unloadingLocations}
                placeholder={t`Select unloading loation`}
                style={{ width: '100%' }}
            />
        </>
    );
}
