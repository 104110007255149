/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { AutoComplete, Form } from 'antd';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import useGetDeliveryLocationNamesByProject from '../../../Modals/OrderLiteModal/hooks/useGetDeliveryLocationNameByProject';
import { useCallback, useEffect } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';

function LocationNameFormItem(props: InputFormItemProps) {
    const { loadData, locationNames, error } = useGetDeliveryLocationNamesByProject();
    const form = Form.useFormInstance();

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    const onChange = useCallback(
        (text: string) => {
            form?.setFields([
                {
                    name: 'locationName',
                    value: text
                }
            ]);
        },
        [form]
    );

    const onSearch = useCallback((inputValue: string, option: ValuesDropDown) => {
        return (option?.value as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }, []);

    return (
        <Form.Item name="locationName" data-testid="locationName" label={t`Location name`} {...props}>
            <AutoComplete
                placeholder={t`Please select a name`}
                onChange={onChange}
                options={locationNames}
                filterOption={onSearch}
            />
        </Form.Item>
    );
}

export default LocationNameFormItem;
