/* eslint-disable react/no-unused-prop-types */

import { InfoCircleOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import {
  DeliveryOverviewStatus,
  OrderDeliveryNotes
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { OrderFlowState } from '@nexploretechnology/concreting-core-client/order.services-client/order.services.dto';
import { Col, Row, Tag } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { statusText, tagStatusColor } from 'src/app-react/business-page/order-monitoring/utils';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useModalState from 'src/app-react/hooks/useModalState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import AdditionalQuantityModal from '../AdditionalQuantityModal/AdditionalQuantityModal';
import CancelOrderDetailsModal from '../CancelOrderDetailsModal/CancelOrderModalDetails';
import styles from './DeliveryOverviewStatusCard.module.css';
import { InfoOverview } from './components/InfoOverview';

export interface IDeliveryOverviewStatusCardProps {
    editOrder: () => void;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

function DeliveryOverviewStatusCard({ editOrder, setRefreshTable }: IDeliveryOverviewStatusCardProps) {
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { projectId, companyId } = useProjectState();
    const permissions = useUserPermissions();
    const api = useApi();

    const [visibleAdditionalQuantityModal, setVisibleAdditionalQuantityModal] = useState(false);

    const [percent, setPercent] = useState<DeliveryOverviewStatus>();

    const [infoOverview, setInfoOverview] = useState<OrderDeliveryNotes>();
    const [statusOverview, setStatusOverview] = useState<DeliveryOverviewStatus>();
    const [orderOverview, setOrderOverview] = useState<OrderOverviewBP>();

    const [cancelOrderDetailsModalVisible, setCancelOrderDetailsModalVisible] = useState(false);
    const [statusToShowCancelOrderDetails, setStatusToShowCancelOrderDetails] = useState({} as OrderFlowState);

    const orderLiteModalState = useModalState();
    const orderLiteModalQRState = useModalState();

    const modalVisible = orderLiteModalQRState.isOpen || orderLiteModalState.isOpen;

    const refreshData = useCallback(async () => {
        const getInfoOverview = (): void => {
            if (!projectId || !companyId) {
                return;
            }

            api.deliveryNoteClient.getDeliveryNoteOrderOverview(projectId, orderId, companyId).then((response) => {
                if (response.isSuccess()) {
                    setInfoOverview(response.getEntity());
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
        };

        const getStatusOverview = (): void => {
            if (!projectId || !companyId) {
                return;
            }
            api.deliveryNoteClient.getDeliveryNoteStatusOverview(projectId, orderId, companyId).then((response) => {
                if (response.isSuccess()) {
                    setStatusOverview(response.getEntity());
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
        };

        const getOrder = (): void => {
            if (!projectId || !companyId) {
                return;
            }
            api.orderManagementClient.getOrderOverview(projectId, companyId, orderId).then((response) => {
                if (response.isSuccess()) {
                    setOrderOverview(response.getEntity());
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
        };
        getInfoOverview();
        getStatusOverview();
        getOrder();
    }, [companyId, projectId, orderId]);

    useEffect(() => {
        if (!modalVisible) {
            refreshData();
            setRefreshTable(true);
        }

        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            if (!modalVisible) {
                refreshData();
            }
        }, 20000);

        return () => clearInterval(interval);
    }, [companyId, projectId, refreshData, setRefreshTable, modalVisible]);

    useEffect(() => {
        if (statusOverview) {
            const total: number =
                statusOverview.deliveredAndAccepted +
                statusOverview.inProgress +
                statusOverview.declined +
                statusOverview.queued +
                statusOverview.aditionalRequired;

            setPercent({
                deliveredAndAccepted:
                    statusOverview.deliveredAndAccepted > 0
                        ? Math.round((statusOverview.deliveredAndAccepted * 24) / total)
                        : 0,
                inProgress: statusOverview.inProgress > 0 ? Math.round((statusOverview.inProgress * 24) / total) : 0,
                declined: statusOverview.declined > 0 ? Math.round((statusOverview.declined * 24) / total) : 0,
                queued: statusOverview.queued > 0 ? Math.round((statusOverview.queued * 24) / total) : 0,
                aditionalRequired:
                    statusOverview.aditionalRequired > 0
                        ? Math.round((statusOverview.aditionalRequired * 24) / total)
                        : 0
            });
        }
    }, [statusOverview]);

    function showCancelOrderDetailsModal(status: OrderFlowState): void {
        setStatusToShowCancelOrderDetails(status);
        setCancelOrderDetailsModalVisible(true);
    }

    function hideCancelOrderDetailsModal(): void {
        setCancelOrderDetailsModalVisible(false);
    }

    if (!infoOverview || !statusOverview || !orderOverview) {
        return null;
    }

    const allowCancelOrder =
        (orderOverview.orderStatus === 'cancellationRequested' || orderOverview.orderStatus === 'cancelled') &&
        permissions.isAllowToReadConcreteOrderCancellationRequests;

    return (
        <>
            <br />
            <Row gutter={[120, 132]}>
                <Col span={24}>
                    <Row className={styles.cardTitle}>
                        <Col span={9}>
                            <Trans>Order</Trans> {infoOverview.orderNumber}
                        </Col>
                        <Col span={15}>
                            <Tag
                                data-testid="status-tag"
                                color={tagStatusColor(
                                    orderOverview.orderStatus,
                                    orderOverview.concreteLoadCount,
                                    orderOverview.estimatedEndOfDelivery
                                )}>
                                {statusText(
                                    orderOverview.orderStatus,
                                    orderOverview.concreteLoadCount,
                                    orderOverview.estimatedEndOfDelivery
                                )}
                            </Tag>
                            {allowCancelOrder && (
                                <InfoCircleOutlined
                                    type="primary"
                                    onClick={() => showCancelOrderDetailsModal(orderOverview.orderStatus)}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row className={styles.supplierOrderId}>
                        <Col span={9}>
                            <Trans>Supplier order id</Trans> {infoOverview.supplierOrderId}
                        </Col>
                    </Row>

                    <br />

                    <Row gutter={[0, 32]}>
                        <Col span={24}>
                            <InfoOverview
                                infoOverview={infoOverview}
                                percent={percent}
                                orderOverview={orderOverview}
                                setVisibleAdditionalQuantityModal={setVisibleAdditionalQuantityModal}
                                statusOverview={statusOverview}
                                orderLiteModalState={orderLiteModalState}
                                orderLiteModalQRState={orderLiteModalQRState}
                                editOrder={editOrder}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {visibleAdditionalQuantityModal && (
                <AdditionalQuantityModal
                    projectId={projectId}
                    orderId={orderId}
                    open={visibleAdditionalQuantityModal}
                    setOpen={setVisibleAdditionalQuantityModal}
                    refreshData={refreshData}
                    initialFormValues={{
                        currentQuantity: infoOverview.quantity,
                        quantity: 1
                    }}
                />
            )}

            {cancelOrderDetailsModalVisible && (
                <CancelOrderDetailsModal
                    orderStatus={statusToShowCancelOrderDetails}
                    order={orderOverview}
                    hideModal={hideCancelOrderDetailsModal}
                    showModal={cancelOrderDetailsModalVisible}
                />
            )}
        </>
    );
}

export default DeliveryOverviewStatusCard;
