import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import {
  generateRequired,
  generateRequiredXf,
  getRequiredXfFirst,
  getRequiredXfSecond,
  getRequiredXx
} from 'src/app-react/business-page/shared/Forms/StructuralElement/utils';
import { MARKETS } from 'src/app-react/constants/markets';

const toNumber = (value: string | number | undefined): number | null => {
    if (value || value === 0) {
        return Number(value);
    }
    return null;
};

export const getInitialFormValues = (cose: ConcretedStructuralElementBP) => {
    const fields: any = {
        name: cose.elementName,
        densityClass: cose.densityClass,
        moistureClass: cose.moistureClass,
        typeOfConcrete: cose.typeOfConcrete,
        cylindricCompressiveStrength: cose.cylindricCompressiveStrength,
        cubicCompressiveStrength: cose.cubicCompressiveStrength,
        strengthDevelopment: cose.strengthDevelopment,
        externalInspection: cose.externalInspection,
        minTemperature: cose.minTemperature,
        maxTemperature: cose.maxTemperature,
        compressiveStrength: cose.compressiveStrength,
        costCode: cose.costCode,
        massConcrete: cose.massConcrete,
        slump: cose.slump,
        slumpVariance: cose.slumpVariance,

        requiredX0: cose.requiredX0,
        requiredXc: generateRequired('XC', cose.requiredXc),
        requiredXd: generateRequired('XD', cose.requiredXd),
        requiredXf: generateRequiredXf(cose.requiredXfFirst, cose.requiredXfSecond),
        requiredXa: generateRequired('XA', cose.requiredXa),
        requiredXm: generateRequired('XM', cose.requiredXm),
        requiredXs: generateRequired('XS', cose.requiredXs)
    };

    if (cose.methodOfConcrete) {
        fields.methodConcreteUseChloride = cose.methodOfConcrete;
    }
    if (cose.chlorideContent) {
        fields.methodConcreteUseChloride += ` / ${cose.chlorideContent}`;
    }

    return fields;
};

export const createNewCose = (
    cose: ConcretedStructuralElementBP,
    data: any,
    marketId: string,
    projectId: string,
    companyId: string
) => {
    const newCose: ConcretedStructuralElementBP = {
        elementName: data.name,
        densityClass: data.densityClass,
        moistureClass: data.moistureClass,
        typeOfConcrete: data.typeOfConcrete,
        cylindricCompressiveStrength: toNumber(data.cylindricCompressiveStrength),
        cubicCompressiveStrength: toNumber(data.cubicCompressiveStrength),
        methodOfConcrete: data.methodConcreteUseChloride
            ? data.methodConcreteUseChloride.split(' / ')[0]
            : data.methodOfConcrete || undefined,
        chlorideContent: data.methodConcreteUseChloride ? data.methodConcreteUseChloride.split(' / ')[1] : undefined,
        strengthDevelopment: toNumber(data.strengthDevelopment),
        externalInspection: data.externalInspection,
        minTemperature: toNumber(data.minTemperature),
        maxTemperature: toNumber(data.maxTemperature),

        requiredX0: data.requiredX0,
        requiredXc: getRequiredXx(data.requiredXc),
        requiredXd: getRequiredXx(data.requiredXd),
        requiredXfFirst: getRequiredXfFirst(data.requiredXf),
        requiredXfSecond: getRequiredXfSecond(data.requiredXf),
        requiredXa: getRequiredXx(data.requiredXa),
        requiredXm: getRequiredXx(data.requiredXm),
        requiredXs: getRequiredXx(data.requiredXs),
        massConcrete: data.massConcrete,
        slump: toNumber(data.slump),
        slumpVariance: toNumber(data.slumpVariance),

        temperatureUnit: marketId === MARKETS.DE || marketId === MARKETS.AUS ? '°C' : '°F',

        compressiveStrength: toNumber(data.compressiveStrength),
        costCode: data.costCode,

        projectId,
        companyId,
        cataloguedStructuralElementId: cose.cataloguedStructuralElementId
    };
    if (newCose.slump) {
        newCose.slumpUnit = 'in';
    } else {
        newCose.slumpUnit = null;
    }

    return newCose;
};
