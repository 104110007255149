import { useContext } from "react";

const useContextOrProps = (required: string[], props: any, context: React.Context<any>) => {

    let { ...properties } = props;

    if (!required.every(x => x in properties)) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        properties = useContext(context);
    }

    return properties;
};

export default useContextOrProps;