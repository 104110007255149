import { FormInstance } from "antd";
import { SpecimenTestFormValuesDE } from "./models";

import { Trans } from "@lingui/macro";
import { 
    TestTypeBPDE,
    TestPurposeBPDE,
    CompactorBP,
    TestSpecimenBPDE
 } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto";
import { FreshConcreteTest } from "@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto";

export function createInitialValues(values: any) {
    const initialFormValues: SpecimenTestFormValuesDE = values;

    return {
        specimenIdentification: initialFormValues?.specimenIdentification,
        typeOfTesting: initialFormValues?.typeOfTesting,
        purposeOfTesting: initialFormValues?.purposeOfTesting,
        testingAge: initialFormValues?.testingAge?.toString(),
        mould: initialFormValues?.mould,
        compactor: initialFormValues?.compactor,
        compactionTime: initialFormValues?.compactionTime?.toString(),
        externalInspection:
            initialFormValues?.externalInspection !== undefined
                ? initialFormValues?.externalInspection
                    ? 'true'
                    : 'false'
                : undefined
    };
}

export function getSpecimentTestFormValuesDE(form: FormInstance<any>) {
    return {
        specimenIdentification: form.getFieldValue('specimenIdentification'),
        typeOfTesting: form.getFieldValue('typeOfTesting') as TestTypeBPDE,
        purposeOfTesting: form.getFieldValue('purposeOfTesting') as TestPurposeBPDE,
        testingAge: parseFloat(form.getFieldValue('testingAge') as string),
        mould: form.getFieldValue('mould'),
        compactor: form.getFieldValue('compactor') as CompactorBP,
        compactionTime: parseFloat(form.getFieldValue('compactionTime') as string),
        externalInspection: (form.getFieldValue('externalInspection') as string) === 'true'
    };
}

export function createSpecimenTestDE(
    freshConcreteTest: FreshConcreteTest,
    specimenTestFormValues: SpecimenTestFormValuesDE
) {
    const specimenTest: TestSpecimenBPDE = {
        ...freshConcreteTest,
        externalInspection: specimenTestFormValues.externalInspection
            ? specimenTestFormValues.externalInspection
            : false,
        testType: specimenTestFormValues.typeOfTesting,
        testPurpose: specimenTestFormValues.purposeOfTesting,
        testSpecimenIdentifier: specimenTestFormValues.specimenIdentification,
        mould: specimenTestFormValues.mould,
        compactor: specimenTestFormValues.compactor,
        compactionTime: {
            quantity: specimenTestFormValues.compactionTime,
            unit: 's'
        },
        testingAge: specimenTestFormValues.testingAge
    };

    return specimenTest;
}

export const specimenIdentificationTitleDE = <Trans>Specimen Identification</Trans>;