import { UserManagement } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import useGlobalState from '../hooks/useGlobalState';

export interface IUserManagementFilters {
    userName?: string;
    userEmail?: string;
}

export interface UserManagementContextValue {
    userManagement?: UserManagement[];
    reloadUserManagement?: () => void;
    loading?: boolean;
    filters?: IUserManagementFilters;
    setFilter?: (filter: IUserManagementFilters) => void;
}

const UserManagementContext = createContext<UserManagementContextValue>({
    userManagement: undefined
});

function UserManagementProvider({ children }: { children: unknown }) {
    const [userManagement, setUserManagement] = useState(undefined);
    const [reloaded, reloadUserManagement] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState<boolean>(false);
    const [filters, _setFilters] = useState<IUserManagementFilters>({});

    const setFilter = useCallback(
        (filter: IUserManagementFilters) => {
            _setFilters((prev) => ({ ...prev, ...filter }));
        },
        [_setFilters]
    );

    const { api } = useGlobalState();

    useEffect(() => {
        setLoading(true);
        api.userManagementClient
            .getUserManagement()
            .then((response) => {
                if (response.isSuccess()) {
                    setUserManagement(response.getEntity());
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
            });
    }, [api, reloaded]);

    const value = useMemo(
        () => ({ userManagement, reloadUserManagement, loading, filters, setFilter }),
        [userManagement, reloadUserManagement, loading, filters, setFilter]
    );

    return <UserManagementContext.Provider value={value}>{children}</UserManagementContext.Provider>;
}

export { UserManagementContext, UserManagementProvider };
