import { IMarketData } from '..';
import { BUSINESS_PAGES } from '../../businessPages';
import METHOD_CONCRETE from '../marketDataDE/dataValues/methodConcrete';
import AIR_MEASURMENT_METHOD_DATA from './dataValues/airMeasurmenMethod';
import LOAD_SIZES_DATA from './dataValues/loadSizes';
import SLUMPS_DATA from './dataValues/slumps';
import SPACING_IN_MINUTES_DATA from './dataValues/spacingInMinutes';
import { TEST_SPECIMEN_TEST_PURPOSE } from './dataValues/testSpecimenTestPurpose';
import { TEST_SPECIMEN_TEST_TYPE } from './dataValues/testSpecimenTestType';
import UNITS from './dataValues/units';
import FORMATS from './dataValues/formats';

const MARKET_DATA_AUS: IMarketData = {
    businessPages: [BUSINESS_PAGES.DELIVERIES, BUSINESS_PAGES.CONCRETING_DOCUMENTATION],
    custom: {
        // deprecated
        orderDependencies: {
            spacingInMinutes: SPACING_IN_MINUTES_DATA,
            loadSizes: LOAD_SIZES_DATA,
            slumps: SLUMPS_DATA
        }
    },
    constants: {
        formats: FORMATS,
        units: UNITS,
        spacingInMinutes: SPACING_IN_MINUTES_DATA,
        loadSizes: LOAD_SIZES_DATA,
        slumps: SLUMPS_DATA,
        methodConcrete: METHOD_CONCRETE,
        airMeasurmenMethod: AIR_MEASURMENT_METHOD_DATA,
        testSpecimenTestType: TEST_SPECIMEN_TEST_TYPE,
        testSpecimenTestPurpose: TEST_SPECIMEN_TEST_PURPOSE
    }
};

export default MARKET_DATA_AUS;
