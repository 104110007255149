import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import {
  CataloguedStructuralElementExtendedBP,
  ConcreteTypeBPDE,
  ConcreteTypeBPUS,
  DataList,
  SimpleClientResponse,
  StructuralElementsWithPaginationParamsBP
} from '@nexploretechnology/concreting-core-client';
import { Button, Form, Modal, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { formatDecimalByLocale } from 'src/app-react/utils/formatters';
import { ConcreteTypeAction } from './Components/ConcreteTypeAction';
import { FormTitle } from './Components/FormTitle';
import ConcreteTypeModalFormDE from './Forms/DE/FormDE';
import ConcreteTypeModalFormUS from './Forms/US/FormUS';
import { mapperFormValuesToConcreteTypeDE, mapperFormValuesToConcreteTypeUS } from './mappings';
import { ConcreteTypeModalFormValuesDE, ConcreteTypeModalFormValuesUS } from './types';
import { formatDecimalAsFraction, getFormValuesDE, getFormValuesUS } from './util';

interface Props {
    open: boolean;
    initialValues?: ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS;
    action: string;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}

function CatalogueConcreteTypeModalForm({ open, initialValues, action, setOpen, onComplete }: Props) {
    const [form] = Form.useForm();
    const { projectId, companyId, marketId, supplierId } = useProjectState();
    const [additionalAttributes, setAdditionalAttributes] = useState<string[]>([]);
    const [getAssignableStructuralElements, setAssignableStructuralElements] = useState<
        CataloguedStructuralElementExtendedBP[]
    >([]);

    const loadAssignableStructuralElements = async (): Promise<void> => {
        setAssignableStructuralElements(await getAssignableStructuralElementsOptions());
    };
    const api = useApi();

    useEffect(() => {
        loadAssignableStructuralElements();
    }, [open, projectId, companyId]);

    useEffect(() => {
        updateValue();
    }, [initialValues, marketId, projectId, companyId]);

    const updateValue = (): void => {
        if (marketId === MARKETS.DE && initialValues) {
            const initialValuesDE: Partial<ConcreteTypeModalFormValuesDE> = {
                ...(initialValues as ConcreteTypeModalFormValuesDE)
            };

            if (action === 'duplicate') {
                initialValuesDE.typeNumber = undefined;
            }
            initialValuesDE.price = formatDecimalByLocale(initialValuesDE.price, i18n.locale);
            initialValuesDE.waterCementRatio = formatDecimalByLocale(initialValuesDE.waterCementRatio, i18n.locale);
            form.setFieldsValue(initialValuesDE);

            if (initialValuesDE.additionalAttributes) {
                setAdditionalAttributes(initialValuesDE.additionalAttributes!);
            } else {
                setAdditionalAttributes([]);
            }
        } else if (initialValues) {
            const initialValuesUS: Partial<ConcreteTypeModalFormValuesUS> = {
                ...(initialValues as ConcreteTypeModalFormValuesUS)
            };

            if (initialValuesUS.maxAggregateSize) {
                initialValuesUS.maxAggregateSize = formatDecimalAsFraction(Number(initialValuesUS.maxAggregateSize));
            }
            if (initialValuesUS.aggregates) {
                initialValuesUS.aggregates.forEach((aggregate) => {
                    aggregate.maxAggregateSize = formatDecimalAsFraction(Number(aggregate.maxAggregateSize));
                });
            }

            initialValuesUS.slump = formatDecimalByLocale(initialValuesUS.slump, i18n.locale);
            initialValuesUS.slumpVariance = formatDecimalByLocale(initialValuesUS.slumpVariance, i18n.locale);
            initialValuesUS.maxAggregateSize = formatDecimalByLocale(initialValuesUS.maxAggregateSize, i18n.locale);

            if (initialValuesUS.aggregates) {
                initialValuesUS.aggregates.forEach((aggregate) => {
                    if (aggregate.maxAggregateSize) {
                        aggregate.maxAggregateSize = formatDecimalByLocale(aggregate.maxAggregateSize, i18n.locale);
                    }
                });
            }

            if (action === 'duplicate') {
                initialValuesUS.typeNumber = undefined;
            }
            initialValuesUS.price = formatDecimalByLocale(initialValuesUS.price, i18n.locale);
            initialValuesUS.waterCementRatio = formatDecimalByLocale(initialValuesUS.waterCementRatio, i18n.locale);
            form.setFieldsValue(initialValuesUS);

            if (initialValuesUS.additionalAttributes) {
                setAdditionalAttributes(initialValuesUS.additionalAttributes!);
            } else {
                setAdditionalAttributes([]);
            }
        }
    };

    const createOrDuplicateCT = async (payload: ConcreteTypeBPDE | ConcreteTypeBPUS): Promise<void> => {
        await api.catalogueManagementClient
            .createConcreteType(companyId, projectId, payload)
            .then((response: SimpleClientResponse<{ id: string }>) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Concrete type element created successfully`,
                        description: ''
                    });

                    form.resetFields();

                    onComplete?.();
                    setOpen(false);
                } else if ((response?.httpResponse?.data as any)?.error?.code === 'DUPLICATED_CONCRETE_TYPE_ERROR') {
                    ErrorNotification({
                        message: t`This concrete type already exists.`,
                        description: ''
                    });
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    const editCT = async (payload: ConcreteTypeBPDE | ConcreteTypeBPUS): Promise<void> => {
        if (payload.id) {
            await api.catalogueManagementClient
                .updateConcreteType(companyId, projectId, payload.id, payload)
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`Concrete type updated successfully`,
                            description: ''
                        });

                        form.resetFields();

                        onComplete?.();
                        setOpen(false);
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }
    };
    const executeAction = async (payload: ConcreteTypeBPDE | ConcreteTypeBPUS): Promise<void> => {
        if (action === 'create' || action === 'duplicate') {
            await createOrDuplicateCT(payload);
        } else if (action === 'edit' && payload.id) {
            await editCT(payload);
        }
    };

    const onSubmit = async (): Promise<void> => {
        try {
            await form
                .validateFields()
                .then(async () => {
                    const id: string | undefined = initialValues ? initialValues.id : undefined;
                    let payload: ConcreteTypeBPDE;

                    if (marketId === MARKETS.DE) {
                        payload = await mapperFormValuesToConcreteTypeDE(
                            companyId,
                            projectId,
                            id,
                            getFormValuesDE(form)
                        );
                    } else {
                        payload = await mapperFormValuesToConcreteTypeUS(
                            companyId,
                            projectId,
                            id,
                            getFormValuesUS(form)
                        );
                    }

                    await executeAction(payload);
                })
                .catch((error) => {
                    ErrorNotification({ message: error.errorFields[0].errors[0], description: '' });
                });
        } catch (error: any) {
            ErrorNotification({ message: error.code, description: error.message });
        }
    };

    const onCancel = (): void => {
        form.resetFields();
        setOpen(false);
    };

    const getAssignableStructuralElementsOptions = async (): Promise<CataloguedStructuralElementExtendedBP[]> => {
        const parameters: StructuralElementsWithPaginationParamsBP = {
            pagination: {
                skip: 0,
                limit: 1000,
                sorting: []
            }
        };

        return api.catalogueManagementClient
            .getExtendedCataloguedStructuralElementsForProjectAsDataList(companyId, projectId, parameters)
            .then((response: SimpleClientResponse<DataList<CataloguedStructuralElementExtendedBP>>) => {
                if (response.isSuccess()) {
                    const assignableStructuralElementsOptions: CataloguedStructuralElementExtendedBP[] =
                        response.getEntity().data;
                    return assignableStructuralElementsOptions;
                }
                ErrorNotification({
                    message: response.getError(),
                    description: ''
                });

                return [];
            });
    };

    const renderForm = () => {
        if (marketId === MARKETS.DE) {
            return (
                <ConcreteTypeModalFormDE
                    additionalAttributes={additionalAttributes}
                    assignableStructuralElements={getAssignableStructuralElements}
                />
            );
        }
        if (marketId === MARKETS.US) {
            return (
                <ConcreteTypeModalFormUS
                    supplierId={supplierId}
                    additionalAttributes={additionalAttributes}
                    assignableStructuralElements={getAssignableStructuralElements}
                />
            );
        }
        return <Skeleton />;
    };

    return !open ? null : (
        <Modal
            maskClosable={false}
            data-testid={`concrete-type-modal-form-${action}`}
            forceRender // without this flag the tests will fail, because rerendering of the component will fail
            title={<FormTitle action={action} />}
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button data-testid="cancelButton" key="cancelButton" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button onClick={onSubmit} data-testid="okButton" type="primary" key="concrete-type-modal-button">
                    <ConcreteTypeAction action={action} />
                </Button>
            ]}>
            <Form
                layout="vertical"
                form={form}
                data-testid="form"
                key={`concrete-type-form-${projectId}`}
                initialValues={{ initialValues }}>
                {renderForm()}
            </Form>
        </Modal>
    );
}

export default CatalogueConcreteTypeModalForm;
