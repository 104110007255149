import { t } from "@lingui/macro";

export const TEST_SPECIMEN_TEST_PURPOSE = [
    {
        value: 'fieldTest',
        label: t`Field test`
    },
    {
        value: 'labTest',
        label: t`Lab test`
    },
    {
        value: 'strippingTest',
        label: t`Stripping test`
    },
    {
        value: 'other',
        label: t`Other`
    }
];