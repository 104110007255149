import { t } from '@lingui/macro';
import { Card, Row } from 'antd';
import AmountCol from './components/AmountCol';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import ConcreteTypesCol from './components/ConcreteTypesCol';
import { DeliveryOverviewData } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

function AmountCardDefault({ data }: { data: DeliveryOverviewData }) {
    const { marketId } = useProjectState();
    const { units } = getProjectConstants({ marketId });

    return (
        <Card data-testid="delivery-dashboard-amount-card-default">
            <Row gutter={24}>
                <AmountCol title={t`Expected amount`}>
                    <NumberFormatter value={data.totalExpectedAmount} unit={units.volume} />
                </AmountCol>
                <AmountCol title={t`Delivered amount`}>
                    <NumberFormatter value={data.totalDeliveredAmount} unit={units.volume} />
                </AmountCol>
                <AmountCol title={t`Poured amount`}>
                    <NumberFormatter value={data.totalPouredAmount} unit={units.volume} />
                </AmountCol>
                <AmountCol title={t`Rejected amount`}>
                    <NumberFormatter value={data.totalRejectedAmount} unit={units.volume} />
                </AmountCol>
                <ConcreteTypesCol data={data} />
            </Row>
        </Card>
    );
}

export default AmountCardDefault;
