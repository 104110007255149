import { Trans, t } from "@lingui/macro";
import { Select } from "antd";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";

export function TypeOfTestingFormItem() {
    return <SelectFormItem
        name="typeOfTesting"
        label={<Trans>Type Of Testing</Trans>}
        required={true}
    >
        <Select data-testid="typeOfTesting" placeholder={t`Select the type of testing`}>
            <Select.Option key="compressiveStrength" value="compressiveStrength">
                {t`Compressive strength`}
            </Select.Option>

            <Select.Option key="tensibleSplittingStrength" value="tensibleSplittingStrength">
                {t`Tensible splitting strength`}
            </Select.Option>

            <Select.Option key="modulusOfElasticity" value="modulusOfElasticity">
                {t`Modulus of elasticity`}
            </Select.Option>

            <Select.Option key="waterImpermeability" value="waterImpermeability">
                {t`Water impermeability`}
            </Select.Option>

            <Select.Option key="CDF" value="CDF">
                {t`CDF`}
            </Select.Option>

            <Select.Option key="other" value="other">
                {t`Other`}
            </Select.Option>
        </Select>
    </SelectFormItem>
}