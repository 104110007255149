import { t, Trans } from '@lingui/macro';
import { Col, Form } from 'antd';
import InputNumber from 'src/app-react/components/Form/InputNumber';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

export function AirTemperatureItem() {
    const form = Form.useFormInstance();
    const { units } = useProjectConstantsState();

    return (
        <>
            <Col span={12}>
                <Form.Item
                    label={<Trans>Air temperature min</Trans>}
                    name="minTemperature"
                    rules={[{ message: <Trans>Invalid number</Trans>, pattern: new RegExp(/^-?\d+(?:\.\d{1,2})?$/) }]}>
                    <InputNumber
                        addonAfter={units.temperature}
                        placeholder={t`Enter Air temperature min`}
                        data-testid="airTemperatureMin"
                    />
                </Form.Item>
            </Col>
            <Col span={12}>
                <Form.Item
                    label={<Trans>Air temperature max</Trans>}
                    name="maxTemperature"
                    rules={[
                        { message: <Trans>Invalid number</Trans>, pattern: new RegExp(/^-?\d+(?:\.\d{1,2})?$/) },
                        {
                            message: <Trans>max temperature must be bigger than min temperature</Trans>,
                            validator: (_, value) => {
                                if ((value || value === 0) && value < form.getFieldValue('minTemperature')) {
                                    return Promise.reject(
                                        new Error('max temperature must be bigger than min temperature')
                                    );
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}>
                    <InputNumber
                        addonAfter={units.temperature}
                        placeholder={t`Enter Air temperature max`}
                        data-testid="airTemperatureMax"
                    />
                </Form.Item>
            </Col>
        </>
    );
}
