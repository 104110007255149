import { Trans, t } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function PasswordFormItem() {
    return (
        <FormItem
            label={<Trans>Password</Trans>}
            name="password"
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="password" data-testid="password" placeholder={t`Enter user password`} />
        </FormItem>
    );
}
