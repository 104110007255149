import { Form, Input } from "antd";
import { Trans, t } from "@lingui/macro";


interface StrengthDevelopmentItemProps {
    style?: React.CSSProperties;
}

export function StrengthDevelopmentItem({ style }: StrengthDevelopmentItemProps) {
    return (
        <Form.Item
            label={<Trans>Strength development</Trans>}
            style={style}
            name="strengthDevelopment"
            rules={[
                () => ({
                    validator(_, value) {
                        if (!value || value === '') {
                            return Promise.resolve();
                        }
                        const numberValue: number = Number(value);
                        if (numberValue >= 0 && numberValue <= 1) {
                            return Promise.resolve();
                        }
                        return Promise.reject('A correct number');
                    }
                })
            ]}>
            <Input
                type="number"
                addonBefore="r ≤ "
                min={0}
                max={1}
                placeholder={t`Enter structural strength development`}
                data-testid="strengthDevelopment"
            />
        </Form.Item>
    )
}