import { t } from '@lingui/macro';
import { Breadcrumb } from 'antd';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useNavigateDiscardChanges from 'src/app-react/hooks/useNavigateDiscardChanges';
import useProjectState from 'src/app-react/hooks/useProjectState';

export default function StructuralElementBreadcrumb() {
    const navigateConfirm = useNavigateDiscardChanges();
    const { company, project, projectId } = useProjectState();

    return (
        <Breadcrumb>
            <Breadcrumb.Item>{company?.companyName}</Breadcrumb.Item>
            <Breadcrumb.Item>
                <RawLinkButton onClick={() => navigateConfirm(`/projects`)}>{project?.name}</RawLinkButton>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <RawLinkButton onClick={() => navigateConfirm(`/projects/${projectId}/concreting-documentation`)}>
                    {t`Concreting documentation`}
                </RawLinkButton>
            </Breadcrumb.Item>
        </Breadcrumb>
    );
}
