/* eslint-disable react/jsx-props-no-spreading */

import { Col, Form, FormProps } from 'antd';
import { SupplierConfigApiForm } from './ApiForm';
import { IntegrationTypeFormItem } from './Form/components/FormItems/IntergrationTypeFormItem';
import { SupplierConfigManualForm } from './ManualForm';

type SupplierConfigFormProps = Partial<FormProps>;

export function SupplierConfigForm(props: SupplierConfigFormProps) {
    const { form } = props;
    const integrationTypeIsApi = Form.useWatch('integrationType', form);

    return (
        <Form {...props} data-testid="supplierConfigForm" id="supplierForm" layout="vertical">
            <Col span={11}>
                <IntegrationTypeFormItem />
            </Col>
            {integrationTypeIsApi === 'isApiType' && <SupplierConfigApiForm />}
            {(integrationTypeIsApi === 'isManualType' || integrationTypeIsApi === 'isQrType') && (
                <SupplierConfigManualForm />
            )}
        </Form>
    );
}
