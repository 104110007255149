import { Divider, Table } from 'antd';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import concretingDetailsTableColumns from './columns';
import styles from '../../../shared/shared.module.css';
import { Trans } from '@lingui/macro';

export interface Props {
    deliveryNoteAllDetails: DeliveryNoteAllDetails;
}

function ConcreteDetailsTableAUS(props: Props) {
    const { deliveryNoteAllDetails } = props;

    const { units } = useProjectConstantsState();

    return (
        <>
            <p className={styles.tableTitle}>
                <Trans>Concreting Details</Trans>
            </p>
            <Table
                bordered={false}
                data-testid="customer-property-table"
                scroll={{ x: 1 }}
                columns={concretingDetailsTableColumns({ units })}
                dataSource={[deliveryNoteAllDetails]}
                pagination={false}
            />
            <Divider />
        </>
    );
}

export default ConcreteDetailsTableAUS;
