import { t } from "@lingui/macro";

export const TEST_SPECIMEN_TEST_TYPE = [
    {
        value: 'compressiveStrength',
        label: t`Compressive strength`
    },
    {
        value: 'tensibleSplittingStrength',
        label: t`Tensible splitting strength`
    },
    {
        value: 'modulusOfElasticity',
        label: t`Modulus of elasticity`
    },
    {
        value: 'waterImpermeability',
        label: t`Water impermeability`
    }
];