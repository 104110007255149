import { t } from '@lingui/macro';
import Form, { Rule } from 'antd/lib/form';
import DateTimePickerFormItem from 'src/app-react/components/Form/FormItem/DateTimePickerFormItem';
import dateTimeWithDependenciesRule from 'src/app-react/components/Form/Validations/dateTimeWithDependenciesRule';
import TIME_STAMP_DEPENDENCIES from '../../../../../constants/timeStampDependencies';

const RULES: Rule[] = [
    dateTimeWithDependenciesRule({
        name: 'unloadingStart',
        sameOrBefore: ['loadingStart', 'arrivalAtConstructionSite'],
        sameOrAfter: ['unloadingEnd', 'signedOn'],
        requiredWhen: ['unloadingEnd']
    })
];

function UnloadingStartFormItem() {
    const signedOn = Form.useWatch('signedOn');

    return (
        <DateTimePickerFormItem
            required={!!signedOn}
            datePickerFormItemProps={{
                name: 'unloadingStartDate',
                label: t`Unloading start date`,
                rules: []
            }}
            timePickerFormItemProps={{
                name: 'unloadingStart',
                dependencies: TIME_STAMP_DEPENDENCIES,
                rules: RULES
            }}
        />
    );
}

export default UnloadingStartFormItem;
