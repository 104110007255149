import { Form, FormProps } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { TemperatureMeasurememntEditlModalFormDE } from './DE';
import { TemperatureMeasurememntEditlModalFormUS } from './US';

export function TemperatureMeasurementEditModalForm(props: Partial<FormProps>) {
    const { marketId } = useProjectState();

    let children: any;

    switch (marketId) {
        case MARKETS.DE:
            children = <TemperatureMeasurememntEditlModalFormDE />;
            break;
        case MARKETS.US:
        case MARKETS.AUS:
            children = <TemperatureMeasurememntEditlModalFormUS />;
            break;
        default:
            children = null;
            break;
    }

    return (
        <Form
            {...props}
            name="temperatureMeasurementModalForm"
            data-testid="temperatureMeasurementModalForm"
            layout="vertical">
            {children}
        </Form>
    );
}
