import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { StrengthDevelopmentFormatter } from 'src/app-react/components/Formatters';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { formatCompressiveStrengthDE, formatExposureClass } from '../../utils';
import styles from '../StructuralElementsTable/StructuralElementsTable.module.css';
import {
    CataloguedStructuralElementExtendedBP,
    ConcreteTypeExtendedBP
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

export const StructuralElementsTableColumnsDE = (
    dispatchModalAction: (record: CataloguedStructuralElementExtendedBP, action: string) => void,
    elementNameClicked: (record: CataloguedStructuralElementExtendedBP) => void,
    activateCheckboxClicked: (record: CataloguedStructuralElementExtendedBP) => void,
    assignUnassignConcreteTypeButtonClicked: (record: CataloguedStructuralElementExtendedBP) => void,
    concreteType?: ConcreteTypeExtendedBP
): ColumnsType<CataloguedStructuralElementExtendedBP> => {
    const getActions = (record: CataloguedStructuralElementExtendedBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: concreteType ? <Trans>Unassign</Trans> : <Trans>Assign/Unassign</Trans>,
            key: `${record.id} AssignCT`,
            icon: <DeleteOutlined />,
            onClick: () => assignUnassignConcreteTypeButtonClicked(record)
        });

        items.push({
            label: <Trans>Edit</Trans>,
            key: `${record.id} Edit`,
            icon: <EditOutlined />,
            onClick: () => {
                dispatchModalAction(record, 'edit');
            }
        });

        items.push({
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <EditOutlined />,
            onClick: () => {
                dispatchModalAction(record, 'duplicate');
            }
        });

        return items;
    };
    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<CataloguedStructuralElementExtendedBP>[] = [
        {
            title: <Trans>Structural element</Trans>,
            dataIndex: 'structuralElementName',
            sorter: true,
            width: 300,
            render: (_elementName: string, record: CataloguedStructuralElementExtendedBP) => (
                <RawLinkButton data-testid="editButton" onClick={() => elementNameClicked(record)}>
                    {record.structuralElementName || ''}
                </RawLinkButton>
            )
        },
        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'minimalRequiredCubicCompressiveStrength',
            sorter: true,
            render: (_compressiveStrength: string, record: CataloguedStructuralElementExtendedBP) => (
                <>
                    {formatCompressiveStrengthDE(
                        record.minimalRequiredTypeOfConcreteType,
                        record.minimalRequiredCylindricCompressiveStrength,
                        record.minimalRequiredCubicCompressiveStrength
                    )}
                </>
            )
        },
        {
            title: <Trans>Exposure class</Trans>,
            dataIndex: 'id',
            render: (_id: string, record: CataloguedStructuralElementExtendedBP) => (
                <>
                    {formatExposureClass(
                        record.requiredX0,
                        record.minimalRequiredXc,
                        record.minimalRequiredXd,
                        record.minimalRequiredXfFirst,
                        record.minimalRequiredXfSecond,
                        record.minimalRequiredXa,
                        record.minimalRequiredXm,
                        record.minimalRequiredXs
                    )}
                </>
            )
        },
        {
            title: <Trans>Moisture class</Trans>,
            dataIndex: 'minimalRequiredMoistureClass',
            sorter: true,
            render: (_moistureClass: string, record: CataloguedStructuralElementExtendedBP) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>{record.minimalRequiredMoistureClass || ''}</>
            )
        },
        {
            title: <Trans>Strength development</Trans>,
            dataIndex: 'minimalRequiredStrengthDevelopment',
            sorter: true,
            render: (_id: string, record: CataloguedStructuralElementExtendedBP) => (
                <StrengthDevelopmentFormatter value={record.minimalRequiredStrengthDevelopment} />
            )
        },
        {
            title: <Trans>Active</Trans>,
            dataIndex: 'isActive',
            sorter: true,
            width: 100,
            render: (_active: boolean, record: CataloguedStructuralElementExtendedBP) => (
                <Checkbox checked={record.isActive} onChange={() => activateCheckboxClicked(record)} />
            )
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            hidden: isViewer,
            width: 50, // we set this width to have an alignment of this column with the other table having also 50 as width for the action column
            render: (id: string, record: CataloguedStructuralElementExtendedBP) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                );
            }
        }
    ];
    return columns.filter((item) => !item.hidden);
};
