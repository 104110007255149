import { PERMISSIONS } from 'src/app-react/constants/permissions';

export default function buildUserPermissions(permissions: Record<string, string[]>) {
    const hasPermissions = (permission: string, action: string) => {
        return permissions?.[permission]?.includes(action);
    };

    return {
        isAllowToEditTimestamps: hasPermissions(PERMISSIONS.CONCRETE_DELIVERY_LOADS, 'EDIT_TIMESTAMPS'),
        isAllowToCreateAdmixture: hasPermissions(PERMISSIONS.CONCRETE_DELIVERY_ADMIXTURES, 'CREATE'),
        isAllowToCreateConcreteTests: hasPermissions(PERMISSIONS.FRESH_CONCRETE_TESTS, 'CREATE'),
        isAllowToCreateConcreteOrderCancellationRequests: hasPermissions(
            PERMISSIONS.CONCRETE_ORDER_CANCELLATION_REQUESTS,
            'CREATE'
        ),
        isAllowToReadConcreteOrderCancellationRequests: hasPermissions(
            PERMISSIONS.CONCRETE_ORDER_CANCELLATION_REQUESTS,
            'READ'
        ),
        isAllowToEditConcreteDeliveryNotes: hasPermissions(PERMISSIONS.CONCRETE_DELIVERY_NOTES, 'EDIT'),
        isAllowToReportArrivalConcreteDeliveryLoads: hasPermissions(
            PERMISSIONS.CONCRETE_DELIVERY_LOADS,
            'REPORT_ARRIVAL'
        ),
        isAllowToStartUnloadingConcreteDeliveryLoads: hasPermissions(
            PERMISSIONS.CONCRETE_DELIVERY_LOADS,
            'START_UNLOADING'
        ),
        isAllowToEndUnloadingConcreteDeliveryLoads: hasPermissions(
            PERMISSIONS.CONCRETE_DELIVERY_LOADS,
            'END_UNLOADING'
        ),
        isAllowToAcceptConcreteDeliveryLoads: hasPermissions(PERMISSIONS.CONCRETE_DELIVERY_LOADS, 'ACCEPT'),
        isAllowToRejectConcreteDeliveryLoads: hasPermissions(PERMISSIONS.CONCRETE_DELIVERY_LOADS, 'REJECT'),
        isAllowToCreateConcreteOrders: hasPermissions(PERMISSIONS.CONCRETE_ORDERS, 'CREATE')
    };
}
