import { Col, Row } from 'antd';
import CompressiveStrengthFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/CompressiveStrengthFormItem';
import MaxWaterToAddFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/MaxWaterToAddFormItem';
import SlumpFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/SlumpFormItem';

export default function OrderLiteModalForm2DefaultUSLayout() {
    return (
        <>
            <Row gutter={54}>
                <Col span="12">
                    <CompressiveStrengthFormItem required={false} />
                </Col>
                <Col span="12">
                    <SlumpFormItem required={false} />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <MaxWaterToAddFormItem required={false} />
                </Col>
            </Row>
        </>
    );
}
