import { Trans, t } from "@lingui/macro";
import { Form, Input } from "antd";


export function PlanNumberItem() {
    return <Form.Item
        label={<Trans>Plan number</Trans>}
        name="planNumber"
        rules={[]}>
        <Input
            type="text"
            placeholder={t`Enter structural element plan number`}
            data-testid="planNumber"
        />
    </Form.Item>
}