import {
    CataloguedStructuralElementExtendedBP,
    ConcreteTypeExtendedBP,
    ConcreteTypeMinimalBP,
    OffsetPagination
} from '@nexploretechnology/concreting-core-client';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { noPagination, paginationAdapterReactAntdToBCQD } from 'src/app-react/utils/pagination';
import { StructuralElementModal } from '../../../../components/Modals/StructuralElementModal/StructuralElementModal';
import ActivateStructuralElementModal from '../ActivateStructuralElementModal/ActivateStructuralElementModal';
import AssignConcreteType from '../AssignConcreteType/assign-concrete-type';
import StructuralElementModalForm, {
    IStructuralElementDE,
    IStructuralElementUS
} from '../StructuralElementModalForm/StructuralElementModalForm';
import UnassignStructuralElementConcreteTypeModal from '../UnassignStructuralElementConcreteTypeModal/UnassignStructuralElementConcreteTypeModal';
import { StructuralElementsTableColumnsDE } from './StructuralElementsTable.columns.de';
import { StructuralElementsTableColumnsUS } from './StructuralElementsTable.columns.us';

interface IProps {
    parentConcreteType?: ConcreteTypeExtendedBP;
    expandable?: boolean;
    setUnassignStructuralElementParentModalValue?: React.Dispatch<
        React.SetStateAction<CataloguedStructuralElementExtendedBP | undefined>
    >;
    setUnassignConcreteTypeParentModalValue?: React.Dispatch<React.SetStateAction<ConcreteTypeExtendedBP | undefined>>;
    setUnassignStructuralElementConcreteTypeParentModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    triggerReloadStructuralElementsTable: boolean;
    setTriggerReloadStructuralElementsTable: (value: boolean) => void;
}

const DE = 'DE';
const US = 'US';
const AUS = 'AUS';

export default function StructuralElementsTable({
    parentConcreteType,
    expandable = false,
    setUnassignConcreteTypeParentModalValue,
    setUnassignStructuralElementParentModalValue,
    setUnassignStructuralElementConcreteTypeParentModalVisible,
    count,
    setCount,
    triggerReloadStructuralElementsTable,
    setTriggerReloadStructuralElementsTable
}: IProps) {
    const { projectId, companyId, marketId } = useProjectState();

    const [catalogueStructuralElementModalValue, setCatalogueStructuralElementModalValue] =
        useState<CataloguedStructuralElementExtendedBP>();
    const [catalogueStructuralElementModalVisible, setCatalogueStructuralElementModalVisible] = useState(false);
    const [structuralElementModalVisible, setStructuralElementModalVisible] = useState(false);
    const [structuralElementModalAction, setStructuralElementModalAction] = useState('create');
    const [structuralElementInitialValues, setStructuralElementInitialValues] = useState<
        IStructuralElementDE | IStructuralElementUS
    >();
    const [dataSource, setDataSource] = useState<CataloguedStructuralElementExtendedBP[]>();
    const [assignConcreteTypeModalstructuralElementId, setAssignConcreteTypeModalstructuralElementId] =
        useState<string>();
    const [assignConcreteTypeModalVisible, setAssignConcreteTypeModalVisible] = useState(false);
    const [assignConcreteTypeInitialValues, setAssignConcreteTypeInitialValues] = useState<string[]>();

    const [unassignConcreteTypeModalValue] = useState<ConcreteTypeExtendedBP | undefined>();
    const [unassignStructuralElementModalValue] = useState<CataloguedStructuralElementExtendedBP | undefined>();
    const [unassignStructuralElementConcreteTypeModalVisible, setUnassignStructuralElementConcreteTypeModalVisible] =
        useState(false);

    const [assignUnassignConcreteTypeModalAction] = useState<'Assign' | 'Unassign'>();

    const api = useApi();

    const [activateStructuralElementModalValue, setActivateStructuralElementModalValue] =
        useState<CataloguedStructuralElementExtendedBP>();
    const [activateStructuralElementModalVisible, setActivateStructuralElementModalVisible] = useState(false);

    const [pagination, setPagination] = useState<OffsetPagination<CataloguedStructuralElementExtendedBP>>(noPagination);

    const loadTableData = () => {
        if (parentConcreteType) {
            api.catalogueManagementClient
                .getAssignedExtendedCaSesToConcreteTypeAsDataList(companyId, projectId, parentConcreteType.id, {
                    pagination
                })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        } else {
            api.catalogueManagementClient
                .getExtendedCataloguedStructuralElementsForProjectAsDataList(companyId, projectId, { pagination })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        }
    };

    useEffect(() => {
        loadTableData();
        const interval = setInterval(loadTableData, 20001);
        return () => clearInterval(interval);
    }, [parentConcreteType, pagination, projectId, companyId]);

    useEffect(() => {
        loadTableData();
    }, [triggerReloadStructuralElementsTable]);

    function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<CataloguedStructuralElementExtendedBP>
            | SorterResult<CataloguedStructuralElementExtendedBP>[],
        extra: TableCurrentDataSource<CataloguedStructuralElementExtendedBP>
    ): void {
        setPagination(paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra));
    }

    const generateRequiredXf = (requiredXfFirst: number | undefined, requiredXfSecond: number | undefined): string => {
        if (requiredXfFirst && requiredXfSecond) {
            return 'XF2/3';
        }
        if (requiredXfFirst) {
            return `XF${requiredXfFirst}`;
        }
        return '';
    };

    const baseDE = (structuralElement: CataloguedStructuralElementExtendedBP): IStructuralElementDE => {
        if (structuralElement) {
            return {
                name: structuralElement.structuralElementName,
                elementDescription: structuralElement.structuralElementDescription,
                planNumber: structuralElement.planNumber ? structuralElement.planNumber : undefined,

                typeOfConcrete: structuralElement.minimalRequiredTypeOfConcreteType,
                cylindricCompressiveStrength: structuralElement.minimalRequiredCylindricCompressiveStrength,
                cubicCompressiveStrength: structuralElement.minimalRequiredCubicCompressiveStrength,

                boqPositions: structuralElement.boqPositions,
                moistureClass: structuralElement.minimalRequiredMoistureClass,
                requiredX0: structuralElement.requiredX0,
                requiredXc: structuralElement.minimalRequiredXc
                    ? `XC${structuralElement.minimalRequiredXc} `
                    : undefined,
                requiredXd: structuralElement.minimalRequiredXd
                    ? `XD${structuralElement.minimalRequiredXd} `
                    : undefined,
                requiredXf: generateRequiredXf(
                    structuralElement.minimalRequiredXfFirst,
                    structuralElement.minimalRequiredXfSecond
                ),
                requiredXa: structuralElement.minimalRequiredXa
                    ? `XA${structuralElement.minimalRequiredXa} `
                    : undefined,
                requiredXm: structuralElement.minimalRequiredXm
                    ? `XM${structuralElement.minimalRequiredXm} `
                    : undefined,
                requiredXs: structuralElement.minimalRequiredXs
                    ? `XS${structuralElement.minimalRequiredXs} `
                    : undefined,
                strengthDevelopment: structuralElement.minimalRequiredStrengthDevelopment
                    ? structuralElement.minimalRequiredStrengthDevelopment
                    : undefined,
                methodOfConcrete: structuralElement.requiredMethodOfConcreteUse,
                chlorideContent: structuralElement.requiredChlorideContentClass,
                externalInspection: structuralElement.externalInspection,
                densityClass: structuralElement.requiredDensityClass,
                concreteTypeIds: getConcreteTypeIds(structuralElement.assignedConcreteTypes)
            };
        }
        return {} as IStructuralElementDE;
    };

    const baseUS = (structuralElement: CataloguedStructuralElementExtendedBP) => {
        if (structuralElement) {
            return {
                name: structuralElement.structuralElementName,
                elementDescription: structuralElement.structuralElementDescription,
                methodOfConcrete: structuralElement.requiredMethodOfConcreteUse
                    ? structuralElement.requiredMethodOfConcreteUse
                    : '',
                planNumber: structuralElement.planNumber,

                slump: String(structuralElement.requiredSlump ? structuralElement.requiredSlump : ''),
                slumpVariance: String(
                    structuralElement.maximalAllowedSlumpVariance ? structuralElement.maximalAllowedSlumpVariance : ''
                ),
                massConcrete: structuralElement.massConcreteRequired,
                compressiveStrength: structuralElement.minimalRequiredCompressiveStrength || 0,
                costCode: structuralElement.costCode,
                concreteTypeIds: getConcreteTypeIds(structuralElement.assignedConcreteTypes)
            };
        }
        return {} as IStructuralElementUS;
    };

    const getConcreteTypeIds = (concreteTypes: ConcreteTypeMinimalBP[] | undefined): string[] => {
        if (concreteTypes) {
            const ids: string[] = [];
            concreteTypes.forEach((element: ConcreteTypeMinimalBP) => {
                if (element.id) {
                    ids.push(element.id);
                }
            });
            return ids;
        }
        return [];
    };

    const getPrefillEdit = async (structuralElement: CataloguedStructuralElementExtendedBP): Promise<void> => {
        if (structuralElement) {
            if (marketId === DE) {
                setStructuralElementInitialValues({
                    id: structuralElement.id,
                    ...baseDE(structuralElement)
                });
            } else if (marketId === US || marketId === AUS) {
                setStructuralElementInitialValues({
                    id: structuralElement.id,
                    ...baseUS(structuralElement)
                });
            }
        }
    };

    const dispatchModalAction = (record: CataloguedStructuralElementExtendedBP, action: string): void => {
        getPrefillEdit(record);

        setStructuralElementModalAction(action);

        setStructuralElementModalVisible(true);
    };

    const elementNameClicked = (record: CataloguedStructuralElementExtendedBP): void => {
        setCatalogueStructuralElementModalValue(record);
        setCatalogueStructuralElementModalVisible(true);
    };

    const activateCheckboxClicked = (record: CataloguedStructuralElementExtendedBP): void => {
        setActivateStructuralElementModalValue(record);
        setActivateStructuralElementModalVisible(true);
    };

    const assignUnassignConcreteTypeButtonClicked = (record: CataloguedStructuralElementExtendedBP): void => {
        if (parentConcreteType) {
            setUnassignConcreteTypeParentModalValue?.(parentConcreteType);
            setUnassignStructuralElementParentModalValue?.(record);
            setUnassignStructuralElementConcreteTypeParentModalVisible?.(true);
        } else {
            setAssignConcreteTypeModalstructuralElementId(record.id);
            setAssignConcreteTypeInitialValues(record?.assignedConcreteTypes?.map((element) => element.id) || []);
            setAssignConcreteTypeModalVisible(true);
        }
    };

    const onCompleteStructuralElementModalForm = async (): Promise<void> => {
        setTriggerReloadStructuralElementsTable(!triggerReloadStructuralElementsTable);
    };

    return (
        <>
            <Table
                data-testid="StructuralElementsTable"
                rowClassName="StructuralElementsTable-testid"
                pagination={false}
                onChange={onChange}
                showSorterTooltip={false}
                scroll={{ x: 1000 }}
                columns={{
                    DE: StructuralElementsTableColumnsDE,
                    US: StructuralElementsTableColumnsUS,
                    AUS: StructuralElementsTableColumnsUS
                }[marketId as 'DE' | 'US' | 'AUS'](
                    dispatchModalAction,
                    elementNameClicked,
                    activateCheckboxClicked,
                    assignUnassignConcreteTypeButtonClicked,
                    parentConcreteType
                )}
                rowKey="id"
                dataSource={dataSource}
            />

            {catalogueStructuralElementModalValue && (
                <StructuralElementModal
                    structuralElementDetails={catalogueStructuralElementModalValue}
                    open={catalogueStructuralElementModalVisible}
                    setOpen={setCatalogueStructuralElementModalVisible}
                />
            )}
            <StructuralElementModalForm
                data-testid="structural-element-modal"
                open={structuralElementModalVisible}
                setOpen={setStructuralElementModalVisible}
                initialValues={structuralElementInitialValues}
                action={structuralElementModalAction}
                onComplete={onCompleteStructuralElementModalForm}
            />

            {!parentConcreteType && assignConcreteTypeModalstructuralElementId && (
                <AssignConcreteType
                    data-testid="assign-concrete-type-modal"
                    structuralElementId={assignConcreteTypeModalstructuralElementId}
                    open={assignConcreteTypeModalVisible}
                    setOpen={setAssignConcreteTypeModalVisible}
                    action={assignUnassignConcreteTypeModalAction}
                    initialValues={assignConcreteTypeInitialValues}
                />
            )}

            {!parentConcreteType && unassignConcreteTypeModalValue && unassignStructuralElementModalValue && (
                <UnassignStructuralElementConcreteTypeModal
                    data-testid="unassign-concrete-type-modal"
                    structuralElement={unassignStructuralElementModalValue}
                    concreteType={unassignConcreteTypeModalValue}
                    open={unassignStructuralElementConcreteTypeModalVisible}
                    setOpen={setUnassignStructuralElementConcreteTypeModalVisible}
                    onComplete={loadTableData}
                />
            )}

            {activateStructuralElementModalValue && (
                <ActivateStructuralElementModal
                    structuralElement={activateStructuralElementModalValue}
                    open={activateStructuralElementModalVisible}
                    setOpen={setActivateStructuralElementModalVisible}
                    onComplete={loadTableData}
                />
            )}
        </>
    );
}

export function ChildStructuralElementsTable(props: Omit<IProps, 'count' | 'setCount'>) {
    const [count, setCount] = useState(0);
    return <StructuralElementsTable {...{ ...props, count, setCount }} />;
}
