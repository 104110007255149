import { Form } from "antd";
import EditableTagGroup from "../../../../EditableTagGroup/EditableTagGroup";
import { t } from "@lingui/macro";

export function AdditionalAttributesFormItem({additionalAttributes}: {additionalAttributes: string[]}): JSX.Element {
    return (
        <Form.Item
            name="additionalAttributes"
            data-testid="additionalAttributes"
            label={t`Additional attributes`}>
            <EditableTagGroup tags={additionalAttributes} newTagLabel={t`New`} />
        </Form.Item>
    );
}