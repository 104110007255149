import { t, Trans } from '@lingui/macro';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import { useContext } from 'react';
import { useDiscardChanges } from 'src/app-react/components/DiscardChangesModal/hooks/useDiscardChanges';
import { PostTreatmentContext } from '../../../contexts/PostTreatmentContext';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export function EquippingFormItem() {
    const { unsavedChanges } = useDiscardChanges();
    const { concretingEndDate } = useContext(PostTreatmentContext);

    function onChange(current: moment.Moment | null) {
        current?.startOf('day');
        unsavedChanges.current = true;
    }

    function disabledDateHandler(current: moment.Moment | null): boolean {
        if (!current) {
            return false;
        }
        const concretingEndDateMoment: moment.Moment = moment(concretingEndDate);
        return current.isBefore(concretingEndDateMoment, 'day');
    }

    return (
        <FormItem
            name="equippingOrStripping"
            label={
                <b>
                    <Trans>Equipping/Stripping</Trans>
                </b>
            }
            style={{ gap: '.2rem' }}
            rules={[
                {
                    required: false
                }
            ]}>
            <DatePicker
                onChange={onChange}
                style={{ width: '100%' }}
                data-testid="equippingOrStripping"
                placeholder={t`Enter date of equipping/stripping`}
                disabledDate={disabledDateHandler}
            />
        </FormItem>
    );
}
