import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import {
    createConsistencyTestDE,
    createInitialValues as createInitialValuesDE,
    getConsistencyFormValuesDE
} from './markets/DE/util';
import {
    createConsistencyTestUS,
    createInitialValues as createInitialValuesUS,
    getConsistencyFormValuesUS
} from './markets/US/util';
import { FormInstance } from 'antd';
import { TestSamplingWithType } from '../CommonTestForm/models';
import { ConsistencyTestFormValues } from './models';
import { ConsistencyTest, FreshConcreteTest, newFreshConcreteTest } from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import { ConsistencyTestFormValuesUS } from './markets/US/models';
import { ConsistencyTestFormValuesDE } from './markets/DE/models';
import { createConsistencyTestAUS } from './markets/AUS/util';
import { ConsistencyTestFormValuesAUS } from './markets/AUS/models';

export function createInitialValues(values: any, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return createInitialValuesDE(values);
        default:
            return createInitialValuesUS(values);
    }
}

export function getConsistencyFormValues(values: FormInstance<any>, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return getConsistencyFormValuesDE(values);
        default:
            return getConsistencyFormValuesUS(values);
    }
}

export function createConsistencyTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    commonTest: TestSamplingWithType,
    consistecyTestFormValues: ConsistencyTestFormValues,
    marketId: string
): ConsistencyTest {
    const freshConcreteTest: FreshConcreteTest = newFreshConcreteTest(
        companyId,
        projectId,
        orderId,
        concreteLoadId,
        commonTest
    );

    switch (marketId) {
        case MARKETS.DE:
            return createConsistencyTestDE(freshConcreteTest, consistecyTestFormValues as ConsistencyTestFormValuesDE);
        case MARKETS.AUS:
            return createConsistencyTestAUS(
                freshConcreteTest,
                consistecyTestFormValues as ConsistencyTestFormValuesAUS
            );
        default:
            return createConsistencyTestUS(freshConcreteTest, consistecyTestFormValues as ConsistencyTestFormValuesUS);
    }
}
