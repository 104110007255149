import { Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext, useEffect } from 'react';
import { SUPPLIERS_DATA } from 'src/app-react/constants/suppliersData';
import { SupplierConfigFormContext } from '../../../../contexts/SupplierConfigFormContext';

const SUPPLIER_VALUES = Object.values(SUPPLIERS_DATA);

export function ManualSupplierNameFormItem() {
    const form = Form.useFormInstance();
    const { marketId } = useContext(SupplierConfigFormContext);

    const supplierNameOptions: string[] = SUPPLIER_VALUES.filter(
        (supplier) => supplier.market === marketId && supplier.manualIntegration
    ).flatMap((options) => options.name);

    useEffect(() => {
        const supplierSoftware = form.getFieldValue('supplierSoftware');

        if (supplierSoftware !== undefined) {
            form.setFieldsValue({ supplierName: undefined });
        }
    }, [form]);

    return (
        <FormItem
            name="supplierName"
            label={<Trans>Supplier name</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Select
                style={{ width: '100%' }}
                data-testid="supplierName"
                placeholder={<Trans>Select a supplier name</Trans>}>
                {supplierNameOptions?.map((supplierNameOption) => (
                    <Select.Option data-testid={supplierNameOption} value={supplierNameOption}>
                        <span>{supplierNameOption}</span>
                    </Select.Option>
                ))}
            </Select>
        </FormItem>
    );
}
