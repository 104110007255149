import { t, Trans } from '@lingui/macro';
import { TypeOfMeasurement } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext } from 'react';
import { EditModalFormContext } from '../../../../../contexts/EditModalFormContext';

interface CompressiveTypeFormItemProps {
    compressiveType: TypeOfMeasurement;
    setCompressiveType: React.Dispatch<React.SetStateAction<TypeOfMeasurement>>;
}

export function CompressiveTypeFormItem(props: CompressiveTypeFormItemProps) {
    const { compressiveType, setCompressiveType } = props;
    const { formInitialValues } = useContext(EditModalFormContext);

    function evalDisableItem() {
        return formInitialValues && formInitialValues.type !== undefined;
    }

    function onChange(option: TypeOfMeasurement) {
        setCompressiveType(option);
    }

    return (
        <FormItem
            name="type"
            label={
                <b>
                    <Trans>Type of measurement</Trans>
                </b>
            }
            style={compressiveType === 'other' ? { gap: '.2rem', marginBottom: '.7rem' } : { gap: '.2rem' }}
            rules={[
                {
                    required: true,
                    message: <Trans>Please select a type of measurement</Trans>
                }
            ]}>
            <Select
                data-testid="types"
                onChange={onChange}
                disabled={evalDisableItem()}
                placeholder={t`Select type of measurement`}
                style={{ width: '100%' }}>
                <Select.Option key="testSpecimen" value="testSpecimen">
                    {t`Test specimen`}
                </Select.Option>
                <Select.Option key="maturity" value="maturity">
                    {t`Maturity`}
                </Select.Option>
                <Select.Option key="reboundHammer" value="reboundHammer">
                    {t`Rebound hammer`}
                </Select.Option>
                <Select.Option key="pendulumHammer" value="pendulumHammer">
                    {t`Pendulum hammer`}
                </Select.Option>
                <Select.Option key="drillingCore" value="drillingCore">
                    {t`Drilling core`}
                </Select.Option>
                <Select.Option key="other" value="other">
                    {t`Other`}
                </Select.Option>
            </Select>
        </FormItem>
    );
}
