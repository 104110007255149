import { SupplierConstructorData } from '@nexploretechnology/concreting-core-client';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGetSupplierConstructorDataByBusinessNumber() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [constructorData, setConstructorData] = useState<SupplierConstructorData>(undefined);

    const loadConstructorData = useCallback(
        async (businessNumber: string) => {
            return (
                api.concreteLoadClient
                    .getConstructorDataByBusinessNumber(companyId, projectId, businessNumber)
                    .then((response) => setConstructorData(response.getEntity()))
                    // eslint-disable-next-line no-shadow
                    .catch((error: any) => {
                        setError({ message: error.code, description: error.message });
                    })
            );
        },
        [api, companyId, projectId]
    );

    return {
        loadConstructorData,
        constructorData,
        error
    };
}

export default useGetSupplierConstructorDataByBusinessNumber;
