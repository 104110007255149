/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Input, Select } from 'antd';
import { t } from '@lingui/macro';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import requieredWithDependenciesRule from 'src/app-react/components/Form/Validations/requiredWithDependenciesRule';

interface CompressiveStrengthClassFormItemProps {
    required?: boolean;
    formItem1?: SelectFormItemProps;
    formItem2?: InputFormItemProps;
    formItem3?: InputFormItemProps;
    style?: React.CSSProperties;
}

function CompressiveStrengthClassFormItem({
    required = true,
    formItem1,
    formItem2,
    formItem3,
    style
}: CompressiveStrengthClassFormItemProps) {
    const { typeOfConcrete = [] } = useProjectConstantsState();

    const formItem1Name = formItem1?.name || ['compressiveStrengthClass', 'requiredTypeOfConcreteType'];
    const formItem2Name = formItem2?.name || ['compressiveStrengthClass', 'cylindricCompressiveStrength'];
    const formItem3Name = formItem3?.name || ['compressiveStrengthClass', 'cubicCompressiveStrength'];

    return (
        <Input.Group style={{ display: 'flex', flexWrap: 'nowrap', ...style }}>
            <SelectFormItem
                name={formItem1Name}
                label={t`Compressive strength`}
                style={{ minWidth: '0', width: '100%' }}
                required={required}
                dependencies={[formItem2Name, formItem3Name]}
                rules={[
                    requieredWithDependenciesRule({
                        disable: !required,
                        dependencies: [formItem2Name, formItem3Name]
                    })
                ]}
                {...formItem1}>
                <Select
                    allowClear={required === false}
                    placeholder={t`Please select type of concrete type strength class`}
                    options={typeOfConcrete}
                    style={{ width: '100%' }}
                    data-testid="requiredTypeOfConcreteTypeTest"
                />
            </SelectFormItem>

            <InputFormItem
                name={formItem2Name}
                label=" "
                style={{ width: '100%', marginLeft: '8px', flex: '0 0 60px' }}
                dependencies={[formItem1Name, formItem3Name]}
                rules={[
                    requieredWithDependenciesRule({
                        disable: !required,
                        dependencies: [formItem1Name, formItem3Name]
                    }),
                    numberRule({
                        message: '[8, 160]',
                        max: 160,
                        min: 8
                    })
                ]}
                {...formItem2}>
                <Input type="number" min={8} max={160} data-testid="cylindricCompressiveStrength" />
            </InputFormItem>

            <span
                style={{
                    margin: '36px 15px',
                    alignContent: 'center'
                }}>
                /
            </span>

            <InputFormItem
                name={formItem3Name}
                label=" "
                style={{ width: '100%', flex: '0 0 60px' }}
                dependencies={[formItem1Name, formItem2Name]}
                rules={[
                    requieredWithDependenciesRule({
                        disable: !required,
                        dependencies: [formItem1Name, formItem2Name]
                    }),
                    numberRule({
                        message: '[8,160]',
                        max: 160,
                        min: 8
                    })
                ]}
                {...formItem3}>
                <Input type="number" min={8} max={160} data-testid="cubicCompressiveStrength" />
            </InputFormItem>
        </Input.Group>
    );
}

export default CompressiveStrengthClassFormItem;
