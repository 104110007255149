import { Col, Form, FormProps, Row } from 'antd';
import { useContext } from 'react';
import { MARKETS } from 'src/app-react/constants/markets';
import { SupplierConfigFormContext } from '../../../contexts/SupplierConfigFormContext';
import { SupplierConfigFormLayoutDE } from './FormDE';
import { SupplierNameFormItem } from './FormItems/SupplierNameFormItem';
import { SupplierSoftwareFormItem } from './FormItems/SupplierSoftwareFormItem';
import { SupplierConfigFormLayoutUS } from './FormUS';

type SupplierConfigFormProps = Partial<FormProps>;

export function SupplierConfigApiForm(props: SupplierConfigFormProps) {
    const { marketId } = useContext(SupplierConfigFormContext);
    const selectedSoftware = Form.useWatch('supplierSoftware', props.form);
    let children: any;

    switch (marketId) {
        case MARKETS.DE:
            children = <SupplierConfigFormLayoutDE />;
            break;
        case MARKETS.US:
            children = <SupplierConfigFormLayoutUS />;
            break;
        default:
            children = null;
            break;
    }

    return (
        <>
            <Row gutter={8}>
                <Col span={11}>
                    <SupplierSoftwareFormItem />
                </Col>
                <Col span={13}>{selectedSoftware && <SupplierNameFormItem />}</Col>
            </Row>
            {selectedSoftware && children}
        </>
    );
}
