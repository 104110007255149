import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col } from 'antd';
import { formatMethodOfConcrete } from 'src/app-react/business-page/catalogue-management/mapper.fields';
import styles from '../../ConcreteTypeModal.module.css';
import { empty } from '../../utils/utils';

export function MethodOfConcreteUse({ concreteDetails }: { concreteDetails: SupplierProducedConcrete }) {
    const methodOfConcrete = formatMethodOfConcrete(concreteDetails.methodOfConcrete, concreteDetails.chlorideContent);

    return (
        <>
            <Col span={24}>
                <label className={styles.modalLabel}>
                    <Trans>Method of concrete use / Chloride content</Trans>
                </label>
            </Col>
            <Col className={styles.colWithValue} data-testid="concreteTypeModal-MethodOfConcrete" span={24}>
                {methodOfConcrete || empty}
            </Col>
        </>
    );
}
