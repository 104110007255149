import { Trans, t } from '@lingui/macro';
import { SupplierProducedConcreteAccumulativeVolume } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import { IMarketUnits } from 'src/app-react/constants/marketsData';

interface Props {
    units: IMarketUnits;
    accumulativeVolumeData: SupplierProducedConcreteAccumulativeVolume;
}

export default function orderFormInformationColumnsAUS({ units, accumulativeVolumeData: volumeData }: Props) {
    const columns: ColumnsType<DeliveryNoteFullDetails> = [
        {
            title: <Trans>PO Number</Trans>,
            key: 'externalReference',
            dataIndex: 'externalReference',
            render: (_val: string, record) => record?.concreteOrder?.externalReference
        },
        {
            title: <Trans>Supplier Order / Job Number</Trans>,
            key: 'orderFormNo',
            dataIndex: 'orderFormNo',
            render: (_val: string, record) => record?.concreteOrder?.supplierOrderId
        },
        {
            title: <Trans>Total ordered</Trans>,
            key: 'totalOrder',
            dataIndex: 'totalOrder',
            render: (_val: string, record) => (
                <NumberFormatter value={record?.concreteOrder?.volume} unit={units.volume} />
            )
        },
        {
            title: <Trans>Total rejected</Trans>,
            key: 'totalRejected',
            dataIndex: 'totalRejected',
            render: () => <NumberFormatter value={volumeData?.totalRejected ?? 0} unit={units.volume} />
        },
        {
            title: <Trans>Total placed</Trans>,
            key: 'totalPlaced',
            dataIndex: 'totalPlaced',
            render: () => <NumberFormatter value={volumeData?.totalPlaced ?? 0} unit={units.volume} />
        },
        {
            title: <Trans>Total delivered</Trans>,
            key: 'totalDelivered',
            dataIndex: 'totalDelivered',
            render: () => <NumberFormatter value={volumeData?.totalDelivered ?? 0} unit={units.volume} />
        },
        {
            title: <Trans>Plus Load Expected</Trans>,
            key: 'plusLoadExpected',
            dataIndex: 'plusLoadExpected',
            render: (_val: string, record) =>
                record.concreteOrder?.additionalQuantityRequired ? (
                    <Tag color="green">{t`Yes`}</Tag>
                ) : (
                    <Tag color="grey">{t`No`}</Tag>
                )
        }
    ];

    return columns;
}
