import { MARKETS } from "src/app-react/constants/markets";
import useProjectState from "src/app-react/hooks/useProjectState";
import { PurposeOfTestingFormItem as PurposeOfTestingFormItemDE } from '../markets/DE/FormItems/PurposeOfTestingFormItem'
import { PurposeOfTestingFormItem as PurposeOfTestingFormItemUS } from '../markets/US/FormItems/PurposeOfTestingFormItem';

export function PurposeOfTestingFormItem() {
    const { marketId } = useProjectState();
    switch (marketId) {
        case MARKETS.DE:
            return <PurposeOfTestingFormItemDE />
        default:
            return <PurposeOfTestingFormItemUS />
    }
}