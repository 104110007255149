/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { Input } from 'antd';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import useProjecsConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function MaxAggregateSizeFormItem(props: InputFormItemProps) {
    const { key } = MaxAggregateSizeFormItem;
    const { units } = useProjecsConstantsState();
    const required = props.required ?? false;

    return (
        <InputFormItem
            required={required}
            name="maxAggregateSize"
            label={t`Maximum aggregate size`}
            rules={[numberRule({ minOrEqual: 0, decimal: false })]}
            {...props}>
            <Input
                type="number"
                data-testid="MaxAggregateSizeFormItem"
                addonAfter={units.maxAggregateSize}
                placeholder={t`Enter the maximum aggregate size`}
            />
        </InputFormItem>
    );
}

MaxAggregateSizeFormItem.key = 'max_aggregate_size';
export default MaxAggregateSizeFormItem;
