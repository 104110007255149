import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { MouldFormItem as MouldFormItemDE } from '../markets/DE/FormItems/MouldFormItem';
import { MouldFormItem as MouldFormItemUS } from '../markets/US/FormItems/MouldFormItem';

export function MouldFormItem() {
    const { marketId } = useProjectState();
    switch (marketId) {
        case MARKETS.DE:
        case MARKETS.AUS:
            return <MouldFormItemDE />;
        default:
            return <MouldFormItemUS />;
    }
}
