export const PERMISSIONS = {
    COMPANY: 'COMPANY',
    PROJECT: 'PROJECT',
    COMPONENT_CATALOGUE_CONCRETE_TYPES: 'COMPONENT_CATALOGUE_CONCRETE_TYPES',
    COMPONENT_CATALOGUE_STRUCTURAL_ELEMENTS: 'COMPONENT_CATALOGUE_STRUCTURAL_ELEMENTS',
    CONCRETED_STRUCTURAL_ELEMENTS: 'CONCRETED_STRUCTURAL_ELEMENTS',
    CONCRETE_DELIVERY_ADMIXTURES: 'CONCRETE_DELIVERY_ADMIXTURES',
    CONCRETE_DELIVERY_LOADS: 'CONCRETE_DELIVERY_LOADS',
    CONCRETE_DELIVERY_NOTES: 'CONCRETE_DELIVERY_NOTES',
    CONCRETE_DELIVERY_VENDOR_DATA: 'CONCRETE_DELIVERY_VENDOR_DATA',
    CONCRETE_ORDERS: 'CONCRETE_ORDERS',
    CONCRETE_ORDER_CANCELLATION_REQUESTS: 'CONCRETE_ORDER_CANCELLATION_REQUESTS',
    CONCRETE_ORDER_CHANGE_REQUESTS: 'CONCRETE_ORDER_CHANGE_REQUESTS',
    FRESH_CONCRETE_TESTS: 'FRESH_CONCRETE_TESTS',
    MONITORING_CLASS: 'MONITORING_CLASS',
    PERSONAL_PROFILE: 'PERSONAL_PROFILE',
    POST_TREATMENTS: 'POST_TREATMENTS',
    PROJECT_CONCRETE_SUPPLIERS: 'PROJECT_CONCRETE_SUPPLIERS',
    PROJECT_USER_ROLES: 'PROJECT_USER_ROLES',
    TEST_SAMPLES: 'TEST_SAMPLES',
    TEST_SPECIMEN: 'TEST_SPECIMEN',
    UNLOADING_LOCATIONS: 'UNLOADING_LOCATIONS'
};

export const PERMISSION_ACTIONS = {
    ACTION_READ: 'READ',
    ACTION_CREATE: 'CREATE',
    ACTION_EDIT: 'EDIT',
    ACTION_DELETE: 'DELETE'
};
