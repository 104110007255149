/* eslint-disable react/jsx-props-no-spreading */

import { PlusOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { BasicConcreteLoadWithOrderId, DeliveryStatusCounter } from '@nexploretechnology/concreting-core-client';
import { Badge, Button, Space, Tabs } from 'antd';
import debounce from 'lodash.debounce';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import OrderLiteLoadModal from 'src/app-react/business-page/shared/Modals/OrderLiteModal';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useModalState from 'src/app-react/hooks/useModalState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { useSearchParamsState } from 'src/app-react/hooks/useSearchParamsState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ActionModals, Modals } from './components/ActionModals/ActionModals';
import { DeliveryDashboardTable } from './components/DeliveryDashboardTable';
import { DeliveryDashboardContext } from '../../context/DeliveryDahsboardPageContext';

export const DEBOUNCE_TIME = 500;

interface DeliveryDashboardTableViewsProps {
    refreshData: boolean;
    setRefreshData: Dispatch<SetStateAction<boolean>>;
}

export function DeliveryDashboardTableViews({ refreshData, setRefreshData }: DeliveryDashboardTableViewsProps) {
    const { projectId, companyId, projectPermissions, marketId } = useProjectState();
    const api = useApi();
    const { filter } = useContext(DeliveryDashboardContext);
    const [deliveriesCounter, setDeliveriesCounter] = useState<DeliveryStatusCounter>(null);
    const { isViewer } = useUserPermissions();
    const [tabSelected, setTabState] = useSearchParamsState(
        'tab',
        marketId === 'AUS' ? 'AtConstructionSite' : 'onRoute'
    );
    const [modal, setModal] = useState<Modals>(null);
    const [selected, setSelected] = useState<BasicConcreteLoadWithOrderId | undefined>();
    const orderLiteModalState = useModalState();
    const orderLiteModalQRState = useModalState();

    const modalVisible = orderLiteModalQRState.isOpen || orderLiteModalState.isOpen;

    const loadDeliveryCounter = useCallback(
        debounce(() => {
            api.deliveryNoteLiteClient
                .getDeliveryStatusCounterByProject(companyId, projectId, filter)
                .then((response) => {
                    if (response.isSuccess()) {
                        setDeliveriesCounter(response.getEntity());
                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }, DEBOUNCE_TIME),
        [filter]
    );

    useEffect(() => {
        loadDeliveryCounter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, refreshData]);

    useEffect(() => {
        if (!modalVisible) {
            setRefreshData((prev) => !prev);
        }
    }, [modalVisible, setRefreshData]);

    function calculateOnRouteCounter() {
        return deliveriesCounter.deliveryNoteReceived + deliveriesCounter.loading + deliveriesCounter.onRoute;
    }

    function calculateAtConstrutionSiteCounter() {
        return deliveriesCounter.arrived + deliveriesCounter.releasedForUnloading;
    }

    const onModalAction = useCallback((record: BasicConcreteLoadWithOrderId, _modal: Modals) => {
        setSelected(record);
        setModal(_modal);
    }, []);

    const tableProps = {
        selectedKey: tabSelected,
        onModalAction,
        refresh: refreshData
    };

    if (!deliveriesCounter) {
        return null;
    }

    const items = [
        {
            key: 'AtConstructionSite',
            label: (
                <>
                    <Trans>At construction site</Trans> <Badge count={calculateAtConstrutionSiteCounter()} />
                </>
            ),
            children: (
                <DeliveryDashboardTable
                    tab="AtConstructionSite"
                    states={['Arrived', 'ReleasedForUnloading']}
                    {...tableProps}
                />
            )
        },
        {
            key: 'unloading',
            label: (
                <>
                    <Trans>Unloading</Trans> <Badge count={deliveriesCounter.unloading} />
                </>
            ),
            children: <DeliveryDashboardTable tab="unloading" states={['Unloading']} {...tableProps} />
        },
        {
            key: 'toSign',
            label: (
                <>
                    <Trans>To Sign</Trans> <Badge count={deliveriesCounter.unloaded} />
                </>
            ),
            children: <DeliveryDashboardTable tab="toSign" states={['Unloaded']} {...tableProps} />
        },
        {
            key: 'completed',
            label: (
                <>
                    <Trans>Completed</Trans> <Badge count={deliveriesCounter.signed} />
                </>
            ),
            children: <DeliveryDashboardTable tab="completed" states={['Signed']} {...tableProps} />
        },
        {
            key: 'rejected',
            label: (
                <>
                    <Trans>Rejected</Trans> <Badge count={deliveriesCounter.rejected} />
                </>
            ),
            children: <DeliveryDashboardTable tab="rejected" states={['Rejected']} {...tableProps} />
        }
    ];

    if (marketId !== MARKETS.AUS)
        items.unshift({
            key: 'onRoute',
            label: (
                <>
                    <Trans>On Route</Trans> <Badge count={calculateOnRouteCounter()} />
                </>
            ),
            children: (
                <DeliveryDashboardTable
                    tab="onRoute"
                    states={['DeliveryNoteReceived', 'Loading', 'OnRoute']}
                    {...tableProps}
                />
            )
        });

    return (
        <>
            <Tabs
                items={items}
                activeKey={tabSelected}
                onChange={setTabState}
                tabBarExtraContent={
                    <Space>
                        <Button
                            type="primary"
                            size="large"
                            block
                            data-testid="concrete-load-lite-add-btn"
                            hidden={isViewer || !projectPermissions.allowCreateOrderLoadLite}
                            onClick={orderLiteModalState.open}>
                            <PlusOutlined /> <Trans>Create delivery ticket</Trans>
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            block
                            data-testid="concrete-load-lite-qr-add-btn"
                            hidden={isViewer || !projectPermissions.allowCreateOrderLoadLiteQR}
                            onClick={orderLiteModalQRState.open}>
                            <PlusOutlined /> <Trans>Create delivery ticket (QR)</Trans>
                        </Button>
                    </Space>
                }
            />

            <ActionModals
                modal={modal}
                setModal={setModal}
                selected={selected}
                onClose={(keepSelected?: boolean) => {
                    setModal(null);
                    if (!keepSelected) {
                        setSelected(undefined);
                    }
                }}
                onOk={() => {
                    setRefreshData((prev) => !prev);
                }}
            />

            {orderLiteModalState.isOpen && (
                <OrderLiteLoadModal open={orderLiteModalState.isOpen} close={orderLiteModalState.close} />
            )}

            {orderLiteModalQRState.isOpen && (
                <OrderLiteLoadModal type="Qr" open={orderLiteModalQRState.isOpen} close={orderLiteModalQRState.close} />
            )}
        </>
    );
}
