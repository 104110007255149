import { EditOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { InputOrderBP } from '@nexploretechnology/concreting-core-client/concrete/order.management-client/order.management.dto';
import { Card, Form } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useProjectState from 'src/app-react/hooks/useProjectState';
import ConcreteTypeModal from '../../../../components/Modals/ConcreteTypeModal/ConcreteTypeModal';
import OrderPreviewModal, { OrderPreview } from '../../components/OrderPreviewModal/OrderPreviewModal';
import useOrderDependenciesState from '../../hooks/useOrderDependenciesState';
import { OrderManagementActions, OrderManagementActionsValues } from '../../models/Actions';
import { OrderFields } from '../../models/OrderFields';
import {
  fromOrderFieldsToInputOrderBP,
  fromOrderFieldsToOrderPreview,
  fromOrderToOrderFields
} from '../../utils/mappers';
import OrderManagementFormBreadcrumb from './components/Breadcrumb';
import OrderManagementForm from './components/Form';
import styles from './OrderManagementFormPage.module.css';

interface CreateUpdateOrderProps {
    title?: string;
    urlBack?: string;
    orderId?: string;
    action?: OrderManagementActionsValues;
    structuralElementId?: string;
    concreteTypeId?: string;
}

function OrderManagementFormPage({
    action,
    orderId,
    concreteTypeId,
    structuralElementId,
    ...props
}: CreateUpdateOrderProps) {
    const [form] = Form.useForm();
    const [orderPreviewModalVisible, setOrderPreviewModalVisible] = useState(false);
    const [concreteTypeModalVisible, setConcreteTypeModalVisible] = useState(false);
    const { projectId, marketId } = useProjectState();
    const { dependencies, loadAllDependencies } = useOrderDependenciesState();
    const navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        loadAllDependencies({
            structuralElementId,
            concreteTypeId,
            orderId
        });
    }, [loadAllDependencies, structuralElementId, concreteTypeId, orderId]);

    useEffect(() => {
        if (marketId) {
            const formFieldsValue = form.getFieldsValue();
            const orderFieldsValue = fromOrderToOrderFields({
                action,
                marketId,
                dependencies
            });

            // prevent to reset the form, when we update the locations
            const newFieldsValue = Object.entries(formFieldsValue).reduce(
                (memo, [key, value]) => {
                    if (value != null && value !== '') {
                        memo[key] = value;
                    }

                    return memo;
                },
                { ...orderFieldsValue } as Record<string, unknown>
            );

            form.setFieldsValue(newFieldsValue);
        }
    }, [marketId, dependencies, action]);

    const handleAfterCreateOrder = useCallback((): void => {
        navigate(`/projects/${projectId}/orders`);
    }, [navigate, projectId]);

    const handleOnSubmit = useCallback((): void => {
        form.validateFields()
            .then(() => {
                setOrderPreviewModalVisible(true);
            })
            .catch(() => {
                ErrorNotification({
                    message: t`Form not filled correctly`,
                    description: t`Please, make sure that all the fields are filled correctlyyyy`
                });
            });
    }, [form, setOrderPreviewModalVisible]);

    const title = props.title || t`New order`;
    const urlBack = props.urlBack || `/projects/${projectId}/order-type`;

    let orderPreview: OrderPreview;
    let newOrder: InputOrderBP;

    // TODO: we can move this logic into order preview modal.
    if (orderPreviewModalVisible) {
        const orderFields: OrderFields = form.getFieldsValue();

        orderPreview = fromOrderFieldsToOrderPreview({
            marketId,
            dependencies,
            orderFields
        }) as OrderPreview;

        newOrder = fromOrderFieldsToInputOrderBP({
            action,
            marketId,
            dependencies,
            orderFields
        });
    }

    return (
        <>
            <AppHeader
                title={title}
                onBack={() => navigate(urlBack)}
                onBreadcrumbCreation={() => <OrderManagementFormBreadcrumb title={title} />}
            />
            <Card>
                <div>
                    <Trans>Concrete type No. </Trans>
                    <a href={urlBack}>
                        <EditOutlined /> <Trans>Change</Trans>
                    </a>
                </div>
                <div className={styles.concreteLink}>
                    <RawLinkButton data-testid="concreteTypeNumber" onClick={() => setConcreteTypeModalVisible(true)}>
                        {dependencies?.concreteType?.concreteTypeNumber}
                    </RawLinkButton>
                </div>
                <OrderManagementForm
                    onBack={handleAfterCreateOrder}
                    onSubmit={handleOnSubmit}
                    form={form}
                    layout="vertical"
                    data-testid="CreateUpdateOrderForm"
                    className={styles.formColor}
                />
            </Card>

            {orderPreviewModalVisible && (
                <OrderPreviewModal
                    orderPreview={orderPreview}
                    order={newOrder}
                    orderId={action !== OrderManagementActions.Update ? undefined : orderId}
                    open={orderPreviewModalVisible}
                    setOpen={setOrderPreviewModalVisible}
                />
            )}

            {concreteTypeModalVisible && (
                <ConcreteTypeModal
                    concreteDetails={dependencies?.concreteType}
                    open={concreteTypeModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                />
            )}
        </>
    );
}

export default OrderManagementFormPage;
