import { Space } from "antd";
import { Measurements } from "./Measurements";
import { PostTreatment } from "./PostTreatment";

export function PostTreatmentView() {
    return (
        <>
        <Space direction="vertical" size="large" style={{width: '100%'}}>
             <PostTreatment/>
             <Measurements/>
        </Space>
        </>
    );
}