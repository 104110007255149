import { MARKETS } from 'src/app-react/constants/markets';
import { deliveryDetailsTableTimestampsColumnsDE } from './markets/DE/columns';
import { deliveryDetailsTableTimestampsColumnsAUS } from './markets/AUS/columns';

interface Props {
    marketId: string;
    onEditTimestamp: () => void;
    canEdit: boolean;
}

export const deliveryDetailsTableTimestampsColumns = ({ marketId, onEditTimestamp, canEdit }: Props) => {
    switch (marketId) {
        case MARKETS.AUS:
            return deliveryDetailsTableTimestampsColumnsAUS({ onEditTimestamp, canEdit });
        default:
            return deliveryDetailsTableTimestampsColumnsDE({ onEditTimestamp, canEdit });
    }
};
