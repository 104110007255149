import { ConcretingDeliveryNoteDocumentationFilter } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import React from 'react';
import { useSearchParamsState } from 'src/app-react/hooks/useSearchParamsState';
export interface ConcretingDocumentationProviderProps {
    projectId: string;
    structuralElementId?: string;
    filter: ConcretingDeliveryNoteDocumentationFilter;
    setFilter: React.Dispatch<React.SetStateAction<ConcretingDeliveryNoteDocumentationFilter>>;
}

type ProviderChildren = { projectId: string; structuralElementId?: string; children: React.ReactNode };

const ConcretingDocumentationContext = React.createContext({} as ConcretingDocumentationProviderProps);

function ConcretingDocumentationProvider({ projectId, structuralElementId, children }: ProviderChildren) {
    const [filter, setFilter] = useSearchParamsState<ConcretingDeliveryNoteDocumentationFilter>('filter', {
        deliveryTime: null,
        deliveryNoteNumber: [],
        supplierName: [],
        supplierOrderId: [],
        constructorName: []
    });

    const value: ConcretingDocumentationProviderProps = {
        projectId,
        structuralElementId,
        filter,
        setFilter
    };
    return <ConcretingDocumentationContext.Provider value={value}>{children}</ConcretingDocumentationContext.Provider>;
}

export { ConcretingDocumentationContext, ConcretingDocumentationProvider };
