import { Trans, t } from '@lingui/macro';
import { Form, Input } from 'antd';

export function NameItem() {
    return (
        <Form.Item
            name="name"
            label={<Trans>Structural element name</Trans>}
            rules={[
                {
                    required: true,
                    message: t`Please input structural element name`
                }
            ]}>
            <Input
                data-testid="structuralElementName"
                placeholder={t`Enter the name of the structural element`}
                maxLength={250}
            />
        </Form.Item>
    );
}