/* eslint-disable react/jsx-props-no-spreading */
import { t, Trans } from '@lingui/macro';
import { AutoComplete, Form } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import { useCallback, useEffect } from 'react';
import useExternalReferences from 'src/app-react/business-page/concreting-documentation/hooks/useExternalReferences';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';

function ExternalReferenceFormItem(props: FormItemInputProps) {
    const { loadExternalReferences, externalReferences } = useExternalReferences();
    const form = Form.useFormInstance();

    useEffect(() => {
        loadExternalReferences();
    }, [loadExternalReferences]);

    const onSearch = useCallback((inputValue: string, option: ValuesDropDown) => {
        return (option?.value as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }, []);

    const onChange = useCallback(
        (text: string) => {
            form?.setFields([
                {
                    name: 'externalReference',
                    value: text
                }
            ]);
        },
        [form]
    );

    return (
        <Form.Item name="externalReference" label={<Trans>PO number</Trans>} {...props}>
            <AutoComplete
                allowClear
                onChange={onChange}
                options={externalReferences?.map((reference) => ({ label: reference, value: reference }))}
                filterOption={onSearch}
                placeholder={t`Enter PO number`}
                data-testid="ExternalReferenceFormItem"
                onSearch={(query) => loadExternalReferences({ query })}
            />
        </Form.Item>
    );
}

export default ExternalReferenceFormItem;
