/* eslint-disable react/jsx-props-no-spreading */
import { Form, FormProps } from 'antd';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { MARKETS } from 'src/app-react/constants/markets';
import FormDE from './FormDE';
import FormUS from './FormUS';

export function ConcretedStructuralElementEditModalForm(props: Partial<FormProps>) {
    const { marketId } = useProjectState();

    return (
        <Form
            name="ConcretedStructuralElementModalForm"
            data-testid="ConcretedStructuralElementModalForm"
            layout="vertical"
            {...props}>
            {marketId === MARKETS.DE ? <FormDE /> : <FormUS />}
        </Form>
    );
}

