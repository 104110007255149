import {
    CloseCircleOutlined,
    EllipsisOutlined,
    FileTextOutlined,
    FilterOutlined,
    FormOutlined,
    InfoCircleOutlined
} from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { OrderFlowState, OrderOverviewOrderedConcreteType } from '@nexploretechnology/concreting-core-client';
import {
    OrderOverviewBP,
    Quantity
} from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { Button, Dropdown, Input, MenuProps, Space, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useOrderPermissions from 'src/app-react/hooks/useOrderPermissions';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { statusText, tagStatusColor } from '../../utils';
import styles from './OrderMonitoringTable.module.css';

type FixedType = boolean | 'right' | 'left';

export const OrderMonitoringColumns = (props: {
    showDetailsModal: (order: OrderOverviewBP) => void;
    showCancelOrderModal: (order: OrderOverviewBP) => void;
    showCancelOrderDetailsModal: (order: OrderOverviewBP, status: OrderFlowState) => void;
    showConcreteType: (orderedConcreteType: OrderOverviewOrderedConcreteType) => void;
    onEdit: (orderId: string) => void;
    onDuplicate: (orderId: string) => void;
    goToConcreteLoad: (orderId: string) => void;
    filterOrderNumber: string;
    setFilterOrderNumber: React.Dispatch<React.SetStateAction<string>>;
    filterOrderNumberClicked: React.MouseEventHandler<HTMLAnchorElement>;
    filterConcreteType: string;
    setFilterConcreteType: React.Dispatch<React.SetStateAction<string>>;
    filterConcreteTypeClicked: React.MouseEventHandler<HTMLAnchorElement>;
    filterStructuralElement: string;
    setFilterStructuralElement: React.Dispatch<React.SetStateAction<string>>;
    filterStructuralElementClicked: React.MouseEventHandler<HTMLAnchorElement>;
}): ColumnsType<OrderOverviewBP> => {
    const {
        showDetailsModal,
        showConcreteType,
        onEdit,
        onDuplicate,
        goToConcreteLoad,
        showCancelOrderModal,
        showCancelOrderDetailsModal,
        filterOrderNumber,
        setFilterOrderNumber,
        filterOrderNumberClicked,
        filterConcreteType,
        setFilterConcreteType,
        filterConcreteTypeClicked,
        filterStructuralElement,
        setFilterStructuralElement,
        filterStructuralElementClicked
    } = props;
    const { marketId } = useProjectState();
    const permissions = useUserPermissions();
    const getOrderPermissions = useOrderPermissions();

    const renderQuantityDE = (quantity: Quantity, performance: Quantity): JSX.Element => {
        return (
            <>
                <p>
                    <NumberFormatter value={quantity.amount} unit={quantity.unit} />
                </p>
                <p>
                    <NumberFormatter value={performance.amount} unit={performance.unit} />
                </p>
            </>
        );
    };

    const renderVolumenUS = (quantity: Quantity, spacingMinutes: number | undefined): JSX.Element => {
        return (
            <>
                <p>
                    <NumberFormatter value={quantity.amount} unit={quantity.unit} />
                </p>
                <p>
                    <NumberFormatter value={spacingMinutes} unit="min" />
                </p>
            </>
        );
    };

    const getActions = (record: OrderOverviewBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];
        const { allowEdit, allowCancel, allowDuplicate } = getOrderPermissions({ record });

        items.push({
            disabled: !allowEdit,
            label: <Trans>Edit Order</Trans>,
            key: `${record.id} Edit`,
            icon: <FormOutlined />,
            onClick: () => {
                onEdit(record.id);
            }
        });

        items.push({
            disabled: !allowDuplicate,
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <FormOutlined />,
            onClick: () => {
                onDuplicate(record.id);
            }
        });

        items.push({
            disabled: !allowCancel,
            label: <Trans>Cancel Order</Trans>,
            key: `${record.id} Cancel`,
            icon: <CloseCircleOutlined />,
            onClick: () => {
                showCancelOrderModal(record);
            }
        });

        return items;
    };

    return [
        {
            title: <Trans>Delivery time</Trans>,
            key: 'deliveryTime',
            dataIndex: 'deliveryTime',
            sorter: true,
            hidden: false,
            render: (deliveryDate: Date) => <DateTimeFormatter date={deliveryDate} />
        },
        {
            title: <Trans>Order number</Trans>,
            key: 'id',
            dataIndex: 'id',
            sorter: true,
            hidden: false,
            render: (number: string, record: OrderOverviewBP) => (
                <RawLinkButton data-testid="orderNumberLink" onClick={() => showDetailsModal(record)}>
                    {number}
                </RawLinkButton>
            ),
            filterDropdown: () => (
                <Space style={{ padding: 8 }} data-testid="orderNumberFilter">
                    <Input
                        placeholder={t`Filter by order number`}
                        value={filterOrderNumber}
                        data-testid="orderNumberFilterInput"
                        onChange={(event: any) => {
                            setFilterOrderNumber(event.target.value);
                        }}
                        onPressEnter={(event: any) => {
                            event.stopPropagation();
                            filterOrderNumberClicked(event);
                        }}
                    />
                    <Button
                        data-testid="orderNumberFilterButton"
                        onClick={filterOrderNumberClicked}
                        type="primary"
                        size="middle"
                        icon={<FilterOutlined />}>
                        <Trans id="Filter" />
                    </Button>
                </Space>
            )
        },

        {
            title: <Trans>Concrete type</Trans>,
            key: 'orderedConcreteTypeNumber',
            dataIndex: 'orderedConcreteTypeNumber',
            sorter: true,
            hidden: false,
            render: (orderedConcreteTypeNumber: string, record: OrderOverviewBP) => (
                <RawLinkButton
                    data-testid="concreTypeLink"
                    onClick={() => showConcreteType(record.orderedConcreteType)}>
                    {orderedConcreteTypeNumber}
                </RawLinkButton>
            ),
            filterDropdown: () => (
                <Space style={{ padding: 8 }} data-testid="concreteTypeFilter">
                    <Input
                        placeholder={t`Filter by concrete type`}
                        value={filterConcreteType}
                        data-testid="concreteTypeFilterInput"
                        onChange={(event: any) => {
                            setFilterConcreteType(event.target.value);
                        }}
                        onPressEnter={(event: any) => {
                            event.stopPropagation();
                            filterConcreteTypeClicked(event);
                        }}
                    />
                    <Button
                        data-testid="concreteTypeFilterButton"
                        onClick={filterConcreteTypeClicked}
                        type="primary"
                        size="middle"
                        icon={<FilterOutlined />}>
                        {' '}
                        <Trans id="Filter" />
                    </Button>
                </Space>
            )
        },

        {
            title: <Trans>Structural element</Trans>,
            key: 'structuralElementNames',
            dataIndex: 'structuralElementNames',
            sorter: true,
            hidden: false,
            render: (structuralElementNames: string[]) => <>{structuralElementNames.join('\n')}</>,
            filterDropdown: () => (
                <Space style={{ padding: 8 }} data-testid="structuralElementNameFilter">
                    <Input
                        placeholder={t`Filter by structural element`}
                        value={filterStructuralElement}
                        data-testid="structuralElementNameFilterInput"
                        onChange={(event: any) => {
                            setFilterStructuralElement(event.target.value);
                        }}
                        onPressEnter={(event: any) => {
                            event.stopPropagation();
                            filterStructuralElementClicked(event);
                        }}
                    />
                    <Button
                        data-testid="structuralElementNameFilterButton"
                        onClick={filterStructuralElementClicked}
                        type="primary"
                        size="middle"
                        icon={<FilterOutlined />}>
                        <Trans id="Filter" />
                    </Button>
                </Space>
            )
        },
        {
            title: () => {
                return marketId === 'DE' ? <Trans>Quantity / output</Trans> : <Trans>Quantity / spacing</Trans>;
            },
            key: 'concreteVolume',
            dataIndex: 'concreteVolume',
            hidden: false,
            render: (_unknown: unknown, record: OrderOverviewBP) => {
                return marketId === 'DE'
                    ? renderQuantityDE(
                          { amount: record.concreteVolume, unit: record.concreteVolumeUnit },
                          { amount: record.performance, unit: record.performanceUnit }
                      )
                    : renderVolumenUS(
                          { amount: record.concreteVolume, unit: record.concreteVolumeUnit },
                          record.spacingInMinutes
                      );
            }
        },
        {
            title: <Trans>Status</Trans>,
            key: 'orderStatus',
            dataIndex: 'orderStatus',
            sorter: true,
            hidden: false,
            render: (orderStatus: OrderFlowState, record: OrderOverviewBP) => {
                const allowCancelOrder =
                    (orderStatus === 'cancellationRequested' ||
                        orderStatus === 'cancelled' ||
                        orderStatus === 'cancelledFinal') &&
                    permissions.isAllowToReadConcreteOrderCancellationRequests;

                return isUpdateNotConfirmed(record) ? (
                    <p>
                        <RawLinkButton
                            data-testid="status-tag"
                            style={{ textTransform: 'none', fontWeight: 'normal' }}
                            onClick={() => showDetailsModal(record)}>
                            <Tag
                                data-testid="status-tag"
                                color={tagStatusColor(
                                    orderStatus,
                                    record.concreteLoadCount,
                                    record.estimatedEndOfDelivery
                                )}>
                                {statusText(orderStatus, record.concreteLoadCount, record.estimatedEndOfDelivery)}
                            </Tag>
                        </RawLinkButton>
                    </p>
                ) : (
                    <p>
                        <Tag
                            data-testid="status-tag"
                            color={tagStatusColor(
                                orderStatus,
                                record.concreteLoadCount,
                                record.estimatedEndOfDelivery
                            )}>
                            {statusText(orderStatus, record.concreteLoadCount, record.estimatedEndOfDelivery)}
                        </Tag>
                        {allowCancelOrder && (
                            <InfoCircleOutlined
                                type="primary"
                                onClick={() => showCancelOrderDetailsModal(record, orderStatus)}
                            />
                        )}
                    </p>
                );
            }
        },
        {
            title: <Trans>Action</Trans>,
            dataIndex: 'actionEditOrder',
            fixed: 'right' as FixedType,
            hidden: !permissions.isForeman,
            render: (actionEditOrder: string, record: OrderOverviewBP) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown key="actionEditOrder" data-testid="editButton" menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className={styles.buttonIcon} />
                    </Dropdown>
                );
            }
        },
        {
            title: <Trans>Delivery details</Trans>,
            dataIndex: 'actionDeliveryNote',
            fixed: 'right' as FixedType,
            hidden: false,
            render: (actionDeliveryNote: string, record: OrderOverviewBP) => (
                <Button
                    key="actionDeliveryNote"
                    data-testid="editButton"
                    type="link"
                    size="small"
                    onClick={() => goToConcreteLoad(record.id)}
                    className={styles.buttonIcon}>
                    <FileTextOutlined /> <Trans>Enter</Trans>
                </Button>
            )
        }
    ].filter((item) => !item.hidden);
};

function isUpdateNotConfirmed(orderData: OrderOverviewBP): boolean {
    return orderData.orderStatus === 'changeRequest' && Boolean(orderData.orderStatus);
}
