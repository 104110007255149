import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import useOrderDependenciesState from 'src/app-react/business-page/order-management/hooks/useOrderDependenciesState';

export function AdditionalRequirementFormItem() {
    const {
        validations: { additionalRequirementRules }
    } = useOrderDependenciesState();

    return (
        <Form.Item
            label={<Trans>Additional requirements (optional)</Trans>}
            name="additionalRequirement"
            rules={additionalRequirementRules || []}>
            <Input.TextArea
                name="additionalRequirement"
                placeholder={t`Enter additional requirements`}
                data-testid="additionalRequirement"
                autoSize={{ minRows: 8, maxRows: 8 }}
            />
        </Form.Item>
    );
}
