/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { Select } from 'antd';
import { t } from '@lingui/macro';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';

function LocationTypeFormItem(props: SelectFormItemProps) {
    return (
        <SelectFormItem
            data-testid="locationTypeFormItem"
            label={t`Location Type`}
            name="locationType"
            required
            {...props}>
            <Select
                placeholder={t`Select type unloading location`}
                data-testid="locationType"
                allowClear={props.required === false}>
                <Select.Option data-testid="ConcretePump" key="ConcretePump" value="ConcretePump">
                    {t`Concrete pump`}
                </Select.Option>

                <Select.Option data-testid="ConstructionCrane" key="ConstructionCrane" value="ConstructionCrane">
                    {t`Construction Crane`}
                </Select.Option>

                <Select.Option data-testid="CleaningLocation" key="CleaningLocation" value="CleaningLocation">
                    {t`Cleaning Location`}
                </Select.Option>

                <Select.Option data-testid="Other" key="Other" value="Other">
                    {t`Other`}
                </Select.Option>
            </Select>
        </SelectFormItem>
    );
}

export default LocationTypeFormItem;
