import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function SubscriptionKeyFormItem() {
    return (
        <FormItem
            name="subscriptionKey"
            label={<Trans>Subscription Key</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="text" data-testid="subscriptionKey" placeholder={t`Enter subscription key`} />
        </FormItem>
    );
}
