import { Col, Row } from 'antd';
import { AdditionalQuantityFormItem } from '../../../Form/components/FormItems/AdditionalQuantityFormItem';
import { AdditionalRequirementFormItem } from '../../../Form/components/FormItems/AdditionalRequirementFormItem';
import { ConcreteQuantityFormItem } from '../../../Form/components/FormItems/ConcreteQuantityFormItem';
import { DateOfConcretingFormItem } from '../../../Form/components/FormItems/DateOfConcretingFormItem';
import { DeliveryTimeFormItem } from '../../../Form/components/FormItems/DeliveryTimeFormItem';
import { StructuralElementFormItem } from '../../../Form/components/FormItems/StructuralElementFormItem';
import { UnloadingLocationFormItem } from '../../../Form/components/FormItems/UnloadingLocationFormItem';
import { OutPerHourFormItem } from '../FormItems/OutPerHourFormItem';

export function OrderManagementFormLayoutDefaultDE() {
    return (
        <>
            <Row gutter={24}>
                <Col span="6">
                    <DateOfConcretingFormItem />
                </Col>
                <Col span="6">
                    <DeliveryTimeFormItem />
                </Col>
                <Col span="12">
                    <StructuralElementFormItem />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span="6">
                    <UnloadingLocationFormItem />
                </Col>
                <Col span="6">
                    <ConcreteQuantityFormItem />
                </Col>
                <Col span="6">
                    <OutPerHourFormItem />
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span="24">
                    <AdditionalQuantityFormItem />
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span="24">
                    <AdditionalRequirementFormItem />
                </Col>
            </Row>
        </>
    )
}
