/* eslint-disable react/jsx-props-no-spreading */

import { DatePicker, TimePickerProps } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function DateTimePicker(props: TimePickerProps) {
    const { formats } = useProjectConstantsState();

    return (
        <DatePicker
            format={formats.dateTimeForm}
            showTime={{ format: formats.timeForm }}
            style={{ width: '100%' }}
            {...props}
        />
    );
}

export default DateTimePicker;
