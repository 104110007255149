import { BasicConcreteLoadData } from '@nexploretechnology/concreting-core-client';
import { Space } from 'antd';
import { DeliveryDashboardStatusInfo } from './components/DeliveryDashboardStatusInfo';

export interface DeliveryDashboardExpandedInfoProps {
    record: BasicConcreteLoadData;
}

export function DeliveryDashboardExpandedInfo(props: DeliveryDashboardExpandedInfoProps) {
    return (
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
            <DeliveryDashboardStatusInfo
                // eslint-disable-next-line react/destructuring-assignment
                record={props.record}
            />
            {/* <DeliveryDashboardRejectInfo record={props.record} />  */}
        </Space>
    );
}
