import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col } from 'antd';
import { formatSlump } from 'src/app-react/business-page/catalogue-management/utils';
import styles from '../../ConcreteTypeModal.module.css';
import { empty } from '../../utils/utils';

export function Slump({ concreteDetails }: { concreteDetails: SupplierProducedConcrete }) {
    return (
        <>
            <Col span={24}>
                <label data-testid="slump" className={styles.modalLabel}>
                    <Trans>Slump</Trans>
                </label>
            </Col>
            <Col className={styles.colWithValue} data-testid="concreteTypeModalUS-slump" span={24}>
                {formatSlump(concreteDetails.slump, null, concreteDetails.slumpUnit, empty)}
            </Col>
        </>
    );
}
