import { t, Trans } from '@lingui/macro';
import {
    AssignedCataloguedStructuralElementsBP,
    CataloguedStructuralElementExtendedBP,
    DataList,
    SimpleClientResponse,
    StructuralElementsWithPaginationParamsBP
} from '@nexploretechnology/concreting-core-client';
import { Button, Form, Modal, Select, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { formatCompressiveStrengthDE, formatCompressiveStrengthUS, formatStrengthDevelopment } from '../../utils';

interface IFormFields {
    cataloguedStructuralElementIds: string[];
}
interface IAssignStructuralElementModalProps {
    concreteTypeId: string;
    open: boolean;
    initialValues?: string[];
    action: 'Assign' | 'Unassign';
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}

interface TagProps {
    label: React.ReactNode;
    disabled: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
}

const noPaginationParameters: StructuralElementsWithPaginationParamsBP = {
    pagination: {
        skip: 0,
        limit: 1000,
        sorting: []
    }
};

function AssignStructuralElementModal({
    concreteTypeId,
    open,
    initialValues = [],
    action,
    setOpen,
    onComplete
}: IAssignStructuralElementModalProps) {
    const { projectId, companyId, marketId } = useProjectState();

    const [form] = Form.useForm<IFormFields>();
    const [structuralElements, setStructuralElements] = useState<CataloguedStructuralElementExtendedBP[]>([]);
    const [showErrorNoStructElemRemoved, setShowErrorNoStructElemRemoved] = useState(false);
    const [showErrorNoStructElemAdded, setShowErrorNoStructElemAdded] = useState(false);
    const api = useApi();

    useEffect(() => {
        async function fetchData(): Promise<void> {
            setStructuralElements(
                concreteTypeId
                    ? await api.catalogueManagementClient
                          .getAssignedExtendedCaSesToConcreteTypeAsDataList(
                              companyId,
                              projectId,
                              concreteTypeId,
                              noPaginationParameters
                          )
                          .then(
                              async (
                                  response: SimpleClientResponse<DataList<CataloguedStructuralElementExtendedBP>>
                              ) => {
                                  if (response.isSuccess()) {
                                      const assignedStructuralElements: CataloguedStructuralElementExtendedBP[] =
                                          response.getEntity().data;

                                      if (action === 'Unassign') {
                                          return assignedStructuralElements;
                                      }
                                      return getAvailableStrucElementsWithoutAssignedOnes(assignedStructuralElements);
                                  }
                                  ErrorNotification({
                                      message: response.getError(),
                                      description: ''
                                  });

                                  return [];
                              }
                          )
                    : []
            );

            function getAvailableStrucElementsWithoutAssignedOnes(
                assignedStructuralElements: CataloguedStructuralElementExtendedBP[]
            ):
                | CataloguedStructuralElementExtendedBP[]
                | ((prevState: CataloguedStructuralElementExtendedBP[]) => CataloguedStructuralElementExtendedBP[])
                | PromiseLike<
                      | CataloguedStructuralElementExtendedBP[]
                      | ((
                            prevState: CataloguedStructuralElementExtendedBP[]
                        ) => CataloguedStructuralElementExtendedBP[])
                  > {
                return api.catalogueManagementClient
                    .getExtendedCataloguedStructuralElementsForProjectAsDataList(
                        companyId,
                        projectId,
                        noPaginationParameters
                    )
                    .then((response: SimpleClientResponse<DataList<CataloguedStructuralElementExtendedBP>>) => {
                        if (response.isSuccess()) {
                            const assignableForProjectStructuralElements: CataloguedStructuralElementExtendedBP[] =
                                response.getEntity().data;

                            const assignableStructuralElements: CataloguedStructuralElementExtendedBP[] =
                                assignableForProjectStructuralElements.filter(
                                    (caSe: CataloguedStructuralElementExtendedBP) =>
                                        !assignedStructuralElements.find(
                                            (assignedCaSe: CataloguedStructuralElementExtendedBP) =>
                                                caSe.id === assignedCaSe.id
                                        )
                                );
                            return assignableStructuralElements;
                        }
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });

                        return [];
                    });
            }
        }

        fetchData();
    }, [open, projectId, companyId]);

    useEffect(() => {
        if (action === 'Assign') {
            form.resetFields();
        } else if (action === 'Unassign' && initialValues) {
            form.setFieldsValue({
                cataloguedStructuralElementIds: structuralElements
                    .filter((element) => initialValues.includes(element.id))
                    .map((element) => element.id)
            });
        }
    }, [initialValues, structuralElements]);

    const onCancel = (): void => {
        form.resetFields();
        setOpen(false);
        setShowErrorNoStructElemRemoved(false);
        setShowErrorNoStructElemAdded(false);
    };

    const checkSaveModificationsAllowed = (
        storedStrucElemIds: string[],
        currentInputStructElementIds: string[]
    ): boolean => {
        if (storedStrucElemIds.length !== currentInputStructElementIds.length) return true;
        for (const currentId of currentInputStructElementIds) {
            if (!storedStrucElemIds.includes(currentId)) return true;
        }

        return false;
    };

    const onSubmit = async (): Promise<void> => {
        const assignedCataloguedStructuralElementsBP: AssignedCataloguedStructuralElementsBP =
            await form.validateFields();
        let cataloguedStructuralElementIds: string[] =
            assignedCataloguedStructuralElementsBP.cataloguedStructuralElementIds?.length > 0
                ? assignedCataloguedStructuralElementsBP.cataloguedStructuralElementIds
                : [];

        if (action === 'Assign') {
            api.catalogueManagementClient
                .getAssignedExtendedCaSesToConcreteTypeAsDataList(
                    companyId,
                    projectId,
                    concreteTypeId,
                    noPaginationParameters
                )
                .then((response: SimpleClientResponse<DataList<CataloguedStructuralElementExtendedBP>>) => {
                    if (response.isSuccess()) {
                        const assignedStructuralElements: CataloguedStructuralElementExtendedBP[] =
                            response.getEntity().data;
                        if (cataloguedStructuralElementIds.length === 0) {
                            setShowErrorNoStructElemAdded(true);
                        } else {
                            cataloguedStructuralElementIds = cataloguedStructuralElementIds.concat(
                                assignedStructuralElements.map((caSe: CataloguedStructuralElementExtendedBP) => caSe.id)
                            );
                            setShowErrorNoStructElemAdded(false);
                            saveAssignedCataloguedStructuralElementsToConcreteType();
                        }
                    }
                });
        } else {
            // Unassign
            api.catalogueManagementClient
                .getAssignedExtendedCaSesToConcreteTypeAsDataList(
                    companyId,
                    projectId,
                    concreteTypeId,
                    noPaginationParameters
                )
                .then((response: SimpleClientResponse<DataList<CataloguedStructuralElementExtendedBP>>) => {
                    if (response.isSuccess()) {
                        const assignedStructuralElementsInDb: CataloguedStructuralElementExtendedBP[] =
                            response.getEntity().data;
                        const assignedStrucElemInDbIds = assignedStructuralElementsInDb.map((element) => element.id);
                        const currentInputIds = form.getFieldsValue().cataloguedStructuralElementIds;
                        if (checkSaveModificationsAllowed(assignedStrucElemInDbIds, currentInputIds)) {
                            setShowErrorNoStructElemRemoved(false);
                            saveAssignedCataloguedStructuralElementsToConcreteType();
                        } else {
                            setShowErrorNoStructElemRemoved(true);
                        }
                    }
                });
        }

        async function saveAssignedCataloguedStructuralElementsToConcreteType(): Promise<void> {
            const payload: AssignedCataloguedStructuralElementsBP = {
                cataloguedStructuralElementIds
            };

            const response = await api.catalogueManagementClient.assignCataloguedStructuralElementsToConcreteType(
                companyId,
                projectId,
                concreteTypeId,
                payload
            );

            if (response.getError()) {
                return ErrorNotification({
                    message: response.getError(),
                    description: ''
                });
            }

            SuccessNotification({
                message:
                    action === 'Assign'
                        ? t`Structural element(s) assigned successfully`
                        : t`Structural element(s) unassigned successfully`,
                description: ''
            });
            form.resetFields();
            onComplete?.();
            setOpen(false);
        }
    };

    const tagRender = ({ label, closable, onClose }: TagProps) => (
        <Tag color="blue" closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {label}
        </Tag>
    );

    return (
        <Modal
            maskClosable={false}
            data-testid="modal"
            forceRender
            getContainer={false}
            title={
                action === 'Assign'
                    ? t`Assign structural element(s) to concrete type`
                    : t`Unassign structural element(s) to concrete type`
            }
            open={open}
            onCancel={onCancel}
            onOk={onSubmit}
            width={1000}
            footer={[
                <Button key="footer-btn-1" data-testid="cancel-btn" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button key="footer-btn-2" onClick={onSubmit} data-testid="submit-btn" type="primary">
                    <Trans>Save</Trans>
                </Button>
            ]}>
            <Form form={form} data-testid="form" key={`assign-structural-modal-form-${concreteTypeId}`}>
                <label>
                    {action === 'Assign' ? (
                        <Trans>Please select the structural element(s) you want to assign to the concrete type</Trans>
                    ) : (
                        <Trans>
                            Please deselect the structural element(s) you want to unassign to the concrete type
                        </Trans>
                    )}
                </label>
                <p />
                <Form.Item
                    label={
                        action === 'Assign' ? (
                            <Trans>Assign structural element(s) to concrete type</Trans>
                        ) : (
                            <Trans>Unassign structural element(s) from concrete type</Trans>
                        )
                    }
                    name="cataloguedStructuralElementIds"
                    className="structural-element-modal__inline100">
                    <Select
                        mode="multiple"
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showArrow
                        tagRender={tagRender}
                        style={{ width: '100%' }}
                        data-testid="select-input">
                        {structuralElements.length ? (
                            structuralElements.map((element) => (
                                <Select.Option
                                    data-testid="select-option"
                                    key={`structural-element-${element.id}`}
                                    label={element.structuralElementName}
                                    value={element.id}>
                                    <span>
                                        <b>{element.structuralElementName}</b>&nbsp;&#40;
                                        {[
                                            element.structuralElementDescription,
                                            marketId === 'DE'
                                                ? formatCompressiveStrengthDE(
                                                      element.minimalRequiredTypeOfConcreteType,
                                                      element.minimalRequiredCylindricCompressiveStrength,
                                                      element.minimalRequiredCubicCompressiveStrength
                                                  )
                                                : marketId === 'US' || marketId === 'AUS'
                                                ? formatCompressiveStrengthUS(
                                                      element.minimalRequiredCompressiveStrength,
                                                      element.compressiveStrengthUnit
                                                  )
                                                : undefined,
                                            formatStrengthDevelopment(element.minimalRequiredStrengthDevelopment),
                                            element.costCode
                                        ]
                                            .filter(Boolean)
                                            .join(',')}
                                        &#41;
                                    </span>
                                </Select.Option>
                            ))
                        ) : (
                            <Select.Option data-testid="select-option-none" value="none" disabled>
                                <span>
                                    <Trans>No results available</Trans>
                                </span>
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <div>
                    <p style={{ color: 'red' }}>
                        {showErrorNoStructElemRemoved ? (
                            <Trans>Cannot save: no structural element has been removed</Trans>
                        ) : (
                            ''
                        )}
                    </p>
                </div>
                <div>
                    <p style={{ color: 'red' }}>
                        {showErrorNoStructElemAdded ? (
                            <Trans>Cannot save: no structural element has been added</Trans>
                        ) : (
                            ''
                        )}
                    </p>
                </div>
            </Form>
        </Modal>
    );
}

export default AssignStructuralElementModal;
