import { Form } from 'antd';
import { SUPPLIERS } from 'src/app-react/constants/suppliers';
import { SupplierConfigFormLayoutCemexUS } from './components/Layouts/CemexLayout';
import { SupplierConfigFormLayoutDefaultUS } from './components/Layouts/DefaultLayout';

export function SupplierConfigFormLayoutUS() {
    const form = Form.useFormInstance();
    const selectedSoftware = Form.useWatch('supplierSoftware', form);

    switch (selectedSoftware) {
        case SUPPLIERS.CEMEX_GO:
            return <SupplierConfigFormLayoutCemexUS />;
        default:
            return <SupplierConfigFormLayoutDefaultUS />;
    }
}
