import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

export function TemperatureFormItem() {
    const { units } = useProjectConstantsState();

    return (
        <FormItem
            name="result"
            label={
                <b>
                    <Trans>Temperature</Trans>
                </b>
            }
            style={{ gap: '.2rem' }}
            rules={[
                {
                    required: true,
                    message: <Trans>Please enter temperature</Trans>
                },
                {
                    pattern:
                        i18n.locale === 'de-DE' ? new RegExp(/^-?\d+(?:,\d{1})?$/) : new RegExp(/^-?\d+(?:\.\d{1})?$/),
                    message: <Trans>temperature value is invalid</Trans>
                }
            ]}>
            <Input
                style={{ width: '100%' }}
                placeholder={t`Enter temperature`}
                data-testid="result"
                addonAfter={units.temperature}
            />
        </FormItem>
    );
}
