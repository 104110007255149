/* eslint-disable react-hooks/exhaustive-deps */

import { t } from '@lingui/macro';
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { Form } from 'antd';
import { useCallback, useEffect, useRef } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useGenerateConcreteLoadLiteData from '../../../../../hooks/useGenerateConcreteLoadLiteData';
import ConcreteLoadDataLiteFormFields from '../../../../../models/ConcreteLoadDataLiteFormFields';
import { ORDER_LOAD_LITE_TYPES } from '../../../../../models/Types';
import fromRawDataResponseToFormFields from '../../../../../utils/mappers/fromRawDataResponseToFormFields';

interface OrderLoadLiteModalForm0QRLayout {
    initializeForm: (formFields: ConcreteLoadDataLiteFormFields, origin?: string) => void;
    onNext: () => void;
}

export function OrderLoadLiteModalForm0QRLayout({ onNext, initializeForm }: OrderLoadLiteModalForm0QRLayout) {
    const { generateConcreteLoadLiteData, error } = useGenerateConcreteLoadLiteData();
    const form = Form.useFormInstance();
    const prevScanResult = useRef<string>();

    useEffect(() => {
        if (error) {
            if (error.message == 'ERROR_CONCRETE_LOAD_LITE_DELIVERY_NOTE_NUMBER_ALREADY_REGISTERED') {
                ErrorNotification({
                    message: t`INVALID QR CODE`,
                    description: t`Delivery Docket already registered`
                });
            } else {
                ErrorNotification({
                    message: t`INVALID QR CODE`,
                    description: t`QR code cannot be scanned because the data is incomplete. Add the delivery note number manually`
                });
            }
        }
    }, [error]);

    const onScan = useCallback(
        async (result: IDetectedBarcode[]) => {
            if (result == null || result.length <= 0 || prevScanResult.current === result[0].rawValue) {
                return;
            }

            prevScanResult.current = result[0].rawValue;

            const concreteLoadData = await generateConcreteLoadLiteData(result[0].rawValue);

            if (concreteLoadData) {
                const fieldsValue = form.getFieldsValue(true);
                initializeForm(
                    fromRawDataResponseToFormFields(concreteLoadData, fieldsValue),
                    ORDER_LOAD_LITE_TYPES.QR
                );
                onNext();
            }
        },
        [onNext, initializeForm, generateConcreteLoadLiteData, form]
    );

    return (
        <Scanner
            onScan={onScan}
            components={{ zoom: true }}
            scanDelay={0}
            styles={{ container: { overflow: 'hidden' } }}
        />
    );
}

export default OrderLoadLiteModalForm0QRLayout;
