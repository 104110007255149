import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import AmountCardAUS from './AmountCardAUS';
import AmountCardDefault from './AmountCardDefault';
import { DeliveryOverviewData } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

interface AmountCardProps {
    data: DeliveryOverviewData;
}

function AmountCard({ data }: AmountCardProps) {
    const { marketId } = useProjectState();

    switch (marketId) {
        case MARKETS.AUS:
            return <AmountCardAUS data={data} />;
        default:
            return <AmountCardDefault data={data} />;
    }
}

export default AmountCard;
