import { t } from '@lingui/macro';
import { Button, Modal, Row } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import { ConcreteTypeNumber } from './views/ConcreteTypeNumber';
import { MaxAggregateSize } from './views/MaxAggregationSize';
import { WaterCementRatio } from './views/WaterCementRatio';
import { CompressiveStrength } from './views/viewsDE/CompressiveStrength';
import { ConsistencyClass } from './views/viewsDE/ConsistencyClass';
import { ExposureClass } from './views/viewsDE/ExposureClass';
import { MethodOfConcreteUse as MethodOfConcreteUseDE } from './views/viewsDE/MethodOfConcreteUse';
import { MoistureClass } from './views/viewsDE/MoistureClass';
import { StrengthDevelopmentClass } from './views/viewsDE/StrengthDevelopmentClass';
import { CompressiveStrengthUS } from './views/viewsUS/CompressiveStrength';
import { MethodOfConcreteUse as MethodOfConcreteUseUS } from './views/viewsUS/MethodOfConcreteUse';

import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { TestingAge } from './views/viewsDE/TestingAge';
import { Slump } from './views/viewsUS/Slump';

interface ConcreteTypeModalProps {
    concreteDetails: SupplierProducedConcrete;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    displayNextLabel?: boolean;
    onClose?: () => void;
}
function ConcreteTypeModal(props: ConcreteTypeModalProps) {
    const { concreteDetails, onClose, open, setOpen, displayNextLabel } = props;

    const { marketId } = useProjectState();

    const onCloseButton = (): void => {
        setOpen(false);
    };

    const onNextButton = (): void => {
        setOpen(false);
        onClose?.();
    };

    return (
        <Modal
            maskClosable={false}
            open={open}
            forceRender
            onCancel={onCloseButton}
            title={marketId === MARKETS.DE ? t`Concrete Details` : t`Mix design details`}
            width={750}
            data-testid="concreteTypeModal"
            footer={[
                <Button data-testid="closeButton" key="send" type="primary" onClick={onNextButton}>
                    {displayNextLabel ? t`Next` : t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 4]}>
                <ConcreteTypeNumber concreteDetails={concreteDetails} />
                {marketId === MARKETS.DE ? (
                    <CompressiveStrength concreteDetails={concreteDetails} />
                ) : (
                    <CompressiveStrengthUS concreteDetails={concreteDetails} />
                )}

                {marketId === MARKETS.DE ? (
                    <>
                        <ExposureClass concreteDetails={concreteDetails} />
                        <MoistureClass concreteDetails={concreteDetails} />
                        <ConsistencyClass concreteDetails={concreteDetails} />
                    </>
                ) : null}

                <MaxAggregateSize concreteDetails={concreteDetails} />

                {marketId === MARKETS.DE ? <StrengthDevelopmentClass concreteDetails={concreteDetails} /> : null}

                <WaterCementRatio concreteDetails={concreteDetails} />

                {marketId === MARKETS.DE ? (
                    <MethodOfConcreteUseDE concreteDetails={concreteDetails} />
                ) : (
                    <MethodOfConcreteUseUS concreteDetails={concreteDetails} />
                )}

                <TestingAge concreteDetails={concreteDetails} />

                {marketId === MARKETS.US ? <Slump concreteDetails={concreteDetails} /> : null}
            </Row>
        </Modal>
    );
}

export default ConcreteTypeModal;
