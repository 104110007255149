import { Trans } from '@lingui/macro';
import { ConcreteLoadIngredientOverviewResponse } from '@nexploretechnology/concreting-core-client/concrete.load-client/concrete.load.dto';
import { ConcreteLoadIngredientOverview } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Divider, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DangerIcon } from '../shared/components/danger.icon';
import styles from '../shared/shared.module.css';
import { concreteLoadIngredientColumns } from './concrete-load-ingredients-overview-table.columns';
import ConcreteLoadIngredientsOverviewTableSumary from './concrete-load-ingredients-overview-table.sumary';

interface IConcreteLoadIngredientsOverviewTableProps {
    concreteLoadIngredients?: ConcreteLoadIngredientOverviewResponse;
}

const { Text } = Typography;
const MAX_DEVIATION = 3;

function keyMapFromTableData(tableData: ConcreteLoadIngredientOverview[]) {
    return tableData.map((value: ConcreteLoadIngredientOverview, key: number) => {
        return { ...value, key: `${value.ingredientName}-${key}` };
    });
}

function ingredientHasDeviation(ingredient: ConcreteLoadIngredientOverview): boolean {
    return Math.abs(ingredient.percentageDeviation) >= MAX_DEVIATION;
}

function getRowClass(ingredient: ConcreteLoadIngredientOverview): string {
    return ingredientHasDeviation(ingredient) ? styles.tableRowDanger : styles.tableRow;
}

function ConcreteLoadIngredientsOverviewTable(props: IConcreteLoadIngredientsOverviewTableProps) {
    const { marketId } = useProjectState();
    const ingredients: ConcreteLoadIngredientOverview[] =
        props.concreteLoadIngredients?.concreteLoadIngredientsOverview || [];
    const hasDeviations: boolean = ingredients.some(ingredientHasDeviation);
    const tableData: ConcreteLoadIngredientOverview[] = keyMapFromTableData(ingredients);
    const columns: ColumnsType<ConcreteLoadIngredientOverview> = useMemo(
        () => concreteLoadIngredientColumns(marketId),
        [marketId]
    );

    if (!ingredients.length || !marketId) {
        return null;
    }

    return (
        <div className="antd-table">
            <p>
                <span className={styles.tableTitle}>
                    <Trans>Concrete load quantity overview</Trans>:
                </span>
                {hasDeviations && (
                    <>
                        <DangerIcon /> {/* prettier-ignore */}
                        <Trans>Attention! Max. <Text type="danger" strong>3%</Text> deviation recommended.</Trans>
                    </>
                )}
            </p>
            <Table
                data-testid="concrete-load-ingredients-overview-table"
                scroll={{ x: 10 }}
                pagination={false}
                columns={columns}
                dataSource={tableData}
                rowClassName={getRowClass}
                summary={() => (
                    <ConcreteLoadIngredientsOverviewTableSumary
                        concreteLoadIngredients={props.concreteLoadIngredients}
                    />
                )}
            />
            <Divider />
        </div>
    );
}

export default ConcreteLoadIngredientsOverviewTable;
