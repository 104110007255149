export interface ExperimentalFeatures {
  orderDep: boolean
}

let experimentalFeatures: ExperimentalFeatures | undefined = undefined

export function getExperimentalFeatures(): ExperimentalFeatures {
  if (!experimentalFeatures || !experimentalFeaturesAreEnabled()) {
    experimentalFeatures = {
      orderDep: false
    }
  }
  return experimentalFeatures
}

export function loadExperimentalFeaturesConfig(): void {
  if (experimentalFeatures) {
    return
  }

  const urlParams = new URLSearchParams(window.location.search)

  experimentalFeatures = {
    orderDep: urlParams.has('orderDep')
  }
}

export function experimentalFeaturesAreEnabled(): boolean {
  // implement logic here for upper environments if requiered
  return true
}

export function anyExperimentalFeatureIsEnabled(): boolean {
  if (!experimentalFeatures) {
    throw new Error('Experimental features must be initialized first')
  }
  const featuresAreEnabled: boolean = experimentalFeaturesAreEnabled()
  const anyFeatureIsEnabled: boolean = Object.values(experimentalFeatures).some(feature => !!feature)
  return featuresAreEnabled && anyFeatureIsEnabled
}
