import { t, Trans } from '@lingui/macro';
import { TypeOfMeasurement } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import InputNumber from 'src/app-react/components/Form/InputNumber';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { mapCompressiveMeasurementValueUnit } from 'src/app-react/utils/formatters';

interface MeasurementValueFormItemProps {
    compressiveType: TypeOfMeasurement;
}

export function MeasurementValueFormItem(props: MeasurementValueFormItemProps) {
    const resultField = Form.useWatch('result', Form.useFormInstance());
    const { marketId } = useProjectState();
    const {compressiveType} = props;

    return (
        <FormItem
            name="measurement"
            label={<b><Trans>Measurement value</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                    message: <Trans>Measurement value is required if result is not filled</Trans>,
                    required: !Boolean(resultField)
                }, {
                    pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                    message: <Trans>Measurement value is invalid</Trans>,
                }]}>
             <InputNumber
                style={{width:'100%'}}
                placeholder={t`Enter measurement value`}
                data-testid="measurement"
                addonAfter={mapCompressiveMeasurementValueUnit(compressiveType, marketId)}
            />
        </FormItem>
    );
}
