import { t } from '@lingui/macro';
import { AxiosResponse } from 'axios';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';

export function generateAndDownloadXlsx(response: AxiosResponse<Blob>): void {
    if (!response.data?.size) {
        ErrorNotification({
            message: t`No data found`,
            description: ''
        });
        return;
    }

    const fileName = response.headers['content-disposition'].split('attachment; filename=')[1];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', decodeURI(fileName));
    document.body.appendChild(link);
    link.click();

    setTimeout(function () {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    }, 200);
}
