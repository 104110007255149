import { Trans } from "@lingui/macro";
import { Form, Radio } from "antd";
import requiredRule from "src/app-react/components/Form/Validations/requiredRule";

export function ExternalInspectionFormItem() {
    return <Form.Item
        name="externalInspection"
        label={<Trans>Use results for external inspection</Trans>}
        rules={[requiredRule]}>
        <Radio.Group
            data-testid="externalInspection"
            style={{ justifyContent: 'flex-start' }}>
            <Radio data-testid="externalInspectionYes" value="true">
                <Trans>Yes</Trans>
            </Radio>
            <Radio data-testid="externalInspectionNo" value="false">
                <Trans>No</Trans>
            </Radio>
        </Radio.Group>
    </Form.Item>
}