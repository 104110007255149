import { CataloguedStructuralElementExtendedBP, ConcreteTypeExtendedBP, OffsetPagination } from '@nexploretechnology/concreting-core-client';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ConcreteTypeModal from 'src/app-react/components/Modals/ConcreteTypeModal/ConcreteTypeModal';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import {
    defaultPagination,
    noPagination,
    pageSize,
    paginationAdapterReactAntdToBCQD
} from 'src/app-react/utils/pagination';
import { ChildOrderStructuralElementsTable } from '../OrderStructuralElementsTable/OrderStructuralElementsTable';
import { OrderConcreteTypesTableColumnsDE } from './OrderConcreteTypesTable.columns.de';
import { OrderConcreteTypesTableColumnsUS } from './OrderConcreteTypesTable.columns.us';

interface IProps {
    parentStructuralElement?: CataloguedStructuralElementExtendedBP;
    expandable?: boolean;
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
    orderId?: string;
    isChild?: boolean;
}
export default function OrderConcreteTypesTable({
    parentStructuralElement,
    expandable = false,
    count,
    setCount,
    orderId,
    isChild
}: IProps) {
    const { projectId, companyId, marketId } = useProjectState();
    const [dataSource, setDataSource] = useState<ConcreteTypeExtendedBP[]>([]);
    const [concreteTypeModalVisible, setConcreteTypeModalVisible] = useState(false);
    const [orderCreation, setIsOrderCreation] = useState(false);
    const [concreteTypeModalInitialValues, setConcreteTypeModalInitialValues] = useState<ConcreteTypeExtendedBP>();
    const api = useApi();

    const [pagination, setPagination] = useState<OffsetPagination<ConcreteTypeExtendedBP>>(
        isChild ? noPagination : defaultPagination
    );
    const navigate: NavigateFunction = useNavigate();

    function showCatalogueConcreteTypeModal(concreteType: ConcreteTypeExtendedBP, isOrderCreation: boolean): void {
        setConcreteTypeModalInitialValues(concreteType);
        setConcreteTypeModalVisible(true);
        setIsOrderCreation(isOrderCreation);
    }

    async function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ConcreteTypeExtendedBP> | SorterResult<ConcreteTypeExtendedBP>[],
        extra: TableCurrentDataSource<ConcreteTypeExtendedBP>
    ): Promise<void> {
        setPagination(paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra));
    }

    function onModalClose() {
        if (orderCreation) {
            const structuralElementId = parentStructuralElement ? parentStructuralElement.id : '';
            if (!orderId) {
                navigate(
                    `/projects/${projectId}/order-type/structuralel/${structuralElementId}/concretetype/${concreteTypeModalInitialValues.id}/new-order`
                );
            } else {
                navigate(
                    `/projects/${projectId}/order-type/structuralel/${structuralElementId}/concretetype/${concreteTypeModalInitialValues.id}/order/${orderId}/edit`
                );
            }
        }
    }

    const loadTableData = () => {
        if (parentStructuralElement) {
            api.catalogueManagementClient
                .getAssignedExtendedConcreteTypesToCaSeAsDataList(companyId, projectId, parentStructuralElement.id, {
                    pagination,
                    canBeOrdered: true
                })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        } else {
            api.catalogueManagementClient
                .getExtendedConcreteTypesForProjectAsDataList(companyId, projectId, { pagination, canBeOrdered: true })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        }
    };

    useEffect(() => {
        loadTableData();
        const interval = setInterval(loadTableData, 20001);
        return () => clearInterval(interval);
    }, [parentStructuralElement, pagination, companyId, projectId]);

    return (
        <>
            <Table
                data-testid="ConcreteTypesTable"
                rowClassName="ConcreteTypesTable-testid"
                pagination={isChild ? false : { position: ['bottomRight'], defaultPageSize: pageSize, total: count }}
                onChange={onChange}
                showSorterTooltip={false}
                scroll={{ x: 1000 }}
                columns={{
                    DE: OrderConcreteTypesTableColumnsDE,
                    US: OrderConcreteTypesTableColumnsUS
                }[marketId as 'DE' | 'US']({
                    expandable,
                    showCatalogueConcreteTypeModal
                })}
                rowKey="id"
                dataSource={dataSource}
                expandable={
                    isChild
                        ? {}
                        : {
                            expandedRowRender: (record) => (
                                <ChildOrderStructuralElementsTable
                                    parentConcreteType={record}
                                    expandable={false}
                                    orderId={orderId}
                                />
                            ),
                            rowExpandable: (record) =>
                                Boolean(expandable && record.assignedCataloguedStructuralElements?.length)
                        }
                }
            />
            {concreteTypeModalInitialValues && (
                <ConcreteTypeModal
                    concreteDetails={concreteTypeModalInitialValues}
                    open={concreteTypeModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                    displayNextLabel={orderCreation}
                    onClose={onModalClose}
                />
            )}
        </>
    );
}

export function ChildOrderConcreteTypesTable(props: Omit<IProps, 'count' | 'setCount'>) {
    const [count, setCount] = useState(0);
    return <OrderConcreteTypesTable {...{ isChild: true, ...props, count, setCount }} />;
}
