import { IMarketUnits } from '../..';

const UNITS: IMarketUnits = {
    volume: 'm3',
    density: 'kg/m3',
    temperature: '°C',
    slump: 'mm'
};

export default UNITS;
