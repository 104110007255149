import { t, Trans } from '@lingui/macro';
import FormItem from 'antd/lib/form/FormItem';
import { InputNumber } from 'src/app-react/components/Form';

export function ActualDurationFormItem() {
    return (
        <FormItem
            name="actualNumberOfDays"
            label={<b><Trans>Actual duration</Trans></b>}
            style={{gap: '.2rem'}}
            rules={[{
                required: false,
            }]}>
             <InputNumber
                disabled={true}
                style={{width:'100%'}}
                data-testid="actualNumberOfDays"
                addonAfter={t`day(s)`}
            />
        </FormItem>
    );
}
