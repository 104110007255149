import { CompressiveAndAggregationData } from '@nexploretechnology/concreting-core-client';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGetCompressiveAndAggregationDataByConcreteNumber() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [compressiveAndAggregationData, setCompressiveAndAggregationData] =
        useState<CompressiveAndAggregationData>(undefined);

    const loadcompressiveAndAggregationData = useCallback(
        async (concreteTypeNumber: string) => {
            return (
                api.concreteLoadClient
                    .getCompressiveAndAggregationDataByConcreteNumber(companyId, projectId, concreteTypeNumber)
                    .then((response) => setCompressiveAndAggregationData(response.getEntity()))
                    // eslint-disable-next-line no-shadow
                    .catch((error: any) => {
                        setError({ message: error.code, description: error.message });
                    })
            );
        },
        [api, companyId, projectId]
    );

    return {
        loadcompressiveAndAggregationData,
        compressiveAndAggregationData,
        error
    };
}

export default useGetCompressiveAndAggregationDataByConcreteNumber;
