import { Col, Row } from 'antd';
import { SupplierUIFormItem } from '../../../../Form/components/FormItems/SupplierUIFormItem';
import { PasswordFormItem } from '../../../FormItems/PasswordFormItem';
import { SupplierUrlFormItem } from '../../../FormItems/SupplierUrlFormItem';
import { UsernameFormItem } from '../../../FormItems/UsernameFormItem';
import { CustomerIdFormItem } from '../FormItems/CustomerIdFormItem';
import { DispGroupFormItem } from '../FormItems/DispGroupFormItem';
import { SupplierProjectIdFormItem } from '../FormItems/SupplierProjectIdFormItem';

export function SupplierConfigFormLayoutSimmaDE() {
    return (
        <>
            <Row gutter={8}>
                <Col span={11}>
                    <SupplierUrlFormItem />
                </Col>
                <Col span={13}>
                    <SupplierProjectIdFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <DispGroupFormItem />
                </Col>
                <Col span={13}>
                    <CustomerIdFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <UsernameFormItem />
                </Col>
                <Col span={13}>
                    <PasswordFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <SupplierUIFormItem />
            </Row>
        </>
    );
}
