import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDiscardChanges } from '../components/DiscardChangesModal/hooks/useDiscardChanges';

function useNavigateDiscardChanges() {
    const navigate = useNavigate();
    const { displayDiscardChangeModal } = useDiscardChanges();

    const navigateConfirm = useCallback(
        (path: string) => {
            displayDiscardChangeModal({
                onConfirm: () => navigate(path)
            });
        },
        [navigate, displayDiscardChangeModal]
    );

    return navigateConfirm;
}

export default useNavigateDiscardChanges;
