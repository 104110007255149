import { IMarketFormats } from '../..';

const FORMATS: IMarketFormats = {
    dateTimeForm: 'DD/MM/YYYY HH:mm',
    dateForm: 'DD/MM/YYYY',
    timeForm: 'HH:mm',
    dateTime: 'DD/MM/YYYY HH:mm',
    date: 'DD/MM/YYYY',
    time: 'HH:mm'
};

export default FORMATS;
