import { Col, Row } from 'antd';
import { DeliveryTimeInputItem } from './InputItems/DeliveryTimeInputItem';
import { useState } from 'react';
import { CustomDeliveryTimeInputItem } from './InputItems/CustomDeliveryTimeInputItem';

export const DEBOUNCE_TIME = 500;

export function ConcretingDocumentationFilters() {
    const [displayCustomRange, setDisplayCustomRange] = useState<boolean>(false);

    return (
        <Row gutter={24}>
            <Col
                span={5}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.5rem',
                    minWidth: '496px',
                    maxWidth: '600px'
                }}>
                <DeliveryTimeInputItem setDisplayCustomRange={setDisplayCustomRange} />
            </Col>
            {displayCustomRange && (
                <Col style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
                    <CustomDeliveryTimeInputItem />
                </Col>
            )}
        </Row>
    );
}
