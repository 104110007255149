import debounce from 'lodash.debounce';
import { useCallback, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DEBOUNCE_TIME } from '../components/ConcretingDocumentationTable/ConcretingDocumentationFilters';
import { defaultPagination } from '../utils/util';
import { PeriodAndTextFilter } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';

function useSupplierOrderIds() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string }>();
    const [supplierOrderIds, setSupplierOrderIds] = useState<string[] | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const loadSupplierOrderIds = useCallback(
        debounce((filters?: PeriodAndTextFilter) => {
            setLoading(true);
            api.deliveryNoteLiteClient
                .getSupplierOrderIdsByProject(
                    companyId,
                    projectId,
                    { query: filters?.query, periodOfConcreting: filters?.periodOfConcreting },
                    defaultPagination
                )
                .then((response) => {
                    setLoading(false);
                    if (response.isSuccess()) {
                        setSupplierOrderIds(response.getEntity());
                    } else {
                        setError(response.getError());
                    }
                })
                .catch((info) => {
                    setLoading(false);
                    setError(info.errorFields[0].errors[0]);
                });
        }, DEBOUNCE_TIME),
        [api, projectId, companyId]
    );

    return {
        supplierOrderIds,
        error,
        loading,
        loadSupplierOrderIds
    };
}

export default useSupplierOrderIds;
