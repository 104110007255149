import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function DispGroupFormItem() {
    const form = Form.useFormInstance();
    const selectedName = Form.useWatch('supplierName', form);

    return (
        <FormItem
            name="dispgroup"
            label={<Trans>Supplier software dispo group</Trans>}
            rules={[{
                required: selectedName == 'MBK' ? true : false,
                message: (
                    <span>
                        <Trans>Please input supplier software dispo group</Trans>
                    </span>
                )
            }]}>
            <Input 
                type="text" 
                data-testid="disp-group" 
                placeholder={t`Enter dispo group`}
            />
        </FormItem>
    );
}
