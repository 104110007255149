import { SpecimenTestFormValuesUS } from "../../components/TestSpecimenTestForm/markets/US/models";

export function createInitialFormValuesTestSpecimenTestForm() {
    return {
        specimenIdentification: '',
        typeOfTesting: 'compressiveStrength',
        purposeOfTesting: 'fieldTest',
        testingAge: 30,
        mould: 'cylinder',
        compactor: 'internalVibrator'
    } as SpecimenTestFormValuesUS;
}