import { PlusCircleOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { BasicConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import {
    DeliveryNoteDetailsBP,
    UpdateConcreteLoad
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import { useContext, useEffect, useState } from 'react';
import WeatherFormItem from 'src/app-react/business-page/shared/FormItems/WeatherFormItem';
import { InputNumber } from 'src/app-react/components/Form';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getAuthService } from 'src/app-react/services/authService';
import { numberFormat } from 'src/app-react/utils/formatters';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import styles from './RejectConcreteLoadModal.module.css';

interface IFormValues {
    rejectReason: string;
    comment: string;
    concretedStructuralElement?: string;
    concretingStartDate?: Moment;
    concretingStartTime?: Moment;
    concretingEndDate?: Moment;
    concretingEndTime?: Moment;
    quantity?: string;
    weather?: string;
}

const { Option } = Select;

const marketIdDE = 'DE';

function RejectConcreteLoadModal(props: {
    deliveryNoteDetailsBP: DeliveryNoteDetailsBP;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}) {
    const { deliveryNoteDetailsBP, open, setOpen, onComplete } = props;
    const concreteLoadId = deliveryNoteDetailsBP?.deliveryNoteData?.dnId;
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { companyId, projectId, marketId } = useProjectState();
    const [commentsRequired, setCommentsRequired] = useState<boolean>(false);
    const { units } = useProjectConstantsState();
    const api = useApi();
    const [form] = Form.useForm();

    const { userId } = getAuthService().user;

    const [concretedStructuralElements, setConcretedStructuralElements] = useState<BasicConcretedStructuralElementBP[]>(
        []
    );

    const [alreadyUsedConcreteVisible, setAlreadyUsedConcreteVisible] = useState(false);

    async function fetchData(): Promise<void> {
        await api.concretingDocumentationClient
            .loadConcretedStructuralElementsByOrderId(projectId, orderId, companyId)
            .then((response: SimpleClientResponse<BasicConcretedStructuralElementBP[]>) => {
                if (response.isSuccess()) {
                    setConcretedStructuralElements(response.getEntity());
                    form.setFieldsValue({ concretedStructuralElement: response.getEntity()[0].idCoSE });
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    let enabledButton = true;
    const form_onOk = async (): Promise<void> => {
        function getFormValues(): IFormValues {
            try {
                return {
                    rejectReason: form.getFieldValue('rejectReason') as string,
                    comment: form.getFieldValue('comment') as string,
                    concretedStructuralElement: form.getFieldValue('concretedStructuralElement') as string,
                    concretingStartDate: form.getFieldValue('concretingStartDate'),
                    concretingStartTime: form.getFieldValue('concretingStartTime'),
                    concretingEndDate: form.getFieldValue('concretingEndDate'),
                    concretingEndTime: form.getFieldValue('concretingEndTime'),
                    quantity: numberFormat(form.getFieldValue('quantity')) || '',
                    weather: form.getFieldValue('weather') as string
                };
            } catch (e) {
                return undefined;
            }
        }

        try {
            if (enabledButton === true) {
                if (await form.validateFields()) {
                    const formValues: IFormValues = getFormValues();

                    const concretingStart: Date = mergeDateTime(
                        moment(formValues.concretingStartDate),
                        moment(formValues.concretingStartTime)
                    ).toDate();
                    const concretingEnd: Date = mergeDateTime(
                        moment(formValues.concretingEndDate),
                        moment(formValues.concretingEndTime)
                    ).toDate();

                    const updateConcreteLoad: UpdateConcreteLoad = {
                        inputconcretedLoad: alreadyUsedConcreteVisible
                            ? [
                                  {
                                      concretingStartDate: concretingStart,
                                      concretingEndDate: concretingEnd,
                                      concreteLoadId,
                                      concretedStructuralElementId: formValues.concretedStructuralElement,
                                      volume: Number(numberFormat(form.getFieldValue('quantity')) || ''),
                                      volumeUnit: units.volume,
                                      weather: formValues.weather,
                                      createdOn: new Date(),
                                      lastModifiedOn: new Date()
                                  }
                              ]
                            : [],
                        updateStatusConcreteLoad: {
                            status: 'Rejected',
                            dateTimeAction: new Date(),
                            inputCommentConcreteLoad: formValues.comment
                                ? {
                                      concreteLoadId,
                                      comment: formValues.comment,
                                      actionConcreteLoad: 'rejectConcreteLoad',
                                      createdBy: userId
                                  }
                                : undefined,
                            rejectionReasonCode: formValues.rejectReason,
                            rejectedBy: userId
                        }
                    };
                    api.deliveryNoteClient
                        .updateConcreteLoad(companyId, projectId, orderId, concreteLoadId, updateConcreteLoad)
                        .then((response: SimpleClientResponse<boolean>) => {
                            if (response.isSuccess()) {
                                enabledButton = false;
                                form.resetFields();
                                setAlreadyUsedConcreteVisible(false);
                                setOpen(false);
                                onComplete?.();
                            } else {
                                ErrorNotification({
                                    message: response.getError(),
                                    description: ''
                                });
                            }
                        });
                }
            }
        } catch (errorInfo) {
            // Must be empty. This catch is necessary for the tests, without the tests are failing. In normal usage ant design displays the required field information.
        }
    };

    const onCancel = (): void => {
        form.resetFields();
        setAlreadyUsedConcreteVisible(false);
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="reject-concrete-load-modal"
            title={t`Reject concrete load`}
            open={open}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button key="cancel-button" data-testid="cancel-button" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    className={styles.modalButton}
                    disabled={!enabledButton}
                    onClick={form_onOk}
                    data-testid="reject-concrete-load-submit"
                    type="primary"
                    key={`submit-button-${concreteLoadId}`}>
                    <Trans>Confirm</Trans>
                </Button>
            ]}>
            <Form
                form={form}
                layout="vertical"
                data-testid="reject-concrete-load-form"
                key={`modal-form-${concreteLoadId}`}
                initialValues={
                    {
                        rejectReason: undefined,
                        comment: '',
                        concretedStructuralElement: concretedStructuralElements[0]?.idCoSE,
                        concretingStartDate: moment(deliveryNoteDetailsBP?.deliveryNoteData?.unloadingStart),
                        concretingStartTime: moment(deliveryNoteDetailsBP?.deliveryNoteData?.unloadingStart),
                        concretingEndDate: moment(deliveryNoteDetailsBP?.deliveryNoteData?.unloadingEnd),
                        concretingEndTime: moment(deliveryNoteDetailsBP?.deliveryNoteData?.unloadingEnd)
                    } as IFormValues
                }>
                <Row gutter={8}>
                    <Col span="11">
                        <Row>
                            <label className={styles.modalLabel}>
                                {marketId === marketIdDE ? (
                                    <Trans>Delivery note number</Trans>
                                ) : (
                                    <Trans>Ticket number</Trans>
                                )}
                            </label>
                        </Row>
                        <Row>
                            <p>{deliveryNoteDetailsBP.deliveryNoteData.deliveryNoteNumber}</p>
                        </Row>
                    </Col>
                    <Col span="13">
                        <Row>
                            <label className={styles.modalLabel}>
                                {marketId === marketIdDE ? <Trans>License Plate</Trans> : <Trans>Truck number</Trans>}
                            </label>
                        </Row>
                        <Row>
                            <p>{deliveryNoteDetailsBP.deliveryNoteData.truckLicensePlate}</p>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={[48, 16]}>
                    <Col span={24}>
                        <Form.Item
                            name="rejectReason"
                            label={<Trans>Please provide a reason for rejecting a concrete load.</Trans>}
                            rules={[
                                {
                                    required: true,
                                    message: t`This field is required`
                                }
                            ]}>
                            <Select
                                data-testid="select-reject-reason-input"
                                placeholder={t`Select reject reason`}
                                onChange={(value: string) => {
                                    switch (value) {
                                        case 'weather':
                                        case 'other':
                                            setCommentsRequired(true);
                                            break;
                                        default:
                                            setCommentsRequired(false);
                                            break;
                                    }
                                }}>
                                <Option value="truckTooLate">
                                    <Trans>Truck arrived too late</Trans>
                                </Option>
                                <Option value="truckTooEarly">
                                    <Trans>Truck arrived too early</Trans>
                                </Option>
                                <Option value="wrongDelivery">
                                    <Trans>Delivered concrete doesn’t match ordered concrete</Trans>
                                </Option>
                                <Option value="qualityInadequate">
                                    <Trans>Test results: Quality of delivered concrete inadequate</Trans>
                                </Option>
                                <Option value="recalledBySupplier">
                                    <Trans>Recalled by supplier</Trans>
                                </Option>
                                <Option value="truckMalfunction">
                                    <Trans>Truck malfunction</Trans>
                                </Option>
                                <Option value="weather">
                                    <Trans>Weather conditions</Trans>
                                </Option>
                                <Option value="other">
                                    <Trans>Other</Trans>
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <label className={styles.undoneNoteLabel}>
                        <Trans>Note that the rejection cannot be undone.</Trans>
                    </label>
                </Row>
                <Row>
                    <br />
                </Row>

                <Row>
                    <Col span="24">
                        <Form.Item
                            name="comment"
                            label={<Trans>Comment</Trans>}
                            rules={[
                                {
                                    required: commentsRequired,
                                    message: t`This field is required`
                                },
                                () => ({
                                    validator(_, value) {
                                        if (form.getFieldValue('rejectReason') === 'weather' && !value) {
                                            return Promise.reject(
                                                t`Comments are required for rejection by weather conditions`
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}>
                            <Input.TextArea
                                data-testid="report-comment-input"
                                placeholder={t`Enter here any remark you might have`}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                {alreadyUsedConcreteVisible ? (
                    <>
                        <hr />
                        <br />
                        <Row>
                            <label>
                                <Trans>
                                    Please enter the concreting information for the concreted structural element.
                                </Trans>
                            </label>
                        </Row>
                        <br />

                        <Form.Item
                            name="concretedStructuralElement"
                            label={<Trans>Concreted structural element</Trans>}
                            rules={[
                                {
                                    required: alreadyUsedConcreteVisible,
                                    message: (
                                        <span data-testid="concretedStructuralElement">
                                            <Trans>This field is required</Trans>
                                        </span>
                                    )
                                }
                            ]}>
                            <Select
                                optionLabelProp="label"
                                optionFilterProp="label"
                                showArrow
                                style={{ width: '100%' }}
                                data-testid="select-input">
                                {concretedStructuralElements.map((element) => (
                                    <Select.Option
                                        data-testid="select-option"
                                        key={`structural-element-${element.idCoSE}`}
                                        label={element.nameCoSE}
                                        value={element.idCoSE}>
                                        <span>
                                            <b>{element.nameCoSE}</b>
                                        </span>
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Row gutter={[16, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    name="concretingStartDate"
                                    label={<Trans>Concreting start</Trans>}
                                    rules={[
                                        {
                                            required: alreadyUsedConcreteVisible,
                                            message: (
                                                <span data-testid="concretingStartDate">
                                                    <Trans>This field is required</Trans>
                                                </span>
                                            )
                                        }
                                    ]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        data-testid="concretingStartDate"
                                        placeholder={t`Enter Date`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    required={false} // we want to have this field required, but without the asteriks shown. So we set it first set to not required in form item, but later the validation kicks in, because of the rules with required.
                                    name="concretingStartTime"
                                    label={<></>}
                                    rules={[
                                        {
                                            required: alreadyUsedConcreteVisible,
                                            message: t`This field is required`
                                        }
                                    ]}>
                                    <TimePicker data-testid="concretingStartTime" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    name="concretingEndDate"
                                    label={<Trans>Concreting end</Trans>}
                                    rules={[
                                        {
                                            required: alreadyUsedConcreteVisible,
                                            message: (
                                                <span data-testid="concretingEndDate">
                                                    <Trans>This field is required</Trans>
                                                </span>
                                            )
                                        }
                                    ]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        data-testid="concretingEndDate"
                                        placeholder={t`Enter Date`}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    required={false} // we want to have this field required, but without the asteriks shown. So we set it first set to not required in form item, but later the validation kicks in, because of the rules with required.
                                    name="concretingEndTime"
                                    label={<></>}
                                    rules={[
                                        {
                                            required: alreadyUsedConcreteVisible,
                                            message: t`This field is required`
                                        }
                                    ]}>
                                    <TimePicker data-testid="concretingEndTime" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            name="quantity"
                            label={<Trans>Quantity</Trans>}
                            rules={[
                                {
                                    pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                                    required: true,
                                    message: t`This field is required`
                                },
                                () => ({
                                    validator(_, value) {
                                        if (Number(value) >= (deliveryNoteDetailsBP?.deliveryNoteData?.volume || 0)) {
                                            return Promise.reject(
                                                t`The quantity must be smaller than the volume of the concrete produced by the supplier`
                                            );
                                        }
                                        return Promise.resolve();
                                    }
                                })
                            ]}>
                            <InputNumber
                                data-testid="quantity"
                                addonAfter={units.volume}
                                placeholder={t`Enter the quantity`}
                            />
                        </Form.Item>

                        <Row gutter={[48, 16]}>
                            <Col span={24}>
                                <WeatherFormItem
                                    label={<Trans>Select weather during the concreting</Trans>}
                                    rules={[
                                        {
                                            required: true,
                                            message: t`This field is required`
                                        }
                                    ]}
                                    radioGroupProps={{
                                        'data-testid': 'weather'
                                    }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Button
                                data-testid="none-used-concrete-button"
                                type="link"
                                size="small"
                                className={styles.alreadyUsedConcreteButton}
                                onClick={() => setAlreadyUsedConcreteVisible(false)}>
                                <PlusCircleOutlined /> <Trans>Click here if none of the concrete has been used</Trans>
                            </Button>
                        </Row>
                    </>
                ) : (
                    <Row>
                        <Button
                            data-testid="already-used-concrete-button"
                            type="link"
                            size="small"
                            className={styles.alreadyUsedConcreteButton}
                            onClick={() => setAlreadyUsedConcreteVisible(true)}>
                            <PlusCircleOutlined />{' '}
                            <Trans>Click here if some of the concrete has already been used</Trans>
                        </Button>
                    </Row>
                )}
            </Form>
        </Modal>
    );
}

export default RejectConcreteLoadModal;
