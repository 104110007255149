/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Input, Select } from 'antd';
import { t } from '@lingui/macro';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import SelectFormItem, { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import requieredWithDependenciesRule from 'src/app-react/components/Form/Validations/requiredWithDependenciesRule';
import styles from 'src/app-react/components/Form/styles.module.css';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

interface CompressiveStrengthClassFormItemProps {
    required?: boolean;
    formItem1?: SelectFormItemProps;
    formItem2?: InputFormItemProps;
}

function ConsistencyClassFormItem({ required = true, formItem1, formItem2 }: CompressiveStrengthClassFormItemProps) {
    const { consistencyType = [] } = useProjectConstantsState();
    const formItem1Name = formItem1?.name || ['consistencyClass', 'consistencyType'];
    const formItem2Name = formItem2?.name || ['consistencyClass', 'consistencyClass'];

    return (
        <Input.Group compact>
            <SelectFormItem
                name={formItem1Name}
                label={t`Consistency class`}
                style={{ display: 'inline-block', width: '45%' }}
                required={required}
                dependencies={[formItem2Name]}
                rules={[
                    requieredWithDependenciesRule({
                        disable: required,
                        dependencies: [formItem2Name]
                    })
                ]}
                {...formItem1}>
                <Select allowClear={required === false} options={consistencyType} data-testid="consistencyType" />
            </SelectFormItem>
            <InputFormItem
                name={formItem2Name}
                className={styles.hideRequiredMark}
                label=" "
                style={{ display: 'inline-block', width: '35%', margin: '0 8px' }}
                dependencies={[formItem1Name]}
                rules={[
                    { ...requiredRule, required: required ?? formItem2?.required },
                    requieredWithDependenciesRule({
                        disable: required,
                        dependencies: [formItem1Name]
                    }),
                    (form) => ({
                        validator(_, value) {
                            switch (form.getFieldValue(formItem1Name)) {
                                case 'F':
                                    if (!(Number(value) >= 1 && Number(value) <= 6)) {
                                        return Promise.reject(new Error('[1,6]'));
                                    }
                                    break;
                                case 'S':
                                    if (!(Number(value) >= 1 && Number(value) <= 5)) {
                                        return Promise.reject(new Error('[1,5]'));
                                    }
                                    break;
                                case 'C':
                                case 'V':
                                    if (!(Number(value) >= 0 && Number(value) <= 4)) {
                                        return Promise.reject(new Error('[0,4]'));
                                    }
                                    break;
                                default:
                                    return Promise.resolve();
                            }

                            return Promise.resolve();
                        }
                    })
                ]}
                {...formItem2}>
                <Input type="number" min={0} max={6} style={{ width: '50%' }} data-testid="consistencyClass" />
            </InputFormItem>
        </Input.Group>
    );
}

export default ConsistencyClassFormItem;
