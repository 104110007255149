import { t } from '@lingui/macro';
import { FormInstance } from 'antd';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';

function validateFields(form: FormInstance, done: () => void) {
    form.validateFields()
        .then(done)
        .catch(() => {
            ErrorNotification({
                message: t`Form not filled correctly`,
                description: t`Please, make sure that all the fields are filled correctly`
            });
        });
}

export default validateFields;
