/* eslint-disable react/jsx-props-no-spreading */

import { useLingui } from '@lingui/react';
import { InputNumber as OriginalInputNumber, InputNumberProps } from 'antd';

const DECIMAL_SEPARATORS: Record<string, string> = {
    'en-UK': '.',
    'en-US': '.',
    'es-ES': ',',
    'de-DE': ','
};

export default function InputNumber(props: InputNumberProps) {
    const { i18n } = useLingui();
    const decimalSeparator = DECIMAL_SEPARATORS[i18n.locale];

    return <OriginalInputNumber style={{ width: '100%' }} {...props} decimalSeparator={decimalSeparator} />;
}
