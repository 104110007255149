export const ORDER_LOAD_LITE_TYPES = {
    MANUAL: 'Manual',
    QR: 'Qr'
} as const;

export type OrderLoadLiteTypesValues = (typeof ORDER_LOAD_LITE_TYPES)[keyof typeof ORDER_LOAD_LITE_TYPES];

export const CONCRETE_LOAD_LITE_MODAL_ACTION = {
    CREATE: 'Create',
    EDIT: 'Edit'
};

export type ConcreteLoadLiteModalAction =
    (typeof CONCRETE_LOAD_LITE_MODAL_ACTION)[keyof typeof CONCRETE_LOAD_LITE_MODAL_ACTION];
