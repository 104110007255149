/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { AutoComplete, Form, Input } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import useSearchConcreteType from '../../../../../../hooks/useSearchConcreteType';

const DEBOUNCE_WAIT = 200;

function ConcreteTypeNumberFormItem(props: FormItemInputProps) {
    const form = Form.useFormInstance();
    const concreteTypesRef = useRef<ConcreteTypeExtendedBP[]>();
    const searchConcreteType = useSearchConcreteType();
    const [options, setOptions] = useState([]);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value?: string) => {
            setOptions([]);

            searchConcreteType(value).then((items) => {
                concreteTypesRef.current = items;

                const newOptions = items.map((item) => ({
                    label: item.concreteTypeNumber,
                    value: item.concreteTypeNumber
                }));

                setOptions(newOptions);
            });
        };

        return debounce(loadOptions, DEBOUNCE_WAIT);
    }, [searchConcreteType]);

    const onChange = useCallback(
        (concreteTypeNumber: string) => {
            const concreteType = concreteTypesRef.current?.find(
                (ct) => ct.concreteTypeNumber?.toLowerCase() === concreteTypeNumber?.toLowerCase()
            );

            form?.setFields([
                {
                    name: 'concreteType',
                    value: concreteType
                }
            ]);
        },
        [form, concreteTypesRef]
    );

    useEffect(() => {
        debounceFetcher();
    }, [debounceFetcher, form]);

    return (
        <>
            <Form.Item hidden name="concreteType">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="number" label={t`Concrete type number`} rules={[requiredRule]} {...props}>
                <AutoComplete onChange={onChange} onSearch={debounceFetcher} options={options} />
            </Form.Item>
        </>
    );
}

export default ConcreteTypeNumberFormItem;
