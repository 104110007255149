import { useMemo } from 'react';
import { CompanyRoles, ProjectRoles } from '../../services/authService';
import useProjectState from '../useProjectState';
import buildRolePermissions from './buildRolePermissions';
import buildUserPermissions from './buildUserPermissions';

function mergeRolePermissions(projectRoles: ProjectRoles, companyRoles: CompanyRoles) {
    return [...Object.values(projectRoles), ...Object.values(companyRoles)].reduce((memo, permissionsObject) => {
        Object.entries(permissionsObject).forEach(([key, permissions]) => {
            // eslint-disable-next-line no-param-reassign
            memo[key] = [...new Set([...(memo[key] || []), ...permissions])];
        });

        return memo;
    }, {});
}

function useUserPermissions() {
    const { projectRoles = {}, company: { companyRoles = {} } = {} } = useProjectState();

    return useMemo(() => {
        const roles = [...new Set([...Object.keys(projectRoles), ...Object.keys(companyRoles)])];
        const permissions = mergeRolePermissions(projectRoles, companyRoles);

        return {
            roles,
            hasPermissions(permission: string, action: string) {
                return permissions?.[permission]?.includes(action);
            },
            ...buildRolePermissions(roles),
            ...buildUserPermissions(permissions)
        };
    }, [projectRoles, companyRoles]);
}

export type UserPermissions = ReturnType<typeof useUserPermissions>;

export default useUserPermissions;
