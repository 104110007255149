import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import { InputNumber } from 'src/app-react/components/Form';

export function OutPerHourFormItem() {
    return (
        <Form.Item
            name="outPerHour"
            label={<Trans>Out per hour</Trans>}
            rules={[{
                pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                required: true,
                message: (
                    <span data-testid="errorInputOutPerOut">
                        <Trans>This field is required</Trans>
                    </span>
                )
            }]}>
            <InputNumber
                placeholder={t`Enter out per our`}
                data-testid="outPerOur"
                addonAfter={t`m3/h`}
            />
        </Form.Item>
    );
}
