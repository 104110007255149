import { Trans } from '@lingui/macro';
import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Divider, Table } from 'antd';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import styles from '../../../shared/shared.module.css';
import concreteDetailsTableColumns from './columns';

export interface Props {
    deliveryNoteAllDetails: DeliveryNoteAllDetails;
    concretedStructuralElement: ConcretedStructuralElementBP;
}

function ConcreteDetailsTableDE(props: Props) {
    const { deliveryNoteAllDetails, concretedStructuralElement } = props;

    const { units } = useProjectConstantsState();

    return (
        <>
            <p className={styles.tableTitle}>
                <Trans>Concreting Details</Trans>
            </p>
            <Table
                bordered={false}
                data-testid="customer-property-table"
                scroll={{ x: 1 }}
                columns={concreteDetailsTableColumns({ units, concretedStructuralElement })}
                dataSource={[deliveryNoteAllDetails]}
                pagination={false}
            />
            <Divider />
        </>
    );
}

export default ConcreteDetailsTableDE;
