import { ConcreteTypeModalFormValuesDE, ConcreteTypeModalFormValuesUS } from "./types";
import { FormInstance } from "antd";
import { parseDecimalByLocale } from "src/app-react/utils/formatters";
import { i18n } from '@lingui/core';
import Fraction from "fraction.js";


function number(value: string): number | undefined {
    const n: number = parseFloat(value);
    if (isNaN(n)) {
        return undefined;
    }
    return n;
}
function boolean(value: string | boolean): boolean {
    if (value === true || value === 'true') {
        return true;
    }
    return false;
}


export const decimal = (value: string) => value ? Number(value.replace(',','.')) : undefined;

export const extractRequiredXX = (requiredXX: number | undefined) => requiredXX ? Number(requiredXX) : undefined;

export function extractMethodOfConcreteChlorideContent(methodOfConcreteChlorideContent: string) {
    const split: string[] = methodOfConcreteChlorideContent.split('/');
    const methodOfConcrete: string = split[0].trim();
    const chlorideContent: string = split[1].trim();

    return {
        methodOfConcrete,
        chlorideContent
    };
}

export function formatDecimalAsFraction(decimal: number): string {
    const excludeWhole = true; // e.g. "1 1/5" will be visible instead of "6/5"
    return new Fraction(Number(decimal)).toFraction(excludeWhole);
}

export function getFormValuesDE(form: FormInstance): ConcreteTypeModalFormValuesDE {
    const formValues = form.getFieldsValue();
    return {
        typeNumber: formValues.typeNumber,
        description: formValues.description || undefined,
        price: formValues.price,

        canBeOrdered: formValues.canBeOrdered,

        testingAge: number(formValues.testingAge),
        waterCementRatio: formValues.waterCementRatio,

        cements:
            formValues.cements && formValues.cements.length > 0
                ? formValues.cements.map((cement: any) => {
                    return {
                        type: cement.type,
                        origin: cement.origin || undefined,
                        quantity: number(cement.quantity)
                    };
                })
                : [],

        supplementaryCementiousMaterials:
            formValues.supplementaryCementiousMaterials && formValues.supplementaryCementiousMaterials.length > 0
                ? formValues.supplementaryCementiousMaterials.map((supplementaryCementiousMaterial: any) => {
                    return {
                        type: supplementaryCementiousMaterial.type,
                        origin: supplementaryCementiousMaterial.origin || undefined,
                        quantity: number(supplementaryCementiousMaterial.quantity)
                    };
                })
                : [],

        admixtures:
            formValues.admixtures && formValues.admixtures.length > 0
                ? formValues.admixtures.map((admixture: any) => {
                    return {
                        type: admixture.type,
                        origin: admixture.origin || undefined
                    };
                })
                : [],

        additionalAttributes:
            formValues.additionalAttributes?.length > 0 ? formValues.additionalAttributes : undefined,
        assignToStructuralElements:
            formValues?.assignToStructuralElements?.length > 0 ? formValues.assignToStructuralElements : undefined,

        externalInspection: boolean(formValues.externalInspection),

        compressiveStrengthClass: {
            requiredTypeOfConcreteType: formValues.compressiveStrengthClass.requiredTypeOfConcreteType,
            cylindricCompressiveStrength: number(formValues.compressiveStrengthClass.cylindricCompressiveStrength),
            cubicCompressiveStrength: number(formValues.compressiveStrengthClass.cubicCompressiveStrength)
        },

        consistencyClass: {
            consistencyType: formValues.consistencyClass.consistencyType,
            consistencyClass: number(formValues.consistencyClass.consistencyClass)
        },
        moistureClass: formValues.moistureClass,
        exposureClasses: formValues.exposureClasses,
        strengthDevelopmentClass: formValues.strengthDevelopmentClass,
        densityClass: formValues.densityClass,
        methodOfConcreteChlorideContent: formValues.methodOfConcreteChlorideContent,
        maxAggregateSize: number(formValues.maxAggregateSize),
        aggregates:
            formValues.aggregates && formValues.aggregates.length > 0
                ? formValues.aggregates.map((aggregate: any) => {
                    return {
                        type: aggregate.type,
                        origin: aggregate.origin,
                        minAggregateSize: number(aggregate.minAggregateSize),
                        maxAggregateSize: number(aggregate.maxAggregateSize),
                        quantity: number(aggregate.quantity)
                    };
                })
                : []
    };
}

export function getFormValuesUS(form: FormInstance): ConcreteTypeModalFormValuesUS {
    const formValues = form.getFieldsValue();

    return {
        typeNumber: formValues.typeNumber,
        description: formValues.description || undefined,
        price: formValues.price,

        canBeOrdered: formValues.canBeOrdered,

        testingAge: number(formValues.testingAge),
        waterCementRatio: formValues.waterCementRatio,

        ...(formValues.cements && {
            cements: formValues.cements.map((cement: any) => {
                return {
                    type: cement.type,
                    origin: cement.origin || undefined,
                    quantity: number(cement.quantity)
                };
            })
        }),

        supplementaryCementiousMaterials: formValues.supplementaryCementiousMaterials
            ? formValues.supplementaryCementiousMaterials.map((supplementaryCementiousMaterial: any) => {
                return {
                    type: supplementaryCementiousMaterial.type,
                    origin: supplementaryCementiousMaterial.origin || undefined,
                    quantity: number(supplementaryCementiousMaterial.quantity)
                };
            })
            : [],

        admixtures: formValues.admixtures
            ? formValues.admixtures.map((admixture: any) => {
                return {
                    type: admixture.type,
                    origin: admixture.origin || undefined
                };
            })
            : [],

        additionalAttributes:
            formValues.additionalAttributes?.length > 0 ? formValues.additionalAttributes : undefined,
        assignToStructuralElements:
            formValues.assignToStructuralElements?.length > 0 ? formValues.assignToStructuralElements : undefined,

        compressiveStrength: number(formValues.compressiveStrength),
        slump: formValues.slump,
        slumpVariance: formValues.slumpVariance,
        airContentInPercent: number(formValues.airContentInPercent),
        submittalNumber: formValues.submittalNumber || undefined,
        methodOfConcrete: formValues.methodOfConcrete,
        maxAggregateSize: formValues.maxAggregateSize || undefined,
        aggregates: formValues.aggregates
            ? formValues.aggregates.map((aggregate: any) => {
                return {
                    type: aggregate.type,
                    origin: aggregate.origin || undefined,
                    maxAggregateSize: aggregate.maxAggregateSize || undefined,
                    quantity: number(aggregate.quantity)
                };
            })
            : []
    };
}