import { t } from "@lingui/macro";
import { WaterCementRatioFormItem } from "../../../FormItems/WaterCementRatioFormItem";

export function WaterCementRatioFormItemUS(): JSX.Element {
    return (
        <WaterCementRatioFormItem
            rules={[
                {
                    pattern: /^(0(,\d{1,2})?)$|^(1(,0{1,2})?)$/,
                    message: t`Please enter the water/cement ratio. Must be >= 0 and <= 1`
                }
            ]}
        />
    );
}
