/* eslint-disable react/jsx-props-no-spreading */

import { DatePicker as AntdDatePicker, DatePickerProps } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function DatePicker(props: DatePickerProps) {
    const { formats } = useProjectConstantsState();

    return <AntdDatePicker style={{ width: '100%' }} format={formats.dateForm} {...props} />;
}

export default DatePicker;
