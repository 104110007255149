/* eslint-disable react/jsx-no-useless-fragment */
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { formatCompressiveStrengthDE, formatExposureClass, mapStrengthDevelopmentClass } from '../../utils';
import styles from '../ConcreteTypesTable/ConcreteTypesTable.module.css';
import {
    CataloguedStructuralElementExtendedBP,
    ConcreteTypeExtendedBP
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

export const ConcreteTypesTableColumnsDE = (
    dispatchModalAction: (record: ConcreteTypeExtendedBP, action: string) => void,
    typeNumberClicked: (record: ConcreteTypeExtendedBP) => void,
    canBeOrderedCheckboxClicked: (record: ConcreteTypeExtendedBP) => void,
    assignUnassignStructuralElementButtonClicked: (record: ConcreteTypeExtendedBP) => void,
    onAssign: (record: ConcreteTypeExtendedBP) => void,
    onUnassign: (record: ConcreteTypeExtendedBP) => void,
    structuralElement?: CataloguedStructuralElementExtendedBP
): ColumnsType<ConcreteTypeExtendedBP> => {
    const getActions = (record: ConcreteTypeExtendedBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: <Trans>Edit</Trans>,
            key: `${record.id} Edit`,
            icon: <EditOutlined />,
            onClick: () => {
                dispatchModalAction(record, 'edit');
            }
        });

        items.push({
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <EditOutlined />,
            onClick: () => {
                dispatchModalAction(record, 'duplicate');
            }
        });

        if (structuralElement) {
            items.push({
                label: <Trans>Unassign</Trans>,
                key: `${record.id} AssignCaSe`,
                icon: <DeleteOutlined />,
                onClick: () => assignUnassignStructuralElementButtonClicked(record)
            });
        } else {
            items.push({
                label: <Trans>Assign structural elements</Trans>,
                key: `${record.id} AssignCaSe`,
                icon: <PlusCircleOutlined />,
                onClick: () => {
                    onAssign(record);
                }
            });

            items.push({
                label: <Trans>Unassign structural elements</Trans>,
                key: `${record.id} UnassignCaSe`,
                icon: <DeleteOutlined />,
                onClick: () => {
                    onUnassign(record);
                }
            });
        }

        return items;
    };

    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<ConcreteTypeExtendedBP>[] = [
        {
            title: <Trans>Concrete Type Number</Trans>,
            dataIndex: 'concreteTypeNumber',
            sorter: true,
            width: 300,
            render: (concreteTypeNumber: string, record: ConcreteTypeExtendedBP) => (
                <RawLinkButton data-testid="editButton" onClick={() => typeNumberClicked(record)}>
                    {concreteTypeNumber || ''}
                </RawLinkButton>
            )
        },
        {
            title: <Trans>Concrete Type Description</Trans>,
            dataIndex: 'concreteTypeDescription',
            sorter: true,
            width: 300,
            render: (concreteTypeDescription: string, _record: ConcreteTypeExtendedBP) => (
                <>{concreteTypeDescription || ''}</>
            )
        },
        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'cylindricCompressiveStrength',
            sorter: true,
            render: (_compressiveStrength: string, record: ConcreteTypeExtendedBP) => (
                <>
                    {formatCompressiveStrengthDE(
                        record.typeOfConcrete,
                        record.cylindricCompressiveStrength,
                        record.cubicCompressiveStrength
                    )}
                </>
            )
        },
        {
            title: <Trans>Exposure class</Trans>,
            dataIndex: 'id',
            render: (_id: string, record: ConcreteTypeExtendedBP) => (
                <>
                    {formatExposureClass(
                        record.x0,
                        record.xc,
                        record.xd,
                        record.xfFirst,
                        record.xfSecond,
                        record.xa,
                        record.xm,
                        record.xs
                    )}
                </>
            )
        },
        {
            title: <Trans>Max Aggregate Class</Trans>,
            dataIndex: 'maximumAggregateSize',
            sorter: true,
            render: (_maximumAggregateSize: string, record: ConcreteTypeExtendedBP) => (
                <>{record.maximumAggregateSize || ''}</>
            )
        },
        {
            title: <Trans>Consistency Class</Trans>,
            dataIndex: 'consistencyClass',
            sorter: true,
            render: (_consistencyClass: string, record: ConcreteTypeExtendedBP) => (
                <>
                    {record.consistencyType && record.consistencyClass
                        ? record.consistencyType + record.consistencyClass
                        : ''}
                </>
            )
        },
        {
            title: <Trans>Strength development Class</Trans>,
            dataIndex: 'id',
            sorter: true,
            render: (_id: string, record: ConcreteTypeExtendedBP) => (
                <>{mapStrengthDevelopmentClass(record.strengthDevelopmentClass)}</>
            )
        },
        {
            title: <Trans>Can be ordered</Trans>,
            dataIndex: 'canBeOrdered',
            sorter: true,
            width: 100,
            render: (_active: boolean, record: ConcreteTypeExtendedBP) => (
                <Checkbox checked={record.canBeOrdered} onChange={() => canBeOrderedCheckboxClicked(record)} />
            )
        },
        {
            title: <Trans>Actions</Trans>,
            hidden: isViewer,
            dataIndex: 'id',
            width: 50, // we set this width to have an alignment of this column with the other table having also 50 as width for the action column
            render: (id: string, record: ConcreteTypeExtendedBP) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                );
            }
        }
    ];

    return columns.filter((item) => !item.hidden);
};
