import { useCallback, useState } from 'react';
import { ValuesDropDown, mapStringToDropdown } from 'src/app-react/constants/marketsData';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGetDeliveryLocationDescriptionByProject() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [error, setError] = useState<{ message: string; description?: string }>();
    const [locationDescriptions, setLocationDescriptions] = useState<ValuesDropDown[]>(undefined);

    const loadData = useCallback(async () => {
        return (
            api.concreteLoadClient
                .loadUnloadingLocationDescriptionsByProject(companyId, projectId, undefined, undefined)
                .then((response) => setLocationDescriptions(mapStringToDropdown(response.getEntity())))
                // eslint-disable-next-line no-shadow
                .catch((error: any) => {
                    setError({ message: error.code, description: error.message });
                })
        );
    }, [api, companyId, projectId]);

    return {
        loadData,
        locationDescriptions,
        error
    };
}

export default useGetDeliveryLocationDescriptionByProject;
