import { Trans } from '@lingui/macro';
import { Badge, Tabs } from 'antd';
import { useContext, useState } from 'react';
import useProjectState from 'src/app-react/hooks/useProjectState';
import DeliveryNoteDetailsContext from '../../context/delivery-note-details-context';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import DeliveryStatusTable from '../delivery-status-table/delivery-status-table';
import { MARKETS } from 'src/app-react/constants/markets';

interface IDeliveryStatusTableGroupProps {
    refreshTable: boolean;
    setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function DeliveryStatusTableGroup({ refreshTable, setRefreshTable }: IDeliveryStatusTableGroupProps) {
    const [onRouteCount, setOnRouteCount] = useState(0);
    const [atConstructionSiteCount, setAtConstructionSiteCount] = useState(0);
    const [toSignCount, setToSignCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [rejectedCount, setRejectedCount] = useState(0);
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { projectId, marketId } = useProjectState();

    const items = [
        {
            label: (
                <>
                    <Trans>At Construction Site</Trans> <Badge count={atConstructionSiteCount} />
                </>
            ),
            key: 'AtConstructionSite',
            children: (
                <DeliveryStatusTable
                    status="AtConstructionSite"
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                    count={atConstructionSiteCount}
                    setCount={setAtConstructionSiteCount}
                />
            ),
            forceRender: true
        },
        {
            label: (
                <>
                    {MARKETS.DE === marketId ? (
                        <span data-testid="tabToSignDE">
                            <Trans>To Sign</Trans>
                        </span>
                    ) : (
                        <span data-testid="tabToSignUS">
                            <Trans>To accept</Trans>
                        </span>
                    )}
                    <Badge count={toSignCount} />
                </>
            ),
            key: 'ToSign',
            children: (
                <DeliveryStatusTable
                    status="ToSign"
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                    count={toSignCount}
                    setCount={setToSignCount}
                />
            ),
            forceRender: true
        },
        {
            label: (
                <>
                    <Trans>Completed</Trans> <Badge count={completedCount} />
                </>
            ),
            key: 'Completed',
            children: (
                <DeliveryStatusTable
                    status="Completed"
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                    count={completedCount}
                    setCount={setCompletedCount}
                />
            ),
            forceRender: true
        },
        {
            label: (
                <>
                    <Trans>Rejected</Trans> <Badge count={rejectedCount} />
                </>
            ),
            key: 'Rejected',
            children: (
                <DeliveryStatusTable
                    status="Declined"
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                    count={rejectedCount}
                    setCount={setRejectedCount}
                />
            ),
            forceRender: true
        }
    ];

    if (marketId !== MARKETS.AUS)
        items.unshift({
            label: (
                <>
                    <Trans>On Route</Trans> <Badge count={onRouteCount} />
                </>
            ),
            key: 'OnRoute',
            children: (
                <DeliveryStatusTable
                    status="OnRoute"
                    refreshTable={refreshTable}
                    setRefreshTable={setRefreshTable}
                    count={onRouteCount}
                    setCount={setOnRouteCount}
                />
            ),
            forceRender: true
        });

    return (
        <DeliveryNoteDetailsContext.Provider value={{ projectId, orderId }}>
            <Tabs defaultActiveKey={marketId === MARKETS.AUS ? 'AtConstructionSite' : 'onRoute'} items={items} />
        </DeliveryNoteDetailsContext.Provider>
    );
}
