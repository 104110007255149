import { Trans, t } from "@lingui/macro";
import { CataloguedStructuralElementExtendedBP } from "@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto";
import { Select } from "antd";
import { formatDescription } from "src/app-react/business-page/catalogue-management/utils";
import SelectFormItem from "src/app-react/components/Form/FormItem/SelectFormItem";

const { Option } = Select;

function compressiveStrengthUS(structuralElement: CataloguedStructuralElementExtendedBP): string {
    if (structuralElement.minimalRequiredCompressiveStrength) {
        return `${structuralElement.minimalRequiredCompressiveStrength} ${structuralElement.compressiveStrengthUnit}`;
    }
    return '';
}

function costCode(structuralElement: CataloguedStructuralElementExtendedBP): string {
    if (structuralElement.costCode) {
        return `${structuralElement.costCode}`;
    }
    return '';
}

export function AssignToStructuralElementsFormItem(
    {assignableStructuralElements}: {assignableStructuralElements: CataloguedStructuralElementExtendedBP[]} 
): JSX.Element {
    return (
        <SelectFormItem
            name="assignToStructuralElements"
            label={t`Assign to structural element(s)`}>
            <Select
                mode="multiple"
                key="assignToStructuralElements"
                data-testid="assignToStructuralElements"
                className="concrete-type-modal__input"
                placeholder={t`Please select`}
                optionLabelProp="label"
                optionFilterProp="label">
                {assignableStructuralElements.length > 0 ? (
                    assignableStructuralElements.map((structuralElement: CataloguedStructuralElementExtendedBP) =>
                        <Option
                            key={structuralElement.id}
                            value={structuralElement.id}
                            label={structuralElement.structuralElementName}>
                            <span>
                                <b>{structuralElement.structuralElementName}</b>
                                {' ('}
                                {[
                                    formatDescription(structuralElement.structuralElementDescription),
                                    compressiveStrengthUS(structuralElement),
                                    costCode(structuralElement)
                                ]
                                    .filter((x) => x !== '')
                                    .join(', ')}
                                )
                            </span>
                        </Option>
                    )
                ) : (
                    <Option value="No results available" disabled>
                        <Trans>No results available</Trans>
                    </Option>
                )}
            </Select>
        </SelectFormItem>
    );
}
