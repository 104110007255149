import { t } from '@lingui/macro';
import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { Col, DatePicker, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import InputFormItem from 'src/app-react/components/Form/FormItem/InputFormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import { BillingAddressFormItem } from './FormItems/BillingAddressFormItem';
import { ConstructionCountryFormItem } from './FormItems/ConstructionCountryFormItem';
import { ProjectNameFormItem } from './FormItems/ProjectNameFormitem';
import { SelectMarketFormItem } from './FormItems/SelectMarketFormItem';
import { TimeZoneFormItem } from './FormItems/TimeZoneFormItem';
import { TypeOfConstructionFormItem } from './FormItems/TypeOfConstructionFormItem';
import { ProjectFormValues } from './ProjectModalForm';

interface ProjectFormProps {
    form: FormInstance;
    project: Project;
    onFinish: (values: ProjectFormValues) => void;
    allowToUpdateMarketId: boolean;
}

export function ProjectForm({ form, project, onFinish, allowToUpdateMarketId }: ProjectFormProps) {
    return (
        <Form
            form={form}
            data-testid="projectModalForm"
            name="projectModalForm"
            layout="vertical"
            initialValues={project}
            onFinish={onFinish}>
            <Row>
                <TypeOfConstructionFormItem />
            </Row>
            <Row gutter={8}>
                <Col span="11">
                    <ProjectNameFormItem />
                </Col>
                <Col span="13">
                    <InputFormItem required label={t`Project number`} name="number">
                        <Input type="text" data-testid="projectNumber" placeholder={t`Enter project number`} />
                    </InputFormItem>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span="11">
                    <InputFormItem label={t`Short description of the project`} name="description">
                        <Input type="text" data-testid="projectDescription" placeholder={t`Enter short description`} />
                    </InputFormItem>
                </Col>
                <Col span="13">
                    <InputFormItem required label={t`Construction Site`} name="constructionName">
                        <Input type="text" data-testid="constructionSite" placeholder={t`Enter name`} />
                    </InputFormItem>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col span="6">
                    <Form.Item label={t`Start Construction`} name="startDate" rules={[requiredRule]}>
                        <DatePicker placeholder={t`Enter Date`} format="L" data-testid="startDate" />
                    </Form.Item>
                </Col>
                <Col span="5">
                    <Form.Item label={t`End Construction`} name="endDate" rules={[requiredRule]}>
                        <DatePicker placeholder={t`Enter Date`} format="L" data-testid="endDate" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span="5">
                    <InputFormItem required label={t`Street`} name="constructionStreet">
                        <Input type="text" placeholder={t`street`} data-testid="projectStreet" />
                    </InputFormItem>
                </Col>
                <Col span="3">
                    <InputFormItem required label={t`House No`} name="constructionHouseNo">
                        <Input type="text" placeholder={t`House Number`} data-testid="houseNumber" />
                    </InputFormItem>
                </Col>
                <Col span="3">
                    <InputFormItem required label={t`Zip`} name="constructionZip">
                        <Input type="text" placeholder={t`Enter Zip`} data-testid="projectZip" />
                    </InputFormItem>
                </Col>
                <Col span="6">
                    <InputFormItem label={t`City`} required name="constructionCity">
                        <Input type="text" placeholder={t`Enter City`} data-testid="projectCity" />
                    </InputFormItem>
                </Col>
                <Col span="6">
                    <ConstructionCountryFormItem />
                </Col>
            </Row>
            <Row>
                <TimeZoneFormItem />
            </Row>
            <Row>
                <BillingAddressFormItem />
            </Row>

            <Row>
                <SelectMarketFormItem disabled={!allowToUpdateMarketId} />
            </Row>
        </Form>
    );
}
