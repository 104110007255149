import { useMemo } from 'react';
import { IMarketData } from '../constants/marketsData';
import { getProjectConstants } from '../services/projectService';
import useProjectState from './useProjectState';

function useProjectConstantsState() {
    const { marketId, supplierId } = useProjectState();

    return useMemo<IMarketData['constants']>(
        () => getProjectConstants({ marketId, supplierId }),
        [marketId, supplierId]
    );
}

export default useProjectConstantsState;

