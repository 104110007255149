import { UserProfile } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { TablePaginationConfig } from 'antd';
import { createContext, useEffect, useMemo, useState } from 'react';
import { loadAuth } from 'src/launch';
import Api from '../../api';

export interface MutableGlobalContextValue {
    coseFocused?: string;
    documentationPaginationState?: TablePaginationConfig;
    documentationConcreteLoadPaginationState?: TablePaginationConfig;
}

export interface GlobalContextValue extends MutableGlobalContextValue {
    initialize: boolean;
    userProfile: UserProfile;
    api: Api;
    setGlobalState: (newGlobalState: Partial<MutableGlobalContextValue>) => void;
}

const GlobalContext = createContext<GlobalContextValue>({} as GlobalContextValue);

function GlobalContextProvider({ children }: { children: JSX.Element }) {
    const [globalState, setGlobalState] = useState<Omit<GlobalContextValue, 'setGlobalState'>>({
        initialize: false
    } as GlobalContextValue);

    useEffect(() => {
        if (!globalState.initialize) {
            loadAuth()
                .then((newGlobalState) => {
                    setGlobalState((oldGlobalState) => ({
                        ...oldGlobalState,
                        ...newGlobalState,
                        initialize: true
                    }));
                })
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                .catch((_error) => {
                    // TODO: handle launch error;
                });
        }
    }, [globalState, setGlobalState]);

    const value = useMemo(
        () => ({
            ...globalState,
            setGlobalState(newGlobalState: MutableGlobalContextValue) {
                setGlobalState((oldGlobalState) => ({
                    ...oldGlobalState,
                    ...newGlobalState
                }));
            }
        }),
        [globalState, setGlobalState]
    );

    return <GlobalContext.Provider value={value}>{globalState.initialize && children}</GlobalContext.Provider>;
}

export { GlobalContext, GlobalContextProvider };

