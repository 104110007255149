import { Trans } from "@lingui/macro";
import { Row, Col } from "antd";
import { AirTemperatureItem } from "../../../../../../../shared/Forms/StructuralElement/FormItems/AirTemperatureItem";
import { NameItem } from "../../../../../../../shared/Forms/StructuralElement/FormItems/NameItem";
import Title from "antd/lib/typography/Title";
import { CompressiveStrengthItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsDE/CompressiveStrengthItem";
import { StrengthDevelopmentItem } from "src/app-react/business-page/shared/Forms/StructuralElement/FormItemsDE/StrengthDevelopmentItem";
import DensityClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/DensityClassFormItem";
import ExposureClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/ExposureClassesFormItem";
import MoistureClassFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/MoistureClassFormItem";
import { ExternalInspectionFormItem } from "src/app-react/business-page/shared/FormItems/Catalogue/DE/ExternalInspectionFormItem";
import MethodConcreteUseChlorideFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/MethodOfConcreteUseChlorideFormItem";


const FormDE = () => {
    return (
        <>
            <Row>
                <Col span={24}>
                    <NameItem />
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <DensityClassFormItem required={false} />
                </Col>
                <Col span={12}>
                    <MoistureClassFormItem required={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <ExposureClassFormItem  required={false}/>
                </Col>
            </Row>
            <Row>
                <Col style={{ width: '100%' }}>
                    <CompressiveStrengthItem smallInputsStyle={{flex: 'auto', maxWidth: '160px'}} />
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <MethodConcreteUseChlorideFormItem name="methodConcreteUseChloride" />
                </Col>
                <Col span={12}>
                    <StrengthDevelopmentItem />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ExternalInspectionFormItem />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Title level={5} data-testid="title-air-temperature">
                        <Trans>Air temperature during concreting</Trans>
                    </Title>
                </Col>
            </Row>
            <Row gutter={[16, 0]}>
                <AirTemperatureItem />
            </Row>
        </>
    );
}

export default FormDE;