import { FunctionComponent } from 'react';
import { strengthDevelopmentFormatter } from 'src/app-react/utils/formatters';

interface StrengthDevelopmentFormatterProps {
    value: number | string | undefined | null;
}

const StrengthDevelopmentFormatter: FunctionComponent<StrengthDevelopmentFormatterProps> = (props) => {
    return <>{strengthDevelopmentFormatter(props.value)}</>;
}

export default StrengthDevelopmentFormatter;
