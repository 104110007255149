import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { BasicConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { DeliveryNoteDetailsBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Col, Form, Modal, Row, Select, SelectProps } from 'antd';
import moment from 'moment';
import { default as React, ReactElement, useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import WeatherFormItem from 'src/app-react/business-page/shared/FormItems/WeatherFormItem';
import { InputNumber } from 'src/app-react/components/Form';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { isObjectEmpty, mergeDateTime } from 'src/app-react/utils/lib';
import DeliveryNoteDetailsContext from '../../context/delivery-note-details-context';
import useContextOrProps from '../../hooks/useContextOrProps';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';

export type TSearchResultsState = SelectProps<object>['options'];
export interface IOptions {
    value: string;
    label: ReactElement;
}
const marketIdDE = 'DE';
export interface IConcretedLoad {
    id?: string;
    concretingStartDate: Date;
    concretingEndDate: Date;
    concreteLoadId: string;
    concretedStructuralElementId: string;
    volume: number;
    volumeUnit: string;
    weather: string;
    createdOn?: Date;
    lastModifiedOn?: Date;
}

export interface IConcreteLoadPreFill {
    quantity: number;
    startTime?: Date;
    endTime?: Date;
}
export interface IAcceptAndSignConcreteLoadModalProps {
    projectId: string;
    orderId: string;
    concreteLoadId: string;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
    fromDeliveryDetailsPage: boolean;
}

function AcceptAndSignDeliveryModal(props: IAcceptAndSignConcreteLoadModalProps) {
    const { concreteLoadId } = useContextOrProps(
        ['projectId', 'orderId', 'concreteLoadId'],
        props,
        DeliveryNoteDetailsContext
    );
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { companyId, projectId, marketId } = useProjectState();
    const { units } = useProjectConstantsState();
    const { open, setOpen, onComplete, fromDeliveryDetailsPage } = props;
    const [form] = Form.useForm();
    const [getOptions, setSelectionOptions] = useState<TSearchResultsState>([]);

    // TODO: fix this workaround
    let [concreteLoadPreFill, setConcreteLoadPreFill] = useState<IConcreteLoadPreFill>({} as IConcreteLoadPreFill);

    const [getSelectedOption, setSelectedCoSE] = useState<string>({} as string);
    const navigate: NavigateFunction = useNavigate();

    const api = useApi();

    useEffect(() => {
        if (open) {
            getCoSEsByOrderToSelectOption();
            getDeliveryNotePreFill();
        }
    }, [open, projectId, orderId, concreteLoadId]);

    const getInitialValue = () => {
        return {
            concretedStructuralElementId: getSelectedOption,
            concretingStartDate: moment(concreteLoadPreFill.startTime).set({ second: 0, millisecond: 0 }),
            concretingStartTime: moment(concreteLoadPreFill.startTime).set({ second: 0, millisecond: 0 }),
            concretingEndDate: moment(concreteLoadPreFill.endTime),
            concretingEndTime: moment(concreteLoadPreFill.endTime),
            volume: concreteLoadPreFill.quantity,
            volumeUnit: units.volume,
            concreteLoadId,
            weather: window.sessionStorage.getItem('weather') || undefined
        };
    };

    const setInitialFormValues = () => {
        form.setFieldsValue({ concreteLoadsList: [getInitialValue()] });
    };

    useEffect(() => {
        setInitialFormValues();
    }, [concreteLoadPreFill]);

    const getDeliveryNotePreFill = async (): Promise<void> => {
        api.deliveryNoteClient
            .getDeliveryNoteDetails(projectId, orderId, concreteLoadId, companyId)
            .then((response) => {
                if (response.isSuccess()) {
                    const delivery: DeliveryNoteDetailsBP = response.getEntity();

                    const concreteLoad: IConcreteLoadPreFill = {
                        quantity: delivery.deliveryNoteData.volume,
                        startTime: delivery.deliveryNoteData.unloadingStart,
                        endTime: delivery.deliveryNoteData.unloadingEnd
                    };

                    setConcreteLoadPreFill(concreteLoad);
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    };

    const getCoSEsByOrderToSelectOption = async (): Promise<void> => {
        let coSEsByOrder: BasicConcretedStructuralElementBP[] = [];

        api.concretingDocumentationClient
            .loadConcretedStructuralElementsByOrderId(projectId, orderId, companyId)
            .then((response) => {
                if (response.isSuccess()) {
                    coSEsByOrder = response.getEntity();
                    setSelectionOptions(createSelectOptions(coSEsByOrder));
                    setSelectedCoSE(coSEsByOrder[0].idCoSE);
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
    };

    const createSelectOptions = (coSEs: BasicConcretedStructuralElementBP[]): IOptions[] => {
        if (coSEs.length === 0) {
            return [
                {
                    value: 'No CoSEs available',
                    label: (
                        <div data-testid="option">
                            <span>
                                <Trans>No Results Available</Trans>
                            </span>
                        </div>
                    )
                }
            ];
        }
        return coSEs.map((coSE: BasicConcretedStructuralElementBP) => {
            return {
                key: coSE.idCoSE,
                value: coSE.idCoSE,
                label: (
                    <div data-testid="option">
                        <span> {coSE.nameCoSE} </span>
                    </div>
                )
            };
        });
    };

    const validateDates = (index: number): boolean => {
        const previousStartDate: moment.Moment = mergeDateTime(
            moment(form.getFieldsValue().concretedLoadsList[index - 1].concretingStartDate),
            form.getFieldsValue().concretedLoadsList[index - 1].concretingStartTime
        );

        const previousEndDate: moment.Moment = mergeDateTime(
            moment(form.getFieldsValue().concretedLoadsList[index - 1].concretingEndDate),
            form.getFieldsValue().concretedLoadsList[index - 1].concretingEndTime
        );

        if (previousStartDate.isAfter(previousEndDate)) {
            ErrorNotification({
                message: t`Inconsistent dates`,
                description: t`Concreting start must be lesses than concreting end date`
            });
            return false;
        }
        return true;
    };

    const validatePositiveQuantity = (concretedLoads: IConcretedLoad[]): boolean => {
        let isValid = true;
        concretedLoads.forEach((concretedLoad: IConcretedLoad) => {
            if (concretedLoad.volume <= 0) {
                if (isValid) {
                    ErrorNotification({
                        message: t`Inconsistent quantity`,
                        description: t`Quantity should be positive and bigger than 0`
                    });
                }
                isValid = false;
            }
        });
        return isValid;
    };

    const validateStructuralElementRepeated = (concretedLoads: IConcretedLoad[], index: number): boolean => {
        let isValid = true;
        if (concretedLoads.length > 1) {
            const actualStructuralElementId: string =
                form.getFieldsValue().concretedLoadsList[index - 1].concretedStructuralElementId;

            const concretedLoad: IConcretedLoad | undefined = concretedLoads.find(
                (element: IConcretedLoad) => element.concretedStructuralElementId === actualStructuralElementId
            );

            if (concretedLoad) {
                isValid = false;
            }
        }
        return isValid;
    };

    const validateQuantityExcededOrEqualLimit = (concretedLoads: IConcretedLoad[]): boolean => {
        const totalVolume: number = concretedLoads
            .map((concretedLoad: IConcretedLoad) => Number(concretedLoad.volume))
            .reduce((accum, volume) => accum + volume);
        if (totalVolume >= concreteLoadPreFill.quantity) {
            ErrorNotification({
                message: t`Max volume will be exceeded`,
                description: t`Maximum volume admitted is ${concreteLoadPreFill.quantity} ${units.volume}`
            });
            return false;
        }

        return true;
    };

    const validateQuantityExcededLimit = (concretedLoads: IConcretedLoad[]): boolean => {
        const totalVolume: number = concretedLoads
            .map((concretedLoad: IConcretedLoad) => Number(concretedLoad.volume))
            .reduce((accum, volume) => accum + volume);
        if (totalVolume > concreteLoadPreFill.quantity) {
            ErrorNotification({
                message: t`Max volume will be exceeded`,
                description: t`Maximum volume admitted is ${concreteLoadPreFill.quantity} ${units.volume}`
            });
            return false;
        }

        return true;
    };

    const validatePreviousValues = (index: number): boolean => {
        if (!validateDates(index)) {
            return false;
        }

        if (!validatePositiveQuantity(form.getFieldsValue().concretedLoadsList)) {
            return false;
        }

        const previousWeather: string = form.getFieldsValue().concretedLoadsList[index - 1].weather;

        if (previousWeather === undefined) {
            ErrorNotification({
                message: t`Inconsistent weather`,
                description: t`Weather is not selected`
            });
            return false;
        }

        if (!validateQuantityExcededOrEqualLimit(form.getFieldsValue().concretedLoadsList)) {
            return false;
        }

        return true;
    };

    const validateAcceptAndSign = (index: number): boolean => {
        if (!validateDates(index)) {
            return false;
        }

        if (!validatePositiveQuantity(form.getFieldsValue().concretedLoadsList)) {
            return false;
        }

        if (!validateQuantityExcededLimit(form.getFieldsValue().concretedLoadsList)) {
            return false;
        }

        if (!validateStructuralElementRepeated(form.getFieldsValue().concretedLoadsList, index)) {
            ErrorNotification({
                message: t`Structural Element is repeated`,
                description: t`Concreted loads should have different structural elements`
            });
            return false;
        }

        return true;
    };

    const getPreviousConcretedLoadFill = (index: number) => {
        const previousWeatherFromForm: string = form.getFieldsValue().concretedLoadsList[index - 1].weather;
        return {
            concretedStructuralElementId:
                form.getFieldsValue().concretedLoadsList[index - 1].concretedStructuralElementId,
            concretingStartDate: moment(concreteLoadPreFill.startTime),
            concretingStartTime: moment(concreteLoadPreFill.startTime),
            concretingEndDate: moment(concreteLoadPreFill.endTime),
            concretingEndTime: moment(concreteLoadPreFill.endTime),
            volume: concreteLoadPreFill.quantity,
            volumeUnit: units.volume,
            weather: previousWeatherFromForm || window.sessionStorage.getItem('weather')
        };
    };

    const transformToInputConcretedLoad = (concretedLoadList: any[]): IConcretedLoad[] => {
        const concretedLoads: IConcretedLoad[] = [];

        concretedLoadList.forEach((e: any) => {
            concretedLoads.push({
                concretingStartDate: mergeDateTime(e.concretingStartDate, e.concretingStartTime).toDate(),
                concretingEndDate: mergeDateTime(e.concretingEndDate, e.concretingEndTime).toDate(),
                concreteLoadId,
                concretedStructuralElementId: e.concretedStructuralElementId,
                volume: Number(e.volume),
                volumeUnit: e.volumeUnit,
                weather: e.weather
            });
        });
        return concretedLoads;
    };

    const handleOk = () => {
        form.validateFields()
            .then((concretedLoadList) => {
                try {
                    if (validateAcceptAndSign(form.getFieldsValue().concretedLoadsList.length)) {
                        let concretedLoadsArray: IConcretedLoad[] = [];
                        setOpen(false);

                        Object.keys(concretedLoadList).forEach(function (key) {
                            concretedLoadsArray = transformToInputConcretedLoad(concretedLoadList[key]);
                        });
                        api.deliveryNoteClient
                            .acceptSignDeliveryPost(projectId, orderId, concreteLoadId, companyId, concretedLoadsArray)
                            .then((response) => {
                                if (response.isSuccess()) {
                                    SuccessNotification({
                                        message: t`Accepted and Signed successfully`,
                                        description: ''
                                    });
                                    setInitialFormValues();
                                    onComplete?.();
                                    if (!fromDeliveryDetailsPage) {
                                        navigate(`/projects/${projectId}/orders/${orderId}/delivery-notes-details`);
                                    }
                                } else {
                                    ErrorNotification({
                                        message: response.getError(),
                                        description: ''
                                    });
                                }
                            });
                    }
                } catch (e: any) {
                    ErrorNotification({ message: e.code, description: e.message });
                }
            })
            .catch((info) => {
                ErrorNotification({ message: info.errorFields[0].errors[0], description: '' });
            });
    };

    const resetAndCloseModal = (): void => {
        setInitialFormValues();
        concreteLoadPreFill = {} as IConcreteLoadPreFill;
        setOpen(false);
    };

    return isObjectEmpty(concreteLoadPreFill) ? null : (
        <Modal
            maskClosable={false}
            open={open}
            width={700}
            title={
                marketId === marketIdDE ? (
                    <span>
                        <Trans>Accept and sign delivery</Trans>
                    </span>
                ) : (
                    <span>
                        <Trans>Accept delivery</Trans>
                    </span>
                )
            }
            okText={
                marketId === marketIdDE ? (
                    <span>
                        <Trans>Accept and Sign</Trans>
                    </span>
                ) : (
                    <span>
                        <Trans>Accept delivery</Trans>
                    </span>
                )
            }
            cancelText={t`Cancel`}
            onCancel={resetAndCloseModal}
            onOk={handleOk}
            data-testid="acceptAndSignModal"
            destroyOnClose>
            <Form
                form={form}
                name="concreteLoadsForm"
                data-testid="concreteLoadsForm"
                autoComplete="off"
                layout="vertical"
                initialValues={{ concretedLoadsList: [getInitialValue()] }}>
                <Form.List name="concretedLoadsList" data-testid="concretedLoadsList">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <div key={field.key}>
                                    <div style={field.name === 0 ? { visibility: 'hidden' } : {}}>
                                        <Row gutter={[48, 16]}>
                                            <Col span={24}>
                                                <hr />
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row gutter={[48, 16]}>
                                        <Col span={19}>
                                            <p>
                                                <Trans>
                                                    Please enter the concreting information for the concreted structural
                                                    element
                                                </Trans>
                                            </p>
                                        </Col>
                                        <Col span={5}>
                                            <div style={field.name === 0 ? { visibility: 'hidden' } : {}}>
                                                <Form.Item name={[field.name, 'remove']}>
                                                    <Button type="link" onClick={() => remove(field.name)}>
                                                        <MinusOutlined /> <Trans>Remove</Trans>
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[48, 16]}>
                                        <Col span={24}>
                                            <h4>
                                                <Trans>Concreted structural element</Trans>
                                            </h4>
                                            <Form.Item
                                                {...field}
                                                name={[field.name, 'concretedStructuralElementId']}
                                                rules={[requiredRule]}>
                                                <Select
                                                    placeholder="Please select the concreted structural element"
                                                    options={getOptions}
                                                    data-testid="structuralElementId"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[48, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<Trans>Concreting start</Trans>}
                                                name={[field.name, 'concretingStartDate']}
                                                rules={[
                                                    requiredRule
                                                    // {
                                                    //     message: t`Concreting start date is incorrect`,
                                                    //     validator: dateValidatorStartDate(field.key)
                                                    // }
                                                ]}
                                                data-testid="startConcretingDateInput">
                                                <DatePicker
                                                    data-testid="startDate"
                                                    format="L"
                                                    // disabledDate={disabledStartDateHandler}
                                                    style={{ width: 250 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                required={false}
                                                label=" "
                                                name={[field.name, 'concretingStartTime']}
                                                rules={[
                                                    requiredRule
                                                    // {
                                                    //     message: t`Time of concreting start date is not correct`,
                                                    //     validator: timeValidatorStartDate(field.key)
                                                    // }
                                                ]}>
                                                <TimePicker
                                                    data-testid="startTime"
                                                    // disabledHours={() => disabledHoursStartDateHandler(field.name)}
                                                    // disabledMinutes={() => disabledMinutesStartDateHandler(field.name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[48, 16]}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={<Trans>Concreting end</Trans>}
                                                name={[field.name, 'concretingEndDate']}
                                                rules={[
                                                    requiredRule
                                                    // {
                                                    //     message: t`Concreting end date is not correct`,
                                                    //     validator: dateValidatorEndDate(field.key)
                                                    // }
                                                ]}
                                                data-testid="endConcretingDateInput">
                                                <DatePicker
                                                    format="L"
                                                    data-testid="endDate"
                                                    //  disabledDate={disabledEndDateHandler}
                                                    style={{ width: 250 }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                required={false}
                                                label=" "
                                                name={[field.name, 'concretingEndTime']}
                                                rules={[
                                                    requiredRule
                                                    // {
                                                    //     message: t`Time of concreting end date is not correct`,
                                                    //     validator: timeValidatorEndDate(field.key)
                                                    // }
                                                ]}>
                                                <TimePicker
                                                    data-testid="endTime"
                                                    // disabledHours={() => disabledHoursEndDateHandler(field.name)}
                                                    // disabledMinutes={() => disabledMinutesEndDateHandler(field.name)}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[48, 16]}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={<Trans>Quantity</Trans>}
                                                name={[field.name, 'volume']}
                                                rules={[requiredRule]}>
                                                <InputNumber
                                                    addonAfter={units.volume}
                                                    style={{ minWidth: '150px' }}
                                                    value={`${concreteLoadPreFill.quantity}`}
                                                    data-testid="volumenInput"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={[48, 16]}>
                                        <Col span={24}>
                                            <WeatherFormItem
                                                label={<Trans>Select weather during the concreting</Trans>}
                                                name={[field.name, 'weather']}
                                                rules={[requiredRule]}
                                                radioGroupProps={{
                                                    'data-testid': 'weather'
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}

                            <Form.Item>
                                <Button
                                    disabled
                                    type="link"
                                    onClick={() => {
                                        validatePreviousValues(form.getFieldsValue().concretedLoadsList.length) &&
                                            add(
                                                getPreviousConcretedLoadFill(
                                                    form.getFieldsValue().concretedLoadsList.length
                                                )
                                            );
                                    }}>
                                    <PlusOutlined /> <Trans>Further structural element concreted</Trans>
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}

export default AcceptAndSignDeliveryModal;
