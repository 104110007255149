import * as React from 'react';

interface OrderMonitoringProviderProps {
    projectId: string;
}

type ProviderChildren = { projectId: string; children: React.ReactNode };

const OrderMonitoringContext = React.createContext({} as OrderMonitoringProviderProps);

function OrderMonitoringProvider({ projectId, children }: ProviderChildren) {
    const value: OrderMonitoringProviderProps = {
        projectId
    };
    return <OrderMonitoringContext.Provider value={value}>{children}</OrderMonitoringContext.Provider>;
}

export { OrderMonitoringContext, OrderMonitoringProvider };
