import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col } from 'antd';
import { numberFormat } from 'src/app-react/utils/formatters';
import styles from '../ConcreteTypeModal.module.css';
import { empty } from '../utils/utils';

export function WaterCementRatio({ concreteDetails }: { concreteDetails: SupplierProducedConcrete }) {
    return (
        <>
            <Col span={24}>
                <label className={styles.modalLabel}>
                    <Trans>Water/Cement Ratio</Trans>
                </label>
            </Col>
            <Col className={styles.colWithValue} data-testid="concreteTypeModal-waterCementRatio" span={24}>
                {concreteDetails.waterCementRatio ? numberFormat(concreteDetails.waterCementRatio) : empty}
            </Col>
        </>
    );
}
