import {
    OrderManagementProvider as OrderManagementProviderLegacy,
    OrderManagementContext,
    ProviderChildren
} from './order.management.providerLegacy';
import { OrderDependenciesContextProvider } from './OrderDependenciesContext';

function OrderManagementProvider({ projectId, children }: ProviderChildren) {
    return (
        <OrderDependenciesContextProvider>
            <OrderManagementProviderLegacy projectId={projectId}>
                {children}
            </OrderManagementProviderLegacy>
        </OrderDependenciesContextProvider>
    );
};

export { OrderManagementProvider, OrderManagementContext };
