import { Trans, t } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function UsernameFormItem() {
    return (
        <FormItem
            label={<Trans>Username</Trans>}
            name="username"
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="text" data-testid="username" placeholder={t`Enter Username`} />
        </FormItem>
    );
}
