import { Tag, Input, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React from 'react';

interface EditableTagGroupState {
    tags: string[];
    newTagLabel: string;
    inputVisible: boolean;
    inputValue: string;
    editInputIndex: number;
    editInputValue: string;
}

class EditableTagGroup extends React.Component<{
    tags?: string[];
    newTagLabel: string;
    onChange?: (tags: string[]) => void;
}> {
    public state: EditableTagGroupState;

    constructor(props: any) {
        super(props);
        this.state = {
            tags: this.props.tags ? this.props.tags : [],
            newTagLabel: this.props.newTagLabel,
            inputVisible: false,
            inputValue: '',
            editInputIndex: -1,
            editInputValue: ''
        };
        this.updateAntDesignFormItem(this.props.tags ? this.props.tags : []);
    }

    componentDidUpdate(prevProps: any) {
        if (JSON.stringify(prevProps.tags) !== JSON.stringify(this.props.tags)) {
            this.setState({
                tags: this.props.tags ? this.props.tags : []
            });
            if (this.props.tags) {
                this.props?.onChange!(this.props.tags);
            }
        }
    }

    private updateAntDesignFormItem(tags: string[]) {
        this.props?.onChange!(tags);
    }

    private handleClose = (removedTag: any) => {
        const tags = this.state.tags.filter((tag) => tag !== removedTag);
        this.setState({ tags });
        this.updateAntDesignFormItem(tags);
    };

    private showInput = () => {
        this.setState({ inputVisible: true }, () => this.input.focus());
    };

    private handleInputChange = (e: any) => {
        this.setState({ inputValue: e.target.value });
    };

    private handleInputConfirm = () => {
        const { inputValue } = this.state;
        let { tags } = this.state;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }

        this.setState({
            tags,
            inputVisible: false,
            inputValue: ''
        });
        this.updateAntDesignFormItem(tags);
    };

    private handleEditInputChange = (e: any) => {
        this.setState({ editInputValue: e.target.value });
    };

    private handleEditInputConfirm = () => {
        const { tags, editInputIndex, editInputValue } = this.state;

        const newTags = [...tags];
        newTags[editInputIndex] = editInputValue;

        this.setState({
            tags: newTags,
            editInputIndex: -1,
            editInputValue: ''
        });
        this.updateAntDesignFormItem(tags);
    };

    private input: any;
    private editInput: any;

    private saveInputRef = (input: any) => {
        this.input = input;
    };

    private saveEditInputRef = (input: any) => {
        this.editInput = input;
    };

    render(): JSX.Element {
        const { tags, inputVisible, inputValue, editInputIndex, editInputValue } = this.state;
        return (
            <>
                <div
                    style={{
                        borderStyle: 'solid',
                        borderWidth: 'thin',
                        borderColor: '#D9D9D9',
                        paddingLeft: '7px',
                        paddingRight: '7px',
                        paddingTop: '2px',
                        paddingBottom: '4px'
                    }}>
                    {tags &&
                        tags.map((tag: string, index: number) => {
                            if (editInputIndex === index) {
                                return (
                                    <Input
                                        ref={this.saveEditInputRef}
                                        key={tag}
                                        size="small"
                                        value={editInputValue}
                                        onChange={this.handleEditInputChange}
                                        onBlur={this.handleEditInputConfirm}
                                        onPressEnter={this.handleEditInputConfirm}
                                    />
                                );
                            }

                            const isLongTag: boolean = tag.length > 20;

                            const tagElem: JSX.Element = (
                                <Tag key={tag} closable={true} onClose={() => this.handleClose(tag)}>
                                    <span
                                        onDoubleClick={(e) => {
                                            this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                                                this.editInput.focus();
                                            });
                                            e.preventDefault();
                                        }}>
                                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                    </span>
                                </Tag>
                            );
                            return isLongTag ? (
                                <Tooltip title={tag} key={tag}>
                                    {tagElem}
                                </Tooltip>
                            ) : (
                                tagElem
                            );
                        })}
                    {inputVisible && (
                        <Input
                            ref={this.saveInputRef}
                            type="text"
                            size="small"
                            value={inputValue}
                            onChange={this.handleInputChange}
                            onBlur={this.handleInputConfirm}
                            onPressEnter={this.handleInputConfirm}
                        />
                    )}
                    {!inputVisible && (
                        <Tag onClick={this.showInput}>
                            <PlusOutlined /> {`${this.state.newTagLabel}`}
                        </Tag>
                    )}
                </div>
            </>
        );
    }
}

export default EditableTagGroup;
