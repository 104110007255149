const TYPE_OF_CONCRETE = [
    {
        value: 'C',
        label: 'C'
    },
    {
        value: 'LC',
        label: 'LC'
    }
];

export default TYPE_OF_CONCRETE;
