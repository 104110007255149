import { PlusSquareOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { ColumnsType } from 'antd/lib/table';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';

const NA: string = '';

interface IProps {
    expandable: boolean;
    onCatalogueStructuralElementModal: (structuralElement: CataloguedStructuralElementExtendedBP) => void;
    onCatalogueConcreteTypeModal: (structuralElementId: string) => void;
}
export const OrderStructuralElementsTableColumnsUS = ({
    expandable,
    onCatalogueStructuralElementModal,
    onCatalogueConcreteTypeModal
}: IProps): ColumnsType<CataloguedStructuralElementExtendedBP> => {
    const columns = [
        {
            title: <Trans>Structural element</Trans>,
            dataIndex: 'elementName',
            sorter: true,
            render: (structuralElementName: string, record: CataloguedStructuralElementExtendedBP) => (
                <RawLinkButton data-testid="editButton" onClick={() => onCatalogueStructuralElementModal(record)}>
                    {record ? record.structuralElementName : NA}
                </RawLinkButton>
            )
        },

        {
            title: <Trans>Description</Trans>,
            dataIndex: 'id',
            sorter: true,
            render: (structuralElementDescription: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record && record.structuralElementDescription ? record.structuralElementDescription : NA}</>
            )
        },

        {
            title: <Trans>Compressive strength + unit</Trans>,
            dataIndex: 'compressiveStrength',
            sorter: true,
            render: (compressiveStrength: string, record: CataloguedStructuralElementExtendedBP) => (
                <> {record ? `${record.minimalRequiredCompressiveStrength} ${record.compressiveStrengthUnit}` : NA}</>
            )
        },

        {
            title: <Trans>Description</Trans>,
            dataIndex: 'elementDescription',
            render: (structuralElementDescription: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record ? record.structuralElementDescription : NA}</>
            )
        },
        {
            title: <Trans>Cost Code</Trans>,
            dataIndex: 'costCode',
            sorter: true,
            render: (costCode: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record ? record.costCode : NA}</>
            )
        }
    ];

    if (!expandable) {
        columns.push(displayOrderCreationColumn());
    }

    return columns;

    function displayOrderCreationColumn() {
        return {
            title: <Trans>Order</Trans>,
            dataIndex: 'order',
            sorter: false,
            fixed: 'right',

            render: (_: string, record: CataloguedStructuralElementExtendedBP) => (
                <PlusSquareOutlined
                    data-testid="createOrderBtn"
                    onClick={() => onCatalogueConcreteTypeModal(record.id)}
                    style={{ fontSize: '150%' }}></PlusSquareOutlined>
            )
        };
    }
};
