import { Trans, t } from '@lingui/macro';
import { SupplierProducedConcreteTotalVolume } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import { IMarketUnits } from 'src/app-react/constants/marketsData';

interface Props {
    units: IMarketUnits;
    totalVolumeData: SupplierProducedConcreteTotalVolume;
}

export default function orderFormInformationColumnsDE({ units, totalVolumeData: volumeData }: Props) {
    const columns: ColumnsType<DeliveryNoteFullDetails> = [
        {
            title: <Trans>Order Form No.</Trans>,
            key: 'supplierOrderId',
            dataIndex: 'supplierOrderId',
            render: (_val: string, record) => record?.concreteOrder?.supplierOrderId
        },
        {
            title: <Trans>Progress Total</Trans>,
            key: 'totalProgress',
            dataIndex: 'totalProgress',
            render: () => <NumberFormatter value={volumeData?.progress} unit={units.volume} />
        },
        {
            title: <Trans>Delivered Total</Trans>,
            key: 'totalDelivered',
            dataIndex: 'totalDelivered',
            render: () => <NumberFormatter value={volumeData?.total} unit={units.volume} />
        },
        {
            title: <Trans>Total order</Trans>,
            key: 'totalOrder',
            dataIndex: 'totalOrder',
            render: (_val: string, record) => (
                <NumberFormatter value={record?.concreteOrder?.volume} unit={units.volume} />
            )
        },
        {
            title: <Trans>Plus Load Expected</Trans>,
            key: 'plusLoadExpected',
            dataIndex: 'plusLoadExpected',
            render: (_val: string, record) =>
                record.concreteOrder?.additionalQuantityRequired ? (
                    <Tag color="green">{t`Yes`}</Tag>
                ) : (
                    <Tag color="grey">{t`No`}</Tag>
                )
        }
    ];

    return columns;
}
