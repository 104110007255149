import { Col, Row } from 'antd';
import {
    ConcreteLoadLiteModalAction,
    OrderLoadLiteTypesValues
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import DeliveryNoteNumberFormItem from '../../Form/FormItems/DeliveryNoteNumberFormItem';
import OrderVolumeFormItem from '../../Form/FormItems/OrderVolumeFormItem';
import TruckNumberFormItem from '../../Form/FormItems/TruckNumberFormItem';
import VolumeFormItem from '../../Form/FormItems/VolumeFormItem';
import ConcreteTypeNumberFormItem from '../FormItems/ConcreteTypeNumberFormItem';
import ConstructorNameFormItem from '../FormItems/ConstructorNameFormItem';
import CustomerNumberFormItem from '../FormItems/CustomerNumberFormItem';
import DeliveryTimeFormItem from '../FormItems/DeliveryTimeFormItem';
import DistanceToPlantFormItem from '../FormItems/DistanceToPlantFormItem';
import ExternalCustomPropFormItem from '../FormItems/ExternalCustomPropFormItem';
import ExternalReferenceFormItem from '../FormItems/ExternalReferenceFormItem';
import LocationDescriptionFormItem from '../FormItems/LocationDescriptionFormItem';
import LocationNameFormItem from '../FormItems/LocationNameFormItem';
import MixingPlantFormItem from '../FormItems/MixingPlantFormItem';
import ProductDescriptionFormItem from '../FormItems/ProductDescriptionFormItem';
import SupplierInformationGroupItems from '../FormItems/SupplierInformationGroupItems';
import SupplierOrderIdFormItem from '../FormItems/SupplierOrderIdFormItem';

interface OrderLiteModalForm1AUSLayout {
    action?: ConcreteLoadLiteModalAction;
    type: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
}

export function OrderLiteModalForm1AUSLayout({ action, type, origin }: OrderLiteModalForm1AUSLayout) {
    return (
        <>
            <Row gutter={54}>
                <Col span={12}>
                    <DeliveryNoteNumberFormItem data-testid="DeliveryNoteNumberFormItemAUS" />
                </Col>
                <Col span={12}>
                    <ConcreteTypeNumberFormItem data-testid="ConcreteTypeNumberFormItemAUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span="12">
                    <VolumeFormItem data-testid="VolumeFormItemAUS" />
                </Col>
                <Col span={12}>
                    <ExternalReferenceFormItem data-testid="ExternalReferenceFormItemAUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <MixingPlantFormItem data-testid="MixingPlantFormItemAUS" />
                </Col>
                <Col span={12}>
                    <SupplierOrderIdFormItem action={action} data-testid="SupplierOrderIdFormItemAUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <OrderVolumeFormItem data-testid="OrderVolumeFormItemAUS" />
                </Col>
                <Col span={12}>
                    <DeliveryTimeFormItem data-testid="DeliveryTimeFormItemAUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <TruckNumberFormItem data-testid="TruckNumberFormItemAUS" />
                </Col>
                <Col span={12}>
                    <ExternalCustomPropFormItem data-testid="ExternalCustomPropFormItemAUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <LocationDescriptionFormItem required={false} data-testid="LocationDescriptionFormItemAUS" />
                </Col>
                <Col span={12}>
                    <LocationNameFormItem required={false} data-testid="LocationNameFormItemAUS" />
                </Col>
            </Row>
            <Row gutter={54}>
                <Col span={12}>
                    <DistanceToPlantFormItem data-testid="DistanceToPlantFormItemAUS" />
                </Col>
                <Col span={12}>
                    <ProductDescriptionFormItem data-testid="ProductDescriptionFormItemAUS" />
                </Col>
            </Row>
            <SupplierInformationGroupItems origin={origin} type={type} data-testid="SupplierInformationGroupAUS" />
            <Row gutter={54}>
                <Col span={12}>
                    <ConstructorNameFormItem data-testid="ConstructorNameFormItemAUS" />
                </Col>
                <Col span={12}>
                    <CustomerNumberFormItem data-testid="CustomerNumberFormItemAUS" />
                </Col>
            </Row>
        </>
    );
}
