import { FormInstance, Tag } from "antd";
import { Trans } from "@lingui/macro";
import { IOptions } from "src/app-react/business-page/catalogue-management/components/AssignConcreteType/assign-concrete-type";
import { keyValue, mapper, optionMethodConcrete } from "src/app-react/business-page/catalogue-management/mapper.fields";


export const getRequiredXfFirst = (selectedValue: string | undefined): number | null => {
    if (selectedValue) {
        if (selectedValue !== '2/3') {
            return Number(selectedValue.replace('XF', ''));
        } else {
            return 2;
        }
    }
    return null;
};
export const getRequiredXfSecond = (selectedValue: string | undefined): number | null => {
    if (selectedValue && selectedValue === '2/3') {
        return 3;
    }
    return null;
    
};
export const getRequiredXx = (selectedValue: string | undefined): number | null => {
    if (selectedValue) {
        return Number(selectedValue);
    }
    return null;
    
};

export const generateRequiredXf = (requiredXfFirst: number | undefined, requiredXfSecond: number | undefined): string => {
    if (requiredXfFirst && requiredXfSecond) {
        return '2/3';
    } else if (requiredXfFirst) {
        return `${requiredXfFirst}`;
    }
    return '';
    
};
export const generateRequired = (XX: string, n: number | undefined) => {
    return n ? `${n}` : undefined;
}

export function hasValidExposureClass(form: FormInstance<any>): boolean {
    const isEmptyOrUndefined = (field: string): boolean => {
        const empty: boolean = form.getFieldValue(field) ? false : true;
        return empty || form.getFieldValue(field) === '';
    };
    const haveAtLeastOne = (): boolean => {
        const result: boolean =
            !isEmptyOrUndefined('requiredXc') ||
            !isEmptyOrUndefined('requiredXd') ||
            !isEmptyOrUndefined('requiredXf') ||
            !isEmptyOrUndefined('requiredXa') ||
            !isEmptyOrUndefined('requiredXm') ||
            !isEmptyOrUndefined('requiredXs');
        return result;
    };
    const atLeastOne: boolean = haveAtLeastOne();

    if (atLeastOne === false && (form.getFieldValue('requiredX0') === false || !form.getFieldValue('requiredX0'))) {
        return false;
    }
    return true;
}

export function mixDesignItemTagRender(props: any) {
    const { label, closable, onClose } = props;
    if ((label as string).toUpperCase() !== 'No Results Available'.toUpperCase()) {
        return (
            <Tag
                color={'blue'}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
                data-testid="valueConcreteTypeIds">
                {label}
            </Tag>
        );
    } else {
        return <></>;
    }
}

const getOptions = (fieldOption: string, value: string): JSX.Element => {
    switch (fieldOption) {
        case 'methodConcreteUseChloride':
        case 'methodConcreteUse':
            return (
                <div data-testid={`field${value}`}>
                    <span>{optionMethodConcrete(value)} </span>
                </div>
            );
        default:
            return (
                <div data-testid={`field${value}`}>
                    <span> {value} </span>
                </div>
            );
    }
};

export const noOptionAvailable = (): IOptions[] => {
    return [
        {
            value: 'No results available',
            label: (
                <div data-testid="option">
                    <span>
                        <Trans>No Results Available</Trans>
                    </span>
                </div>
            )
        }
    ];
};


export const createSelectOptions = (marketId: string, field: string): IOptions[] => {
    const options: keyValue[] = mapper(marketId, field);

    if (options.length > 0) {
        return options.map((option: keyValue) => {
            return {
                key: option.key,
                value: option.value,
                label: getOptions(field, option.value)
            };
        });
    }
    return noOptionAvailable();
};