/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { Input } from 'antd';
import { t } from '@lingui/macro';
import InputFormItem, { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import { i18n } from '@lingui/core';
import { fractionToNumberOrJustNumber } from 'src/app-react/components/Formatters/FractionToNumberOrJustNumber';

function MaxAggregateSizeFormItem(props: InputFormItemProps) {
    const required = props.required ?? false;

    const allowPositiveDecimalWith2DigitsAfterDecimalSeparator = new RegExp(
        i18n.locale === 'de-DE' ? /(^\d+(?:,\d{1,2})?$)/ : /(^\d+(?:\.\d{1,2})?$)/
    );
    const allowPositiveFraction = new RegExp(/(^(\d+ +)?(?:[1-9]\d*|0)\/[1-9]\d*$)/);

    return (
        <InputFormItem
            required={required}
            name="maxAggregateSize"
            label={t`Aggregate size`}
            rules={[
                {
                    pattern: new RegExp(
                        `${allowPositiveDecimalWith2DigitsAfterDecimalSeparator.source}|${allowPositiveFraction.source}`
                    ),
                    message: t`Please enter the aggregate size`
                },
                () => ({
                    validator(_, value) {
                        if (value === undefined || fractionToNumberOrJustNumber(value)! > 0) {
                            return Promise.resolve();
                        }
                        return Promise.reject('> 0');
                    }
                })
            ]}
            {...props}
        >
            <Input type="text" data-testid="maxAggregateSize" placeholder={t`Enter aggregate size`} />
        </InputFormItem>
    );
}

export default MaxAggregateSizeFormItem;
