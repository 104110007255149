import { t, Trans } from '@lingui/macro';
import { TestingAgeForConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Button, Form, FormInstance, Modal, Row, Steps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKET_VALUES, MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import { ConcreteTestingTypes } from '../../utils/concrete-tests-service';
import AirContentTestForm from './components/AirContentTestForm/AirContentTestForm';
import {
    createAirContentTest,
    createInitialValues as createInitialValuesAir,
    getAirContentFormValues
} from './components/AirContentTestForm/util';
import CommonTestForm from './components/CommonTestForm/CommonTestForm';
import { CommonTestFormValues } from './components/CommonTestForm/models';
import { createInitialCommonValues, getFormCommonValues } from './components/CommonTestForm/util';
import ConsistencyTestForm from './components/ConsistencyTestForm/ConsistencyTestForm';
import {
    createConsistencyTest,
    createInitialValues as createInitialValuesConsistency,
    getConsistencyFormValues
} from './components/ConsistencyTestForm/util';
import RelativeHumidityTestForm from './components/RelativeHumidityTestForm/RelativeHumidityTestForm';
import {
    createInitialValues as createInitialValuesRelativeHumidity,
    createRelativeHumidityTest,
    getRelativeHumidityFormValues
} from './components/RelativeHumidityTestForm/util';
import {
    createInitialValues as createInitialValuesTemperature,
    createTemperatureTest,
    getTemperatureFormValues
} from './components/TemperatureTestForm/markets/util';
import TemperatureTestForm from './components/TemperatureTestForm/TemperatureTestForm';
import {
    createInitialValues as createInitialValuesTestSpecimen,
    createSpecimenTest,
    getSpecimentTestFormValues
} from './components/TestSpecimenTestForm/markets/util';
import TestSpecimenTestForm from './components/TestSpecimenTestForm/TestSpecimenTestForm';
import {
    createInitialValues as createInitialValuesWindSpeed,
    createWindSpeedTest,
    getWindSpeedFormValues
} from './components/WindSpeedTestForm/util';
import WindSpeedTestForm from './components/WindSpeedTestForm/WindSpeedTestForm';
import styles from './MultipleConcreteTestModal.module.css';
import { createInitialFormValuesTestSpecimenTestForm } from './util';
import {
    AirContentTest,
    ConsistencyTest,
    InputMultipleConcreteTest,
    RelativeHumidityTest,
    TemperatureTest,
    WindSpeedTest
} from '@nexploretechnology/concreting-core-client';
import { TestSpecimenBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto';
import useMultipleTest from './components/MultipleTestForm/hooks/useMultipleTest';

const { Step } = Steps;

function Separator() {
    return (
        <>
            <hr />
            <br />
        </>
    );
}

export interface MultipleConcreteTestModalProps {
    readonly concreteLoadId: string;
    readonly open: boolean;
    readonly setOpen: (isVisible: boolean) => void;

    readonly initialFormValues?: Partial<CommonTestFormValues>;
    readonly resetInitialFormValuesConcreteTestModal?: () => void;
    readonly onOkFreshConcreteTests?: () => void;
    readonly onOkTestSpecimen?: () => void;
    readonly deliveryNoteNumber?: string;
}

function MultipleConcreteTestModal(props: MultipleConcreteTestModalProps) {
    const {
        concreteLoadId,
        open,
        setOpen,
        initialFormValues,
        resetInitialFormValuesConcreteTestModal,
        onOkFreshConcreteTests,
        onOkTestSpecimen
    } = props;

    const { orderId } = useContext(DeliveryNoteManagementContext);

    const api = useApi();

    const multipleTestMutate = useMultipleTest();

    const { projectId, marketId, companyId } = useProjectState();

    let enableConfirmButton = true;
    const [visiblePage, setVisiblePage] = useState(0);

    const [temperatureTestFormVisible, setTemperatureTestFormVisible] = useState(false);
    const [consistencyTestFormVisible, setConsistencyTestFormVisible] = useState(false);
    const [testSpecimenTestFormVisible, setTestSpecimenTestFormVisible] = useState(false);
    const [airContentFormVisible, setAirContentTestFormVisible] = useState(false);
    const [windSpeedFormVisible, setWindSpeedTestFormVisible] = useState(false);
    const [relativeHumidityFormVisible, setRelativeHumidityTestFormVisible] = useState(false);

    const [testingAgeForConcreteLoad, setTestingAgeForConcreteLoad] = useState<TestingAgeForConcreteLoad>();

    const [showErrorTestSpecimAlreadyExists, setShowErrorTestSpecimAlreadyExists] = useState(false);

    const [temperatureTestForm] = Form.useForm();
    const [consistencyTestForm] = Form.useForm();
    const [testSpecimenTestForm] = Form.useForm();
    const [airContentTestForm] = Form.useForm();
    const [windSpeedTestForm] = Form.useForm();
    const [relativeHumidityTestForm] = Form.useForm();

    useEffect(() => {
        if (marketId === MARKETS.DE) {
            fetchTestingAge();
        }
    }, [projectId, companyId]);

    const fetchTestingAge = async () => {
        api.deliveryNoteClient
            .getTestingAgeForConcreteLoad(companyId, projectId, concreteLoadId)
            .then((res: SimpleClientResponse<TestingAgeForConcreteLoad>) => {
                const err = res.getError();
                if (err) {
                    ErrorNotification({
                        message: t`Testing Age not found`,
                        description: err.toString()
                    });
                } else {
                    setTestingAgeForConcreteLoad(res.getEntity());
                }
            });
    };

    const now: Date = new Date();

    const [commonForm] = Form.useForm();

    async function formOnOk(): Promise<void> {
        if (visiblePage === 0) {
            if (await util.isFormValid(commonForm)) {
                const formValues: CommonTestFormValues = getFormCommonValues(commonForm);

                const commonValues = {
                    testingDate: formValues?.samplingDate || now,
                    testingTime: formValues?.samplingTime || now,
                    externalInspection: true
                };

                for (const testType of formValues.testType) {
                    switch (testType) {
                        case ConcreteTestingTypes.Temperature:
                            temperatureTestForm.setFieldsValue(
                                createInitialValuesTemperature(commonValues, marketId as MARKET_VALUES)
                            );
                            setTemperatureTestFormVisible(true);
                            break;
                        case ConcreteTestingTypes.Consistency:
                            consistencyTestForm.setFieldsValue(
                                createInitialValuesConsistency(commonValues, marketId as MARKET_VALUES)
                            );
                            setConsistencyTestFormVisible(true);
                            break;
                        case ConcreteTestingTypes.Specimen:
                            const formValues = createInitialFormValuesTestSpecimenTestForm(
                                testingAgeForConcreteLoad,
                                marketId as MARKET_VALUES
                            );
                            const values = createInitialValuesTestSpecimen(
                                formValues,
                                marketId as MARKET_VALUES,
                                props.deliveryNoteNumber
                            );
                            testSpecimenTestForm.setFieldsValue(values);
                            setTestSpecimenTestFormVisible(true);
                            break;
                        case ConcreteTestingTypes.AirContent:
                            airContentTestForm.setFieldsValue(
                                createInitialValuesAir(commonValues, marketId as MARKET_VALUES)
                            );
                            setAirContentTestFormVisible(true);
                            break;
                        case ConcreteTestingTypes.WindSpeed:
                            windSpeedTestForm.setFieldsValue(createInitialValuesWindSpeed(commonValues));
                            setWindSpeedTestFormVisible(true);
                            break;
                        case ConcreteTestingTypes.RelativeHumidity:
                            relativeHumidityTestForm.setFieldsValue(createInitialValuesRelativeHumidity(commonValues));
                            setRelativeHumidityTestFormVisible(true);
                            break;
                    }
                }
                setVisiblePage(1);
            }
        } else if (enableConfirmButton === true) {
            enableConfirmButton = false;
            await handleMultipleConcreteTestMutation();
        }
    }

    async function handleMultipleConcreteTestMutation(): Promise<void> {
        const multipleTestInput = await createMultipleConcreteTestInput();

        await multipleTestMutate(multipleTestInput, {
            orderId,
            concreteLoadId
        })
            .then((response) => handleMultipleConcreteTestResponse(multipleTestInput, response))
            .finally(() => (enableConfirmButton = true));
    }

    function handleMultipleConcreteTestResponse(
        inputs: InputMultipleConcreteTest,
        response: {
            success: boolean;
            error: any;
            data: any;
        }
    ): void {
        const {
            inputTemperatureTest,
            inputConsistencyTest,
            inputTestSpecimen,
            inputAirContentTest,
            inputWindSpeedTest,
            inputRelativeHumidityTest
        } = inputs;

        const {
            responseTemperatureTest,
            responseConsistencyTest,
            responseTestSpecimen,
            responseAirContentTest,
            responseWindSpeedTest,
            responseRelativeHumidityTest
        } = response.data;

        if (inputTemperatureTest) {
            handleTemperatureTestResponse(responseTemperatureTest, response.error);
        }

        if (inputConsistencyTest) {
            handleConsistencyTestResponse(responseConsistencyTest, response.error);
        }

        if (inputTestSpecimen) {
            handleTestSpecimenResponse(responseTestSpecimen, response.error);
        }

        if (inputAirContentTest) {
            handleAirConditionTestResponse(responseAirContentTest, response.error);
        }

        if (inputWindSpeedTest) {
            handleWindSpeedTestResponse(responseWindSpeedTest, response.error);
        }

        if (inputRelativeHumidityTest) {
            handleRelativeHumidityTestResponse(responseRelativeHumidityTest, response.error);
        }

        if (Object.values(response.data).some((value) => value !== null)) {
            formReset();
        }
    }

    function handleTemperatureTestResponse(response: TemperatureTest, error?: string): void {
        if (response) {
            onOkFreshConcreteTests?.();
            SuccessNotification({
                message: t`Temperature test created successfully`,
                description: ''
            });
        } else {
            ErrorNotification({
                message: t`Temperature test could not be created`,
                description: error ?? t`Unknown error`
            });
        }
    }

    function handleConsistencyTestResponse(response: ConsistencyTest, error?: string): void {
        setConsistencyTestFormVisible(response === null);
        if (response) {
            onOkFreshConcreteTests?.();
            SuccessNotification({
                message: t`Consistency test created successfully`,
                description: ''
            });
        } else {
            ErrorNotification({
                message: t`Consistency test could not be created`,
                description: error ?? t`Unknown error`
            });
        }
    }

    function handleTestSpecimenResponse(response: TestSpecimenBP, error?: string): void {
        setTestSpecimenTestFormVisible(response === null);
        if (response) {
            onOkTestSpecimen?.();
            SuccessNotification({
                message: t`Test specimen created successfully`,
                description: ''
            });
        } else {
            if (error === 'TEST_SPECIMEN_ALREADY_EXISTS') {
                setShowErrorTestSpecimAlreadyExists(true);
                error = t`This specimen identifier already exists, please insert a unique identifier`;
            }
            ErrorNotification({
                message: t`Test specimen could not be created`,
                description: error ?? t`Unknown error`
            });
        }
    }

    function handleAirConditionTestResponse(response: AirContentTest, error?: string): void {
        setAirContentTestFormVisible(response === null);
        if (response) {
            onOkFreshConcreteTests?.();
            SuccessNotification({
                message: t`Air Content test created successfully`,
                description: ''
            });
        } else {
            ErrorNotification({
                message: t`Air Content test could not be created`,
                description: error ?? t`Unknown error`
            });
        }
    }

    function handleWindSpeedTestResponse(response: ConsistencyTest, error?: string): void {
        setWindSpeedTestFormVisible(response === null);
        if (response) {
            onOkFreshConcreteTests?.();
            SuccessNotification({
                message: t`Wind Speed test created successfully`,
                description: ''
            });
        } else {
            ErrorNotification({
                message: t`Wind Speed test could not be created`,
                description: error ?? t`Unknown error`
            });
        }
    }

    function handleRelativeHumidityTestResponse(response: ConsistencyTest, error?: string): void {
        setRelativeHumidityTestFormVisible(response === null);
        if (response) {
            onOkFreshConcreteTests?.();
            SuccessNotification({
                message: t`Relative Humidity test created successfully`,
                description: ''
            });
        } else {
            ErrorNotification({
                message: t`Relative Humidity test could not be created`,
                description: error ?? t`Unknown error`
            });
        }
    }

    async function createMultipleConcreteTestInput(): Promise<InputMultipleConcreteTest> {
        const inputTemperatureTest =
            temperatureTestFormVisible && (await isFormValid(temperatureTestForm))
                ? createTemperatureTestInput()
                : undefined;
        const inputConsistencyTest =
            consistencyTestFormVisible && (await isFormValid(consistencyTestForm))
                ? createConcistencyTestInput()
                : undefined;
        const inputTestSpecimen =
            testSpecimenTestFormVisible && (await isFormValid(testSpecimenTestForm))
                ? createTestSpecimenInput()
                : undefined;
        const inputAirContentTest =
            airContentFormVisible && (await isFormValid(airContentTestForm)) ? createAirContentTestInput() : undefined;
        const inputWindSpeedTest =
            windSpeedFormVisible && (await isFormValid(windSpeedTestForm)) ? createWindSpeedTestInput() : undefined;
        const inputRelativeHumidityTest =
            relativeHumidityFormVisible && (await isFormValid(relativeHumidityTestForm))
                ? createRelativeHumidityTestInput()
                : undefined;

        return {
            inputTemperatureTest,
            inputConsistencyTest,
            inputTestSpecimen,
            inputAirContentTest,
            inputWindSpeedTest,
            inputRelativeHumidityTest
        };
    }

    function createTemperatureTestInput(): TemperatureTest {
        const temperatureTestModalFormValues = getTemperatureFormValues(temperatureTestForm, marketId as MARKET_VALUES);
        const commonFormValues = getFormCommonValues(commonForm);

        const temperatureTest = createTemperatureTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            {
                ...commonFormValues,
                testType: ConcreteTestingTypes.Temperature
            },
            temperatureTestModalFormValues,
            marketId
        );

        return temperatureTest;
    }

    function createConcistencyTestInput(): ConsistencyTest {
        const consistencyTestFormValues = getConsistencyFormValues(consistencyTestForm, marketId as MARKET_VALUES);
        const commonFormValues = getFormCommonValues(commonForm);

        const consistencyTest = createConsistencyTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            {
                ...commonFormValues,
                testType: ConcreteTestingTypes.Consistency
            },
            consistencyTestFormValues,
            marketId
        );

        return consistencyTest;
    }

    function createTestSpecimenInput(): TestSpecimenBP {
        const commonFormValues = getFormCommonValues(commonForm);
        const specimenTestFormValues = getSpecimentTestFormValues(testSpecimenTestForm, marketId as MARKET_VALUES);

        const specimenTest = createSpecimenTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            {
                ...commonFormValues,
                testType: ConcreteTestingTypes.Specimen
            },
            specimenTestFormValues,
            marketId
        );

        return specimenTest;
    }

    function createAirContentTestInput(): AirContentTest {
        const airContentFormValues = getAirContentFormValues(airContentTestForm, marketId as MARKET_VALUES);
        const commonFormValues = getFormCommonValues(commonForm);

        const airContentTest = createAirContentTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            {
                ...commonFormValues,
                testType: ConcreteTestingTypes.AirContent
            },
            airContentFormValues,
            marketId
        );

        return airContentTest;
    }

    function createWindSpeedTestInput(): WindSpeedTest {
        const windSpeedFormValues = getWindSpeedFormValues(windSpeedTestForm);
        const commonFormValues = getFormCommonValues(commonForm);

        const windSpeedTest = createWindSpeedTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            {
                ...commonFormValues,
                testType: ConcreteTestingTypes.WindSpeed
            },
            windSpeedFormValues
        );

        return windSpeedTest;
    }

    function createRelativeHumidityTestInput(): RelativeHumidityTest {
        const relativeHumidityFormValues = getRelativeHumidityFormValues(relativeHumidityTestForm);
        const commonFormValues = getFormCommonValues(commonForm);

        const relativeHumidityTest = createRelativeHumidityTest(
            companyId,
            projectId,
            orderId,
            concreteLoadId,
            {
                ...commonFormValues,
                testType: ConcreteTestingTypes.RelativeHumidity
            },
            relativeHumidityFormValues
        );

        return relativeHumidityTest;
    }

    function formReset(): void {
        setOpen(false);
        setVisiblePage(0);

        setTemperatureTestFormVisible(false);
        setConsistencyTestFormVisible(false);
        setTestSpecimenTestFormVisible(false);
        setAirContentTestFormVisible(false);
        setWindSpeedTestFormVisible(false);
        setRelativeHumidityTestFormVisible(false);

        resetInitialFormValuesConcreteTestModal?.();
    }

    function formOnCancel(): void {
        formReset();
    }

    useEffect(() => {
        commonForm.setFieldsValue(createInitialCommonValues(initialFormValues, marketId as MARKET_VALUES)); // we need to manually set it initialValues does not work even with destroyOnClose and preserve on each reopen - see https://github.com/ant-design/ant-design/issues/22171
    }, [initialFormValues, commonForm, marketId]);

    return (
        <Modal
            maskClosable={false}
            title={visiblePage === 0 ? t`Record concrete test` : t`Record concrete test(s)`}
            open={open}
            destroyOnClose
            width={500}
            onOk={formOnOk}
            onCancel={formOnCancel}
            footer={[
                <Button data-testid="MultipleConcreteTestModal.cancelButton" key="cancelButton" onClick={formOnCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    data-testid="MultipleConcreteTestModal.okButton"
                    key="okButton"
                    onClick={formOnOk}
                    type="primary"
                    disabled={!enableConfirmButton}>
                    {visiblePage === 0 ? <Trans>Next</Trans> : <Trans>Confirm</Trans>}
                </Button>
            ]}>
            <Row>
                <Steps current={visiblePage}>
                    <Step className={styles.step} title={t`Concrete test parameters`} />
                    <Step className={styles.step} title={t`Test type`} />
                </Steps>
            </Row>

            <br />

            {visiblePage === 0 ? (
                <Form form={commonForm} layout="vertical">
                    <CommonTestForm />
                </Form>
            ) : (
                <>
                    {temperatureTestFormVisible && (
                        <>
                            {' '}
                            <Separator />
                            <Form form={temperatureTestForm} layout="vertical">
                                <TemperatureTestForm />
                            </Form>
                        </>
                    )}
                    {consistencyTestFormVisible && (
                        <>
                            {' '}
                            <Separator />
                            <Form form={consistencyTestForm} layout="vertical">
                                <ConsistencyTestForm />
                            </Form>
                        </>
                    )}

                    {airContentFormVisible && (
                        <>
                            {' '}
                            <Separator />
                            <Form form={airContentTestForm} layout="vertical">
                                <AirContentTestForm />
                            </Form>
                        </>
                    )}

                    {windSpeedFormVisible && (
                        <>
                            {' '}
                            <Separator />
                            <Form form={windSpeedTestForm} layout="vertical">
                                <WindSpeedTestForm />
                            </Form>
                        </>
                    )}

                    {relativeHumidityFormVisible && (
                        <>
                            {' '}
                            <Separator />
                            <Form form={relativeHumidityTestForm} layout="vertical">
                                <RelativeHumidityTestForm />
                            </Form>
                        </>
                    )}

                    {testSpecimenTestFormVisible && (
                        <>
                            {' '}
                            <Separator />
                            <Form form={testSpecimenTestForm} layout="vertical">
                                <TestSpecimenTestForm
                                    form={testSpecimenTestForm}
                                    showErrorTestSpecimAlreadyExists={showErrorTestSpecimAlreadyExists}
                                    setShowErrorTestSpecimAlreadyExists={setShowErrorTestSpecimAlreadyExists}
                                />
                            </Form>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
}

export default MultipleConcreteTestModal;

async function isFormValid(form: FormInstance): Promise<boolean> {
    return await form
        .validateFields()
        .then((value) => value)
        .catch(() => false);
}

export const util = { isFormValid };
