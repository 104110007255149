import { FormOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Direction } from '@nexploretechnology/bcqd-client';
import { ConcretedStructuralElementForConcretingDocumentationBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ConcretingDeleiveryNoteDocumentationPagination } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import { Menu, MenuProps } from 'antd';
import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';

interface IActionMenuProps {
    record: ConcretedStructuralElementForConcretingDocumentationBP;
    onEditPostTreatment: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
    onEditStructuralElement: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
}

export function ActionMenu({ record, onEditPostTreatment, onEditStructuralElement }: IActionMenuProps) {
    return (
        <Menu
            items={[
                {
                    key: `${record.structuralElementId} Edit post-treatment`,
                    label: <Trans>Edit Post-treatment</Trans>,
                    icon: <FormOutlined />,
                    onClick: () => onEditPostTreatment(record)
                },
                {
                    key: `${record.structuralElementId} Edit structural element`,
                    label: <Trans>Edit structural element</Trans>,
                    icon: <FormOutlined />,
                    onClick: () => onEditStructuralElement(record)
                }
            ]}
        />
    );
}

export function getActionMenuItems({
    record,
    onEditPostTreatment,
    onEditStructuralElement
}: IActionMenuProps): MenuProps['items'] {
    return [
        {
            key: `${record.structuralElementId} Edit post-treatment`,
            label: <Trans>Edit Post-treatment</Trans>,
            icon: <FormOutlined />,
            onClick: () => onEditPostTreatment(record)
        },
        {
            key: `${record.structuralElementId} Edit structural element`,
            label: <Trans>Edit structural element</Trans>,
            icon: <FormOutlined />,
            onClick: () => onEditStructuralElement(record)
        }
    ];
}

export const parseQueryParamsPerMarket = (
    marketId: MARKET_VALUES,
    params: OffsetPagination<ConcretedStructuralElementForConcretingDocumentationBP>
) => {
    if (marketId === MARKETS.AUS) {
        const sorting = params.sorting ? params.sorting[0] : undefined;
        if (sorting) {
            for (const key in sorting) {
                sorting[key as keyof typeof sorting] = sorting[key as keyof typeof sorting].toLowerCase() as Direction;
            }
        }

        return {
            ...params,
            sorting: { ...sorting }
        } as ConcretingDeleiveryNoteDocumentationPagination;
    }

    return params;
};

export const mapFilters = (filter: string[]) => filter?.map((f) => ({ text: f, value: f })) ?? [];
