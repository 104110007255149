/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-indent */
/* eslint-disable prettier/prettier */
/* eslint-disable react/self-closing-comp */
/* eslint-disable prefer-template */
import { EllipsisOutlined } from '@ant-design/icons';
import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete.load-client/concrete.load.dto';
import {
    ConcretedStructuralElementForConcretingDocumentationBP,
    PostTreatmentTypes
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import ExposureClassesFormatter from 'src/app-react/components/Formatters/ExposureClassesFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import {
    dateFormatter,
    dateTimeFormatter,
    daysTranslationSelector,
    formatNumberValueToLanguage,
    postTreatmentTypeMapper,
    weatherMapper
} from 'src/app-react/utils/formatters';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { getActionMenuItems } from '../../util';

interface ConcretingDocumentationTableColumnsDEProps {
    onDisplayCoseModal: (structuralElementId: string) => void;
    onDisplayConcreteTypeModal: (supplierProducedConcrete: SupplierProducedConcrete) => void;
    onDisplayPostTreatmentModal: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
    onEditPostTreatment: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
    onEditStructuralElement: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
}

function isHidden(column: ExtendedColumnType<ConcretedStructuralElementForConcretingDocumentationBP>): boolean {
    if (column.hidden) {
        return true;
    }

    if (column.children) {
        return column.children.some(isHidden);
    }

    return false;
}

export const concretingDocumentationTableColumnsDE: (
    props: ConcretingDocumentationTableColumnsDEProps
) => ColumnsType<ConcretedStructuralElementForConcretingDocumentationBP> = (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<ConcretedStructuralElementForConcretingDocumentationBP>[] = [
        {
            title: <Trans>Time of concreting</Trans>,
            children: [
                {
                    title: <Trans>Concreting start</Trans>,
                    key: 'concretingStartDate',
                    dataIndex: 'concretingStartDate',
                    width: 130,
                    sorter: true,
                    render: (
                        concretingStartDate: Date,
                        record: ConcretedStructuralElementForConcretingDocumentationBP
                    ) => formatConcretingDocumentationDates(concretingStartDate, record.weather)
                },
                {
                    title: <Trans>Concreting end</Trans>,
                    key: 'concretingEndDate',
                    dataIndex: 'concretingEndDate',
                    width: 130,
                    sorter: true,
                    render: (concretingEndDate: Date, record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                        formatConcretingDocumentationDates(concretingEndDate, record.weather)
                }
            ]
        },
        {
            title: <Trans>Structural element parameters</Trans>,
            children: [
                {
                    title: <Trans>Structural element</Trans>,
                    key: 'structuralElementName',
                    dataIndex: 'structuralElementName',
                    sorter: true,
                    width: 200,
                    render: (
                        structuralElementName: string,
                        row: ConcretedStructuralElementForConcretingDocumentationBP
                    ) => (
                        <RawLinkButton onClick={() => props.onDisplayCoseModal(row.structuralElementId)}>
                            {structuralElementName}
                        </RawLinkButton>
                    )
                },
                {
                    title: <Trans>Concrete Type</Trans>,
                    key: 'supplierOrderedTypes',
                    dataIndex: 'supplierOrderedTypes',
                    sorter: true,
                    width: 200,
                    render: (supplierOrderedTypes: SupplierProducedConcrete[]) =>
                        !supplierOrderedTypes || supplierOrderedTypes.length === 0
                            ? ''
                            : supplierOrderedTypes.map(
                                  (supplierOrderedType: SupplierProducedConcrete, index: number) => (
                                      <RawLinkButton
                                          style={{ display: 'block' }}
                                          onClick={() => props.onDisplayConcreteTypeModal(supplierOrderedType)}
                                          key={index}>
                                          {supplierOrderedType.number}
                                      </RawLinkButton>
                                  )
                              )
                },
                {
                    title: <Trans>Mixing plants</Trans>,
                    key: 'mixingPlants',
                    width: 120,
                    sorter: true,
                    dataIndex: 'mixingPlants',
                    render: (mixingPlants: string[]) =>
                        !mixingPlants || mixingPlants.length === 0
                            ? ''
                            : mixingPlants.map((mixingPlant: string, index: number) => <p key={index}>{mixingPlant}</p>)
                },
                {
                    title: <Trans>Total quantity</Trans>,
                    key: 'totalQuantity',
                    width: 120,
                    sorter: true,
                    dataIndex: 'totalQuantity',
                    render: (totalQuantity: number, record: ConcretedStructuralElementForConcretingDocumentationBP) => (
                        <NumberFormatter value={totalQuantity} unit={record.volumeUnit} />
                    )
                },
                {
                    title: <Trans>Compressive strength class</Trans>,
                    key: 'typeOfConcrete',
                    dataIndex: 'typeOfConcrete',
                    width: 130,
                    sorter: true,
                    render: (typeOfConcrete: string, record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                        typeOfConcrete && record.cubicCompressiveStrength && record.cylindricCompressiveStrength ? (
                            <p>
                                {`${record.typeOfConcrete} ${record.cylindricCompressiveStrength}/${record.cubicCompressiveStrength}`}
                            </p>
                        ) : (
                            <p data-testid="emptyAirTemp" />
                        )
                },
                {
                    title: <Trans>Exposure class</Trans>,
                    key: 'exposureClasses',
                    dataIndex: 'exposureClasses',
                    width: 130,
                    render: (_: string, record: ConcretedStructuralElementForConcretingDocumentationBP) => (
                        <ExposureClassesFormatter
                            x0={record.requiredX0}
                            xa={record.requiredXa}
                            xc={record.requiredXc}
                            xd={record.requiredXd}
                            xfFirst={record.requiredXfFirst}
                            xfSecond={record.requiredXfSecond}
                            xm={record.requiredXm}
                            xs={record.requiredXs}
                        />
                    )
                },
                {
                    title: <Trans>Moisture class</Trans>,
                    key: 'moistureClass',
                    dataIndex: 'moistureClass',
                    width: 200,
                    sorter: true,
                    render: (moistureClass: string) => moistureClass || ''
                },

                {
                    title: <Trans>Air temp. (Min/Max)</Trans>,
                    key: 'minAirTemperature',
                    dataIndex: 'minAirTemperature',
                    width: 130,
                    sorter: true,
                    render: (
                        minAirTemperature: number,
                        record: ConcretedStructuralElementForConcretingDocumentationBP
                    ) =>
                        (minAirTemperature || minAirTemperature === 0) &&
                        (record.maxAirTemperature || record.maxAirTemperature === 0) &&
                        record.temperatureUnit ? (
                            <p>
                                {formatNumberValueToLanguage(record.minAirTemperature + '') +
                                    '/' +
                                    formatNumberValueToLanguage(record.maxAirTemperature) +
                                    ' ' +
                                    record.temperatureUnit}
                            </p>
                        ) : (
                            <p data-testid="emptyAirTemp"></p>
                        )
                },
                {
                    title: <Trans>Weather</Trans>,
                    key: 'weather',
                    dataIndex: 'weather',
                    width: 130,
                    sorter: false,
                    render: (weather: string[]) => (
                        <>
                            {weather.map((weatherEntry: string, index: number) => (
                                <p key={index}> {weatherMapper[weatherEntry]} </p>
                            ))}
                        </>
                    )
                }
            ]
        },
        {
            title: <Trans>Post treatment</Trans>,
            children: [
                {
                    title: <Trans>Post-treatment types</Trans>,
                    key: 'ptTypes',
                    dataIndex: 'ptTypes',
                    width: 200,
                    sorter: false,
                    render: (ptTypes: PostTreatmentTypes[]) =>
                        Array.isArray(ptTypes) &&
                        ptTypes.map((targetType: PostTreatmentTypes, index: number) => (
                            <p key={index}> {postTreatmentTypeMapper[targetType]} </p>
                        ))
                },
                {
                    title: <Trans>Minimal duration</Trans>,
                    key: 'targetNumberOfDays',
                    dataIndex: 'targetNumberOfDays',
                    width: 140,
                    sorter: true,
                    render: (
                        targetNumberOfDays: number,
                        record: ConcretedStructuralElementForConcretingDocumentationBP
                    ) => (
                        <RawLinkButton onClick={() => props.onDisplayPostTreatmentModal(record)}>
                            {daysTranslationSelector(targetNumberOfDays)}
                        </RawLinkButton>
                    )
                },
                {
                    title: <Trans>Actual duration</Trans>,
                    key: 'actualNumberOfDays',
                    dataIndex: 'actualNumberOfDays',
                    width: 130,
                    sorter: true,
                    render: (
                        actualNumberOfDays: number,
                        record: ConcretedStructuralElementForConcretingDocumentationBP
                    ) => (
                        <RawLinkButton onClick={() => props.onDisplayPostTreatmentModal(record)}>
                            {daysTranslationSelector(actualNumberOfDays)}
                        </RawLinkButton>
                    )
                },
                {
                    title: <Trans>Equipping/stripping</Trans>,
                    key: 'equippingOrStripping',
                    dataIndex: 'number',
                    width: 200,
                    sorter: true,
                    render: (_, record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                        dateFormatter(record.equippingOrStripping, { dateStyle: 'short' })
                },
                {
                    title: <Trans>Measurements</Trans>,
                    key: 'measurements',
                    dataIndex: 'measurements',
                    width: 170,
                    sorter: true,
                    render: (measurements: number) =>
                        measurements ? (
                            <Tag icon={<CheckCircleOutlined />} color="success">
                                <Trans>Recorded</Trans>
                            </Tag>
                        ) : (
                            <Tag icon={<ExclamationCircleOutlined />} color="warning">
                                <Trans>Unrecorded</Trans>
                            </Tag>
                        )
                }
            ]
        },
        {
            title: <Trans>Concrete tests</Trans>,
            children: [
                {
                    title: <Trans>Fresh concrete tests</Trans>,
                    key: 'freshConcreteTests',
                    dataIndex: 'freshConcreteTests',
                    width: 200,
                    sorter: true,
                    render: (freshConcreteTests: number) => freshConcreteTests || ''
                },
                {
                    title: <Trans>Test specimen</Trans>,
                    key: 'testSpecimen',
                    dataIndex: 'testSpecimen',
                    width: 130,
                    sorter: true,
                    render: (testSpecimen: number) => testSpecimen || ''
                }
            ]
        },
        {
            title: '',
            children: [
                {
                    title: <Trans>Actions</Trans>,
                    fixed: 'right',
                    key: 'actions',
                    dataIndex: 'actions',
                    hidden: isViewer,
                    width: 130,
                    render: (_, record: ConcretedStructuralElementForConcretingDocumentationBP) => {
                        const items: MenuProps['items'] = getActionMenuItems({
                            record,
                            onEditPostTreatment: props.onEditPostTreatment,
                            onEditStructuralElement: props.onEditStructuralElement
                        });
                        return (
                            <Dropdown key="actionEdit" data-testid="actions" menu={{ items }} trigger={['click']}>
                                <EllipsisOutlined />
                            </Dropdown>
                        );
                    }
                }
            ]
        }
    ];

    return columns.filter((item) => !isHidden(item));
};

export function formatConcretingDocumentationDates(concretingDate: Date, weather: string | string[]) {
    if (!concretingDate) {
        return null;
    }

    if (weather && weather.length > 0) {
        return <p>{`${dateTimeFormatter(concretingDate, { dateStyle: 'short', timeStyle: 'short' })}`}</p>;
    } else {
        return <p>{`${dateTimeFormatter(concretingDate, { dateStyle: 'short' })}`}</p>;
    }
}
