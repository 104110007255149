import { Direction, OffsetPagination } from '@nexploretechnology/concreting-core-client';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import TestSpecimenModal from 'src/app-react/business-page/concreting-documentation/components/TestSpecimenDetailModal/TestSpecimenDetailModal';
import ConcreteTypeModal from 'src/app-react/components/Modals/ConcreteTypeModalForDocumentation/ConcreteTypeModal';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { noPagination } from 'src/app-react/utils/pagination';
import { concretedLoadTableColumnsDE } from './dataTableConcretedLoad.columns.de';
import { concretedLoadTableColumnsUS } from './dataTableConcretedLoad.columns.us';

import {
  ConcretedLoadForConcretingDocumentationBP,
  SupplierProducedConcrete
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import useApi from 'src/app-react/hooks/useApi';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import css from './concretedLoad.module.css';

export interface IDataTableConcretedLoad {
    structuralElementId: string;
}

const pageSize = 10;

function DataTableConcretedLoad(props: IDataTableConcretedLoad) {
    const { structuralElementId } = props;
    const { documentationConcreteLoadPaginationState, setGlobalState } = useGlobalState();
    const { projectId, companyId, marketId } = useProjectState();
    const [concretedLoad, setConcretedLoad] = useState([] as ConcretedLoadForConcretingDocumentationBP[]);
    const [total, setTotal] = useState(0);
    const [pagination, setPagination] = useState(
        documentationConcreteLoadPaginationState || ({} as TablePaginationConfig)
    );
    const [selectedTestSpecimen, setSelectedTestSpecimen] = useState('');
    const [concreteTypenDetailsData, setConcreteTypeData] = useState<SupplierProducedConcrete>(
        {} as SupplierProducedConcrete
    );
    const [isSpecimenModalVisible, setIsSpecimenModalVisible] = useState(false);
    const [isConcreteTypenModalVisible, setConcreteTypeModalVisible] = useState(false);
    const api = useApi();

    const navigate: NavigateFunction = useNavigate();

    function keyMapFromTableData(
        tableData: ConcretedLoadForConcretingDocumentationBP[]
    ): ConcretedLoadForConcretingDocumentationBP[] {
        return tableData.map((value: ConcretedLoadForConcretingDocumentationBP, key: number) => {
            return { ...value, key };
        });
    }

    const getSupplierProducedConcrete = async (
        supplierProducedConcreteLoadDataId: string
    ): Promise<SupplierProducedConcrete | null> => {
        try {
            const response = await api.concretingDocumentationClient.getSupplierProducedConcreteBySupplierLoadId(
                companyId,
                projectId,
                supplierProducedConcreteLoadDataId
            );
            if (response.isSuccess()) {
                return response.getEntity();
            }
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
        return null;
    };

    const getConcretedLoad = async (
        pagination: TablePaginationConfig,
        sorter:
            | SorterResult<ConcretedLoadForConcretingDocumentationBP>
            | SorterResult<ConcretedLoadForConcretingDocumentationBP>[]
    ): Promise<void> => {
        try {
            sorter = sorter as SorterResult<ConcretedLoadForConcretingDocumentationBP>;
            let sorting: Partial<Record<keyof ConcretedLoadForConcretingDocumentationBP, Direction>> | undefined;

            if (sorter.columnKey && sorter.order) {
                const sortDirection: string = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
                const sortingValue = `{"${
                    (sorter as SorterResult<ConcretedLoadForConcretingDocumentationBP>).columnKey
                }": "${sortDirection || 'DESC'}"}`;
                sorting = JSON.parse(sortingValue);
            }

            const queryParams: OffsetPagination<ConcretedLoadForConcretingDocumentationBP> = {
                ...noPagination,
                sorting: sorting ? [sorting!] : []
            };

            await api.concretingDocumentationClient
                .loadConcretedLoadsForConcretingDocDataList(projectId, companyId, structuralElementId, queryParams)
                .then((response) => {
                    if (response.isSuccess()) {
                        const concreteLoad = response.getEntity();
                        setConcretedLoad(concreteLoad.data);
                        setTotal(concreteLoad.count);
                        setPagination({
                            ...documentationConcreteLoadPaginationState,
                            total
                        } as TablePaginationConfig);
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };
    const displayTestSpecimenModal = (testSpecimen: string): void => {
        setSelectedTestSpecimen(testSpecimen);
        setIsSpecimenModalVisible(true);
    };

    const displayConcreteTypeModal = async (record: ConcretedLoadForConcretingDocumentationBP): Promise<void> => {
        const supplierProducedConcrete: SupplierProducedConcrete | null = await getSupplierProducedConcrete(
            record.supplierConcreteLoadDataId
        );
        if (!supplierProducedConcrete) return;

        setConcreteTypeData(supplierProducedConcrete);
        setConcreteTypeModalVisible(true);
    };

    const onDeliveryNoteNumberClick = (record: ConcretedLoadForConcretingDocumentationBP): void => {
        navigate(
            `/projects/${projectId}/orders/${record.orderId}/delivery-notes-details/${record.concreteLoadId}/details`
        );
    };

    async function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter:
            | SorterResult<ConcretedLoadForConcretingDocumentationBP>
            | SorterResult<ConcretedLoadForConcretingDocumentationBP>[],
        extra: TableCurrentDataSource<ConcretedLoadForConcretingDocumentationBP>
    ) {
        setGlobalState({ documentationConcreteLoadPaginationState: pagination });
        getConcretedLoad(pagination, sorter);
    }

    useEffect(() => {
        if (structuralElementId) {
            getConcretedLoad(pagination, []);
        }
    }, [structuralElementId, projectId, companyId]);

    return (
        <>
            {marketId === 'DE' ? (
                <Table
                    data-testid="concrete-load-table"
                    scroll={{ x: 1900 }}
                    pagination={pagination}
                    columns={concretedLoadTableColumnsDE(
                        navigate,
                        setGlobalState,
                        projectId,
                        displayTestSpecimenModal,
                        displayConcreteTypeModal,
                        onDeliveryNoteNumberClick,
                        true
                    )}
                    dataSource={keyMapFromTableData(concretedLoad)}
                    onChange={onChange}
                    rowClassName="concreting-load-row"
                    onHeaderRow={(col, index) => {
                        return {
                            className: !index ? css.headerRow : css.secondHeaderRow
                        };
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            className: css.row
                        };
                    }}
                />
            ) : (
                <Table
                    data-testid="concrete-load-table"
                    scroll={{ x: 1900 }}
                    pagination={pagination}
                    columns={concretedLoadTableColumnsUS(
                        navigate,
                        setGlobalState,
                        projectId,
                        displayTestSpecimenModal,
                        displayConcreteTypeModal,
                        onDeliveryNoteNumberClick,
                        true
                    )}
                    dataSource={keyMapFromTableData(concretedLoad)}
                    onChange={onChange}
                    rowClassName="concreting-load-row"
                    onHeaderRow={(col, index) => {
                        return {
                            className: !index ? css.headerRowUS : css.secondHeaderRowUS
                        };
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            className: css.rowUS
                        };
                    }}
                />
            )}
            {isSpecimenModalVisible && (
                <TestSpecimenModal
                    specimenIdentifier={selectedTestSpecimen}
                    isModalVisible={isSpecimenModalVisible}
                    setIsModalVisible={setIsSpecimenModalVisible}
                />
            )}

            {isConcreteTypenModalVisible && (
                <ConcreteTypeModal
                    concreteDetails={concreteTypenDetailsData}
                    open={isConcreteTypenModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                />
            )}
        </>
    );
}

export default DataTableConcretedLoad;
