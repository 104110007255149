import { MARKETS } from 'src/app-react/constants/markets';
import { exposureClassesFormatter } from 'src/app-react/utils/formatters';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { OrderDependencies } from '../../models/OrderDependencies';

import {
    OrderFields, 
    OrderFieldsDE, 
    OrderFieldsUS,
} from '../../models/OrderFields';

import { 
    OrderPreview, 
    OrderPreviewCommon, 
    OrderPreviewDE, 
    OrderPreviewUS,
} from '../../models/OrderPreview';
import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

function getStrengthClassDE(concreteType: ConcreteTypeExtendedBP): string {
    let strengthClass: string = '';

    if (concreteType.typeOfConcrete) {
        strengthClass += `${concreteType.typeOfConcrete}`;
    }

    if (concreteType.cylindricCompressiveStrength) {
        strengthClass += ` ${concreteType.cylindricCompressiveStrength}`;
    }

    if (concreteType.cubicCompressiveStrength) {
        strengthClass += ` / ${concreteType.cubicCompressiveStrength}`;
    }

    return strengthClass;
}

function getStrengthClassUS(concreteType: ConcreteTypeExtendedBP): string {
    let strengthClass: string = '';

    if (concreteType.compressiveStrength) {
        strengthClass += `${concreteType.compressiveStrength}`;
    }

    if (concreteType.compressiveStrengthUnit) {
        strengthClass += ` ${concreteType.compressiveStrengthUnit}`;
    }

    return strengthClass;
}

function getExposureClassesFormatter(concreteType: ConcreteTypeExtendedBP) {
    return exposureClassesFormatter({
        x0: concreteType.x0, 
        xa: concreteType.xa, 
        xc: concreteType.xc, 
        xd: concreteType.xd, 
        xm: concreteType.xm,
        xs: concreteType.xs,
        xfFirst: concreteType.xfFirst,
        xfSecond: concreteType.xfSecond,
    })
}

export function fromOrderFieldsToOrderPreview({
    marketId,
    orderFields,
    dependencies: { 
        concreteType, 
        locations,
        spacingInMinutes,
    }
} : {
    marketId: string,
    orderFields: OrderFields,
    dependencies: OrderDependencies,
}) : OrderPreview {
    const date = orderFields.dateOfConcreting && orderFields.deliveryTime ? mergeDateTime(orderFields.dateOfConcreting, orderFields.deliveryTime).toDate() : undefined;
    const location = locations.find(l => l.id === orderFields.unloadingLocation);

    const commonFields: Partial<OrderPreviewCommon> = {
        deliveryTime: date,
        structuralElement: orderFields.structuralElement,
        typeOfConcrete: concreteType?.concreteTypeNumber ?? '',
        exposureClasses: getExposureClassesFormatter(concreteType),
        maxAggregationSize: concreteType?.maximumAggregateSize ?? 0,
        consistencyClass: concreteType?.consistencyClass?.toString() ?? '',
        consistencyType: concreteType?.consistencyType ?? '',
        strengthDevelopment: concreteType?.strengthDevelopmentClass ?? '',
        concreteQuantity: orderFields.concreteQuantity,
        unloadingLocation: location?.locationName ?? '',
        additionalRequirement: orderFields.additionalRequirement ?? '',
        additionalQuantity: orderFields.additionalQuantity,
        concreteTypeNumber: concreteType?.concreteTypeNumber ?? '',
    }

    switch (marketId) {
        case MARKETS.DE:
            const orderFieldsDE = orderFields as OrderFieldsDE;

            return {
                ...commonFields,
                compressiveStrengthClass: getStrengthClassDE(concreteType),
                outputPerHour: orderFieldsDE.outPerHour,
            } as unknown as OrderPreviewDE;
        case MARKETS.US:
            const orderFieldsUS = orderFields as OrderFieldsUS;
            const spacingInMinutesLabel = spacingInMinutes
                .find(l => l.value === orderFieldsUS.spacingInMinutes)?.label;

            return {
                ...commonFields,
                compressiveStrengthClass: getStrengthClassUS(concreteType),
                spacingInMinutes: spacingInMinutesLabel,
                slump: orderFieldsUS.slump,
                loadSize: orderFieldsUS.loadSize,
            } as OrderPreviewUS;
        default:
            throw new Error(`fromOrderFieldsToOrderPreview: invalid marketId: ${marketId}`);
    }
}
