import { Trans } from '@lingui/macro';
import { PostTreatment } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { PostTreatmentDetailsTableColumns } from './columns';

export function PostTreatmentDetailsTable(props: { structuralElementId: string }) {
    const [postTreatments, setPostTreatments] = useState([] as PostTreatment[]);
    const { projectId, companyId } = useProjectState();
    const [loadTable, setLoadTable] = useState(true);
    const { structuralElementId } = props;
    const api = useApi();

    async function loadPostTreatment() {
        api.concretingDocumentationClient
            .loadStructuralElementPostTreatments(companyId, projectId, structuralElementId)
            .then((response) => {
                if (response.isSuccess()) {
                    const result: PostTreatment[] = response.getEntity().filter((x) => x.deleted !== true);
                    setPostTreatments(result);
                    setLoadTable(false);
                } else {
                    ErrorNotification({ message: response.getError(), description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        loadPostTreatment();
    }, [companyId, projectId]);

    return (
        <>
            {!loadTable && postTreatments.length === 0 ? (
                <Trans>No post-treatments have been recorded.</Trans>
            ) : (
                <Table
                    style={{ marginTop: '1.5rem' }}
                    data-testid="postTreatmentTable"
                    rowClassName="postTreatmentRow"
                    rowKey="id"
                    columns={PostTreatmentDetailsTableColumns()}
                    dataSource={postTreatments}
                    loading={loadTable}
                    pagination={false}
                />
            )}
        </>
    );
}
