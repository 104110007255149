import { Trans } from '@lingui/macro';

const METHOD_CONCRETE_USECHLORIDE = [
    {
        value: 'prestressedConcrete / Cl 0,20',
        label: <Trans>Prestressed concrete / Cl 0,20</Trans>
    },
    {
        value: 'reinforcedConcrete / Cl 0,40',
        label: <Trans>Reinforced concrete  / Cl 0,40</Trans>
    },
    {
        value: 'nonReinforcedConcrete / Cl 1,00',
        label: <Trans>Non-reinforced concrete / Cl 1,00</Trans>
    }
];

export default METHOD_CONCRETE_USECHLORIDE;
