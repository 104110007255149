import * as React from 'react';
export interface DeliveryNoteProviderProps {
    projectId: string;
    orderId: string;
    reloadDeliveryNotePage: () => void;
    reloaded: unknown
}

type ProviderChildren = { projectId: string; orderId: string; children: React.ReactNode };

const DeliveryNoteManagementContext = React.createContext({} as DeliveryNoteProviderProps);

function DeliveryNoteProvider({ projectId, orderId, children }: ProviderChildren) {
  const [reloaded, reloadDeliveryNotePage] = React.useReducer((x) => x + 1, 0);

    const value: DeliveryNoteProviderProps = {
        projectId,
        orderId,
        reloadDeliveryNotePage,
        reloaded
    };
    return <DeliveryNoteManagementContext.Provider value={value}>{children}</DeliveryNoteManagementContext.Provider>;
}

export { DeliveryNoteManagementContext, DeliveryNoteProvider };
