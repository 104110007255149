interface RawLinkButtonProps {
    'onClick': () => void;
    'children': React.ReactNode;
    'data-testid'?: string;
    'className'?: string;
    'key'?: string | number;
    'style'?: React.CSSProperties;
}

function RawLinkButton(props: RawLinkButtonProps) {
    return (
        <a
            href="#/"
            {...props}
            onClick={(e) => {
                props.onClick();
                e.preventDefault();
            }}>
            {props.children}
        </a>
    );
}

export default RawLinkButton;
