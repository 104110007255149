/* eslint-disable react/jsx-props-no-spreading */

import { Trans, t } from '@lingui/macro';
import { SelectFormItemProps } from 'src/app-react/components/Form/FormItem/SelectFormItem';
import { Input } from 'antd';
import InputFormItem from 'src/app-react/components/Form/FormItem/InputFormItem';

function ConstructorNameFormItem(props: SelectFormItemProps) {
    return (
        <InputFormItem
            data-testid="constructorNameFormItem"
            label={<Trans>Customer Name</Trans>}
            name="constructorName"
            {...props}>
            <Input data-testid="constructorName" placeholder={t`Enter Customer Name`} />
        </InputFormItem>
    );
}

export default ConstructorNameFormItem;
