import { notification } from 'antd';

export interface ISuccessNotification {
    message: string;
    description?: string;
}

const SuccessNotification = ({ message, description }: ISuccessNotification) => {
    return notification.success({
        message,
        description: description || ''
    });
};

export default SuccessNotification;
