import { Trans } from '@lingui/macro';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { ColumnsType } from 'antd/lib/table';

export default function supplierInformationTableColumns() {
    const columns: ColumnsType<DeliveryNoteFullDetails> = [
        {
            title: <Trans>Supplier name</Trans>,
            key: 'supplierName',
            dataIndex: 'supplierName',
            render: (_val: string, record) => record?.supplierConcreteLoadData?.supplierName
        },
        {
            title: <Trans>ABN number</Trans>,
            key: 'supplierBusinessNumber',
            dataIndex: 'supplierBusinessNumber',
            render: (_val: string, record) => record?.supplierConcreteLoadData?.supplierBusinessNumber
        },
        {
            title: <Trans>Plant address</Trans>,
            key: 'mixingPlant',
            dataIndex: 'mixingPlant',
            render: (_val: string, record) => record?.supplierConcreteLoadData?.mixingPlant
        }
    ];

    return columns;
}
