const CONSISTENCY_TYPE = [
    {
        value: 'F',
        label: 'F'
    },
    {
        value: 'S',
        label: 'S'
    },
    {
        value: 'C',
        label: 'C'
    },
    {
        value: 'V',
        label: 'V'
    }
];

export default CONSISTENCY_TYPE;
