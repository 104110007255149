import { QuestionCircleOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { InputUpdateStatusConcreteLoad } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import React, { ChangeEvent, useContext, useEffect } from 'react';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { getAuthService } from 'src/app-react/services/authService';
import { mergeDateTime } from '../../../../utils/lib';
import DeliveryNoteDetailsContext from '../../context/delivery-note-details-context';
import useContextOrProps from '../../hooks/useContextOrProps';
import { DeliveryNoteManagementContext } from '../../providers/delivery.note.provider';
import styles from './EndUnloadingModal.module.css';

// TODO: NB-3986 import AcceptAndSignDeliveryModal from 'src/app-react/components/Modal/AcceptAndSignDeliveryModalForm/AcceptAndSignModal';

export interface IEndUnloadingForm {
    time: moment.Moment;
    date: moment.Moment;
    comment: string;
}

const marketIdDE = 'DE';

export interface IEndUnloadingModalProps {
    concreteLoadId?: string;
    deliveryNoteNo: string | undefined;
    licensePlate: string;
    startUnloadingDateTime: Date | undefined;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setAcceptAndSignModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}

function EndUnloadingModal(props: IEndUnloadingModalProps) {
    const { concreteLoadId } = useContextOrProps(['concreteLoadId'], props, DeliveryNoteDetailsContext);
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { companyId, projectId, marketId } = useProjectState();
    const api = useApi();

    const {
        deliveryNoteNo,
        licensePlate,
        startUnloadingDateTime,
        open,
        setOpen,
        setAcceptAndSignModalVisible,
        onComplete
    } = props;
    const [endUnloadingForm] = Form.useForm();
    const startUnloadingMoment: moment.Moment = moment(startUnloadingDateTime);

    useEffect(() => {
        updateValue(new Date());
    }, [open]);

    const updateValue = (date: Date): void => {
        endUnloadingForm.setFieldsValue({ date: moment(date), time: moment(), comment: '' });
    };
    const dateValidator = (rule: RuleObject, date: moment.Moment, callback: (error?: string) => void) => {
        const time: moment.Moment = endUnloadingForm.getFieldValue('time');
        const dateTimeAction: moment.Moment = mergeDateTime(date, time);
        if (dateTimeAction.isSameOrAfter(startUnloadingMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const timeValidator = (rule: RuleObject, time: moment.Moment, callback: (error?: string) => void) => {
        const { date } = endUnloadingForm.getFieldsValue();
        const dateTimeAction: moment.Moment = mergeDateTime(date, time);
        if (dateTimeAction.isSameOrAfter(startUnloadingMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const disabledDateHandler = (current: moment.Moment | null): boolean => {
        if (!current) {
            return false;
        }
        return current.isAfter(moment(), 'day') || current.isBefore(startUnloadingMoment, 'day');
    };

    const commentHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
        endUnloadingForm.setFieldsValue({ comment: e.target.value });
    };

    const onEndUnloadingSubmit = async (): Promise<boolean> => {
        try {
            const { date, time, comment }: IEndUnloadingForm = await endUnloadingForm.validateFields();
            setOpen(false);
            const { userId } = getAuthService().user;
            const payload: InputUpdateStatusConcreteLoad = {
                status: 'Unloaded',
                dateTimeAction: mergeDateTime(date, time).toDate(),
                inputCommentConcreteLoad: comment
                    ? {
                          concreteLoadId,
                          comment,
                          actionConcreteLoad: 'endUnloading',
                          createdBy: userId
                      }
                    : undefined
            };

            api.deliveryNoteClient
                .updateConcreteLoadById(companyId, projectId, orderId, concreteLoadId, payload)
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`End unloading updated successfully`,
                            description: ''
                        });
                        endUnloadingForm.resetFields();
                        onComplete?.();
                    } else {
                        ErrorNotification({
                            message: response.getError(),
                            description: ''
                        });
                    }
                });
            return true;
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
            return false;
        }
    };

    const onEndUnloadingAndAcceptAndSignSubmit = async (): Promise<void> => {
        try {
            if (await onEndUnloadingSubmit()) {
                setAcceptAndSignModalVisible(true);
            }
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    const onCancel = (): void => {
        endUnloadingForm.resetFields();
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            data-testid="unloaded-modal"
            key={`end-unloading-modal-${concreteLoadId}`}
            forceRender
            title={t`End Unloading`}
            open={open}
            onCancel={onCancel}
            onOk={onEndUnloadingSubmit}
            width={1000}
            footer={[
                <>
                    <Button
                        className={styles.modalButton}
                        onClick={onEndUnloadingSubmit}
                        data-testid="submit"
                        type="primary"
                        key={`submit-btn-${concreteLoadId}`}>
                        <Trans>End Unloading</Trans>
                    </Button>
                    <Button
                        className={styles.modalButton}
                        onClick={() => onEndUnloadingAndAcceptAndSignSubmit()}
                        data-testid="submit-acept-and-sign"
                        type="primary"
                        key={`submit-acept-and-sign-btn-${concreteLoadId}`}>
                        {marketId === marketIdDE ? (
                            <Trans>End Unloading &amp; accept and sign</Trans>
                        ) : (
                            <Trans>End Unloading &amp; accept delivery</Trans>
                        )}
                    </Button>
                </>
            ]}>
            <Form
                form={endUnloadingForm}
                data-testid="EndUnloadingForm"
                key={`modal-form-${concreteLoadId}`}
                initialValues={{ date: moment(), time: moment(), comment: '' }}>
                <Trans>Please enter the time when the truck ends unloading.</Trans>
                <Row gutter={8}>
                    <Col span="11">
                        <Row>
                            <label className={styles.modalLabel}>
                                {marketId === marketIdDE ? (
                                    <Trans>Delivery note number</Trans>
                                ) : (
                                    <Trans>Ticket number</Trans>
                                )}
                            </label>
                        </Row>
                        <Row>
                            <p>{deliveryNoteNo}</p>
                        </Row>
                    </Col>
                    <Col span="13">
                        <Row>
                            <label className={styles.modalLabel}>
                                {marketId === marketIdDE ? <Trans>License Plate</Trans> : <Trans>Truck number</Trans>}
                            </label>
                        </Row>
                        <Row>
                            <p>{licensePlate}</p>
                        </Row>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span="11">
                        <Form.Item
                            label={<Trans>End Date</Trans>}
                            className={styles.inline}
                            name="date"
                            rules={[
                                { required: true, message: t`This field is required` },
                                {
                                    message: t`End unloading date must be between start unloading date and now`,
                                    validator: dateValidator
                                }
                            ]}>
                            <DatePicker
                                data-testid="date-input"
                                value={moment()}
                                placeholder={t`Enter Date`}
                                disabledDate={disabledDateHandler}
                            />
                        </Form.Item>
                    </Col>
                    <Col span="13">
                        <Form.Item
                            label={<Trans>End Time</Trans>}
                            className={styles.inline}
                            name="time"
                            rules={[
                                { required: true, message: t`This field is required` },
                                {
                                    message: t`End unloading time must be between start unloading time and now`,
                                    validator: timeValidator
                                }
                            ]}>
                            <TimePicker value={moment()} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span="24">
                        <label className={styles.modalLabel}>
                            <Trans>Comment</Trans>
                            <span>
                                <QuestionCircleOutlined /> (optional)
                            </span>
                        </label>
                        <Form.Item name="comment">
                            <Input.TextArea
                                data-testid="comment-input"
                                placeholder={t`Write a comment e.g. about the concrete mix`}
                                autoSize={{ minRows: 3, maxRows: 5 }}
                                onChange={commentHandler}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default EndUnloadingModal;
