import { t } from '@lingui/macro';
import {
  ConcretedStructuralElementBP,
  ConcretedStructuralElementForPostTreatment
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Button, Card, Col, Form, Row } from 'antd';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { StructuralElementPageContext } from 'src/app-react/business-page/StructuralElementPage/contexts/StructuralElementPageContext';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import { useDiscardChanges } from 'src/app-react/components/DiscardChangesModal/hooks/useDiscardChanges';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { PostTreatmentContext } from '../../contexts/PostTreatmentContext';
import { PostTreatmentSummaryFormDE } from './DE';
import { PostTreatmentSummaryFormUS } from './US';

export interface PostTreatmentSummaryProps {
    loadSummary: boolean;
    triggerReload: () => void;
}

export function PostTreatmentSummaryForm(props: PostTreatmentSummaryProps) {
    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId, marketId } = useProjectState();
    const { unsavedChanges } = useDiscardChanges();
    const { setStructuralElementName } = useContext(StructuralElementPageContext);
    const { concretingEndDate } = useContext(PostTreatmentContext);
    const { loadSummary, triggerReload } = props;
    const [form] = Form.useForm();
    const api = useApi();

    let children: any;

    switch (marketId) {
        case MARKETS.DE:
            children = <PostTreatmentSummaryFormDE />;
            break;
        case MARKETS.US:
        case MARKETS.AUS:
            children = <PostTreatmentSummaryFormUS />;
            break;
        default:
            children = null;
            break;
    }
    function setInitialValues(concretedStructuralElement: ConcretedStructuralElementBP) {
        const initialValues = {
            targetNumberOfDays: concretedStructuralElement.targetNumberOfDays,
            equippingOrStripping: concretedStructuralElement.equippingOrStripping
                ? moment(concretedStructuralElement.equippingOrStripping)
                : null,
            actualNumberOfDays: concretedStructuralElement.actualNumberOfDays
        };

        form.setFieldsValue(initialValues);
    }

    function getConcretedStructuralElement() {
        api.concretingDocumentationClient
            .getConcretedStructuralElementById(companyId, projectId, structuralElementId)
            .then((response) => {
                if (response.isSuccess()) {
                    const result: ConcretedStructuralElementBP = response.getEntity();
                    setStructuralElementName(result.elementName || '');
                    setInitialValues(result);
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        if (loadSummary) {
            getConcretedStructuralElement();
        }
    }, [loadSummary, companyId, projectId]);

    function updateConcretedStructuralElementForPostTreatment(
        concretedStructuralElement: ConcretedStructuralElementForPostTreatment
    ) {
        api.concretingDocumentationClient
            .updateConcretedStructuralElementForPostTreatment(
                companyId,
                projectId,
                structuralElementId,
                concretedStructuralElement
            )
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Post-treatment summary has been updated successfully`,
                        description: ''
                    });
                    unsavedChanges.current = false;
                    triggerReload();
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function onFinish(fieldsValue: ConcretedStructuralElementBP) {
        form.validateFields()
            .then(() => {
                updateConcretedStructuralElementForPostTreatment({
                    id: structuralElementId,
                    targetNumberOfDays: fieldsValue.targetNumberOfDays || null,
                    equippingOrStripping: fieldsValue.equippingOrStripping || null,
                    concretingEnd: moment(concretingEndDate).startOf('day').toDate()
                });
            })
            .catch((e) => {
                ErrorNotification({ message: e.code, description: e.message });
            });
    }

    return (
        <Card style={{ height: 140 }}>
            <Form
                data-testid="structuralElementPostTreatmentForm"
                name="structuralElementPostTreatmentForm"
                layout="vertical"
                style={{ justifyItems: 'center' }}
                form={form}
                onFinish={onFinish}>
                <Row gutter={24}>
                    {children}
                    <Col span={3}>
                        <Row style={{ marginTop: '1.8rem', marginLeft: '1rem' }}>
                            <Button onClick={() => form.submit()} type="primary">
                                {t`Save`}
                            </Button>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
}
