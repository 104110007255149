import { Trans, t } from '@lingui/macro';
import { timeZone } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Form, Select } from 'antd';

export function TimeZoneFormItem() {
    return (
        <Form.Item
            required
            name="timeZone"
            label={t`Timezone`}
            rules={[
                {
                    required: true,
                    message: <Trans>Timezone is a required field.</Trans>
                }
            ]}>
            <Select
                showSearch
                placeholder={t`Select Timezone`}
                defaultActiveFirstOption
                data-testid="projectTimeZone"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                options={timeZone.map((x) => ({ value: x, label: x }))}
                style={{ display: 'inline-block', width: '200%' }}
            />
        </Form.Item>
    );
}
