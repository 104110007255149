import { TypeOfMeasurement } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col, Form, Row } from 'antd';
import { useState } from 'react';
import { CompressiveTypeFormItem } from '../../FormItems/CompressiveTypeFormItem';
import { MeasurementDateFormItem } from '../../FormItems/MeasurementDateFormItem';
import { MeasurementTimeFormItem } from '../../FormItems/MeasurementTimeFormItem';
import { MeasurementValueFormItem } from '../../FormItems/MeasurementValueFormItem';
import { ResultFormItem } from '../../FormItems/ResultFormItem';
import { TestSpecimenFormItem } from '../../FormItems/TestSpecimenFormItem';
import { TypeOtherFormItem } from '../../FormItems/TypeOtherFormItem';

export function CompressiveMeasurememntEditlModalFormLayoutUS() {
    const form = Form.useFormInstance();
    const [compressiveType, setCompressiveType] = useState<TypeOfMeasurement>(form.getFieldValue('type') || undefined);

    return (
        <>
            <Row>
                <Col span={24} style={compressiveType === 'other' ? { marginBottom: '.5rem' } : {}}>
                    <CompressiveTypeFormItem
                        compressiveType={compressiveType}
                        setCompressiveType={setCompressiveType}
                    />
                    {compressiveType === 'other' && <TypeOtherFormItem />}
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={12}>
                    <MeasurementDateFormItem />
                </Col>
                <Col span={12}>
                    <MeasurementTimeFormItem />
                </Col>
            </Row>

            {compressiveType !== 'other' && compressiveType !== 'testSpecimen' && (
                <Row>
                    <Col span={24}>
                        <MeasurementValueFormItem compressiveType={compressiveType} />
                    </Col>
                </Row>
            )}

            <Row>
                <Col span={24}>
                    <ResultFormItem />
                </Col>
            </Row>

            {compressiveType === 'testSpecimen' && (
                <Row>
                    <Col span={24}>
                        <TestSpecimenFormItem />
                    </Col>
                </Row>
            )}
        </>
    );
}
