import { ConcreteTypeBP, ConcreteTypeExtendedBP } from "@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto";
import { ConcreteType } from "../ConcreteTypeModal";
import { OrderOverviewOrderedConcreteType } from "@nexploretechnology/concreting-core-client";

export function transformConcreteType(concreteType: ConcreteType) : ConcreteTypeExtendedBP | OrderOverviewOrderedConcreteType {

    if(concreteType.hasOwnProperty('typeNumber')) {
        const originalConcreteType: ConcreteTypeBP = concreteType as ConcreteTypeBP;
        return {
            ...originalConcreteType,
            concreteTypeNumber : originalConcreteType.typeNumber,
            concreteTypeDescription: originalConcreteType.description,
            maximumAggregateSize: originalConcreteType.maxAggregateSize,
            chlorideContentClass: originalConcreteType.chlorideContent,
            ingredients: originalConcreteType.ingredients,
            methodOfConcreteUse: originalConcreteType.methodOfConcrete
        } as ConcreteTypeExtendedBP;
    }
    return concreteType as ConcreteTypeExtendedBP | OrderOverviewOrderedConcreteType;
}