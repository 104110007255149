/* eslint-disable react/jsx-props-no-spreading */

import { UnloadingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/unloading.location-client/unloading.location.dto';
import { Select, SelectProps, Typography } from 'antd';
import { useMemo } from 'react';

const { Text } = Typography;

export function LocationSelect({ locations, ...selectProps }: { locations: UnloadingLocationBP[] } & SelectProps) {
    const options = useMemo(
        () =>
            locations.map((location) => {
                const title = `${location.locationName}, ${location.locationDescription}, ${location.locationType}`;

                return {
                    key: location.id,
                    value: location.id,
                    label: (
                        <Text data-testid={location.id} ellipsis title={title}>
                            {title}
                        </Text>
                    )
                };
            }),
        [locations]
    );

    return <Select options={options} {...selectProps} />;
}
