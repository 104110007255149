import { useLingui } from '@lingui/react';
import { useEffect } from 'react';

const BugButton = () => {
    const { i18n } = useLingui();

    useEffect(() => {
        const script1 = document.createElement('script');

        const locale = i18n.locale === 'de-DE' ? 'de-DE' : 'en-US';
        const formId = i18n.locale === 'de-DE' ? '224d885a' : '224d885a';
        const form: string = `https://nexplore.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-orl6br/b/5/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=${locale}&collectorId=${formId}`;

        script1.async = true;

        script1.src = form;

        document.body.appendChild(script1);

        return () => {
            document.body.removeChild(script1);
        };
    }, [i18n]);

    return i18n.locale === 'de-DE' ? (
        <button id="bug_button" className="feedback-button">
            Fehler
        </button>
    ) : (
        <button id="bug_button" className="feedback-button">
            Bug
        </button>
    );
};

export default BugButton;
