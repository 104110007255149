import { DeleteOutlined, EditOutlined, EllipsisOutlined } from '@ant-design/icons';
import { i18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { Checkbox, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { formatPercentageByLocale } from '../../utils';
import styles from './ConcreteTypesTable.module.css';
import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

const NA = '';

export const ConcreteTypesTableColumnsUS = (props: {
    onCanBeOrdered: (concreteType: ConcreteTypeExtendedBP) => void;
    onUnassign: (concreteType: ConcreteTypeExtendedBP) => void;
    onDuplicate: (concreteType: ConcreteTypeExtendedBP) => void;
    onEdit: (concreteType: ConcreteTypeExtendedBP) => void;
    showCatalogueConcreteTypeModal: (concreteType: ConcreteTypeExtendedBP) => void;
}): ColumnsType<ConcreteTypeExtendedBP> => {
    const { onCanBeOrdered, onUnassign, onDuplicate, onEdit, showCatalogueConcreteTypeModal } = props;
    const { isViewer } = useUserPermissions();

    const getActions = (record: ConcreteTypeExtendedBP): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        items.push({
            label: <Trans>Edit</Trans>,
            key: `${record.id} Edit`,
            icon: <EditOutlined />,
            onClick: () => {
                onEdit(record);
            }
        });

        items.push({
            label: <Trans>Duplicate</Trans>,
            key: `${record.id} Duplicate`,
            icon: <EditOutlined />,
            onClick: () => {
                onDuplicate(record);
            }
        });

        items.push({
            label: <Trans>Assign/Unassign concrete types</Trans>,
            key: `${record.id} AssignCT`,
            icon: <DeleteOutlined />,
            onClick: () => {
                onUnassign(record);
            }
        });

        return items;
    };

    const columns: ExtendedColumnType<ConcreteTypeExtendedBP>[] = [
        {
            title: <Trans>Mix design number</Trans>,
            dataIndex: 'concreteTypeNumber',
            sorter: true,
            render: (typeNumber: string, record: ConcreteTypeExtendedBP) => (
                <RawLinkButton onClick={() => showCatalogueConcreteTypeModal(record)}>{typeNumber}</RawLinkButton>
            )
        },
        {
            title: <Trans>Mix design description</Trans>,
            dataIndex: 'concreteTypeDescription',
            sorter: true,
            render: (concreteTypeDescription: string) => <>{concreteTypeDescription}</>
        },
        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'compressiveStrength',
            sorter: true,
            render: (compressiveStrength: string, record: ConcreteTypeExtendedBP) =>
                renderCompressiveStrength(compressiveStrength, record)
        },
        {
            title: <Trans>Slump</Trans>,
            dataIndex: 'slump',
            sorter: true,
            render: (slump: string, record: ConcreteTypeExtendedBP) => renderSlump(slump, record)
        },
        {
            title: <Trans>Air content</Trans>,
            dataIndex: 'airContentInPercent',
            sorter: true,
            render: (airContentInPercent: number) => renderAirContent(airContentInPercent)
        },
        {
            title: <Trans>Can be ordered</Trans>,
            dataIndex: 'canBeOrdered',
            sorter: true,
            render: (id: string, record: ConcreteTypeExtendedBP) => renderCanBeOrdered(record)
        },
        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'id',
            hidden: isViewer,
            width: 50, // we set this width to have an alignment of this column with the other table having also 50 as width for the action column
            render: (id: string, record: ConcreteTypeExtendedBP) => {
                const items: MenuProps['items'] = getActions(record);
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className={styles.icon} />
                    </Dropdown>
                );
            }
        }
    ];

    return columns.filter((item) => !item.hidden);

    function renderCompressiveStrength(compressiveStrength: string, record: ConcreteTypeExtendedBP): JSX.Element {
        return <>{`${compressiveStrength} ${record.compressiveStrengthUnit}`}</>;
    }

    function renderSlump(slump: string, record: ConcreteTypeExtendedBP): JSX.Element {
        return <>{slump ? `${record.slump} ± ${record.slumpVariance} ${record.slumpUnit}` : NA}</>;
    }

    function renderAirContent(airContentInPercent: number): JSX.Element {
        return <>{airContentInPercent ? formatPercentageByLocale(airContentInPercent, i18n.locale) : NA}</>;
    }

    function renderCanBeOrdered(record: ConcreteTypeExtendedBP): JSX.Element {
        const canOrdered: boolean = record.canBeOrdered;
        function onChange(): void {
            onCanBeOrdered(record);
        }

        return <Checkbox onChange={onChange} checked={canOrdered} />;
    }
};
