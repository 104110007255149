import { ISupplierData } from '.';
import { MARKETS } from '../markets';

export const SIMMA_DATA: ISupplierData = {
    market: MARKETS.DE,
    label: 'SIMMA',
    name: ['MBK', 'Innovativbeton'],
    softwareName: 'mbk.simma',
    apiIntegration: true,
    permissions: {
        allowOrderEdit: true,
        allowOrderCancel: false,
        allowOrderAdditionalQuantity: true,
        allowGenerateDocumentationOrders: true,
        allowGenerateDocumentationPerConcreteType: true
    }
};
