import { t, Trans } from '@lingui/macro';
import { Tabs } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import { useDiscardChanges } from 'src/app-react/components/DiscardChangesModal/hooks/useDiscardChanges';
import useProjectState from 'src/app-react/hooks/useProjectState';
import StructuralElementBreadcrumb from './components/breadcrumb';
import { PostTreatmentView } from './components/views/PostTreatmentView';
import { StructuralElementPageContext } from './contexts/StructuralElementPageContext';

export function StructuralElementPage() {
    const navigator = useNavigate();
    const { projectId } = useProjectState();
    const [structuralElementName, setStructuralElementName] = useState('');
    const { displayDiscardChangeModal } = useDiscardChanges();

    function onBack() {
        displayDiscardChangeModal({
            onConfirm: navigateForward
        });
    }

    function navigateForward() {
        navigator(`/projects/${projectId}/concreting-documentation`);
    }

    const items = [
        {
            label: <Trans>Post-treatment</Trans>,
            key: 'postTreatment',
            children: (
                <StructuralElementPageContext.Provider value={{ setStructuralElementName: setStructuralElementName }}>
                    <PostTreatmentView />
                </StructuralElementPageContext.Provider>
            ),
            forceRender: true
        }
    ];

    return (
        <>
            <AppHeader
                title={t`Structural Element:` + ` ${structuralElementName}`}
                onBack={onBack}
                breadcrumb={<StructuralElementBreadcrumb />}
            />
            <Tabs defaultActiveKey="postTreatment" items={items} />
        </>
    );
}
