import { TableProps } from 'antd';
import css from '../concretingDocumentation.module.css';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { MARKETS } from 'src/app-react/constants/markets';
import { ConcretingDocumentationTableUS } from './US/concretingDocumentationTable';
import { ConcretingDocumentationTableDE } from './DE/concretingDocumentationTable';
import { ConcretingDocumentationTableAUS } from './AUS/concretingDocumentationTable';
import { ConcretingDocumentationData } from '../../../hooks/useConcretingDocumentation';

export interface ConcretingDocumentationTableProps {
    onDisplayCoseModal: (record: any) => void;
    onDisplayConcreteTypeModal: (record: any) => void;
    onDisplayPostTreatmentModal: (record: any) => void;
    onEditPostTreatment: (record: any) => void;
    onEditStructuralElement: (record: any) => void;
    tableData: ConcretingDocumentationData['data'];
    pagination: any;
    onChange: any;
    coseFocused: any;
    setGlobalState: any;
    loading: boolean;
    commonProps?: TableProps<ConcretingDocumentationData['data'][0]>;
}

export function ConcretingDocumentationTable(props: ConcretingDocumentationTableProps) {
    const { marketId } = useProjectState();

    const { coseFocused, setGlobalState, pagination, onChange, loading } = props;

    const commonProps = {
        ...props,
        commonProps: {
            'scroll': { x: 1 },
            'bordered': true,
            'className': css.table,
            'data-testid': 'concreting-doc-table',
            pagination,
            onChange,
            'rowClassName': 'concreting-doc-row',
            'onRow': () => ({ className: css.row }),
            loading
        }
    };

    switch (marketId) {
        case MARKETS.US:
            return (
                <ConcretingDocumentationTableUS
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonProps}
                    setGlobalState={setGlobalState}
                    coseFocused={coseFocused}
                />
            );
        case MARKETS.DE:
            return (
                <ConcretingDocumentationTableDE
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...commonProps}
                    setGlobalState={setGlobalState}
                    coseFocused={coseFocused}
                />
            );
        default:
            // eslint-disable-next-line react/jsx-props-no-spreading
            return <ConcretingDocumentationTableAUS {...commonProps} />;
    }
}
