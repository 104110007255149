import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function ContractIdFormItem() {
    return (
        <FormItem
            name="contractId"
            label={<Trans>Supplier software contract code</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>Please input supplier software contract code</Trans>
                }
            ]}>
            <Input type="text" data-testid="contractId" placeholder={t`Enter supplier software contract code`} />
        </FormItem>
    );
}
