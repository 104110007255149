import { NumberFormatter } from 'src/app-react/components/Formatters';

interface DeviationFormatterProps {
    value: number;
    format?: Intl.NumberFormatOptions;
}

export default function DeviationFormatter({ value, format }: DeviationFormatterProps): JSX.Element {
    if (value < 0) {
        return <>{'-'}<NumberFormatter value={value * -1} format={format} /></>;
    }

    return <>{'+'}<NumberFormatter value={value} format={format} /></>;
}

