import { Outlet } from 'react-router-dom';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ProjectContextProvider } from 'src/app-react/providers/ProjectContext';
import AppMenu from '../AppMenu/AppMenu';

// ensure project context is loaded
function ProjectChildren() {
    const { projectId } = useProjectState();

    if (!projectId) {
        return null;
    }

    return <Outlet />;
}

function ProjectLayout() {
    return (
        <ProjectContextProvider>
            <AppMenu>
                <ProjectChildren />
            </AppMenu>
        </ProjectContextProvider>
    );
}

export default ProjectLayout;
