import { Route, Routes, useParams } from 'react-router-dom';
import { DeliveryDashboardPage } from './business-page/DeliveryDashboardPage';
import { StructuralElementPage } from './business-page/StructuralElementPage';
import CatalogueManagementPage from './business-page/catalogue-management/catalogue.management.page';
import { CatalogueManagementProvider } from './business-page/catalogue-management/providers/catalogue.management.provider';
import { hasPermissionsToAccessCatalogueManagement } from './business-page/catalogue-management/services/authorization.catalogue.management';
import ConcretingDocumentationPage from './business-page/concreting-documentation/ConcretingDocumentationPage';
import { ConcretingDocumentationProvider } from './business-page/concreting-documentation/providers/concreting-documentation.provider';
import DeliveryDetailsPage from './business-page/delivery-details/delivery.details.page';
import DeliveryNoteDetailsPage from './business-page/delivery-note-details/page/DeliveryNoteDetailsPage';
import { DeliveryNoteProvider } from './business-page/delivery-note-details/providers/delivery.note.provider';
import CreateOrderHandler from './business-page/order-management/CreateOrderHandler';
import DuplicateOrderHandler from './business-page/order-management/DuplicateOrderHandler';
import UpdateOrderHandler from './business-page/order-management/UpdateOrderHandler';
import OrderCreationTableTabs from './business-page/order-management/components/OrderCreationTableTabs/OrderCreationTableTabs';
import { OrderManagementProvider } from './business-page/order-management/providers/order.management.provider';
import OrderMonitoringPage from './business-page/order-monitoring/OrderMonitoringPage';
import { OrderMonitoringProvider } from './business-page/order-monitoring/provider/OrderMonitoringProvider';
import ProjectsPage from './business-page/project/components/Projects';
import CompanyLayout from './components/Layouts/CompanyLayout';
import ProjectLayout from './components/Layouts/ProjectLayout';
import NotFound from './components/NotFound/NotFound';
import useProjectState from './hooks/useProjectState';
import UserManagementPage from './business-page/user-management/user.management.page';
import { UserManagementProvider } from './providers/UserManagementContext';
import { DeliveryDashboardProvider } from './business-page/DeliveryDashboardPage/context/DeliveryDahsboardPageContext';

function OrderMonitoringHandler() {
    const { projectId } = useParams();

    return (
        <OrderMonitoringProvider projectId={projectId}>
            <OrderMonitoringPage />
        </OrderMonitoringProvider>
    );
}

function OrderManagementHandler() {
    const { projectId } = useParams();

    return (
        <OrderManagementProvider projectId={projectId}>
            <OrderCreationTableTabs />
        </OrderManagementProvider>
    );
}

function OrderManagementUpdateHandler() {
    const { projectId, orderId } = useParams();

    return (
        <OrderManagementProvider projectId={projectId}>
            <OrderCreationTableTabs orderId={orderId} />
        </OrderManagementProvider>
    );
}

function DeliveryDetailsHandler() {
    const { projectId, orderId, concreteLoadId } = useParams();

    return (
        <DeliveryNoteProvider projectId={projectId} orderId={orderId}>
            <DeliveryDetailsPage orderId={orderId} concreteLoadId={concreteLoadId} />
        </DeliveryNoteProvider>
    );
}

function DeliveryNoteHandler() {
    const { projectId, orderId } = useParams();

    return (
        <DeliveryNoteProvider projectId={projectId} orderId={orderId}>
            <DeliveryNoteDetailsPage />
        </DeliveryNoteProvider>
    );
}

function ConcretingDocumentationHandler() {
    const { projectId } = useParams();

    return (
        <ConcretingDocumentationProvider projectId={projectId}>
            <ConcretingDocumentationPage />
        </ConcretingDocumentationProvider>
    );
}

function StructuralElementHandler() {
    const { projectId } = useParams();
    const { structuralElementId } = useParams();

    return (
        <ConcretingDocumentationProvider projectId={projectId} structuralElementId={structuralElementId}>
            <StructuralElementPage />
        </ConcretingDocumentationProvider>
    );
}

function DeliveryDashboardHandler() {
    return (
        <DeliveryDashboardProvider>
            <DeliveryDashboardPage />
        </DeliveryDashboardProvider>
    );
}

function CatalogueManagementHandler() {
    const { projectId } = useParams();
    const { projectRoles } = useProjectState();

    return hasPermissionsToAccessCatalogueManagement(projectRoles) ? (
        <CatalogueManagementProvider projectId={projectId}>
            <CatalogueManagementPage />
        </CatalogueManagementProvider>
    ) : (
        <NotFound />
    );
}

function ProjectsHandler() {
    return <ProjectsPage />;
}

function UserManagementHandler() {
    return (
        <UserManagementProvider>
            <UserManagementPage />
        </UserManagementProvider>
    );
}

function AppRouter() {
    return (
        <Routes>
            <Route element={<CompanyLayout />}>
                <Route path="/" element={<ProjectsHandler />} />
                <Route path="/projects" element={<ProjectsHandler />} />
                <Route path="/user-management" element={<UserManagementHandler />} />
            </Route>
            <Route element={<ProjectLayout />}>
                <Route
                    path="/projects/:projectId/concreting-documentation"
                    element={<ConcretingDocumentationHandler />}
                />
                <Route
                    path="/projects/:projectId/concreting-documentation/:structuralElementId"
                    element={<StructuralElementHandler />}
                />
                <Route path="/projects/:projectId/catalogue-management" element={<CatalogueManagementHandler />} />
                <Route path="/projects/:projectId/orders" element={<OrderMonitoringHandler />} />
                <Route path="/projects/:projectId/orders/:orderId/edit" element={<UpdateOrderHandler />} />
                <Route path="/projects/:projectId/orders/:orderId/duplicate" element={<DuplicateOrderHandler />} />
                <Route path="/projects/:projectId/order-type" element={<OrderManagementHandler />} />
                <Route
                    path="/projects/:projectId/order-type/:orderId/edit"
                    element={<OrderManagementUpdateHandler />}
                />
                <Route
                    path="/projects/:projectId/order-type/structuralel/:structElementId/concretetype/:concreteTypeId/new-order"
                    element={<CreateOrderHandler />}
                />
                <Route
                    path="/projects/:projectId/order-type/structuralel/:structElementId/concretetype/:concreteTypeId/order/:orderId/edit"
                    element={<UpdateOrderHandler />}
                />
                <Route
                    path="/projects/:projectId/orders/:orderId/delivery-notes-details"
                    element={<DeliveryNoteHandler />}
                />
                <Route
                    path="/projects/:projectId/orders/:orderId/delivery-notes-details/:concreteLoadId/details"
                    element={<DeliveryDetailsHandler />}
                />

                <Route path="/projects/:projectId/deliveries" element={<DeliveryDashboardHandler />} />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default AppRouter;
