import CheckCircleOutlined from '@ant-design/icons/lib/icons/CheckCircleOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import { Trans } from '@lingui/macro';
import {
    ConcretedStructuralElementForConcretingDocumentationBP,
    PostTreatmentTypes,
    SupplierProducedConcrete
} from '@nexploretechnology/bcqd-client';
import { ColumnsType } from 'antd/lib/table';
import Tag from 'antd/lib/tag';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import {
    dateFormatter,
    dateTimeFormatter,
    daysTranslationSelector,
    formatNumberValueToLanguage,
    postTreatmentTypeMapper,
    weatherMapper
} from 'src/app-react/utils/formatters';
import { Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { getActionMenuItems } from '../../util';

interface ConcretingDocumentationTableColumnsUSProps {
    onDisplayCoseModal: (structuralElementId: string) => void;
    onDisplayConcreteTypeModal: (supplierProducedConcrete: SupplierProducedConcrete) => void;
    onDisplayPostTreatmentModal: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
    onEditPostTreatment: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
    onEditStructuralElement: (record: ConcretedStructuralElementForConcretingDocumentationBP) => void;
}

export const concretingDocumentationTableColumnsUS: (
    props: ConcretingDocumentationTableColumnsUSProps
) => ColumnsType<ConcretedStructuralElementForConcretingDocumentationBP> = (props) => [
    {
        title: <Trans>Time of concreting</Trans>,
        children: [
            {
                title: <Trans>Concreting start</Trans>,
                key: 'concretingStartDate',
                dataIndex: 'concretingStartDate',
                width: 130,
                sorter: true,
                render: (concretingStartDate: Date) => (
                    <p>{`${dateTimeFormatter(concretingStartDate, { dateStyle: 'short', timeStyle: 'short' })}`}</p>
                )
            },
            {
                title: <Trans>Concreting end</Trans>,
                key: 'concretingEndDate',
                dataIndex: 'concretingEndDate',
                width: 130,
                sorter: true,
                render: (concretingEndDate: Date) => (
                    <p>{`${dateTimeFormatter(concretingEndDate, { dateStyle: 'short', timeStyle: 'short' })}`}</p>
                )
            }
        ]
    },
    {
        title: <Trans>Structural element parameters</Trans>,
        children: [
            {
                title: <Trans>Structural element</Trans>,
                key: 'structuralElementName',
                dataIndex: 'structuralElementName',
                sorter: true,
                width: 200,
                render: (
                    structuralElementName: string,
                    row: ConcretedStructuralElementForConcretingDocumentationBP
                ) => (
                    <RawLinkButton onClick={() => props.onDisplayCoseModal(row.structuralElementId)}>
                        {structuralElementName}
                    </RawLinkButton>
                )
            },
            {
                title: <Trans>Concrete Type</Trans>,
                key: 'supplierOrderedTypes',
                dataIndex: 'supplierOrderedTypes',
                sorter: true,
                width: 200,
                render: (supplierOrderedTypes: SupplierProducedConcrete[]) =>
                    !supplierOrderedTypes || supplierOrderedTypes.length === 0
                        ? ''
                        : supplierOrderedTypes.map((orderedConcreteType: SupplierProducedConcrete, index: number) => (
                              <RawLinkButton
                                  style={{ display: 'block' }}
                                  onClick={() => props.onDisplayConcreteTypeModal(orderedConcreteType)}
                                  key={index}>
                                  {orderedConcreteType.number}
                              </RawLinkButton>
                          ))
            },
            {
                title: <Trans>Mixing plants</Trans>,
                key: 'mixingPlants',
                width: 120,
                sorter: true,
                dataIndex: 'mixingPlants',
                render: (mixingPlants: string[]) =>
                    !mixingPlants || mixingPlants.length === 0
                        ? ''
                        : mixingPlants.map((mixingPlant: string, index: number) => <p key={index}>{mixingPlant}</p>)
            },
            {
                title: <Trans>Total quantity</Trans>,
                key: 'totalQuantity',
                width: 120,
                sorter: true,
                dataIndex: 'totalQuantity',
                render: (totalQuantity: number, record: ConcretedStructuralElementForConcretingDocumentationBP) => (
                    <NumberFormatter value={totalQuantity} unit={record.volumeUnit} />
                )
            },
            {
                title: <Trans>Compressive strength class</Trans>,
                key: 'compressiveStrength',
                dataIndex: 'compressiveStrength',
                sorter: true,
                width: 130,
                render: (compressiveStrength: number, record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                    compressiveStrength && record.compressiveStrengthUnit
                        ? `${compressiveStrength} ${record.compressiveStrengthUnit}`
                        : ''
            },
            {
                title: <Trans>Air temp. (Min/Max)</Trans>,
                key: 'minAirTemperature',
                dataIndex: 'minAirTemperature',
                width: 130,
                sorter: true,
                render: (minAirTemperature: number, record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                    (minAirTemperature || minAirTemperature === 0) &&
                    (record.maxAirTemperature || record.maxAirTemperature === 0) &&
                    record.temperatureUnit ? (
                        <p>
                            {formatNumberValueToLanguage(record.minAirTemperature + '') +
                                '/' +
                                formatNumberValueToLanguage(record.maxAirTemperature) +
                                ' ' +
                                record.temperatureUnit}
                        </p>
                    ) : (
                        <p data-testid="emptyAirTemp"></p>
                    )
            },
            {
                title: <Trans>Weather</Trans>,
                key: 'weather',
                dataIndex: 'weather',
                width: 130,
                sorter: false,
                render: (weather: string[]) => (
                    <>
                        {weather.map((weatherEntry: string, index: number) => (
                            <p key={index}> {weatherMapper[weatherEntry]} </p>
                        ))}
                    </>
                )
            }
        ]
    },
    {
        title: <Trans>Post treatment</Trans>,
        children: [
            {
                title: <Trans>Post-treatment types</Trans>,
                key: 'ptTypes',
                dataIndex: 'ptTypes',
                width: 200,
                sorter: false,
                render: (ptTypes: PostTreatmentTypes[]) =>
                    Array.isArray(ptTypes) &&
                    ptTypes.map((targetType: PostTreatmentTypes, index: number) => (
                        <p key={index}> {postTreatmentTypeMapper[targetType]} </p>
                    ))
            },
            {
                title: <Trans>Minimal duration</Trans>,
                key: 'targetNumberOfDays',
                dataIndex: 'targetNumberOfDays',
                width: 140,
                sorter: true,
                render: (
                    targetNumberOfDays: number,
                    record: ConcretedStructuralElementForConcretingDocumentationBP
                ) => (
                    <RawLinkButton onClick={() => props.onDisplayPostTreatmentModal(record)}>
                        {daysTranslationSelector(targetNumberOfDays)}
                    </RawLinkButton>
                )
            },
            {
                title: <Trans>Actual duration</Trans>,
                key: 'actualNumberOfDays',
                dataIndex: 'actualNumberOfDays',
                width: 130,
                sorter: true,
                render: (
                    actualNumberOfDays: number,
                    record: ConcretedStructuralElementForConcretingDocumentationBP
                ) => (
                    <RawLinkButton onClick={() => props.onDisplayPostTreatmentModal(record)}>
                        {daysTranslationSelector(actualNumberOfDays)}
                    </RawLinkButton>
                )
            },
            {
                title: <Trans>Equipping/stripping</Trans>,
                key: 'equippingOrStripping',
                dataIndex: 'number',
                width: 200,
                sorter: true,
                render: (_, record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                    dateFormatter(record.equippingOrStripping, { dateStyle: 'short' })
            },
            {
                title: <Trans>Measurements</Trans>,
                key: 'measurements',
                dataIndex: 'measurements',
                width: 170,
                sorter: true,
                render: (measurements: number) =>
                    measurements ? (
                        <Tag icon={<CheckCircleOutlined />} color="success">
                            <Trans>Recorded</Trans>
                        </Tag>
                    ) : (
                        <Tag icon={<ExclamationCircleOutlined />} color="warning">
                            <Trans>Unrecorded</Trans>
                        </Tag>
                    )
            }
        ]
    },
    {
        title: <Trans>Concrete tests</Trans>,
        children: [
            {
                title: <Trans>Fresh concrete tests</Trans>,
                key: 'freshConcreteTests',
                dataIndex: 'freshConcreteTests',
                width: 200,
                sorter: true,
                render: (freshConcreteTests: number) => (freshConcreteTests ? freshConcreteTests : '')
            },
            {
                title: <Trans>Test specimen</Trans>,
                key: 'testSpecimen',
                dataIndex: 'testSpecimen',
                width: 130,
                sorter: true,
                render: (testSpecimen: number) => (testSpecimen ? testSpecimen : '')
            }
        ]
    },
    {
        title: '',
        children: [
            {
                title: <Trans>Actions</Trans>,
                fixed: 'right',
                key: 'actions',
                dataIndex: 'actions',
                width: 130,
                render: (_, record: ConcretedStructuralElementForConcretingDocumentationBP) => {
                    const items: MenuProps['items'] = getActionMenuItems({
                        record,
                        onEditPostTreatment: props.onEditPostTreatment,
                        onEditStructuralElement: props.onEditStructuralElement
                    });
                    return (
                        <Dropdown key="actionEdit" data-testid={'actions'} menu={{ items }} trigger={['click']}>
                            <EllipsisOutlined />
                        </Dropdown>
                    );
                }
            }
        ]
    }
];
