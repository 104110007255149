import { t, Trans } from '@lingui/macro';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { ConcretedStructuralElementBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatSlump } from 'src/app-react/business-page/catalogue-management/utils';
import { NumberFormatter, StrengthDevelopmentFormatter } from 'src/app-react/components/Formatters';
import ExposureClassesFormatter from 'src/app-react/components/Formatters/ExposureClassesFormatter';
import useProjectState from 'src/app-react/hooks/useProjectState';
import styles from './StructuralElementModal.module.css';
import { transformStructuralElement } from './utils/formatters';

const empty = '-';

export type StructuralElement = CataloguedStructuralElementExtendedBP | ConcretedStructuralElementBP;

interface CatalogueStructuralElementProps {
    structuralElementDetails: StructuralElement;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isDocumentation?: boolean;
}

function StructuralElementModal(props: CatalogueStructuralElementProps) {
    const { structuralElementDetails, open, setOpen, isDocumentation } = props;

    const { marketId } = useProjectState();

    const [structuralElementData, setStructuralElement] = useState<CataloguedStructuralElementExtendedBP>(
        {} as CataloguedStructuralElementExtendedBP
    );

    const marketDE = 'DE';

    useEffect(() => {
        if (open) {
            setStructuralElement(transformStructuralElement(structuralElementDetails));
        }
    }, [open]);

    const firstBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                {!isDocumentation && (
                    <>
                        <Col span={24}>
                            <label className={styles.modalLabel}>
                                <Trans>BoQ positions</Trans>
                            </label>
                        </Col>
                        <Col
                            className={styles.colWithValue}
                            data-testid="structuralElementModal-boqPositions"
                            span={24}>
                            {structuralElementData?.boqPositions ? structuralElementData.boqPositions.join(' ') : empty}
                        </Col>

                        <Col span={24}>
                            <label className={styles.modalLabel}>
                                <Trans>Plan Number</Trans>
                            </label>
                        </Col>
                        <Col className={styles.colWithValue} data-testid="structuralElementModal-planNumber" span={24}>
                            {structuralElementData?.planNumber ? structuralElementData.planNumber : empty}
                        </Col>
                    </>
                )}

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-strengthClass" span={24}>
                    {getStrengthClass() || empty}
                </Col>

                <Col span={24}>
                    <label data-testid="exposureClass" className={styles.modalLabel}>
                        <Trans>Exposure Class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-exposureClasses" span={24}>
                    <ExposureClassesFormatter
                        x0={structuralElementData.requiredX0}
                        xa={structuralElementData.minimalRequiredXa}
                        xc={structuralElementData.minimalRequiredXc}
                        xd={structuralElementData.minimalRequiredXd}
                        xfFirst={structuralElementData.minimalRequiredXfFirst}
                        xfSecond={structuralElementData.minimalRequiredXfSecond}
                        xm={structuralElementData.minimalRequiredXm}
                        xs={structuralElementData.minimalRequiredXs}
                    />
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Moisture Class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-moistureClasses" span={24}>
                    {structuralElementData?.minimalRequiredMoistureClass
                        ? structuralElementData.minimalRequiredMoistureClass
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Density class</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-densityClass" span={24}>
                    {structuralElementData?.requiredDensityClass ? structuralElementData.requiredDensityClass : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Strength development</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-strengthDevelopment" span={24}>
                    <StrengthDevelopmentFormatter value={structuralElementData?.minimalRequiredStrengthDevelopment} />
                </Col>
            </>
        );
    };

    const firstBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Drawing set</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModalUS-planNumber" span={24}>
                    {structuralElementData?.planNumber ? structuralElementData.planNumber : empty}
                </Col>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Compressive strength</Trans>
                    </label>
                </Col>
                <Col
                    className={styles.colWithValue}
                    data-testid="structuralElementModalUS-compressiveStrength"
                    span={24}>
                    <NumberFormatter
                        value={structuralElementData?.minimalRequiredCompressiveStrength || empty}
                        unit={structuralElementData?.compressiveStrengthUnit}
                    />
                </Col>
            </>
        );
    };

    const secondBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Chloride content</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-chlorideContent" span={24}>
                    {structuralElementData?.requiredChlorideContentClass
                        ? structuralElementData.requiredChlorideContentClass
                        : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Use for external inspection</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModal-externalInspection" span={24}>
                    {getElementYesOrNo(structuralElementData?.externalInspection)}
                </Col>
            </>
        );
    };

    const secondBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Cost code</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModalUS-costCode" span={24}>
                    {structuralElementData?.costCode ? structuralElementData.costCode : empty}
                </Col>

                <Col span={24}>
                    <label data-testid="slump" className={styles.modalLabel}>
                        <Trans>Slump and allowed variance</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModalUS-slumpVariance" span={24}>
                    {formatSlump(
                        structuralElementData?.requiredSlump,
                        structuralElementData?.maximalAllowedSlumpVariance,
                        structuralElementData?.slumpUnit,
                        empty
                    )}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Mass Concrete required</Trans>
                    </label>
                </Col>
                <Col className={styles.colWithValue} data-testid="structuralElementModalUS-massConcrete" span={24}>
                    {structuralElementData && structuralElementData.massConcreteRequired !== undefined
                        ? getElementYesOrNo(structuralElementData.massConcreteRequired)
                        : empty}
                </Col>
            </>
        );
    };

    function getStrengthClass(): string {
        let strengthClass = '';

        if (structuralElementData && structuralElementData.minimalRequiredTypeOfConcreteType) {
            strengthClass += `${structuralElementData.minimalRequiredTypeOfConcreteType}`;
        }

        if (structuralElementData && structuralElementData.minimalRequiredCylindricCompressiveStrength) {
            strengthClass += ` ${structuralElementData.minimalRequiredCylindricCompressiveStrength}`;
        }

        if (structuralElementData && structuralElementData.minimalRequiredCubicCompressiveStrength) {
            strengthClass += ` / ${structuralElementData.minimalRequiredCubicCompressiveStrength}`;
        }

        return strengthClass;
    }

    function getElementYesOrNo(element: boolean): string {
        return element ? t`yes` : t`no`;
    }

    function optionMethodConcrete(option: string): string {
        switch (option) {
            case 'prestressedConcrete / Cl 0,20':
                return t`Prestressed concrete / Cl 0,20`;
            case 'reinforcedConcrete / Cl 0,40':
                return t`Reinforced concrete  / Cl 0,40`;
            case 'nonReinforcedConcrete / Cl 1,00':
                return t`Non-reinforced concrete / Cl 1,00`;
            case 'prestressedConcrete':
                return t`Prestressed concrete`;
            case 'reinforcedConcrete':
                return t`Reinforced concrete`;
            case 'nonReinforcedConcrete':
                return t`Non-reinforced concrete`;
        }
        return '';
    }

    const onCancel = (): void => {
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            open={open}
            forceRender
            onCancel={onCancel}
            title={t`Structural element details`}
            width={750}
            data-testid="structuralElementModal"
            footer={[
                <Button data-testid="closeButton" key="send" type="primary" onClick={onCancel}>
                    {t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 4]}>
                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Structural Element Name</Trans>
                    </label>
                </Col>
                <Col
                    className={styles.colWithValue}
                    data-testid="structuralElementModal-structuralElementName"
                    id="lbl-name-de"
                    span={24}>
                    {structuralElementData ? structuralElementData.structuralElementName : empty}
                </Col>

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Description</Trans>
                    </label>
                </Col>
                <Col
                    className={styles.colWithValue}
                    data-testid="structuralElementModal-structuralElementDescription"
                    id="lbl-name-de"
                    span={24}>
                    {structuralElementData.structuralElementDescription || empty}
                </Col>

                {marketId === marketDE ? firstBlockRenderMarketIdDE() : firstBlockRenderMarketIdUS()}

                <Col span={24}>
                    <label className={styles.modalLabel}>
                        <Trans>Method of concrete use</Trans>
                    </label>
                </Col>
                <Col
                    className={styles.colWithValue}
                    data-testid="structuralElementModal-requiredMethodOfConcreteUse"
                    span={24}>
                    {structuralElementData?.requiredMethodOfConcreteUse
                        ? optionMethodConcrete(structuralElementData.requiredMethodOfConcreteUse)
                        : empty}
                </Col>

                {marketId === marketDE ? secondBlockRenderMarketIdDE() : secondBlockRenderMarketIdUS()}
            </Row>
        </Modal>
    );
}

export { StructuralElementModal };
