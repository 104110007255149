import { Trans } from '@lingui/macro';
import {
  CataloguedStructuralElementExtendedBP,
  ConcreteTypeExtendedBP
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { SimpleClientResponse } from '@nexploretechnology/concreting-core-client/simple.client.response';
import { Button, Modal } from 'antd';
import React from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

interface IProps {
    structuralElement: CataloguedStructuralElementExtendedBP;
    concreteType: ConcreteTypeExtendedBP;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onComplete?: () => void;
}
export default function UnassignStructuralElementConcreteTypeModal({
    structuralElement,
    concreteType,
    open,
    setOpen,
    onComplete
}: IProps) {
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    const onOk = async (): Promise<void> => {
        setOpen(false);

        await api.catalogueManagementClient
            .unassignCataloguedStructuralElementAndConcreteType(
                companyId,
                projectId,
                structuralElement.id,
                concreteType.id
            )
            .then((response: SimpleClientResponse<void>) => {
                if (response.isSuccess()) {
                    onComplete?.();
                } else {
                    ErrorNotification({
                        message: response.getError(),
                        description: ''
                    });
                }
            });
    };

    const onCancel = (): void => {
        setOpen(false);
    };

    return !open ? null : (
        <Modal
            maskClosable={false}
            data-testid="modal"
            title={<Trans>Unassign concrete type</Trans>}
            open={open}
            width={600}
            onOk={onOk}
            onCancel={onCancel}
            footer={[
                <Button data-testid="cancel-btn" key="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button data-testid="ok-btn" key="okButton" onClick={onOk} type="primary">
                    <Trans>OK</Trans>
                </Button>
            ]}>
            <p data-testid="modal-message">
                <Trans
                    id={`You are about to unassign '${concreteType.concreteTypeNumber}' concrete type from '${structuralElement.structuralElementName}' selected structural element!`}
                />
            </p>
        </Modal>
    );
}
