import Icon from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Button, Modal, Select } from 'antd';
import React, { useContext, useState } from 'react';
import { Language, LanguageContext } from 'src/app-react/providers/LanguageProvider';
import AULanguageSvg from '../../../../assets/images/languages/country-australia.svg';
import HKLanguageSvg from '../../../../assets/images/languages/country-china.svg';
import czechLanguageSvg from '../../../../assets/images/languages/country-czech.svg';
import germanLanguageSvg from '../../../../assets/images/languages/country-germany.svg';
import INLanguageSvg from '../../../../assets/images/languages/country-india.svg';
import polishLanguageSvg from '../../../../assets/images/languages/country-polish.svg';
import ukLangeguageSvg from '../../../../assets/images/languages/country-uk.svg';
import USLanguageSvg from '../../../../assets/images/languages/country-usa.svg';
interface LanguageModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function LanguageModal(props: LanguageModalProps) {
    const { open, setOpen } = props;
    const { changeLanguage, getLanguage } = useContext(LanguageContext);
    const [language, setLanguage] = useState(getLanguage);

    const onLanguageChange = (value: () => string) => {
        setLanguage(value);
    };

    const onCancel = (): void => {
        setOpen(false);
    };

    const onSubmit = (): void => {
        changeLanguage(language as Language);
        setOpen(false);
    };

    return (
        <Modal
            maskClosable={false}
            open={open}
            onCancel={onCancel}
            title={t`Language`}
            width={700}
            data-testid="languageModal"
            footer={[
                <Button data-testid="cancelButton" key="cancelButton" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button onClick={onSubmit} data-testid="structural-element-submit" type="primary" key="confirmButton">
                    <Trans>Confirm</Trans>
                </Button>
            ]}>
            <p>
                <Trans>You can change the default system and display language of your Nexplore product.</Trans>
            </p>

            <Select
                size="large"
                style={{ width: 250 }}
                onChange={onLanguageChange}
                defaultValue={getLanguage}
                data-testid="languageSelect">
                <Select.Option data-testid="de" key="de" value="de-DE">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="de" src={germanLanguageSvg} />}
                    />{' '}
                    {t`German`}
                </Select.Option>

                <Select.Option data-testid="cs" key="cs" value="cs-CZ">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="cs" src={czechLanguageSvg} />}
                    />{' '}
                    {t`Czech`}
                </Select.Option>

                <Select.Option data-testid="pl" key="pl" value="pl-PL">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="pl" src={polishLanguageSvg} />}
                    />{' '}
                    {t`Polish`}
                </Select.Option>

                <Select.Option data-testid="uk" key="uk" value="en-UK">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="uk" src={ukLangeguageSvg} />}
                    />{' '}
                    {t`English (UK)`}
                </Select.Option>

                <Select.Option data-testid="en" key="en" value="en-US">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="us" src={USLanguageSvg} />}
                    />{' '}
                    {t`English (US)`}
                </Select.Option>
                <Select.Option data-testid="au" key="au" value="en-AU">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="au" src={AULanguageSvg} />}
                    />{' '}
                    {t`English (AU)`}
                </Select.Option>
                <Select.Option data-testid="au" key="au" value="zh-HK">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="hk" src={HKLanguageSvg} />}
                    />{' '}
                    {t`Chinese (HK)`}
                </Select.Option>
                <Select.Option data-testid="au" key="au" value="hi-IN">
                    <Icon
                        style={{ verticalAlign: 'text-bottom' }}
                        component={() => <img alt="in" src={INLanguageSvg} />}
                    />{' '}
                    {t`Indian (IN)`}
                </Select.Option>
            </Select>
        </Modal>
    );
}

export default LanguageModal;
