import { ConcretedStructuralElementForConcretingDocumentationBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Table, TableProps } from 'antd';
import DataTableConcretedLoad from '../../../ConcretedLoadTable/dataTableConcretedLoad';
import css from '../../concretingDocumentation.module.css';
import { keyMapFromTableData } from '../US/util';
import { ConcretingDocumentationTableProps } from '../concretingDocumentationTable';
import { concretingDocumentationTableColumnsDE } from './columns';

interface ConcretingDocumentationTablePropsDE extends Omit<ConcretingDocumentationTableProps, 'commonProps'> {
    commonProps?: TableProps<ConcretedStructuralElementForConcretingDocumentationBP>;
    setGlobalState: any;
    coseFocused: any;
}

export function ConcretingDocumentationTableDE({
    setGlobalState,
    coseFocused,
    ...props
}: ConcretingDocumentationTablePropsDE) {
    const {
        onDisplayCoseModal,
        onDisplayConcreteTypeModal,
        onDisplayPostTreatmentModal,
        onEditPostTreatment,
        onEditStructuralElement,
        commonProps
    } = props;

    return (
        <Table
            {...commonProps}
            expandable={{
                expandedRowRender: (record: ConcretedStructuralElementForConcretingDocumentationBP) => (
                    <DataTableConcretedLoad structuralElementId={record.structuralElementId} />
                ),
                rowExpandable: (record: ConcretedStructuralElementForConcretingDocumentationBP) =>
                    record.typeOfConcrete !== 'Not Expandable',
                defaultExpandedRowKeys: coseFocused ? [coseFocused] : [],
                onExpand: (expanded: boolean, record: ConcretedStructuralElementForConcretingDocumentationBP) => {
                    if (!expanded && record.structuralElementId === coseFocused) {
                        setGlobalState({ coseFocused: undefined });
                    }
                }
            }}
            dataSource={keyMapFromTableData(
                props.tableData as ConcretedStructuralElementForConcretingDocumentationBP[]
            )}
            columns={concretingDocumentationTableColumnsDE({
                onDisplayCoseModal,
                onDisplayConcreteTypeModal,
                onDisplayPostTreatmentModal,
                onEditPostTreatment,
                onEditStructuralElement
            })}
            onHeaderRow={(col, index) => {
                return {
                    className: !index ? css.headerRow : css.secondHeaderRow
                };
            }}
        />
    );
}
