import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import { RuleObject } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';
import moment from 'moment';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export function EndingDateFormItem() {
    const form = Form.useFormInstance();

    function disabledDateHandler(current: moment.Moment | null): boolean {
        if (!current) {
            return false;
        }
        const postTreatmentStartMoment: moment.Moment = form.getFieldValue('postTreatmentStart');
        return current.isBefore(postTreatmentStartMoment, 'day');
    }

    function postTreatmentEndingDateValidator(
        rule: RuleObject,
        date: moment.Moment,
        callback: (error?: string) => void
    ) {
        const postTreatmentStartMoment: moment.Moment = form.getFieldValue('postTreatmentStart');
        postTreatmentStartMoment?.startOf('day');
        if ((date && !postTreatmentStartMoment) || date?.isSameOrAfter(postTreatmentStartMoment)) {
            return callback();
        }
        return callback(rule.message as string);
    }

    function onChange(current: moment.Moment | null) {
        current?.startOf('day');
    }

    return (
        <FormItem
            name="postTreatmentEnd"
            label={
                <b>
                    <Trans>Ending Date</Trans>
                </b>
            }
            style={{ gap: '.2rem' }}
            rules={[
                {
                    required: true,
                    message: <Trans>Please select a valid ending date</Trans>,
                    validator: postTreatmentEndingDateValidator
                }
            ]}>
            <DatePicker
                onChange={onChange}
                style={{ width: '100%' }}
                data-testid="postTreatmentEnd"
                value={moment()}
                placeholder={t`Select ending date`}
                disabledDate={disabledDateHandler}
            />
        </FormItem>
    );
}
