export const ROLES = {
    FOREMAN: 'Foreman',
    VIEWER: 'Viewer',
    SUBCONTRACTOR: 'Subcontractor',
    PROJECT_ADMIN: 'ProjectAdmin',
    PROJECT_MANAGER: 'ProjectManager',
    CONCRETE_ENGINEER: 'ConcreteEngineer',
    BATCHER: 'Batcher'
} as const;

export type ROLES_VALUES = (typeof ROLES)[keyof typeof ROLES];
