/* eslint-disable react/require-default-props */

import { Divider, Table } from 'antd';
import customerInformationTableColumns from './customerInformationTableColumns';
import styles from '../shared/shared.module.css';
import { Trans } from '@lingui/macro';
import { DeliveryNoteFullDetails } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';

export interface CustomerInformationTableProps {
    deliveryDetails?: DeliveryNoteFullDetails;
}

function CustomerInformationTable({ deliveryDetails }: CustomerInformationTableProps) {
    return (
        deliveryDetails && (
            <>
                <p className={styles.tableTitle}>
                    <Trans>Customer Information</Trans>
                </p>
                <Table
                    bordered={false}
                    data-testid="customer-information-table"
                    scroll={{ x: 1 }}
                    columns={customerInformationTableColumns()}
                    dataSource={[deliveryDetails]}
                    pagination={false}
                />
                <Divider />
            </>
        )
    );
}

export default CustomerInformationTable;
