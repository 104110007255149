import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import useProjectState from 'src/app-react/hooks/useProjectState';

export default function ProjectBreadcrumb({ children }: { children: any }) {
    const { project, company } = useProjectState();

    return (
        <Breadcrumb>
            <Breadcrumb.Item>{company.companyName}</Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/projects'}>{project.name}</Link>
            </Breadcrumb.Item>
            {children}
        </Breadcrumb>
    );
}
