import { t } from '@lingui/macro';

export interface keyValue {
    key: string;
    value: string;
}

export const mapper = (marketId: string, field: string): keyValue[] => {
    function setValuesAsKeys(values: string[]): keyValue[] {
        return values.map((value: string) => {
            return { key: value, value: value };
        });
    }

    if (marketId === 'DE') {
        switch (field) {
            case 'typeOfConcrete':
                return setValuesAsKeys(['C', 'LC']);
            case 'optionMoisture':
                return setValuesAsKeys(['W0', 'WF', 'WA', 'WS']);
            case 'optionConsistencyType':
                return setValuesAsKeys(['F', 'S', 'C', 'V']);
            case 'requiredXc':
                return setValuesAsKeys(['XC1', 'XC2', 'XC3', 'XC4', '']);
            case 'requiredXd':
                return setValuesAsKeys(['XD1', 'XD2', 'XD3', '']);
            case 'requiredXf':
                return setValuesAsKeys(['XF1', 'XF2', 'XF2/3', 'XF3', 'XF4', '']);
            case 'requiredXa':
                return setValuesAsKeys(['XA1', 'XA2', 'XA3', '']);
            case 'requiredXm':
                return setValuesAsKeys(['XM1', 'XM2', 'XM3', '']);
            case 'requiredXs':
                return setValuesAsKeys(['XS1', 'XS2', 'XS3', '']);
            case 'methodConcreteUseChloride':
                return setValuesAsKeys([
                    'prestressedConcrete / Cl 0,20',
                    'reinforcedConcrete / Cl 0,40',
                    'nonReinforcedConcrete / Cl 1,00'
                ]);
            case 'strengthDevelopmentClass':
                return setValuesAsKeys(['fast', 'intermediate', 'slow', 'very slow']);
            case 'densityClass':
                return setValuesAsKeys(['D1.0', 'D1.2', 'D1.4', 'D1.6', 'D1.8', 'D2.0']);
            default:
                return [];
        }
    }

    if (marketId === 'US') {
        switch (field) {
            case 'methodConcreteUse':
                return setValuesAsKeys(['prestressedConcrete', 'reinforcedConcrete', 'nonReinforcedConcrete']);
            case 'cemexGoSlump':
                return setValuesAsKeys([
                    '0.5',
                    '1.0',
                    '1.5',
                    '2.0',
                    '2.5',
                    '3.0',
                    '3.5',
                    '4.0',
                    '4.5',
                    '5.0',
                    '5.5',
                    '6.0',
                    '6.5',
                    '7.0',
                    '7.5',
                    '8.0',
                    '8.5',
                    '9.0',
                    '9.5',
                    '10.0',
                    '10.5',
                    '11.0',
                    '11.5',
                    '12.0',
                    '12.5',
                    '13.0',
                    '13.5',
                    '14.0',
                    '14.5',
                    '15.0'
                ]);
            default:
                return [];
        }
    }
    return [];
};

export function optionMethodConcrete(option: string): string {
    switch (option) {
        case 'prestressedConcrete / Cl 0,20':
            return t`Prestressed concrete / Cl 0,20`;
        case 'reinforcedConcrete / Cl 0,40':
            return t`Reinforced concrete  / Cl 0,40`;
        case 'nonReinforcedConcrete / Cl 1,00':
            return t`Non-reinforced concrete / Cl 1,00`;
        case 'prestressedConcrete':
            return t`Prestressed concrete`;
        case 'reinforcedConcrete':
            return t`Reinforced concrete`;
        case 'nonReinforcedConcrete':
            return t`Non-reinforced concrete`;
    }
    return '';
}

export function optionStrengthDevelopmentClass(option: string): string {
    switch (option) {
        case 'fast':
            return t`Fast`;
        case 'intermediate':
            return t`Intermediate`;
        case 'slow':
            return t`Slow`;
        case 'very slow':
            return t`Very slow`;
    }
    return '';
}

export function optionStrengthCemexGoSlump(option: string): string {
    switch (option) {
        case '0.5':
            return t`0.5 in`;
        case '1.0':
            return t`1.0 in`;
        case '1.5':
            return t`1.5 in`;
        case '2.0':
            return t`2.0 in`;
        case '2.5':
            return t`2.5 in`;
        case '3.0':
            return t`3.0 in`;
        case '3.5':
            return t`3.5 in`;
        case '4.0':
            return t`4.0 in`;
        case '4.5':
            return t`4.5 in`;
        case '5.0':
            return t`5.0 in`;
        case '5.5':
            return t`5.5 in`;
        case '6.0':
            return t`6.0 in`;
        case '6.5':
            return t`6.5 in`;
        case '7.0':
            return t`7.0 in`;
        case '7.5':
            return t`7.5 in`;
        case '8.0':
            return t`8.0 in`;
        case '8.5':
            return t`8.5 in`;
        case '9.0':
            return t`9.0 in`;
        case '9.5':
            return t`9.5 in`;
        case '10.0':
            return t`10.0 in`;
        case '10.5':
            return t`10.5 in`;
        case '11.0':
            return t`11.0 in`;
        case '11.5':
            return t`11.5 in`;
        case '12.0':
            return t`12.0 in`;
        case '12.5':
            return t`12.5 in`;
        case '13.0':
            return t`13.0 in`;
        case '13.5':
            return t`13.5 in`;
        case '14.0':
            return t`14.0 in`;
        case '14.5':
            return t`14.5 in`;
        case '15.0':
            return t`15.0 in`;
    }
    return '';
}

export const methodConcreteSelected = (method: string | undefined, chloride: string | undefined): string | undefined => {
    if (method && chloride) {
        return `${method} / ${chloride}`;
    }
    if (method) {
        return `${method}`;
    }
    return undefined;
};

export const slumpSelected = (slump: string | undefined): string => {
    if (slump) {
        return `${slump} in`;
    }
    return '';
};

export const formatMethodOfConcrete = (method: string | undefined, chloride: string | undefined): string => {
    let result: string = '';

    switch(method){
        case 'prestressedConcrete':
            result = t`Prestressed concrete`;
            break;
        case 'reinforcedConcrete':
            result = t`Reinforced concrete`;
            break;
        case 'nonReinforcedConcrete':
            result = t`Non-reinforced concrete`;
            break;
        default: 
            result = '-';
    }

    result += ` / ${chloride}`;

    return result;
};
