import { PlusOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { OrderPage } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { Badge, Breadcrumb, Button, Space, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ProjectBreadcrumb from 'src/app-react/components/Breadcrumb/ProjectBreadcrumb';
import { MARKETS } from 'src/app-react/constants/markets';
import { SUPPLIERS } from 'src/app-react/constants/suppliers';
import useModalState from 'src/app-react/hooks/useModalState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import OrderLiteLoadModal from '../shared/Modals/OrderLiteModal';
import OrderMonitoringTable from './components/OrderMonitoringTable/OrderMonitoringTable';

function OrderMonitoringPage() {
    const navigate = useNavigate();
    const [totalActive, setTotalActive] = useState(0);
    const [totalOpen, setTotalOpen] = useState(0);
    const [reLoad, setReLoad] = useState(false);
    const [totalCompleted, setTotalCompleted] = useState(0);
    const [totalCancelled, setTotalCancelled] = useState(0);
    const { projectId, supplierId, marketId, projectPermissions, supplierConfig } = useProjectState();
    const orderLiteModalState = useModalState();
    const orderLiteModalQRState = useModalState();
    const { isViewer } = useUserPermissions();

    const refreshTabCounter = (countElements: OrderPage) => {
        setTotalActive(countElements.activeTotal);
        setTotalOpen(countElements.openTotal);
        setTotalCompleted(countElements.completedTotal);
        setTotalCancelled(countElements.canceledTotal);
    };

    const forwardOrderManagement = () => {
        navigate(`/projects/${projectId}/order-type`);
    };

    const modalVisible = orderLiteModalQRState.isOpen || orderLiteModalState.isOpen;

    useEffect(() => {
        if (!modalVisible) {
            setReLoad((prev) => !prev);
        }
    }, [modalVisible]);

    const items = [
        {
            key: 'Active',
            label: (
                <>
                    <Trans>Active</Trans> <Badge count={totalActive} />
                </>
            ),
            children: (
                <OrderMonitoringTable
                    refreshCounter={refreshTabCounter}
                    tabStatus="Active"
                    reLoad={reLoad}
                    setReLoad={setReLoad}
                />
            ),
            forceRender: true
        },
        {
            key: 'Open',
            label: (
                <>
                    <Trans>Open</Trans> <Badge count={totalOpen} />
                </>
            ),
            children: <OrderMonitoringTable refreshCounter={refreshTabCounter} tabStatus="Open" />,
            forceRender: true
        },
        {
            key: 'Completed',
            label: (
                <>
                    <Trans>Completed</Trans> <Badge count={totalCompleted} />
                </>
            ),
            children: <OrderMonitoringTable refreshCounter={refreshTabCounter} tabStatus="Completed" />,
            forceRender: true
        },
        {
            key: 'Canceled',
            label: (
                <>
                    <Trans>Cancelled</Trans> <Badge count={totalCancelled} />
                </>
            ),
            children: <OrderMonitoringTable refreshCounter={refreshTabCounter} tabStatus="Canceled" />,
            forceRender: true
        }
    ];

    return (
        <>
            <AppHeader
                title={t`Order Monitoring`}
                onBack={() => navigate('/projects', { state: { projectId } })}
                breadcrumb={
                    <ProjectBreadcrumb>
                        <Breadcrumb.Item>
                            <Trans>Order monitoring</Trans>
                        </Breadcrumb.Item>
                    </ProjectBreadcrumb>
                }
            />

            <Tabs
                items={items}
                defaultActiveKey="Active"
                tabBarExtraContent={
                    <Space>
                        <Button
                            type="primary"
                            size="large"
                            block
                            data-testid="concrete-load-lite-add-btn"
                            hidden={!projectPermissions.allowCreateOrderLoadLite || marketId === MARKETS.DE}
                            onClick={orderLiteModalState.open}>
                            <PlusOutlined /> <Trans>Create delivery ticket</Trans>
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            block
                            data-testid="concrete-load-lite-qr-add-btn"
                            hidden={!projectPermissions.allowCreateOrderLoadLiteQR || marketId === MARKETS.DE}
                            onClick={orderLiteModalQRState.open}>
                            <PlusOutlined /> <Trans>Create delivery ticket (QR)</Trans>
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            block
                            disabled={supplierId !== SUPPLIERS.BERGER && supplierConfig.isManualType}
                            hidden={isViewer}
                            data-testid="structural-element-modal-add-btn"
                            onClick={() => forwardOrderManagement()}>
                            <PlusOutlined /> <Trans>Create order</Trans>
                        </Button>
                    </Space>
                }
            />

            {orderLiteModalState.isOpen && (
                <OrderLiteLoadModal open={orderLiteModalState.isOpen} close={orderLiteModalState.close} />
            )}

            {orderLiteModalQRState.isOpen && (
                <OrderLiteLoadModal
                    type="Qr"
                    open={orderLiteModalQRState.isOpen}
                    close={orderLiteModalQRState.close}
                />
            )}
        </>
    );
}

export default OrderMonitoringPage;
