import {
    CataloguedStructuralElementExtendedBP,
    ConcreteTypeExtendedBP,
    OffsetPagination
} from '@nexploretechnology/concreting-core-client';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import React, { useEffect, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { defaultPagination, pageSize, paginationAdapterReactAntdToBCQD } from 'src/app-react/utils/pagination';
import ConcreteTypeModal from '../../../../components/Modals/ConcreteTypeModal/ConcreteTypeModal';
import { concreteTypeExtendedBPToIConcreteTypeMapper } from '../../utils';
import AssignStructuralElementModal from '../AssignStructuralElementModal/AssignStructuralElementModal';
import CanBeOrderedModal from '../CanBeOrderedModal/CanBeOrderedModal';
import CatalogueConcreteTypeModalForm from '../CatalogueConcreteTypeModalForm/CatalogueConcreteTypeModalForm';
import { ConcreteTypeModalFormValuesDE, ConcreteTypeModalFormValuesUS } from '../CatalogueConcreteTypeModalForm/types';
import { ChildStructuralElementsTable } from '../StructuralElementsTable2/StructuralElementsTable';
import UnassignStructuralElementConcreteTypeModal from '../UnassignStructuralElementConcreteTypeModal/UnassignStructuralElementConcreteTypeModal';
import { ConcreteTypesTableColumnsDE } from './ConcreteTypesTable.columns.de';
import { ConcreteTypesTableColumnsUS } from './ConcreteTypesTable.columns.us';

interface IProps {
    parentStructuralElement?: CataloguedStructuralElementExtendedBP;
    reLoad: boolean;
    setReLoad: (value: boolean) => void;
    expandable?: boolean;
    setUnassignStructuralElementParentModalValue?: React.Dispatch<
        React.SetStateAction<CataloguedStructuralElementExtendedBP | undefined>
    >;
    setUnassignConcreteTypeParentModalValue?: React.Dispatch<React.SetStateAction<ConcreteTypeExtendedBP | undefined>>;
    setUnassignStructuralElementConcreteTypeParentModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    count: number;
    setCount: React.Dispatch<React.SetStateAction<number>>;
}
export default function ConcreteTypesTable({
    parentStructuralElement,
    reLoad = false,
    setReLoad,
    expandable = false,
    setUnassignStructuralElementParentModalValue,
    setUnassignConcreteTypeParentModalValue,
    setUnassignStructuralElementConcreteTypeParentModalVisible,
    count,
    setCount
}: IProps) {
    const { projectId, companyId, marketId } = useProjectState();

    const [catalogueConcreteTypeModalVisible, setCatalogueConcreteTypeModalVisible] = useState(false);
    const [catalogueConcreteTypeModalConcreteDetailsValue, setCatalogueConcreteTypeModalConcreteDetailsValue] =
        useState<ConcreteTypeExtendedBP>();
    const [catalogueConcreteTypeModalFormVisible, setCatalogueConcreteTypeModalFormModalVisible] = useState(false);
    const [catalogueConcreteTypeModalFormModalAction, setCatalogueConcreteTypeModalFormModalAction] =
        useState('create');
    const [concreteTypesInitialValues, setConcreteTypesInitialValues] = useState<
        ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS
    >();
    const [dataSource, setDataSource] = useState<ConcreteTypeExtendedBP[]>([]);
    const [assignStructuralConcreteTypeId, setAssignStructuralConcreteTypeId] = useState<string>();
    const [assignStructuralElementModalVisible, setAssignStructuralElementModalVisible] = useState(false);
    const [assignStructuralElementInitialValues, setAssignStructuralElementInitialValues] = useState<string[]>([]);
    const [assignUnassignStructuralElementModalAction, setAssignUnassignStructuralElementModalAction] = useState<
        'Assign' | 'Unassign'
    >();

    const api = useApi();

    const [unassignConcreteTypeModalValue, setUnassignConcreteTypeModalValue] = useState<
        ConcreteTypeExtendedBP | undefined
    >();
    const [unassignStructuralElementModalValue, setUnassignStructuralElementModalValue] = useState<
        CataloguedStructuralElementExtendedBP | undefined
    >();
    const [unassignStructuralElementConcreteTypeModalVisible, setUnassignStructuralElementConcreteTypeModalVisible] =
        useState(false);

    const [canBeOrderedModalValue, setCanBeOrderedModalValue] = useState<ConcreteTypeExtendedBP>();
    const [canBeOrderedModalVisible, setCanBeOrderedModalVisible] = useState(false);

    const [pagination, setPagination] = useState<OffsetPagination<ConcreteTypeExtendedBP>>(
        defaultPagination as OffsetPagination<ConcreteTypeExtendedBP>
    );

    const [triggerReloadStructuralElementsTable, setTriggerReloadStructuralElementsTable] = useState<boolean>(false);

    const loadTableData = () => {
        if (parentStructuralElement) {
            api.catalogueManagementClient
                .getAssignedExtendedConcreteTypesToCaSeAsDataList(companyId, projectId, parentStructuralElement.id, {
                    pagination
                })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        } else {
            api.catalogueManagementClient
                .getExtendedConcreteTypesForProjectAsDataList(companyId, projectId, { pagination })
                .then((res) => {
                    const { data, count } = res.getEntity();
                    setDataSource(data);
                    setCount(count);
                });
        }
    };

    useEffect(() => {
        loadTableData();
        const interval = setInterval(loadTableData, 20001);
        return () => clearInterval(interval);
    }, [parentStructuralElement, pagination]);

    useEffect(() => {
        loadTableData();
    }, [reLoad, projectId, companyId]);

    function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<ConcreteTypeExtendedBP> | SorterResult<ConcreteTypeExtendedBP>[],
        extra: TableCurrentDataSource<ConcreteTypeExtendedBP>
    ): void {
        setPagination(paginationAdapterReactAntdToBCQD(pagination, filters, sorter, extra));
    }

    const dispatchModalAction = (record: ConcreteTypeExtendedBP, action: string): void => {
        setConcreteTypesInitialValues(concreteTypeExtendedBPToIConcreteTypeMapper(record));
        setCatalogueConcreteTypeModalFormModalAction(action);
        setCatalogueConcreteTypeModalFormModalVisible(true);
    };

    const TypeNumberClicked = (record: ConcreteTypeExtendedBP): void => {
        setCatalogueConcreteTypeModalConcreteDetailsValue(record);
        setCatalogueConcreteTypeModalVisible(true);
    };

    const canBeOrderedCheckboxClicked = (record: ConcreteTypeExtendedBP): void => {
        setCanBeOrderedModalValue(record);
        setCanBeOrderedModalVisible(true);
    };

    const assignUnassignStructuralElementButtonClicked = (record: ConcreteTypeExtendedBP): void => {
        if (parentStructuralElement) {
            setUnassignStructuralElementParentModalValue?.(parentStructuralElement);
            setUnassignConcreteTypeParentModalValue?.(record);
            setUnassignStructuralElementConcreteTypeParentModalVisible?.(true);
        } else {
            setAssignStructuralElementInitialValues(
                record?.assignedCataloguedStructuralElements?.map((element) => element.id) || []
            );
            setAssignStructuralConcreteTypeId(record.id);
            setAssignStructuralElementModalVisible(true);
        }
    };

    function onAssign(record: ConcreteTypeExtendedBP): void {
        setAssignStructuralConcreteTypeId(record.id);
        setAssignUnassignStructuralElementModalAction('Assign');
        setAssignStructuralElementModalVisible(true);
    }

    function onUnassign(record: ConcreteTypeExtendedBP): void {
        setAssignStructuralElementInitialValues(
            record?.assignedCataloguedStructuralElements?.map((element) => element.id) || []
        );
        setAssignStructuralConcreteTypeId(record.id);
        setAssignUnassignStructuralElementModalAction('Unassign');
        setAssignStructuralElementModalVisible(true);
    }

    const onCompleteCatalogueConcreteTypeModalForm = async (): Promise<void> => {
        loadTableData();
        setTriggerReloadStructuralElementsTable(!triggerReloadStructuralElementsTable);
    };

    const AssignStructuralElementModal_onComplete = async (): Promise<void> => {
        setReLoad(true);
        setTriggerReloadStructuralElementsTable(!triggerReloadStructuralElementsTable);
    };

    return (
        <>
            <Table
                data-testid="ConcreteTypesTable"
                rowClassName="ConcreteTypesTable-testid"
                pagination={{ position: ['bottomRight'], defaultPageSize: pageSize, total: count, showSizeChanger: true }}
                onChange={onChange}
                showSorterTooltip={false}
                scroll={{ x: 1000 }}
                columns={{
                    DE: ConcreteTypesTableColumnsDE,
                    US: ConcreteTypesTableColumnsUS,
                    AUS: ConcreteTypesTableColumnsUS
                }[marketId as 'DE' | 'US' | 'AUS'](
                    dispatchModalAction,
                    TypeNumberClicked,
                    canBeOrderedCheckboxClicked,
                    assignUnassignStructuralElementButtonClicked,
                    onAssign,
                    onUnassign,
                    parentStructuralElement
                )}
                rowKey="id"
                dataSource={dataSource}
                expandable={{
                    expandedRowRender: (record, _index, _indent, expanded) =>
                        expanded && (
                            <ChildStructuralElementsTable
                                parentConcreteType={record}
                                expandable={false}
                                setUnassignConcreteTypeParentModalValue={setUnassignConcreteTypeModalValue}
                                setUnassignStructuralElementParentModalValue={setUnassignStructuralElementModalValue}
                                setUnassignStructuralElementConcreteTypeParentModalVisible={
                                    setUnassignStructuralElementConcreteTypeModalVisible
                                }
                                triggerReloadStructuralElementsTable={triggerReloadStructuralElementsTable}
                                setTriggerReloadStructuralElementsTable={setTriggerReloadStructuralElementsTable}
                            />
                        ),
                    rowExpandable: (record) =>
                        Boolean(expandable && record.assignedCataloguedStructuralElements?.length)
                }}
            />
            {catalogueConcreteTypeModalConcreteDetailsValue && catalogueConcreteTypeModalVisible && (
                <ConcreteTypeModal
                    data-testid="catalogue-concrete-type-modal"
                    concreteDetails={catalogueConcreteTypeModalConcreteDetailsValue}
                    open={catalogueConcreteTypeModalVisible}
                    setOpen={setCatalogueConcreteTypeModalVisible}
                />
            )}

            {catalogueConcreteTypeModalFormVisible && (
                <CatalogueConcreteTypeModalForm
                    data-testid="catalogue-concrete-type-modal-form"
                    open={catalogueConcreteTypeModalFormVisible}
                    setOpen={setCatalogueConcreteTypeModalFormModalVisible}
                    initialValues={concreteTypesInitialValues}
                    action={catalogueConcreteTypeModalFormModalAction}
                    onComplete={onCompleteCatalogueConcreteTypeModalForm}
                />
            )}

            {!parentStructuralElement && assignStructuralConcreteTypeId && assignStructuralElementModalVisible && (
                <AssignStructuralElementModal
                    data-testid="assign-structural-element-modal"
                    concreteTypeId={assignStructuralConcreteTypeId}
                    open={assignStructuralElementModalVisible}
                    setOpen={setAssignStructuralElementModalVisible}
                    action={assignUnassignStructuralElementModalAction}
                    initialValues={assignStructuralElementInitialValues}
                    onComplete={AssignStructuralElementModal_onComplete}
                />
            )}

            {!parentStructuralElement &&
                unassignConcreteTypeModalValue &&
                unassignStructuralElementModalValue &&
                unassignStructuralElementConcreteTypeModalVisible && (
                    <UnassignStructuralElementConcreteTypeModal
                        data-testid="unassign-structural-element-modal"
                        structuralElement={unassignStructuralElementModalValue}
                        concreteType={unassignConcreteTypeModalValue}
                        open={unassignStructuralElementConcreteTypeModalVisible}
                        setOpen={setUnassignStructuralElementConcreteTypeModalVisible}
                        onComplete={loadTableData}
                    />
                )}
            {canBeOrderedModalValue && canBeOrderedModalVisible && (
                <CanBeOrderedModal
                    concreteType={canBeOrderedModalValue}
                    open={canBeOrderedModalVisible}
                    setOpen={setCanBeOrderedModalVisible}
                    onComplete={loadTableData}
                />
            )}
        </>
    );
}

export function ChildConcreteTypesTable(props: Omit<IProps, 'count' | 'setCount'>) {
    const [count, setCount] = useState(0);
    return <ConcreteTypesTable {...{ ...props, count, setCount }} />;
}
