import { DeliveryConcreteInformation } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';

const categories = ['slump', 'AggregateSize', 'compressiveStrength', 'maxWaterToAdd'];

export function formatConcreteInformationUS(concreteInformation: DeliveryConcreteInformation[]) {
    const information: DeliveryConcreteInformation[] = [];

    concreteInformation.forEach((element: DeliveryConcreteInformation) => {
        if (element.category === 'concreteTypeNumber') {
            information.push({
                ...element,
                category: 'mixDesignNumber'
            });
        }
        if (categories.includes(element.category)) {
            information.push(element);
        }
    });
    return information || [];
}
