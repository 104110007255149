import { t } from '@lingui/macro';
import { Steps } from 'antd';
import { useEffect, useState } from 'react';
import { dateTimeFormatter } from 'src/app-react/utils/formatters';
import styles from './delivery-status-step.module.css';
import { DeliveryStatusBp } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
interface IStatusStep {
    status: DeliveryStatusBp[];
}

interface StatusDeliveryStep {
    description: string;
    date?: Date;
    title: string;
}

const { Step } = Steps;

const StatusDeliveryDisplayValues = ['DeliveryNoteReceived', 'Arrived', 'Unloading', 'Unloaded', 'Signed'];

function DeliveryStatusStep(props: IStatusStep) {
    const { status } = props;
    const [displaySteps, setDisplaySteps] = useState([] as StatusDeliveryStep[]);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        configureSteps();
    }, [status]);

    const getTitle = (status: string): string => {
        switch (status) {
            case 'DeliveryNoteReceived':
            case 'Loading':
            case 'OnRoute':
                return t`Start of delivery`;
            case 'Arrived':
            case 'ReleasedForUnloading':
                return t`Arrival at site`;
            case 'Unloading':
                return t`Start unloading`;
            case 'Unloaded':
                return t`End unloading`;
            case 'Signed':
                return t`Delivery Completed`;
            default:
                return t`Delivery Rejected`;
        }
    };

    const getDescription = (status: string): string => {
        switch (status) {
            case 'DeliveryNoteReceived':
            case 'Loading':
            case 'OnRoute':
                return t`The mixing plant confirmed the loading of the truck mixer`;
            case 'Arrived':
            case 'ReleasedForUnloading':
                return t`The truck mixer has reported its arrival at the construction site`;
            case 'Unloading':
                return t`The truck mixer has started unloading`;
            case 'Unloaded':
                return t`The truck mixer has finished unloading`;
            case 'Signed':
                return t`The delivery has been accepted and finished`;
            default:
                return t`The delivery was rejected`;
        }
    };

    const configureSteps = () => {
        const displayStatuses: DeliveryStatusBp[] = status.filter((entry: DeliveryStatusBp) =>
            StatusDeliveryDisplayValues.includes(entry.status)
        );
        let currentEntryIndex = 0;
        let addedStatus = 0;

        const displaySteps = StatusDeliveryDisplayValues.map((status: string, idx: number) => {
            const statusEntry = displayStatuses.filter((p) => p.status === status);
            const title: string = getTitle(status);

            if (statusEntry && statusEntry.length > 0) {
                addedStatus += 1;
                return {
                    date: statusEntry[0].dateTime,
                    description: getDescription(statusEntry[0].status),
                    title
                };
            }
            if (displayStatuses.length >= addedStatus) {
                addedStatus += 1;
            }
            return {
                description: '',
                title
            };
        });
        switch (displayStatuses[displayStatuses.length - 1].status) {
            case 'Signed':
                currentEntryIndex = 4;
                break;
            case 'Unloaded':
                currentEntryIndex = 3;
                break;

            case 'Unloading':
                currentEntryIndex = 2;
                break;
            case 'Arrived':
                currentEntryIndex = 1;
                break;
            default:
                currentEntryIndex = 0;
                break;
        }
        setCurrentStep(currentEntryIndex);
        setDisplaySteps(displaySteps);
    };

    return (
        <Steps current={currentStep} data-testid="delivery-status-step">
            {displaySteps.map((status) => {
                return (
                    <Step
                        key={status.title}
                        title={<span className={styles.title}>{status.title}</span>}
                        description={
                            <div className={styles.description}>
                                <span>
                                    {dateTimeFormatter(status.date, { dateStyle: 'medium', timeStyle: 'short' })}
                                </span>
                                <span>{status.description}</span>
                            </div>
                        }
                    />
                );
            })}
        </Steps>
    );
}

export default DeliveryStatusStep;
