import { Trans } from '@lingui/macro';
import { Divider, Table } from 'antd';
import useModalState from 'src/app-react/hooks/useModalState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { DeliveryNoteAllDetails } from '../../hooks/useDeliveryNoteDetails';
import { keyMapFromTableData } from '../../util';
import TimestampsModal from '../TimestampModal/TimestampsModal';
import styles from '../shared/shared.module.css';
import { deliveryDetailsTableTimestampsColumns } from './columns';

interface DeliveryTimestampsTableProps {
    readonly deliveryAllDetails: DeliveryNoteAllDetails;
    readonly reloadData?: (value: boolean) => void;
}

export function DeliveryTimestampsTable({ deliveryAllDetails, reloadData }: DeliveryTimestampsTableProps) {
    const { marketId } = useProjectState();

    const timestampModal = useModalState();
    const { isAllowToEditTimestamps } = useUserPermissions();

    const onEditTimestamp = () => timestampModal.open();

    return (
        <>
            <p className={styles.tableTitle}>
                <Trans>Timestamps</Trans>
            </p>
            <Table
                bordered={false}
                data-testid="delivery-details-timestamp-table"
                scroll={{ x: 1 }}
                columns={deliveryDetailsTableTimestampsColumns({
                    marketId,
                    onEditTimestamp,
                    canEdit: isAllowToEditTimestamps
                })}
                dataSource={keyMapFromTableData([deliveryAllDetails])}
                pagination={false}
                rowClassName="delivery-details-row"
            />

            <Divider />

            {timestampModal.isOpen && (
                <TimestampsModal
                    delivery={deliveryAllDetails.deliveryDetails}
                    open={timestampModal.isOpen}
                    close={timestampModal.close}
                    onComplete={() => reloadData?.(true)}
                />
            )}
        </>
    );
}
