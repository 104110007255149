import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcreteLoadLiteModalAction, OrderLoadLiteTypesValues } from '../../../models/Types';
import { OrderLiteModalForm1AUSLayout } from './components/FormAUS/Layouts/DefaultLayout';
import { OrderLiteModalForm1DELayout } from './components/FormDE/Layouts/DefaultLayout';
import { OrderLiteModalForm1USLayout } from './components/FormUS/Layouts/DefaultLayout';

interface OrderLoadLiteModalForm1Props {
    action?: ConcreteLoadLiteModalAction;
    type: OrderLoadLiteTypesValues;
    origin?: OrderLoadLiteTypesValues;
}

function OrderLiteModalForm1({ action, type, origin }: OrderLoadLiteModalForm1Props) {
    const { marketId } = useProjectState();

    switch (marketId) {
        case MARKETS.DE:
            return <OrderLiteModalForm1DELayout action={action} />;
        case MARKETS.AUS:
            return <OrderLiteModalForm1AUSLayout origin={origin} type={type} action={action} />;
        case MARKETS.US:
            return <OrderLiteModalForm1USLayout action={action} />;
        default:
            throw new Error('Invalid form market');
    }
}

export default OrderLiteModalForm1;
