import { i18n } from '@lingui/core';
import moment from 'moment';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

const DEFAULT_FORMAT = 'DD/MM/YYYY hh:mm';

export default function DateTimeFormatter({ date, format }: { date: Date | string | null, format?: string }) {
    const { formats } = useProjectConstantsState();

    return <>{date ? moment(date).locale(i18n.locale).format(format ?? formats?.dateTime ?? DEFAULT_FORMAT) : null}</>;
}