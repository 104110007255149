const LOAD_SIZES_DATA = [
    { value: 11, label: '11  yd³' },
    { value: 10.5, label: '10.5 yd³' },
    { value: 10, label: '10 yd³' },
    { value: 9.5, label: '9.5 yd³' },
    { value: 9, label: '9 yd³' },
    { value: 9.25, label: '9.25 yd³' },
    { value: 8.5, label: '8.5 yd³' },
    { value: 8, label: '8 yd³' },
    { value: 7, label: '7 yd³' },
    { value: 6, label: '6 yd³' },
    { value: 5, label: '5 yd³' },
    { value: 4, label: '4 yd³' },
    { value: 3, label: '3 yd³' },
    { value: 2, label: '2 yd³' },
    { value: 1, label: '1 yd³' }
];

export default LOAD_SIZES_DATA;
