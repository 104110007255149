import { useParams } from 'react-router-dom';
import { OrderManagementProvider } from './providers/order.management.provider';
import OrderManagementFormPage from './components/OrderManagementFormPage/OrderManagementFormPage';

const CreateOrderHandler = () => {
    const { projectId, structElementId, concreteTypeId } = useParams();

    return (
        <OrderManagementProvider projectId={projectId}>
            <OrderManagementFormPage 
                structuralElementId={structElementId} 
                concreteTypeId={concreteTypeId} 
                action='new' 
            />
        </OrderManagementProvider>
    );
};

export default  CreateOrderHandler;
