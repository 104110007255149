import { CloseCircleOutlined, EllipsisOutlined, FormOutlined } from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import {
    ConcretedStructuralElementTest,
    TypeOfMeasurement
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Dropdown, MenuProps } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import { compressiveMeasurementMapper } from 'src/app-react/utils/formatters';
import { nullableNumberTableSorter, nullableStringTableSorter } from 'src/app-react/utils/lib';

const getActions = (
    record: ConcretedStructuralElementTest,
    onEdit: (record: ConcretedStructuralElementTest) => void,
    onDelete: (id: string) => void
): MenuProps['items'] => {
    const items: MenuProps['items'] = [];

    items.push({
        label: <Trans>Edit</Trans>,
        key: `${record.id} Edit`,
        icon: <FormOutlined />,
        onClick: () => {
            onEdit(record);
        }
    });

    items.push({
        label: <Trans>Delete</Trans>,
        key: `delete`,
        icon: <CloseCircleOutlined />,
        onClick: () => {
            onDelete(record.id);
        }
    });

    return items;
};

export const compressiveMeasurementsTableColumns: (
    marketId: string,
    onEdit: (measurement: ConcretedStructuralElementTest) => void,
    onDelete: (id: string) => void
) => ColumnsType<ConcretedStructuralElementTest> = (
    marketId: string,
    onEdit: (measurement: ConcretedStructuralElementTest) => void,
    onDelete: (id: string) => void
) => {
    const columns: (ColumnType<ConcretedStructuralElementTest> & { hidden?: boolean })[] = [
        {
            title: <Trans>Type of measurement</Trans>,
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type.localeCompare(b.type),
            render: (type: TypeOfMeasurement, record: ConcretedStructuralElementTest) =>
                type === 'other' ? <p>{record.typeOther}</p> : <p>{compressiveMeasurementMapper[type]}</p>
        },

        {
            title: <Trans>Measurement time</Trans>,
            dataIndex: 'timeMeasurement',
            key: 'timeMeasurement',
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.timeMeasurement).unix() - moment(b.timeMeasurement).unix(),
            render: (timeMeasurement: Date) => <DateTimeFormatter date={timeMeasurement} />
        },

        {
            title: <Trans>Measurement value</Trans>,
            dataIndex: 'measurement',
            key: 'measurement',
            sorter: false,
            render: (_, record: ConcretedStructuralElementTest) => (
                <p>
                    <NumberFormatter
                        value={record.measurement}
                        unit={record.measurement != null ? record.measurementUnit : ''}
                    />
                </p>
            )
        },

        {
            title: <Trans>Result</Trans>,
            dataIndex: 'result',
            key: 'result',
            sorter: (a, b, sortOrder) => nullableNumberTableSorter(a.result, b.result, sortOrder),
            render: (_, record: ConcretedStructuralElementTest) => (
                <p>
                    <NumberFormatter value={record.result} unit={record.result != null ? record.resultUnit : ''} />
                </p>
            )
        },

        {
            title: <Trans>Test specimen</Trans>,
            dataIndex: 'testSpecimenTestSpecimenIdentifier',
            key: 'testSpecimenTestSpecimenIdentifier',
            sorter: (a, b, sortOrder) =>
                nullableStringTableSorter(
                    a.testSpecimenTestSpecimenIdentifier,
                    b.testSpecimenTestSpecimenIdentifier,
                    sortOrder
                ),
            render: (_, record: ConcretedStructuralElementTest) => <p>{record.testSpecimenTestSpecimenIdentifier}</p>
        },

        {
            title: <Trans>Use for Post-treatment</Trans>,
            dataIndex: 'useForPt',
            key: 'useForPt',
            hidden: marketId !== 'DE',
            sorter: (a, b) => a.useForPt.toString().localeCompare(b.useForPt.toString()),
            render: (useForPt: boolean) => <p>{useForPt ? t`Yes` : t`No`}</p>
        },

        {
            title: <Trans>Actions</Trans>,
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record: ConcretedStructuralElementTest) => (
                <Dropdown
                    key="actionButton"
                    data-testid="actionButton"
                    menu={{ items: getActions(record, onEdit, onDelete) }}
                    trigger={['click']}>
                    <EllipsisOutlined />
                </Dropdown>
            )
        }
    ];

    return columns.filter((item) => !item.hidden);
};
