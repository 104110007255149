import { PlusOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Button, Col, Row } from 'antd';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ProjectBreadcrumb from 'src/app-react/components/Breadcrumb/ProjectBreadcrumb';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import styles from './catalogue.management.page.module.css';
import CatalogueConcreteTypeModalForm from './components/CatalogueConcreteTypeModalForm/CatalogueConcreteTypeModalForm';
import {
    ConcreteTypeModalFormValuesDE,
    ConcreteTypeModalFormValuesUS
} from './components/CatalogueConcreteTypeModalForm/types';
import CatalogueTabs from './components/CatalogueTabs/CatalogueTabs';
import StructuralElementModalForm, {
    IStructuralElementDE,
    IStructuralElementUS
} from './components/StructuralElementModalForm/StructuralElementModalForm';

function CatalogueManagementPage() {
    const [structuralElementModalVisible, setStructuralElementModalVisible] = useState(false);
    const [action, setAction] = useState('create');
    const [initialValues, setInitialValues] = useState({
        externalInspection: true
    } as IStructuralElementDE | IStructuralElementUS);
    const [reLoad, setReLoad] = useState(false);
    const [activeTab, setActiveTab] = useState<string>('');
    const navigate: NavigateFunction = useNavigate();
    const { isViewer } = useUserPermissions();

    const completed = async (): Promise<void> => {
        try {
            setInitialValues({
                externalInspection: true
            } as IStructuralElementDE | IStructuralElementUS);
            setReLoad(true);
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    function onAddstructuralElement() {
        setAction('create');
        setStructuralElementModalVisible(true);
    }

    const [concreteTypeModalVisible, setConcreteTypeModalVisible] = useState(false);
    const [concreteTypeModalAction, setConcreteTypeModalAction] = useState('create');
    const [concreteTypeModalInitialValues, setConcreteTypeModalInitialValues] = useState(
        {} as ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS
    );

    function onAddConcreteType() {
        setConcreteTypeModalAction('create');
        setConcreteTypeModalInitialValues({ ...concreteTypeModalInitialValues, canBeOrdered: true });
        setConcreteTypeModalVisible(true);
    }

    return (
        <>
            <AppHeader
                title={t`Catalogue Management`}
                onBack={() => navigate('/projects')}
                breadcrumb={
                    <ProjectBreadcrumb>
                        <Breadcrumb.Item>
                            <Trans>Order monitoring</Trans>
                        </Breadcrumb.Item>
                    </ProjectBreadcrumb>
                }
            />

            <Row gutter={[48, 16]}>
                <Col span={16}>
                    <p className={styles.pageTitle} data-testid="title-catalogue-management">
                        {activeTab === 'StructuralElementsTable' ? (
                            <Trans>Structural element catalogue</Trans>
                        ) : (
                            <Trans>Concrete type catalogue</Trans>
                        )}
                    </p>
                </Col>
                <Col span={8}>
                    {activeTab === 'StructuralElementsTable' && (
                        <Button
                            type="primary"
                            size="large"
                            block
                            data-testid="structural-element-modal-add-btn"
                            hidden={isViewer}
                            onClick={() => onAddstructuralElement()}>
                            <PlusOutlined /> <Trans>Add structural element</Trans>
                        </Button>
                    )}
                    {activeTab === 'ConcreteTypesTable' && (
                        <Button
                            type="primary"
                            size="large"
                            block
                            data-testid="concrete-type-modal-add-button"
                            hidden={isViewer}
                            onClick={() => onAddConcreteType()}>
                            <PlusOutlined /> <Trans>Add concrete type</Trans>
                        </Button>
                    )}
                </Col>
            </Row>
            <Row gutter={[48, 16]}>
                <Col span={24}>
                    <CatalogueTabs reLoad={reLoad} setReLoad={setReLoad} setActiveTab={setActiveTab} />
                </Col>
            </Row>

            <CatalogueConcreteTypeModalForm
                data-testid="concrete-type-modal-form-create"
                open={concreteTypeModalVisible}
                setOpen={setConcreteTypeModalVisible}
                initialValues={concreteTypeModalInitialValues}
                action={concreteTypeModalAction}
                onComplete={() => completed()}
            />
            <StructuralElementModalForm
                data-testid="structural-element-modal-form-create"
                open={structuralElementModalVisible}
                setOpen={setStructuralElementModalVisible}
                onComplete={() => completed()}
                initialValues={initialValues}
                action={action}
            />
        </>
    );
}

export default CatalogueManagementPage;
