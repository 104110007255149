import { OffsetPagination } from '@nexploretechnology/concreting-core-client';
import {
  ConcretedStructuralElementForConcretingDocumentationBP,
  ConcretingDeliveryNoteDocumentationFilter,
  ConcretingDocumentationBPAndTotal
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import {
  ConcretingDeleiveryNoteDocumentationPagination,
  ConcretingDeliveryNoteDocumentation
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { useCallback, useRef, useState } from 'react';
import { MARKETS } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { cleanConcretingDeliveryDocumentationFilter } from '../utils/util';

export interface ConcretingDocumentationData {
    data: ConcretedStructuralElementForConcretingDocumentationBP[] | ConcretingDeliveryNoteDocumentation['data'];
    count: number;
}

function useConcretingDocumentation() {
    const api = useApi();
    const { projectId, companyId, marketId } = useProjectState();
    const [error, setError] = useState<{ message: string }>();
    const [data, setData] = useState<ConcretingDocumentationData | undefined>();
    const [loading, setLoading] = useState<boolean>(false);
    const lastCallId = useRef(0);

    const loadDataGeneric = useCallback(
        (pagination: OffsetPagination<ConcretedStructuralElementForConcretingDocumentationBP>) => {
            setLoading(true);
            api.concretingDocumentationClient
                .loadConcretingDocumentationWithTotal(projectId, companyId, pagination)
                .then((response) => {
                    setLoading(false);
                    if (response.isSuccess()) {
                        const concretingDocumentationWithTotal: ConcretingDocumentationBPAndTotal =
                            response.getEntity();

                        setData({
                            data: concretingDocumentationWithTotal.concretingDocumentations,
                            count: concretingDocumentationWithTotal.totalConcretingDocumentation
                        });
                    } else {
                        setError(response.getError());
                    }
                })
                .catch((info) => {
                    setLoading(false);
                    setError(info.errorFields[0].errors[0]);
                });
        },
        [api, projectId, companyId]
    );

    const loadDataForAUS = useCallback(
        (
            pagination: ConcretingDeleiveryNoteDocumentationPagination,
            filters?: ConcretingDeliveryNoteDocumentationFilter
        ) => {
            const id = Math.random();
            lastCallId.current = id;
            setLoading(true);
            api.concretingDocumentationClient
                .loadDeliveryNoteConcretingDocumentation({
                    companyId,
                    projectId,
                    pagination,
                    filter: cleanConcretingDeliveryDocumentationFilter(filters),
                    encodedToken: '_encodedToken'
                })
                .then((response) => {
                    if (id !== lastCallId.current) return;
                    setLoading(false);
                    if (response.isSuccess()) {
                        setData(response.getEntity() as ConcretingDocumentationData);
                    } else {
                        setError(response.getError());
                    }
                })
                .catch((info) => {
                    setLoading(false);
                    setError(info.errorFields[0].errors[0]);
                });
        },
        [api, projectId, companyId, lastCallId]
    );

    const loadData = useCallback(
        async (
            pagination:
                | OffsetPagination<ConcretedStructuralElementForConcretingDocumentationBP>
                | ConcretingDeleiveryNoteDocumentationPagination,
            filters?: ConcretingDeliveryNoteDocumentationFilter
        ) => {
            return marketId === MARKETS.AUS
                ? loadDataForAUS(pagination as ConcretingDeleiveryNoteDocumentationPagination, filters)
                : loadDataGeneric(
                      pagination as OffsetPagination<ConcretedStructuralElementForConcretingDocumentationBP>
                  );
        },
        [marketId, loadDataForAUS, loadDataGeneric]
    );

    return { data, error, loading, loadData };
}

export default useConcretingDocumentation;
