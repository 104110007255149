import { t, Trans } from '@lingui/macro';
import { Button, Form, Modal } from 'antd';
import { useEffect } from 'react';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcreteTestingTypes } from '../../utils/concrete-tests-service';
import { getModalTitle } from './util';
import { MARKET_VALUES } from 'src/app-react/constants/markets';
import {
    createInitialValues,
    createSpecimenTest,
    getSpecimentTestFormValues
} from '../MultipleConcreteTestModal/components/TestSpecimenTestForm/markets/util';
import useTestSpecimen from '../MultipleConcreteTestModal/components/TestSpecimenTestForm/hooks/useTestSpecimen';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import CommonTestForm from '../MultipleConcreteTestModal/components/CommonTestForm/CommonTestForm';
import TestSpecimenTestForm from '../MultipleConcreteTestModal/components/TestSpecimenTestForm/TestSpecimenTestForm';
import {
    createInitialCommonValues,
    getFormCommonValues
} from '../MultipleConcreteTestModal/components/CommonTestForm/util';
import { CommonTestFormValues } from '../MultipleConcreteTestModal/components/CommonTestForm/models';
import { SpecimenTestFormValues } from '../MultipleConcreteTestModal/components/TestSpecimenTestForm/markets/models';

export interface ISpecimenTestModalProps {
    orderId?: string;
    concreteLoadId?: string;
    specimenTestId?: string;

    open: boolean;
    setOpen: (isVisible: boolean) => void;

    initialFormValues?: Partial<SpecimenTestFormValues & CommonTestFormValues>;

    setModalVisible?: (isVisible: boolean) => void;

    reloadTestSpecimens?: () => Promise<void>;
    onOk?: () => void;
    onCancel?: () => void;
}

function SpecimenTestModal(props: ISpecimenTestModalProps) {
    const {
        orderId,
        concreteLoadId,
        specimenTestId,
        open,
        setOpen,
        initialFormValues,
        setModalVisible,
        reloadTestSpecimens,
        onOk,
        onCancel
    } = props;

    const [form] = Form.useForm();
    const { companyId, projectId, marketId } = useProjectState();

    const testSpecimenMutate = useTestSpecimen();

    let enabledButton = true;

    async function onSubmit(): Promise<void> {
        if (!enabledButton) return;

        enabledButton = false;

        try {
            if (!(await form.validateFields())) return;

            const testSpecimenFormValues = getSpecimentTestFormValues(form, marketId as MARKET_VALUES);
            const commonTestFormValues = { ...getFormCommonValues(form), testType: ConcreteTestingTypes.Specimen };

            const specimenTest = createSpecimenTest(
                companyId,
                projectId,
                orderId,
                concreteLoadId,
                commonTestFormValues,
                testSpecimenFormValues,
                marketId
            );

            if (!specimenTestId) {
                const { success, error } = await testSpecimenMutate(specimenTest);

                if (error || !success) {
                    let description = error;
                    if (description === 'TEST_SPECIMEN_ALREADY_EXISTS') {
                        description = t`This specimen identifier already exists, please insert a unique identifier`;
                    }

                    ErrorNotification({
                        message: 'Test specimen could not be created',
                        description: description ?? t`Unknown error`
                    });

                    return;
                }

                SuccessNotification({
                    message: t`Test specimen created successfully`,
                    description: ''
                });
                reloadTestSpecimens?.();
                setOpen(false);
                onOk?.();
            } else {
                const { success, error } = await testSpecimenMutate(specimenTest, specimenTestId);

                if (error || !success) {
                    let description = error;
                    if (description === 'TEST_SPECIMEN_ALREADY_EXISTS') {
                        description = t`This specimen identifier already exists, please insert a unique identifier`;
                    }

                    ErrorNotification({
                        message: 'Test specimen could not be updated',
                        description: description ?? t`Unknown error`
                    });

                    return;
                }

                SuccessNotification({
                    message: t`Test specimen updated successfully`,
                    description: ''
                });
                reloadTestSpecimens?.();
                setOpen(false);
                onOk?.();
            }
        } catch (err) {}
    }

    function formOnCancel(): void {
        setOpen(false);
        if (setModalVisible) {
            setModalVisible(true);
        }
        onCancel?.();
    }

    useEffect(() => {
        form.setFieldsValue(createInitialCommonValues(initialFormValues, marketId as MARKET_VALUES));
        form.setFieldsValue(createInitialValues(initialFormValues, marketId as MARKET_VALUES)); // we need to manually set it initialValues does not work even with destroyOnClose and preserve on each reopen - see https://github.com/ant-design/ant-design/issues/22171
    }, [initialFormValues, marketId, form]);

    return (
        <Modal
            maskClosable={false}
            title={<Trans>{getModalTitle(marketId as MARKET_VALUES)}</Trans>}
            destroyOnClose
            open={open}
            width={500}
            onCancel={formOnCancel}
            footer={[
                <Button data-testid="SpecimenTestModal.cancelButton" key="cancelButton" onClick={formOnCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button
                    data-testid="SpecimenTestModal.okButton"
                    key="okButton"
                    onClick={onSubmit}
                    type="primary"
                    disabled={!enabledButton}>
                    <Trans>Save</Trans>
                </Button>
            ]}>
            <Form form={form} layout="vertical" preserve={false}>
                <CommonTestForm requestTestType={false} />
                <TestSpecimenTestForm showTitle={false} />
            </Form>
        </Modal>
    );
}

export default SpecimenTestModal;
