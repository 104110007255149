import { FileOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Button } from 'antd';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ProjectBreadcrumb from 'src/app-react/components/Breadcrumb/ProjectBreadcrumb';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import DataTableConcreteDocumentation from './components/ConcretingDocumentationTable/concretingDocumentation';
import GenerateDocumentModal from './components/GenerateDocumentModal/GenerateDocumentModal';
import useConcretingDocumentation from './hooks/useConcretingDocumentation';

function ConcretingDocumentationPage() {
    const [generateDocumentModalVisible, setGenerateDocumentModalVisible] = useState(false);
    const { error, data: concretingDocumentation, loadData, loading } = useConcretingDocumentation();
    const { isForeman, isSubcontractor, isProjectAdmin, isProjectManager, isConcreteEngineer, isBatcher } =
        useUserPermissions();
    const navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        if (!error) return;
        ErrorNotification({ message: error.message, description: '' });
    }, [error]);

    return (
        <>
            <AppHeader
                title={t`Concreting Documentation`}
                onBack={() => navigate('/projects')}
                breadcrumb={
                    <ProjectBreadcrumb>
                        <Breadcrumb.Item>
                            <Trans>Order monitoring</Trans>
                        </Breadcrumb.Item>
                    </ProjectBreadcrumb>
                }
                extra={[
                    <Button
                        type="primary"
                        hidden={
                            !isProjectManager &&
                            !isProjectAdmin &&
                            !isForeman &&
                            !isBatcher &&
                            !isSubcontractor &&
                            !isConcreteEngineer
                        }
                        size="middle"
                        block={false}
                        data-testid="concreting-documentation-modal-button"
                        onClick={() => setGenerateDocumentModalVisible(true)}>
                        <FileOutlined /> <Trans>Generate documents</Trans>
                    </Button>
                ]}
            />

            <DataTableConcreteDocumentation
                tableData={concretingDocumentation?.data ?? []}
                totalData={concretingDocumentation?.count ?? 0}
                refreshTable={loadData}
                loading={loading}
            />
            <GenerateDocumentModal open={generateDocumentModalVisible} setOpen={setGenerateDocumentModalVisible} />
        </>
    );
}

export default ConcretingDocumentationPage;
