/* eslint-disable default-case */
import { t, Trans } from '@lingui/macro';
import { Checkbox, Col, Row, Form, Radio, Select, Tag } from 'antd';
import { ConcreteTestingTypes } from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';

export interface CommonTestFormProps {
    requestTestType?: boolean;
}

const { Option } = Select;

function multipleSelectTagRender(propsAssiged: any) {
    const { label, closable, onClose } = propsAssiged;

    let translatedLabel = '';
    switch (label) {
        case 'Temperature':
            translatedLabel = t`Temperature Test`;
            break;
        case 'Consistency':
            translatedLabel = t`Consistency Test`;
            break;
        case 'Specimen':
            translatedLabel = t`Test specimen`;
            break;
        case 'AirContent':
            translatedLabel = t`Air Content Test`;
            break;
        case 'WindSpeed':
            translatedLabel = t`Wind Speed Test`;
            break;
        case 'RelativeHumidity':
            translatedLabel = t`Relative Humidity Test`;
            break;
    }

    return (
        <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {translatedLabel}
        </Tag>
    );
}

function CommonTestForm({ requestTestType = true }: CommonTestFormProps) {
    const { marketId } = useProjectState();

    return (
        <>
            <Row>
                {requestTestType ? (
                    <Trans>Please select test type, sampling time and location for the test you want to perform.</Trans>
                ) : (
                    <Trans>Please enter the test results</Trans>
                )}
            </Row>

            <br />

            <Form.Item
                name="samplingLocation"
                label={<Trans>Sampling Location</Trans>}
                rules={[
                    {
                        required: true,
                        message: (
                            <span data-testid="messageSamplingLocation">
                                <Trans>This field is required</Trans>
                            </span>
                        )
                    }
                ]}>
                <Radio.Group data-testid="samplingLocation">
                    <Radio data-testid="samplingLocationTruckMixer" value="truckMixer">
                        <Trans>Truck mixer</Trans>
                    </Radio>
                    <Radio data-testid="samplingLocationConcretingLocation" value="concretingLocation">
                        <Trans>Concreting location</Trans>
                    </Radio>
                </Radio.Group>
            </Form.Item>

            {requestTestType && (
                <Form.Item
                    label={
                        <span>
                            <Trans>Test Type</Trans>
                        </span>
                    }
                    name="testType">
                    <Checkbox.Group data-testid="testType">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Row>
                                    <Checkbox value={ConcreteTestingTypes.Temperature}>
                                        <Trans>Temperature Test</Trans>
                                    </Checkbox>
                                </Row>
                                <Row>
                                    <Checkbox value={ConcreteTestingTypes.Consistency}>
                                        <Trans>Consistency Test</Trans>
                                    </Checkbox>
                                </Row>
                                <Row>
                                    <Checkbox value={ConcreteTestingTypes.Specimen}>
                                        <Trans>Test specimen</Trans>
                                    </Checkbox>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Row>
                                    <Checkbox value={ConcreteTestingTypes.AirContent}>
                                        <Trans>Air Content Test</Trans>
                                    </Checkbox>
                                </Row>
                                <Row hidden={marketId !== 'AUS'}>
                                    <Checkbox value={ConcreteTestingTypes.WindSpeed}>
                                        <Trans>Wind Speed Test</Trans>
                                    </Checkbox>
                                </Row>
                                <Row hidden={marketId !== 'AUS'}>
                                    <Checkbox value={ConcreteTestingTypes.RelativeHumidity}>
                                        <Trans>Relative Humidity Test</Trans>
                                    </Checkbox>
                                </Row>
                            </Col>
                        </Row>
                    </Checkbox.Group>
                </Form.Item>
            )}

            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="samplingDate"
                        label={<Trans>Sampling Date</Trans>}
                        rules={[
                            {
                                required: true,
                                message: (
                                    <span data-testid="messageSamplingDate">
                                        <Trans>This field is required</Trans>
                                    </span>
                                )
                            }
                        ]}>
                        <DatePicker style={{ width: '100%' }} data-testid="samplingDate" placeholder={t`Enter Date`} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="samplingTime"
                        label={<Trans>Sampling Time</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <TimePicker data-testid="samplingTime" />
                    </Form.Item>
                </Col>
            </Row>
        </>
    );
}

export default CommonTestForm;
