import { useMemo } from "react";
import { Input, Select } from "antd";
import { Trans, t } from "@lingui/macro";
import FormItem from "antd/lib/form/FormItem";
import useProjectState from "src/app-react/hooks/useProjectState";
import { createSelectOptions } from "../utils";


interface CompressiveStrengthItemProps {
    style?: React.CSSProperties;
    compressiveStrengthStyle?: React.CSSProperties;
    smallInputsStyle?: React.CSSProperties;
}

export function CompressiveStrengthItem({ style, compressiveStrengthStyle, smallInputsStyle }: CompressiveStrengthItemProps) {
    const { marketId } = useProjectState();
    const options = useMemo(() => createSelectOptions(marketId, 'typeOfConcrete'), [marketId]);

    return (
        <Input.Group style={{display: "flex", flexWrap: 'nowrap', ...style}}>
            <FormItem
                colon={false}
                label={<Trans>Compressive strength</Trans>}
                name={'typeOfConcrete'}
                style={{minWidth: '0', width: '100%', ...compressiveStrengthStyle }}>
                <Select
                    placeholder={t`Please select type of concrete type strength`}
                    style={{width: '100%'}}
                    options={options}
                    data-testid="typeOfConcreteTypeTest"/>
            </FormItem>

            <FormItem
                name={'cylindricCompressiveStrength'}
                required={false} // we want to have this field required, but without the asteriks shown. So we set it first set to not required in form item, but later the validation kicks in, because of the rules with required.
                colon={false}
                label={<label></label>}
                style={{  width: '100%', marginLeft: '8px', flex: '0 0 60px', ...smallInputsStyle }}
                rules={[
                    {
                        pattern: new RegExp(/^[1-9]\d*$/),
                        message: t`A number`
                    },
                    () => ({
                        validator(_, value) {
                            if (Number(value) < 8 || Number(value) > 160) {
                                return Promise.reject('[8,160]');
                            }
                            return Promise.resolve();
                        }
                    })
                ]}>
                <Input type="number" min={8} max={160} data-testid="cylindricCompressiveStrength" />
            </FormItem>

            <span
                style={{
                    margin: '36px 15px',
                    alignContent: 'center',
                }}>
                {'/'}
            </span>

            <FormItem
                name={'cubicCompressiveStrength'}
                required={false} // we want to have this field required, but without the asteriks shown. So we set it first set to not required in form item, but later the validation kicks in, because of the rules with required.
                colon={false}
                label={<label></label>}
                style={{ width: '100%', flex: '0 0 60px', ...smallInputsStyle }}
                rules={[
                    {
                        pattern: new RegExp(/^[1-9]\d*$/),
                        message: t`A number`
                    },
                    () => ({
                        validator(_, value) {
                            if (Number(value) < 8 || Number(value) > 160) {
                                return Promise.reject('[8,160]');
                            }
                            return Promise.resolve();
                        }
                    })
                ]}>
                <Input type="number" min={8} max={160} data-testid="cubicCompressiveStrength" />
            </FormItem>
        </Input.Group>
    )

}