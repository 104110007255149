/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form, Input } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';

function TruckNumberFormItem(props: FormItemInputProps) {
    return (
        <Form.Item name="truckNumber" label={t`License plate`} {...props}>
            <Input placeholder={t`Enter license plate`} data-testid="truckNumberFormItem" />
        </Form.Item>
    );
}

export default TruckNumberFormItem;
