/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { AutoComplete, Form } from 'antd';
import { useCallback, useEffect } from 'react';
import useGetDeliveryLocationDescriptionByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetDeliveryLocationDescriptionByProject';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import SelectFormItem from 'src/app-react/components/Form/FormItem/SelectFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';

function LocationDescriptionFormItem(props: InputFormItemProps) {
    const { loadData, locationDescriptions, error } = useGetDeliveryLocationDescriptionByProject();
    const form = Form.useFormInstance();

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    const onChange = useCallback(
        (text: string) => {
            form?.setFields([
                {
                    name: 'locationDescription',
                    value: text
                }
            ]);
        },
        [form]
    );

    const onSearch = useCallback((inputValue: string, option: ValuesDropDown) => {
        return (option?.value as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }, []);

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectFormItem label={t`Location Description`} name="locationDescription" {...props}>
            <AutoComplete
                placeholder={t`Please select a location description`}
                onChange={onChange}
                options={locationDescriptions}
                filterOption={onSearch}
            />
        </SelectFormItem>
    );
}

export default LocationDescriptionFormItem;
