import { PlusSquareOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { ColumnsType } from 'antd/lib/table';
import { StrengthDevelopmentFormatter } from 'src/app-react/components/Formatters';
import ExposureClassesFormatter from 'src/app-react/components/Formatters/ExposureClassesFormatter';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';

const NA: string = '';
interface IProps {
    expandable: boolean;
    onCatalogueStructuralElementModal: (structuralElement: CataloguedStructuralElementExtendedBP) => void;
    onCatalogueConcreteTypeModal: (structuralElementId: string) => void;
}
export const OrderStructuralElementsTableColumnsDE = ({
    expandable,
    onCatalogueStructuralElementModal,
    onCatalogueConcreteTypeModal
}: IProps): ColumnsType<CataloguedStructuralElementExtendedBP> => {
    const columns = [
        {
            title: <Trans>Structural element</Trans>,
            dataIndex: 'elementName',
            sorter: true,
            render: (structuralElementName: string, record: CataloguedStructuralElementExtendedBP) => (
                <RawLinkButton data-testid="editButton" onClick={() => onCatalogueStructuralElementModal(record)}>
                    {record ? record.structuralElementName : NA}
                </RawLinkButton>
            )
        },

        {
            title: <Trans>Description</Trans>,
            dataIndex: 'id',
            sorter: true,
            render: (structuralElementDescription: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record && record.structuralElementDescription ? record.structuralElementDescription : NA}</>
            )
        },

        {
            title: <Trans>Compressive strength class</Trans>,
            dataIndex: 'compressiveStrength',
            sorter: true,
            render: (compressiveStrength: string, record: CataloguedStructuralElementExtendedBP) => (
                <>
                    {record
                        ? `${record.minimalRequiredTypeOfConcreteType} ${record.minimalRequiredCylindricCompressiveStrength}/${record.minimalRequiredCubicCompressiveStrength}`
                        : NA}
                </>
            )
        },
        {
            title: <Trans>Exposure class</Trans>,
            dataIndex: 'id',
            render: (id: string, record: CataloguedStructuralElementExtendedBP) => 
                <ExposureClassesFormatter x0={record.requiredX0} xa={record.minimalRequiredXa} xc={record.minimalRequiredXc} xd={record.minimalRequiredXd} 
                    xfFirst={record.minimalRequiredXfFirst} xfSecond={record.minimalRequiredXfSecond} xm={record.minimalRequiredXm} xs={record.minimalRequiredXs}/>
        },
        {
            title: <Trans>Moisture class</Trans>,
            dataIndex: 'moistureClass',
            sorter: true,
            render: (moistureClass: string, record: CataloguedStructuralElementExtendedBP) => (
                <>{record ? record.minimalRequiredMoistureClass : NA}</>
            )
        },
        {
            title: <Trans>Strength development</Trans>,
            dataIndex: 'minimalRequiredStrengthDevelopment',
            sorter: true,
            render: (minimalRequiredStrengthDevelopment: number) => (
                <StrengthDevelopmentFormatter value={minimalRequiredStrengthDevelopment} />
            )
        }
    ];

    if (!expandable) {
        columns.push(displayOrderCreationColumn());
    }

    return columns;

    function displayOrderCreationColumn() {
        return {
            title: <Trans>Order</Trans>,
            dataIndex: 'order',
            sorter: false,
            fixed: 'right',

            render: (_: string, record: CataloguedStructuralElementExtendedBP) => (
                <PlusSquareOutlined
                    data-testid="createOrderBtn"
                    onClick={() => onCatalogueConcreteTypeModal(record.id)}
                    style={{ fontSize: '150%' }}></PlusSquareOutlined>
            )
        };
    }
};
