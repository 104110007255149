import {
  ConcreteTypeBPDE,
  ConcreteTypeBPUS
} from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import { IngredientBP } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { fractionToNumberOrJustNumber } from 'src/app-react/components/Formatters/FractionToNumberOrJustNumber';
import { MARKETS } from 'src/app-react/constants/markets';
import { getProjectConstants } from 'src/app-react/services/projectService';
import { ConcreteTypeModalFormValuesDE, ConcreteTypeModalFormValuesUS } from './types';
import { decimal, extractMethodOfConcreteChlorideContent, extractRequiredXX } from './util';

function mapperFormValuesToIngredientBP(
    formValues: ConcreteTypeModalFormValuesDE | ConcreteTypeModalFormValuesUS,
    marketId: string
): IngredientBP[] {
    const { units } = getProjectConstants({ marketId });
    const ingredients: IngredientBP[] = [];

    if (formValues.cements) {
        formValues.cements.forEach((cement: any) => {
            const ingredient: IngredientBP = {
                ingredientType: 'cement',
                ingredientName: cement.type,
                ingredientOrigin: cement.origin,
                density: cement.quantity,
                densityUnit: units.density
            };

            ingredients.push(ingredient);
        });
    }

    if (formValues.supplementaryCementiousMaterials) {
        formValues.supplementaryCementiousMaterials.forEach((supplementaryCementiousMaterial: any) => {
            const ingredient: IngredientBP = {
                ingredientType: 'supplementaryCementiousMaterials',
                ingredientName: supplementaryCementiousMaterial.type,
                ingredientOrigin: supplementaryCementiousMaterial.origin,
                density: supplementaryCementiousMaterial.quantity,
                densityUnit: units.density
            };

            ingredients.push(ingredient);
        });
    }

    if (formValues.admixtures) {
        formValues.admixtures.forEach((admixture: any) => {
            const ingredient: IngredientBP = {
                ingredientType: 'admixture',
                ingredientName: admixture.type,
                ingredientOrigin: admixture.origin
            };

            ingredients.push(ingredient);
        });
    }
    return ingredients;
}

export async function mapperFormValuesToConcreteTypeDE(
    companyId: string,
    projectId: string,
    id: string | undefined,
    formValues: ConcreteTypeModalFormValuesDE
): Promise<ConcreteTypeBPDE> {
    const { units } = getProjectConstants({ marketId: MARKETS.DE });
    const ingredients: IngredientBP[] = mapperFormValuesToIngredientBP(formValues, 'DE');

    if (formValues.aggregates) {
        formValues.aggregates.forEach((aggregate: any) => {
            const ingredient: IngredientBP = {
                ingredientType: 'aggregate',
                ingredientName: aggregate.type,
                ingredientOrigin: aggregate.origin,
                minAggregateSize: aggregate.minAggregateSize,
                maxAggregateSize: aggregate.maxAggregateSize,
                density: aggregate.quantity,
                densityUnit: units.density
            };

            ingredients.push(ingredient);
        });
    }
    const priceCurrency = '$';

    const { methodOfConcrete, chlorideContent } = extractMethodOfConcreteChlorideContent(
        formValues.methodOfConcreteChlorideContent
    );

    const extractedXfFirst: number = formValues.exposureClasses.requiredXf
        ? Number(formValues.exposureClasses.requiredXf.slice(0, 1))
        : undefined;
    const extractedXfSecond: number | undefined =
        formValues.exposureClasses.requiredXf && formValues.exposureClasses.requiredXf === '2/3'
            ? Number(formValues.exposureClasses.requiredXf.slice(2))
            : 0;

    return {
        id,
        projectId,
        companyId,

        typeNumber: formValues.typeNumber,

        testingAge: formValues.testingAge,
        maxAggregateSize: formValues.maxAggregateSize,
        waterCementRatio: decimal(formValues.waterCementRatio),
        price: decimal(formValues.price),
        priceCurrency,
        ingredients,
        additionalAttributes: formValues.additionalAttributes || [],
        equivalentTo: [],
        description: formValues.description,
        cataloguedStructuralElementIds: formValues.assignToStructuralElements
            ? formValues.assignToStructuralElements
            : [],

        canBeOrdered: formValues.canBeOrdered,

        typeOfConcrete: formValues.compressiveStrengthClass.requiredTypeOfConcreteType,
        cylindricCompressiveStrength: formValues.compressiveStrengthClass.cylindricCompressiveStrength,
        cubicCompressiveStrength: formValues.compressiveStrengthClass.cubicCompressiveStrength,

        consistencyType: formValues.consistencyClass.consistencyType,
        consistencyClass: formValues.consistencyClass.consistencyClass,

        moistureClass: formValues.moistureClass,
        strengthDevelopmentClass: formValues.strengthDevelopmentClass,
        densityClass: formValues.densityClass,

        methodOfConcrete: formValues.methodOfConcreteChlorideContent ? methodOfConcrete : '',
        chlorideContent: formValues.methodOfConcreteChlorideContent ? chlorideContent : '',

        x0:
            formValues.exposureClasses.requiredX0 === true || formValues.exposureClasses.requiredX0 === false
                ? formValues.exposureClasses.requiredX0
                : false,
        xc: extractRequiredXX(formValues.exposureClasses.requiredXc),
        xd: extractRequiredXX(formValues.exposureClasses.requiredXd),
        xfFirst: extractedXfFirst,
        xfSecond: extractedXfSecond,
        xa: extractRequiredXX(formValues.exposureClasses.requiredXa),
        xm: extractRequiredXX(formValues.exposureClasses.requiredXm),
        xs: extractRequiredXX(formValues.exposureClasses.requiredXs),
        externalInspection: !!formValues.externalInspection
    };
}

export async function mapperFormValuesToConcreteTypeUS(
    companyId: string,
    projectId: string,
    id: string | undefined,
    formValues: ConcreteTypeModalFormValuesUS
): Promise<ConcreteTypeBPUS> {
    const { units } = getProjectConstants({ marketId: MARKETS.US });
    const ingredients: IngredientBP[] = mapperFormValuesToIngredientBP(formValues, 'US');

    if (formValues.aggregates) {
        formValues.aggregates.forEach((aggregate: any) => {
            const aggregateMaxAggregateSize: number | undefined = fractionToNumberOrJustNumber(
                aggregate.maxAggregateSize
            );
            const ingredient: IngredientBP = {
                ingredientType: 'aggregate',
                ingredientName: aggregate.type,
                ingredientOrigin: aggregate.origin,
                maxAggregateSize: aggregateMaxAggregateSize,
                density: aggregate.quantity,
                densityUnit: units.density
            };

            ingredients.push(ingredient);
        });
    }

    const priceCurrency = '$';

    const slump = decimal(`${formValues.slump}`);
    const slumpVariance = decimal(formValues.slumpVariance);
    const maxAggregateSize = fractionToNumberOrJustNumber(formValues.maxAggregateSize);
    const price = decimal(formValues.price);
    const waterCementRatio = decimal(formValues.waterCementRatio);

    return {
        id,
        projectId,
        companyId,

        typeNumber: formValues.typeNumber,

        testingAge: formValues.testingAge,
        maxAggregateSize,
        waterCementRatio,
        price,
        priceCurrency,
        ingredients,
        additionalAttributes: formValues.additionalAttributes || [],
        equivalentTo: [],
        description: formValues.description,
        cataloguedStructuralElementIds: formValues.assignToStructuralElements
            ? formValues.assignToStructuralElements
            : [],

        canBeOrdered: formValues.canBeOrdered,

        methodOfConcrete: formValues.methodOfConcrete,

        slump,
        slumpUnit: units.slump,
        slumpVariance,
        airContentInPercent: formValues.airContentInPercent,
        compressiveStrength: formValues.compressiveStrength,
        compressiveStrengthUnit: units.compressiveStrength,
        submittalNumber: formValues.submittalNumber
    };
}
