import { useCallback, useMemo, useState } from 'react';

export interface ModalState {
    isOpen: boolean;
    close: () => void;
    open: () => void;
}
function useModalState() {
    const [isOpen, setOpen] = useState(false);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    const open = useCallback(() => {
        setOpen(true);
    }, []);

    return useMemo<ModalState>(
        () => ({
            isOpen,
            close,
            open
        }),
        [isOpen, close, open]
    );
}

export default useModalState;
