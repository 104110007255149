import { t } from '@lingui/macro';
import { useContext, useEffect } from 'react';
import useSupplierNames from 'src/app-react/business-page/concreting-documentation/hooks/useSupplierNames';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import { mapFilters } from '../../util';
import { TableSearhFilter } from './TableSearchFilter';

export function SupplierNamesInputItem() {
    const { loadSupplierNames, supplierNames, loading } = useSupplierNames();
    const { filter, setFilter } = useContext(ConcretingDocumentationContext);

    useEffect(() => {
        loadSupplierNames({ periodOfConcreting: filter?.deliveryTime });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter?.deliveryTime]);

    return (
        <TableSearhFilter
            placeholder={t`Search supplier name`}
            options={mapFilters(supplierNames)}
            onSearch={(query: string) => {
                loadSupplierNames({ query, periodOfConcreting: filter?.deliveryTime });
            }}
            onChange={(values: string[]) => {
                setFilter((prev: any) => ({
                    ...prev,
                    supplierName: values.length ? values : undefined
                }));
            }}
            loading={loading}
        />
    );
}
