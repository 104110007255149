import { t, Trans } from '@lingui/macro';
import { Form, Modal } from 'antd';
import moment from 'moment';
import { useContext } from 'react';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { mapCompressiveMeasurementValueUnit } from 'src/app-react/utils/formatters';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { EditModalFormContext } from '../../../contexts/EditModalFormContext';
import { CompressiveMeasurementEditModalForm } from './Forms';
import { ConcretedStructuralElementTest } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';

interface MeasurementEditModalProps {
    measurementTest?: ConcretedStructuralElementTest;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    triggerTableReload: () => void;
}

export function CompressiveMeasurementEditModal(props: MeasurementEditModalProps) {
    const [form] = Form.useForm();
    const { measurementTest, open, setOpen, triggerTableReload } = props;
    const { structuralElementId } = useContext(ConcretingDocumentationContext);
    const { projectId, companyId, marketId } = useProjectState();
    const api = useApi();

    function updateMeasurement(measurementTest: ConcretedStructuralElementTest) {
        api.concretingDocumentationClient
            .updateCoseTest(companyId, projectId, structuralElementId, measurementTest.id!, measurementTest)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`Compressive strength measurement has been updated successfully`,
                        description: ''
                    });
                    triggerTableReload();
                    onClose();
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function createMeasurement(measurementTest: ConcretedStructuralElementTest) {
        api.concretingDocumentationClient
            .createCoseTest(companyId, projectId, structuralElementId, measurementTest)
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: t`New compressive strength measurement has been recorded successfully`,
                        description: ''
                    });
                    triggerTableReload();
                    onClose();
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    function onFinish(fieldsValue: any) {
        form.validateFields()
            .then(() => {
                const inputMeasurement: ConcretedStructuralElementTest = {
                    concretedStructuralElementId: structuralElementId,
                    typeMeasurement: 'compressiveStrength',
                    type: fieldsValue.type,
                    result: fieldsValue?.result,
                    resultUnit: fieldsValue?.result
                        ? marketId === 'DE' || marketId === 'AUS'
                            ? 'MPa'
                            : 'psi'
                        : fieldsValue?.result,
                    measurement:
                        fieldsValue?.measurement || fieldsValue?.measurement === null
                            ? fieldsValue?.measurement
                            : undefined,
                    measurementUnit: fieldsValue?.measurement
                        ? mapCompressiveMeasurementValueUnit(fieldsValue.type, marketId)
                        : fieldsValue?.measurement,
                    timeMeasurement: mergeDateTime(fieldsValue.dateMeasurement, fieldsValue.timeMeasurement).toDate(),
                    typeOther: fieldsValue?.typeOther,
                    testSpecimenTestSpecimenIdentifier: fieldsValue?.specimenOther
                        ? fieldsValue?.specimenOther
                        : fieldsValue?.testSpecimenTestSpecimenIdentifier,
                    useForPt: fieldsValue?.useForPt
                };

                if (measurementTest) {
                    updateMeasurement({ ...measurementTest, ...inputMeasurement });
                } else {
                    createMeasurement(inputMeasurement);
                }
            })
            .catch((e) => {
                ErrorNotification({ message: e.code, description: e.message });
            });
    }

    function setInitialValues() {
        if (measurementTest) {
            const initialValues = {
                ...measurementTest,
                result: measurementTest.result || null,
                measurement: measurementTest.measurement || null,
                dateMeasurement: moment(measurementTest.timeMeasurement),
                timeMeasurement: moment(measurementTest.timeMeasurement)
            };

            form.setFieldsValue(initialValues);
            return initialValues;
        }

        form.setFieldsValue({
            type: undefined,
            measurement: undefined,
            result: undefined,
            typeOther: undefined,
            timeMeasurement: undefined,
            dateMeasurement: undefined,
            useForPt: undefined,
            testSpecimenTestSpecimenIdentifier: undefined
        });

        return undefined;
    }

    function onClose() {
        setOpen(false);
    }

    return (
        <Modal
            maskClosable={false}
            open={open}
            data-testid="compressive-measurement-modal"
            title={
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <label style={{ fontSize: '1rem', float: 'left', color: '#8C8C8C' }}>
                        <Trans>POST TREATMENT</Trans>
                    </label>
                    {measurementTest ? (
                        <Trans>Edit compressive strength measurement</Trans>
                    ) : (
                        <Trans>Record compressive strength measurement</Trans>
                    )}
                </div>
            }
            okText={<Trans>Save</Trans>}
            cancelText={<Trans>Cancel</Trans>}
            onOk={() => form.submit()}
            onCancel={onClose}
            destroyOnClose
            width={700}>
            <EditModalFormContext.Provider value={{ formInitialValues: setInitialValues() }}>
                <CompressiveMeasurementEditModalForm
                    form={form}
                    initialValues={setInitialValues()}
                    onFinish={onFinish}
                />
            </EditModalFormContext.Provider>
        </Modal>
    );
}
