import { InfoCircleOutlined } from '@ant-design/icons';
import { t } from '@lingui/macro';
import { Alert, Form } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';

function OrderLiteModalForm2Alert() {
    const { marketId } = useProjectState();
    const form = Form.useFormInstance();
    const concreteType = form.getFieldValue('concreteType');
    const compressiveStrength = form.getFieldValue('compressiveStrength');
    const maxAggregatonSize = form.getFieldValue('maxAggregateSize');

    return (marketId === MARKETS.DE && concreteType) ||
        (marketId === MARKETS.AUS && (compressiveStrength || maxAggregatonSize)) ? (
        // eslint-disable-next-line react/jsx-indent
        <>
            <Alert
                type="info"
                showIcon
                icon={<InfoCircleOutlined />}
                message={t`Prefilled values are based on the selected concrete type`}
            />
            <br /> <br />
        </>
    ) : null;
}

export default OrderLiteModalForm2Alert;
