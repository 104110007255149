const LOAD_SIZES_DATA = [
    { value: 11, label: '11 m³' },
    { value: 10.5, label: '10.5 m³' },
    { value: 10, label: '10 m³' },
    { value: 9.5, label: '9.5 m³' },
    { value: 9, label: '9 m³' },
    { value: 9.25, label: '9.25 m³' },
    { value: 8.5, label: '8.5 m³' },
    { value: 8, label: '8 m³' },
    { value: 7, label: '7 m³' },
    { value: 6, label: '6 m³' },
    { value: 5, label: '5 m³' },
    { value: 4, label: '4 m³' },
    { value: 3, label: '3 m³' },
    { value: 2, label: '2 m³' },
    { value: 1, label: '1 m³' }
];

export default LOAD_SIZES_DATA;
