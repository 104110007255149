import { Form, FormProps } from 'antd';
import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { PostTreatmentEditlModalFormDE } from './DE';
import { PostTreatmentEditlModalFormUS } from './US';

export function PostTreatmentEditModalForm(props: Partial<FormProps>) {
    const { marketId } = useProjectState();

    let children: any;

    switch (marketId) {
        case MARKETS.DE:
            children = <PostTreatmentEditlModalFormDE />;
            break;
        case MARKETS.AUS:
        case MARKETS.US:
            children = <PostTreatmentEditlModalFormUS />;
            break;
        default:
            children = null;
            break;
    }

    return (
        <Form {...props} name="postTreatmentModalForm" data-testid="postTreatmentModalForm" layout="vertical">
            {children}
        </Form>
    );
}
