import { Trans } from '@lingui/macro';
import { Form, Radio } from 'antd';
import styles from '../../../../../OrderManagementFormPage.module.css';

export function AdditionalQuantityFormItem() {
    return (
        <Form.Item
            name="additionalQuantity"
            label={<Trans>With / Without additional quantity</Trans>}
            rules={[{
                required: true,
                message: (
                    <span className={styles.radioError} data-testid="errorInputAddQuantity">
                        <Trans>This field is required</Trans>
                    </span>
                )
            }]}>
            <Radio.Group data-testid="additionalQuantity" style={{ justifyContent: 'flex-start' }}>
                <Radio data-testid="withAdditionalQuantity" value={true}>
                    <Trans>With additional quantity</Trans>
                </Radio>
                <Radio data-testid="withOutAdditionalQuantity" value={false}>
                    <Trans>Without additional quantity</Trans>
                </Radio>
            </Radio.Group>
        </Form.Item>
    );
}
