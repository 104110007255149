import { ROLES } from 'src/app-react/constants/roles';

export default function buildRolePermissions(roles: string[]) {
    return {
        isForeman: roles.includes(ROLES.FOREMAN),
        isSubcontractor: roles.includes(ROLES.SUBCONTRACTOR),
        isViewer: roles.includes(ROLES.VIEWER),
        isProjectAdmin: roles.includes(ROLES.PROJECT_ADMIN),
        isProjectManager: roles.includes(ROLES.PROJECT_MANAGER),
        isConcreteEngineer: roles.includes(ROLES.CONCRETE_ENGINEER),
        isBatcher: roles.includes(ROLES.BATCHER)
    };
}
