import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function SupplierProjectIdFormItem() {
    return (
        <FormItem
            name="supplierProjectId"
            label={<Trans>Supplier software jobsite code</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>Please input supplier software jobsite code</Trans>
                }
            ]}>
            <Input type="text" data-testid="supplierProjectId" placeholder={t`Enter jobsite code`} />
        </FormItem>
    );
}
