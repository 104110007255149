/* eslint-disable react/require-default-props */

import { useEffect } from 'react';
import { t } from '@lingui/macro';
import { Col, Form, Row, Select, Switch } from 'antd';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

const DEPENDENCIES = ['requiredXc', 'requiredXd', 'requiredXf', 'requiredXa', 'requiredXm', 'requiredXs'];

function ExposureClassFormItem({ parentName, required = true }: { parentName?: string; required?: boolean }) {
    const form = Form.useFormInstance();
    const constants = useProjectConstantsState();
    const getName = (n: string) => (parentName ? [parentName, n] : n);
    const requiredX0 = Form.useWatch(getName('requiredX0'), form);

    useEffect(() => {
        if (requiredX0) {
            const fields = DEPENDENCIES.reduce<Record<string, null>>((memo, field) => {
                // eslint-disable-next-line no-param-reassign
                memo[field] = null;
                return memo;
            }, {});
            form.setFieldsValue(parentName ? { [parentName]: fields } : fields);
        }
    }, [requiredX0, form]);

    return (
        <Form.Item
            required={required}
            name="__ExposureClass"
            label={t`Exposure class`}
            style={{ width: '100%' }}
            dependencies={DEPENDENCIES}
            rules={[
                { required: false },
                ({ getFieldValue }) => ({
                    validator() {
                        if (requiredX0 || required === false) {
                            return Promise.resolve();
                        }

                        const valid = DEPENDENCIES.some((field) => {
                            const value = getFieldValue(getName(field));
                            return value !== '' && value != null;
                        });

                        if (valid) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error(requiredRule.message));
                    }
                })
            ]}>
            <Row gutter={8}>
                <Col span="2">
                    <Form.Item label={t`X0`} name={getName('requiredX0')} valuePropName="checked">
                        <Switch data-testid="requiredX0" />
                    </Form.Item>
                </Col>
                <Col span="4">
                    <Form.Item label={t`XC`} name={getName('requiredXc')}>
                        <Select
                            disabled={requiredX0}
                            allowClear
                            options={constants.exposureClassXc}
                            data-testid="requiredXc"
                        />
                    </Form.Item>
                </Col>
                <Col span="4">
                    <Form.Item label={t`XD`} name={getName('requiredXd')}>
                        <Select
                            disabled={requiredX0}
                            allowClear
                            options={constants.exposureClassXd}
                            data-testid="requiredXd"
                        />
                    </Form.Item>
                </Col>
                <Col span="4">
                    <Form.Item label={t`XF`} name={getName('requiredXf')}>
                        <Select
                            disabled={requiredX0}
                            allowClear
                            options={constants.exposureClassXf}
                            data-testid="requiredXf"
                        />
                    </Form.Item>
                </Col>
                <Col span="4">
                    <Form.Item label={t`XA`} name={getName('requiredXa')}>
                        <Select
                            disabled={requiredX0}
                            allowClear
                            options={constants.exposureClassXa}
                            data-testid="requiredXa"
                        />
                    </Form.Item>
                </Col>
                <Col span="3">
                    <Form.Item label={t`XM`} name={getName('requiredXm')}>
                        <Select
                            disabled={requiredX0}
                            allowClear
                            options={constants.exposureClassXm}
                            data-testid="requiredXm"
                        />
                    </Form.Item>
                </Col>
                <Col span="3">
                    <Form.Item label={t`XS`} name={getName('requiredXs')}>
                        <Select
                            disabled={requiredX0}
                            allowClear
                            options={constants.exposureClassXs}
                            data-testid="requiredXs"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form.Item>
    );
}

export default ExposureClassFormItem;
