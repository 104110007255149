import { i18n } from '@lingui/core';
import { Trans, t } from "@lingui/macro";
import { Form, Input } from "antd";
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

export function SlumpFormItem() {
    const { units } = useProjectConstantsState();

    return <Form.Item
        name="flowTableTest"
        label={<Trans>Flow Table Test</Trans>}
        rules={[
            {
                required: true,
                pattern:
                    i18n.locale === 'de-DE'
                        ? new RegExp(/^\d+(?:,\d{1,2})?$/)
                        : new RegExp(/^\d+(?:\.\d{1,2})?$/),
                message: t`Please introduce a valid input`
            }
        ]}>
        <Input
            type="text"
            data-testid="flowTableTest"
            addonAfter={units.slump}
            placeholder={t`Enter the test results`}
        />
    </Form.Item>
}