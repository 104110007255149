import { Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { useContext } from 'react';
import { SUPPLIERS_DATA } from 'src/app-react/constants/suppliersData';
import { SupplierConfigFormContext } from '../../../../contexts/SupplierConfigFormContext';

export function SupplierSoftwareFormItem() {
    const { marketId } = useContext(SupplierConfigFormContext);
    const form = Form.useFormInstance();

    const options = Object.entries(SUPPLIERS_DATA).reduce((memo, [supplier, supplierData]) => {
        if (supplierData.market === marketId && supplierData.apiIntegration) {
            memo.push(
                <Select.Option value={supplierData.softwareName} key={supplierData.softwareName} data-testid={supplier}>
                    {supplierData.label}
                </Select.Option>
            );
        }

        return memo;
    }, []);

    return (
        <FormItem
            name="supplierSoftware"
            label={<Trans>Supplier software</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Select
                style={{ width: '100%' }}
                data-testid="supplierSoftware"
                placeholder={<Trans>Select a supplier software</Trans>}
                onChange={() => form.setFieldsValue({ supplierName: undefined })}>
                {options}
            </Select>
        </FormItem>
    );
}
