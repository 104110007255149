/* eslint-disable react/jsx-props-no-spreading */

import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import { InputNumber } from 'src/app-react/components/Form';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';

function DistanceToPlantFormItem(props: InputFormItemProps) {
    return (
        <Form.Item
            data-testid="distanceToPlantFormItem"
            label={<Trans>Distance to plant</Trans>}
            name="locationDistanceToPlant"
            {...props}>
            <InputNumber data-testid="distanceToPlant" placeholder={t`Enter distance to plant`} />
        </Form.Item>
    );
}

export default DistanceToPlantFormItem;
