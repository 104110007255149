import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import {
    createInitialValues as createInitialValuesDE,
    createSpecimenTestDE,
    getSpecimentTestFormValuesDE,
    specimenIdentificationTitleDE
} from '../markets/DE/util';
import {
    createInitialValues as createInitialValuesUS,
    createSpecimenTestUS,
    getSpecimentTestFormValuesUS,
    specimenIdentificationTitleUS
} from '../markets/US/util';
import { createInitialValues as createInitialValuesAUS } from '../markets/AUS/util';
import { FormInstance } from 'antd';
import { TestSamplingWithType } from '../../CommonTestForm/models';
import { SpecimenTestFormValues } from './models';
import { newFreshConcreteTest } from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import { SpecimenTestFormValuesDE } from './DE/models';
import { SpecimenTestFormValuesUS } from './US/models';
import { FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValues(values: any, marketId: MARKET_VALUES, deliveryNoteNumber?: string) {
    switch (marketId) {
        case MARKETS.DE:
            return createInitialValuesDE(values);
        case MARKETS.AUS:
            return createInitialValuesAUS(values, deliveryNoteNumber);
        default:
            return createInitialValuesUS(values);
    }
}

export function getSpecimenIdentificationTitle(marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return specimenIdentificationTitleDE;
        default:
            return specimenIdentificationTitleUS;
    }
}

export function getSpecimentTestFormValues(values: FormInstance<any>, marketId: MARKET_VALUES) {
    switch (marketId) {
        case MARKETS.DE:
            return getSpecimentTestFormValuesDE(values);
        default:
            return getSpecimentTestFormValuesUS(values);
    }
}

export function createSpecimenTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    commonTest: TestSamplingWithType,
    specimenTestFormValues: SpecimenTestFormValues,
    marketId: string
) {
    const freshConcreteTest: FreshConcreteTest = newFreshConcreteTest(
        companyId,
        projectId,
        orderId,
        concreteLoadId,
        commonTest
    );

    switch (marketId) {
        case MARKETS.DE:
            return createSpecimenTestDE(freshConcreteTest, specimenTestFormValues as SpecimenTestFormValuesDE);
        default:
            return createSpecimenTestUS(freshConcreteTest, specimenTestFormValues as SpecimenTestFormValuesUS);
    }
}
