import { t, Trans } from '@lingui/macro';
import { PostTreatment } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import { postTreatmentMapper } from 'src/app-react/utils/formatters';

export const PostTreatmentDetailsTableColumns: () => ColumnsType<PostTreatment> = () => {
    return [
        {
            title: <Trans>Post-treatment type</Trans>,
            dataIndex: 'types',
            key: 'types',
            sorter: (a, b) => a.types.localeCompare(b.types),
            render: (types: string, record: PostTreatment) =>
                types === 'other' ? <p>{record.typeOther}</p> : <p>{postTreatmentMapper[types]}</p>
        },

        {
            title: <Trans>Starting Date</Trans>,
            dataIndex: 'postTreatmentStart',
            key: 'postTreatmentStart',
            width: 150,
            defaultSortOrder: 'ascend',
            sorter: (a, b) => moment(a.postTreatmentStart).unix() - moment(b.postTreatmentStart).unix(),
            render: (postTreatmentStart: Date) => <DateFormatter date={postTreatmentStart} />
        },

        {
            title: <Trans>Ending Date</Trans>,
            dataIndex: 'postTreatmentEnd',
            key: 'postTreatmentEnd',
            width: 150,
            sorter: (a, b) => moment(a.postTreatmentEnd).unix() - moment(b.postTreatmentEnd).unix(),
            render: (postTreatmentEnd: Date) => <DateFormatter date={postTreatmentEnd} />
        },

        {
            title: <Trans>Duration</Trans>,
            dataIndex: 'duration',
            key: 'duration',
            sorter: false,
            width: 150,
            render: (_, record: PostTreatment) => (
                <p>
                    {moment(record.postTreatmentEnd).diff(record.postTreatmentStart, 'days') + 1} {t`day(s)`}
                </p>
            )
        },

        {
            title: <Trans>Comments</Trans>,
            width: 200,
            dataIndex: 'comment',
            key: 'comment',
            sorter: false,
            render: (comment: string) => <p>{comment}</p>
        }
    ];
};
