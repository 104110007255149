import { Trans } from '@lingui/macro';
import { Radio } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function SupplierUIFormItem() {
    return (
        <FormItem
            name="useSupplierUI"
            label={<Trans>Supplier UI</Trans>}
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Radio.Group data-testid="useSupplierUI" style={{ justifyContent: 'flex-start', gap: '1rem' }}>
                <Radio data-testid="withSupplierUI" value>
                    <Trans>Yes</Trans>
                </Radio>
                <Radio data-testid="withoutSupplierUI" value={false}>
                    <Trans>No</Trans>
                </Radio>
            </Radio.Group>
        </FormItem>
    );
}
