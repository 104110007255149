import { Userpilot } from 'userpilot';

let userpilotEnabled = false;

export function initialize(upilotToken?: string) {
    if (!upilotToken) return;
    Userpilot.initialize(upilotToken);
    userpilotEnabled = true;
}

export function identifyUser(userId: string) {
    if (!userpilotEnabled || !userId) {
        return;
    }

    Userpilot.identify(userId, {
        id: userId
    });
}

export function notifyPageChange() {
    if (!userpilotEnabled) {
        return;
    }

    Userpilot.reload();
}
