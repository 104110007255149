import * as React from 'react';

export interface CatalogueManagementProviderProps {
    projectId: string;
}

type ProviderChildren = { projectId: string; children: React.ReactNode };

const CatalogueManagementContext = React.createContext({} as CatalogueManagementProviderProps);

function CatalogueManagementProvider({ projectId, children }: ProviderChildren) {
    const value: CatalogueManagementProviderProps = {
        projectId
    };
    return <CatalogueManagementContext.Provider value={value}>{children}</CatalogueManagementContext.Provider>;
}

export { CatalogueManagementContext, CatalogueManagementProvider };

