import { Breadcrumb } from 'antd';
import { useNavigate } from 'react-router-dom';
import RawLinkButton from 'src/app-react/components/RawLinkButton/RawLinkButton';
import useProjectState from 'src/app-react/hooks/useProjectState';

export default function DeliveryDashboardBreadcrumb() {
    const { project, company } = useProjectState();
    const navigate = useNavigate();

    return (
        <Breadcrumb>
            <Breadcrumb.Item>{company.companyName}</Breadcrumb.Item>
            <Breadcrumb.Item>
                <RawLinkButton onClick={() => navigate(`/projects`)}>{project.name}</RawLinkButton>
            </Breadcrumb.Item>
        </Breadcrumb>
    );
}
