import { Trans } from '@lingui/macro';
import { SupplierProducedConcrete } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Col } from 'antd';
import ExposureClassesFormatter from '../../../../Formatters/ExposureClassesFormatter';
import styles from '../../ConcreteTypeModal.module.css';

export function ExposureClass({ concreteDetails }: { concreteDetails: SupplierProducedConcrete }) {
    return (
        <>
            <Col span={24}>
                <label className={styles.modalLabel}>
                    <Trans>Exposure Class</Trans>
                </label>
            </Col>

            <Col className={styles.colWithValue} data-testid="concreteTypeModal-exposureClass" span={24}>
                <ExposureClassesFormatter
                    x0={concreteDetails.x0}
                    xa={concreteDetails.xa}
                    xc={concreteDetails.xc}
                    xd={concreteDetails.xd}
                    xfFirst={concreteDetails.xfFirst}
                    xfSecond={concreteDetails.xfSecond}
                    xm={concreteDetails.xm}
                    xs={concreteDetails.xs}
                />
            </Col>
        </>
    );
}
