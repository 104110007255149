/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { isValidElement, cloneElement } from 'react';
import { Form, FormItemProps, RadioGroupProps } from 'antd';
import parseRules from '../utils/parseRules';

export interface RadioGroupFormItemProps extends FormItemProps {
    radioGroupProps?: RadioGroupProps & {
        'data-testid'?: string;
    };
}

function RadioGroupFormItem({ required, radioGroupProps, ...props }: RadioGroupFormItemProps) {
    return (
        <Form.Item {...props} rules={parseRules(required, props)}>
            {isValidElement(props.children) && cloneElement(props.children, radioGroupProps)}
        </Form.Item>
    );
}

export default RadioGroupFormItem;
