import { MARKETS } from 'src/app-react/constants/markets';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { SlumpFormItem as SlumpFormItemDE } from './DE/SlumpFormItem';
import { SlumpFormItem as SlumpFormItemUS } from './US/SlumpFormItem';

export function SlumpFormItem() {
    const { marketId } = useProjectState();
    switch (marketId) {
        case MARKETS.DE:
            return <SlumpFormItemDE />;
        case MARKETS.AUS:
            return <SlumpFormItemUS allowDecimals={false} />;
        default:
            return <SlumpFormItemUS allowDecimals={true} />;
    }
}
