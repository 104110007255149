import { Trans } from '@lingui/macro';

const STRENGTH_DEVELOPMENT_CLASS = [
    {
        value: 'fast',
        label: <Trans>Fast</Trans>
    },
    {
        value: 'intermediate',
        label: <Trans>Intermediate</Trans>
    },
    {
        value: 'slow',
        label: <Trans>Slow</Trans>
    },
    {
        value: 'very slow',
        label: <Trans>Very slow</Trans>
    }
];

export default STRENGTH_DEVELOPMENT_CLASS;
