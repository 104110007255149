/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { AutoComplete, Form } from 'antd';
import { useCallback, useEffect } from 'react';
import useGetSupplierOrderIdsByProject from 'src/app-react/business-page/shared/Modals/OrderLiteModal/hooks/useGetSupplierOrderIdsByProject';
import {
    CONCRETE_LOAD_LITE_MODAL_ACTION,
    ConcreteLoadLiteModalAction
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import { InputFormItemProps } from 'src/app-react/components/Form/FormItem/InputFormItem';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { ValuesDropDown } from 'src/app-react/constants/marketsData';

interface SupplierOrderIdFormItemProps extends InputFormItemProps {
    action?: ConcreteLoadLiteModalAction;
}

function SupplierOrderIdFormItem({ action, ...props }: SupplierOrderIdFormItemProps) {
    let isReadOnly = false;

    const { loadData, supplierNumberIds, error } = useGetSupplierOrderIdsByProject();
    const form = Form.useFormInstance();

    useEffect(() => {
        loadData(undefined, undefined);
    }, [loadData]);

    if (action === CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT) {
        isReadOnly = true;
    }

    useEffect(() => {
        if (error) {
            ErrorNotification({
                message: error.message || 'unexpected error',
                description: error.description
            });
        }
    }, [error]);

    const onChange = useCallback(
        (text: string) => {
            form?.setFields([
                {
                    name: 'supplierOrderId',
                    value: text
                }
            ]);
        },
        [form]
    );

    const onSearch = useCallback((inputValue: string, option: ValuesDropDown) => {
        return (option?.value as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1;
    }, []);

    return (
        <Form.Item name="supplierOrderId" label={t`Supplier Order / Job Number`} {...props}>
            <AutoComplete
                disabled={isReadOnly}
                placeholder={t`Enter Supplier Order / Job Number`}
                data-testid="supplierOrderIdFormItem"
                onChange={onChange}
                options={supplierNumberIds}
                filterOption={onSearch}
            />
        </Form.Item>
    );
}

export default SupplierOrderIdFormItem;
