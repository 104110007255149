import { t } from '@lingui/macro';
import { Form } from 'antd';
import WeatherFormItemBase from 'src/app-react/business-page/shared/FormItems/WeatherFormItem';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';

function WeatherFormItem() {
    const signedOn = Form.useWatch('signedOn');

    return (
        <WeatherFormItemBase
            name="weather"
            label={t`Weather during the concreting`}
            radioGroupProps={{
                disabled: !signedOn
            }}
            dependencies={['signedOn']}
            rules={[
                {
                    message: requiredRule.message,
                    required: !!signedOn
                }
            ]}
        />
    );
}

export default WeatherFormItem;
