import { t, Trans } from '@lingui/macro';
import { Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useContext, useEffect, useState } from 'react';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { DeliveryDashboardContext } from '../../../context/DeliveryDahsboardPageContext';

function TagRender(props: CustomTagProps) {
    const { label, closable, onClose } = props;

    return (
        <Tag
            closable={closable}
            onClose={onClose}
            style={{ maxWidth: 150, display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
            <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{label}</div>
        </Tag>
    );
}

export function UnloadingLocationDescriptionInputItem() {
    const api = useApi();
    const { projectId, companyId } = useProjectState();
    const [unloadingLocationDescriptions, setUnloadingLocationDescriptions] = useState<string[]>([]);
    const { filter, setFilter } = useContext(DeliveryDashboardContext);

    function onChange(value: string[]) {
        const newFilter = { ...filter, unloadingLocationDescriptions: value };
        setFilter(newFilter);
    }

    function loadUnloadingLocationDescriptions() {
        api.concreteLoadClient
            .loadUnloadingLocationDescriptionsByProject(companyId, projectId, filter, undefined)
            .then((response) => {
                if (response.isSuccess()) {
                    setUnloadingLocationDescriptions(response.getEntity());
                } else {
                    const msg = JSON.parse(response.getError());
                    ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                }
            })
            .catch((info) => {
                ErrorNotification({
                    message: info.errorFields[0].errors[0],
                    description: ''
                });
            });
    }

    useEffect(() => {
        loadUnloadingLocationDescriptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return (
        <>
            <b>
                <Trans>Unloading Location descriptions</Trans>
            </b>
            <Select
                options={unloadingLocationDescriptions.map((description) => ({
                    value: description,
                    label: description
                }))}
                data-testid="unloadingLocationDescriptions"
                mode="multiple"
                allowClear
                tagRender={TagRender}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                value={filter?.unloadingLocationDescriptions}
                placeholder={t`Select unloading location description`}
                style={{ width: '100%' }}
            />
        </>
    );
}
