import { Space, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConcreteTypeModal from 'src/app-react/components/Modals/ConcreteTypeModalForDocumentation/ConcreteTypeModal';
import { StructuralElementModal } from 'src/app-react/components/Modals/StructuralElementModal/StructuralElementModal';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { ConcretingDocumentationContext } from '../../providers/concreting-documentation.provider';
import { PostTreatmentDetailsModal } from '../PostTreatmentDetailsModal';

import {
    ConcretedStructuralElementBP,
    ConcretedStructuralElementForConcretingDocumentationBP,
    ConcretingDeliveryNoteDocumentationFilter,
    SupplierProducedConcrete
} from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Direction, OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import { MARKETS, MARKET_VALUES } from 'src/app-react/constants/markets';
import useApi from 'src/app-react/hooks/useApi';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import { ConcretingDocumentationData } from '../../hooks/useConcretingDocumentation';
import { ConcretedStruturalElementModalEdit } from '../views/ConcretedStructuralElement/components/ConcretedStructuralElementEditModal';
import { ConcretingDocumentationFilters } from './ConcretingDocumentationFilters';
import { ConcretingDocumentationTable } from './markets/concretingDocumentationTable';
import { parseQueryParamsPerMarket } from './util';

type SortingType = Partial<Record<keyof ConcretedStructuralElementForConcretingDocumentationBP, Direction>> | undefined;

export interface IDataTableConcretingDocumentation {
    tableData: ConcretingDocumentationData['data'];
    totalData: ConcretingDocumentationData['count'];
    refreshTable(queryParams: any, filters?: ConcretingDeliveryNoteDocumentationFilter): Promise<void>;
    loading: boolean;
}

const pageSize = 10;

function DataTableConcreteDocumentation(props: IDataTableConcretingDocumentation) {
    const { tableData, totalData, refreshTable, loading } = props;
    const { documentationPaginationState, coseFocused, setGlobalState } = useGlobalState();
    const { projectId, companyId, marketId } = useProjectState();
    const navigate = useNavigate();
    const [isCoseModalVisible, setCoseModalVisible] = useState(false);
    const { filter, setFilter } = useContext(ConcretingDocumentationContext);
    const [coseForPostTreatmentModal, setCoseForPostTreatmentModal] = useState(
        {} as ConcretedStructuralElementForConcretingDocumentationBP
    );

    const [isPostTreatmentModalVisible, setPostTreatmentModalVisible] = useState(false);
    const [concreteTypenDetailsData, setConcreteTypeData] = useState<SupplierProducedConcrete>(
        {} as SupplierProducedConcrete
    );
    const [isConcreteTypenModalVisible, setConcreteTypeModalVisible] = useState(false);
    const [isCoseEditModalVisible, setCoseEditModalVisible] = useState(false);

    const [pagination, setPagination] = useState(
        documentationPaginationState ||
            ({
                position: ['bottomRight'],
                pageSize,
                total: totalData,
                current: 1,
                showSizeChanger: true
            } as TablePaginationConfig)
    );

    const [lastSorting, setLastSorting] = useState<SortingType>();

    const api = useApi();
    const [structuralElementDetailsData, setStructuralElementData] = useState<ConcretedStructuralElementBP>(
        {} as ConcretedStructuralElementBP
    );

    async function onChange(
        tablePagination: TablePaginationConfig,
        tableFilter: Record<string, string[] | null>,
        sorter: SorterResult<ConcretedStructuralElementForConcretingDocumentationBP>
    ) {
        setFilter((prev: any) => ({ ...prev, ...tableFilter }));
        setPagination(tablePagination);
        let sorting: SortingType;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
            const sortingValue = `{"${
                (sorter as SorterResult<ConcretedStructuralElementForConcretingDocumentationBP>).columnKey
            }": "${sortDirection || 'DESC'}"}`;
            sorting = JSON.parse(sortingValue);
        }

        setLastSorting(sorting);

        const queryParams: OffsetPagination<ConcretedStructuralElementForConcretingDocumentationBP> = {
            skip: tablePagination.current === 0 ? 0 : (tablePagination.current - 1) * tablePagination.pageSize,
            limit: tablePagination.pageSize,
            sorting: sorting ? [sorting] : []
        };

        const parsed = parseQueryParamsPerMarket(marketId as MARKET_VALUES, queryParams);

        await refreshTable(parsed, tableFilter);
    }

    const triggerReload = useCallback(async () => {
        const queryParams: OffsetPagination<ConcretedStructuralElementForConcretingDocumentationBP> = {
            skip: pagination.current === 0 ? 0 : (pagination.current - 1) * pagination.pageSize,
            limit: pagination.pageSize,
            sorting: lastSorting ? [lastSorting] : []
        };
        await refreshTable(parseQueryParamsPerMarket(marketId as MARKET_VALUES, queryParams), filter);
    }, [pagination, refreshTable, lastSorting, filter, marketId]);

    const getStructuralElement = async (structuralElementId: string, onSuccess: () => void) => {
        try {
            await api.concretingDocumentationClient
                .getConcretedStructuralElementById(companyId, projectId, structuralElementId)
                .then((response) => {
                    if (response.isSuccess()) {
                        setStructuralElementData(response.getEntity());
                        onSuccess();
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    const onDisplayCoseModal = async (structuralElementId: string): Promise<void> => {
        getStructuralElement(structuralElementId, () => setCoseModalVisible(true));
    };

    const onDisplayPostTreatmentModal = (record: ConcretedStructuralElementForConcretingDocumentationBP): void => {
        setCoseForPostTreatmentModal(record);
        setPostTreatmentModalVisible(true);
    };

    function onEditPostTreatment(record: ConcretedStructuralElementForConcretingDocumentationBP) {
        navigate(`/projects/${projectId}/concreting-documentation/${record.structuralElementId}`, {
            state: {
                name: record.structuralElementName
            }
        });
    }

    const onDisplayConcreteTypeModal = (supplierProducedConcrete: SupplierProducedConcrete): void => {
        setConcreteTypeData(supplierProducedConcrete);
        setConcreteTypeModalVisible(true);
    };

    const onEditStructuralElement = (record: ConcretedStructuralElementForConcretingDocumentationBP): void => {
        getStructuralElement(record.structuralElementId, () => setCoseEditModalVisible(true));
    };

    useEffect(() => {
        refreshTable({ skip: 0, limit: 10 }, filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        triggerReload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, pagination]);

    useEffect(() => {
        setPagination((prev) => {
            const maxPage = totalData / prev.pageSize;
            let page = prev.current === 0 ? 1 : prev.current / prev.pageSize + 1;
            page = Math.floor(page > maxPage ? maxPage : page);
            return {
                position: ['bottomRight'],
                pageSize,
                current: page || 1,
                total: totalData,
                showSizeChanger: true
            } as TablePaginationConfig;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalData, companyId, projectId]);

    useEffect(() => {
        const interval = setInterval(() => {
            triggerReload();
        }, 20000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId, companyId, filter]);

    return (
        <>
            <Space size="large" direction="vertical" style={{ width: '100%' }}>
                {marketId === MARKETS.AUS && <ConcretingDocumentationFilters />}
                <ConcretingDocumentationTable
                    onDisplayCoseModal={onDisplayCoseModal}
                    onDisplayConcreteTypeModal={onDisplayConcreteTypeModal}
                    onDisplayPostTreatmentModal={onDisplayPostTreatmentModal}
                    onEditPostTreatment={onEditPostTreatment}
                    onEditStructuralElement={onEditStructuralElement}
                    tableData={tableData}
                    pagination={pagination}
                    onChange={onChange}
                    coseFocused={coseFocused}
                    setGlobalState={setGlobalState}
                    loading={loading}
                />
            </Space>

            {isCoseModalVisible && (
                <StructuralElementModal
                    structuralElementDetails={structuralElementDetailsData}
                    open={isCoseModalVisible}
                    setOpen={setCoseModalVisible}
                    isDocumentation
                />
            )}

            {isPostTreatmentModalVisible && (
                <PostTreatmentDetailsModal
                    concretedStructuralElement={coseForPostTreatmentModal}
                    open={isPostTreatmentModalVisible}
                    setOpen={setPostTreatmentModalVisible}
                />
            )}

            {isConcreteTypenModalVisible && (
                <ConcreteTypeModal
                    concreteDetails={concreteTypenDetailsData}
                    open={isConcreteTypenModalVisible}
                    setOpen={setConcreteTypeModalVisible}
                />
            )}

            {isCoseEditModalVisible && (
                <ConcretedStruturalElementModalEdit
                    open={isCoseEditModalVisible}
                    setOpen={setCoseEditModalVisible}
                    cose={structuralElementDetailsData}
                    triggerReload={triggerReload}
                />
            )}
        </>
    );
}

export default DataTableConcreteDocumentation;
