import { Rule } from 'antd/lib/form';
import { t } from '@lingui/macro';

interface NumberRangeParams {
    max?: number;
    min?: number;
    minOrEqual?: number;
    maxOrEqual?: number;
    required?: boolean;
    decimal?: boolean;
    message?: string;
}

function numberRule({ max, min, minOrEqual, maxOrEqual, required, message, decimal }: NumberRangeParams): Rule {
    return () => ({
        validator(_, value) {
            if (value == null || value === '') {
                if (required === true) {
                    return Promise.reject(new Error(t`Required`));
                }

                return Promise.resolve();
            }

            if (decimal === false && value % 1 > 0) {
                return Promise.reject(new Error(t`Number should be integer`));
            }

            if (min != null && value < min) {
                return Promise.reject(new Error(message || `>= ${min}`));
            }

            if (minOrEqual != null && value <= minOrEqual) {
                return Promise.reject(new Error(message || `> ${minOrEqual}`));
            }

            if (max != null && value > max) {
                return Promise.reject(new Error(message || `<= ${max}`));
            }

            if (maxOrEqual != null && value >= maxOrEqual) {
                return Promise.reject(new Error(message || `< ${maxOrEqual}`));
            }

            return Promise.resolve();
        }
    });
}

export default numberRule;
