import { Trans, t } from '@lingui/macro';
import {
    DeliveryNoteDetailsBP,
    InputUpdateStatusConcreteLoad
} from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Col, Form, Modal, Row } from 'antd';
import { RuleObject } from 'antd/lib/form';
import moment from 'moment';
import { useContext } from 'react';
import { DeliveryNoteManagementContext } from 'src/app-react/business-page/delivery-note-details/providers/delivery.note.provider';
import DatePicker from 'src/app-react/components/Form/DatePicker';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { mergeDateTime } from 'src/app-react/utils/lib';

interface TimestampModalsProps {
    delivery: DeliveryNoteDetailsBP;
    open: boolean;
    close: () => void;
    onComplete?: () => void;
}

function TimestampsModal(props: TimestampModalsProps) {
    const { delivery, open, close, onComplete } = props;
    const [modalForm] = Form.useForm();
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const { companyId, projectId, marketId, supplierConfig } = useProjectState();
    const api = useApi();

    function onCancel() {
        modalForm.resetFields();
        close();
    }

    const startUnloadingDateValidator = (rule: RuleObject, date: moment.Moment, callback: (error?: string) => void) => {
        const { arrivalDate, arrivalTime, startTime } = modalForm.getFieldsValue([
            'arrivalDate',
            'arrivalTime',
            'startTime'
        ]);
        const arrivalMoment = mergeDateTime(arrivalDate, arrivalTime);
        const dateTimeAction: moment.Moment = mergeDateTime(date, startTime);
        if (dateTimeAction.isSameOrAfter(arrivalMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const startUnloadingTimeValidator = (rule: RuleObject, time: moment.Moment, callback: (error?: string) => void) => {
        const { arrivalDate, arrivalTime, startDate } = modalForm.getFieldsValue([
            'arrivalDate',
            'arrivalTime',
            'startDate'
        ]);
        const arrivalMoment = mergeDateTime(arrivalDate, arrivalTime);
        const dateTimeAction: moment.Moment = mergeDateTime(startDate, time);
        if (dateTimeAction.isSameOrAfter(arrivalMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const endUnloadingDateValidator = (rule: RuleObject, date: moment.Moment, callback: (error?: string) => void) => {
        const { startDate, startTime, endTime } = modalForm.getFieldsValue(['startDate', 'startTime', 'endTime']);
        const startUnloadingMoment = mergeDateTime(startDate, startTime);
        const dateTimeAction: moment.Moment = mergeDateTime(date, endTime);
        if (dateTimeAction.isSameOrAfter(startUnloadingMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const endUnloadingTimeValidator = (rule: RuleObject, time: moment.Moment, callback: (error?: string) => void) => {
        const { startDate, startTime } = modalForm.getFieldsValue(['startDate', 'startTime']);
        const startUnloadingMoment = mergeDateTime(startDate, startTime);
        const date = modalForm.getFieldValue('endDate');
        const dateTimeAction: moment.Moment = mergeDateTime(date, time);
        if (dateTimeAction.isSameOrAfter(startUnloadingMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const signedOnDateValidator = (rule: RuleObject, date: moment.Moment, callback: (error?: string) => void) => {
        const { endDate, endTime } = modalForm.getFieldsValue(['endDate', 'endTime']);
        const endUnloadingMoment = mergeDateTime(endDate, endTime);
        const time = modalForm.getFieldValue('signedOnTime');
        const dateTimeAction: moment.Moment = mergeDateTime(date, time);
        if (dateTimeAction.isSameOrAfter(endUnloadingMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    const signedOnTimeValidator = (rule: RuleObject, time: moment.Moment, callback: (error?: string) => void) => {
        const { endDate, endTime, signedOnDate } = modalForm.getFieldsValue(['endDate', 'endTime', 'signedOnDate']);
        const endUnloadingMoment = mergeDateTime(endDate, endTime);
        const dateTimeAction: moment.Moment = mergeDateTime(signedOnDate, time);
        if (dateTimeAction.isSameOrAfter(endUnloadingMoment) && dateTimeAction.isSameOrBefore(moment())) {
            return callback();
        }
        return callback(rule.message as string);
    };

    function getLoadingStartInputDate(): moment.Moment | undefined {
        const loadingStartDate = modalForm.getFieldValue('loadingStartDate');
        const loadingStartTime = modalForm.getFieldValue('loadingStartTime');
        if (loadingStartDate && loadingStartTime) {
            return mergeDateTime(loadingStartDate, loadingStartTime);
        }
        return undefined;
    }

    function getArrivalInputDate(): moment.Moment | undefined {
        const arrivalDate = modalForm.getFieldValue('arrivalDate');
        const arrivalTime = modalForm.getFieldValue('arrivalTime');
        if (arrivalDate && arrivalTime) {
            return mergeDateTime(arrivalDate, arrivalTime);
        }
        return undefined;
    }

    function getStartUnloadingInputDate(): moment.Moment | undefined {
        const startUnloadingDate = modalForm.getFieldValue('startDate');
        const startUloadingTime = modalForm.getFieldValue('startTime');
        if (startUnloadingDate && startUloadingTime) {
            return mergeDateTime(startUnloadingDate, startUloadingTime);
        }
        return undefined;
    }

    function getEndUnloadingInputDate(): moment.Moment | undefined {
        const endUnloadingDate: moment.Moment = modalForm.getFieldValue('endDate');
        const endUloadingTime: moment.Moment = modalForm.getFieldValue('endTime');
        if (endUnloadingDate && endUloadingTime) {
            return mergeDateTime(endUnloadingDate, endUloadingTime);
        }
        return undefined;
    }

    const disabledArrivalDateHandler = (current: moment.Moment | null): boolean => {
        if (!current) {
            return false;
        }
        const loadingStartMoment = getLoadingStartInputDate();
        return current.isAfter(moment(), 'day') || (loadingStartMoment && current.isBefore(loadingStartMoment, 'day'));
    };

    const disabledStartUnloadingDateHandler = (current: moment.Moment | null): boolean => {
        if (!current) {
            return false;
        }
        return current.isAfter(moment(), 'day') || current.isBefore(getArrivalInputDate(), 'day');
    };

    const disabledEndUnloadingDateHandler = (current: moment.Moment | null): boolean => {
        if (!current) {
            return false;
        }
        return current.isAfter(moment(), 'day') || current.isBefore(getStartUnloadingInputDate(), 'day');
    };

    const disabledSignedOnDateHandler = (current: moment.Moment | null): boolean => {
        if (!current) {
            return false;
        }
        return current.isAfter(moment(), 'day') || current.isBefore(getEndUnloadingInputDate(), 'day');
    };

    async function onSubmit() {
        try {
            const {
                loadingStartDate,
                loadingStartTime,
                arrivalDate,
                arrivalTime,
                startDate,
                startTime,
                endDate,
                endTime,
                signedOnDate,
                signedOnTime
            } = await modalForm.validateFields();

            const loadingStartInput: InputUpdateStatusConcreteLoad | null =
                loadingStartDate && loadingStartTime
                    ? { status: 'Loading', dateTimeAction: mergeDateTime(loadingStartDate, loadingStartTime).toDate() }
                    : null;

            const arrivalInput: InputUpdateStatusConcreteLoad | null =
                arrivalDate && arrivalTime
                    ? { status: 'Arrived', dateTimeAction: mergeDateTime(arrivalDate, arrivalTime).toDate() }
                    : null;
            const startInput: InputUpdateStatusConcreteLoad | null =
                startDate && startTime
                    ? { status: 'Unloading', dateTimeAction: mergeDateTime(startDate, startTime).toDate() }
                    : null;
            const endInput: InputUpdateStatusConcreteLoad | null =
                endDate && endTime
                    ? { status: 'Unloaded', dateTimeAction: mergeDateTime(endDate, endTime).toDate() }
                    : null;

            const signedOnInput: InputUpdateStatusConcreteLoad | null =
                signedOnDate && signedOnTime
                    ? { status: 'Signed', dateTimeAction: mergeDateTime(signedOnDate, signedOnTime).toDate() }
                    : null;

            const updateStatusTimestamp: InputUpdateStatusConcreteLoad[] = [
                loadingStartInput,
                arrivalInput,
                startInput,
                endInput,
                signedOnInput
            ].filter(Boolean);

            api.deliveryNoteClient
                .updateConcreteLoadStateTimestamp(
                    companyId,
                    projectId,
                    orderId,
                    delivery.deliveryNoteData.dnId,
                    updateStatusTimestamp
                )
                .then((response) => {
                    if (response.isSuccess()) {
                        SuccessNotification({
                            message: t`Timestamps have been updated succesfully.`,
                            description: ''
                        });

                        onComplete?.();
                        onCancel();
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info: any) => {
                    ErrorNotification({
                        message: info,
                        description: ''
                    });
                });
        } catch (e: any) {
            ErrorNotification({
                message: t`Form not filled correctly`,
                description: t`Please, make sure that all the fields are filled correctly`
            });
        }
    }

    return (
        <Modal
            maskClosable={false}
            data-testid="edit-timestamps-modal"
            title={t`Edit Timestamps`}
            open={open}
            onCancel={onCancel}
            width={700}
            footer={[
                <Button key="cancel-button" data-testid="cancel-button" name="cancelButton" onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </Button>,
                <Button key="save-button" onClick={onSubmit} data-testid="submit-button" type="primary">
                    <Trans>Save</Trans>
                </Button>
            ]}>
            <Form form={modalForm} layout="vertical" data-testid="cancel-order-form">
                {marketId === 'AUS' && (supplierConfig.isQrType || supplierConfig.isManualType) && (
                    <Row gutter={[48, 16]}>
                        <Col span={11}>
                            <Form.Item
                                initialValue={
                                    delivery.deliveryNoteData.loadingStart &&
                                    moment(delivery.deliveryNoteData.loadingStart)
                                }
                                label={<Trans>Loading start date</Trans>}
                                name="loadingStartDate">
                                <DatePicker
                                    style={{ width: '100%' }}
                                    data-testid="loadingStart-date-input"
                                    placeholder={t`Enter Date`}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                initialValue={
                                    delivery.deliveryNoteData.loadingStart &&
                                    moment(delivery.deliveryNoteData.loadingStart)
                                }
                                label={<Trans>Loading start time</Trans>}
                                name="loadingStartTime">
                                <TimePicker data-testid="loadingStart-time-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Row gutter={[48, 16]}>
                    <Col span={11}>
                        <Form.Item
                            initialValue={moment(delivery.deliveryNoteData.arrivalAtConstructionSite) || moment()}
                            label={<Trans>Arrival at site date</Trans>}
                            name="arrivalDate"
                            rules={[{ required: true, message: t`This field is required` }]}>
                            <DatePicker
                                style={{ width: '100%' }}
                                data-testid="arrival-date-input"
                                placeholder={t`Enter Date`}
                                disabledDate={delivery.deliveryNoteData.loadingStart && disabledArrivalDateHandler}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            initialValue={moment(delivery.deliveryNoteData.arrivalAtConstructionSite) || moment()}
                            label={<Trans>Arrival at site time</Trans>}
                            name="arrivalTime"
                            rules={[{ required: true, message: t`This field is required` }]}>
                            <TimePicker data-testid="arrival-time-input" />
                        </Form.Item>
                    </Col>
                </Row>

                {delivery.deliveryNoteData.unloadingStart && (
                    <Row gutter={[48, 16]}>
                        <Col span={11}>
                            <Form.Item
                                initialValue={moment(delivery.deliveryNoteData.unloadingStart) || moment()}
                                label={<Trans>Unloading start date</Trans>}
                                name="startDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t`This field is required`
                                    },
                                    {
                                        message: t`Unloading start date must be between arrival at site date and now`,
                                        validator: startUnloadingDateValidator
                                    }
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    data-testid="start-date-input"
                                    placeholder={t`Enter Date`}
                                    disabledDate={disabledStartUnloadingDateHandler}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                initialValue={moment(delivery.deliveryNoteData.unloadingStart) || moment()}
                                label={<Trans>Unloading start time</Trans>}
                                name="startTime"
                                rules={[
                                    {
                                        required: true,
                                        message: t`This field is required`
                                    },
                                    {
                                        message: t`Unloading start time must be between arrival at site date and now`,
                                        validator: startUnloadingTimeValidator
                                    }
                                ]}>
                                <TimePicker data-testid="start-time-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                )}

                {delivery.deliveryNoteData.unloadingEnd && (
                    <Row gutter={[48, 16]}>
                        <Col span={11}>
                            <Form.Item
                                initialValue={moment(delivery.deliveryNoteData.unloadingEnd) || moment()}
                                label={<Trans>Unloading end date</Trans>}
                                name="endDate"
                                rules={[
                                    {
                                        required: true,
                                        message: t`This field is required`
                                    },
                                    {
                                        message: t`Unloading end date must be between start unloading date and now`,
                                        validator: endUnloadingDateValidator
                                    }
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    data-testid="end-date-input"
                                    placeholder={t`Enter Date`}
                                    disabledDate={disabledEndUnloadingDateHandler}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                initialValue={moment(delivery.deliveryNoteData.unloadingEnd) || moment()}
                                label={<Trans>Unloading end time</Trans>}
                                name="endTime"
                                rules={[
                                    {
                                        required: true,
                                        message: t`This field is required`
                                    },
                                    {
                                        message: t`Unloading end time must be between start unloading date and now`,
                                        validator: endUnloadingTimeValidator
                                    }
                                ]}>
                                <TimePicker data-testid="end-time-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                {marketId === 'AUS' && delivery.deliveryNoteData.signedOn && (
                    <Row gutter={[48, 16]}>
                        <Col span={11}>
                            <Form.Item
                                initialValue={moment(delivery.deliveryNoteData.signedOn) || moment()}
                                label={<Trans>Signed on date</Trans>}
                                name="signedOnDate"
                                rules={[
                                    { required: true, message: t`This field is required` },
                                    {
                                        message: t`Signed On date must be between unloading end and now`,
                                        validator: signedOnDateValidator
                                    }
                                ]}>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    data-testid="signedOn-date-input"
                                    placeholder={t`Enter Date`}
                                    disabledDate={disabledSignedOnDateHandler}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                initialValue={moment(delivery.deliveryNoteData.signedOn) || moment()}
                                label={<Trans>Signed on time</Trans>}
                                name="signedOnTime"
                                rules={[
                                    { required: true, message: t`This field is required` },
                                    {
                                        message: t`Signed On time must be between unloading end and now`,
                                        validator: signedOnTimeValidator
                                    }
                                ]}>
                                <TimePicker data-testid="signedOn-time-input" />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
}

export default TimestampsModal;
