import { ConcretingDeliveryNoteDocumentation } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Table, TableProps } from 'antd';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConcretingDocumentationContext } from 'src/app-react/business-page/concreting-documentation/providers/concreting-documentation.provider';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import { ConcretingDocumentationTableProps } from '../concretingDocumentationTable';
import { concretingDocumentationTableColumnsAUS } from './columns';
import { ConcretingDocumentationParsedAUSData, parseConcretingDocumentationTable } from './util';

interface ConcretingDocumentationTablePropsDE extends Omit<ConcretingDocumentationTableProps, 'commonProps'> {
    commonProps: TableProps<ConcretingDocumentationParsedAUSData> | undefined;
}

export function ConcretingDocumentationTableAUS(props: ConcretingDocumentationTablePropsDE) {
    const { units } = useProjectConstantsState();
    const navigate = useNavigate();
    const { filter, setFilter } = useContext(ConcretingDocumentationContext);

    const {
        onDisplayCoseModal,
        onDisplayConcreteTypeModal,
        onDisplayPostTreatmentModal,
        onEditPostTreatment,
        onEditStructuralElement,
        commonProps,
        tableData
    } = props;

    return (
        <Table
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...commonProps}
            dataSource={parseConcretingDocumentationTable(tableData as ConcretingDeliveryNoteDocumentation['data'])}
            columns={concretingDocumentationTableColumnsAUS({
                onDisplayCoseModal,
                onDisplayConcreteTypeModal,
                onDisplayPostTreatmentModal,
                onEditPostTreatment,
                onEditStructuralElement,
                units,
                navigate,
                filter,
                setFilter
            })}
            onRow={() => ({})}
        />
    );
}
