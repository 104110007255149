/* eslint-disable prettier/prettier */
import { RelativeHumidityTest } from '@nexploretechnology/concreting-core-client';
import { useCallback } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useRelativeHumidityTest() {
  const api = useApi();
  const { projectId, companyId } = useProjectState();

  return useCallback(
    async (relativeHumidityTest: RelativeHumidityTest, id?: string) => {
      return (
        id
          ? api.freshConcreteClientConcrete.updateRelativeHumidityTest(
            companyId,
            projectId,
            relativeHumidityTest.orderId,
            relativeHumidityTest.concreteLoadId,
            id,
            relativeHumidityTest
          )
          : api.freshConcreteClientConcrete.createRelativeHumidityTest(
            companyId,
            projectId,
            relativeHumidityTest.orderId,
            relativeHumidityTest.concreteLoadId,
            relativeHumidityTest,
          )
      )
        .then((response) => ({ success: response.isSuccess(), error: undefined }))
        .catch((err) => ({
          success: false,
          error:
            err?.response?.data?.error?.errors?.[0]
              ?.replace('"testingTime"', 'Testing time')
              ?.replace('"ref:samplingTime"', 'sampling time') ?? err
        }));
    },
    [api, projectId, companyId]
  );
}

export default useRelativeHumidityTest;
