import { i18n } from '@lingui/core';
import moment from "moment";
import { AirContentTestFormValuesDE } from "./models";
import { formatDecimalByLocale } from "src/app-react/utils/formatters";
import { FormInstance } from 'antd';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { AirContentTest, FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValuesDE(values: any) {
    const initialFormValuesDE: AirContentTestFormValuesDE = values;

    return {
        testingDate:
            initialFormValuesDE?.testingDate !== undefined
                ? moment(initialFormValuesDE.testingDate)
                : undefined,
        testingTime:
            initialFormValuesDE?.testingTime !== undefined
                ? moment(initialFormValuesDE.testingTime)
                : undefined,
        airContent: formatDecimalByLocale(initialFormValuesDE?.airContent?.toString(), i18n.locale),
        externalInspection:
            initialFormValuesDE?.externalInspection !== undefined
                ? initialFormValuesDE?.externalInspection
                    ? 'true'
                    : 'false'
                : undefined
    };
}

export function getAirContentFormValuesDE(airContentTestForm: FormInstance<any>): AirContentTestFormValuesDE {
    return {
        testingDate: (airContentTestForm.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (airContentTestForm.getFieldValue('testingTime') as moment.Moment).toDate(),
        airContent: Number(airContentTestForm.getFieldValue('airContent') as string),
        externalInspection:
            (airContentTestForm.getFieldValue('externalInspection') as string) === 'true'
    };
}

export function createAirContentTestDE(
    freshConcreteTest: FreshConcreteTest,
    airContentTestFormValues: AirContentTestFormValuesDE
) {
    const testingTime: Date = mergeDateTime(
        moment(airContentTestFormValues.testingDate),
        moment(airContentTestFormValues.testingTime)
    ).toDate();

    const airContentTest: AirContentTest = {
        ...freshConcreteTest,
        airContent: airContentTestFormValues.airContent,
        externalInspection: airContentTestFormValues.externalInspection,
        testingTime
    };

    return airContentTest;
}