/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { t } from '@lingui/macro';
import { DatePickerProps, Form, FormItemProps } from 'antd';
import { cloneElement, isValidElement } from 'react';
import parseRules from '../utils/parseRules';

export interface DatePickerFormItemProps extends FormItemProps {
    datePickerProps?: DatePickerProps;
}

function DatePickerFormItem({ required, datePickerProps, ...props }: DatePickerFormItemProps) {
    return (
        <Form.Item {...props} rules={parseRules(required, props)}>
            {isValidElement(props.children) &&
                cloneElement(props.children, { placeholder: t`Enter Date`, ...datePickerProps })}
        </Form.Item>
    );
}

export default DatePickerFormItem;
