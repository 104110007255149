import { ProjectRoles, Role, RoleDefinition } from 'src/app-react/services/authService';

export function hasPermissionsToAccessCatalogueManagement(projectRoles: ProjectRoles): boolean {
    if (projectRoles) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key of Object.keys(projectRoles)) {
            const permissionsBykey: RoleDefinition | undefined =
                projectRoles[key as keyof Partial<Record<Role, RoleDefinition>>];
            if (
                permissionsBykey &&
                permissionsBykey.COMPONENT_CATALOGUE_STRUCTURAL_ELEMENTS &&
                permissionsBykey.COMPONENT_CATALOGUE_CONCRETE_TYPES
            ) {
                const CaSE: Array<string> = permissionsBykey.COMPONENT_CATALOGUE_STRUCTURAL_ELEMENTS;
                const CaCT: Array<string> = permissionsBykey.COMPONENT_CATALOGUE_CONCRETE_TYPES;

                const hasPermisionToStructuralElement: boolean =
                    CaSE.includes('READ') || (CaSE.includes('CREATE') && CaSE.includes('EDIT'));
                const hasPermisionToConcreteType: boolean =
                    CaCT.includes('READ') || (CaCT.includes('CREATE') && CaCT.includes('EDIT'));

                if (hasPermisionToStructuralElement && hasPermisionToConcreteType) {
                    return true;
                }
            }
        }
    }

    return false;
}
