import { DeliveryStatusFilter } from '@nexploretechnology/concreting-core-client';
import { useCallback, useState } from 'react';
import { ValuesDropDown, mapStringToDropdown } from 'src/app-react/constants/marketsData';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';

function useGetSupplierProducedConcreteNumbersByProject() {
    const { projectId, companyId } = useProjectState();
    const api = useApi();

    const [error, setError] = useState<{ message: string; description?: string }>();
    const [typeNumbers, setTypeNumbers] = useState<ValuesDropDown[]>(undefined);

    const loadData = useCallback(
        async (filter: DeliveryStatusFilter | undefined) => {
            return (
                api.deliveryNoteLiteClient
                    .getSupplierProducedConcreteNumbersByProject(companyId, projectId, filter)
                    .then((response) => {
                        setTypeNumbers(mapStringToDropdown(response.getEntity()));
                    })
                    // eslint-disable-next-line no-shadow
                    .catch((error: any) => {
                        setError({ message: error.code, description: error.message });
                    })
            );
        },
        [api, companyId, projectId]
    );

    return {
        loadData,
        typeNumbers,
        error
    };
}

export default useGetSupplierProducedConcreteNumbersByProject;
