import { t, Trans } from '@lingui/macro';
import { OrderOverviewBP } from '@nexploretechnology/concreting-core-client/concrete/order.monitoring-client/order.monitoring.dto';
import { UnloadingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/unloading.location-client/unloading.location.dto';
import { Button, Col, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DateTimeFormatter from 'src/app-react/components/Formatters/DateTimeFormatter';
import useProjectState from 'src/app-react/hooks/useProjectState';
import styles from './MonitoringOrderDetailModal.module.css';

function MonitoringOrderDetailModal(props: {
    projectId: string;
    order: OrderOverviewBP;
    hideModal: () => void;
    showModal: boolean;
}) {
    const { order, hideModal, showModal } = props;
    const { marketId } = useProjectState();
    const [orderOverview, setOrderOverview] = useState<OrderOverviewBP>({} as OrderOverviewBP);
    const marketDE = 'DE';

    useEffect(() => {
        if (showModal) {
            setOrderOverview(order);
        }
    }, [showModal]);

    function getUnloadingLocation(unloadingLocation: UnloadingLocationBP): string {
        let unloadingLocationString = '';

        if (unloadingLocation) {
            if (unloadingLocation.locationName) {
                unloadingLocationString += `${unloadingLocation.locationName}`;
            }
            if (unloadingLocation.locationType) {
                unloadingLocationString += ` ${unloadingLocation.locationType}`;
            }
            if (unloadingLocation.locationDescription) {
                unloadingLocationString += ` ${unloadingLocation.locationDescription}`;
            }
        }

        return unloadingLocationString;
    }

    const firstBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Concrete Type No.</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-concreteTypeNumber" span={12}>
                    {orderOverview.orderedConcreteTypeNumber || ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Concrete type description</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-description" span={12}>
                    {orderOverview.orderedConcreteTypeDescription}
                </Col>
            </>
        );
    };

    const firstBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Mix design number</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModalUS-concreteTypeNumber" span={12}>
                    {orderOverview.orderedConcreteTypeNumber || ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Slump</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModalUS-slump" span={12}>
                    <NumberFormatter value={orderOverview.slump} unit={orderOverview.slumpUnit} />
                </Col>
            </>
        );
    };

    const secondBlockRenderMarketIdDE = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Output per hour</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-performance" span={12}>
                    <NumberFormatter value={orderOverview.performance} unit={orderOverview.performanceUnit} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>With additional quantity</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-additionalQuantityRequired" span={12}>
                    {orderOverview.additionalQuantityRequired ? <Trans>Yes</Trans> : <Trans>No</Trans>}
                </Col>
            </>
        );
    };

    const secondBlockRenderMarketIdUS = (): JSX.Element => {
        return (
            <>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Spacing between trucks</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModalUS-spacingInMinutes" span={12}>
                    {orderOverview.spacingInMinutes ? `${orderOverview.spacingInMinutes} min` : ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Load size per truck</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModalUS-loadSize" span={12}>
                    <NumberFormatter value={orderOverview.truckLoadSize} unit={orderOverview.concreteVolumeUnit} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>With clean up load</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModalUS-additionalQuantityRequired" span={12}>
                    {orderOverview.additionalQuantityRequired ? <Trans>Yes</Trans> : <Trans>No</Trans>}
                </Col>
            </>
        );
    };

    return (
        <Modal
            maskClosable={false}
            open={showModal}
            title={t`Order Details`}
            width={750}
            onCancel={hideModal}
            data-testid="orderMonitoringModal"
            footer={[
                <Button data-testid="closeButton" key="close" type="primary" onClick={hideModal}>
                    {t`Close`}
                </Button>
            ]}>
            <Row gutter={[48, 16]}>
                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Order No.</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-orderId" span={12}>
                    {orderOverview.id}
                </Col>

                {marketId === marketDE ? firstBlockRenderMarketIdDE() : firstBlockRenderMarketIdUS()}

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Structural Element</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-allocatedStructuralComponentName" span={12}>
                    {orderOverview.structuralElementNames || ''}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Delivery Time</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-deliveryTime" span={12}>
                    <DateTimeFormatter date={orderOverview.deliveryTime} />
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Concrete Quantity</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-quantity" span={12}>
                    <NumberFormatter value={orderOverview.concreteVolume} unit={orderOverview.concreteVolumeUnit} />
                </Col>

                {marketId === marketDE ? secondBlockRenderMarketIdDE() : secondBlockRenderMarketIdUS()}

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Unloading Location</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-unloadingLocation" span={12}>
                    {getUnloadingLocation(orderOverview.unloadingLocation)}
                </Col>

                <Col span={12}>
                    <label className={styles.modalLabel}>
                        <Trans>Additional requirements</Trans>
                    </label>
                </Col>
                <Col data-testid="MonitoringOrderDetailModal-additionalRequirements" span={12}>
                    {orderOverview.additionalRequirements || ''}
                </Col>
            </Row>
        </Modal>
    );
}

export default MonitoringOrderDetailModal;
