import { t } from '@lingui/macro';

export function mapStrengthDevelopmentClass(storedValue: string): string {
    switch (storedValue) {
        case 'fast':
            return t`Fast`;
        case 'intermediate':
            return t`Intermediate`;
        case 'slow':
            return t`Slow`;
        case 'very slow':
            return t`Very slow`;
        default:
            return '';
    }
}
