import { FormInstance } from 'antd';
import { TestSamplingWithType } from '../CommonTestForm/models';
import { WindSpeedTestFormValues } from './models';
import { newFreshConcreteTest } from 'src/app-react/business-page/delivery-note-details/utils/concrete-tests-service';
import moment from 'moment';
import { WindSpeedTest } from '@nexploretechnology/concreting-core-client';
import { mergeDateTime } from 'src/app-react/utils/lib';
import { FreshConcreteTest } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export function createInitialValues(values: any) {
    return {
        testingDate: values?.testingDate !== undefined ? moment(values.testingDate) : undefined,
        testingTime: values?.testingTime !== undefined ? moment(values.testingTime) : undefined,
        windSpeed: values?.windSpeed
    };
}

export function getWindSpeedFormValues(values: FormInstance) {
    return {
        testingDate: (values.getFieldValue('testingDate') as moment.Moment).toDate(),
        testingTime: (values.getFieldValue('testingTime') as moment.Moment).toDate(),
        windSpeed: Number(values.getFieldValue('windSpeed') as string)
    };
}

export function createWindSpeedTest(
    companyId: string,
    projectId: string,
    orderId: string,
    concreteLoadId: string,
    commonTest: TestSamplingWithType,
    windSpeedTestFormValues: WindSpeedTestFormValues
): WindSpeedTest {
    const freshConcreteTest: FreshConcreteTest = newFreshConcreteTest(
        companyId,
        projectId,
        orderId,
        concreteLoadId,
        commonTest
    );

    const testingTime: Date = mergeDateTime(
        moment(windSpeedTestFormValues.testingDate),
        moment(windSpeedTestFormValues.testingTime)
    ).toDate();

    const windSpeedTest: WindSpeedTest = {
        ...freshConcreteTest,
        windSpeed: windSpeedTestFormValues.windSpeed,
        testingTime
    };

    return windSpeedTest;
}
