import { t, Trans } from '@lingui/macro';
import { Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function CustomerIdFormItem() {
    return (
        <FormItem
            name="customerId"
            label={<Trans>Customer ID</Trans>}
            rules={[{
                required: true,
                message: <Trans>Please input customer ID</Trans>
            }]}>
            <Input
                type="text"
                data-testid="customerId"
                placeholder={t`Enter customer id`}
            />
        </FormItem>
    );
}
