import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Select } from 'antd';
import SelectFormItem from 'src/app-react/components/Form/FormItem/SelectFormItem';

const { Option } = Select;

export function ConstructionCountryFormItem() {
    return (
        <SelectFormItem required name="constructionCountry" label={t`Country`}>
            <Select placeholder={t`Select Country`} defaultActiveFirstOption data-testid="projectCountry">
                <Option value="GE" data-testid="GE-option" key="GE">
                    <Trans id="GERMANY - DE" />
                </Option>
                <Option value="US" data-testid="US-option" key="US">
                    <Trans id="USA - US" />
                </Option>
                <Option value="AUS" data-testid="US-option" key="AUS">
                    <Trans id="AUSTRALIA - AUS" />
                </Option>
                <Option value="HK" data-testid="US-option" key="HK">
                    <Trans id="HONG KONG - HK" />
                </Option>
            </Select>
        </SelectFormItem>
    );
}
