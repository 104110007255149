/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */

import { Col, Row } from 'antd';
import styles from 'src/app-react/components/Form/styles.module.css';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';
import DatePickerFormItem, { DatePickerFormItemProps } from './DatePickerFormItem';
import TimePickerFormItem, { TimePickerFormItemProps } from './TimePickerFormItem';

interface DateTimePickerFormItemProps {
    required?: boolean;
    datePickerFormItemProps?: DatePickerFormItemProps;
    timePickerFormItemProps?: TimePickerFormItemProps;
}

function DateTimePickerFormItem({
    required,
    datePickerFormItemProps,
    timePickerFormItemProps
}: DateTimePickerFormItemProps) {
    return (
        <Row gutter={8}>
            <Col span="14">
                <DatePickerFormItem required={required} {...datePickerFormItemProps}>
                    <DatePicker allowClear={false} />
                </DatePickerFormItem>
            </Col>
            <Col span="10">
                <TimePickerFormItem
                    label={' '}
                    required={required}
                    className={styles.hideRequiredMark}
                    {...timePickerFormItemProps}>
                    <TimePicker allowClear={false} />
                </TimePickerFormItem>
            </Col>
        </Row>
    );
}

export default DateTimePickerFormItem;
