import { UserProfile } from "@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto";
import { SimpleClientResponse } from "@nexploretechnology/concreting-core-client/simple.client.response";
import axios, { AxiosInstance } from "axios";
import Keycloak, { KeycloakProfile } from "keycloak-js";
import Api from "./api";
import { IAuthService, setAuthService } from "./app-react/services/authService";
import {
  IConfigService,
  setConfigService,
} from "./app-react/services/configService";
import { loadExperimentalFeaturesConfig } from "./app-react/services/experimental.features";
import { identifyUser, initialize } from "./app-react/utils/userpilot";

let keycloak: Keycloak;
let authService: IAuthService | undefined;
const FAKE_TOKEN = "";

async function loadConfig(): Promise<IConfigService> {
    const r = await fetch("./config/config.json");
    const configService: IConfigService = await r.json();
    setConfigService(configService);
    return configService;
}

function getKeycloak(): Keycloak {
    if (!keycloak) {
        throw new Error("You must load the user information first");
    }
    return keycloak;
}

export function getToken(): string {
    return getKeycloak().token;
}

export async function updateToken(): Promise<void> {
    try {
        await getKeycloak().updateToken(60);
    } catch {
        getKeycloak().logout();
    }
}

async function loadUserProfile(api: Api): Promise<UserProfile> {
    try {
        const request: SimpleClientResponse<UserProfile> =
            await api.userManagementClient.getUserProfile(FAKE_TOKEN);

        return request.getEntity();
    } catch (err: any) {
        alert(err.message);
        throw err;
    }
}

function setupUserpilot(configData: IConfigService) {
    if (!configData.upilot) {
        return;
    }

    initialize(configData.upilot);
}

export async function loadAuth() {
    const config = await loadConfig();
    setupUserpilot(config);
    keycloak = new Keycloak({
        url: config.keycloakConfig.url,
        realm: config.keycloakConfig.realm,
        clientId: config.keycloakConfig.clientId,
    });

    const success = await keycloak.init({
        onLoad: "login-required",
        checkLoginIframe: false,
    });

    let keyCloakProfile: KeycloakProfile;
    if (success) {
        keyCloakProfile = await keycloak.loadUserProfile();
    }

    const axiosInstance: AxiosInstance = axios.create({
        baseURL: `${config.host}/api/v1`,
    });

    const axiosInstanceCorepi: AxiosInstance = axios.create({
        baseURL: `${config.coreHost}/api/v1`,
    });

    axiosInstance.interceptors.request.use(async (axiosInterceptorConfig) => {
        await updateToken();
        const token = getToken();
        axiosInterceptorConfig.headers.Authorization = `Bearer ${token}`;
        return axiosInterceptorConfig;
    });

    axiosInstanceCorepi.interceptors.request.use(
        async (axiosInterceptorConfig) => {
            await updateToken();
            const token = getToken();
            axiosInterceptorConfig.headers.Authorization = `Bearer ${token}`;
            return axiosInterceptorConfig;
        }
    );

    const api = new Api(axiosInstance, axiosInstanceCorepi);
    const userProfile: UserProfile = await loadUserProfile(api);

    userProfile.username = userProfile.username || keyCloakProfile?.username;
    userProfile.userEmail = userProfile.userEmail || keyCloakProfile?.email;
    userProfile.userId = userProfile.userId || keyCloakProfile?.id;

    identifyUser(userProfile.userId);
    authService = {
        user: {
            userName: userProfile.username,
            userEmail: userProfile.userEmail,
            userId: userProfile.userId,
            companies: userProfile.companies,
        },
        logout(): void {
            getKeycloak().logout();
        },

        getToken(): string {
            return FAKE_TOKEN;
        },

        getAxiosInstance(): AxiosInstance {
            return axiosInstance;
        },

        getAxiosCoreInstance(): AxiosInstance {
            return axiosInstanceCorepi;
        },

        getServerPath(): string {
            return `${config.host}/api/v1`;
        },
    };

    setAuthService(authService);

    loadExperimentalFeaturesConfig();

    return { api, userProfile };
}
