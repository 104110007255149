import { Trans, t } from '@lingui/macro';
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

export function SlumpItem() {
    const form = Form.useFormInstance();
    const slump = Form.useWatch('slump', form);
    const slumpVariance = Form.useWatch('slumpVariance', form);

    const { units } = useProjectConstantsState();

    return (
        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: '5px' }}>
            <FormItem
                style={{ width: '50%' }}
                colon={false}
                label={<Trans>Slump</Trans>}
                name="slump"
                rules={[
                    {
                        pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                        message: t`A positive number`
                    },
                    {
                        message: t`Required if Slump variance is filled`,
                        required: Boolean(slumpVariance)
                    }
                ]}>
                <Input placeholder={t`Enter slump`} data-testid="slump" addonAfter={units.slump} />
            </FormItem>
            <FormItem
                style={{ width: '50%' }}
                colon={false}
                label={<Trans>Slump variance</Trans>}
                name="slumpVariance"
                rules={[
                    {
                        pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                        message: t`A positive number`
                    },
                    {
                        message: t`Required if Slump is filled`,
                        required: Boolean(slump)
                    }
                ]}>
                <Input addonBefore="+-" addonAfter={units.slump} data-testid="slumpVariance" />
            </FormItem>
        </div>
    );
}
