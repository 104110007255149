import { t, Trans } from '@lingui/macro';
import { Col, Form, Row } from 'antd';
import styles from '../../MultipleConcreteTestModal.module.css';
import { MARKETS } from 'src/app-react/constants/markets';
import { ExternalInspectionFormItem } from '../../FormItems/DE/ExternalInspectionFormItem';
import { SlumpFormItem } from '../../FormItems/SlumpFormItem';
import useProjectState from 'src/app-react/hooks/useProjectState';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import DatePicker from 'src/app-react/components/Form/DatePicker';

export interface Props {
    showTitle?: boolean;
}

function ConsistencyTestForm({ showTitle = true }: Props) {
    const { marketId } = useProjectState();

    return (
        <>
            {showTitle && (
                <>
                    <Row>
                        <label className={styles.title}>
                            <Trans>Consistency Test</Trans>
                        </label>
                    </Row>
                    <br />
                </>
            )}

            <Row gutter={[16, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="testingDate"
                        label={<Trans>Testing Date</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <DatePicker style={{ width: '100%' }} data-testid="testingDate" placeholder={t`Enter Date`} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="testingTime"
                        label={<Trans>Testing Time</Trans>}
                        rules={[
                            {
                                required: true,
                                message: t`This field is required`
                            }
                        ]}>
                        <TimePicker data-testid="testingTime" />
                    </Form.Item>
                </Col>
            </Row>
            <SlumpFormItem />

            {marketId === MARKETS.DE && <ExternalInspectionFormItem />}
        </>
    );
}

export default ConsistencyTestForm;
