import { Trans, t } from "@lingui/macro";
import { Form, Input } from "antd";

export function CompactionTimeFormItem() {
    return <Form.Item
        name="compactionTime"
        label={<Trans>Compaction Time</Trans>}
        rules={[
            {
                required: true,
                pattern: new RegExp(/^[0-9]\d*$/),
                message: t`This field is required`
            }
        ]}>
        <Input type="number" data-testid="compactionTime" addonAfter={t`sec`} placeholder={t`30`} />
    </Form.Item>
}