import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Col, Row } from 'antd';
import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import AppHeader from 'src/app-react/components/AppHeader/AppHeader';
import ProjectBreadcrumb from 'src/app-react/components/Breadcrumb/ProjectBreadcrumb';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { storedOrderNumber } from 'src/app-react/utils/lib';
import DeliveryStatusTableGroup from '../components/delivery-status-table-group/delivery-status-table-group';
import DeliveryOverviewStatusCard from '../components/DeliveryOverviewStatusCard/DeliveryOverviewStatusCard';
import { DeliveryNoteManagementContext } from '../providers/delivery.note.provider';

export interface IDeliveryNoteDetailsProps {
    projectId: string;
    orderId: string;
}

function DeliveryNoteDetailsPage() {
    const { orderId } = useContext(DeliveryNoteManagementContext);
    const navigate: NavigateFunction = useNavigate();
    const { projectId } = useProjectState();
    const [refreshTable, setRefreshTable] = useState(false);

    const editOrder = (): void => {
        navigate(`/projects/${projectId}/orders/${orderId}/edit`);
    };

    return (
        <>
            <AppHeader
                title={`${t`Order`} ${storedOrderNumber()}`}
                onBack={() => navigate(-1)}
                breadcrumb={
                    <ProjectBreadcrumb>
                        <Breadcrumb.Item>
                            <Trans>Order monitoring</Trans>
                        </Breadcrumb.Item>
                    </ProjectBreadcrumb>
                }
            />

            <Row gutter={[0, 32]}>
                <Col span={24}>
                    <DeliveryOverviewStatusCard setRefreshTable={setRefreshTable} editOrder={editOrder} />
                </Col>
                <Col span={24}>
                    <DeliveryStatusTableGroup refreshTable={refreshTable} setRefreshTable={setRefreshTable} />
                </Col>
            </Row>
        </>
    );
}

export default DeliveryNoteDetailsPage;
