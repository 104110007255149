import { Col, Row } from 'antd';
import { SupplierUIFormItem } from '../../../../Form/components/FormItems/SupplierUIFormItem';
import { SupplierUrlFormItem } from '../../../FormItems/SupplierUrlFormItem';
import { TokenFormItem } from '../../../FormItems/TokenFormItem';

export function SupplierConfigFormLayoutGodelDE() {
    return (
        <>
            <Row gutter={8}>
                <Col span={11}>
                    <SupplierUrlFormItem />
                </Col>
                <Col span={13}>
                    <TokenFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <SupplierUIFormItem />
            </Row>
        </>
    );
}
