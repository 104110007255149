import { t } from '@lingui/macro';
import { Button, Modal, Row } from 'antd';
import { useEffect, useState } from 'react';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import ErrorNotification from '../../../../components/Notification/ErrorNotification';
import { dateTimeFormatter } from '../../../../utils/formatters';
import { isObjectEmpty } from '../../../../utils/lib';
import TestSpecimenModalRow from './TestSpecimenDetailModalRow';
import { 
    CompactorBP,
    TestPurposeBPDE,
    TestPurposeBPUS,
    TestSpecimenDetailsBP,
    TestSpecimenDetailsBPDE,
    TestSpecimenDetailsBPUS,
    TestTypeBPDE,
    TestTypeBPUS
 } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/specimen.test.dto';
import { SamplingLocationBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/fresh.concrete.test.dto';

export interface ITestSpecimenModalProps {
    specimenIdentifier: string;
    isModalVisible: boolean;
    setIsModalVisible: (isVisible: boolean) => void;
}

export interface ITestSpecimenModalRowProps {
    specimenLabel: string;
    specimenValue: string | Date | number | boolean | null;
    specimenTestId: string;
}

function TestSpecimenModal(props: ITestSpecimenModalProps) {
    const { specimenIdentifier, isModalVisible, setIsModalVisible } = props;

    const { projectId, companyId, marketId } = useProjectState();

    const [specimenDetailsData, setSpecimenData] = useState<TestSpecimenDetailsBP>({} as TestSpecimenDetailsBP);

    const api = useApi();

    const getSpecimenDetails = async () => {
        try {
            await api.testSpecimenClient
                .loadTestSpecimenDetailsByIdentifier(projectId, specimenIdentifier, companyId)
                .then((response) => {
                    if (response.isSuccess()) {
                        setSpecimenData(response.getEntity());
                    } else {
                        ErrorNotification({ message: response.getError(), description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        } catch (e: any) {
            ErrorNotification({ message: e.code, description: e.message });
        }
    };

    function testType(testType: TestTypeBPDE | TestTypeBPUS): string {
        switch (testType) {
            case 'compressiveStrength':
                return t`Compressive strength`;
            case 'tensibleSplittingStrength':
                return t`Tensible splitting strength`;
            case 'modulusOfElasticity':
                return t`Modulus of elasticity`;
            case 'waterImpermeability':
                return t`Water impermeability`;
            case 'CDF':
                return t`CDF`;
        }
        return '';
    }

    function testPurpose(testPurpose: TestPurposeBPDE | TestPurposeBPUS): string {
        switch (testPurpose) {
            case 'qualityTest':
                return t`Quality test`;
            case 'hardeningTest':
                return t`Hardening test`;
            case 'initialTest':
                return t`Initial test`;
            case 'fieldTest':
                return t`Field test`;
            case 'labTest':
                return t`Lab test`;
            case 'strippingTest':
                return t`Stripping test`;
            case 'other':
                return t`Other`;
            default:
                return '';
        }
    }

    function testSpecimenMould(mould: string): string {
        switch (mould) {
            case 'cube':
                return t`Cube`;
            case 'cylinder':
                return t`Cylinder`;
            case 'waterImpermeabilityPlate':
                return t`Water impermeability plate`;
            default:
                return '';
        }
    }

    function testSamplingLocation(testSamplingLocation: SamplingLocationBP): string {
        switch (testSamplingLocation) {
            case 'truckMixer':
                return t`Truck Mixer`;
            case 'concretingLocation':
                return t`Concreting Location`;
        }
    }

    function testCompactor(testCompactor: CompactorBP): string {
        switch (testCompactor) {
            case 'vibratingTable':
                return t`Vibrating table`;
            case 'internalVibrator':
                return t`Internal vibrator`;
            case 'tamper':
                return t`Tamper`;
            case 'tampingRod':
                return t`Tamping Rod`;
            default:
                return '';
        }
    }

    useEffect(() => {
        if (specimenIdentifier) {
            getSpecimenDetails();
        }
        return () => undefined;
    }, [specimenIdentifier, isModalVisible === true]);

    const closeModal = () => {
        setSpecimenData({} as TestSpecimenDetailsBP);
        setIsModalVisible(false);
    };

    function renderModalDE() {
        const specimenDetailsDataDE: TestSpecimenDetailsBPDE = specimenDetailsData as TestSpecimenDetailsBPDE;
        return (
            <Row gutter={[48, 4]}>
                <TestSpecimenModalRow
                    specimenLabel={t`Date/Time of Sampling`}
                    specimenValue={dateTimeFormatter(specimenDetailsData.samplingTime) || ''}
                    specimenTestId="samplingTime"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Sampling Location`}
                    specimenValue={testSamplingLocation(specimenDetailsData.samplingLocation)}
                    specimenTestId="samplingLocation"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Specimen Identifier`}
                    specimenValue={specimenDetailsData.testSpecimenIdentifier}
                    specimenTestId="testSpecimenIdentifier"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Test Type`}
                    specimenValue={testType(specimenDetailsDataDE.testType)}
                    specimenTestId="testType"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Purpose of Testing`}
                    specimenValue={testPurpose(specimenDetailsDataDE.testPurpose)}
                    specimenTestId="testPurpose"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Testing Age`}
                    specimenValue={specimenDetailsData.testingAge}
                    specimenTestId="testingAge"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Test Specimen Mould`}
                    specimenValue={testSpecimenMould(specimenDetailsDataDE.mould)}
                    specimenTestId="mould"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Compactor`}
                    specimenValue={testCompactor(specimenDetailsData.compactor)}
                    specimenTestId="compactor"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Compactor Time`}
                    specimenValue={
                        specimenDetailsDataDE.compactionTime.quantity && specimenDetailsDataDE.compactionTime.unit
                            ? `${specimenDetailsDataDE.compactionTime.quantity} ${specimenDetailsDataDE.compactionTime.unit}`
                            : ''
                    }
                    specimenTestId="compactionTime"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Use Result for External Inspection`}
                    specimenValue={specimenDetailsDataDE.externalInspection ? t`Yes` : t`No`}
                    specimenTestId="externalInspection"
                />
            </Row>
        );
    }

    function renderModalUS() {
        const specimenDetailsDataUS: TestSpecimenDetailsBPUS = specimenDetailsData as TestSpecimenDetailsBPUS;
        return (
            <Row gutter={[48, 4]}>
                <TestSpecimenModalRow
                    specimenLabel={t`Date/Time of Sampling`}
                    specimenValue={dateTimeFormatter(specimenDetailsData.samplingTime) || ''}
                    specimenTestId="samplingTime"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Sampling Location`}
                    specimenValue={testSamplingLocation(specimenDetailsData.samplingLocation)}
                    specimenTestId="samplingLocation"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Cylinder identification`}
                    specimenValue={specimenDetailsData.testSpecimenIdentifier}
                    specimenTestId="testSpecimenIdentifier"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Test Type`}
                    specimenValue={testType(specimenDetailsDataUS.testType)}
                    specimenTestId="testType"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Purpose of Testing`}
                    specimenValue={testPurpose(specimenDetailsDataUS.testPurpose)}
                    specimenTestId="testPurpose"
                />
                <TestSpecimenModalRow
                    specimenLabel={t`Testing Age`}
                    specimenValue={specimenDetailsData.testingAge}
                    specimenTestId="testingAge"
                />

                <TestSpecimenModalRow
                    specimenLabel={t`Compactor`}
                    specimenValue={testCompactor(specimenDetailsData.compactor)}
                    specimenTestId="compactor"
                />
            </Row>
        );
    }

    return isObjectEmpty(specimenDetailsData) ? null : (
        <Modal
            maskClosable={false}
            open={props.isModalVisible}
            title={t`Test specimen details`}
            width={600}
            onCancel={closeModal}
            footer={[
                <Button data-testid="closeButton" key="close" type="primary" onClick={closeModal}>
                    Close
                </Button>
            ]}>
            {marketId === 'DE' ? renderModalDE() : renderModalUS()}
        </Modal>
    );
}

export default TestSpecimenModal;
