import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import InputNumber from 'src/app-react/components/Form/InputNumber';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

export function ResultFormItem() {
    const form = Form.useFormInstance();
    const typeField = Form.useWatch('type', form);
    const measurementField = Form.useWatch('measurement', form);
    const { units } = useProjectConstantsState();

    function setErrorMessage() {
        if (typeField === 'other' || typeField === 'testSpecimen') {
            return <Trans>result is required</Trans>;
        }

        return <Trans>result is required if measurement value is not filled</Trans>;
    }

    return (
        <FormItem
            name="result"
            label={
                <b>
                    <Trans>Result</Trans>
                </b>
            }
            rules={[
                {
                    message: setErrorMessage(),
                    required: !measurementField
                },
                {
                    pattern: new RegExp(/^\d+(?:\.\d{1,2})?$/),
                    message: <Trans>result value is invalid</Trans>
                }
            ]}
            style={{ gap: '.2rem' }}>
            <InputNumber
                style={{ width: '100%' }}
                data-testid="result"
                placeholder={t`Enter result`}
                addonAfter={units.compressiveStrength}
                min={0}
            />
        </FormItem>
    );
}
