import { Trans } from '@lingui/macro';
import { ConcreteLoadIngredientOverview } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { ColumnsType } from 'antd/lib/table';
import Fraction from 'fraction.js';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import DeviationFormatter from './components/DeviationFormatter';

const MARKETS = {
    DE: 'DE',
    US: 'US'
};

const UNITS = {
    KG: 'kg'
};

const INGREDIENT_TYPES = {
    AGGREGATE: 'Aggregate'
};

function toFraction(decimal: number): string {
    const excludeWhole = true; // e.g. "1 1/5" will be visible instead of "6/5"
    return new Fraction(Number(decimal)).toFraction(excludeWhole);
}

function renderDeviation(value: number, format?: Intl.NumberFormatOptions) {
    return <DeviationFormatter value={value} format={format} />;
}

function renderPercentage(value: number, record: ConcreteLoadIngredientOverview): string | JSX.Element {
    if (!value) {
        return '-';
    }

    return (
        <NumberFormatter
            value={value}
            unit="%"
            format={{ minimumFractionDigits: record.ingredientType === 'Admixture' ? 2 : 1 }}
        />
    );
}

function renderUnit(value?: number, unit?: string, format?: Intl.NumberFormatOptions): string | JSX.Element {
    if (!value) {
        return '-';
    }

    return <NumberFormatter value={value} unit={unit} format={format} />;
}

function renderIngredientName(marketId: string) {
    return (ingredientName: string, record: ConcreteLoadIngredientOverview): JSX.Element | string => {
        const { ingredientType, minAggregateSize, maxAggregateSize } = record;

        if (INGREDIENT_TYPES.AGGREGATE === ingredientType) {
            if (marketId === MARKETS.DE && minAggregateSize >= 0 && maxAggregateSize) {
                return `${ingredientName} ${minAggregateSize}/${maxAggregateSize}`;
            }
            if (marketId === MARKETS.US && maxAggregateSize) {
                return `${ingredientName} ${toFraction(maxAggregateSize)} in`;
            }
        }

        return ingredientName;
    };
}

export const concreteLoadIngredientColumns: (marketId: string) => ColumnsType<ConcreteLoadIngredientOverview> = (
    marketId
) => [
    {
        title: <Trans>Attributes</Trans>,
        key: 'ingredientName',
        dataIndex: 'ingredientName',
        render: renderIngredientName(marketId)
    },
    {
        title: <Trans>Moisture (%)</Trans>,
        key: 'category',
        dataIndex: 'percentageOfMoistureAmount',
        render: renderPercentage
    },
    {
        title: <Trans>Moisture (kg)</Trans>,
        key: 'moistureAmountInKg',
        dataIndex: 'moistureAmountInKg',
        render: (moistureAmountInKg: number, record: ConcreteLoadIngredientOverview) =>
            renderUnit(moistureAmountInKg, UNITS.KG, {
                minimumFractionDigits: record.ingredientType === 'Admixture' ? 2 : 1
            })
    },
    {
        title: <Trans>Actual quantity</Trans>,
        key: 'actualQuantity',
        dataIndex: 'actualQuantity',
        render: (_, record: ConcreteLoadIngredientOverview) =>
            renderUnit(record.actualQuantity, record.actualQuantityUnit, {
                minimumFractionDigits: record.ingredientType === 'Admixture' ? 2 : 1
            })
    },
    {
        title: <Trans>Target quantity</Trans>,
        key: 'targetQuantity',
        dataIndex: 'targetQuantity',
        render: (_, record: ConcreteLoadIngredientOverview) =>
            renderUnit(record.targetQuantity, record.targetQuantityUnit, {
                minimumFractionDigits: record.ingredientType === 'Admixture' ? 2 : 1
            })
    },
    {
        title: (
            <>
                <Trans>Deviation</Trans> (%)
            </>
        ),
        key: 'percentageDeviation',
        dataIndex: 'percentageDeviation',
        render: (deviation: number, record: ConcreteLoadIngredientOverview) =>
            renderDeviation(deviation, { minimumFractionDigits: record.ingredientType === 'Admixture' ? 2 : 1 })
    }
];
