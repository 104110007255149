import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';
import { ConcreteLoadLiteModalAction, OrderLoadLiteTypesValues } from '../../models/Types';
import OrderLoadLiteModalForm0 from './Form0';
import OrderLoadLiteModalForm1 from './Form1';
import OrderLoadLiteModalForm2 from './Form2';
import OrderLoadLiteModalForm3 from './Form3';

interface OrderLoadLiteModalFormProps {
    page: number;
    onNext: () => void;
    initializeForm: (formFields: ConcreteLoadDataLiteFormFields, origin?: string) => void;
    type: OrderLoadLiteTypesValues;
    action?: ConcreteLoadLiteModalAction;
    origin?: OrderLoadLiteTypesValues;
}

function OrderLoadLiteModalForm({ page, onNext, initializeForm, type, action, origin }: OrderLoadLiteModalFormProps) {
    switch (page) {
        case 0:
            return <OrderLoadLiteModalForm0 type={type} onNext={onNext} initializeForm={initializeForm} />;
        case 1:
            return <OrderLoadLiteModalForm1 origin={origin} type={type} action={action} />;
        case 2:
            return <OrderLoadLiteModalForm2 type={type} origin={origin} />;
        case 3:
            return <OrderLoadLiteModalForm3 />;
        default:
            return null;
    }
}

export default OrderLoadLiteModalForm;
