import { t } from "@lingui/macro";
import { Input } from "antd";
import InputFormItem from "src/app-react/components/Form/FormItem/InputFormItem";
import numberRule from "src/app-react/components/Form/Validations/numberRule";

export function TestingAgeFormItem(): JSX.Element {
    return (
        <InputFormItem
            required={true}
            name="testingAge"
            label={t`Testing Age`}
            rules={[numberRule({ minOrEqual: 0 })]}>
            <Input type="number" data-testid="testingAge" addonAfter={t`days`} placeholder={t`Enter the testing age`} />
        </InputFormItem>
    );
}