import { AreaChartOutlined, ReadOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Trans } from '@lingui/macro';
import { Layout, Menu, MenuProps } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { Role } from 'src/app-react/services/authService';
import iconDashboard from '../../../../assets/icons/icon-deliveries.svg';
import { ReactComponent as IconProjects } from '../../../../assets/icons/projects.svg';
import RawLinkButton from '../../RawLinkButton/RawLinkButton';

import { BUSINESS_PAGES } from 'src/app-react/constants/businessPages';
import { ROLES } from 'src/app-react/constants/roles';
import useNavigateDiscardChanges from 'src/app-react/hooks/useNavigateDiscardChanges';
import useUserIsProjectManagerState from 'src/app-react/hooks/useUserIsProjectManagerState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import styles from '../AppMenu.module.css';

type MenuItem = MenuProps['items'][number] & {
    roles: Role[];
    permission?: {
        permissionName: string;
        action: string;
    };
    key: string;
};

function getMainMenuItems(projectId: string, navigateToPath: (url: string) => void): MenuItem[] {
    if (!projectId) {
        return [];
    }
    return [
        {
            roles: [ROLES.PROJECT_MANAGER, ROLES.CONCRETE_ENGINEER, ROLES.VIEWER],
            key: BUSINESS_PAGES.CATALOGUE_MANAGEMENT,
            label: (
                <RawLinkButton
                    data-testid="catalogueMenuBtn"
                    onClick={() => navigateToPath(`/projects/${projectId}/catalogue-management`)}>
                    <Trans>Catalogue management</Trans>
                </RawLinkButton>
            ),
            icon: <ReadOutlined />
        },
        {
            roles: [ROLES.FOREMAN, ROLES.CONCRETE_ENGINEER, ROLES.VIEWER],
            key: BUSINESS_PAGES.ORDERS,
            label: (
                <RawLinkButton
                    data-testid="ordersMenuBtn"
                    onClick={() => navigateToPath(`/projects/${projectId}/orders`)}>
                    <Trans>Order monitoring</Trans>
                </RawLinkButton>
            ),
            icon: <ShoppingCartOutlined />
        },
        {
            roles: [ROLES.FOREMAN, ROLES.PROJECT_MANAGER, ROLES.CONCRETE_ENGINEER, ROLES.VIEWER, ROLES.SUBCONTRACTOR],
            permission: {
                permissionName: 'DELIVERY_DASHBOARD',
                action: 'READ'
            },
            key: BUSINESS_PAGES.DELIVERIES,
            label: (
                <RawLinkButton
                    data-testid="deliveriesMenuBtn"
                    onClick={() => navigateToPath(`/projects/${projectId}/deliveries`)}>
                    <Trans>Deliveries</Trans>
                </RawLinkButton>
            ),
            icon: <Icon component={() => <img alt="iconDashboard" src={iconDashboard} />} />
        },
        {
            roles: [ROLES.FOREMAN, ROLES.CONCRETE_ENGINEER, ROLES.VIEWER, ROLES.SUBCONTRACTOR],
            key: BUSINESS_PAGES.CONCRETING_DOCUMENTATION,
            label: (
                <RawLinkButton
                    data-testid="concreteDocMenuBtn"
                    onClick={() => navigateToPath(`/projects/${projectId}/concreting-documentation`)}>
                    <Trans>Documentation</Trans>
                </RawLinkButton>
            ),
            icon: <AreaChartOutlined />
        }
    ];
}

function SiderMenu() {
    const location = useLocation();
    const [collapsed, setCollased] = useState(true);
    const { projectId, projectBusinessPages } = useProjectState();
    const { userIsProjectManager } = useUserIsProjectManagerState();
    const { roles, hasPermissions } = useUserPermissions();
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>();
    const navigateConfirm = useNavigateDiscardChanges();

    const userManagementItems = [
        userIsProjectManager && {
            key: BUSINESS_PAGES.USER_MANAGEMENT,
            label: (
                <RawLinkButton data-testid="userManagementMenuBtn" onClick={() => navigateConfirm(`/user-management`)}>
                    <Trans>User management</Trans>
                </RawLinkButton>
            ),
            icon: <UserOutlined />
        }
    ];

    const renderMenuItems = (): MenuProps['items'] => {
        const sideMenuItems = getMainMenuItems(projectId, navigateConfirm).reduce(
            (memo, { roles: menuItemRoles, permission, ...menuItem }) => {
                const validRole = menuItemRoles.some((role) => roles.includes(role));
                const validBusinessPages = projectBusinessPages.includes(menuItem.key);
                const validPermision = permission ? hasPermissions(permission.permissionName, permission.action) : true;

                if (validRole && validPermision && validBusinessPages) {
                    memo.push(menuItem);
                }

                return memo;
            },
            []
        );

        return [
            {
                key: 'projects',
                label: (
                    <RawLinkButton data-testid="projectsMenuBtn" onClick={() => navigateConfirm(`/projects`)}>
                        <Trans>Project list</Trans>
                    </RawLinkButton>
                ),
                icon: <Icon component={IconProjects} />
            },
            ...sideMenuItems,
            ...userManagementItems
        ];
    };

    useEffect(() => {
        if (location.pathname.includes('orders') || location.pathname.includes('order-type')) {
            setSelectedMenuItem('orders');
        } else {
            const splitUrl: string[] = location.pathname.split('/');
            setSelectedMenuItem(splitUrl[splitUrl.length - 1] || 'projects');
        }
    }, [location, setSelectedMenuItem]);

    return (
        <Layout.Sider
            className={styles.menuSidebar}
            width={240}
            collapsible
            collapsed={collapsed}
            onCollapse={setCollased}>
            <Menu
                theme="dark"
                className={styles.menuSidebar}
                mode="inline"
                items={renderMenuItems()}
                selectedKeys={[selectedMenuItem]}
            />
        </Layout.Sider>
    );
}

export default SiderMenu;
