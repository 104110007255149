import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import { OrderManagementProvider } from './providers/order.management.provider';
import OrderManagementFormPage from './components/OrderManagementFormPage/OrderManagementFormPage';

const DuplicateOrderHandler = () => {
    const { projectId, structElementId, concreteTypeId, orderId } = useParams();

    return (
        <OrderManagementProvider projectId={projectId}>
            <OrderManagementFormPage 
                title={t`Duplicate order`}
                orderId={orderId} 
                structuralElementId={structElementId} 
                concreteTypeId={concreteTypeId} 
                action='duplicate' 
            />
        </OrderManagementProvider>
    );
};

export default DuplicateOrderHandler;
