import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Col, Form, Input, Row, Select } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import CompressiveStrengthFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/CompressiveStrengthFormItem';
import MethodConcreteFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/MethodOfConcrete';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import InputFormItem from 'src/app-react/components/Form/FormItem/InputFormItem';
import { CanBeOrderedFormItem } from '../../FormItems/CanBeOrderedFormItem';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import { TestingAgeFormItem } from '../../FormItems/TestingAgeFormItem';
import { AdditionalAttributesFormItem } from './FormItems/AdditionalAttributesFormItem';
import { CementsFormItem } from '../../FormItems/CementsFormItem';
import { SupplementaryCementiousMaterialsFormItem } from '../../FormItems/SupplementaryCementiousMaterialsFormItem';
import { AdmixturesFormItem } from '../../FormItems/AdmixturesFormitem';
import { AssignToStructuralElementsFormItem } from './FormItems/AssignToStructuralElementsFormItem';
import MaxAggregateSizeFormItem from 'src/app-react/business-page/shared/FormItems/Catalogue/US/MaxAggregateSizeFormItem';
import { AggregatesFormItem } from './FormItems/AggregatesFormItem';
import { WaterCementRatioFormItemUS } from './FormItems/WaterCementRatioFormItemUS';
import { CataloguedStructuralElementExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';

interface Props {
    supplierId: string;
    additionalAttributes: string[];
    assignableStructuralElements: CataloguedStructuralElementExtendedBP[];
}

export const ConcreteTypeModalFormUS = ({
    supplierId,
    additionalAttributes,
    assignableStructuralElements
}: Props) => {
    const { slumps = [] } = useProjectConstantsState();
    const cemexGo = 'cemex.cemexgo';

    return (
        <>
            <Row>
                <Trans>Please select or fill the required fields to create a mix design.</Trans>
            </Row>

            <br />

            <Row gutter={12}>
                <Col span="12">
                    <Form.Item
                        name="typeNumber"
                        label={t`Mix design number`}
                        rules={[requiredRule]}>
                        <Input type="text" placeholder={t`Enter mix design number`} data-testid="typeNumber" />
                    </Form.Item>
                </Col>
                <Col span="12">
                    <InputFormItem
                        label={t`Mix design description`}
                        name="description">
                        <Input type="text" placeholder={t`Enter mix design description`} data-testid="description" />
                    </InputFormItem>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span="12">
                    <Form.Item
                        name="price"
                        label={t`Price`}
                        rules={[
                            {
                                pattern:
                                    i18n.locale === 'de-DE'
                                        ? new RegExp(/^-?\d+(?:,\d{1,2})?$/)
                                        : new RegExp(/^-?\d+(?:\.\d{1,2})?$/),
                                message: t`Please input price`
                            }
                        ]}>
                        <Input placeholder={t`Enter price`} data-testid="price" addonAfter={t`$/yd³`} />
                    </Form.Item>
                </Col>
                <Col span="12">
                    <CanBeOrderedFormItem/>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span="12">
                    <CompressiveStrengthFormItem/>
                </Col>
                <Col span="12">
                    <Input.Group compact>
                        <FormItem
                            name={'slump'}
                            label={t`Slump`}
                            style={{ display: 'inline-block', width: '45%', margin: '0 8px' }}
                            rules={[
                                {
                                    required: true,
                                    ...(supplierId !== cemexGo && {
                                        pattern:
                                            i18n.locale === 'de-DE'
                                                ? new RegExp(/^\d+(?:,\d{1,2})?$/)
                                                : new RegExp(/^\d+(?:\.\d{1,2})?$/)
                                    }),
                                    message: t`A number`
                                }
                            ]}>
                            {supplierId === cemexGo ? (
                                <Select
                                    style={{ display: 'inline-block', width: '100%' }}
                                    placeholder={t`Please select slump`}
                                    options={slumps}
                                    data-testid="slump"></Select>
                            ) : (
                                <Input placeholder={t`Enter slump`} data-testid="slump" addonAfter={t`in`} />
                            )}
                        </FormItem>

                        <Form.Item
                            name={'slumpVariance'}
                            label={t`Slump variance`}
                            style={{ display: 'inline-block', width: '45%' }}
                            rules={[
                                {
                                    pattern:
                                        i18n.locale === 'de-DE'
                                            ? new RegExp(/^-?\d+(?:,\d{1,2})?$/)
                                            : new RegExp(/^-?\d+(?:\.\d{1,2})?$/),
                                    message: t`A number`
                                }
                            ]}>
                            <Input
                                placeholder={t`Enter slump variance`}
                                addonBefore={t`±`}
                                addonAfter={t`in`}
                                data-testid="slumpVariance"
                            />
                        </Form.Item>
                    </Input.Group>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span="12">
                    <MethodConcreteFormItem/>
                </Col>
                <Col span="12">
                    <InputFormItem
                        name={'airContentInPercent'}
                        label={t`Air content`}
                        rules={[numberRule({min: 0, max: 100, decimal: true})]}>
                        <Input
                            type="number"
                            min={0}
                            max={100}
                            placeholder={t`Enter air content`}
                            data-testid="airContentInPercent"
                            addonAfter={t`%`}
                        />
                    </InputFormItem>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span="12">
                    <MaxAggregateSizeFormItem />
                </Col>
                <Col span="12">
                    <WaterCementRatioFormItemUS/>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span="12">
                    <TestingAgeFormItem/>
                </Col>
                <Col span="12">
                    <InputFormItem
                        required={false}
                        label={t`Submittal number`}
                        name="submittalNumber"
                    >
                        <Input type="text" placeholder={t`Enter submittal number`} data-testid="submittalNumber" />
                    </InputFormItem>
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <AdditionalAttributesFormItem additionalAttributes={additionalAttributes}/>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <AggregatesFormItem/>
                </Col>
            </Row>
            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <CementsFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <SupplementaryCementiousMaterialsFormItem />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <AdmixturesFormItem/>
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <hr className="concrete-type-modal__hr" />
                    <br />
                </Col>
            </Row>

            <Row>
                <Col span="24">
                    <AssignToStructuralElementsFormItem assignableStructuralElements={assignableStructuralElements} />
                </Col>
            </Row>
        </>
    );
};

export default ConcreteTypeModalFormUS;