import { ConcreteTypeExtendedBP } from '@nexploretechnology/concreting-core-client/concrete/catalogue.management-client/catalogue.management.dto';
import ConcreteLoadDataLiteFormFields from '../../models/ConcreteLoadDataLiteFormFields';

export function parseRequiredXf({ xfFirst, xfSecond }: { xfFirst?: number | string; xfSecond?: number | string }) {
    if (xfFirst && xfSecond) {
        return `${xfFirst}/${xfSecond}`;
    }

    if (xfFirst) {
        return `${xfFirst}`;
    }

    return undefined;
}

function fromConcreteTypeToFormFields(
    concreteType: ConcreteTypeExtendedBP,
    formFieldsValue?: Partial<ConcreteLoadDataLiteFormFields>
) {
    return {
        concreteTypeId: concreteType.id,
        chlorideContentClass: formFieldsValue?.chlorideContentClass ?? concreteType.chlorideContentClass,
        moistureClass: formFieldsValue?.moistureClass ?? concreteType.moistureClass,
        methodOfConcrete: formFieldsValue?.methodOfConcrete ?? concreteType.methodOfConcreteUse,
        strengthDevelopmentClass: formFieldsValue?.strengthDevelopmentClass ?? concreteType.strengthDevelopmentClass,
        maxAggregateSize: formFieldsValue?.maxAggregateSize ?? concreteType.maximumAggregateSize,
        densityClass: formFieldsValue?.densityClass ?? concreteType.densityClass,
        consistencyClass: {
            consistencyType: formFieldsValue?.consistencyClass?.consistencyType ?? concreteType.consistencyType,
            consistencyClass: formFieldsValue?.consistencyClass?.consistencyClass ?? concreteType.consistencyClass
        },
        compressiveStrengthClass: {
            requiredTypeOfConcreteType:
                formFieldsValue?.compressiveStrengthClass?.requiredTypeOfConcreteType ?? concreteType.typeOfConcrete,
            cylindricCompressiveStrength:
                formFieldsValue?.compressiveStrengthClass?.cylindricCompressiveStrength ??
                concreteType.cylindricCompressiveStrength,
            cubicCompressiveStrength:
                formFieldsValue?.compressiveStrengthClass?.cubicCompressiveStrength ??
                concreteType.cubicCompressiveStrength
        },
        requiredX0: formFieldsValue?.requiredX0 ?? concreteType.x0,
        requiredXc: formFieldsValue?.requiredXc ?? concreteType.xc,
        requiredXd: formFieldsValue?.requiredXd ?? concreteType.xd,
        requiredXf: formFieldsValue?.requiredXf ?? parseRequiredXf(concreteType),
        requiredXa: formFieldsValue?.requiredXa ?? concreteType.xa,
        requiredXm: formFieldsValue?.requiredXm ?? concreteType.xm,
        requiredXs: formFieldsValue?.requiredXs ?? concreteType.xs,
        compressiveStrength: formFieldsValue?.compressiveStrength ?? concreteType.compressiveStrength,
        slump: formFieldsValue?.slump ?? concreteType.slump
    };
}

export default fromConcreteTypeToFormFields;
