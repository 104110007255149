import { t } from "@lingui/macro";
import { Input } from "antd";
import InputFormItem from "src/app-react/components/Form/FormItem/InputFormItem";

export function ProjectNameFormItem() {
    return (
        <InputFormItem
            required={true}
            label={t`Project name`}
            name="name"
        >
            <Input type="text" data-testid="projectName" placeholder={t`Project name`} />
        </InputFormItem>
    )
}