import { UserProfile } from '@nexploretechnology/concreting-core-client/concrete/user.management-client/user.management.dto';
import { Role } from '../services/authService';

interface IsRoleSelectedParams {
    role: Role;
    userProfile?: UserProfile;
    companyId?: string;
}

function isRoleSelected({ role, userProfile, companyId }: IsRoleSelectedParams): boolean {
    if (!companyId || !userProfile) {
        return false;
    }

    const company = userProfile.companies.find((c) => c.companyId === companyId);

    return !!company?.companyRoles?.[role];
}

export default isRoleSelected;
