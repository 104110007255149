import { t } from '@lingui/macro';
import { Form, TimePicker } from 'antd';
import { Rule } from 'antd/lib/form';
import DateTimePickerFormItem from 'src/app-react/components/Form/FormItem/DateTimePickerFormItem';
import dateTimeWithDependenciesRule from 'src/app-react/components/Form/Validations/dateTimeWithDependenciesRule';
import TIME_STAMP_DEPENDENCIES from '../../../../../constants/timeStampDependencies';

const RULES: Rule[] = [
    dateTimeWithDependenciesRule({
        name: 'unloadingEnd',
        sameOrBefore: ['loadingStart', 'arrivalAtConstructionSite', 'unloadingStart'],
        after: ['signedOn']
    })
];

function UnloadingEndFormItem() {
    const signedOn = Form.useWatch('signedOn');

    return (
        <DateTimePickerFormItem
            required={!!signedOn}
            datePickerFormItemProps={{
                name: 'unloadingEndDate',
                label: t`Unloading end date`
            }}
            timePickerFormItemProps={{
                name: 'unloadingEnd',
                dependencies: TIME_STAMP_DEPENDENCIES,
                rules: RULES
            }}
        />
    );
}

export default UnloadingEndFormItem;
